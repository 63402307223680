import SporkReviewQuestions from 'pages/spork-review-questions/SporkReviewQuestions';
import Create from 'pages/spork-review-questions/Create';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'sporkReviewQuestions',
		path: '',
		component: SporkReviewQuestions,
		permissions: ['spork-review-questions:view'],
		exact: true,
	},
	{
		name: 'sporkReviewQuestionsCreate',
		path: '/create',
		component: Create,
		permissions: ['spork-review-questions:create'],
		exact: true,
	},
];

export default prependUrlPath('spork-review-questions', routes);
