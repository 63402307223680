import React from 'react';
import gql from 'graphql-tag';
import { useHistory, useParams } from 'react-router-dom';
import {
	deletePartnerListMenuSections,
	put,
} from '@sporkbytes/api-client/PartnerListMenuSections';

import FormWrapper from 'components/forms/FormWrapper';
import NameForm from 'components/forms/NameForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const PARTNER_LIST_MENU_SECTION_QUERY = gql`
	query PartnerListMenuSectionEdit($id: uuid!) {
		PartnerListMenuSections_by_pk(id: $id) {
			name
			PartnerListMenu {
				id
			}
		}
	}
`;

const Edit = () => {
	const { id } = useParams();
	const { pushNamedRoute } = useHistory();

	return (
		<Page
			title={({ PartnerListMenuSections_by_pk: { name } }) =>
				`Edit ${name}`
			}
			query={PARTNER_LIST_MENU_SECTION_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					PartnerListMenuSections_by_pk: { name, PartnerListMenu },
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${name}`} />
					<FormWrapper
						form={NameForm}
						initialValues={{
							name,
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
						onDelete={axios =>
							deletePartnerListMenuSections(axios, id).then(() =>
								pushNamedRoute('partnerListMenusDetail', {
									id: PartnerListMenu.id,
								})
							)
						}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
