import React from 'react';
import { useParams } from 'react-router-dom';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { Card, CardContent } from '@material-ui/core';
import { Alert } from '@sporkbytes/material-ui-kit-react';
import { getMealMenu } from '@sporkbytes/api-client/PublicMealOptions';

import DateOutput from 'components/content/DateOutput';
import LoadingPage from 'components/content/LoadingPage';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PurchaseOrderPrintableMenu from 'components/purchase-orders/PurchaseOrderPrintableMenu';

import useRequest from 'hooks/useRequest';

import axios from 'services/data';

const MealMenu = props => {
	const { mealMenuId } = useParams();
	const { data: mealOption, loading } = useRequest(() =>
		getMealMenu(axios, mealMenuId)
	);

	if (loading) {
		return <LoadingPage />;
	}

	if (!mealOption) {
		return (
			<Page title="Meal Not Found">
				<Alert type="info" size="large">
					No meal was found.
				</Alert>
			</Page>
		);
	}

	const { deliveryDate } = mealOption.MealProposal;
	const { timezone } = mealOption.MealProposal.ClientLocation;

	return (
		<Page
			title={
				'Menu for ' +
				format(
					utcToZonedTime(new Date(deliveryDate), timezone),
					'EEEE L/d yyyy'
				)
			}
		>
			<Card>
				<CardContent>
					<PageHeader
						headerText={
							<DateOutput
								date={deliveryDate}
								timezone={timezone}
								variant="inherit"
							/>
						}
					/>
					{mealOption.PurchaseOrders.map(purchaseOrder => (
						<PurchaseOrderPrintableMenu
							key={purchaseOrder.id}
							purchaseOrder={purchaseOrder}
						/>
					))}
				</CardContent>
			</Card>
		</Page>
	);
};

export default MealMenu;
