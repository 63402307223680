import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { Typography, makeStyles } from '@material-ui/core';
import { shortLinkDomain } from '@sporkbytes/config';

import Logo from 'components/content/Logo';
import Page from 'components/layout/Page';
import ReviewQRCode from 'components/content/ReviewQRCode';

import { logo } from 'services/sporkMetadata';
import PrintButton from 'components/utilities/PrintButton';

const CLIENT_LOCATION_QUERY = gql`
	query ReviewableMealsQRCodeQuery($id: uuid!) {
		ClientLocations_by_pk(id: $id) {
			friendlyName
			publicMenuProfileId
			Client {
				name
				logoUrl
			}
		}
	}
`;

const useStyles = makeStyles(theme => ({
	container: {
		alignItems: 'center',
		border: `10px solid ${theme.palette.secondary[500]}`,
		display: 'flex',
		flexDirection: 'column',
		height: 672,
		justifyContent: 'space-between',
		margin: 'auto',
		padding: theme.spacing(2),
		width: 480,
		'@media print': {
			left: 0,
			position: 'absolute',
			top: 0,
		},
	},
	logo: {
		maxHeight: 125,
	},
	printButton: {
		display: 'flex',
		margin: theme.spacing(2, 'auto'),
		'@media print': {
			display: 'none',
		},
	},
}));

const ReviewableMealsQRCode = () => {
	const { id } = useParams();
	const classes = useStyles();

	return (
		<Page
			query={CLIENT_LOCATION_QUERY}
			variables={{ id }}
			title={({ ClientLocations_by_pk: { friendlyName, Client } }) =>
				`${friendlyName} Reviewable Meals QR Code (${Client.name})`
			}
		>
			{({
				data: {
					ClientLocations_by_pk: {
						publicMenuProfileId,
						Client: { name, logoUrl },
					},
				},
			}) => (
				<>
					<div className={classes.container}>
						{logoUrl ? (
							<Logo
								url={logoUrl}
								alt={name}
								className={classes.logo}
							/>
						) : (
							<Typography variant="h3">{name}</Typography>
						)}
						<ReviewQRCode
							headerProps={{ variant: 'h4' }}
							subheaderProps={{ variant: 'h6' }}
							qrCodeValue={`${shortLinkDomain}/client-location-reviewable-meals?publicMenuProfileId=${publicMenuProfileId}`}
						/>

						<Logo
							url={logo.small.main}
							alt="Spork Bytes"
							size="small"
						/>
					</div>
					<PrintButton className={classes.printButton} />
				</>
			)}
		</Page>
	);
};

export default ReviewableMealsQRCode;
