import { cdn } from '@sporkbytes/config';
import { getMonth } from 'date-fns';

export const logo = {
	small: {
		main: `${cdn.url}/logos/spork/orange-small.png`,
		white: `${cdn.url}/logos/spork/white-small.png`,
		pride: `${cdn.url}/logos/spork/pride-small.png`,
	},
};

export const getThemedLogo = ({ date, size = 'small' } = {}) => {
	const month = date ? getMonth(new Date(date)) : getMonth(new Date());

	switch (month) {
		case 5:
			return logo[size].pride;
		default:
			return logo[size].main;
	}
};
