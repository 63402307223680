import React, { useRef } from 'react';
import gql from 'graphql-tag';

import ActionMenu from 'components/utilities/ActionMenu';
import RemoteTable from 'components/content/RemoteTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { useAuth } from 'services/auth';
import { createEditAction } from 'services/actionMenu';

const getEmailTemplatesQuery = searchTerm =>
	gql`
		query EmailTemplates(
			$nameOrderBy: order_by
			$limit: Int!
			$offset: Int!
		) {
			EmailTemplates(
				where: { name: { _ilike: "%${searchTerm}%" } }
				order_by: { name: $nameOrderBy, }
				limit: $limit
				offset: $offset
			) {
				id
				name	
			}
			EmailTemplates_aggregate {
				aggregate {
					count
				}
			}
		}
	`;

const Detail = () => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<Page title="Email Templates">
			<PageHeader headerText="Email Templates" />
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Name',
						field: 'name',
					},
					{
						title: 'Actions',
						field: 'action',
						sorting: false,
						render: ({ id, name }) => (
							<ActionMenu
								options={[
									createEditAction({
										entityId: id,
										entityName: name,
										entityType: 'EmailTemplates',
										userCan,
									}),
								]}
							/>
						),
					},
				]}
				getQuery={getEmailTemplatesQuery}
				defaultOrderBy="name"
				defaultOrderDirection="asc"
				queryEntity="EmailTemplates"
				addNewEntityRouteName="emailTemplatesCreate"
				entityToAddName="Email Template"
				canAddNewEntity={userCan(['email-templates:create'])}
				options={{
					pageSize: 25,
				}}
			/>
		</Page>
	);
};

export default Detail;
