import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { post } from '@sporkbytes/api-client/MealOptions';

import FormWrapper from 'components/forms/FormWrapper';
import NameForm from 'components/forms/NameForm';

const MEAL_PROPOSAL_QUERY = gql`
	query MealOptionCreate($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			orderNumberText
		}
	}
`;

const MealOptionCreateDialogForm = ({ id, ...props }) => {
	const { data, loading } = useQuery(MEAL_PROPOSAL_QUERY, {
		variables: {
			id,
		},
	});

	return (
		<FormWrapper
			form={NameForm}
			displayIn="dialog"
			onSubmit={(axios, values) =>
				post(axios, {
					MealProposalId: id,
					...values,
				})
			}
			redirectToRoute="mealProposalsMealOptionsManage"
			routeVariables={({ id: MealOptionId }) => ({
				id,
				'meal-option': MealOptionId,
			})}
			loading={loading}
			title={`New Meal Option for Meal Proposal ${data?.MealProposals_by_pk.orderNumberText}`}
			{...props}
		/>
	);
};

MealOptionCreateDialogForm.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MealOptionCreateDialogForm;
