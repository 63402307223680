import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { put, sort } from '@sporkbytes/api-client/MealCategories';
import { Alert } from '@sporkbytes/material-ui-kit-react';

import ActionMenu from 'components/utilities/ActionMenu';
import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import EditableSortOrder from 'components/forms/EditableSortOrder';
import RemoteTable from 'components/content/RemoteTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { createEditAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const getMealCategoriesQuery = searchTerm =>
	gql`
		query MealCategories(
			$sortOrderOrderBy: order_by
			$nameOrderBy: order_by
			$limit: Int!
			$offset: Int!
		) {
			MealCategories(
				where: { name: { _ilike: "%${searchTerm}%" } }
				order_by: { sortOrder: $sortOrderOrderBy, name: $nameOrderBy }
				limit: $limit
				offset: $offset
			) {
				id
				name	
				sortOrder
				active
			}
			MealCategories_aggregate {
				aggregate {
					count
				}
			}
		}
	`;

const Detail = () => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<Page title="Meal Categories">
			<PageHeader headerText="Meal Categories" />
			<Alert type="info">
				Meal categories are for financial analysis. They allow us to
				easily see how much revenue is generated by each category. They
				differ from meal types in that meal types drive how menus appear
				on the partner list.
			</Alert>
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Sort Order',
						field: 'sortOrder',
						render: ({ id, sortOrder }) => (
							<EditableSortOrder
								onSave={value =>
									sort(axios, id, {
										newValue: value,
									}).then(ref.current.onQueryChange)
								}
								permissions={['meal-categories:edit']}
								required={true}
								value={sortOrder}
							/>
						),
					},
					{
						title: 'Name',
						field: 'name',
					},
					{
						title: 'Active',
						field: 'active',
						render: ({ active, id }) => (
							<BooleanFieldSwitch
								afterChange={ref.current.onQueryChange}
								entityId={id}
								entityType="partnerMenuItems"
								updateEntity={put}
								value={active}
							/>
						),
					},
					{
						title: 'Actions',
						field: 'actions',
						render: ({ id, name }) => (
							<ActionMenu
								options={[
									createEditAction({
										entityId: id,
										entityName: name,
										entityType: 'MealCategories',
										userCan,
									}),
								]}
							/>
						),
					},
				]}
				getQuery={getMealCategoriesQuery}
				defaultOrderBy="sortOrder"
				defaultOrderDirection="asc"
				queryEntity="MealCategories"
				addNewEntityRouteName="mealCategoriesCreate"
				entityToAddName="Meal Category"
				canAddNewEntity={userCan(['meal-categories:create'])}
			/>
		</Page>
	);
};

export default Detail;
