import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Tooltip } from '@material-ui/core';

import Link from 'components/routing/Link';
import Logo from 'components/content/Logo';

const useStyles = makeStyles(theme => ({
	actionButtonContainer: {
		marginLeft: theme.spacing(1),
	},
	container: {
		alignItems: 'center', // needed to keep actionButton from stretching its height
		display: 'flex',
		justifyContent: 'center',
		margin: theme.spacing(4, 0),
	},
	content: {
		fontSize: theme.typography.h4.fontSize,
		textAlign: 'center',
		[theme.breakpoints.up('lg')]: {
			fontSize: theme.typography.h3.fontSize,
		},
	},
	img: {
		maxHeight: 200,
		maxWidth: 200,
	},
}));

const PageHeader = ({ actionButton, headerText, imgUrl, linkProps }) => {
	const classes = useStyles();

	const Wrapper = linkProps
		? props => <Link {...props} {...linkProps} />
		: React.Fragment;

	return (
		<div className={classes.container}>
			<Typography component="h1" className={classes.content}>
				<Wrapper>
					{imgUrl ? (
						<Tooltip title={headerText} aria-label={headerText}>
							<Logo url={imgUrl} alt={headerText} size="small" />
						</Tooltip>
					) : (
						headerText
					)}
				</Wrapper>
			</Typography>
			<div className={classes.actionButtonContainer}>{actionButton}</div>
		</div>
	);
};

PageHeader.propTypes = {
	actionButton: PropTypes.element,
	headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	imgUrl: PropTypes.string,
	linkProps: PropTypes.object,
};

export default PageHeader;
