import React from 'react';
import PropTypes from 'prop-types';
import { Check } from '@material-ui/icons';
import { PrimaryButton } from '@sporkbytes/material-ui-kit-react';

import axios from 'services/data';

const ApproveButton = ({ afterApprove, approve, id, ...props }) => {
	return (
		<PrimaryButton
			onClick={() => approve(axios, id).then(afterApprove)}
			size="large"
			startIcon={<Check />}
			{...props}
		>
			Approve
		</PrimaryButton>
	);
};

ApproveButton.propTypes = {
	afterApprove: PropTypes.func.isRequired,
	approve: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
};

export default ApproveButton;
