// https://medium.com/swlh/using-react-hooks-to-sync-your-component-state-with-the-url-query-string-81ccdfcb174f
// https://gist.github.com/karol-majewski/dd45139e103db2811fcdaa74a9de9a18

import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const parse = query =>
	[...new URLSearchParams(query).entries()].reduce(
		(accumulator, [key, value]) =>
			Object.assign(accumulator, { [key]: value }),
		{}
	);

const stringify = query =>
	new URLSearchParams(
		Object.entries(query)
			.filter(([_key, value]) => value !== undefined && value !== '')
			.map(([key, value]) => [key, value])
	).toString();

const useQueryString = () => {
	const [defaultQuery, setValue] = useState({});
	const history = useHistory();
	const { search, pathname, hash } = useLocation();
	const hasParams = search.includes('=');
	const setValueWithQuery = newQuery => {
		const search = stringify(newQuery);
		setValue(newQuery);
		history.push({
			pathname,
			search,
			hash,
		});
	};
	return hasParams
		? [
				{
					...defaultQuery,
					...parse(search),
				},
				setValueWithQuery,
		  ]
		: [defaultQuery, setValueWithQuery];
};

export default useQueryString;
