import React from 'react';
import gql from 'graphql-tag';
import { TableRow } from '@material-ui/core';
import { grossMargin } from '@sporkbytes/meal-option-utils';

import TableCellIndented from 'components/content/TableCellIndented';
import TableCellNumeric from 'components/content/TableCellNumeric';

const MealOptionGrossMarginLineItem = ({ mealOption }) => (
	<TableRow>
		<TableCellIndented colSpan="3">Gross Margin</TableCellIndented>
		<TableCellNumeric
			format="percent"
			value={grossMargin(mealOption, mealOption.MealProposal)}
		/>
	</TableRow>
);

MealOptionGrossMarginLineItem.fragments = {
	details: gql`
		fragment MealOptionGrossMarginLineItemDetails on MealOptions {
			PurchaseOrders(order_by: { pickupTime: asc }) {
				sporkCommissionPercent
				PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
					PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
						costToClient
						discount
						quantity
					}
				}
				PurchaseOrderPayoutAdjustments {
					amount
				}
			}
			MealProposal {
				headCount
				MealProposalFees {
					amount
					discount
					type
				}
			}
		}
	`,
};

export default MealOptionGrossMarginLineItem;
