import React from 'react';
import PropTypes from 'prop-types';
import {
	DialogForm,
	Form,
	GridContainer,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import * as yup from 'models/validation';

const NameForm = ({ displayIn, ...props }) => {
	const validationSchema = yup.object().shape({
		name: yup.name().required(),
	});

	const Wrapper = displayIn === 'dialog' ? DialogForm : Form;

	return (
		<Wrapper {...props} validationSchema={validationSchema}>
			{() => (
				<GridContainer columns={displayIn === 'dialog' ? 1 : 2}>
					<LinkedTextInput name="name" autoFocus />
				</GridContainer>
			)}
		</Wrapper>
	);
};

NameForm.propTypes = {
	displayIn: PropTypes.oneOf(['dialog', 'page']),
};

NameForm.defaultProps = {
	displayIn: 'page',
};

export default NameForm;
