import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { emailAddresses, phoneNumbers } from '@sporkbytes/config';
import { Alert, PhoneNumber } from '@sporkbytes/material-ui-kit-react';

import Page from 'components/layout/Page';

const NotFoundPage = ({ body, title }) => {
	return (
		<Page title={title}>
			<Alert type="error" size="large">
				{title}
			</Alert>
			<Typography>
				{body} Please email{' '}
				<a href={`mailto:${emailAddresses.help}`}>
					{emailAddresses.help}
				</a>{' '}
				or call{' '}
				<PhoneNumber
					value={phoneNumbers.main}
					renderText={phoneNumber => (
						<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
					)}
				/>{' '}
				for assistance.
			</Typography>
		</Page>
	);
};

NotFoundPage.propTypes = {
	body: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};

export default NotFoundPage;
