import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	noContentText: {
		color: theme.palette.grey[500],
		fontSize: 10,
		fontWeight: 'bold',
		letterSpacing: 1,
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
	},
}));

const NoContentText = ({ children, className, ...props }) => {
	const classes = useStyles();

	return (
		<Typography
			className={`${classes.noContentText}  ${className}`}
			{...props}
		>
			** {children} **
		</Typography>
	);
};

NoContentText.propTypes = {
	children: PropTypes.string,
	className: PropTypes.string,
};

NoContentText.defaultProps = {
	children: 'No content',
	className: '',
};

export default NoContentText;
