import Edit from 'pages/partner-menu-item-option-groups/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'partnerMenuItemOptionGroupsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['partner-menu-item-option-groups:edit'],
		exact: true,
	},
];

export default prependUrlPath('partner-menu-item-option-groups', routes);
