import React from 'react';
import PropTypes from 'prop-types';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import { PrimaryButton } from '@sporkbytes/material-ui-kit-react';

import NoContentText from 'components/content/NoContentText';
import RichText from 'components/content/RichText';

const useStyles = makeStyles(theme => ({
	button: {
		margin: theme.spacing(2),
	},
	divider: {
		marginBottom: theme.spacing(2),
	},
	noContentText: {
		margin: theme.spacing(3, 0),
	},
	warningText: {
		marginTop: theme.spacing(2),
		color: theme.palette.error[500],
	},
}));

const EmailTemplatePreview = ({
	emailTemplate: {
		buttonText,
		greeting,
		postButtonContent,
		preButtonContent,
		signature,
		warningText,
	},
}) => {
	const classes = useStyles();

	const CustomNoContentText = props => (
		<NoContentText {...props} className={classes.noContentText} />
	);
	return (
		<div>
			<Typography variant="h5">Email Preview</Typography>
			<Divider className={classes.divider} />

			{greeting ? (
				<Typography>{greeting}</Typography>
			) : (
				<CustomNoContentText>Greeting</CustomNoContentText>
			)}
			{preButtonContent ? (
				<RichText body={preButtonContent} />
			) : (
				<CustomNoContentText>Pre-Button Content</CustomNoContentText>
			)}

			<PrimaryButton variant="contained" className={classes.button}>
				{buttonText || '** Button Text **'}
			</PrimaryButton>

			{postButtonContent ? (
				<RichText body={postButtonContent} />
			) : (
				<CustomNoContentText>Post-Button Content</CustomNoContentText>
			)}

			{signature ? (
				<RichText body={signature} />
			) : (
				<Typography>
					Thank you! <br />
					Spork Bytes Team
				</Typography>
			)}

			{warningText && (
				<Typography className={classes.warningText}>
					{warningText}
				</Typography>
			)}
		</div>
	);
};

EmailTemplatePreview.propTypes = {
	emailTemplate: PropTypes.shape({
		buttonText: PropTypes.string,
		greeting: PropTypes.string,
		postButtonContent: PropTypes.string,
		preButtonContent: PropTypes.string,
		signature: PropTypes.string,
		warningText: PropTypes.string,
	}).isRequired,
};

export default EmailTemplatePreview;
