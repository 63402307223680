import CancellationReasons from 'pages/cancellation-reasons/CancellationReasons';
import Create from 'pages/cancellation-reasons/Create';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'cancellationReasons',
		path: '',
		component: CancellationReasons,
		permissions: ['cancellation-reasons:view'],
		exact: true,
	},
	{
		name: 'cancellationReasonsCreate',
		path: '/create',
		component: Create,
		permissions: ['cancellation-reasons:create'],
		exact: true,
	},
];

export default prependUrlPath('cancellation-reasons', routes);
