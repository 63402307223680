import gql from 'graphql-tag';

import MealProposalActionMenu from 'components/meal-proposals/MealProposalActionMenu';

import { getFilters } from 'services/mealProposal';

export { getMealProposalsQuery };

function getMealProposalsQuery({ pageSize, page, ...queryString }) {
	return gql`
		query MealProposalTablePageData {
			MealProposals(
				where: {${getFilters(queryString)}}
				order_by: {${_getOrdering(queryString)}}
				limit: ${pageSize}
				offset: ${page * pageSize}
			) {
				createdAt
				deliveryDate
				id
				headCount
				name
				orderNumberText
				status
				updatedAt
				ClientLocation {
					id
					friendlyName
					timezone
					Client {
						id
						name
					}
				}
				MealOptions(where: { approved: { _eq: true } }) {
					approved
					confirmed
					PurchaseOrders(order_by: { pickupTime: asc }) {
						approved
						confirmed
						id
						pickupTime
						PartnerLocation {
							timezone
							friendlyName
							Partner {
								id
								name
							}
						}
					}
				}
				MealProposalsDeliveryDrivers(order_by: { DeliveryDriver: { firstName: asc } } ) {
					DeliveryDriver {
						id
						abbreviatedName
					}
				}
				...MealProposalActionMenuDetails
			}
			MealProposals_aggregate(
				where: {${getFilters(queryString)}}
			)
			{
				aggregate {
					count
				}
			}
		}
		${MealProposalActionMenu.fragments.details}
	`;
}

function _getOrdering({ orderBy, orderDirection }) {
	if (orderBy) {
		return orderBy === 'clientName'
			? `ClientLocation: { Client: { name: ${orderDirection} } }`
			: `${orderBy} : ${orderDirection}`;
	}
	return '';
}
