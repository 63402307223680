import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { CardContent, makeStyles, Typography } from '@material-ui/core';
import { PrimaryButton } from '@sporkbytes/material-ui-kit-react';

import ApprovedConfirmedStatus from 'components/content/ApprovedConfirmedStatus';
import MealOptionActionMenu from 'components/meal-options/MealOptionActionMenu/';
import MealOptionCreateDialogForm from 'components/meal-options/MealOptionCreateDialogForm';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';

const useStyles = makeStyles(theme => ({
	approvedIcon: {
		marginRight: theme.spacing(1),
	},
	labelContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	status: {
		flexDirection: 'column',
	},
}));

const MealOptionTabs = ({ ContentComponent, mealProposal, ...props }) => {
	const { userCan } = useAuth();
	const { showDialog } = useStore();
	const classes = useStyles();

	const tabs =
		mealProposal.MealOptions.length > 0
			? mealProposal.MealOptions.map(
					({ approved, confirmed, id, name }) => ({
						id,
						label: (
							<div className={classes.labelContainer}>
								<ApprovedConfirmedStatus
									approved={approved}
									confirmed={confirmed}
									setType={false}
									text={<>{name}</>} // this is wrapped in a Fragment to avoid wrapping in Typography
									className={classes.status}
								/>
								<MealOptionActionMenu id={id} />
							</div>
						),
						component: 'div',
						content: <ContentComponent id={id} />,
					})
			  )
			: [
					{
						id: 'no-meal-options',
						label: 'No Meal Options',
						content: (
							<CardContent>
								<Typography>
									You have not created any meal options yet.
								</Typography>
								{/* TODO: change this to the new component https://app.asana.com/0/1156244779648537/1175658838889497 */}
								{userCan([
									'meal-proposals:edit',
									'meal-options:create',
								]) && (
									<PrimaryButton
										onClick={() =>
											showDialog(
												MealOptionCreateDialogForm,
												{
													id: mealProposal.id,
												}
											)
										}
									>
										Add New Meal Option
									</PrimaryButton>
								)}
							</CardContent>
						),
					},
			  ];

	return <QueryStringSyncedTabs id="meal-option" tabs={tabs} {...props} />;
};

MealOptionTabs.fragments = {
	details: gql`
		fragment MealOptionTabsDetails on MealProposals {
			id
			MealOptions(order_by: { approved: desc, createdAt: asc }) {
				id
				name
				approved
				confirmed
			}
		}
	`,
};

MealOptionTabs.propTypes = {
	ContentComponent: PropTypes.elementType.isRequired,
};

export default MealOptionTabs;
