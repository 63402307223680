import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/ClientLocations';

import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import ClientLocationActionMenu from 'components/client-locations/ClientLocationActionMenu';
import ClientLocationDetails from 'components/client-locations/ClientLocationDetails';
import DetailPageLink from 'components/routing/DetailPageLink';
import RemoteTable from 'components/content/RemoteTable';

import { getMultiWordSearchWhereClause } from 'services/search';
import { useAuth } from 'services/auth';

const getClientLocationsQuery = searchTerm => gql`
	query ClientLocationsTable(
		$ClientId: uuid
		$ContactId: uuid
		$activeOrderBy: order_by
		$friendlyNameOrderBy: order_by
		$fullAddressOrderBy: order_by
		$clientNameOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		ClientLocations(
			where: {
				ClientId: { _eq: $ClientId } 
				_and: [ ${getMultiWordSearchWhereClause(searchTerm)} ]
			}
			order_by: {
				active: $activeOrderBy
				friendlyName: $friendlyNameOrderBy
				fullAddress: $fullAddressOrderBy
				Client: { name: $clientNameOrderBy }
			}
			limit: $limit
			offset: $offset
		) {
			id
			active
			friendlyName
			fullAddress
			phoneNumber
		}
		ClientLocations_aggregate(
			where: {
				ClientId: { _eq: $ClientId } 
				_and: [ ${getMultiWordSearchWhereClause(searchTerm)} ]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const CLIENT_LOCATION_DETAIL = gql`
	query ClientLocationDetails($id: uuid!) {
		ClientLocations_by_pk(id: $id) {
			...ClientLocationDetails
		}
	}
	${ClientLocationDetails.fragments.details}
`;

const ClientLocationsTable = ({ ClientId }) => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<RemoteTable
			tableRef={ref}
			columns={[
				{
					title: 'Name',
					field: 'friendlyName',
					render: ({ id, friendlyName }) => (
						<DetailPageLink
							entityType="ClientLocations"
							id={id}
							text={friendlyName}
						/>
					),
				},
				{
					title: 'Address',
					field: 'fullAddress',
				},
				{
					title: 'Active',
					field: 'active',
					render: ({ active, id }) => (
						<BooleanFieldSwitch
							afterChange={ref.current.onQueryChange}
							entityId={id}
							entityType="clientLocations"
							updateEntity={put}
							value={active}
						/>
					),
				},
				{
					title: 'Actions',
					field: 'actions',
					sorting: false,
					render: clientLocation => (
						<ClientLocationActionMenu
							clientLocation={clientLocation}
						/>
					),
				},
			]}
			remoteDetailPanel={{
				query: CLIENT_LOCATION_DETAIL,
				getVariables: ({ id }) => ({ id }),
				render: ({ data }) => (
					<ClientLocationDetails
						clientLocation={data.ClientLocations_by_pk}
					/>
				),
			}}
			defaultOrderBy="friendlyName"
			defaultOrderDirection="asc"
			getQuery={getClientLocationsQuery}
			queryEntity="ClientLocations"
			variables={{ ClientId }}
			canAddNewEntity={userCan(['client-locations:create'])}
			entityToAddName="Client Location"
			addNewEntityRouteName="clientLocationsCreate"
			addNewEntityRouteVariables={{ id: ClientId }}
		/>
	);
};

ClientLocationsTable.propTypes = {
	ClientId: PropTypes.string.isRequired,
};

export default ClientLocationsTable;
