import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { PhoneNumber } from '@sporkbytes/material-ui-kit-react';

import ContactActionMenu from 'components/contacts/ContactActionMenu';
import ContactSearch from 'components/contacts/ContactSearch';
import RemoteTable from 'components/content/RemoteTable';

import axios from 'services/data';
import { getMultiWordSearchWhereClause } from 'services/search';

const getContactsQuery = tableFilter => searchTerm => gql`
	query Contacts(
		$emailOrderBy: order_by
		$firstNameOrderBy: order_by
		$lastNameOrderBy: order_by
		$phoneNumberOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		Contacts(
			where: {
				${tableFilter}
				_and: [
					${getMultiWordSearchWhereClause(searchTerm)}
				]
			}
			order_by: {
				email: $emailOrderBy
				firstName: $firstNameOrderBy
				lastName: $lastNameOrderBy
				phoneNumber: $phoneNumberOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			id
			email
			firstName
			lastName
			fullName
			phoneNumber
		}
		Contacts_aggregate(
			where: {
				${tableFilter}
				_and: [
					${getMultiWordSearchWhereClause(searchTerm)}
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const ContactsTable = ({
	additionalActions,
	onAddExistingEntity,
	tableFilter = '',
	...props
}) => {
	const history = useHistory();
	const ref = useRef();

	return (
		<>
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'First Name',
						field: 'firstName',
					},
					{
						title: 'Last Name',
						field: 'lastName',
					},
					{
						title: 'Email',
						field: 'email',
						disableClick: true,
						render: ({ email }) => (
							<Typography
								// eslint-disable-next-line
								component={props => <a {...props} />}
								href={`mailto:${email}`}
							>
								{email}
							</Typography>
						),
					},
					{
						title: 'Phone Number',
						field: 'phoneNumber',
						disableClick: true,
						render: ({ phoneNumber }) => (
							<PhoneNumber value={phoneNumber} />
						),
					},
					{
						title: 'Actions',
						field: 'actions',
						disableClick: true,
						sorting: false,
						render: contact => (
							<ContactActionMenu
								contact={contact}
								additionalActions={({ setLoading }) =>
									additionalActions({
										contact,
										ref,
										setLoading,
									})
								}
							/>
						),
					},
				]}
				defaultOrderBy="lastName"
				defaultOrderDirection="asc"
				getQuery={getContactsQuery(tableFilter)}
				queryEntity="Contacts"
				onRowClick={(event, { id }) =>
					history.pushNamedRoute('contactsDetail', { id })
				}
				entityToAddName="Contact"
				EntityToAddSearch={({ name }) => (
					<ContactSearch
						autoFocus
						name={name}
						where={`{ _not: { ${tableFilter} } }`}
					/>
				)}
				onAddExistingEntity={contact =>
					onAddExistingEntity(axios, contact, ref)
				}
				{...props}
			/>
		</>
	);
};

ContactsTable.propTypes = {
	additionalActions: PropTypes.func,
	onAddExistingEntity: PropTypes.func.isRequired,
	tableFilter: PropTypes.string,
};

ContactsTable.defaultProps = {
	additionalActions: () => [],
};

export default ContactsTable;
