import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';

import Page from 'components/layout/Page';
import PartnerMenuItemPrintableLabel from 'components/partner-menu-items/PartnerMenuItemPrintableLabel';
import PrintSettings from 'components/printable-labels/PrintSettings';

const PARTNER_MENU_ITEM_QUERY = gql`
	query PartnerMenuItemPrintableLabel($id: uuid!) {
		PartnerMenuItems_by_pk(id: $id) {
			...PartnerMenuItemPrintableLabelDetails
		}
	}
	${PartnerMenuItemPrintableLabel.fragments.details}
`;

const PrintableLabel = () => {
	const { id } = useParams();

	return (
		<Page
			query={PARTNER_MENU_ITEM_QUERY}
			variables={{ id }}
			title={({ PartnerMenuItems_by_pk: { friendlyName } }) =>
				`Print Label for ${friendlyName}`
			}
		>
			{({ data: { PartnerMenuItems_by_pk } }) => (
				<PrintSettings
					initialValues={{
						groups: [
							{
								id: 'item-options',
								name: 'Item Options',
								items: [
									{
										...PartnerMenuItems_by_pk,
										quantity: 1,
									},
								],
							},
						],
					}}
					title={`Label for ${PartnerMenuItems_by_pk.friendlyName}`}
				/>
			)}
		</Page>
	);
};

export default PrintableLabel;
