import React from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import { Dialog, PrimaryButton } from '@sporkbytes/material-ui-kit-react';

import MealOptionSummaryTable from 'components/meal-options/MealOptionSummaryTable';
import PurchaseOrderSummaryTable from 'components/purchase-orders/purchase-order-summary-table/PurchaseOrderSummaryTable';

const PricingDetailsDialog = ({
	mealOption,
	mealProposal,
	onClose,
	...props
}) => {
	return (
		<Dialog maxWidth="md" onClose={onClose} {...props}>
			<DialogContent>
				{mealOption.PurchaseOrders.map(purchaseOrder => (
					<React.Fragment key={purchaseOrder.id}>
						<Typography variant="h6">
							{purchaseOrder.PartnerLocation.Partner.name}
						</Typography>

						<PurchaseOrderSummaryTable
							purchaseOrder={purchaseOrder}
							showSporkMetrics={false}
							showPartnerMetrics={false}
						/>
					</React.Fragment>
				))}
				<MealOptionSummaryTable
					mealOption={{
						...mealOption,
						MealProposal: mealProposal,
					}}
					showPartnerMetrics={false}
					showSporkMetrics={false}
				/>
			</DialogContent>
			<DialogActions>
				<PrimaryButton onClick={onClose}>Close</PrimaryButton>
			</DialogActions>
		</Dialog>
	);
};

PricingDetailsDialog.propTypes = {
	mealOption: PropTypes.object.isRequired,
	mealProposal: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default PricingDetailsDialog;
