import { store } from 'models/store';

export const callPhoneNumber = phoneNumber =>
	(window.location.href = `tel:${phoneNumber}`);

export const copyText = text =>
	window.navigator.clipboard
		.writeText(text)
		.then(() =>
			store.getState().showSuccessNotification('Copied to clipboard')
		);

export const findLastIndex = (array, searchFunction) => {
	const index = [...array].reverse().findIndex(searchFunction);
	const maxIndex = array.length - 1;

	return index >= 0 ? maxIndex - index : index;
};

export const getDirections = address =>
	window.open(`https://www.google.com/maps/dir//${address}`, '_blank');

export const groupBy = (array, groupFunction) => {
	return array.reduce((groups, element) => {
		const groupKey = groupFunction(element);

		if (groups[groupKey]) {
			groups[groupKey].push(element);
		} else {
			groups[groupKey] = [element];
		}

		return groups;
	}, {});
};

/**
 * @description Given an array, key, and optional keyIdentifier, group the array into groups defined by the key.
 * @param {array} array - The array of elements you wish to group by a key.
 * @param {string|object} key - The key to use for grouping elements of the array.
 * @param {string} keyIdentifier - If the provided key is an object, the keyIdentifier is the property of the key that uniquely identifies that key for grouping.
 * @return {array} The given array grouped by key.
 */
export const groupByArray = (array, key, keyIdentifier) => {
	return array.reduce((groupedArray, arrayElement) => {
		let group =
			key instanceof Function ? key(arrayElement) : arrayElement[key];
		let foundGroup = groupedArray.find(r =>
			typeof keyIdentifier === 'string'
				? r.key[keyIdentifier] === group[keyIdentifier]
				: r.key === group
		);

		if (foundGroup) {
			foundGroup.values.push(arrayElement);
		} else {
			groupedArray.push({ key: group, values: [arrayElement] });
		}

		return groupedArray;
	}, []);
};

export const maxBy = (array, maxFunction) => {
	return array.sort((a, b) => maxFunction(b) - maxFunction(a))[0];
};

export const meanBy = (array, countFunction) => {
	if (array.length === 0) {
		return undefined;
	}

	return (
		array.reduce((sum, element) => sum + countFunction(element), 0) /
		array.length
	);
};

export const noop = () => {};

export const not =
	fn =>
	(...args) =>
		!fn(...args);

export const omit = (object, keysToOmit) =>
	typeof object === 'object'
		? Object.fromEntries(
				Object.entries(object).filter(
					([key]) => !keysToOmit.includes(key)
				)
		  )
		: {};

export const partition = (arrayToPartition, partitionFunction) => {
	let truthyItems = [];
	let falsyItems = [];

	arrayToPartition.forEach(item =>
		!!partitionFunction(item)
			? truthyItems.push(item)
			: falsyItems.push(item)
	);

	return [truthyItems, falsyItems];
};

export const pick = (object, keysToPick) =>
	typeof object === 'object'
		? Object.fromEntries(
				Object.entries(object).filter(([key]) =>
					keysToPick.includes(key)
				)
		  )
		: {};

export const prependUrlPath = (url, routes) =>
	routes.map(({ path, ...route }) => ({
		...route,
		path: '/' + url + path,
	}));

export const sendEmail = emailAddress =>
	(window.location.href = `mailto:${emailAddress}`);
