import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { Box, Fab, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';
import { postDeliveryEditWindowInDays } from '@sporkbytes/config';
import { Alert } from '@sporkbytes/material-ui-kit-react';
import { canEdit } from '@sporkbytes/meal-proposal-utils';

import BackButton from 'components/routing/BackButton';
import InlineContent from 'components/content/InlineContent';
import MealOptionCreateDialogForm from 'components/meal-options/MealOptionCreateDialogForm';
import MealOptionPurchaseOrderManager from 'components/meal-options/MealOptionPurchaseOrderManager';
import MealOptionTabs from 'components/meal-options/MealOptionTabs';
import MealProposalHelpDrawer from 'components/meal-proposals/MealProposalHelpDrawer';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { PartnerContextProvider } from 'context/PartnerContext';

import useQueryString from 'hooks/useQueryString';

import { useStore } from 'models/store';

import { pick } from 'services/utilities';
import { useAuth } from 'services/auth';

const MEAL_PROPOSAL_SUBSCRIPTION = gql`
	subscription MealOptionsManage($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			deliveryDate
			# id is required, otherwise we get an error that "data is not defined," despite us not caching results like others with this issue
			# see https://github.com/apollographql/react-apollo/issues/1003
			id
			name
			orderNumberText
			status
			...MealOptionTabsDetails
			...MealProposalHelpDrawerDetails
		}
	}
	${MealOptionTabs.fragments.details}
	${MealProposalHelpDrawer.fragments.details}
`;

const useStyles = makeStyles(theme => ({
	addButton: {
		color: theme.palette.common.white,
	},
	container: {
		display: 'flex',
	},
	content: {
		padding: theme.spacing(2),
		width: '100%',
		[theme.breakpoints.up('lg')]: {
			padding: theme.spacing(3),
			width: '75%',
		},
	},
	doneButton: {
		bottom: theme.spacing(2),
		position: 'fixed',
		right: theme.spacing(2),
		zIndex: theme.layers.fixedContent + 1,
	},
}));

const MealOptionsManage = () => {
	const { id } = useParams();
	const { role } = useAuth();
	const { showDialog } = useStore();
	const [queryString] = useQueryString();
	const classes = useStyles();
	const history = useHistory();

	return (
		<Page
			title={({ MealProposals_by_pk }) =>
				`Manage ${MealProposals_by_pk.orderNumberText}`
			}
			subscription={MEAL_PROPOSAL_SUBSCRIPTION}
			variables={{ id }}
			fullWidth
		>
			{({ data: { MealProposals_by_pk } }) =>
				canEdit(MealProposals_by_pk, role) ? (
					<PartnerContextProvider>
						<div className={classes.container}>
							<div className={classes.content}>
								<PageHeader
									headerText={`Manage ${MealProposals_by_pk.orderNumberText}`}
								/>
								<MealOptionTabs
									mealProposal={MealProposals_by_pk}
									ContentComponent={
										MealOptionPurchaseOrderManager
									}
									postTabsContent={
										<Tooltip title="Add New Meal Option">
											<IconButton
												onClick={() =>
													showDialog(
														MealOptionCreateDialogForm,
														{
															id: MealProposals_by_pk.id,
														}
													)
												}
											>
												<Add
													className={
														classes.addButton
													}
												/>
											</IconButton>
										</Tooltip>
									}
									replaceQueryString={true}
								/>
							</div>
							<MealProposalHelpDrawer
								mealProposal={MealProposals_by_pk}
							/>
						</div>
						<Fab
							variant="extended"
							color="primary"
							className={classes.doneButton}
							onClick={() =>
								history.pushNamedRoute('mealProposalsDetail', {
									id,
									tab: 'meal-options',
									...pick(queryString, ['meal-option']),
								})
							}
						>
							<InlineContent>
								<Check />
							</InlineContent>
							Done
						</Fab>
					</PartnerContextProvider>
				) : (
					<Box padding={2}>
						<Alert type="error" size="large">
							You can only edit this meal up to{' '}
							{postDeliveryEditWindowInDays} days after the
							delivery date.
						</Alert>
						<BackButton />
					</Box>
				)
			}
		</Page>
	);
};

export default MealOptionsManage;
