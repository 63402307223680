import React, { useEffect, useState } from 'react';
import { startOfDay, addDays } from 'date-fns';
import { makeStyles } from '@material-ui/core';

import { DashboardDayContextProvider } from 'pages/dashboard/DashboardDayContext';
import DashboardDay from 'pages/dashboard/DashboardDay';
import DateSelector from 'pages/dashboard/DateSelector';
import Page from 'components/layout/Page';

import { useStore } from 'models/store';

const NUMBER_OF_DAYS = 7;
const START_DATE = startOfDay(new Date());

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		height: `calc(100vh - ${theme.sizes.appToolbar}px - ${theme.sizes.footer}px)`,
		justifyContent: 'space-between',
		marginTop: theme.spacing(1),
		overflowX: 'scroll',
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
		},
	},
	dashboardDay: {
		[theme.breakpoints.up('sm')]: {
			minWidth: 450,
			padding: theme.spacing(2),
		},
	},
}));

const Dashboard = () => {
	const { setToolbarContent } = useStore();
	const classes = useStyles();
	const [startDate, setStartDate] = useState(START_DATE);

	const dates = Array(NUMBER_OF_DAYS)
		.fill(startDate)
		.map((today, index) => addDays(today, index));

	useEffect(() => {
		setToolbarContent(
			<DateSelector
				label="Start Of Week"
				value={startDate}
				onChange={setStartDate}
			/>
		);

		return () => setToolbarContent(undefined);
	}, [startDate, setToolbarContent]);

	return (
		<Page title="Dashboard" className={classes.container} fullWidth>
			{dates.map(date => (
				<DashboardDayContextProvider
					date={date}
					key={date.toISOString()}
				>
					<div className={classes.dashboardDay}>
						<DashboardDay />
					</div>
				</DashboardDayContextProvider>
			))}
		</Page>
	);
};

export default Dashboard;
