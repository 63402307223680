import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
	Form,
	GridContainer,
	LinkedSingleSelect,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';

import * as yup from 'models/validation';

const CANCELLATION_REASONS_QUERY = gql`
	query MealProposalCancellationForm {
		CancellationReasons(order_by: { sortOrder: asc }) {
			id
			name
		}
	}
`;

const validationSchema = yup.object().shape({
	CancellationReasonId: yup.string().nullable(),
	cancellationComment: yup.string().when(['CancellationReasonId'], {
		is: reason => reason === undefined,
		then: yup.string().required('Please enter a comment.'),
		otherwise: yup.string(),
	}),
});

const MealProposalCancellationForm = props => {
	const { data, loading } = useQuery(CANCELLATION_REASONS_QUERY);

	return loading ? (
		<CenteredCircularProgress />
	) : (
		<Form enableReinitialize validationSchema={validationSchema} {...props}>
			{() => (
				<>
					<GridContainer>
						<LinkedSingleSelect
							name="CancellationReasonId"
							label="Reason"
							options={[
								...data?.CancellationReasons,
								{
									id: '',
									name: 'Other',
								},
							]}
							getOptionKey={option => option.id}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.id}
						/>
						<LinkedTextInput
							name="cancellationComment"
							label="Comment"
							multiline={true}
						/>
					</GridContainer>
				</>
			)}
		</Form>
	);
};

export default MealProposalCancellationForm;
