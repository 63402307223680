import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/DeliveryGear';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import DeliveryGearForm from 'components/delivery-gear/DeliveryGearForm';

const DELIVERY_GEAR_QUERY = gql`
	query DeliveryGearEdit($id: uuid!) {
		DeliveryGear_by_pk(id: $id) {
			name
			abbreviatedName
			notes
			SporkLocationId
		}
	}
`;

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ DeliveryGear_by_pk: { name } }) => `Edit ${name}`}
			query={DELIVERY_GEAR_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					DeliveryGear_by_pk: { name, ...deliveryGear },
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${name}`} />

					<FormWrapper
						initialValues={{ name, ...deliveryGear }}
						form={DeliveryGearForm}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
