import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
} from '@material-ui/core';
import { Dialog } from '@sporkbytes/material-ui-kit-react';

const useStyles = makeStyles(theme => ({
	dialog: {
		maxWidth: '100vw',
		minHeight: 300,
		minWidth: 400,
	},
	dialogContent: {
		[theme.breakpoints.up('sm')]: {
			alignItems: 'flex-start',
			display: 'flex',
			flexWrap: 'noWrap',
		},
	},
}));

const DriverSchedulesDialog = ({
	additionalActions,
	children,
	title,
	...props
}) => {
	const classes = useStyles();

	return (
		<Dialog {...props} classes={{ paper: classes.dialog }}>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent className={classes.dialogContent}>
				{children}
			</DialogContent>
			<DialogActions>
				{additionalActions}
				<Button onClick={props.onClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
	);
};

DriverSchedulesDialog.propTypes = {
	additionalActions: PropTypes.element,
	title: PropTypes.string,
};

export default DriverSchedulesDialog;
