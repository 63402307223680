import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	Box,
	Card,
	Grid,
	Tooltip,
	Typography,
	makeStyles,
} from '@material-ui/core';
import {
	approve,
	confirm,
	get,
} from '@sporkbytes/api-client/PublicPurchaseOrders';
import {
	Alert,
	Checkbox,
	GridContainer,
} from '@sporkbytes/material-ui-kit-react';
import { canApprove, canConfirm } from '@sporkbytes/purchase-order-utils';

import ApproveButton from 'components/utilities/ApproveButton';
import ApprovedConfirmedStatus from 'components/content/ApprovedConfirmedStatus';
import ConfirmButton from 'components/utilities/ConfirmButton';
import DateOutput from 'components/content/DateOutput';
import HeadCount from 'components/content/HeadCount';
import LabelValue from 'components/content/LabelValue';
import LoadingPage from 'components/content/LoadingPage';
import LogoHeader from 'pages/public/PurchaseOrderDetail/LogoHeader';
import NotFoundPage from 'components/content/NotFoundPage';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PrintButton from 'components/utilities/PrintButton';
import PurchaseOrderPrintableMenu from 'components/purchase-orders/PurchaseOrderPrintableMenu';
import PurchaseOrderSummaryTable from 'components/purchase-orders/purchase-order-summary-table/PurchaseOrderSummaryTable';
import RichText from 'components/content/RichText';

import useRequest from 'hooks/useRequest';

import { getThemedLogo } from 'services/sporkMetadata';
import axios from 'services/data';

const useStyles = makeStyles(theme => ({
	printContainer: {
		background: theme.palette.common.white,
		margin: theme.spacing(4, 0),
		padding: theme.spacing(2),
		'@media print': {
			boxShadow: 'none',
			pageBreakBefore: 'always',
		},
	},
	status: {
		justifyContent: 'center',
		'& > p': {
			fontSize: 24,
		},
		'@media print': {
			display: 'none',
		},
	},
}));

const PurchaseOrderDetail = () => {
	const { id } = useParams();
	const {
		loading,
		data: purchaseOrder,
		refetch,
	} = useRequest(() => get(axios, id));
	const [showPrices, setShowPrices] = useState(true);
	const classes = useStyles();

	if (loading) {
		return <LoadingPage />;
	}

	if (!purchaseOrder) {
		return (
			<NotFoundPage
				body="It's possible that the customer has chosen a different meal option for their event or the purchase order was deleted."
				title="Purchase Order Could Not Be Found"
			/>
		);
	}

	const {
		approved,
		confirmed,
		MealOption: {
			MealProposal: {
				orderNumberText,
				SporkContact,
				headCount,
				status,
				...mealProposal
			},
			...mealOption
		},
		PartnerLocation,
		pickupTime,
	} = purchaseOrder;
	const structureToValidate = {
		approved,
		confirmed,
		MealOption: {
			...mealOption,
			MealProposal: {
				...mealProposal,
				status,
			},
		},
	};

	return (
		<Page title={`Purchase Order ${orderNumberText}`}>
			{status === 'Canceled' && (
				<Alert type="error" size="large">
					This purchase order has been canceled.
				</Alert>
			)}
			<PageHeader headerText={`Purchase Order - ${orderNumberText}`} />{' '}
			<Box displayPrint="none" margin={1}>
				<Grid container alignItems="center" direction="column">
					<PrintButton />
					<Checkbox
						label="Show Prices"
						value={showPrices}
						onChange={setShowPrices}
					/>
				</Grid>
			</Box>
			<Grid container>
				<LogoHeader
					logoUrl={getThemedLogo({ date: pickupTime })}
					alt="Spork Bytes"
					text={
						<LabelValue
							headingLevel={2}
							label="Spork Contact"
							value={
								<Tooltip title={SporkContact.emailAddress}>
									<Typography variant="h5" component="h3">
										<a
											href={`mailto:${SporkContact.emailAddress}`}
										>
											{SporkContact.fullName}
										</a>
									</Typography>
								</Tooltip>
							}
						/>
					}
				/>
				<LogoHeader
					logoUrl={PartnerLocation.Partner.logoUrl}
					alt={PartnerLocation.Partner.name}
					text={
						<>
							<LabelValue
								headingLevel={2}
								label="Pickup Time"
								value={
									<DateOutput
										date={pickupTime}
										timezone={PartnerLocation.timezone}
										variant="h5"
										component="h3"
									/>
								}
							/>
							<LabelValue
								headingLevel={2}
								label="Location"
								value={PartnerLocation.streetAddress}
							/>
						</>
					}
				/>
			</Grid>
			{/* TODO: output the status icon based on something a little more scalable, such as a state machine */}
			{status !== 'Canceled' && (
				<ApprovedConfirmedStatus
					approved={approved}
					confirmed={confirmed}
					className={classes.status}
				/>
			)}
			{canApprove(structureToValidate) && (
				<GridContainer
					justify="center"
					wrapChildrenWithGridItem={false}
				>
					<ApproveButton
						afterApprove={refetch}
						approve={approve}
						id={id}
					/>
				</GridContainer>
			)}
			{canConfirm(structureToValidate) && (
				<GridContainer
					justify="center"
					wrapChildrenWithGridItem={false}
				>
					<ConfirmButton
						afterConfirm={refetch}
						confirm={confirm}
						id={id}
					/>
				</GridContainer>
			)}
			<div>
				<LabelValue
					headingLevel={2}
					label="Head Count"
					value={
						<HeadCount
							value={headCount}
							variant="h5"
							component="h3"
						/>
					}
					inline
				/>
			</div>
			<PurchaseOrderSummaryTable
				purchaseOrder={purchaseOrder}
				costType="paidToPartner"
				showDiscount={false}
				showPrices={showPrices}
			/>
			{mealProposal.dietaryRestrictions ? (
				<Card className={classes.printContainer}>
					<Typography variant="h3" align="center">
						Dietary Restrictions
					</Typography>
					<RichText body={mealProposal.dietaryRestrictions} />
				</Card>
			) : null}
			<Card className={classes.printContainer}>
				<Typography variant="h3" align="center">
					Menu Item Details
				</Typography>
				<PurchaseOrderPrintableMenu
					purchaseOrder={purchaseOrder}
					showLogo={false}
				/>
			</Card>
		</Page>
	);
};

export default PurchaseOrderDetail;
