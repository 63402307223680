import React from 'react';
import gql from 'graphql-tag';
import { Currency } from '@sporkbytes/material-ui-kit-react';
import { getSummationCalculation } from '@sporkbytes/meal-proposal-utils';

import MetricCard from 'components/content/MetricCard';

const MetricCardPartnerPayout = ({ mealProposals }) => {
	const mealCount = mealProposals.length;
	const partnerPayouts = getSummationCalculation(
		'totalReceivedByAllPartners',
		mealProposals
	);

	return (
		<MetricCard
			label="Partner Payout"
			value={<Currency value={partnerPayouts} />}
			footer={
				<>
					<Currency value={partnerPayouts / mealCount} /> / meal
				</>
			}
			showFooter={mealCount > 0}
		/>
	);
};

MetricCardPartnerPayout.fragments = {
	details: gql`
		fragment MetricCardPartnerPayoutDetails on MealProposals {
			MealOptions(where: { approved: { _eq: true } }) {
				PurchaseOrders {
					sporkCommissionPercent
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							discount
							paidToPartner
							quantity
						}
					}
					PurchaseOrderPayoutAdjustments {
						amount
					}
				}
			}
		}
	`,
};

export default MetricCardPartnerPayout;
