import { createBrowserHistory } from 'history';
import { matchPath } from 'react-router-dom';
import qs from 'qs';
import get from 'lodash.get';
import { getBaseClientUrl } from '@sporkbytes/string-utils';

const history = createBrowserHistory();

const routeNameToPathMap = {};

const getNamedRouteLocation = (routeName, parameters = {}) => {
	let pathname = routeNameToPathMap[routeName];

	if (!pathname) {
		return undefined;
	}

	const requiredParameters = pathname
		.split('/')
		.filter(urlSegment => urlSegment.startsWith(':'))
		.map(parameter => parameter.slice(1));

	let search = {};

	Object.entries(parameters).forEach(([parameter, value]) => {
		if (requiredParameters.includes(parameter)) {
			pathname = pathname.replace(`:${parameter}`, value);
		} else {
			search[parameter] = value;
		}
	});

	// For the case that not all required parameters were provided
	return pathname.includes(':')
		? undefined
		: {
				pathname,
				search: qs.stringify(search),
		  };
};

const getNamedRouteUrl = (routeName, parameters = {}) => {
	const { pathname, search } = getNamedRouteLocation(routeName, parameters);

	return `${getBaseClientUrl().slice(0, -1)}${pathname}${
		search ? `?${search}` : ''
	}`;
};

const getPath = routeName => routeNameToPathMap[routeName];

const isOnPublicPage = () => {
	return history.location.pathname.includes('public');
};

const isOnRoute = routeName =>
	!!matchPath(history.location.pathname, {
		path: getPath(routeName),
		exact: true,
	});

const openNamedRoute = (routeName, parameters) => {
	const url = getNamedRouteUrl(routeName, parameters);
	window.open(url);
};

const push = newLocation => {
	const previousLocation = {
		...history.location,
		state: undefined, // we don't need to keep the state of previousLocation
	};

	if (typeof newLocation === 'object') {
		newLocation.state = {
			...newLocation.state,
			previousLocation,
		};
	} else {
		newLocation = {
			pathname: newLocation,
			state: {
				previousLocation,
			},
		};
	}

	history.push(newLocation);
};

const push404 = () => push('/404');

const pushNamedRoute = (name, parameters) => {
	const newLocation = getNamedRouteLocation(name, parameters);

	if (!newLocation) {
		push404();
	} else {
		push(newLocation);
	}
};

const pushPreviousRoute = () => {
	const previousLocation = get(history, 'location.state.previousLocation');

	push(previousLocation || '/');
};

const registerRoutes = routes =>
	routes.forEach(({ name, path }) => {
		routeNameToPathMap[name] = path;
	});

export default {
	...history,
	getNamedRouteLocation,
	getNamedRouteUrl,
	getPath,
	isOnPublicPage,
	isOnRoute,
	openNamedRoute,
	push,
	push404,
	pushNamedRoute,
	pushPreviousRoute,
	registerRoutes,
};
