import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
	Card,
	CardContent,
	IconButton,
	InputAdornment,
} from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import { getPartnerMenuItems } from '@sporkbytes/api-client/PublicPartners';
import {
	GridContainer,
	MultiSelect,
	SingleSelect,
	TextInput,
} from '@sporkbytes/material-ui-kit-react';

import MenuItemList from './MenuItemList';

import useRequest from 'hooks/useRequest';

import axios from 'services/data';

const activeFilterOptions = [
	{
		label: 'Active',
		value: [true],
	},
	{
		label: 'Inactive',
		value: [false],
	},
	{
		label: 'All',
		value: [true, false],
	},
];

const MenuItemAuditing = () => {
	const [activeFilter, setActiveFilter] = useState(activeFilterOptions[0]);
	const [selectedDietaryTags, setSelectedDietaryTags] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const { publicProfileId } = useParams();

	const { data, loading } = useRequest(
		() =>
			getPartnerMenuItems(axios, publicProfileId, {
				active: activeFilter.value,
				dietaryTags: selectedDietaryTags,
				q: searchQuery,
			}),
		[activeFilter, selectedDietaryTags, searchQuery],
		500
	);

	const { data: dietaryTagData, loading: loadingDietaryTagData } = useQuery(
		gql`
			query PublicPartnerMenuItemAuditing_GetDietaryTags {
				DietaryTags(order_by: { sortOrder: asc }) {
					id
					name
				}
			}
		`
	);

	return (
		<Card>
			<CardContent>
				<GridContainer columns={3}>
					<SingleSelect
						getOptionKey={({ label }) => label}
						getOptionLabel={({ label }) => label}
						onChange={setActiveFilter}
						options={activeFilterOptions}
						value={activeFilter}
						label="Items"
					/>
					{loadingDietaryTagData ? null : (
						<MultiSelect
							label="Dietary Tags"
							options={dietaryTagData.DietaryTags}
							getOptionKey={tag => tag.id}
							getOptionLabel={tag => tag.name}
							getOptionValue={tag => tag.id}
							onChange={setSelectedDietaryTags}
							value={selectedDietaryTags}
						/>
					)}
					{/* the InputProps and label props are set to match the search box design in RemoteTable */}
					<TextInput
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="Clear search field"
										onClick={() => setSearchQuery('')}
									>
										<Clear />
									</IconButton>
								</InputAdornment>
							),
							placeholder: 'Search',
							startAdornment: (
								<InputAdornment position="start">
									<Search fontSize="small" />
								</InputAdornment>
							),
						}}
						label=" "
						onChange={e => {
							setSearchQuery(e.target.value);
						}}
						value={searchQuery}
					/>
				</GridContainer>
				<MenuItemList data={data} loading={loading} />
			</CardContent>
		</Card>
	);
};

export default MenuItemAuditing;
