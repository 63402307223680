import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { put, sort } from '@sporkbytes/api-client/SporkReviewQuestions';

import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import EditableSortOrder from 'components/forms/EditableSortOrder';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import RemoteTable from 'components/content/RemoteTable';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const getSporkReviewQuestionsQuery = searchTerm =>
	gql`
		query SporkReviewQuestions(
			$activeOrderBy: order_by
			$questionOrderBy: order_by
			$requiredOrderBy: order_by
			$sortOrderOrderBy: order_by
			$limit: Int!
			$offset: Int!
		) {
			SporkReviewQuestions(
 				where: { question: { _ilike: "%${searchTerm}%" } }
				order_by: {
					active: $activeOrderBy
					question: $questionOrderBy
					required: $requiredOrderBy
					sortOrder: $sortOrderOrderBy
				}
				limit: $limit
				offset: $offset
			) {
				id
				active
				question
				required
				sortOrder
			}
			SporkReviewQuestions_aggregate(
 				where: { question: { _ilike: "%${searchTerm}%" } }
			) {
				aggregate {
					count
				}
			}
		}
	`;

const Detail = () => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<Page title="Spork Review Questions">
			<PageHeader headerText="Spork Review Questions" />
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Sort Order',
						field: 'sortOrder',
						render: ({ id, sortOrder }) => (
							<EditableSortOrder
								onSave={value =>
									sort(axios, id, {
										newValue: value,
									}).then(ref.current.onQueryChange)
								}
								permissions={['spork-review-questions:edit']}
								required={true}
								value={sortOrder}
							/>
						),
					},
					{
						title: 'Question',
						field: 'question',
					},
					{
						title: 'Required',
						field: 'required',
						render: ({ id, required }) => (
							<BooleanFieldSwitch
								afterChange={ref.current.onQueryChange}
								columnToEdit="required"
								entityId={id}
								entityType="sporkReviewQuestions"
								updateEntity={put}
								value={required}
							/>
						),
					},
					{
						title: 'Active',
						field: 'active',
						render: ({ active, id }) => (
							<BooleanFieldSwitch
								afterChange={ref.current.onQueryChange}
								entityId={id}
								entityType="sporkReviewQuestions"
								updateEntity={put}
								value={active}
							/>
						),
					},
				]}
				getQuery={getSporkReviewQuestionsQuery}
				defaultOrderBy="sortOrder"
				defaultOrderDirection="asc"
				queryEntity="SporkReviewQuestions"
				addNewEntityRouteName="sporkReviewQuestionsCreate"
				entityToAddName="Spork Review Question"
				canAddNewEntity={userCan(['spork-review-questions:create'])}
			/>
		</Page>
	);
};

export default Detail;
