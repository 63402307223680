import { copyText } from 'services/utilities';

import showPurchaseOrderSelectionDialog from '../showPurchaseOrderSelectionDialog';

export default ({ history, id, setLoading }) => async () => {
	await showPurchaseOrderSelectionDialog({
		MealProposalId: id,
		onPurchaseOrderSelect: selectedPurchaseOrderId => {
			copyText(
				history.getNamedRouteUrl('publicPurchaseOrdersDetail', {
					id: selectedPurchaseOrderId,
				})
			);
		},
		setLoading,
	});
};
