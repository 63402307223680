import React from 'react';
import { Card, CardContent } from '@material-ui/core';

const TabContainer = ({ children }) => {
	return (
		<Card>
			<CardContent>{children}</CardContent>
		</Card>
	);
};

export default TabContainer;
