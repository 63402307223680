import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import {
	AddBox,
	ArrowUpward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
} from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core';

// This is needed to get the proper icons to show https://github.com/mbrn/material-table/issues/51#issuecomment-508384214
const tableIcons = [
	['Add', AddBox],
	['Check', Check],
	['Clear', Clear],
	['Delete', DeleteOutline],
	['DetailPanel', ChevronRight],
	['Edit', Edit],
	['Export', SaveAlt],
	['Filter', FilterList],
	['FirstPage', FirstPage],
	['LastPage', LastPage],
	['NextPage', ChevronRight],
	['PreviousPage', ChevronLeft],
	['ResetSearch', Clear],
	['Search', Search],
	['SortArrow', ArrowUpward],
	['ThirdStateCheck', Remove],
	['ViewColumn', ViewColumn],
].reduce(
	(tableIcons, { tableIconName, IconToUse }) => ({
		...tableIcons,
		[tableIconName]: forwardRef((props, ref) => (
			<IconToUse {...props} ref={ref} />
		)),
	}),
	{}
);

const useStyles = makeStyles(theme => ({
	container: {
		background: theme.palette.common.white,
	},
}));

const Table = ({ options = {}, columns, ...props }) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<MaterialTable
			icons={tableIcons}
			columns={columns.filter(({ hidden }) => !hidden)}
			options={{
				showTitle: false,
				debounceInterval: 300,
				emptyRowsWhenPaging: false,
				actionsColumnIndex: -1,
				draggable: false,
				thirdSortClick: false,
				headerStyle: {
					...theme.overrides.MuiTableHead.root,
					position: 'static',
				},
				pageSize: 10,
				pageSizeOptions: [10, 25, 50],
				...options,
			}}
			components={{
				Container: props => (
					<div className={classes.container} {...props}></div>
				),
			}}
			{...props}
		/>
	);
};

export default Table;
