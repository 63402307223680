import React from 'react';
import { post } from '@sporkbytes/api-client/DeliveryDrivers';

import DeliveryDriverForm from 'components/delivery-drivers/DeliveryDriverForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { useStore } from 'models/store';

const Create = () => {
	const { selectedSporkLocationId } = useStore();
	const initialValues = {
		SporkLocationId: selectedSporkLocationId,
	};

	return (
		<Page title="Add New Delivery Driver">
			<PageHeader headerText="Add New Delivery Driver" />
			<FormWrapper
				form={DeliveryDriverForm}
				initialValues={initialValues}
				onSubmit={post}
			/>
		</Page>
	);
};

export default Create;
