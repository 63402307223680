import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { setDeliveryDrivers } from '@sporkbytes/api-client/MealProposals';

import ChecklistDialogForm from 'components/forms/ChecklistDialogForm';

import axios from 'services/data';

const DELIVERY_DRIVERS_QUERY = gql`
	query MealProposalAssignDeliveryDriversDialog(
		$id: uuid!
		$SporkLocationId: uuid!
	) {
		MealProposals_by_pk(id: $id) {
			MealProposalsDeliveryDrivers {
				DeliveryDriver {
					abbreviatedName
					id
				}
			}
		}
		DeliveryDrivers(
			where: {
				_or: [
					{ active: { _eq: true } }
					{
						MealProposalsDeliveryDrivers: {
							MealProposalId: { _eq: $id }
						}
					}
				]
				SporkLocationId: { _eq: $SporkLocationId }
			}
			order_by: { firstName: asc }
		) {
			abbreviatedName
			id
		}
	}
`;

const MealProposalAssignDeliveryDriversDialog = ({
	id,
	SporkLocationId,
	...props
}) => {
	const { data, loading } = useQuery(DELIVERY_DRIVERS_QUERY, {
		variables: {
			id,
			SporkLocationId,
		},
	});

	return (
		<ChecklistDialogForm
			{...props}
			title="Assign Delivery Drivers to this Meal Proposal"
			submitButtonContent="Assign"
			loading={loading}
			initialValues={data?.MealProposals_by_pk.MealProposalsDeliveryDrivers.map(
				({ DeliveryDriver: { id } }) => id
			)}
			options={data?.DeliveryDrivers.map(({ abbreviatedName, id }) => ({
				id,
				label: abbreviatedName,
			}))}
			onSubmit={deliveryDriverIds =>
				setDeliveryDrivers(axios, id, {
					data: deliveryDriverIds.map(id => ({
						id,
					})),
				})
			}
		/>
	);
};

MealProposalAssignDeliveryDriversDialog.propTypes = {
	id: PropTypes.string.isRequired,
	SporkLocationId: PropTypes.string.isRequired,
};

export default MealProposalAssignDeliveryDriversDialog;
