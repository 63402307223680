import React from 'react';
import gql from 'graphql-tag';
import { getIncludedContacts } from '@sporkbytes/purchase-order-utils';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import SendEmailDialogForm from 'components/email-templates/SendEmailDialogForm';

import { store } from 'models/store';

import { formatZonedTime } from 'services/dates';
import apolloClient from 'services/apollo';
import axios from 'services/data';

const MEAL_PROPOSAL_QUERY = gql`
	query RequestPartnerResponse(
		$id: uuid!
		$purchaseOrderApproved: Boolean
		$purchaseOrderConfirmed: Boolean
	) {
		MealProposals_by_pk(id: $id) {
			orderNumberText
			SporkContact {
				firstName
			}
			MealOptions(
				where: {
					approved: { _eq: true }
					PurchaseOrders: {
						approved: { _eq: $purchaseOrderApproved }
						confirmed: { _eq: $purchaseOrderConfirmed }
					}
				}
			) {
				PurchaseOrders(
					where: {
						approved: { _eq: $purchaseOrderApproved }
						confirmed: { _eq: $purchaseOrderConfirmed }
					}
				) {
					id
					pickupTime
					PartnerLocation {
						timezone
					}
					PurchaseOrdersContacts {
						Contact {
							firstName
						}
					}
				}
			}
		}
	}
`;

export default async ({ emailTemplateId, sendEmail, variables }) => {
	const { data } = await apolloClient.query({
		query: MEAL_PROPOSAL_QUERY,
		variables,
	});
	const { showDialogs, showSuccessNotification } = store.getState();

	return showDialogs(
		data.MealProposals_by_pk.MealOptions[0].PurchaseOrders.map(
			purchaseOrder =>
				({ showNextDialog, ...props }) =>
					(
						<SendEmailDialogForm
							emailTemplateId={emailTemplateId}
							substitutions={{
								'-orderNumber-':
									data.MealProposals_by_pk.orderNumberText,
								'-pickupDate-': formatZonedTime(
									purchaseOrder.pickupTime,
									'date',
									purchaseOrder.PartnerLocation.timezone
								),
								'-pickupTime-': formatZonedTime(
									purchaseOrder.pickupTime,
									'time',
									purchaseOrder.PartnerLocation.timezone
								),
								'-recipientFirstNames-': makeCommaSeparatedList(
									getIncludedContacts(purchaseOrder).map(
										({ firstName }) => firstName
									)
								),
								'-sporkContactFirstName-':
									data.MealProposals_by_pk.SporkContact
										.firstName,
							}}
							onSubmit={async values => {
								try {
									await sendEmail(
										axios,
										purchaseOrder.id,
										values
									).then(showSuccessNotification);
								} finally {
									showNextDialog();
								}
							}}
							{...props}
						/>
					)
		)
	);
};
