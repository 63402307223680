import React from 'react';
import gql from 'graphql-tag';
import { Star } from '@material-ui/icons';
import { Integer } from '@sporkbytes/material-ui-kit-react';
import { roundNumberToDigits } from '@sporkbytes/math-utils';

import InlineContent from 'components/content/InlineContent';
import MetricCard from 'components/content/MetricCard';

const MetricCardPurchaseOrderReviews = ({ mealProposals }) => {
	const purchaseOrderReviews = mealProposals.flatMap(
		({ PurchaseOrderReviews }) =>
			PurchaseOrderReviews.map(({ rating }) => rating)
	);

	const purchaseOrderReviewsCount = purchaseOrderReviews.length;

	const averageRating = roundNumberToDigits(
		purchaseOrderReviews.reduce((sum, rating) => sum + rating, 0) /
			purchaseOrderReviews.length,
		1
	);

	return (
		<MetricCard
			label="Partner Reviews"
			value={<Integer value={purchaseOrderReviewsCount} />}
			footer={
				<div>
					<InlineContent>{averageRating}</InlineContent>
					<InlineContent>
						<Star />
					</InlineContent>
					(average)
				</div>
			}
			showFooter={purchaseOrderReviewsCount > 0}
		/>
	);
};

MetricCardPurchaseOrderReviews.fragments = {
	details: gql`
		fragment MetricCardPurchaseOrderReviewsDetails on MealProposals {
			PurchaseOrderReviews {
				rating
			}
		}
	`,
};

export default MetricCardPurchaseOrderReviews;
