import React from 'react';
import { useParams } from 'react-router-dom';
import { subMinutes } from 'date-fns';
import gql from 'graphql-tag';
import { post } from '@sporkbytes/api-client/PurchaseOrders';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PurchaseOrderForm from 'components/purchase-orders/PurchaseOrderForm';

const MEAL_OPTION_QUERY = gql`
	query PurchaseOrderCreate($MealOptionId: uuid!) {
		MealOptions_by_pk(id: $MealOptionId) {
			...PurchaseOrderFormSporkLocationFilter
			...PurchaseOrderFormSubheader
			MealProposal {
				id
				deliveryDate
				SporkLocation {
					sporkCommissionPercent
				}
			}
		}
	}
	${PurchaseOrderForm.fragments.sporkLocationFilter}
	${PurchaseOrderForm.fragments.subheader}
`;

const PurchaseOrderCreate = () => {
	const { id: MealOptionId } = useParams();

	return (
		<Page
			title="New Purchase Order"
			query={MEAL_OPTION_QUERY}
			variables={{ MealOptionId }}
		>
			{({ data: { MealOptions_by_pk: MealOption } }) => {
				let pickupTime = subMinutes(
					new Date(MealOption.MealProposal.deliveryDate),
					45
				);

				return (
					<FormWrapper
						form={PurchaseOrderForm}
						initialValues={{
							pickupTime,
							sporkCommissionPercent:
								MealOption.MealProposal.SporkLocation
									.sporkCommissionPercent,
						}}
						pageSubheaderText="New Purchase Order"
						onSubmit={(axios, values) =>
							post(axios, { MealOptionId, ...values })
						}
						mealOption={MealOption}
						redirectToRoute="mealProposalsMealOptionsManage"
						routeVariables={({ id }) => ({
							id: MealOption.MealProposal.id,
							'meal-option': MealOptionId,
							'purchase-order': id,
						})}
					/>
				);
			}}
		</Page>
	);
};

export default PurchaseOrderCreate;
