import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Typography } from '@material-ui/core';
import { calculate } from '@sporkbytes/api-client/Distances';

import DriverSchedule from 'pages/dashboard/DriverSchedule';
import DriverSchedulesDialog from 'pages/dashboard/DriverSchedulesDialog';

import apolloClient from 'services/apollo';
import axios from 'services/data';

import useRequest from 'hooks/useRequest';

const MEAL_PROPOSALS_QUERY = gql`
	query CurrentDriverSchedulesDialog($ids: [uuid!]!) {
		MealProposals(where: { id: { _in: $ids } }) {
			MealProposalsDeliveryDrivers {
				DeliveryDriver {
					id
					abbreviatedName
				}
			}
			...DriverSchedule
		}
	}
	${DriverSchedule.fragments.details}
`;

const CurrentDriverSchedulesDialog = ({ currentDriverSchedules, ...props }) => {
	const {
		data: [
			distances,
			{
				data: { MealProposals },
			},
		] = [{}, { data: {} }],
		loading,
		error,
	} = useRequest(() =>
		Promise.all([
			calculate(axios, {
				groupedMealProposalIds: currentDriverSchedules.map(
					({ mealProposalIds }) => mealProposalIds
				),
			}),
			apolloClient.query({
				query: MEAL_PROPOSALS_QUERY,
				variables: {
					ids: currentDriverSchedules.flatMap(
						({ mealProposalIds }) => mealProposalIds
					),
				},
			}),
		])
	);

	return (
		<DriverSchedulesDialog
			{...props}
			open={!!error ? false : props.open}
			loading={loading}
		>
			{MealProposals &&
				currentDriverSchedules.map(
					({ mealProposalIds, deliveryDriver }) => (
						<DriverSchedule
							key={deliveryDriver.id}
							distances={distances}
							mealProposals={mealProposalIds.map(id =>
								MealProposals.find(
									mealProposal => mealProposal.id === id
								)
							)}
							header={
								<Typography variant="h5">
									{deliveryDriver.abbreviatedName}
								</Typography>
							}
						/>
					)
				)}
		</DriverSchedulesDialog>
	);
};

CurrentDriverSchedulesDialog.propTypes = {
	currentDriverSchedules: PropTypes.arrayOf(
		PropTypes.shape({
			mealProposalIds: PropTypes.arrayOf(PropTypes.string).isRequired,
			deliveryDriver: PropTypes.shape({
				id: PropTypes.string.isRequired,
				abbreviatedName: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
};

export default CurrentDriverSchedulesDialog;
