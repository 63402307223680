import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { Card, CardContent } from '@material-ui/core';
import { SupervisorAccount } from '@material-ui/icons';
import { put } from '@sporkbytes/api-client/PartnerMenuItems';

import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PartnerMenuItemActionMenu from 'components/partner-menu-items/PartnerMenuItemActionMenu';
import PartnerMenuItemDetails from 'components/partner-menu-items/PartnerMenuItemDetails';
import PartnerMenuItemOptionGroupsTable from 'components/partner-menu-item-option-groups/PartnerMenuItemOptionGroupsTable';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';
import ReassignParentPartnerMenuItemDialog from 'components/parent-partner-menu-items/ReassignParentPartnerMenuItemDialog';
import RibbonContainer from 'components/content/RibbonContainer';

import { useStore } from 'models/store';

import { createToggleActiveStatusAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';

const PARTNER_MENU_ITEM_QUERY = gql`
	query PartnerMenuItemDetails($id: uuid!) {
		PartnerMenuItems_by_pk(id: $id) {
			active
			id
			...PartnerMenuItemActionMenuDetails
			...PartnerMenuItemDetails
			Partner {
				id
				logoUrl
				name
			}
		}
	}
	${PartnerMenuItemActionMenu.fragments.details}
	${PartnerMenuItemDetails.fragments.details}
`;

const Detail = () => {
	const { id } = useParams();
	const { userCan } = useAuth();
	const { showDialog } = useStore();

	return (
		<Page
			query={PARTNER_MENU_ITEM_QUERY}
			variables={{ id }}
			title={({ PartnerMenuItems_by_pk: { name } }) => name}
		>
			{({
				data: { PartnerMenuItems_by_pk: partnerMenuItem },
				refetch,
			}) => (
				<>
					<RibbonContainer
						show={!partnerMenuItem.active}
						title="inactive"
						color="error"
					>
						<PageHeader
							imgUrl={partnerMenuItem.Partner.logoUrl}
							headerText={partnerMenuItem.Partner.name}
							linkProps={{
								routeName: 'partnersDetail',
								variables: {
									id: partnerMenuItem.Partner.id,
									tab: 'menu',
								},
								permissions: ['partners:view'],
							}}
							actionButton={
								<PartnerMenuItemActionMenu
									partnerMenuItem={partnerMenuItem}
									additionalActions={({ setLoading }) => [
										{
											icon: <SupervisorAccount />,
											label: 'Reassign Parent',
											onClick: () =>
												showDialog(
													ReassignParentPartnerMenuItemDialog,
													{
														PartnerId:
															partnerMenuItem
																.Partner.id,
														PartnerMenuItemId: id,
														ParentPartnerMenuItemId:
															partnerMenuItem
																.ParentPartnerMenuItem
																.id,
														onReassign: () =>
															refetch({ id }),
													}
												),
											canPerformAction: userCan([
												'partner-menu-items:edit',
											]),
										},
										createToggleActiveStatusAction({
											active: partnerMenuItem.active,
											entityId: id,
											entityName: partnerMenuItem.name,
											entityType: 'PartnerMenuItems',
											onActionComplete: () =>
												refetch({ id }),
											setLoading,
											updateEntity: put,
											userCan,
										}),
									]}
								/>
							}
						/>
						<PageSubheader>{partnerMenuItem.name}</PageSubheader>
					</RibbonContainer>
					<Card>
						<QueryStringSyncedTabs
							id="tab"
							tabs={[
								{
									id: 'details',
									label: 'Details',
									content: (
										<CardContent>
											<PartnerMenuItemDetails
												partnerMenuItem={
													partnerMenuItem
												}
											/>
										</CardContent>
									),
								},
								{
									id: 'option-groups',
									label: 'Option Groups',
									content: (
										<PartnerMenuItemOptionGroupsTable
											PartnerMenuItemId={
												partnerMenuItem.id
											}
											PartnerId={
												partnerMenuItem.Partner.id
											}
										/>
									),
								},
							]}
						/>
					</Card>
				</>
			)}
		</Page>
	);
};

export default Detail;
