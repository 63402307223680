import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { addNewContact } from '@sporkbytes/api-client/MealProposals';

import ContactForm from 'components/contacts/ContactForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

const MEAL_PROPOSAL_QUERY = gql`
	query ContactCreate($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			orderNumberText
			ClientLocation {
				Client {
					logoUrl
					name
				}
			}
		}
	}
`;

const ContactCreate = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ MealProposals_by_pk: { orderNumberText } }) =>
				`New Contact for ${orderNumberText}`
			}
			query={MEAL_PROPOSAL_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					MealProposals_by_pk: {
						orderNumberText,
						ClientLocation: { Client },
					},
				},
			}) => (
				<>
					<PageHeader
						imgUrl={Client.logoUrl}
						headerText={Client.name}
					/>
					<PageSubheader>
						Add New Contact to Meal Proposal {orderNumberText}
					</PageSubheader>
					<FormWrapper
						form={ContactForm}
						onSubmit={(axios, values) =>
							addNewContact(axios, id, values)
						}
					/>
				</>
			)}
		</Page>
	);
};

export default ContactCreate;
