import React from 'react';
import gql from 'graphql-tag';
import { Integer } from '@sporkbytes/material-ui-kit-react';
import { headCount as getHeadCount } from '@sporkbytes/meal-proposal-utils';

import MetricCard from 'components/content/MetricCard';

const MetricCardHeadCount = ({ mealProposals }) => {
	const headCount = getHeadCount(mealProposals) || 0;
	const mealCount = mealProposals.length;

	return (
		<MetricCard
			label="People"
			value={<Integer value={headCount} />}
			footer={
				<>
					<Integer value={headCount / mealCount} /> / meal
				</>
			}
			showFooter={mealCount > 0}
		/>
	);
};

MetricCardHeadCount.fragments = {
	details: gql`
		fragment MetricCardHeadCountDetails on MealProposals {
			headCount
		}
	`,
};

export default MetricCardHeadCount;
