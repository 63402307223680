import React from 'react';
import gql from 'graphql-tag';
import { TableRow } from '@material-ui/core';
import { Currency } from '@sporkbytes/material-ui-kit-react';
import { totalClientPays, totalDiscount } from '@sporkbytes/meal-option-utils';

import TableCellFeatured from 'components/content/TableCellFeatured';
import TableCellNumeric from 'components/content/TableCellNumeric';

const MealOptionTotalClientPaysLineItem = ({ mealOption }) => {
	const discount = totalDiscount(mealOption, mealOption.MealProposal);

	return (
		<TableRow>
			<TableCellFeatured colSpan="3">
				Total Due
				{discount > 0 && (
					<>
						{' '}
						(includes a discount of <Currency value={discount} />)
					</>
				)}
			</TableCellFeatured>
			<TableCellNumeric
				format="currency"
				value={totalClientPays(mealOption, mealOption.MealProposal)}
			/>
		</TableRow>
	);
};

MealOptionTotalClientPaysLineItem.fragments = {
	details: gql`
		fragment MealOptionTotalClientPaysLineItemDetails on MealOptions {
			PurchaseOrders(order_by: { pickupTime: asc }) {
				PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
					PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
						costToClient
						discount
						quantity
					}
				}
			}
			MealProposal {
				headCount
				MealProposalFees {
					amount
					discount
					type
				}
			}
		}
	`,
};

export default MealOptionTotalClientPaysLineItem;
