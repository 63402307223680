import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		fontWeight: 'bold',
	},
}));

const TableCellFeatured = ({ children, ...props }) => {
	const classes = useStyles();

	return (
		<TableCell className={classes.container} {...props}>
			{children}
		</TableCell>
	);
};

export default TableCellFeatured;
