import React from 'react';
import { addDays, format, startOfMonth } from 'date-fns';
import {
	AttachMoney,
	BarChart,
	Build,
	Business,
	Cancel,
	Category,
	DirectionsCar,
	Email,
	Home,
	Label,
	LocalOffer,
	LocalPizza,
	Lock,
	Map,
	People,
	Restaurant,
	Settings,
	ShoppingBasket,
	Star,
	Sync,
	TableChart,
} from '@material-ui/icons';
import { buildMarketingSite } from '@sporkbytes/api-client/Administration';

import { store } from 'models/store';

import axios from 'services/data';

export default [
	{
		text: 'Home',
		icon: <Home />,
		routeName: 'home',
	},
	{
		text: 'Dashboard',
		icon: <TableChart />,
		routeName: 'dashboard',
		openInNewWindow: true,
		permissions: ['meal-proposals:view'],
	},
	{
		text: 'Analytics',
		icon: <BarChart />,
		routeName: 'analytics',
		permissions: ['analytics:view'],
	},
	{
		text: 'Delivery Drivers',
		icon: <DirectionsCar />,
		routeName: 'deliveryDrivers',
		permissions: ['delivery-drivers:view'],
	},
	{
		text: 'Partners',
		icon: <Restaurant />,
		routeName: 'partners',
		permissions: ['partners:view'],
	},
	{
		text: 'Contacts',
		icon: <People />,
		routeName: 'contacts',
		permissions: ['contacts:view'],
	},
	{
		text: 'Email Activity Logs',
		icon: <Email />,
		routeName: 'emailActivityLogs',
		permissions: ['email-activity-logs:view'],
	},
	{
		text: 'Featured Partners',
		icon: <Star />,
		routeName: 'featuredPartners',
		pathVariables: () => ({
			month: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
		}),
		permissions: ['featured-partners:create', 'featured-partners:edit'],
	},
	{
		text: 'Labels',
		icon: <Label />,
		routeName: 'printableLabelsDay',
		pathVariables: () => ({
			day: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
		}),
		permissions: ['meal-proposals:view', 'partner-menu-items:view'],
	},
	{
		text: 'Bag Tags',
		icon: <LocalOffer />,
		routeName: 'bagTagsDay',
		pathVariables: () => ({
			day: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
		}),
		permissions: ['meal-proposals:view'],
	},
	{
		text: 'Accounting',
		icon: <AttachMoney />,
		routeName: 'accountingHome',
		permissions: ['accounting:connect'],
	},
	{
		text: 'HubSpot Syncs',
		icon: <Sync />,
		routeName: 'mealProposalHubSpotSyncs',
		permissions: ['meal-proposal-hub-spot-syncs:view'],
	},
	{
		type: 'list',
		text: 'Administration',
		icon: <Lock />,
		navItems: [
			{
				text: 'Build Marketing Site',
				icon: <Build />,
				onClick: () =>
					buildMarketingSite(axios).then(
						store.getState().showSuccessNotification
					),
				permissions: ['administration:marketing-site:build'],
			},
			{
				text: 'Serviced States',
				icon: <Map />,
				routeName: 'servicedStates',
				permissions: ['serviced-states:view'],
			},
			{
				text: 'Spork Locations',
				icon: <Business />,
				routeName: 'sporkLocations',
				permissions: ['spork-locations:view'],
			},
			{
				text: 'Users',
				icon: <People />,
				routeName: 'users',
				permissions: ['users:view'],
			},
		],
	},
	{
		type: 'list',
		text: 'Configuration',
		icon: <Settings />,
		navItems: [
			{
				text: 'Cancellation Reasons',
				icon: <Cancel />,
				routeName: 'cancellationReasons',
				permissions: ['cancellation-reasons:view'],
			},
			{
				text: 'Delivery Gear',
				icon: <ShoppingBasket />,
				routeName: 'deliveryGearTypes',
				permissions: ['delivery-gear-types:view'],
			},
			{
				text: 'Dietary Tags',
				icon: <LocalOffer />,
				routeName: 'dietaryTags',
				permissions: ['dietary-tags:view'],
			},
			{
				text: 'Email Templates',
				icon: <Email />,
				routeName: 'emailTemplates',
				permissions: ['email-templates:view'],
			},
			{
				text: 'Fees',
				icon: <AttachMoney />,
				routeName: 'fees',
				permissions: ['fees:view'],
			},
			{
				text: 'Meal Categories',
				icon: <Category />,
				routeName: 'mealCategories',
				permissions: ['meal-categories:view'],
			},
			{
				text: 'Meal Types',
				icon: <LocalPizza />,
				routeName: 'mealTypes',
				permissions: ['meal-types:view'],
			},
			{
				text: 'Partner Tags',
				icon: <LocalOffer />,
				routeName: 'partnerTags',
				permissions: ['partner-tags:view'],
			},
			{
				text: 'Spork Review Questions',
				icon: <Star />,
				routeName: 'sporkReviewQuestions',
				permissions: ['spork-review-questions:view'],
			},
		],
	},
];
