import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';

import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PartnerListMenuActionMenu from 'components/partner-list-menus/PartnerListMenuActionMenu';
import PartnerListMenuSectionsTable from 'components/partner-list-menu-sections/PartnerListMenuSectionsTable';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';

const PARTNER_MENU_QUERY = gql`
	query PartnerListMenuDetails($id: uuid!) {
		PartnerListMenus_by_pk(id: $id) {
			id
			name
			Partner {
				id
				logoUrl
				name
			}
		}
	}
`;

const Detail = () => {
	const { id } = useParams();

	return (
		<Page
			query={PARTNER_MENU_QUERY}
			variables={{ id }}
			title={({ PartnerListMenus_by_pk: { name } }) => name}
		>
			{({
				data: {
					PartnerListMenus_by_pk: { Partner, ...partnerListMenu },
				},
				refetch,
			}) => (
				<>
					<PageHeader
						imgUrl={Partner.logoUrl}
						headerText={Partner.name}
						linkProps={{
							routeName: 'partnersDetail',
							variables: {
								id: Partner.id,
								tab: 'partner-list-menus',
							},
							permissions: ['partners:view'],
						}}
						actionButton={
							<PartnerListMenuActionMenu
								partnerListMenu={partnerListMenu}
								onActionComplete={() => refetch({ id })}
							/>
						}
					/>
					<PageSubheader>{partnerListMenu.name}</PageSubheader>
					<QueryStringSyncedTabs
						id="tab"
						tabs={[
							{
								id: 'sections',
								label: 'Sections',
								content: (
									<PartnerListMenuSectionsTable
										PartnerListMenuId={id}
										PartnerId={Partner.id}
									/>
								),
							},
						]}
					></QueryStringSyncedTabs>
				</>
			)}
		</Page>
	);
};

export default Detail;
