import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		'& + &': {
			marginTop: theme.spacing(2),
		},
	},
}));

const WidgetRow = ({ children }) => {
	const classes = useStyles();

	return (
		<Grid container spacing={2} className={classes.container}>
			{children}
		</Grid>
	);
};

export default WidgetRow;
