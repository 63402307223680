import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { PrimaryButton } from '@sporkbytes/material-ui-kit-react';

export default () => {
	const history = useHistory();

	return (
		<>
			<Typography
				color="inherit"
				component="h1"
				variant="h5"
				gutterBottom
			>
				Something Went Wrong
			</Typography>
			<PrimaryButton
				onClick={() => {
					history.pushNamedRoute('home');
				}}
			>
				Go Home
			</PrimaryButton>
		</>
	);
};
