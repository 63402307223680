import React from 'react';
import { post } from '@sporkbytes/api-client/Contacts';

import ContactForm from 'components/contacts/ContactForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const Create = () => {
	return (
		<Page title="New Contact">
			<PageHeader headerText="New Contact" />
			<FormWrapper
				form={ContactForm}
				onSubmit={(axios, values) => post(axios, values)}
				redirectToRoute="contacts"
			/>
		</Page>
	);
};

export default Create;
