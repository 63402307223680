import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import {
	Table,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';

import MealOptionCostPerPersonLineItem from 'components/meal-options/MealOptionCostPerPersonLineItem';
import MealOptionFoodSubtotalLineItem from 'components/meal-options/MealOptionFoodSubtotalLineItem';
import MealOptionGratuityLineItem from 'components/meal-options/MealOptionGratuityLineItem';
import MealOptionGrossMarginLineItem from 'components/meal-options/MealOptionGrossMarginLineItem';
import MealOptionSporkCommissionLineItem from 'components/meal-options/MealOptionSporkCommissionLineItem';
import MealOptionTotalClientPaysLineItem from 'components/meal-options/MealOptionTotalClientPaysLineItem';
import MealOptionTotalReceivedByAllPartnersLineItem from 'components/meal-options/MealOptionTotalReceivedByAllPartnersLineItem';
import MealOptionTotalReceivedBySporkLineItem from 'components/meal-options/MealOptionTotalReceivedBySporkLineItem';
import MealProposalFeeLineItem from 'components/meal-proposal-fees/MealProposalFeeLineItem';

const MealOptionSummaryTable = ({
	mealOption,
	showPartnerMetrics,
	showSporkMetrics,
	titleProps,
}) => {
	return (
		<>
			<Typography
				component="h3"
				variant="h6"
				gutterBottom
				{...titleProps}
			>
				{mealOption.name} Summary
			</Typography>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Item</TableCell>
							<TableCell align="right">Cost</TableCell>
							<TableCell align="right">Discount</TableCell>
							<TableCell align="right">Total</TableCell>
						</TableRow>
					</TableHead>
					<TableFooter>
						<MealOptionFoodSubtotalLineItem
							mealOption={mealOption}
						/>
						{showSporkMetrics && (
							<MealOptionSporkCommissionLineItem
								mealOption={mealOption}
							/>
						)}
						{mealOption.MealProposal.MealProposalFees.map(fee => (
							<MealProposalFeeLineItem
								key={fee.id}
								mealOption={mealOption}
								fee={fee}
							/>
						))}
						<MealOptionGratuityLineItem mealOption={mealOption} />
						<MealOptionTotalClientPaysLineItem
							mealOption={mealOption}
						/>
						<MealOptionCostPerPersonLineItem
							mealOption={mealOption}
						/>
						{showPartnerMetrics && (
							<MealOptionTotalReceivedByAllPartnersLineItem
								mealOption={mealOption}
							/>
						)}
						{showSporkMetrics && (
							<>
								<MealOptionTotalReceivedBySporkLineItem
									mealOption={mealOption}
								/>
								<MealOptionGrossMarginLineItem
									mealOption={mealOption}
								/>
							</>
						)}
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	);
};

MealOptionSummaryTable.fragments = {
	details: gql`
		fragment MealOptionSummaryTableDetails on MealOptions {
			name
			...MealOptionCostPerPersonLineItemDetails
			...MealOptionFoodSubtotalLineItemDetails
			...MealOptionGratuityLineItemDetails
			...MealOptionGrossMarginLineItemDetails
			...MealOptionSporkCommissionLineItemDetails
			...MealOptionTotalClientPaysLineItemDetails
			...MealOptionTotalReceivedByAllPartnersLineItemDetails
			...MealOptionTotalReceivedBySporkLineItemDetails
			...MealProposalFeeLineItemDetails
		}
		${MealOptionCostPerPersonLineItem.fragments.details}
		${MealOptionFoodSubtotalLineItem.fragments.details}
		${MealOptionGratuityLineItem.fragments.details}
		${MealOptionGrossMarginLineItem.fragments.details}
		${MealOptionSporkCommissionLineItem.fragments.details}
		${MealOptionTotalClientPaysLineItem.fragments.details}
		${MealOptionTotalReceivedByAllPartnersLineItem.fragments.details}
		${MealOptionTotalReceivedBySporkLineItem.fragments.details}
		${MealProposalFeeLineItem.fragments.details}
	`,
};

MealOptionSummaryTable.propTypes = {
	showPartnerMetrics: PropTypes.bool,
	showSporkMetrics: PropTypes.bool,
	titleProps: PropTypes.object,
};

MealOptionSummaryTable.defaultProps = {
	showPartnerMetrics: true,
	showSporkMetrics: true,
};

export default MealOptionSummaryTable;
