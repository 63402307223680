import React from 'react';
import { Typography } from '@material-ui/core';

const Metric = ({ value, label }) => (
	<Typography component="div" variant="subtitle1" align="center">
		<Typography component="div" variant="h6">
			<strong>{value}</strong>
		</Typography>
		<Typography component="div" variant="body2">
			{label}
		</Typography>
	</Typography>
);

export default Metric;
