import React from 'react';
import PropTypes from 'prop-types';
import { sendCorrection } from '@sporkbytes/api-client/MealProposals';
import { DialogForm, LinkedTextInput } from '@sporkbytes/material-ui-kit-react';

import * as yup from 'models/validation';
import { useStore } from 'models/store';

import axios from 'services/data';

const validationSchema = yup.object().shape({
	correction: yup
		.string()
		.required('Please describe the correction to be made.'),
});

const MealProposalCorrectionDialogForm = ({ id, ...props }) => {
	const { showSuccessNotification } = useStore();

	return (
		<DialogForm
			onSubmit={values =>
				sendCorrection(axios, id, values).then(showSuccessNotification)
			}
			title="Send Correction"
			submitButtonText="Send"
			validationSchema={validationSchema}
			{...props}
		>
			{() => <LinkedTextInput autoFocus name="correction" />}
		</DialogForm>
	);
};

MealProposalCorrectionDialogForm.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MealProposalCorrectionDialogForm;
