import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import {
	CardContent,
	IconButton,
	Tooltip,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import {
	AttachMoney,
	ContactMail,
	Create,
	ListAlt,
	LocalPizza,
	Star,
	Sync,
	ZoomIn,
} from '@material-ui/icons';
import { canEdit } from '@sporkbytes/meal-proposal-utils';

import DateOutput from 'components/content/DateOutput';
import DetailPageLink from 'components/routing/DetailPageLink';
import MealOptionDetails from 'components/meal-options/MealOptionDetails';
import MealOptionTabs from 'components/meal-options/MealOptionTabs';
import MealProposalActionMenu from 'components/meal-proposals/MealProposalActionMenu/';
import MealProposalActivityLogsTable from 'components/meal-proposal-activity-logs/MealProposalActivityLogsTable';
import MealProposalContacts from 'components/meal-proposals/MealProposalContacts';
import MealProposalDetails from 'components/meal-proposals/MealProposalDetails';
import MealProposalFeesTable from 'components/meal-proposal-fees/MealProposalFeesTable';
import MealProposalHubSpotSyncsTable from 'components/meal-proposals/MealProposalHubSpotSyncsTable';
import MealProposalReviews from 'components/meal-proposals/MealProposalReviews';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';
import RibbonContainer from 'components/content/RibbonContainer';

import { pick } from 'services/utilities';
import { useAuth } from 'services/auth';

import useQueryString from 'hooks/useQueryString';

const MEAL_PROPOSAL_SUBSCRIPTION = gql`
	subscription MealProposalPage($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			approved
			ClientLocationId
			createdAt
			deliveryDate
			InvoiceId
			name
			orderNumberText
			status
			ClientLocation {
				id
				friendlyName
				timezone
				Client {
					id
					logoUrl
					name
				}
			}
			...MealProposalActionMenuDetails
			...MealOptionTabsDetails
			...MealProposalDetails
			...MealProposalFeesTableDetails
		}
	}
	${MealProposalActionMenu.fragments.details}
	${MealOptionTabs.fragments.details}
	${MealProposalDetails.fragments.details}
	${MealProposalFeesTable.fragments.details}
`;

const Detail = () => {
	const { id } = useParams();
	const { role, userCan } = useAuth();
	const [queryString] = useQueryString();
	const history = useHistory();
	const theme = useTheme();
	const isHighResolution = useMediaQuery(theme.breakpoints.up('md'));
	const canEditMealProposal = mealProposal =>
		userCan(['meal-proposals:manage-meal-options']) &&
		canEdit(mealProposal, role);

	return (
		<Page
			subscription={MEAL_PROPOSAL_SUBSCRIPTION}
			variables={{ id }}
			title={({ MealProposals_by_pk: { orderNumberText } }) =>
				orderNumberText
			}
		>
			{({
				data: {
					MealProposals_by_pk: {
						deliveryDate,
						name,
						orderNumberText,
						status,
						...mealProposal
					},
				},
			}) => (
				<>
					<RibbonContainer
						show={mealProposal.approved}
						title={
							<>
								<div>Manager</div>
								<div>Approved</div>
							</>
						}
						color="success"
					>
						<PageHeader
							imgUrl={mealProposal.ClientLocation.Client.logoUrl}
							headerText={mealProposal.ClientLocation.Client.name}
							actionButton={
								<MealProposalActionMenu
									id={id}
									SporkLocationId={
										mealProposal.SporkLocationId
									}
								/>
							}
							linkProps={{
								permissions: ['clients:view'],
								routeName: 'clientsDetail',
								variables: {
									id: mealProposal.ClientLocation.Client.id,
								},
							}}
						/>
						<PageSubheader>
							<DetailPageLink
								entityType="ClientLocations"
								id={mealProposal.ClientLocation.id}
								text={mealProposal.ClientLocation.friendlyName}
							/>
						</PageSubheader>
						<PageSubheader>{`${
							name ? `${name} - ` : ''
						}${orderNumberText} - ${status}`}</PageSubheader>
						<PageSubheader>
							<DateOutput
								date={deliveryDate}
								timezone={mealProposal.ClientLocation.timezone}
								component="span"
							/>
						</PageSubheader>
					</RibbonContainer>
					<QueryStringSyncedTabs
						id="tab"
						orientation={
							isHighResolution ? 'vertical' : 'horizontal'
						}
						replaceQueryString={true}
						tabs={[
							{
								id: 'details',
								label: 'Details',
								content: (
									<CardContent>
										<MealProposalDetails
											mealProposal={mealProposal}
										/>
									</CardContent>
								),
								icon: <ZoomIn />,
							},
							{
								id: 'meal-options',
								label: 'Meal Options',
								content: (
									<MealOptionTabs
										mealProposal={mealProposal}
										ContentComponent={MealOptionDetails}
										postTabsContent={
											canEditMealProposal({
												deliveryDate,
												status,
												...mealProposal,
											}) ? (
												<Tooltip title="Manage Meal Options">
													<IconButton
														onClick={() =>
															history.pushNamedRoute(
																'mealProposalsMealOptionsManage',
																{
																	id: mealProposal.id,
																	...pick(
																		queryString,
																		[
																			'meal-option',
																		]
																	),
																}
															)
														}
													>
														<Create
															style={{
																color: theme
																	.palette
																	.common
																	.white,
															}}
														/>
													</IconButton>
												</Tooltip>
											) : null
										}
									/>
								),
								icon: <LocalPizza />,
							},
							{
								id: 'fees',
								label: 'Fees',
								content: (
									<MealProposalFeesTable
										MealProposalId={id}
										mealProposal={{
											deliveryDate,
											status,
										}}
									/>
								),
								icon: <AttachMoney />,
							},
							{
								id: 'contacts',
								label: 'Contacts',
								content: (
									<CardContent>
										<MealProposalContacts
											mealProposal={{
												id,
												...mealProposal,
											}}
										/>
									</CardContent>
								),
								icon: <ContactMail />,
							},
							{
								id: 'activity-log',
								label: 'Activity Log',
								content: (
									<MealProposalActivityLogsTable
										MealProposalId={id}
									/>
								),
								icon: <ListAlt />,
							},
							{
								id: 'reviews',
								label: 'Reviews',
								content: (
									<MealProposalReviews MealProposalId={id} />
								),
								icon: <Star />,
							},
							userCan(['meal-proposal-hub-spot-syncs:view'])
								? {
										id: 'hub-spot-syncs',
										label: 'HubSpot Syncs',
										content: (
											<MealProposalHubSpotSyncsTable
												variables={{
													MealProposalId: id,
												}}
											/>
										),
										icon: <Sync />,
								  }
								: undefined,
						].filter(tab => !!tab)}
					/>
				</>
			)}
		</Page>
	);
};

export default Detail;
