import React from 'react';
import PropTypes from 'prop-types';

import TableCellNumeric from 'components/content/TableCellNumeric';

const PurchaseOrderMenuSectionQuantityTableCell = ({ items }) => {
	const total = items.reduce((sum, { quantity }) => sum + (quantity ?? 0), 0);

	return <TableCellNumeric value={total > 0 ? total : undefined} />;
};

PurchaseOrderMenuSectionQuantityTableCell.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
};

export default PurchaseOrderMenuSectionQuantityTableCell;
