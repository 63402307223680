import React from 'react';
import { CardContent, useMediaQuery, useTheme } from '@material-ui/core';
import { CreditCard, Receipt, Settings } from '@material-ui/icons';
import { validateConnection } from '@sporkbytes/api-client/Accounting';
import { PrimaryButton } from '@sporkbytes/material-ui-kit-react';

import AccountingSettingsTable from 'components/accounting-settings/AccountingSettingsTable';
import BillsTable from 'components/accounting/BillsTable';
import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import InvoicesTable from 'components/accounting/InvoicesTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';
import UnbilledPartnerLocationsTable from 'components/partner-locations/UnbilledPartnerLocationsTable';
import UninvoicedClientLocationsTable from 'components/client-locations/UninvoicedClientLocationsTable';

import useRequest from 'hooks/useRequest';

import axios from 'services/data';

const Home = () => {
	const { data, loading } = useRequest(() => validateConnection(axios));
	const theme = useTheme();
	const isHighResolution = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Page title="Accounting">
			{loading ? (
				<CenteredCircularProgress />
			) : data.connected ? (
				<>
					<PageHeader headerText="Accounting" />
					<QueryStringSyncedTabs
						id="tab"
						orientation={
							isHighResolution ? 'vertical' : 'horizontal'
						}
						replaceQueryString={true}
						tabs={[
							{
								id: 'invoices',
								label: 'Invoices',
								icon: <Receipt />,
								content: (
									<QueryStringSyncedTabs
										id="invoices-tab"
										tabs={[
											{
												id: 'uninvoiced',
												label: 'Uninvoiced',
												content: (
													<CardContent>
														<UninvoicedClientLocationsTable />
													</CardContent>
												),
											},
											{
												id: 'recent',
												label: 'Recent',
												content: (
													<CardContent>
														<InvoicesTable />
													</CardContent>
												),
											},
										]}
									/>
								),
							},
							{
								id: 'bills',
								label: 'Bills',
								icon: <CreditCard />,
								content: (
									<QueryStringSyncedTabs
										id="bills-tab"
										tabs={[
											{
												id: 'unbilled',
												label: 'Unbilled',
												content: (
													<CardContent>
														<UnbilledPartnerLocationsTable />
													</CardContent>
												),
											},
											{
												id: 'recent',
												label: 'Recent',
												content: (
													<CardContent>
														<BillsTable />
													</CardContent>
												),
											},
										]}
									/>
								),
							},
							{
								id: 'settings',
								label: 'Settings',
								icon: <Settings />,
								content: (
									<CardContent>
										<AccountingSettingsTable />
									</CardContent>
								),
							},
						]}
					/>
				</>
			) : (
				<PrimaryButton href={data.connectUrl}>
					Connect to Xero
				</PrimaryButton>
			)}
		</Page>
	);
};

export default Home;
