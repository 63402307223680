import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import { AsyncAutocomplete } from '@sporkbytes/material-ui-kit-react';

import NamedAvatar from 'components/content/NamedAvatar';

import apolloClient from 'services/apollo';
import { getMultiWordSearchWhereClause } from 'services/search';

const getClientSearchQuery = (searchTerm, additionalFields) => gql`
	query ClientSearchQuery {
		Clients(
			limit: 10
			order_by: { name: asc }
			where: {
				_and: [${getMultiWordSearchWhereClause(searchTerm)}]
			}
		) {
			id
			name
			logoUrl
			${additionalFields}
		}
	}
`;

const ClientSearch = ({ additionalFields, ...props }) => (
	<AsyncAutocomplete
		getSearchResults={async searchTerm => {
			const {
				data: { Clients },
			} = await apolloClient.query({
				query: getClientSearchQuery(searchTerm, additionalFields),
			});

			return Clients;
		}}
		getOptionLabel={option => option.name}
		renderOption={option => (
			<>
				<ListItemAvatar>
					<NamedAvatar
						alt={option.name}
						imageUrl={option.logoUrl}
						name={option.name}
					/>
				</ListItemAvatar>
				<ListItemText primary={option.name} />
			</>
		)}
		{...props}
	/>
);

ClientSearch.propTypes = {
	additionalFields: PropTypes.string,
};

ClientSearch.defaultProps = {
	additionalFields: '',
};

export default ClientSearch;
