import React from 'react';
import {
	Form,
	GridContainer,
	LinkedCurrencyInput,
	LinkedPercentInput,
	LinkedSingleSelect,
} from '@sporkbytes/material-ui-kit-react';

import * as yup from 'models/validation';
import { useStore } from 'models/store';

const ClientLocationFeeForm = ({ feeOptions, ...props }) => {
	const validationSchema = yup.object().shape({
		FeeId: yup.string().required('Please select a fee.'),
		amount: yup.nonNegativeNumber().required('Please enter an amount.'),
	});
	const { hasMoreThanOneSporkLocation } = useStore();
	const getOptionLabel = option => {
		if (hasMoreThanOneSporkLocation()) {
			return option.name + ' - ' + option.SporkLocation.name;
		}

		return option.name;
	};

	return (
		<Form {...props} validationSchema={validationSchema}>
			{({ values }) => (
				<GridContainer>
					<LinkedSingleSelect
						name="FeeId"
						label="Select a fee"
						options={feeOptions}
						getOptionKey={option => option.id}
						getOptionLabel={getOptionLabel}
						getOptionValue={option => option.id}
					/>
					{feeOptions.find(({ id }) => values.FeeId === id)?.type ===
					'percent' ? (
						<LinkedPercentInput name="amount" />
					) : (
						<LinkedCurrencyInput name="amount" />
					)}
				</GridContainer>
			)}
		</Form>
	);
};

export default ClientLocationFeeForm;
