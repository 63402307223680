import React from 'react';

import NotFoundPage from 'components/content/NotFoundPage';

const NotFound = () => (
	<NotFoundPage
		body="The page you're looking for could not be found."
		title="404 Not Found"
	/>
);

export default NotFound;
