import { createMuiTheme } from '@material-ui/core';
import {
	blue,
	deepOrange,
	green,
	purple,
	red,
	teal,
	yellow,
} from '@material-ui/core/colors';

let theme = createMuiTheme({
	layers: {
		autocompleteResults: 13,
		content: 11,
		fixedContent: 12,
	},
	palette: {
		error: red,
		info: blue,
		lightBlue: '#edf7ff', // this is a lighter shade than the lightest "info" shade available (50) and works well for backgrounds
		lightGray: '#eeeeee',
		primary: process.env.NODE_ENV === 'production' ? teal : purple,
		secondary: deepOrange,
		success: green,
		warning: yellow,
	},
	sizes: {
		appToolbar: 72,
		contentMaxWidth: 1200,
		footer: 80,
	},
	typography: {
		useNextVariants: true,
	},
});

theme = {
	...theme,
	overrides: {
		MuiCssBaseline: {
			'@global': {
				a: {
					color: theme.palette.primary[500],
				},
			},
		},
		MuiTableCell: {
			footer: {
				color: theme.palette.text.primary,
				letterSpacing: 0.5,
				textTransform: 'uppercase',
			},
			head: {
				color: theme.palette.grey[800],
				fontSize: '0.75rem',
				fontWeight: 'bold',
				letterSpacing: 1,
				textTransform: 'uppercase',
				whiteSpace: 'nowrap',
			},
		},
		MuiTableFooter: {
			root: {
				borderTop: `3px solid ${theme.palette.primary[500]}`,
				'@media print': {
					borderTop: `3px solid ${theme.palette.common.black}`,
					display: 'table-row-group', // this is needed to keep the footer from being printed on every page
				},
			},
		},
		MuiTableHead: {
			root: {
				background: theme.palette.primary[100],
				borderBottom: `3px solid ${theme.palette.primary[500]}`,
				'@media print': {
					borderBottom: 'none',
				},
			},
		},
		MuiTooltip: {
			tooltip: {
				fontSize: 14,
			},
		},
	},
};

export default theme;
