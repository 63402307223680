import Detail from 'pages/spork-reviews/Detail';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'sporkReviewsDetail',
		path: '/:id/detail',
		component: Detail,
		permissions: ['spork-reviews:view'],
		exact: true,
	},
];

export default prependUrlPath('spork-reviews', routes);
