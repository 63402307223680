import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ActionMenu from 'components/utilities/ActionMenu';

import {
	createCallPhoneNumberAction,
	createEditAction,
	createSendEmailAction,
} from 'services/actionMenu';
import { useAuth } from 'services/auth';

const UserActionMenu = ({
	additionalActions,
	user: { id, firstName, phoneNumber, emailAddress },
}) => {
	const { userCan } = useAuth();
	const [loading, setLoading] = useState(false);
	const entityType = 'Users';

	return (
		<ActionMenu
			loading={loading}
			options={[
				createEditAction({
					entityId: id,
					entityName: firstName,
					entityType,
					userCan,
				}),
				createCallPhoneNumberAction({
					canPerformAction: !!phoneNumber,
					entityName: firstName,
					phoneNumber,
				}),
				createSendEmailAction({
					entityName: firstName,
					emailAddress,
				}),
				...additionalActions(setLoading),
			]}
		/>
	);
};

UserActionMenu.propTypes = {
	additionalActions: PropTypes.func,
	onActionComplete: PropTypes.func,
	user: PropTypes.shape({
		active: PropTypes.bool.isRequired,
		firstName: PropTypes.string.isRequired,
		phoneNumber: PropTypes.string,
		emailAddress: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
	}).isRequired,
};

UserActionMenu.defaultProps = {
	additionalActions: () => [],
};

export default UserActionMenu;
