import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { cdn } from '@sporkbytes/config';

const useStyles = makeStyles(theme => ({
	container: {
		margin: '0 auto',
		maxWidth: theme.sizes.contentMaxWidth,
		textAlign: 'center',
		width: '100%',
	},
	logo: {
		maxWidth: theme.sizes.contentMaxWidth / 2,
	},
	paragraph: {
		marginTop: theme.spacing(2),
	},
}));

const Maintenance = () => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<img
				src={`${cdn.url}/logos/spork/orange-large.png`}
				alt="Spork Bytes Logo"
				className={classes.logo}
			/>
			<Typography variant="h4" component="h1">
				We’ll be back soon!
			</Typography>
			<Typography className={classes.paragraph}>
				We're cooking up something new and will be back online shortly.
			</Typography>
			<Typography className={classes.paragraph}>— Spork Bytes</Typography>
		</div>
	);
};

export default Maintenance;
