import React from 'react';
import gql from 'graphql-tag';
import { TableCell, TableRow } from '@material-ui/core';

import TableCellNumeric from 'components/content/TableCellNumeric';

const MealOptionGratuityLineItem = ({ mealOption }) =>
	mealOption.gratuity > 0 ? (
		<TableRow>
			<TableCell colSpan="3">Gratuity</TableCell>
			<TableCellNumeric format="currency" value={mealOption.gratuity} />
		</TableRow>
	) : null;

MealOptionGratuityLineItem.fragments = {
	details: gql`
		fragment MealOptionGratuityLineItemDetails on MealOptions {
			gratuity
		}
	`,
};

export default MealOptionGratuityLineItem;
