import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { deleteBills } from '@sporkbytes/api-client/Bills';

import ActionMenu from 'components/utilities/ActionMenu';
import CommaSeparatedList from 'components/utilities/CommaSeparatedList';
import DetailPageLink from 'components/routing/DetailPageLink';
import RemoteTable from 'components/content/RemoteTable';

import { createDeleteAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';

const getBillsQuery = searchTerm => gql`
	query BillsTable($createdAtOrderBy: order_by, $limit: Int!, $offset: Int!) {
		Bills(
			order_by: { createdAt: $createdAtOrderBy }
			limit: $limit
			offset: $offset
			where: {
				_or: [
					{
						Partner: {
							name: { _ilike: "%${searchTerm}%" }
						}
					}
					{
						PartnerLocation: {
							friendlyName: { _ilike: "%${searchTerm}%" }
						}
					}
					{
						MealProposalsBills: {
							MealProposal: {
								orderNumberText: { _ilike: "%${searchTerm}%" }
							}
						}
					}
				]
			}
		) {
			id
			MealProposalsBills {
				MealProposal {
					id
					orderNumberText
				}
			}
			PartnerLocation {
				id
				friendlyName
			}
			Partner {
				id
				name
			}
		}
		Bills_aggregate {
			aggregate {
				count
			}
		}
	}
`;

const BillsTable = () => {
	const { userCannot } = useAuth();
	const ref = useRef();

	return (
		<RemoteTable
			tableRef={ref}
			columns={[
				{
					title: 'Order Number(s)',
					field: 'orderNumber',
					sorting: false,
					render: ({ MealProposalsBills }) => (
						<CommaSeparatedList
							items={MealProposalsBills.map(
								({ MealProposal: { id, orderNumberText } }) => (
									<DetailPageLink
										entityType="mealProposals"
										id={id}
										text={orderNumberText}
									/>
								)
							)}
						/>
					),
				},
				{
					title: 'Partner',
					sorting: false,
					render: ({ Partner: { id, name } }) => (
						<DetailPageLink
							entityType="partners"
							id={id}
							text={name}
						/>
					),
				},
				{
					title: 'Partner Location',
					sorting: false,
					render: ({ PartnerLocation: { id, friendlyName } }) => (
						<DetailPageLink
							entityType="partnerLocations"
							id={id}
							text={friendlyName}
						/>
					),
				},
				{
					title: 'Actions',
					sorting: false,
					hidden: userCannot(['bills:delete']),
					render: ({ id, Partner: { name } }) => (
						<ActionMenu
							options={[
								createDeleteAction({
									afterDelete: ref.current.onQueryChange,
									deleteFunction: deleteBills,
									entityId: id,
									entityName: `Bill for ${name}`,
								}),
							]}
						/>
					),
				},
			]}
			defaultOrderBy="createdAt"
			defaultOrderDirection="desc"
			getQuery={getBillsQuery}
			queryEntity="Bills"
		/>
	);
};

export default BillsTable;
