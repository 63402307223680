import MealOptionEditDialogForm from 'components/meal-options/MealOptionEditDialogForm';

import { createEditAction } from 'services/actionMenu';

export default ({ id, state, userCan }) =>
	createEditAction({
		Dialog: MealOptionEditDialogForm,
		dialogProps: {
			id,
		},
		entityName: `Name of ${state.context?.name}`,
		entityType: 'MealOptions',
		userCan,
	});
