import React from 'react';
import gql from 'graphql-tag';
import { DeleteForever } from '@material-ui/icons';
import {
	deletePurchaseOrders,
	sendPartnerDeletionNotice,
} from '@sporkbytes/api-client/PurchaseOrders';
import { updateContentWithSubstitutions } from '@sporkbytes/email-template-utils';
import {
	DeleteDialog,
	GridContainer,
	LinkedCheckbox,
} from '@sporkbytes/material-ui-kit-react';
import { getIncludedContacts } from '@sporkbytes/purchase-order-utils';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import { store } from 'models/store';

import { formatZonedTime } from 'services/dates';
import apolloClient from 'services/apollo';
import axios from 'services/data';

const PURCHASE_ORDER_QUERY = gql`
	query PurchaseOrderDeleteDialog($emailTemplateId: String!, $id: uuid!) {
		PurchaseOrders_by_pk(id: $id) {
			pickupTime
			PartnerLocation {
				timezone
				Partner {
					name
				}
			}
			PurchaseOrdersContacts {
				Contact {
					firstName
				}
			}
			MealOption {
				MealProposal {
					orderNumberText
					SporkContact {
						firstName
					}
				}
			}
		}
		EmailTemplates_by_pk(id: $emailTemplateId) {
			buttonText
			postButtonContent
			preButtonContent
			signature
			warningText
			greeting
		}
	}
`;

export default ({ afterDelete, id, partnerName, userCan }) => {
	return {
		canPerformAction: userCan(['purchase-orders:delete']),
		icon: <DeleteForever />,
		label: `Delete PO for ${partnerName}`,
		onClick: async () => {
			const { data } = await apolloClient.query({
				query: PURCHASE_ORDER_QUERY,
				variables: { emailTemplateId: 'deletionNotice', id },
			});

			const substitutions = {
				'-date-': formatZonedTime(
					data.PurchaseOrders_by_pk.pickupTime,
					'date',
					data.PurchaseOrders_by_pk.PartnerLocation.timezone
				),
				'-orderNumber-':
					data.PurchaseOrders_by_pk.MealOption.MealProposal
						.orderNumberText,
				'-partnerName-':
					data.PurchaseOrders_by_pk.PartnerLocation.Partner.name,
				'-recipientFirstNames-': makeCommaSeparatedList(
					getIncludedContacts(data.PurchaseOrders_by_pk).map(
						({ firstName }) => firstName
					)
				),
				'-sporkContactFirstName-':
					data.PurchaseOrders_by_pk.MealOption.MealProposal
						.SporkContact.firstName,
				'-time-': formatZonedTime(
					data.PurchaseOrders_by_pk.pickupTime,
					'time',
					data.PurchaseOrders_by_pk.PartnerLocation.timezone
				),
			};
			const emailContent = updateContentWithSubstitutions(
				data.EmailTemplates_by_pk,
				substitutions
			);

			store.getState().showDialog(DeleteDialog, {
				children: (
					<GridContainer>
						<LinkedCheckbox
							name="sendDeletionEmail"
							label={`Send Deletion Email to ${partnerName}`}
						/>
					</GridContainer>
				),
				onDelete: async values => {
					if (values.sendDeletionEmail) {
						await sendPartnerDeletionNotice(
							axios,
							id,
							emailContent
						);
					}

					await deletePurchaseOrders(axios, id);
					await afterDelete();
				},
			});
		},
		type: 'error',
	};
};
