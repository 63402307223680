import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ListItemText } from '@material-ui/core';
import gql from 'graphql-tag';
import {
	deletePartnerMenuItemOptionGroupsPartnerMenuItems,
	post,
	put,
	sort,
} from '@sporkbytes/api-client/PartnerMenuItemOptionGroupsPartnerMenuItems';
import { put as putPartnerMenuItem } from '@sporkbytes/api-client/PartnerMenuItems';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import DetailPageLink from 'components/routing/DetailPageLink';
import EditableSortOrder from 'components/forms/EditableSortOrder';
import EditableValue from 'components/forms/EditableValue';
import NoContentText from 'components/content/NoContentText';
import PartnerMenuItemActionMenu from 'components/partner-menu-items/PartnerMenuItemActionMenu';
import PartnerMenuItemSearch from 'components/partner-menu-items/PartnerMenuItemSearch';
import RemoteTable from 'components/content/RemoteTable';

import { createRemoveAction } from 'services/actionMenu';
import { getMultiWordSearchWhereClause } from 'services/search';
import { removeHtml } from 'services/regexp';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const getPartnerMenuItemsQuery = searchTerm => gql`
	query PartnerMenuItems(
		$PartnerMenuItemOptionGroupId: uuid!
		$activeOrderBy: order_by
		$costOrderBy: order_by
		$descriptionOrderBy: order_by
		$nameOrderBy: order_by
		$sortOrderOrderBy: order_by
		$limit: Int
		$offset: Int
	) {
		PartnerMenuItemOptionGroupsPartnerMenuItems(
			where: { 
				PartnerMenuItemOptionGroupId: { _eq: $PartnerMenuItemOptionGroupId } 
				PartnerMenuItem: {
					_or: [
						{ _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
						{
							PartnerMenuItemsDietaryTags: {
								DietaryTag: {
									name: {
										_ilike: "%${searchTerm}%"
									}
								}
							}
						}
					]
				}
			}
			order_by: {
				cost: $costOrderBy
				sortOrder: $sortOrderOrderBy
				PartnerMenuItem: { 
					active: $activeOrderBy
					description: $descriptionOrderBy
					name: $nameOrderBy
				}
			}
			limit: $limit
			offset: $offset
		) {
			cost
			sortOrder
			PartnerMenuItem {
				id
				active
				description
				name
				...PartnerMenuItemActionMenuDetails
				PartnerMenuItemsDietaryTags(order_by: { DietaryTag: { sortOrder: asc } } ) {
					DietaryTag {
						name
					}
				}
			}
		}
		PartnerMenuItemOptionGroupsPartnerMenuItems_aggregate(
			where: { 
				PartnerMenuItemOptionGroupId: { _eq: $PartnerMenuItemOptionGroupId } 
				PartnerMenuItem: {
					_or: [
						{ _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
						{
							PartnerMenuItemsDietaryTags: {
								DietaryTag: {
									name: {
										_ilike: "%${searchTerm}%"
									}
								}
							}
						}
					]
				}
			}
		) {
			aggregate {
				count
			}
		}
	}
	${PartnerMenuItemActionMenu.fragments.details}
`;

const PartnerMenuItemsTable = ({
	PartnerId,
	PartnerMenuItemId,
	PartnerMenuItemOptionGroupId,
	options,
	...props
}) => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<RemoteTable
			{...props}
			tableRef={ref}
			columns={[
				{
					title: 'Sort Order',
					field: 'sortOrder',
					render: ({ PartnerMenuItem: { id }, sortOrder }) => (
						<EditableSortOrder
							onSave={value =>
								sort(axios, id, PartnerMenuItemOptionGroupId, {
									newValue: value,
								}).then(ref.current.onQueryChange)
							}
							permissions={[
								'partner-menu-item-option-groups-partner-menu-items:edit',
							]}
							required={true}
							value={sortOrder}
						/>
					),
				},
				{
					title: 'Name',
					field: 'name',
					render: ({
						PartnerMenuItem: {
							id,
							name,
							PartnerMenuItemsDietaryTags,
						},
					}) => (
						<ListItemText
							primary={
								<DetailPageLink
									entityType="PartnerMenuItems"
									id={id}
									text={name}
								/>
							}
							secondary={makeCommaSeparatedList(
								PartnerMenuItemsDietaryTags.map(
									({ DietaryTag: { name } }) => name
								),
								false
							)}
						/>
					),
				},
				{
					title: 'Cost As Option',
					field: 'cost',
					render: ({ cost, PartnerMenuItem }) => (
						<EditableValue
							helperText="This is the cost of the item as an option."
							name="cost"
							onSave={cost =>
								put(
									axios,
									PartnerMenuItem.id,
									PartnerMenuItemOptionGroupId,
									{ cost }
								).then(ref.current.onQueryChange)
							}
							permissions={[
								'partner-menu-item-option-groups-partner-menu-items:edit',
							]}
							type="currency"
							value={cost}
						/>
					),
				},
				{
					title: 'Description',
					field: 'description',
					render: ({ PartnerMenuItem: { description } }) =>
						description ? (
							removeHtml(description)
						) : (
							<NoContentText>no description</NoContentText>
						),
				},
				{
					title: 'Active',
					field: 'active',
					render: ({ PartnerMenuItem: { active, id } }) => (
						<BooleanFieldSwitch
							afterChange={ref.current.onQueryChange}
							entityId={id}
							entityType="partnerMenuItems"
							updateEntity={putPartnerMenuItem}
							value={active}
						/>
					),
				},
				{
					title: 'Actions',
					field: 'action',
					sorting: false,
					render: ({ PartnerMenuItem }) => (
						<PartnerMenuItemActionMenu
							partnerMenuItem={PartnerMenuItem}
							onActionComplete={ref.current.onQueryChange}
							additionalActions={({ setLoading }) => [
								createRemoveAction({
									canPerformAction: userCan([
										'partner-menu-item-option-groups-partner-menu-items:delete',
									]),
									entityName: PartnerMenuItem.name,
									onActionComplete: ref.current.onQueryChange,
									removeEntity: () =>
										deletePartnerMenuItemOptionGroupsPartnerMenuItems(
											axios,
											PartnerMenuItem.id,
											PartnerMenuItemOptionGroupId
										),
									setLoading,
								}),
							]}
						/>
					),
				},
			]}
			defaultOrderBy="sortOrder"
			defaultOrderDirection="asc"
			getQuery={getPartnerMenuItemsQuery}
			queryEntity="PartnerMenuItemOptionGroupsPartnerMenuItems"
			variables={{ PartnerMenuItemOptionGroupId }}
			options={{
				padding: 'dense',
				...options,
			}}
			canAddExistingEntity={userCan([
				'partner-menu-items:edit',
				'partner-menu-item-option-groups-partner-menu-items:create',
			])}
			entityToAddName="Partner Menu Item"
			onAddExistingEntity={partnerMenuItem =>
				post(axios, {
					PartnerMenuItemOptionGroupId,
					PartnerMenuItemId: partnerMenuItem.id,
				}).then(ref.current.onQueryChange)
			}
			EntityToAddSearch={({ name }) => (
				<PartnerMenuItemSearch
					autoFocus
					name={name}
					where={`
						PartnerId: { _eq: "${PartnerId}" }
						_not: {
							PartnerMenuItemOptionGroupsPartnerMenuItems: {
								PartnerMenuItemOptionGroup: {
									PartnerMenuItemId: {
										_eq: "${PartnerMenuItemId}"
									}
								}
							}
						}
					`}
				/>
			)}
		/>
	);
};

PartnerMenuItemsTable.propTypes = {
	PartnerId: PropTypes.string.isRequired,
	PartnerMenuItemId: PropTypes.string.isRequired,
	PartnerMenuItemOptionGroupId: PropTypes.string.isRequired,
};

export default PartnerMenuItemsTable;
