import ContactCreate from 'pages/partner-locations/ContactCreate';
import Detail from 'pages/partner-locations/Detail';
import Edit from 'pages/partner-locations/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'partnerLocationsDetail',
		path: '/:id/detail',
		component: Detail,
		permissions: ['partner-locations:view'],
		exact: true,
	},
	{
		name: 'partnerLocationsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['partner-locations:edit'],
		exact: true,
	},
	{
		name: 'partnerLocationsContactsCreate',
		path: '/:id/contacts/create',
		component: ContactCreate,
		permissions: ['contacts:create', 'partner-locations-contacts:create'],
		exact: true,
	},
];

export default prependUrlPath('partner-locations', routes);
