import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { isPast, parseISO } from 'date-fns';
import Chart from 'react-apexcharts';
import { Grid } from '@material-ui/core';
import { getPercent } from '@sporkbytes/math-utils';
import { getPurchaseOrders } from '@sporkbytes/meal-proposal-utils';

import Metric from 'components/analytics/Metric';
import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import { formatGroupedDate, formatPercent } from 'services/mealProposal';
import TimeBins from 'services/TimeBins';

const PickedUpAtStatistics = () => {
	const { chartConfig, filters, groupedMealProposalsForCalculations } =
		useContext(AnalyticsContext);
	let count = 0;

	const binnedPurchaseOrders = groupedMealProposalsForCalculations
		.map(({ date, MealProposals }) => ({
			date,
			purchaseOrders: getPurchaseOrders(
				MealProposals.filter(mealProposal =>
					isPast(parseISO(mealProposal.deliveryDate))
				),
				filters.PartnerId
			),
		}))
		.map(({ purchaseOrders, date }) => {
			const pickedUpAtBins = new TimeBins('pickupTime', 'pickedUpAt');
			const formattedDate = formatGroupedDate(date, filters.groupBy);

			count += purchaseOrders.length;

			const markedPickedUpPurchaseOrder = purchaseOrders.filter(
				purchaseOrder => !!purchaseOrder.pickedUpAt
			);

			markedPickedUpPurchaseOrder.forEach(purchaseOrder =>
				pickedUpAtBins.addToBin(purchaseOrder)
			);

			return {
				date: formattedDate,
				pickedUpAtBins,
			};
		});

	let series = TimeBins.getBins().map(({ name }) => ({ name, data: [] }));

	const { markedPickedUpCount, earlyCount, onTimeCount, lateCount } =
		binnedPurchaseOrders.reduce(
			(
				{ markedPickedUpCount, earlyCount, onTimeCount, lateCount },
				{ pickedUpAtBins }
			) => ({
				markedPickedUpCount:
					markedPickedUpCount + pickedUpAtBins.totalCount,
				earlyCount: earlyCount + pickedUpAtBins.earlyCount,
				onTimeCount: onTimeCount + pickedUpAtBins.onTimeCount,
				lateCount: lateCount + pickedUpAtBins.lateCount,
			}),
			{
				markedPickedUpCount: 0,
				earlyCount: 0,
				onTimeCount: 0,
				lateCount: 0,
			}
		);

	binnedPurchaseOrders.forEach(({ date, pickedUpAtBins }) => {
		pickedUpAtBins.bins.forEach((bin, index) => {
			const matchedSeries = series.find(({ name }) => name === bin.name);

			matchedSeries.data.push({
				x: date,
				y: getPercent(bin.data.length, pickedUpAtBins.totalCount) || 0,
			});
		});
	});

	const options = {
		...chartConfig.options,
		dataLabels: {
			formatter: value => formatPercent(Math.round(value)),
		},
		theme: {
			monochrome: {
				color: chartConfig.options.colors[0],
				enabled: true,
				shadeIntensity: 0.1,
				shadeTo: 'light',
			},
		},
		tooltip: {
			y: {
				formatter: value => formatPercent(Math.round(value)),
			},
		},
		xaxis: {
			tooltip: {
				enabled: false,
			},
		},
	};

	return (
		<>
			<WidgetTitle>Picked Up Statistics</WidgetTitle>
			<Grid container direction="row" justify="space-around">
				<Grid item xs={12} lg={10}>
					<Chart
						type="heatmap"
						series={series}
						options={options}
						height={chartConfig.height}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={2}
					container
					direction="row"
					justify="center"
					spacing={4}
				>
					<Grid item lg={12}>
						<Metric
							value={formatPercent(
								getPercent(markedPickedUpCount, count, 0)
							)}
							label="Marked Picked Up"
						/>
					</Grid>
					<Grid item lg={12}>
						<Metric
							value={formatPercent(
								getPercent(onTimeCount, markedPickedUpCount, 0)
							)}
							label="On Time"
						/>
					</Grid>
					<Grid item lg={12}>
						<Metric
							value={formatPercent(
								getPercent(lateCount, markedPickedUpCount, 0)
							)}
							label="Late"
						/>
					</Grid>
					<Grid item lg={12}>
						<Metric
							value={formatPercent(
								getPercent(earlyCount, markedPickedUpCount, 0)
							)}
							label="Early"
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

PickedUpAtStatistics.fragments = {
	details: gql`
		fragment PickedUpAtStatisticsDetails on MealProposals {
			MealOptions {
				approved
				PurchaseOrders {
					pickedUpAt
					pickupTime
				}
			}
		}
	`,
};

export default PickedUpAtStatistics;
