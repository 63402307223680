import React from 'react';

import DeliveryDriversTable from 'components/delivery-drivers/DeliveryDriversTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { useAuth } from 'services/auth';

const Detail = () => {
	const { userCan } = useAuth();

	return (
		<Page title="Delivery Drivers">
			<PageHeader headerText="Delivery Drivers" />
			<DeliveryDriversTable
				defaultOrderBy="active"
				defaultOrderDirection="desc"
				addNewEntityRouteName="deliveryDriversCreate"
				canAddNewEntity={userCan(['delivery-drivers:create'])}
			/>
		</Page>
	);
};

export default Detail;
