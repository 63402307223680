import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link as LinkRR } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';

const useStyles = makeStyles(theme => ({
	link: {
		color: theme.palette.primary[500],
	},
}));

const Link = React.forwardRef(
	({ permissions, routeName, variables, ...props }, ref) => {
		const classes = useStyles();
		const history = useHistory();
		const { userCan } = useAuth();
		const { hideDialog } = useStore();

		const typographyProps = userCan(permissions)
			? {
					component: LinkRR,
					to: history.getNamedRouteLocation(routeName, variables),
			  }
			: {};

		return (
			<Typography
				className={classes.link}
				variant="inherit"
				{...props}
				{...typographyProps}
				onClick={hideDialog}
				ref={ref}
			/>
		);
	}
);

Link.propTypes = {
	permissions: PropTypes.arrayOf(PropTypes.string),
	routeName: PropTypes.string.isRequired,
	variables: PropTypes.object,
};

Link.defaultProps = {
	permissions: [],
};

export default Link;
