import { addDays, setHours, setMinutes, setSeconds } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { format, utcToZonedTime } from 'date-fns-tz';

const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export {
	convertHoursToMinutes,
	DEFAULT_TIMEZONE,
	formatDateRangeForQueryString,
	formatMinutes,
	formatZonedTime,
	getDefaultDeliveryDate,
};

const formatStringOptions = {
	dateAndTime: `iiii LLLL do, yyyy 'at' h:mm aaaa`,
	dateAndTimeMedium: `EEEE L/d 'at' h:mm aaaa`,
	dateAndTimeShort: `L/d h:mm aaaa`,
	date: 'EEEE L/d',
	dayOfWeek: 'EEEE',
	time: `h:mm aaaa`,
};

function convertHoursToMinutes(hours, minutes) {
	return hours * 60 + (minutes || 0);
}

function formatDateRangeForQueryString({ beginDate, endDate }, dateType) {
	const formatString = 'yyyy-MM-dd';
	const formattedDateRange = {};

	if (beginDate) {
		formattedDateRange[`${dateType}_date_gte`] = format(
			beginDate,
			formatString
		);
	}
	if (endDate) {
		formattedDateRange[`${dateType}_date_lte`] = format(
			endDate,
			formatString
		);
	}

	return formattedDateRange;
}

function formatMinutes(minutes) {
	let date = setHours(new Date(), 0);
	date = setSeconds(date, 0);
	date = setMinutes(date, minutes);

	return format(date, 'h:mm a');
}

function formatZonedTime(date, formatter, timezone) {
	const formatString = formatStringOptions[formatter];
	const formatArgs = timezone
		? [utcToZonedTime(date, timezone), formatString, { timeZone: timezone }]
		: [new Date(date), formatString];

	return format(...formatArgs);
}

function getDefaultDeliveryDate() {
	return addDays(
		toDate(`${new Date().toISOString().slice(0, 10)}T11:30:00`, {
			timeZone: DEFAULT_TIMEZONE,
		}),
		1
	);
}
