import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { post } from '@sporkbytes/api-client/ClientLocationsFees';
import { Alert } from '@sporkbytes/material-ui-kit-react';

import ClientLocationFeeForm from 'components/client-locations-fees/ClientLocationFeeForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

import BackButton from 'components/routing/BackButton';

const CLIENT_LOCATION_QUERY = gql`
	query ClientLocationsFeesCreate($id: uuid!) {
		ClientLocations_by_pk(id: $id) {
			friendlyName
			Client {
				logoUrl
				name
			}
		}
		Fees(
			order_by: { name: asc }
			where: {
				_not: {
					ClientLocationsFees: { ClientLocationId: { _eq: $id } }
				}
				SporkLocation: { active: { _eq: true } }
			}
		) {
			id
			name
			type
			SporkLocation {
				name
			}
		}
	}
`;

const FeeCreate = () => {
	const { id } = useParams();

	return (
		<Page query={CLIENT_LOCATION_QUERY} variables={{ id }} title="New Fee ">
			{({
				data: {
					ClientLocations_by_pk: {
						Client: { name, logoUrl },
						friendlyName,
					},
					Fees,
				},
			}) => {
				if (Fees.length === 0) {
					return (
						<>
							<Alert type="error" size="large">
								{friendlyName} already has defaults for all
								fees.
							</Alert>
							<BackButton />
						</>
					);
				}

				return (
					<>
						<PageHeader headerText={name} imgUrl={logoUrl} />
						<PageSubheader>
							Add New Default Fee for {friendlyName}
						</PageSubheader>
						<FormWrapper
							form={ClientLocationFeeForm}
							feeOptions={Fees}
							initialValues={{
								ClientLocationId: id,
							}}
							onSubmit={(axios, values) => post(axios, values)}
						/>
					</>
				);
			}}
		</Page>
	);
};

export default FeeCreate;
