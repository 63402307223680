import React from 'react';
import gql from 'graphql-tag';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Currency, GridContainer } from '@sporkbytes/material-ui-kit-react';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import BooleanStatusIndicator from 'components/utilities/BooleanStatusIndicator';
import DefinitionList from 'components/content/DefinitionList';
import DetailPageLink from 'components/routing/DetailPageLink';
import DetailSection from 'components/content/DetailSection';
import FeedsNumber from 'components/content/FeedsNumber';
import NoContentText from 'components/content/NoContentText';
import PartnerMenuItemPrintableLabel from 'components/partner-menu-items/PartnerMenuItemPrintableLabel';
import PartnerMenuItemPhoto from 'components/partner-menu-items/PartnerMenuItemPhoto';
import RichText from 'components/content/RichText';

const PartnerMenuItemDetails = ({
	partnerMenuItem: {
		cost,
		description,
		feedsMaximum,
		feedsMinimum,
		howToOrder,
		name,
		ParentPartnerMenuItem,
		PartnerListMenuSectionsPartnerMenuItems,
		PartnerMenuItemsDietaryTags,
		PartnerMenuSection,
		photoUrl,
		printableName,
		producesLabel,
		showInDriverChecklist,
		size,
		visibleInMenu,
		...partnerMenuItem
	},
}) => (
	<>
		<DefinitionList
			title="Basic Info"
			items={[
				{
					label: 'Name',
					value: name,
				},
				{
					label: 'Printable Name',
					value: printableName,
				},
				{
					label: 'Section',
					value: PartnerMenuSection?.name,
					noContentText: 'no section',
				},
				{
					label: 'Size',
					value: size,
				},
				{
					label: 'Cost',
					value: cost,
					render: ({ value }) => (
						<Typography>
							<Currency value={value} />
						</Typography>
					),
				},
				{
					label: 'Feeds',
					value: {
						feedsMinimum,
						feedsMaximum,
					},
					render: ({ value }) => <FeedsNumber {...value} />,
				},
				{
					label: 'Produces Label',
					value: producesLabel,
					render: BooleanStatusIndicator,
				},
				{
					label: 'Visible in Menu',
					value: visibleInMenu,
					render: BooleanStatusIndicator,
				},
				{
					label: 'Show in Driver Checklist',
					value: showInDriverChecklist,
					render: BooleanStatusIndicator,
				},
			]}
		/>
		<RichText title="Description" body={description} />
		<RichText title="How to Order" body={howToOrder} />
		<DetailSection title="Dietary Tags">
			{PartnerMenuItemsDietaryTags.length > 0 ? (
				<Typography>
					{makeCommaSeparatedList(
						PartnerMenuItemsDietaryTags.map(
							({ DietaryTag: { name } }) => name
						)
					)}
				</Typography>
			) : (
				<NoContentText />
			)}
		</DetailSection>
		<DetailSection title={`Parent: ${ParentPartnerMenuItem.name}`}>
			<GridContainer spacing={1} columns="none">
				<Typography>children:</Typography>
				{ParentPartnerMenuItem.PartnerMenuItems.map(({ id, name }) => (
					<DetailPageLink
						key={id}
						entityType="PartnerMenuItems"
						id={id}
						text={name}
						variant="body1"
					/>
				))}
			</GridContainer>
		</DetailSection>
		<DetailSection title="Partner List Menus">
			{PartnerListMenuSectionsPartnerMenuItems.length > 0 ? (
				PartnerListMenuSectionsPartnerMenuItems.map(
					({ PartnerListMenuSection: { name, PartnerListMenu } }) => (
						<Breadcrumbs key={PartnerListMenu.name + name}>
							<DetailPageLink
								entityType="PartnerListMenus"
								id={PartnerListMenu.id}
								text={PartnerListMenu.name}
							/>
							<Typography>{name}</Typography>
						</Breadcrumbs>
					)
				)
			) : (
				<NoContentText />
			)}
		</DetailSection>
		<GridContainer>
			{producesLabel && (
				<DetailSection title="Label">
					<PartnerMenuItemPrintableLabel
						partnerMenuItem={{
							description,
							PartnerMenuItemsDietaryTags,
							...partnerMenuItem,
						}}
					/>
				</DetailSection>
			)}
			{photoUrl && (
				<DetailSection title="Photo">
					<PartnerMenuItemPhoto src={photoUrl} alt={name} />
				</DetailSection>
			)}
		</GridContainer>
	</>
);

PartnerMenuItemDetails.fragments = {
	details: gql`
		fragment PartnerMenuItemDetails on PartnerMenuItems {
			cost
			description
			feedsMaximum
			feedsMinimum
			name
			howToOrder
			photoUrl
			printableName
			producesLabel
			showInDriverChecklist
			size
			visibleInMenu
			PartnerMenuItemsDietaryTags(
				order_by: { DietaryTag: { sortOrder: asc } }
			) {
				DietaryTag {
					name
				}
			}
			ParentPartnerMenuItem {
				id
				name
				PartnerMenuItems {
					id
					name
				}
			}
			PartnerListMenuSectionsPartnerMenuItems {
				PartnerListMenuSection {
					name
					PartnerListMenu {
						id
						name
					}
				}
			}
			PartnerMenuSection {
				name
			}
			...PartnerMenuItemPrintableLabelDetails
		}
		${PartnerMenuItemPrintableLabel.fragments.details}
	`,
};

export default PartnerMenuItemDetails;
