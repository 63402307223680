import React from 'react';
import PropTypes from 'prop-types';
import { Currency } from '@sporkbytes/material-ui-kit-react';
import { isApproved, isConfirmed } from '@sporkbytes/object-utils';
import { totalReceivedByPartner } from '@sporkbytes/purchase-order-utils';

import ApprovedConfirmedStatus from 'components/content/ApprovedConfirmedStatus';
import DateOutput from 'components/content/DateOutput';
import Link from 'components/routing/Link';
import Table from 'components/content/Table';

const PurchaseOrdersTable = ({ hiddenColumns, purchaseOrders, ...props }) => {
	purchaseOrders.forEach(
		purchaseOrder =>
			(purchaseOrder.payout = totalReceivedByPartner(purchaseOrder))
	);

	return (
		<Table
			data={purchaseOrders}
			columns={[
				{
					title: 'Order Number',
					field: 'MealOption.MealProposal.orderNumber',
					render: ({
						MealOption: {
							MealProposal: { orderNumberText },
						},
						id,
						...purchaseOrder
					}) => {
						const approved = isApproved(purchaseOrder);
						const confirmed = isConfirmed(purchaseOrder);

						return (
							<ApprovedConfirmedStatus
								approved={approved}
								confirmed={confirmed}
								text={
									<Link
										routeName="publicPurchaseOrdersDetail"
										variables={{ id }}
									>
										{orderNumberText}
									</Link>
								}
							/>
						);
					},
				},
				{
					title: 'Partner',
					field: 'PartnerLocation.Partner.name',
				},
				{
					title: 'Location',
					field: 'PartnerLocation.streetAddress',
				},
				{
					title: 'Pickup Time',
					field: 'pickupTime',
					type: 'datetime',
					render: ({ pickupTime, PartnerLocation: { timezone } }) => (
						<DateOutput
							date={pickupTime}
							timezone={timezone}
							formatter="dateAndTimeMedium"
						/>
					),
				},
				{
					title: 'Head Count',
					field: 'MealOption.MealProposal.headCount',
				},
				{
					title: 'Payout',
					field: 'payout',
					render: ({ payout }) => <Currency value={payout} />,
				},
			].filter(({ field }) => !hiddenColumns.includes(field))}
			{...props}
		/>
	);
};

PurchaseOrdersTable.propTypes = {
	hiddenColumns: PropTypes.arrayOf(PropTypes.string),
	purchaseOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
};

PurchaseOrdersTable.defaultProps = {
	hiddenColumns: [],
};

export default PurchaseOrdersTable;
