import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { Rating } from '@sporkbytes/material-ui-kit-react';
import { roundNumberToDigits } from '@sporkbytes/math-utils';

const ReviewsSummary = ({ count, rating }) => (
	<Tooltip
		title={
			rating
				? `${roundNumberToDigits(rating, 1)} score on ${count} reviews`
				: 'No reviews yet'
		}
	>
		{/* The Tooltip won't appear without this wrapping div.  This might be fixed in a future version of Rating. */}
		<div>
			<Rating value={rating} precision={0.1} readOnly size="large" />
		</div>
	</Tooltip>
);

ReviewsSummary.propTypes = {
	count: PropTypes.number,
	rating: PropTypes.number,
};

export default ReviewsSummary;
