import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash.startcase';
import {
	DialogForm,
	LinkedCheckboxGroup,
} from '@sporkbytes/material-ui-kit-react';

import * as yup from 'models/validation';

const ChecklistDialogForm = ({
	entityType,
	initialValues,
	minSelected,
	onSubmit,
	options,
	...props
}) => {
	return (
		<DialogForm
			{...props}
			validationSchema={yup.object().shape({
				options: yup
					.array()
					.of(yup.string())
					.min(
						minSelected,
						`Please select at least ${minSelected} ${
							minSelected === 1
								? `${entityType}`
								: `${entityType}s`
						}.`
					),
			})}
			initialValues={{
				options: initialValues,
			}}
			onSubmit={({ options }) => onSubmit(options)}
			enableReinitialize // This is needed since this form has a loading state and will need to reset the initial values once the data become available in the parent component
		>
			{() => (
				<LinkedCheckboxGroup
					name="options"
					label=""
					options={options}
					getOptionLabel={field => field.label || startCase(field.id)}
					getOptionKey={field => field.id}
					getOptionValue={field => field.id}
					getOptionType={field => field.type}
				/>
			)}
		</DialogForm>
	);
};

ChecklistDialogForm.propTypes = {
	entityType: PropTypes.string,
	initialValues: PropTypes.arrayOf(PropTypes.string),
	minSelected: PropTypes.number,
	onSubmit: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.string,
			type: PropTypes.string,
		})
	),
};

ChecklistDialogForm.defaultProps = {
	entityType: 'item',
	initialValues: [],
	minSelected: 0,
	options: [],
};

export default ChecklistDialogForm;
