import React from 'react';
import PropTypes from 'prop-types';
import { getContacts } from '@sporkbytes/api-client/Accounting';
import { LinkedAsyncAutocomplete } from '@sporkbytes/material-ui-kit-react';

import axios from 'services/data';

const AccountingContactSearch = ({ name, ...props }) => {
	return (
		<LinkedAsyncAutocomplete
			getSearchResults={async searchTerm => {
				const results = await getContacts(axios, {
					Name: `Contains|${searchTerm}`,
				});

				return results;
			}}
			getOptionLabel={option => option.name}
			name={name}
			placeholder="Search for an Accounting Contact"
			{...props}
		/>
	);
};

AccountingContactSearch.propTypes = {
	name: PropTypes.string,
};

export default AccountingContactSearch;
