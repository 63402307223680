import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Chart from 'react-apexcharts';
import { getFeeDiscountActual } from '@sporkbytes/meal-proposal-utils';
import startCase from 'lodash.startcase';

import LoadingStateContainer from 'components/utilities/LoadingStateContainer';
import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import { formatCurrency, formatGroupedDate } from 'services/mealProposal';

const FEE_QUERY = gql`
	query DiscountsByTimePeriod {
		Fees(order_by: { name: asc }) {
			id
			name
		}
	}
`;

const DiscountsByTimePeriod = () => {
	const { data, loading } = useQuery(FEE_QUERY);

	const { chartConfig, filters, groupedMealProposalsForCalculations } =
		useContext(AnalyticsContext);

	const options = {
		...chartConfig.options,
		chart: {
			...chartConfig.options.chart,
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: groupedMealProposalsForCalculations.map(
				({ date }) => date
			),
			labels: {
				formatter: value => formatGroupedDate(value, filters.groupBy),
			},
		},
		yaxis: {
			labels: {
				formatter: formatCurrency,
			},
		},
	};

	const series =
		data?.Fees.map(({ id, name }) => ({
			name,
			data: groupedMealProposalsForCalculations.map(({ MealProposals }) =>
				getFeeDiscountActual(id, MealProposals)
			),
		})) ?? [];

	return (
		<LoadingStateContainer loading={loading}>
			<WidgetTitle>Discounts by {startCase(filters.groupBy)}</WidgetTitle>
			<Chart
				type="bar"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</LoadingStateContainer>
	);
};

DiscountsByTimePeriod.fragments = {
	details: gql`
		fragment DiscountsByTimePeriodDetails on MealProposals {
			deliveryDate
			headCount
			MealProposalFees {
				amount
				discount
				type
				FeeId
			}
			MealOptions {
				approved
				gratuity
				PurchaseOrders {
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							costToClient
							discount
							quantity
						}
					}
				}
			}
		}
	`,
};

export default DiscountsByTimePeriod;
