import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Button,
	CardContent,
	Divider,
	Grid,
	IconButton,
	makeStyles,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { Info, Mail, Search } from '@material-ui/icons';
import { addMinutes } from 'date-fns';
import { Formik } from 'formik';
import { get } from '@sporkbytes/api-client/PublicMealProposals';
import { approve, confirm } from '@sporkbytes/api-client/PublicMealOptions';
import { phoneNumbers } from '@sporkbytes/config';
import {
	Alert,
	Currency,
	GridContainer,
	LinkedCheckbox,
	PhoneNumber,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';
import {
	canApprove,
	canConfirm,
	totalClientPays,
} from '@sporkbytes/meal-option-utils';

import ApproveButton from 'components/utilities/ApproveButton';
import ApprovedConfirmedStatus from 'components/content/ApprovedConfirmedStatus';
import CancellationPolicyDialog from 'components/utilities/CancellationPolicyDialog';
import ConfirmButton from 'components/utilities/ConfirmButton';
import DateOutput from 'components/content/DateOutput';
import HeadCount from 'components/content/HeadCount';
import InlineContent from 'components/content/InlineContent';
import LabelValue from 'components/content/LabelValue';
import LoadingPage from 'components/content/LoadingPage';
import NotFoundPage from 'components/content/NotFoundPage';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PricingDetailsDialog from 'components/content/PricingDetailsDialog';
import PurchaseOrderPrintableMenu from 'components/purchase-orders/PurchaseOrderPrintableMenu';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';

import useRequest from 'hooks/useRequest';

import { useStore } from 'models/store';

import { sendEmail } from 'services/utilities';
import axios from 'services/data';

const useStyles = makeStyles(theme => ({
	cancellationPolicyDialogButton: {
		marginLeft: theme.spacing(1),
	},
	divider: {
		margin: theme.spacing(6, 0, 6, 0),
	},
	mealOptionText: {
		margin: theme.spacing(4),
		textAlign: 'center',
	},
	pricingDetailsButton: {
		margin: theme.spacing(3),
	},
	status: {
		flexDirection: 'column',
	},
}));

const MealProposalDetail = props => {
	const { id } = useParams();
	const classes = useStyles();
	const {
		loading,
		data: mealProposal,
		refetch,
	} = useRequest(() => get(axios, id));
	const { showDialog } = useStore();

	if (loading) {
		return <LoadingPage />;
	}

	if (!mealProposal) {
		return (
			<NotFoundPage
				body="No meal proposal was found. It's possible that it has not yet been completed."
				title="Meal Proposal Not Found"
			/>
		);
	}

	const {
		ClientLocation,
		Contacts,
		deliveryDate,
		headCount,
		MealOptions,
		name,
		orderNumberText,
		SporkContact,
	} = mealProposal;

	const OnTimePolicyDateOutput = ({ minutesToAdd }) => (
		<DateOutput
			date={addMinutes(new Date(deliveryDate), minutesToAdd)}
			timezone={ClientLocation.timezone}
			formatter="time"
			component="span"
		/>
	);

	// TODO: add ability for clients to approve meal options
	return (
		<Page title={`Meal Proposal ${orderNumberText}`}>
			{mealProposal.status === 'Canceled' && (
				<Alert type="error" size="large">
					This meal has been canceled.
				</Alert>
			)}
			<PageHeader
				imgUrl={ClientLocation.Client.logoUrl}
				headerText={ClientLocation.Client.name}
			/>
			<PageSubheader>Meal Proposal - {orderNumberText}</PageSubheader>
			{name && <PageSubheader>{name}</PageSubheader>}
			<GridContainer>
				<LabelValue
					headingLevel={3}
					label="Ready to Eat Time"
					value={
						<div>
							<InlineContent>
								<Tooltip
									title={
										<Typography
											align="center"
											variant="body2"
										>
											On Time Policy: Your meal will be
											ready to eat between{' '}
											<OnTimePolicyDateOutput
												minutesToAdd={-10}
											/>
											and{' '}
											<OnTimePolicyDateOutput
												minutesToAdd={10}
											/>
											.
										</Typography>
									}
								>
									<Info />
								</Tooltip>
							</InlineContent>
							<DateOutput
								date={deliveryDate}
								timezone={ClientLocation.timezone}
								display="inline"
								variant="body1"
							/>
						</div>
					}
				/>
				<LabelValue
					headingLevel={3}
					label="Location"
					value={ClientLocation.fullAddress}
				/>
			</GridContainer>
			<GridContainer>
				<LabelValue
					headingLevel={3}
					label="Head Count"
					value={<HeadCount value={headCount} />}
				/>
				<LabelValue
					headingLevel={3}
					label="Spork Contact"
					value={
						<Typography>
							{SporkContact.fullName}
							<Tooltip title={`Email ${SporkContact.fullName}`}>
								<IconButton
									onClick={() =>
										sendEmail(SporkContact.emailAddress)
									}
								>
									<Mail />
								</IconButton>
							</Tooltip>
						</Typography>
					}
				/>
				<LabelValue
					headingLevel={3}
					label={`${ClientLocation.Client.name} Contacts`}
					value={
						<Grid container direction="column" alignItems="center">
							{Contacts.map(({ id, fullName }) => (
								<Typography key={id}>{fullName}</Typography>
							))}
						</Grid>
					}
				/>
			</GridContainer>
			<Typography paragraph>
				Once your meal has been delivered, an electronic invoice will be
				sent to you via email that you can pay online or by check.
				Pricing details can be opened at the bottom of the page.
			</Typography>
			<Typography paragraph>
				If there are any changes that need to be made please contact{' '}
				{SporkContact.fullName} ASAP at{' '}
				<a href={`mailto:${SporkContact.emailAddress}`}>
					{SporkContact.emailAddress}
				</a>{' '}
				or call{' '}
				<PhoneNumber
					value={phoneNumbers.orderHelp}
					renderText={phoneNumber => (
						<a href={`tel:${phoneNumbers.orderHelp}`}>
							{phoneNumber}
						</a>
					)}
				/>
			</Typography>
			<Typography variant="h4" className={classes.mealOptionText}>
				Meal Options
			</Typography>
			<QueryStringSyncedTabs
				id="meal-option"
				tabs={MealOptions.map(mealOption => ({
					id: mealOption.id,
					label: (
						<Grid
							container
							wrap="nowrap"
							alignItems="center"
							justify="center"
						>
							<ApprovedConfirmedStatus
								approved={mealOption.approved}
								confirmed={mealOption.confirmed}
								setType={false}
								text={
									<div>
										<Typography>
											{mealOption.name}
										</Typography>
										<Currency
											value={totalClientPays(
												mealOption,
												mealProposal
											)}
										/>
									</div>
								}
								className={classes.status}
							/>
						</Grid>
					),
					content: (
						<CardContent>
							{canApprove({
								...mealOption,
								MealProposal: mealProposal,
							}) && (
								<Formik
									initialValues={{
										cancellationPolicyAccepted: false,
									}}
								>
									{({ values }) => (
										<>
											<GridContainer>
												<LinkedCheckbox
													name="cancellationPolicyAccepted"
													label={
														<>
															I have confirmed
															that the date, time,
															and location of this
															meal are correct and
															accept the terms of
															the cancellation
															policy.
														</>
													}
												/>
											</GridContainer>
											<GridContainer
												justify="center"
												wrapChildrenWithGridItem={false}
											>
												<ApproveButton
													afterApprove={refetch}
													approve={approve}
													id={mealOption.id}
													disabled={
														!values.cancellationPolicyAccepted
													}
												/>
												<Button
													onClick={() =>
														showDialog(
															CancellationPolicyDialog
														)
													}
													className={
														classes.cancellationPolicyDialogButton
													}
												>
													View Cancellation Policy
												</Button>
											</GridContainer>
											<Divider
												className={classes.divider}
											/>
										</>
									)}
								</Formik>
							)}
							{canConfirm({
								...mealOption,
								MealProposal: mealProposal,
							}) && (
								<>
									<GridContainer
										justify="center"
										wrapChildrenWithGridItem={false}
									>
										<ConfirmButton
											afterConfirm={refetch}
											confirm={confirm}
											id={mealOption.id}
										/>
									</GridContainer>
									<Divider className={classes.divider} />
								</>
							)}
							{mealOption.PurchaseOrders.map(purchaseOrder => (
								<PurchaseOrderPrintableMenu
									key={purchaseOrder.id}
									purchaseOrder={purchaseOrder}
								/>
							))}
							<Divider className={classes.divider} />
							<Grid
								container
								direction="column"
								alignItems="center"
							>
								<Typography variant="h4">
									Total:{' '}
									<Currency
										value={totalClientPays(
											mealOption,
											mealProposal
										)}
									/>
								</Typography>
								<PrimaryButton
									color="secondary"
									startIcon={<Search />}
									onClick={() =>
										showDialog(PricingDetailsDialog, {
											mealOption,
											mealProposal,
										})
									}
									className={classes.pricingDetailsButton}
								>
									View Pricing Details
								</PrimaryButton>
							</Grid>
						</CardContent>
					),
				}))}
			/>
		</Page>
	);
};

export default MealProposalDetail;
