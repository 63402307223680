import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	layoutMain: {
		marginTop: theme.sizes.appToolbar,
		[theme.breakpoints.up('md')]: {
			marginBottom: theme.sizes.footer,
		},
		'@media print': {
			margin: 0,
		},
	},
}));

const Main = ({ children }) => {
	const classes = useStyles();

	return <main className={classes.layoutMain}>{children}</main>;
};

export default Main;
