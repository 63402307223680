import React from 'react';
import { post } from '@sporkbytes/api-client/Fees';

import FeeForm from 'components/fees/FeeForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { useStore } from 'models/store';

const Create = () => {
	const { selectedSporkLocationId } = useStore();
	const initialValues = {
		SporkLocationId: selectedSporkLocationId,
	};

	return (
		<Page title="Add New Fee">
			<PageHeader headerText="Add New Fee" />
			<FormWrapper
				form={FeeForm}
				initialValues={initialValues}
				onSubmit={post}
			/>
		</Page>
	);
};

export default Create;
