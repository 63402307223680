import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MoreVert } from '@material-ui/icons';
import {
	CircularProgress,
	IconButton,
	ListItemIcon,
	makeStyles,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	circularProgress: {
		marginLeft: 10, // This is needed to ensure that the CircularProgress and IconButton are not offset from on another
	},
	error: {
		color: theme.palette.error[800],
	},
	icon: {
		minWidth: 40,
	},
	menuItem: {
		minWidth: 150,
		padding: theme.spacing(1, 2),
	},
	success: {
		color: theme.palette.success[800],
	},
	warning: {
		color: theme.palette.warning[800],
	},
}));

const ActionMenu = ({ options, icon, loading, ...props }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const canPerformActions = options.some(
		({ canPerformAction = true }) => canPerformAction
	);

	const openMenu = event => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = event => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	if (!canPerformActions) {
		return null;
	}

	if (loading) {
		return (
			<CircularProgress size={30} className={classes.circularProgress} />
		);
	}

	if (options.length === 1) {
		const [firstOption] = options;

		return (
			<Tooltip title={firstOption.label}>
				<IconButton
					onClick={event => {
						event.stopPropagation();
						firstOption.onClick(event);
					}}
					color="inherit"
					className={classes[firstOption.type]}
				>
					{firstOption.icon}
				</IconButton>
			</Tooltip>
		);
	}

	return (
		<>
			<IconButton onClick={openMenu} color="inherit">
				{icon || <MoreVert />}
			</IconButton>
			<Menu
				open={open}
				onClose={closeMenu}
				anchorEl={anchorEl}
				{...props}
			>
				{options.map(
					({
						canPerformAction = true,
						divider,
						icon,
						label,
						onClick,
						type,
					}) => (
						<MenuItem
							onClick={event => {
								onClick(event);
								closeMenu(event);
							}}
							divider={divider}
							className={classes.menuItem}
							key={label}
							disabled={!canPerformAction}
						>
							<ListItemIcon
								className={`${classes.icon} ${
									type ? classes[type] : ''
								}`}
							>
								{icon}
							</ListItemIcon>
							<Typography className={classes[type]}>
								{label}
							</Typography>
						</MenuItem>
					)
				)}
			</Menu>
		</>
	);
};

ActionMenu.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			canPerformAction: PropTypes.bool,
			divider: PropTypes.bool,
			icon: PropTypes.node, // this is not required because state machines have transition states where they are processing data asynchronously and therefore don't need an icon
			label: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired,
			type: PropTypes.string,
		})
	),
	icon: PropTypes.element,
	loading: PropTypes.bool,
};

export default ActionMenu;
