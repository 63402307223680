import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { addNewContact } from '@sporkbytes/api-client/PartnerLocations';

import ContactForm from 'components/contacts/ContactForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

const PARTNER_LOCATION_QUERY = gql`
	query ContactCreate($id: uuid!) {
		PartnerLocations_by_pk(id: $id) {
			friendlyName
			fullAddress
			Partner {
				logoUrl
				name
			}
		}
	}
`;

const ContactCreate = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ PartnerLocations_by_pk: { friendlyName } }) =>
				`New Contact for ${friendlyName}`
			}
			query={PARTNER_LOCATION_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					PartnerLocations_by_pk: { friendlyName, Partner },
				},
			}) => (
				<>
					<PageHeader
						imgUrl={Partner.logoUrl}
						headerText={Partner.name}
					/>
					<PageSubheader>
						<div>{friendlyName}</div>
						<div>Add New Contact</div>
					</PageSubheader>
					<FormWrapper
						form={ContactForm}
						onSubmit={(axios, values) =>
							addNewContact(axios, id, values)
						}
					/>
				</>
			)}
		</Page>
	);
};

export default ContactCreate;
