import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import { updateContentWithSubstitutions } from '@sporkbytes/email-template-utils';

import EmailTemplateForm from 'components/email-templates/EmailTemplateForm';

const EMAIL_TEMPLATE_QUERY = gql`
	query SendEmailDialogForm($id: String!) {
		EmailTemplates_by_pk(id: $id) {
			greeting
			buttonText
			preButtonContent
			postButtonContent
			warningText
			signature
		}
	}
`;

const SendEmailDialogForm = ({ emailTemplateId, substitutions, ...props }) => {
	return (
		<Query query={EMAIL_TEMPLATE_QUERY} variables={{ id: emailTemplateId }}>
			{({ data, loading }) => (
				<EmailTemplateForm
					displayIn="dialog"
					submitButtonContent="Send"
					initialValues={updateContentWithSubstitutions(
						data?.EmailTemplates_by_pk,
						substitutions
					)}
					DialogProps={{
						loading,
					}}
					showIdentifierFields={false}
					{...props}
				/>
			)}
		</Query>
	);
};

SendEmailDialogForm.propTypes = {
	emailTemplateId: PropTypes.string.isRequired,
	substitutions: PropTypes.object.isRequired,
};

export default SendEmailDialogForm;
