import React from 'react';
import {
	Form,
	GridContainer,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import * as yup from 'models/validation';

const validationSchema = yup.object().shape({
	name: yup.name().required(),
	abbreviation: yup
		.string()
		.max(2, 'Please limit to 2 characters.')
		.matches(/^[A-Z]+$/, 'Only uppercase letters are allowed.')
		.required('Please enter an abbreviation.'),
});

const ServicedStateForm = props => {
	return (
		<Form {...props} validationSchema={validationSchema}>
			{() => (
				<>
					<GridContainer>
						<LinkedTextInput name="name" autoFocus />
						<LinkedTextInput
							name="abbreviation"
							inputProps={{
								maxLength: 2,
							}}
						/>
					</GridContainer>
				</>
			)}
		</Form>
	);
};

export default ServicedStateForm;
