import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import { AsyncAutocomplete, Avatar } from '@sporkbytes/material-ui-kit-react';

import apolloClient from 'services/apollo';
import { getMultiWordSearchWhereClause } from 'services/search';

const getPartnerSearchQuery = (where, searchTerm) => gql`
	query PartnerSearchQuery {
		Partners(
			limit: 20
			order_by: { name: asc }
			where: {
				_and: [
					${where}
					${getMultiWordSearchWhereClause(searchTerm)}
				]
			}
		) {
			id
			name
			logoUrl
		}
	}
`;

const PartnerSearch = ({ onChange, where, ...props }) => (
	<AsyncAutocomplete
		getSearchResults={async searchTerm => {
			const {
				data: { Partners },
			} = await apolloClient.query({
				query: getPartnerSearchQuery(where, searchTerm),
			});

			return Partners;
		}}
		getOptionLabel={option => option.name}
		onChange={onChange}
		renderOption={option => (
			<>
				<ListItemAvatar>
					<Avatar
						alt={option.name}
						src={option.logoUrl}
						size="small"
					/>
				</ListItemAvatar>
				<ListItemText primary={option.name} />
			</>
		)}
		{...props}
	/>
);

PartnerSearch.propTypes = {
	onChange: PropTypes.func.isRequired,
	where: PropTypes.string,
};

PartnerSearch.defaultProps = {
	where: '',
};

export default PartnerSearch;
