import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Divider, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	title: {
		marginTop: theme.spacing(2),
	},
	children: {
		margin: theme.spacing(1, 0, 4, 0),
	},
}));

const DetailSection = ({ children, title }) => {
	const classes = useStyles();

	return (
		<>
			{title && (
				<>
					<Typography
						component="h3"
						variant="subtitle1"
						className={classes.title}
					>
						{title}
					</Typography>
					<Divider />
				</>
			)}
			<div className={classes.children}>{children}</div>
		</>
	);
};

DetailSection.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
};

export default DetailSection;
