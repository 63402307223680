import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { LinkedAsyncAutocomplete } from '@sporkbytes/material-ui-kit-react';

import apolloClient from 'services/apollo';
import { getMultiWordSearchWhereClause } from 'services/search';

const getPartnerMenuSectionSearchQuery = (where, searchTerm) => gql`
	query PartnerMenuSectionSearchQuery {
		PartnerMenuSections(
			limit: 20
			order_by: { name: asc }
			where: { 
				${where}
				_and: [${getMultiWordSearchWhereClause(searchTerm)}]
			}
		) {
			id
			name
		}
	}
`;

const PartnerMenuSectionSearch = ({ name, where, ...props }) => {
	return (
		<LinkedAsyncAutocomplete
			getSearchResults={async searchTerm => {
				const {
					data: { PartnerMenuSections },
				} = await apolloClient.query({
					query: getPartnerMenuSectionSearchQuery(where, searchTerm),
				});

				return PartnerMenuSections;
			}}
			getOptionLabel={option => option.name}
			name={name}
			{...props}
		/>
	);
};

PartnerMenuSectionSearch.propTypes = {
	where: PropTypes.string,
};

export default PartnerMenuSectionSearch;
