import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, makeStyles } from '@material-ui/core';
import {
	AccessTime,
	Person,
	AttachMoney,
	Help,
	People,
	Phone,
} from '@material-ui/icons';
import {
	Form,
	GridContainer,
	LinkedCurrencyInput,
	LinkedIntegerInput,
	LinkedPhoneNumberInput,
	LinkedRichTextEditor,
	LinkedSingleSelect,
	LinkedSwitch,
	LinkedTextInput,
	LinkedZIPCodeInput,
} from '@sporkbytes/material-ui-kit-react';

import AccountingContactSearch from 'components/accounting/AccountingContactSearch';
import AddressSearch from 'components/forms/AddressSearch';
import FormSectionHeader from 'components/forms/FormSectionHeader';
import StateSelector from 'components/forms/StateSelector';

import { useAuth } from 'services/auth';
import * as yup from 'models/validation';

const useStyles = makeStyles(theme => ({
	accountingContactLabel: {
		marginBottom: theme.spacing(1),
	},
}));

const PartnerLocationForm = ({
	addressSearchDisabled,
	defaultAccountingContact,
	...props
}) => {
	const { userCan } = useAuth();
	const canEditAddresses = userCan(['partner-locations:edit-address']);
	const classes = useStyles();

	const validationSchema = yup.object().shape({
		name: yup.name(),
		phoneNumber: yup.phoneNumber(),
		AccountingContactId: yup
			.string()
			.required('Please select an Accounting Contact.'),
		howToOrder: yup.string(),
		pickupInstructions: yup.string(),
		preferredPickupTimes: yup.string(),
		streetAddress: yup.streetAddress().required(),
		streetAddress2: yup.string(),
		city: yup.city().required(),
		state: yup.state().required(),
		zipCode: yup.zipCode().required(),
		minimumOrderHeadCount: yup.positiveInteger(),
		maximumOrderHeadCount: yup.positiveInteger(),
		minimumOrderAmount: yup.positiveNumber(),
		maximumOrderAmount: yup.positiveNumber(),
		minimumLeadTimeAmount: yup.positiveInteger(),
		minimumLeadTimeScale: yup.mixed().oneOf(['hours', 'days']),
		timeToPark: yup.timeToPark().required(),
		timeToEnter: yup.timeToEnter().required(),
		timeToExit: yup.timeToExit().required(),
		automatedEmailConfirmation: yup.boolean(),
	});

	return (
		<Form {...props} validationSchema={validationSchema}>
			{({ values, setValues }) => (
				<>
					<FormSectionHeader>Basic Info</FormSectionHeader>
					<GridContainer>
						<LinkedTextInput name="name" autoFocus />
						<LinkedPhoneNumberInput
							name="phoneNumber"
							icon={<Phone />}
						/>
					</GridContainer>
					<GridContainer>
						<div>
							<InputLabel
								className={classes.accountingContactLabel}
							>
								Accounting Contact
							</InputLabel>
							<AccountingContactSearch
								name="AccountingContactId"
								getOptionValue={option => option.contactID}
								defaultValue={defaultAccountingContact}
								label="Accounting Contact"
							/>
						</div>
					</GridContainer>
					<GridContainer>
						<LinkedRichTextEditor
							name="howToOrder"
							icon={<Help />}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedRichTextEditor name="pickupInstructions" />
					</GridContainer>
					<FormSectionHeader>Address</FormSectionHeader>
					<GridContainer>
						<AddressSearch
							disabled={addressSearchDisabled}
							onChange={addressComponents =>
								setValues({ ...values, ...addressComponents })
							}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedTextInput
							name="streetAddress"
							disabled={!canEditAddresses}
						/>
						<LinkedTextInput
							name="streetAddress2"
							disabled={!canEditAddresses}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedTextInput
							name="city"
							disabled={!canEditAddresses}
						/>
						<StateSelector
							name="state"
							disabled={!canEditAddresses}
						/>
						<LinkedZIPCodeInput
							name="zipCode"
							disabled={!canEditAddresses}
						/>
					</GridContainer>
					<FormSectionHeader>Order Scale</FormSectionHeader>
					<GridContainer>
						<LinkedIntegerInput
							name="minimumOrderHeadCount"
							icon={<Person />}
						/>
						<LinkedIntegerInput
							name="maximumOrderHeadCount"
							icon={<People />}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedCurrencyInput
							name="minimumOrderAmount"
							icon={<AttachMoney />}
						/>
						<LinkedCurrencyInput
							name="maximumOrderAmount"
							icon={<AttachMoney />}
						/>
					</GridContainer>
					<FormSectionHeader>Lead Times</FormSectionHeader>
					<GridContainer>
						<LinkedIntegerInput
							name="minimumLeadTimeAmount"
							icon={<AccessTime />}
						/>
						<LinkedSingleSelect
							name="minimumLeadTimeScale"
							emptyOptionLabel="None"
							getOptionLabel={option => option.label}
							getOptionValue={option => option.value}
							getOptionKey={option => option.value}
							options={[
								{ label: 'Hours', value: 'hours' },
								{ label: 'Days', value: 'days' },
							]}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedRichTextEditor name="preferredPickupTimes" />
					</GridContainer>
					<FormSectionHeader>Access Times</FormSectionHeader>
					<GridContainer>
						<LinkedIntegerInput
							name="timeToPark"
							label="Time To Park (minutes)"
							icon={<AccessTime />}
						/>
						<LinkedIntegerInput
							name="timeToEnter"
							label="Time To Enter (minutes)"
							icon={<AccessTime />}
						/>
						<LinkedIntegerInput
							name="timeToExit"
							label="Time To Exit (minutes)"
							icon={<AccessTime />}
						/>
					</GridContainer>
					<FormSectionHeader>Automated Emails</FormSectionHeader>
					<GridContainer>
						<LinkedSwitch
							name="automatedEmailConfirmation"
							uncheckedHelperText="No"
							checkedHelperText="Yes"
						/>
					</GridContainer>
				</>
			)}
		</Form>
	);
};

PartnerLocationForm.propTypes = {
	addressSearchDisabled: PropTypes.bool,
	defaultAccountingContact: PropTypes.object,
};

export default PartnerLocationForm;
