import { addDays, format } from 'date-fns';
import { getFeeBeforeDiscount } from '@sporkbytes/meal-option-utils';
import {
	getIncludedItems,
	totalReceivedByPartner,
} from '@sporkbytes/purchase-order-utils';

export { createBill, getLineItemForBill, getLineItemsForInvoice };

function createBill(billDefaults = {}, metadataDefaults = {}) {
	return {
		...metadataDefaults,
		bill: {
			...billDefaults,
			dueDate: format(addDays(new Date(), 8), 'yyyy-MM-dd'),
			type: 'ACCPAY',
		},
	};
}

function getLineItemForBill(mealProposal, purchaseOrder, itemCode) {
	return {
		description: `${format(new Date(mealProposal.deliveryDate), 'MM/dd')} ${
			mealProposal.orderNumberText
		}`,
		quantity: 1,
		unitAmount: totalReceivedByPartner(purchaseOrder),
		itemCode,
	};
}

function getLineItemsForInvoice(defaultItemCode) {
	return function (mealProposal) {
		let lineItems = [];

		mealProposal.MealOptions[0].PurchaseOrders.forEach(purchaseOrder => {
			lineItems = [
				...lineItems,
				...getIncludedItems(purchaseOrder).map(
					({
						costToClient,
						discount,
						quantity,
						PartnerMenuItem,
					}) => ({
						description: `${format(
							new Date(mealProposal.deliveryDate),
							'MM/dd'
						)} ${purchaseOrder.PartnerLocation.Partner.name} - ${
							PartnerMenuItem.friendlyName
						}`,
						discountRate: discount,
						quantity,
						unitAmount: costToClient || 0,
						itemCode: defaultItemCode,
					})
				),
			];
		});

		mealProposal.MealProposalFees.forEach(mealProposalFee => {
			lineItems = [
				...lineItems,
				{
					description: `${format(
						new Date(mealProposal.deliveryDate),
						'MM/dd'
					)} ${mealProposalFee.name}`,
					discountRate: mealProposalFee.discount,
					quantity:
						mealProposalFee.type === 'per person'
							? mealProposal.headCount
							: 1,
					unitAmount:
						mealProposalFee.type === 'per person'
							? mealProposalFee.amount
							: getFeeBeforeDiscount(
									mealProposalFee,
									mealProposal.MealOptions[0],
									mealProposal.headCount
							  ),
					itemCode:
						mealProposalFee.Fee?.AccountingSetting?.value ??
						defaultItemCode,
				},
			];
		});

		return lineItems;
	};
}
