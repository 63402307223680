import React from 'react';
import PropTypes from 'prop-types';
import { ToggleOff, ToggleOn } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	toggleOn: {
		color: theme.palette.success[500],
	},
	toggleOff: {
		color: theme.palette.error[500],
	},
}));

const ToggleIcon = ({ on }) => {
	const classes = useStyles();

	return on ? (
		<ToggleOn className={classes.toggleOn} />
	) : (
		<ToggleOff className={classes.toggleOff} />
	);
};

ToggleIcon.propTypes = {
	on: PropTypes.bool,
};

export default ToggleIcon;
