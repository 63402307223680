import Fees from 'pages/fees/Fees';
import Create from 'pages/fees/Create';
import Edit from 'pages/fees/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'fees',
		path: '',
		component: Fees,
		permissions: ['fees:view'],
		exact: true,
	},
	{
		name: 'feesCreate',
		path: '/create',
		component: Create,
		permissions: ['fees:create'],
		exact: true,
	},
	{
		name: 'feesEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['fees:edit'],
		exact: true,
	},
];

export default prependUrlPath('fees', routes);
