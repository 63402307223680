import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';

import Page from 'components/layout/Page';
import PartnerMenuItemPrintableLabel from 'components/partner-menu-items/PartnerMenuItemPrintableLabel';
import PrintSettings from 'components/printable-labels/PrintSettings';

const PARTNER_MENU_ITEM_QUERY = gql`
	query PartnerPrintableLabels($id: uuid!) {
		PartnerMenuItems(
			where: {
				active: { _eq: true }
				PartnerId: { _eq: $id }
				producesLabel: { _eq: true }
			}
			order_by: { friendlyName: asc }
		) {
			id
			...PartnerMenuItemPrintableLabelDetails
		}
		Partners_by_pk(id: $id) {
			name
		}
	}
	${PartnerMenuItemPrintableLabel.fragments.details}
`;

const PrintableLabels = () => {
	const { id } = useParams();

	return (
		<Page
			query={PARTNER_MENU_ITEM_QUERY}
			variables={{ id }}
			title={({ Partners_by_pk: { name } }) => `Print Labels for ${name}`}
		>
			{({
				data: {
					PartnerMenuItems,
					Partners_by_pk: { name },
				},
			}) => (
				<PrintSettings
					initialValues={{
						groups: [
							{
								id: 'item-options',
								name: 'Item Options',
								items: PartnerMenuItems.map(
									partnerMenuItem => ({
										...partnerMenuItem,
										quantity: 1,
									})
								),
							},
						],
					}}
					title={`Labels for ${name}`}
				/>
			)}
		</Page>
	);
};

export default PrintableLabels;
