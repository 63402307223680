import React from 'react';
import gql from 'graphql-tag';
import { Tooltip, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { getClientApproved } from '@sporkbytes/meal-option-utils';

import NamedAvatar from 'components/content/NamedAvatar';
import TimeAndPlace from 'components/content/TimeAndPlace';

import { formatZonedTime } from 'services/dates';

const PickupAvatar = ({ mealProposal }) => {
	const approvedMealOption = getClientApproved(mealProposal.MealOptions);

	if (!approvedMealOption) {
		return (
			<Tooltip title="No meal option approved">
				<HelpOutline />
			</Tooltip>
		);
	}

	const purchaseOrders = approvedMealOption.PurchaseOrders;
	const hasMultiplePickups = purchaseOrders.length > 1;
	const [firstPurchaseOrder] = purchaseOrders;

	let avatar;
	let tooltip;

	if (hasMultiplePickups) {
		avatar = <NamedAvatar name={purchaseOrders.length.toString()} />;

		tooltip = purchaseOrders.map(
			({ id, pickupTime, PartnerLocation: { timezone, Partner } }) => (
				<TimeAndPlace
					key={id}
					time={pickupTime}
					timezone={timezone}
					place={Partner.name}
					align="center"
				/>
			)
		);
	} else {
		avatar = (
			<NamedAvatar
				imageUrl={firstPurchaseOrder.PartnerLocation.Partner.logoUrl}
				alt={firstPurchaseOrder.PartnerLocation.Partner.name}
				name={firstPurchaseOrder.PartnerLocation.Partner.name}
			/>
		);
		tooltip = (
			<TimeAndPlace
				time={firstPurchaseOrder.pickupTime}
				timezone={firstPurchaseOrder.PartnerLocation.timezone}
				place={firstPurchaseOrder.PartnerLocation.Partner.name}
			/>
		);
	}

	return (
		<>
			<Tooltip title={tooltip}>{avatar}</Tooltip>
			<Typography>
				{formatZonedTime(
					firstPurchaseOrder.pickupTime,
					'time',
					firstPurchaseOrder.PartnerLocation.timezone
				)}
			</Typography>
		</>
	);
};

PickupAvatar.fragments = {
	details: gql`
		fragment PickupAvatar on MealProposals {
			MealOptions(where: { approved: { _eq: true } }) {
				approved
				PurchaseOrders(order_by: { pickupTime: asc }) {
					id
					pickupTime
					PartnerLocation {
						timezone
						Partner {
							name
							logoUrl
						}
					}
				}
			}
		}
	`,
};

export default PickupAvatar;
