import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ListItemText } from '@material-ui/core';
import { TrendingFlat } from '@material-ui/icons';
import gql from 'graphql-tag';
import { put, sort } from '@sporkbytes/api-client/PartnerMenuItems';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';
import { DialogForm } from '@sporkbytes/material-ui-kit-react';

import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import DetailPageLink from 'components/routing/DetailPageLink';
import EditableSortOrder from 'components/forms/EditableSortOrder';
import EditableValue from 'components/forms/EditableValue';
import NoContentText from 'components/content/NoContentText';
import PartnerMenuItemActionMenu from 'components/partner-menu-items/PartnerMenuItemActionMenu';
import PartnerMenuItemSearch from 'components/partner-menu-items/PartnerMenuItemSearch';
import PartnerMenuSectionSearch from 'components/partner-menu-sections/PartnerMenuSectionSearch';
import RemoteTable from 'components/content/RemoteTable';

import * as yup from 'models/validation';
import { useStore } from 'models/store';

import { createRemoveAction } from 'services/actionMenu';
import { getMultiWordSearchWhereClause } from 'services/search';
import { removeHtml } from 'services/regexp';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const getPartnerMenuItemsQuery = searchTerm => gql`
	query PartnerMenuItems(
		$PartnerMenuSectionId: uuid!
		$activeOrderBy: order_by
		$costOrderBy: order_by
		$descriptionOrderBy: order_by
		$nameOrderBy: order_by
		$sortOrderOrderBy: order_by
		$limit: Int
		$offset: Int
	) {
		PartnerMenuItems(
			where: { 
				PartnerMenuSectionId: { _eq: $PartnerMenuSectionId } 
				_or: [
					{ _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
					{
						PartnerMenuItemsDietaryTags: {
							DietaryTag: {
								name: {
									_ilike: "%${searchTerm}%"
								}
							}
						}
					}
				]
			}
			order_by: {
				sortOrder: $sortOrderOrderBy
				active: $activeOrderBy
				cost: $costOrderBy
				description: $descriptionOrderBy
				name: $nameOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			sortOrder
			id
			active
			description
			name
			cost
			...PartnerMenuItemActionMenuDetails
			PartnerMenuItemsDietaryTags(order_by: { DietaryTag: { sortOrder: asc } } ) {
				DietaryTag {
					name
				}
			}
		}
		PartnerMenuItems_aggregate(
			where: { 
				PartnerMenuSectionId: { _eq: $PartnerMenuSectionId } 
				_or: [
					{ _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
					{
						PartnerMenuItemsDietaryTags: {
							DietaryTag: {
								name: {
									_ilike: "%${searchTerm}%"
								}
							}
						}
					}
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
	${PartnerMenuItemActionMenu.fragments.details}
`;

const PartnerMenuItemsTable = ({
	PartnerMenuSectionId,
	PartnerId,
	options,
	...props
}) => {
	const { userCan } = useAuth();
	const { showDialog } = useStore();
	const ref = useRef();

	return (
		<>
			<RemoteTable
				{...props}
				tableRef={ref}
				columns={[
					{
						title: 'Sort Order',
						field: 'sortOrder',
						render: ({ id, sortOrder }) => (
							<EditableSortOrder
								onSave={value =>
									sort(axios, id, {
										newValue: value,
									}).then(ref.current.onQueryChange)
								}
								permissions={['partner-menu-items:edit']}
								required={true}
								value={sortOrder}
							/>
						),
					},
					{
						title: 'Name',
						field: 'name',
						render: ({ id, name, PartnerMenuItemsDietaryTags }) => (
							<ListItemText
								primary={
									<DetailPageLink
										entityType="PartnerMenuItems"
										id={id}
										text={name}
									/>
								}
								secondary={makeCommaSeparatedList(
									PartnerMenuItemsDietaryTags.map(
										({ DietaryTag: { name } }) => name
									),
									false
								)}
							/>
						),
					},
					{
						title: 'Cost',
						field: 'cost',
						render: ({ cost, id }) => (
							<EditableValue
								helperText="This is the cost of the item when it is sold à la carte."
								name="cost"
								onSave={cost =>
									put(axios, id, { cost }).then(
										ref.current.onQueryChange
									)
								}
								permissions={['partner-menu-items:edit']}
								type="currency"
								value={cost}
							/>
						),
					},
					{
						title: 'Description',
						field: 'description',
						render: ({ description }) =>
							description ? (
								removeHtml(description)
							) : (
								<NoContentText>no description</NoContentText>
							),
					},
					{
						title: 'Active',
						field: 'active',
						render: ({ active, id }) => (
							<BooleanFieldSwitch
								afterChange={ref.current.onQueryChange}
								entityId={id}
								entityType="partnerMenuItems"
								updateEntity={put}
								value={active}
							/>
						),
					},
					{
						title: 'Actions',
						field: 'action',
						sorting: false,
						render: partnerMenuItem => (
							<PartnerMenuItemActionMenu
								partnerMenuItem={partnerMenuItem}
								onActionComplete={ref.current.onQueryChange}
								additionalActions={({ setLoading }) => [
									{
										canPerformAction: userCan([
											'partner-menu-items:edit',
										]),
										icon: <TrendingFlat />,
										label: `Move ${partnerMenuItem.name}`,
										onClick: () =>
											showDialog(props => (
												<DialogForm
													{...props}
													title="Search for a Menu Section"
													submitButtonContent="Move"
													onSubmit={values =>
														put(
															axios,
															partnerMenuItem.id,
															{
																PartnerMenuSectionId:
																	values
																		.PartnerMenuSection
																		.id,
															}
														).then(
															ref.current
																.onQueryChange
														)
													}
													validationSchema={yup
														.object()
														.shape({
															PartnerMenuSection:
																yup
																	.object()
																	.required(
																		'Please select a menu section.'
																	),
														})}
												>
													{() => (
														<PartnerMenuSectionSearch
															autoFocus
															name="PartnerMenuSection"
															where={`PartnerId: { _eq: "${PartnerId}" } id: { _neq: "${PartnerMenuSectionId}" }`}
														/>
													)}
												</DialogForm>
											)),
									},
									createRemoveAction({
										canPerformAction: userCan([
											'partner-menu-items:edit',
										]),
										entityName: partnerMenuItem.name,
										onActionComplete:
											ref.current.onQueryChange,
										removeEntity: () =>
											put(axios, partnerMenuItem.id, {
												PartnerMenuSectionId: null,
											}),
										setLoading,
									}),
								]}
							/>
						),
					},
				]}
				defaultOrderBy="sortOrder"
				defaultOrderDirection="asc"
				getQuery={getPartnerMenuItemsQuery}
				queryEntity="PartnerMenuItems"
				variables={{ PartnerMenuSectionId }}
				options={{
					padding: 'dense',
					...options,
				}}
				canAddNewEntity={userCan([
					'partner-menu-items:create',
					'partner-menu-sections:edit',
				])}
				addNewEntityRouteName="partnerMenuItemsCreate"
				addNewEntityRouteVariables={{ id: PartnerMenuSectionId }}
				canAddExistingEntity={userCan([
					'partner-menu-items:edit',
					'partner-menu-sections:edit',
				])}
				entityToAddName="Partner Menu Item"
				onAddExistingEntity={partnerMenuItem =>
					put(axios, partnerMenuItem.id, {
						PartnerMenuSectionId,
					}).then(ref.current.onQueryChange)
				}
				EntityToAddSearch={({ name }) => (
					<PartnerMenuItemSearch
						autoFocus
						name={name}
						where={`
							PartnerId: { _eq: "${PartnerId}" }
							PartnerMenuSectionId: { _is_null: true }
						`}
					/>
				)}
			/>
		</>
	);
};

PartnerMenuItemsTable.propTypes = {
	PartnerMenuSectionId: PropTypes.string.isRequired,
	PartnerId: PropTypes.string.isRequired,
};

export default PartnerMenuItemsTable;
