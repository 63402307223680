import DeliveryGearTypes from 'pages/delivery-gear-types/DeliveryGearTypes';
import Create from 'pages/delivery-gear-types/Create';
import DeliveryGearCreate from 'pages/delivery-gear-types/DeliveryGearCreate';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'deliveryGearTypes',
		path: '',
		component: DeliveryGearTypes,
		permissions: ['delivery-gear-types:view'],
		exact: true,
	},
	{
		name: 'deliveryGearTypesCreate',
		path: '/create',
		component: Create,
		permissions: ['delivery-gear-types:create'],
		exact: true,
	},
	{
		name: 'deliveryGearCreate',
		path: '/:id/delivery-gear/create',
		component: DeliveryGearCreate,
		permissions: ['delivery-gear-types:edit', 'delivery-gear:create'],
		exact: true,
	},
];

export default prependUrlPath('delivery-gear-types', routes);
