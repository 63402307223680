import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import {
	LinkedTextInput,
	LinkedRadioGroup,
	GridContainer,
} from '@sporkbytes/material-ui-kit-react';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';
import {
	createParentPartnerMenuItem,
	reassignParentPartnerMenuItem,
} from '@sporkbytes/api-client/PartnerMenuItems';
import {
	DialogForm,
	LinkedAsyncAutocomplete,
} from '@sporkbytes/material-ui-kit-react';

import * as yup from 'models/validation';
import apolloClient from 'services/apollo';
import axios from 'services/data';
import { getMultiWordSearchWhereClause } from 'services/search';

const getParentPartnerMenuItemsQuery = searchTerm => gql`
	query ParentPartnerMenuItems(
		$PartnerId: uuid!
		$ParentPartnerMenuItemId: uuid!
	){
		ParentPartnerMenuItems(
			where: { 
				PartnerId: { _eq: $PartnerId }
				id: { _neq: $ParentPartnerMenuItemId }
				_and: [${getMultiWordSearchWhereClause(searchTerm)}] 
			}
			order_by: { name: asc }
			limit: 20
		) {
			id
			name
			PartnerMenuItems {
				name
			}
		}
	}
`;

const ReassignParentPartnerMenuItemDialog = ({
	onReassign,
	PartnerId,
	ParentPartnerMenuItemId,
	PartnerMenuItemId,
	...props
}) => {
	const createNewParentSelected = option => option === 'Create New Parent';

	return (
		<DialogForm
			saveButtonText="Reassign"
			title="Reassign Parent"
			cancelButtonText="Cancel"
			onSubmit={({ reassignOption, name, ParentPartnerMenuItemId }) => {
				if (createNewParentSelected(reassignOption)) {
					return createParentPartnerMenuItem(
						axios,
						PartnerMenuItemId,
						{ name, PartnerId }
					).then(onReassign);
				} else {
					return reassignParentPartnerMenuItem(
						axios,
						PartnerMenuItemId,
						ParentPartnerMenuItemId
					).then(onReassign);
				}
			}}
			initialValues={{ reassignOption: 'Create New Parent' }}
			validationSchema={yup.object().shape({
				reassignOption: yup.string().required(),
				name: yup.string().when(['reassignOption'], {
					is: createNewParentSelected,
					then: yup.name().required(),
					otherwise: yup.string(),
				}),
				ParentPartnerMenuItemId: yup.string().when(['reassignOption'], {
					is: createNewParentSelected,
					then: yup.string(),
					otherwise: yup
						.string()
						.required(
							'Please search for a Parent Partner Menu Item.'
						),
				}),
			})}
			{...props}
		>
			{({ values }) => (
				<>
					<GridContainer>
						<LinkedRadioGroup
							name="reassignOption"
							options={[
								'Create New Parent',
								'Select Existing Parent',
							]}
							row
						/>
					</GridContainer>
					<GridContainer>
						{createNewParentSelected(values.reassignOption) ? (
							<LinkedTextInput name="name" autoFocus />
						) : (
							<LinkedAsyncAutocomplete
								async
								autoFocus
								name="ParentPartnerMenuItemId"
								getOptionLabel={option => option.name}
								getSearchResults={async searchTerm => {
									const {
										data: { ParentPartnerMenuItems },
									} = await apolloClient.query({
										query: getParentPartnerMenuItemsQuery(
											searchTerm
										),
										variables: {
											PartnerId,
											ParentPartnerMenuItemId,
										},
									});

									return ParentPartnerMenuItems;
								}}
								getOptionSecondaryText={option =>
									'children: ' +
									makeCommaSeparatedList(
										option.PartnerMenuItems.map(
											({ name }) => name
										)
									)
								}
								getOptionValue={option => option.id}
							/>
						)}
					</GridContainer>
				</>
			)}
		</DialogForm>
	);
};

ReassignParentPartnerMenuItemDialog.propTypes = {
	onReassign: PropTypes.func.isRequired,
	PartnerId: PropTypes.string.isRequired,
	ParentPartnerMenuItemId: PropTypes.string.isRequired,
	PartnerMenuItemId: PropTypes.string.isRequired,
};

export default ReassignParentPartnerMenuItemDialog;
