import gql from 'graphql-tag';

import useSubscribedMachine from 'hooks/useSubscribedMachine';

import mealOptionMachine from 'machines/mealOptionMachine';

const MEAL_OPTION_SUBSCRIPTION = gql`
	subscription UseMealOptionMachine($id: uuid!) {
		MealOptions_by_pk(id: $id) {
			approved
			confirmed
			name
			MealProposal {
				deliveryDate
				status
				MealOptions {
					approved
					confirmed
				}
				MealProposalsContacts {
					Contact {
						id
					}
				}
			}
			PurchaseOrders {
				approved
			}
		}
	}
`;

export default (id, options = {}) =>
	useSubscribedMachine(
		mealOptionMachine,
		MEAL_OPTION_SUBSCRIPTION,
		{ id },
		'data.MealOptions_by_pk',
		options
	);
