import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import {
	put,
	toggleDispatchNotifications,
} from '@sporkbytes/api-client/DeliveryDrivers';
import { PhoneNumber } from '@sporkbytes/material-ui-kit-react';

import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import DeliveryDriverActionMenu from 'components/delivery-drivers/DeliveryDriverActionMenu';
import DeliveryDriverSearch from 'components/delivery-drivers/DeliveryDriverSearch';
import RemoteTable from 'components/content/RemoteTable';

import { useStore } from 'models/store';

import { getMultiWordSearchWhereClause } from 'services/search';
import axios from 'services/data';

const getDeliveryDriversQuery = tableFilter => searchTerm =>
	gql`
	query DeliveryDrivers(
		$activeOrderBy: order_by
		$firstNameOrderBy: order_by
		$lastNameOrderBy: order_by
		$phoneNumberOrderBy: order_by
		$enableDispatchNotificationsOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		DeliveryDrivers(
			where: {
				${tableFilter}
				_and: [${getMultiWordSearchWhereClause(searchTerm)}]
			}
			order_by: {
				active: $activeOrderBy
				firstName: $firstNameOrderBy
				lastName: $lastNameOrderBy
				enableDispatchNotifications: $enableDispatchNotificationsOrderBy
				phoneNumber: $phoneNumberOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			id
			active
			email
			firstName
			lastName
			phoneNumber
			enableDispatchNotifications
			SporkLocation {
				name
			}
		}
		DeliveryDrivers_aggregate(
			where: {
				${tableFilter}
				_and: [${getMultiWordSearchWhereClause(searchTerm)}]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const toggleAllDispatchNotifications =
	(enableDispatchNotifications, ref) => () => {
		toggleDispatchNotifications(axios, {
			enableDispatchNotifications,
		}).then(ref.current.onQueryChange);
	};

const DeliveryDriversTable = ({
	addExistingSearchFilter,
	additionalActions,
	onAddExistingEntity,
	tableFilter,
	...props
}) => {
	const ref = useRef();
	const { hasMoreThanOneSporkLocation } = useStore();

	return (
		<RemoteTable
			{...props}
			tableRef={ref}
			actions={[
				{
					icon: 'notifications_off',
					tooltip: 'Disable Dispatch Notifications',
					onClick: toggleAllDispatchNotifications(false, ref),
					isFreeAction: true,
				},
				{
					icon: 'notifications_active',
					tooltip: 'Enable Dispatch Notifications',
					onClick: toggleAllDispatchNotifications(true, ref),
					isFreeAction: true,
				},
			]}
			columns={[
				{
					title: 'First name',
					field: 'firstName',
				},
				{
					title: 'Last Name',
					field: 'lastName',
				},
				{
					title: 'Phone Number',
					field: 'phoneNumber',
					disableClick: true,
					render: ({ phoneNumber }) => (
						<PhoneNumber value={phoneNumber} />
					),
				},
				{
					title: 'Active',
					field: 'active',
					render: ({ active, id }) => (
						<BooleanFieldSwitch
							afterChange={ref.current.onQueryChange}
							entityId={id}
							entityType="deliveryDrivers"
							updateEntity={put}
							value={active}
						/>
					),
				},
				{
					title: 'Dispatch Notifications',
					field: 'enableDispatchNotifications',
					render: ({ enableDispatchNotifications, id }) => (
						<BooleanFieldSwitch
							afterChange={ref.current.onQueryChange}
							columnToEdit="enableDispatchNotifications"
							entityId={id}
							entityType="deliveryDrivers"
							updateEntity={put}
							value={enableDispatchNotifications}
						/>
					),
				},
				{
					title: 'Spork Location',
					field: 'SporkLocation.name',
					sorting: false,
					hidden: !hasMoreThanOneSporkLocation(),
				},
				{
					title: 'Actions',
					field: 'actions',
					sorting: false,
					render: deliveryDriver => (
						<DeliveryDriverActionMenu
							deliveryDriver={deliveryDriver}
							onActionComplete={ref.current.onQueryChange}
							additionalActions={setLoading =>
								additionalActions({
									axios,
									ref,
									setLoading,
									deliveryDriver,
								})
							}
						/>
					),
				},
			]}
			getQuery={getDeliveryDriversQuery(tableFilter)}
			queryEntity="DeliveryDrivers"
			entityToAddName="Delivery Driver"
			EntityToAddSearch={props => (
				<DeliveryDriverSearch
					{...props}
					autoFocus
					where={`_not: { ${tableFilter} } ${addExistingSearchFilter}`}
				/>
			)}
			onAddExistingEntity={deliveryDriver =>
				onAddExistingEntity(axios, deliveryDriver).then(
					ref.current.onQueryChange
				)
			}
		/>
	);
};

DeliveryDriversTable.propTypes = {
	addExistingSearchFilter: PropTypes.string,
	additionalActions: PropTypes.func,
	onAddExistingEntity: PropTypes.func,
	tableFilter: PropTypes.string,
};

DeliveryDriversTable.defaultProps = {
	addExistingSearchFilter: '',
	additionalActions: () => [],
	tableFilter: '',
};

export default DeliveryDriversTable;
