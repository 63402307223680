import React, { useEffect, useMemo, useRef, useState } from 'react';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import {
	GridContainer,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';
import { Sync } from '@material-ui/icons';
import { syncAllPendingToHubSpot } from '@sporkbytes/api-client/MealProposalHubSpotSyncs';

import DateOutput from 'components/content/DateOutput';
import DetailPageLink from 'components/routing/DetailPageLink';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import StatusIcon from 'components/meal-proposal-hub-spot-syncs/StatusIcon';
import Table from 'components/content/Table';

import { useStore } from 'models/store';

import { formatZonedTime } from 'services/dates';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const MEAL_PROPOSAL_HUB_SPOT_SYNCS_SUBSCRIPTION = gql`
	subscription AllMealProposalHubSpotSyncs(
		$createdAtOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		MealProposalHubSpotSyncs(
			order_by: { createdAt: $createdAtOrderBy }
			limit: $limit
			offset: $offset
		) {
			action
			createdAt
			error
			payload
			status
			updatedAt
			MealProposal {
				id
				name
				orderNumberText
			}
		}
	}
`;

const MEAL_PROPOSAL_HUB_SPOT_SYNCS_AGGREGATE_SUBSCRIPTION = gql`
	subscription AllMealProposalHubSpotSyncsCount {
		MealProposalHubSpotSyncs_aggregate {
			aggregate {
				count
			}
		}
	}
`;

const MEAL_PROPOSAL_HUB_SPOT_SYNCS_PENDING_AGGREGATE_SUBSCRIPTION = gql`
	subscription AllMealProposalHubSpotSyncsCount {
		MealProposalHubSpotSyncs_aggregate(
			where: { status: { _eq: "Pending" } }
		) {
			aggregate {
				count
			}
		}
	}
`;

const MealProposalHubSpotSyncs = () => {
	const { userCan } = useAuth();
	const [orderDirection, setOrderDirection] = useState('desc');
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(0);
	const { data: tableData, loading: tableDataLoading } = useSubscription(
		MEAL_PROPOSAL_HUB_SPOT_SYNCS_SUBSCRIPTION,
		{
			variables: {
				createdAtOrderBy: orderDirection,
				limit,
				offset: page * limit,
			},
		}
	);
	const { data: countData, loading: countLoading } = useSubscription(
		MEAL_PROPOSAL_HUB_SPOT_SYNCS_AGGREGATE_SUBSCRIPTION
	);
	const { data: pendingCountData } = useSubscription(
		MEAL_PROPOSAL_HUB_SPOT_SYNCS_PENDING_AGGREGATE_SUBSCRIPTION
	);
	const ref = useRef();
	const { showSuccessNotification } = useStore();
	const hasPending = useMemo(
		() =>
			pendingCountData?.MealProposalHubSpotSyncs_aggregate?.aggregate
				?.count > 0 ?? false,
		[pendingCountData]
	);

	useEffect(() => {
		ref.current.onQueryChange();
	}, [
		countData,
		countLoading,
		limit,
		orderDirection,
		page,
		tableData,
		tableDataLoading,
	]);

	return (
		<Page title="HubSpot Syncs">
			<PageHeader headerText="HubSpot Syncs" />
			{userCan(['meal-proposal-hub-spot-syncs:edit']) && (
				<GridContainer>
					<PrimaryButton
						onClick={() =>
							syncAllPendingToHubSpot(axios).then(
								showSuccessNotification
							)
						}
						startIcon={<Sync />}
						disabled={!hasPending}
					>
						Sync Now
					</PrimaryButton>
				</GridContainer>
			)}
			<Table
				tableRef={ref}
				isLoading={tableDataLoading}
				columns={[
					{
						title: 'Status',
						field: 'status',
						render: ({ action, updatedAt, status }) => (
							<>
								<StatusIcon
									status={status}
									tooltip={`${status} as of ${formatZonedTime(
										updatedAt,
										'dateAndTime'
									)}`}
								/>
								{action}
							</>
						),
						sorting: false,
					},
					{
						title: 'Order #',
						field: 'MealProposal.orderNumberText',
						render: ({
							MealProposal: { id, name, orderNumberText },
						}) => (
							<DetailPageLink
								entityType="MealProposals"
								id={id}
								text={`${orderNumberText}${
									name ? `: ${name}` : ''
								}`}
							/>
						),
						sorting: false,
					},
					{
						title: 'Payload',
						field: 'payload',
						render: ({ payload }) => (
							<pre style={{ maxWidth: 250, overflowX: 'auto' }}>
								{payload && JSON.stringify(payload, null, 2)}
							</pre>
						),
						sorting: false,
					},
					{
						title: 'Error',
						field: 'error',
						render: ({ error }) => (
							<pre style={{ maxWidth: 250, overflowX: 'auto' }}>
								{error && JSON.stringify(error, null, 2)}
							</pre>
						),
						sorting: false,
					},
					{
						title: 'Created At',
						field: 'createdAt',
						render: ({ createdAt }) => (
							<DateOutput
								date={createdAt}
								formatter="dateAndTime"
							/>
						),
						defaultSort: 'desc',
					},
				]}
				data={async () => {
					return {
						data: tableData?.MealProposalHubSpotSyncs ?? [],
						page,
						totalCount:
							countData?.MealProposalHubSpotSyncs_aggregate
								?.aggregate?.count ?? 0,
					};
				}}
				onOrderChange={(_, orderDirection) => {
					setOrderDirection(orderDirection);
				}}
				onChangeRowsPerPage={setLimit}
				onChangePage={setPage}
				options={{
					pageSize: limit,
					toolbar: false,
				}}
			/>
		</Page>
	);
};

export default MealProposalHubSpotSyncs;
