import React from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash.camelcase';
import {
	AccessTime,
	Cancel,
	Description,
	DirectionsCar,
} from '@material-ui/icons';

import Status from 'components/content/Status';

import { STATUS_OPTIONS } from 'services/mealProposal';

const MealProposalStatus = ({ status }) => {
	const statusToIconMap = {
		canceled: {
			Icon: Cancel,
			type: 'error',
		},
		delivered: {
			Icon: DirectionsCar,
			type: 'success',
		},
		draft: {
			Icon: Description,
			type: 'warning',
		},
		inProgress: {
			Icon: AccessTime,
			type: 'warning',
		},
	};
	const statusProps = {
		...statusToIconMap[camelCase(status)],
		tooltip: status,
	};

	return <Status {...statusProps} />;
};

MealProposalStatus.propTypes = {
	status: PropTypes.oneOf(STATUS_OPTIONS),
};

export default MealProposalStatus;
