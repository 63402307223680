import React from 'react';
import { reviewableMealsTimeframe } from '@sporkbytes/config';

import ClientLocationPublicMealsProfile from 'components/client-locations/ClientLocationPublicMealsProfile';
import PageSubheader from 'components/content/PageSubheader';

const ClientLocationReviewableMealsProfile = () => {
	return (
		<ClientLocationPublicMealsProfile
			timeline="reviewableMeals"
			subheader={streetAddress => (
				<PageSubheader>
					Review Past Meals for {streetAddress}
				</PageSubheader>
			)}
			mealLinkProps={({ mealMenuId }) => ({
				routeName: 'publicMealMenuReview',
				variables: { mealMenuId },
			})}
			noMealsFoundText={`There are no meals to review at this time. Reviews can be given up to ${reviewableMealsTimeframe.amount} ${reviewableMealsTimeframe.scale} after the meal is delivered.`}
		/>
	);
};

export default ClientLocationReviewableMealsProfile;
