import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { LinkedAsyncAutocomplete } from '@sporkbytes/material-ui-kit-react';

import apolloClient from 'services/apollo';
import { getMultiWordSearchWhereClause } from 'services/search';

const getContactSearchQuery = (where, searchTerm) => gql`
	query ContactSearchQuery {
		Contacts(
			limit: 20
			order_by: { firstName: asc }
			where: { 
				_and: [
					${where}
					${getMultiWordSearchWhereClause(searchTerm)}
				] 
			}
		) {
			id
			fullName
			email
		}
	}
`;

const ContactSearch = ({ name, where, ...props }) => {
	return (
		<LinkedAsyncAutocomplete
			getSearchResults={async searchTerm => {
				const {
					data: { Contacts },
				} = await apolloClient.query({
					query: getContactSearchQuery(where, searchTerm),
				});

				return Contacts;
			}}
			getOptionLabel={option => option.fullName}
			getOptionSecondaryText={option => option.email}
			name={name}
			{...props}
		/>
	);
};

ContactSearch.propTypes = {
	name: PropTypes.string,
	where: PropTypes.string,
};

export default ContactSearch;
