import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { sort } from '@sporkbytes/api-client/PartnerListMenuSections';

import EditableSortOrder from 'components/forms/EditableSortOrder';
import PartnerMenuItemsTable from 'components/partner-list-menu-sections/PartnerMenuItemsTable';
import PartnerListMenuSectionActionMenu from 'components/partner-list-menu-sections/PartnerListMenuSectionActionMenu';
import RemoteTable from 'components/content/RemoteTable';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const getPartnerListMenuSectionsQuery = searchTerm => gql`
	query PartnerListMenuSections(
		$PartnerListMenuId: uuid!
		$nameOrderBy: order_by
		$sortOrderOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		PartnerListMenuSections(
			where: {
				PartnerListMenuId: { _eq: $PartnerListMenuId } 
				name: { _ilike: "%${searchTerm}%" }
			}
			order_by: {
				name: $nameOrderBy 
				sortOrder: $sortOrderOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			id
			name
			sortOrder
		}
		PartnerListMenuSections_aggregate(
			where: {
				PartnerListMenuId: { _eq: $PartnerListMenuId } 
				name: { _ilike: "%${searchTerm}%" }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const PartnerListMenuSectionsTable = ({ PartnerListMenuId, PartnerId }) => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<RemoteTable
			tableRef={ref}
			columns={[
				{
					title: 'Sort Order',
					field: 'sortOrder',
					render: ({ id, sortOrder }) => (
						<EditableSortOrder
							onSave={value =>
								sort(axios, id, {
									newValue: value,
								}).then(ref.current.onQueryChange)
							}
							permissions={['partner-list-menu-sections:edit']}
							required={true}
							value={sortOrder}
						/>
					),
				},
				{
					title: 'Name',
					field: 'name',
					render: ({ name }) => name,
				},
				{
					title: 'Actions',
					field: 'actions',
					sorting: false,
					render: partnerListMenuSection => (
						<PartnerListMenuSectionActionMenu
							partnerListMenuSection={partnerListMenuSection}
						/>
					),
				},
			]}
			defaultOrderBy="sortOrder"
			defaultOrderDirection="asc"
			getQuery={getPartnerListMenuSectionsQuery}
			queryEntity="PartnerListMenuSections"
			variables={{ PartnerListMenuId }}
			detailPanel={({ id }) => (
				<PartnerMenuItemsTable
					PartnerListMenuSectionId={id}
					PartnerId={PartnerId}
				/>
			)}
			canAddNewEntity={userCan([
				'partner-list-menu-sections:create',
				'partner-list-menus:edit',
			])}
			addNewEntityRouteName="partnerListMenuSectionsCreate"
			addNewEntityRouteVariables={{ id: PartnerListMenuId }}
			entityToAddName="Partner List Menu Section"
		/>
	);
};

PartnerListMenuSectionsTable.propTypes = {
	PartnerId: PropTypes.string.isRequired,
	PartnerListMenuId: PropTypes.string.isRequired,
};

export default PartnerListMenuSectionsTable;
