import React from 'react';
import gql from 'graphql-tag';
import { useHistory, useParams } from 'react-router-dom';
import {
	deletePartnerMenuItemOptionGroups,
	put,
} from '@sporkbytes/api-client/PartnerMenuItemOptionGroups';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PartnerMenuItemOptionGroupForm from 'components/partner-menu-item-option-groups/PartnerMenuItemOptionGroupForm';

// TODO: use fragments here
const PARTNER_MENU_ITEM_OPTION_GROUP_QUERY = gql`
	query PartnerMenuItemOptionGroupEdit($id: uuid!) {
		PartnerMenuItemOptionGroups_by_pk(id: $id) {
			name
			howToOrder
			checkOptionsByDefault
			PartnerMenuItemId
			PartnerMenuItem {
				name
				Partner {
					logoUrl
					name
				}
			}
		}
	}
`;

const Edit = () => {
	const { id } = useParams();
	const { pushNamedRoute } = useHistory();

	return (
		<Page
			title={({ PartnerMenuItemOptionGroups_by_pk: { name } }) =>
				`Edit ${name}`
			}
			query={PARTNER_MENU_ITEM_OPTION_GROUP_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					PartnerMenuItemOptionGroups_by_pk: {
						name,
						PartnerMenuItemId,
						PartnerMenuItem: {
							name: PartnerMenuItemName,
							Partner: { logoUrl, name: PartnerName },
						},
						...data
					},
				},
			}) => (
				<>
					<PageHeader headerText={PartnerName} imgUrl={logoUrl} />
					<PageSubheader>
						Edit {name} for {PartnerMenuItemName}
					</PageSubheader>
					<FormWrapper
						form={PartnerMenuItemOptionGroupForm}
						initialValues={{
							name,
							...data,
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
						onDelete={axios =>
							deletePartnerMenuItemOptionGroups(axios, id).then(
								() =>
									pushNamedRoute('partnerMenuItemsDetail', {
										id: PartnerMenuItemId,
										tab: 'option-groups',
									})
							)
						}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
