import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { endOfDay, format, parseISO, startOfDay } from 'date-fns';
import { makeStyles } from '@material-ui/core';
import { DateTimePicker } from '@sporkbytes/material-ui-kit-react';
import { getIncludedItems } from '@sporkbytes/meal-option-utils';

import HeadCount from 'components/content/HeadCount';
import MealProposalTableTopper from 'components/meal-proposals/MealProposalTableTopper';
import Page from 'components/layout/Page';
import PartnerMenuItemPrintableLabel from 'components/partner-menu-items/PartnerMenuItemPrintableLabel';
import PrintSettings from 'components/printable-labels/PrintSettings';

import { getDefaultQuantity } from 'services/printableLabels';

const MEAL_PROPOSAL_QUERY = gql`
	query MealProposalPrintableLabels(
		$startDate: timestamptz!
		$endDate: timestamptz!
	) {
		MealProposals(
			where: {
				deliveryDate: { _gte: $startDate, _lte: $endDate }
				MealOptions: { approved: { _eq: true } }
				status: { _nin: ["Canceled", "Draft"] }
			}
			order_by: { deliveryDate: asc }
		) {
			id
			headCount
			orderNumberText
			...MealProposalTableTopperDetails
			MealOptions(where: { approved: { _eq: true } }) {
				PurchaseOrders(order_by: { pickupTime: asc }) {
					PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
						PurchaseOrderMenuItems(
							where: {
								PartnerMenuItem: {
									producesLabel: { _eq: true }
								}
							}
							order_by: { sortOrder: asc }
						) {
							id
							PartnerMenuItem {
								...PartnerMenuItemPrintableLabelDetails
							}
						}
					}
				}
			}
			ClientLocation {
				Client {
					name
				}
			}
		}
	}
	${MealProposalTableTopper.fragments.details}
	${PartnerMenuItemPrintableLabel.fragments.details}
`;

const useStyles = makeStyles(theme => ({
	datePicker: {
		'@media print': {
			display: 'none',
		},
	},
}));

const Day = () => {
	const { day } = useParams();
	const classes = useStyles();
	const history = useHistory();

	return (
		<Page
			query={MEAL_PROPOSAL_QUERY}
			variables={{
				startDate: startOfDay(parseISO(day)),
				endDate: endOfDay(parseISO(day)),
			}}
			title={`Print Labels for ${day}`}
		>
			{({ data: { MealProposals } }) => (
				<>
					<DateTimePicker
						value={day}
						dateOnly
						label="Day"
						onChange={date =>
							history.pushNamedRoute('printableLabelsDay', {
								day: format(date, 'yyyy-MM-dd'),
							})
						}
						className={classes.datePicker}
					/>
					<PrintSettings
						initialValues={{
							groups: MealProposals.map(
								({
									ClientLocation,
									headCount,
									...mealProposal
								}) => ({
									id: mealProposal.id,
									name: (
										<>
											{ClientLocation.Client.name} -{' '}
											<HeadCount
												value={headCount}
												variant="inherit"
											/>
										</>
									),
									items: getIncludedItems(
										mealProposal.MealOptions[0]
									).map(({ id, PartnerMenuItem }) => ({
										...PartnerMenuItem,
										id,
										quantity: getDefaultQuantity(headCount),
									})),
									showTableTopper: true,
									tableTopperData: mealProposal,
								})
							),
						}}
						title={`Labels for ${format(
							parseISO(day),
							'EEEE, MMMM d, yyyy'
						)}`}
					/>
				</>
			)}
		</Page>
	);
};

export default Day;
