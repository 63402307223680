import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@material-ui/core';
import gql from 'graphql-tag';

import DetailPageLink from 'components/routing/DetailPageLink';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import SporkReviewFormCompleted from 'components/spork-reviews/SporkReviewFormCompleted';

const SPORK_REVIEW_QUERY = gql`
	query SporkReviewDetails($id: uuid!) {
		SporkReviews_by_pk(id: $id) {
			...SporkReviewFormCompleted
			MealProposal {
				id
				orderNumberText
				ClientLocation {
					id
					friendlyName
					Client {
						id
						name
						logoUrl
					}
				}
			}
		}
	}
	${SporkReviewFormCompleted.fragments.details}
`;

const Detail = () => {
	const { id } = useParams();

	return (
		<Page
			query={SPORK_REVIEW_QUERY}
			variables={{ id }}
			title={() => 'Spork Review'}
		>
			{({
				data: {
					SporkReviews_by_pk: {
						MealProposal: {
							ClientLocation: { Client, ...ClientLocation },
							...MealProposal
						},
						...SporkReview
					},
				},
			}) => (
				<Card>
					<CardContent>
						<PageHeader
							imgUrl={Client.logoUrl}
							headerText={Client.name}
							linkProps={{
								routeName: 'clientsDetail',
								variables: { id: Client.id },
								permissions: ['clients:view'],
							}}
						/>
						<PageSubheader>
							<DetailPageLink
								entityType="ClientLocations"
								id={ClientLocation.id}
								text={ClientLocation.friendlyName}
							/>
						</PageSubheader>
						<PageSubheader>
							Review Posted for order{' '}
							<DetailPageLink
								entityType="MealProposals"
								id={MealProposal.id}
								text={MealProposal.orderNumberText}
							/>
						</PageSubheader>
						<SporkReviewFormCompleted sporkReview={SporkReview} />
					</CardContent>
				</Card>
			)}
		</Page>
	);
};

export default Detail;
