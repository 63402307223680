import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { post } from '@sporkbytes/api-client/PartnerMenuItems';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PartnerMenuItemForm from 'components/partner-menu-items/PartnerMenuItemForm';

const PARTNER_MENU_SECTION_QUERY = gql`
	query PartnerMenuItemCreate($id: uuid!) {
		PartnerMenuSections_by_pk(id: $id) {
			name
			Partner {
				id
				logoUrl
				name
			}
		}
	}
`;

const PartnerMenuItemCreate = () => {
	const { id: PartnerMenuSectionId } = useParams();

	return (
		<Page
			title={({ PartnerMenuSections_by_pk: { name } }) =>
				`New Menu Item for ${name}`
			}
			query={PARTNER_MENU_SECTION_QUERY}
			variables={{ id: PartnerMenuSectionId }}
		>
			{({
				data: {
					PartnerMenuSections_by_pk: { name, Partner },
				},
			}) => (
				<>
					<PageHeader
						headerText={Partner.name}
						imgUrl={Partner.logoUrl}
					/>
					<PageSubheader>
						<div>{name}</div>
						<div>Add New Item</div>
					</PageSubheader>
					<FormWrapper
						form={PartnerMenuItemForm}
						initialValues={{
							producesLabel: true,
							visibleInMenu: true,
							showInDriverChecklist: true,
						}}
						onSubmit={(axios, values) =>
							post(axios, {
								PartnerId: Partner.id,
								PartnerMenuSectionId,
								...values,
							})
						}
					/>
				</>
			)}
		</Page>
	);
};

export default PartnerMenuItemCreate;
