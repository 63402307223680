import React from 'react';
import gql from 'graphql-tag';
import { Currency, Percent } from '@sporkbytes/material-ui-kit-react';
import {
	getSummationCalculation,
	grossMargin as getGrossMargin,
} from '@sporkbytes/meal-proposal-utils';

import MetricCard from 'components/content/MetricCard';

const MetricCardSporkPayout = ({ mealProposals }) => {
	const mealCount = mealProposals.length;
	const sporkPayout = getSummationCalculation(
		'totalReceivedBySpork',
		mealProposals
	);
	const grossMargin = getGrossMargin(mealProposals);

	return (
		<MetricCard
			label="Spork Payout"
			value={<Currency value={sporkPayout} />}
			footer={
				<>
					<div>
						<Currency value={sporkPayout / mealCount} /> / meal
					</div>
					<div>
						<Percent value={grossMargin} /> gross margin
					</div>
				</>
			}
			showFooter={mealCount > 0}
		/>
	);
};

MetricCardSporkPayout.fragments = {
	details: gql`
		fragment MetricCardSporkPayoutDetails on MealProposals {
			headCount
			MealProposalFees {
				amount
				discount
				type
			}
			MealOptions(where: { approved: { _eq: true } }) {
				approved
				gratuity
				PurchaseOrders {
					sporkCommissionPercent
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							costToClient
							discount
							paidToPartner
							quantity
						}
					}
					PurchaseOrderPayoutAdjustments {
						amount
					}
				}
			}
		}
	`,
};

export default MetricCardSporkPayout;
