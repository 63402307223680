import React from 'react';
import PropTypes from 'prop-types';
import {
	Card,
	CardContent,
	List,
	ListItem,
	ListItemText,
} from '@material-ui/core';
import { Alert } from '@sporkbytes/material-ui-kit-react';
import {
	getMealMenus,
	getWithClient,
} from '@sporkbytes/api-client/PublicClientLocations';
import { getPartnerNames } from '@sporkbytes/purchase-order-utils';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import DateOutput from 'components/content/DateOutput';
import Link from 'components/routing/Link';
import LoadingPage from 'components/content/LoadingPage';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import useQueryString from 'hooks/useQueryString';
import useRequest from 'hooks/useRequest';

import axios from 'services/data';

const ClientLocationPublicMealsProfile = ({
	mealLinkProps,
	noMealsFoundText,
	subheader,
	timeline,
}) => {
	const [{ publicMenuProfileId }] = useQueryString();

	const { data: clientLocation, loading: loadingClientLocation } = useRequest(
		() =>
			getWithClient(axios, publicMenuProfileId, {
				field: 'publicMenuProfileId',
			})
	);

	const {
		data: mealProposals,
		loading: loadingMealProposals,
	} = useRequest(
		() => getMealMenus(axios, publicMenuProfileId, { timeline }),
		[timeline]
	);

	if (loadingClientLocation) {
		return <LoadingPage />;
	}

	const { streetAddress, timezone, Client } = clientLocation;

	return (
		<Page title={`Profile for ${streetAddress} (${Client.name})`}>
			<Card>
				<CardContent>
					<PageHeader
						headerText={Client.name}
						imgUrl={Client.logoUrl}
					/>
					{subheader(streetAddress)}
					{loadingMealProposals ? (
						<CenteredCircularProgress />
					) : mealProposals.length === 0 ? (
						<Alert type="info">{noMealsFoundText}</Alert>
					) : (
						<List>
							{mealProposals.map(
								({
									id,
									deliveryDate,
									MealOptions: [approvedMealOption],
								}) => (
									<ListItem key={id}>
										<ListItemText
											primary={
												<DateOutput
													date={deliveryDate}
													timezone={timezone}
												/>
											}
											secondary={
												<Link
													{...mealLinkProps(
														approvedMealOption
													)}
												>
													{getPartnerNames(
														approvedMealOption.PurchaseOrders
													)}
												</Link>
											}
											primaryTypographyProps={{
												variant: 'h5',
											}}
											secondaryTypographyProps={{
												variant: 'h6',
											}}
										/>
									</ListItem>
								)
							)}
						</List>
					)}
				</CardContent>
			</Card>
		</Page>
	);
};

ClientLocationPublicMealsProfile.propTypes = {
	mealLinkProps: PropTypes.func.isRequired,
	noMealsFoundText: PropTypes.string.isRequired,
	subheader: PropTypes.func.isRequired,
	timeline: PropTypes.string.isRequired,
};

export default ClientLocationPublicMealsProfile;
