import Edit from 'pages/meal-proposal-fees/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'mealProposalFeesEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['meal-proposal-fees:edit'],
		exact: true,
	},
];

export default prependUrlPath('meal-proposal-fees', routes);
