import React from 'react';
import {
	Form,
	LinkedTextInput,
	GridContainer,
	LinkedSingleSelect,
} from '@sporkbytes/material-ui-kit-react';

import * as yup from 'models/validation';

const COLORS = ['gold', 'green', 'orange', 'pink', 'salmon', 'teal'];

const PartnerTagForm = props => {
	const validationSchema = yup.object().shape({
		name: yup.name().required(),
		backgroundColor: yup
			.string()
			.required('Please select a background color.'),
	});

	return (
		<Form {...props} validationSchema={validationSchema}>
			{() => (
				<GridContainer columns={2}>
					<LinkedTextInput name="name" autoFocus />
					<LinkedSingleSelect
						name="backgroundColor"
						options={COLORS}
					/>
				</GridContainer>
			)}
		</Form>
	);
};

export default PartnerTagForm;
