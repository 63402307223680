import React from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import { FileCopy } from '@material-ui/icons';
import { duplicate } from '@sporkbytes/api-client/PurchaseOrders';

import ChecklistDialogForm from 'components/forms/ChecklistDialogForm';

import { store } from 'models/store';

import axios from 'services/data';

const PURCHASE_ORDER_QUERY = gql`
	query PurchaseOrderDuplicateDialog($id: uuid!) {
		PurchaseOrders_by_pk(id: $id) {
			MealOption {
				id
				MealProposal {
					MealOptions(order_by: { createdAt: asc }) {
						id
						name
					}
				}
			}
		}
	}
`;

export default ({ id, partnerName, userCan }) => {
	const { showDialog, showSuccessNotification } = store.getState();

	return {
		canPerformAction: userCan(['purchase-orders:duplicate']),
		icon: <FileCopy />,
		label: `Duplicate PO for ${partnerName}`,
		onClick: () => {
			showDialog(props => (
				<Query query={PURCHASE_ORDER_QUERY} variables={{ id }}>
					{({ data, loading }) => {
						const currentMealOptionId =
							data?.PurchaseOrders_by_pk.MealOption.id;

						const mealOptions =
							data?.PurchaseOrders_by_pk.MealOption.MealProposal.MealOptions.filter(
								({ id }) => id !== currentMealOptionId
							) ?? [];

						return (
							<ChecklistDialogForm
								{...props}
								loading={loading}
								entityType="meal option"
								minSelected={1}
								submitButtonContent="Duplicate"
								title={`Duplicate Purchase Order for ${partnerName}`}
								text="Select Meal Options"
								options={mealOptions.map(({ id, name }) => ({
									id,
									label: name,
								}))}
								initialValues={
									mealOptions.length === 1
										? mealOptions.map(({ id }) => id)
										: undefined
								}
								onSubmit={async MealOptionIds => {
									const response = await duplicate(
										axios,
										id,
										{
											MealOptionIds,
										}
									);

									showSuccessNotification(response);
								}}
							/>
						);
					}}
				</Query>
			));
		},
	};
};
