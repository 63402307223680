import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash.startcase';
import {
	Form,
	GridContainer,
	LinkedMultiSelect,
	LinkedRichTextEditor,
	LinkedSingleSelect,
} from '@sporkbytes/material-ui-kit-react';
import { getIncludedContacts } from '@sporkbytes/meal-proposal-utils';

import * as yup from 'models/validation';

const AVAILABLE_TYPES = ['email', 'phone call', 'text message', 'note'];

const MealProposalActivityLogForm = ({ mealProposal, ...props }) => {
	const { clientContacts, partnerContacts } = getIncludedContacts(
		mealProposal
	);

	const validationSchema = yup.object().shape({
		type: yup
			.mixed()
			.oneOf(AVAILABLE_TYPES)
			.required('Please select a type.'),
		ContactIds: yup.array().when(['type'], {
			is: type => type === undefined || type === 'note',
			then: yup.array().of(yup.string()),
			otherwise: yup
				.array()
				.of(yup.string())
				.required('Please select a contact.'),
		}),
		notes: yup.string().required('Please enter a note.'),
	});

	return (
		<Form {...props} validationSchema={validationSchema}>
			{() => (
				<>
					<GridContainer columns={2}>
						<LinkedSingleSelect
							name="type"
							options={AVAILABLE_TYPES.map(type => ({
								value: type,
								label: startCase(type),
							}))}
							getOptionValue={option => option.value}
							getOptionLabel={option => option.label}
							getOptionKey={option => option.value}
						/>
						<LinkedMultiSelect
							name="ContactIds"
							label="Contacts"
							options={[
								{
									id: 'clientContacts',
									fullName: 'Client Contacts',
									type: 'group',
								},
								...clientContacts,
								{
									id: 'partnerContacts',
									fullName: 'Partner Contacts',
									type: 'group',
								},
								...partnerContacts,
							]}
							getOptionValue={option => option.id}
							getOptionLabel={option => option.fullName}
							getOptionType={option => option.type}
							getOptionKey={option => option.id}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedRichTextEditor name="notes" />
					</GridContainer>
				</>
			)}
		</Form>
	);
};

MealProposalActivityLogForm.propTypes = {
	mealProposal: PropTypes.shape({
		MealProposalsContacts: PropTypes.arrayOf(
			PropTypes.shape({
				Contact: PropTypes.shape({
					id: PropTypes.string.isRequired,
					fullName: PropTypes.string.isRequired,
				}).isRequired,
			})
		),
		MealOptions: PropTypes.arrayOf(
			PropTypes.shape({
				PurchaseOrders: PropTypes.arrayOf(
					PropTypes.shape({
						PurchaseOrdersContacts: PropTypes.arrayOf(
							PropTypes.shape({
								Contact: PropTypes.shape({
									id: PropTypes.string.isRequired,
									fullName: PropTypes.string.isRequired,
								}),
							})
						),
					})
				),
			})
		),
	}),
};

export default MealProposalActivityLogForm;
