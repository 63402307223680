import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { getClientApproved } from '@sporkbytes/meal-option-utils';
import {
	clientHasApproved,
	clientHasConfirmed,
} from '@sporkbytes/meal-proposal-utils';
import { getPartnerNames } from '@sporkbytes/purchase-order-utils';

import ApprovedConfirmedStatus from 'components/content/ApprovedConfirmedStatus';
import DateOutput from 'components/content/DateOutput';
import Link from 'components/routing/Link';
import Table from 'components/content/Table';

export const MealProposalTable = ({ mealProposals }) => {
	mealProposals.forEach(mealProposal => {
		const approvedMealOption = getClientApproved(mealProposal.MealOptions);

		if (approvedMealOption) {
			mealProposal.restaurants = getPartnerNames(
				approvedMealOption.PurchaseOrders
			);
		} else {
			mealProposal.restaurants = 'no meal option approved';
		}
	});

	return (
		<Table
			data={mealProposals}
			columns={[
				{
					title: 'Order Number',
					field: 'orderNumber',
					render: ({
						id,
						name,
						orderNumberText,
						...mealProposal
					}) => (
						<ApprovedConfirmedStatus
							approved={clientHasApproved(mealProposal)}
							confirmed={clientHasConfirmed(mealProposal)}
							text={
								<Link
									routeName="publicMealProposalsDetail"
									variables={{ id }}
								>
									{`${orderNumberText}${
										name ? `: ${name}` : ''
									}`}
								</Link>
							}
						/>
					),
				},
				{
					title: 'Client',
					field: 'ClientLocation.Client.name',
					render: ({ ClientLocation }) => (
						<>
							<div>{ClientLocation.Client.name}</div>
							<div>{ClientLocation.friendlyName}</div>
						</>
					),
				},
				{
					title: 'Delivery Date',
					field: 'deliveryDate',
					type: 'datetime',
					render: ({ deliveryDate, ClientLocation }) => (
						<DateOutput
							date={deliveryDate}
							timezone={ClientLocation.timezone}
							formatter="dateAndTimeMedium"
						/>
					),
				},
				{
					title: 'Head Count',
					field: 'headCount',
				},
				{
					title: 'Restaurants',
					field: 'restaurants',
				},
			]}
		/>
	);
};

export const OrderSection = ({ header, table }) => {
	return (
		<section>
			<Typography variant="h4" component="h2">
				<Box margin={[4, 0, 2, 0]}>{header}</Box>
			</Typography>
			{table}
		</section>
	);
};
