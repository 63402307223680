import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Alert, PrimaryButton } from '@sporkbytes/material-ui-kit-react';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import Link from 'components/routing/Link';
import Page from 'components/layout/Page';

import { useAuth } from 'services/auth';

const ProtectedRoute = ({ component, permissions, ...props }) => {
	const {
		isAuthenticated,
		loading,
		loginWithRedirect,
		userCannot,
	} = useAuth();

	return (
		<Route
			component={
				loading
					? () => <CenteredCircularProgress />
					: !isAuthenticated || userCannot(permissions)
					? () => (
							<Page
								title={
									!isAuthenticated
										? 'Please Sign In'
										: 'Not Authorized'
								}
							>
								<Alert type="error" size="large">
									{!isAuthenticated
										? 'You must be signed in to view this content.  Please sign in.'
										: 'You are not authorized to view this content.'}
								</Alert>
								{!isAuthenticated ? (
									<PrimaryButton
										onClick={() =>
											loginWithRedirect({
												appState: {
													targetUrl:
														window.location
															.pathname,
												},
												connection: 'Users',
											})
										}
									>
										Sign In
									</PrimaryButton>
								) : (
									<PrimaryButton
										component={Link}
										routeName="home"
									>
										Go Home
									</PrimaryButton>
								)}
							</Page>
					  )
					: component
			}
			{...props}
		/>
	);
};

ProtectedRoute.propTypes = {
	component: PropTypes.elementType.isRequired,
	permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProtectedRoute;
