import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const FeedsNumber = ({ feedsMinimum, feedsMaximum, ...props }) => {
	let content;

	if (feedsMinimum && !feedsMaximum) {
		content = <>at least {feedsMinimum}</>;
	} else if (feedsMaximum && !feedsMinimum) {
		content = <>up to {feedsMaximum}</>;
	} else if (feedsMinimum && feedsMaximum) {
		content = (
			<>
				{feedsMinimum} - {feedsMaximum}
			</>
		);
	}

	return content ? <Typography {...props}>Feeds {content}</Typography> : null;
};

FeedsNumber.propTypes = {
	feedsMinimum: PropTypes.number,
	feedsMaximum: PropTypes.number,
};

export default FeedsNumber;
