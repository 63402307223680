import React from 'react';
import { post } from '@sporkbytes/api-client/SporkLocations';

import SporkLocationForm from 'components/spork-locations/SporkLocationForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { useStore } from 'models/store';

const Create = () => {
	const { setActiveSporkLocations } = useStore();

	return (
		<Page title="Add New Spork Location">
			<PageHeader headerText="Add New Spork Location" />
			<FormWrapper
				form={SporkLocationForm}
				onSubmit={async (axios, values) => {
					await post(axios, values);
					setActiveSporkLocations();
				}}
			/>
		</Page>
	);
};

export default Create;
