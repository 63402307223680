import React from 'react';
import { useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { startOfDay, endOfDay } from 'date-fns';
import { Card, Divider, makeStyles, useMediaQuery } from '@material-ui/core';

import { useDashboardDay } from 'pages/dashboard/DashboardDayContext';
import DashboardDayHeader from 'pages/dashboard/DashboardDayHeader';
import DashboardDayMealProposals from 'pages/dashboard/DashboardDayMealProposals';
import ExpandedColumnHeader from 'pages/dashboard/ExpandedColumnHeader';
import LoadingStateContainer from 'components/utilities/LoadingStateContainer';

import { useStore } from 'models/store';

const MEALPROPOSALS = gql`
	subscription MealProposals(
		$beginDate: timestamptz!
		$endDate: timestamptz!
		$SporkLocationId: uuid!
	) {
		MealProposals(
			where: {
				deliveryDate: { _gte: $beginDate, _lte: $endDate }
				status: { _in: ["Delivered", "In Progress"] }
				SporkLocationId: { _eq: $SporkLocationId }
			}
			order_by: { deliveryDate: asc }
		) {
			id
			...DashboardDayHeader
			...DashboardDayMealProposals
		}
	}
	${DashboardDayHeader.fragments.details}
	${DashboardDayMealProposals.fragments.details}
`;

const useStyles = makeStyles(theme => ({
	container: ({ expanded }) =>
		expanded
			? {
					display: 'flex',
					flexDirection: 'column',
					height: '100vh',
					left: 0,
					position: 'absolute',
					top: 0,
					width: '100vw',
					zIndex: theme.zIndex.modal,
			  }
			: {
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
			  },
	loadingStateContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
}));

const DashboardDay = () => {
	const { date, showSchedulingControls, expanded, setMealProposals } =
		useDashboardDay();
	const { selectedSporkLocationId } = useStore();
	const showColumnHeader = useMediaQuery(theme => theme.breakpoints.up('md'));
	const classes = useStyles({ expanded });

	const { loading } = useSubscription(MEALPROPOSALS, {
		variables: {
			beginDate: startOfDay(date),
			endDate: endOfDay(date),
			SporkLocationId: selectedSporkLocationId,
		},
		onSubscriptionData: ({
			subscriptionData: {
				data: { MealProposals },
			},
		}) => setMealProposals(MealProposals),
	});

	return (
		<Card className={classes.container}>
			<LoadingStateContainer
				loading={loading}
				className={classes.loadingStateContainer}
			>
				<DashboardDayHeader />

				{expanded && !showSchedulingControls && showColumnHeader && (
					<ExpandedColumnHeader />
				)}
				<Divider />

				<DashboardDayMealProposals />
			</LoadingStateContainer>
		</Card>
	);
};

export default DashboardDay;
