import React from 'react';
import gql from 'graphql-tag';
import { TableRow } from '@material-ui/core';
import { totalReceivedByPartner } from '@sporkbytes/purchase-order-utils';

import TableCellFeatured from 'components/content/TableCellFeatured';
import TableCellNumeric from 'components/content/TableCellNumeric';

const PurchaseOrderTotalReceivedByPartnerLineItem = ({ purchaseOrder }) => (
	<TableRow>
		<TableCellFeatured colSpan="4">
			Total Received by {purchaseOrder.PartnerLocation.Partner.name}
		</TableCellFeatured>
		<TableCellNumeric
			format="currency"
			value={totalReceivedByPartner(purchaseOrder)}
		/>
	</TableRow>
);

PurchaseOrderTotalReceivedByPartnerLineItem.fragments = {
	details: gql`
		fragment PurchaseOrderTotalReceivedByPartnerLineItemDetails on PurchaseOrders {
			sporkCommissionPercent
			PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
				PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
					discount
					paidToPartner
					quantity
				}
			}
			PurchaseOrderPayoutAdjustments {
				amount
			}
		}
	`,
};

export default PurchaseOrderTotalReceivedByPartnerLineItem;
