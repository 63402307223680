import React, { useContext, useState } from 'react';
import gql from 'graphql-tag';
import Chart from 'react-apexcharts';
import { isPast, parseISO } from 'date-fns';
import { clientHasApproved } from '@sporkbytes/meal-proposal-utils';
import { getClientApproved } from '@sporkbytes/meal-option-utils';
import { Autocomplete } from '@sporkbytes/material-ui-kit-react';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

const DeliveryDriversToPartner = () => {
	const [selectedPartnerIndex, setSelectedPartnerIndex] = useState(0);
	const { chartConfig, mealProposalsForCalculations } =
		useContext(AnalyticsContext);

	let partners = {};

	mealProposalsForCalculations
		.filter(clientHasApproved)
		.filter(({ deliveryDate }) => isPast(parseISO(deliveryDate)))
		.forEach(({ MealOptions, MealProposalsDeliveryDrivers }) => {
			getClientApproved(MealOptions).PurchaseOrders.forEach(
				({ PartnerLocation: { Partner } }) => {
					let currentPartner = partners[Partner.id];

					if (!currentPartner) {
						currentPartner = {
							name: Partner.name,
							drivers: {},
						};

						partners[Partner.id] = currentPartner;
					}

					MealProposalsDeliveryDrivers.forEach(
						({ DeliveryDriver: { id: driverId, fullName } }) => {
							let driver = currentPartner.drivers[driverId];

							if (!driver) {
								driver = {
									fullName,
									deliveries: 0,
								};

								currentPartner.drivers[driverId] = driver;
							}

							driver.deliveries++;
						}
					);
				}
			);
		});

	partners = Object.values(partners)
		.sort((partnerA, partnerB) =>
			partnerA.name.localeCompare(partnerB.name)
		)
		.map(({ name, drivers }, index) => ({
			value: index,
			label: name,
			data: Object.values(drivers)
				.sort(
					(driverA, driverB) =>
						driverB.deliveries - driverA.deliveries
				)
				.reduce(
					(
						{ drivers, deliveriesToPartner },
						{ fullName, deliveries }
					) => ({
						drivers: [...drivers, fullName],
						deliveriesToPartner: [
							...deliveriesToPartner,
							deliveries,
						],
					}),
					{ drivers: [], deliveriesToPartner: [] }
				),
		}));

	const currentPartner = partners[selectedPartnerIndex];
	const deliveredMealsFound = !!currentPartner;
	const drivers = deliveredMealsFound ? currentPartner.data.drivers : [];
	const deliveriesToPartner = deliveredMealsFound
		? currentPartner.data.deliveriesToPartner
		: [];

	const options = {
		...chartConfig.options,
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: drivers,
		},
		yaxis: {
			tickAmount: 1,
		},
	};
	const series = [
		{
			data: deliveriesToPartner,
			name: 'Deliveries',
		},
	];

	return (
		<>
			<WidgetTitle>Pickups from Partner</WidgetTitle>
			<Autocomplete
				getOptionLabel={({ label }) => label}
				onChange={({ value }) => setSelectedPartnerIndex(value)}
				options={partners}
				placeholder="Select Partner"
				value={currentPartner}
			/>
			<Chart
				type="bar"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</>
	);
};

DeliveryDriversToPartner.fragments = {
	details: gql`
		fragment DeliveryDriversToPartnerDetails on MealProposals {
			MealOptions {
				PurchaseOrders {
					PartnerLocation {
						Partner {
							id
							name
						}
					}
				}
			}
			MealProposalsDeliveryDrivers {
				DeliveryDriver {
					id
					fullName
				}
			}
		}
	`,
};

export default DeliveryDriversToPartner;
