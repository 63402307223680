import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { People, Person } from '@material-ui/icons';
import {
	GridContainer,
	LinkedCheckboxGroup,
	LinkedCurrencyInput,
	LinkedIntegerInput,
	LinkedRichTextEditor,
	LinkedSwitch,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import FormSectionHeader from 'components/forms/FormSectionHeader';
import { LinkedS3Upload } from 'components/forms/S3Upload';

import * as yup from 'models/validation';

const ALL_DIETARY_TAGS_QUERY = gql`
	query AllDietaryTags {
		DietaryTags(order_by: { sortOrder: asc }) {
			id
			name
		}
	}
`;

const validationSchema = yup.object().shape({
	name: yup.name().required(),
	printableName: yup.name(),
	cost: yup.positiveNumber(),
	size: yup.string().max(50, 'Please limit to 50 characters.'),
	feedsMinimum: yup.positiveInteger(),
	feedsMaximum: yup.positiveInteger(),
	producesLabel: yup.boolean().required(),
	visibleInMenu: yup.boolean().required(),
	showInDriverChecklist: yup.boolean().required(),
	description: yup.string(),
	howToOrder: yup.string(),
	DietaryTags: yup.array().of(yup.string()),
});

const PartnerMenuItemForm = props => {
	const { loading, data } = useQuery(ALL_DIETARY_TAGS_QUERY);

	return loading ? (
		<CenteredCircularProgress />
	) : (
		<>
			<GridContainer columns={3}>
				<LinkedTextInput name="name" autoFocus />
				<LinkedTextInput name="printableName" />
				<LinkedCurrencyInput
					name="cost"
					helperText="This is the cost of the item when it is sold à la carte."
				/>
				<LinkedTextInput name="size" />
				<LinkedIntegerInput name="feedsMinimum" icon={<Person />} />
				<LinkedIntegerInput name="feedsMaximum" icon={<People />} />
			</GridContainer>
			<GridContainer>
				<LinkedSwitch
					name="producesLabel"
					uncheckedHelperText="This item will not produce a label when labels are printed for a meal."
					checkedHelperText="This item will produce a label when labels are printed for a meal."
				/>
				<LinkedSwitch
					name="visibleInMenu"
					uncheckedHelperText="This item will not be visible in the printable menu."
					checkedHelperText="This item will be visible in the printable menu."
				/>
				<LinkedSwitch
					name="showInDriverChecklist"
					uncheckedHelperText="This item will not be visible in the driver checklist."
					checkedHelperText="This item will be visible in the driver checklist."
				/>
			</GridContainer>
			<GridContainer>
				<LinkedRichTextEditor name="description" />
			</GridContainer>
			<GridContainer>
				<LinkedRichTextEditor name="howToOrder" />
			</GridContainer>
			<GridContainer columns={2}>
				<LinkedS3Upload
					name="photoUrl"
					label="Choose a Photo"
					path="photos/partner-menu-items/"
				/>
			</GridContainer>
			<FormSectionHeader>Dietary Tags</FormSectionHeader>
			<GridContainer>
				<LinkedCheckboxGroup
					name="DietaryTags"
					label="Check the boxes for all applicable dietary tags for this item."
					row
					options={data.DietaryTags}
					getOptionLabel={option => option.name}
					getOptionValue={option => option.id}
					getOptionKey={option => option.id}
				/>
			</GridContainer>
		</>
	);
};

export default PartnerMenuItemForm;
export { validationSchema };
