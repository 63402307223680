import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/PartnerLocations';

import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import DetailPageLink from 'components/routing/DetailPageLink';
import PartnerLocationActionMenu from 'components/partner-locations/PartnerLocationActionMenu';
import PartnerLocationDetails from 'components/partner-locations/PartnerLocationDetails';
import RemoteTable from 'components/content/RemoteTable';

import { getMultiWordSearchWhereClause } from 'services/search';

const getPartnerLocationsQuery = ContactId => searchTerm => gql`
	query PartnerLocations(
		$PartnerId: uuid
		$ContactId: uuid
		$activeOrderBy: order_by
		$friendlyNameOrderBy: order_by
		$fullAddressOrderBy: order_by
		$partnerNameOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		PartnerLocations(
			where: {
				_and: [
					{ PartnerId: { _eq: $PartnerId } }
					${
						ContactId
							? '{ PartnerLocationsContacts: { ContactId: { _eq: $ContactId } } }'
							: ''
					}
					{
						_or: [
							{Partner: { _and: [ ${getMultiWordSearchWhereClause(searchTerm)} ] } }
							${getMultiWordSearchWhereClause(searchTerm)}
						]
					}
				]
			}
			order_by: {
				active: $activeOrderBy
				friendlyName: $friendlyNameOrderBy
				fullAddress: $fullAddressOrderBy
				Partner: { name: $partnerNameOrderBy }
			}
			limit: $limit
			offset: $offset
		) {
			id
			active
			friendlyName
			fullAddress
			phoneNumber
			Partner {
				id
				name
			}
		}
		PartnerLocations_aggregate(
			where: {
				_and: [
					{ PartnerId: { _eq: $PartnerId } }
					{ PartnerLocationsContacts: { ContactId: { _eq: $ContactId } } }
					{
						_or: [
							{Partner: { _and: [ ${getMultiWordSearchWhereClause(searchTerm)} ] } }
							${getMultiWordSearchWhereClause(searchTerm)}
						]
					}
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const PARTNER_LOCATION_DETAIL = gql`
	query PartnerLocationDetails($id: uuid!) {
		PartnerLocations_by_pk(id: $id) {
			...PartnerLocationDetails
		}
	}
	${PartnerLocationDetails.fragments.details}
`;

const PartnerLocationsTable = ({
	additionalActions,
	hiddenColumns,
	variables,
	...props
}) => {
	const ref = useRef();

	return (
		<RemoteTable
			{...props}
			tableRef={ref}
			columns={[
				{
					title: 'Partner',
					field: 'partnerName',
					render: ({ Partner: { name, id } }) => (
						<DetailPageLink
							entityType="Partners"
							id={id}
							text={name}
						/>
					),
				},
				{
					title: 'Name',
					field: 'friendlyName',
					render: ({ id, friendlyName }) => (
						<DetailPageLink
							entityType="PartnerLocations"
							id={id}
							text={friendlyName}
						/>
					),
				},
				{
					title: 'Address',
					field: 'fullAddress',
				},
				{
					title: 'Active',
					field: 'active',
					render: ({ active, id }) => (
						<BooleanFieldSwitch
							afterChange={ref.current.onQueryChange}
							entityId={id}
							entityType="partnerLocations"
							updateEntity={put}
							value={active}
						/>
					),
				},
				{
					title: 'Actions',
					field: 'actions',
					sorting: false,
					render: partnerLocation => (
						<PartnerLocationActionMenu
							partnerLocation={partnerLocation}
						/>
					),
				},
			].filter(column => !hiddenColumns.includes(column.field))}
			defaultOrderBy="active"
			defaultOrderDirection="desc"
			getQuery={getPartnerLocationsQuery(variables.ContactId)}
			queryEntity="PartnerLocations"
			variables={variables}
			remoteDetailPanel={{
				query: PARTNER_LOCATION_DETAIL,
				getVariables: ({ id }) => ({ id }),
				render: ({ data }) => (
					<PartnerLocationDetails
						partnerLocation={data.PartnerLocations_by_pk}
					/>
				),
			}}
			actions={additionalActions(ref)}
		/>
	);
};

PartnerLocationsTable.propTypes = {
	additionalActions: PropTypes.func,
	hiddenColumns: PropTypes.arrayOf(PropTypes.string),
	variables: PropTypes.shape({
		PartnerId: PropTypes.string,
		ContactId: PropTypes.string,
	}),
};

PartnerLocationsTable.defaultProps = {
	additionalActions: () => undefined,
	hiddenColumns: [],
	variables: {},
};

export default PartnerLocationsTable;
