import React, { createContext, useState } from 'react';

const AccordionGroupContext = createContext();

const AccordionGroup = ({ children }) => {
	const [expandedItem, setExpandedItem] = useState();

	return (
		<AccordionGroupContext.Provider
			value={{
				expandedItem,
				setExpandedItem,
			}}
		>
			{children}
		</AccordionGroupContext.Provider>
	);
};

export default AccordionGroup;
export { AccordionGroupContext };
