import React from 'react';
import gql from 'graphql-tag';

import SporkReviewForm from 'components/spork-reviews/SporkReviewForm';

const SporkReviewFormCompleted = ({
	sporkReview: { comments, SporkReviewAnswers },
}) => {
	return (
		<SporkReviewForm
			disabled
			sporkReviewQuestions={SporkReviewAnswers.map(
				({ required, SporkReviewQuestion }) => ({
					...SporkReviewQuestion,
					required,
				})
			)}
			initialValues={{
				comments,
				sporkReviewQuestions: SporkReviewAnswers.map(
					({ answer, SporkReviewQuestion: { id } }) => ({
						id,
						answer,
					})
				),
			}}
		/>
	);
};

SporkReviewFormCompleted.fragments = {
	details: gql`
		fragment SporkReviewFormCompleted on SporkReviews {
			comments
			SporkReviewAnswers(order_by: { sortOrder: asc }) {
				answer
				required
				SporkReviewQuestion {
					id
					question
				}
			}
		}
	`,
};

export default SporkReviewFormCompleted;
