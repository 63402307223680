import Edit from 'pages/delivery-gear/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'deliveryGearEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['delivery-gear:edit'],
		exact: true,
	},
];

export default prependUrlPath('delivery-gear', routes);
