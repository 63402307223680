import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { CardContent } from '@material-ui/core';
import { ImportContacts } from '@material-ui/icons';
import { Query } from '@apollo/react-components';
import { DialogForm } from '@sporkbytes/material-ui-kit-react';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import Table from 'components/content/Table';

import * as yup from 'models/validation';
import { useStore } from 'models/store';

import apolloClient from 'services/apollo';

const RemoteTable = ({
	actions = [],
	addNewEntityRouteName,
	addNewEntityRouteVariables,
	canAddExistingEntity,
	canAddNewEntity,
	defaultOrderBy,
	defaultOrderDirection = 'asc',
	detailPanel,
	entityToAddName,
	EntityToAddSearch,
	getQuery,
	onAddExistingEntity,
	queryEntity,
	remoteDetailPanel,
	tableRef,
	variables,
	...props
}) => {
	const { showDialog } = useStore();
	const history = useHistory();

	useEffect(() => {
		tableRef && tableRef.current.onQueryChange();
	}, [variables, tableRef]);

	if (remoteDetailPanel) {
		const { query, getVariables, render: Render } = remoteDetailPanel;

		detailPanel = rowData => (
			<Query query={query} variables={getVariables(rowData)}>
				{({ loading, data }) => (
					<CardContent>
						{loading ? (
							<CenteredCircularProgress />
						) : (
							<Render data={data} />
						)}
					</CardContent>
				)}
			</Query>
		);
	}

	return (
		<>
			<Table
				{...props}
				tableRef={tableRef}
				detailPanel={detailPanel}
				data={({
					pageSize,
					page,
					orderBy = { field: defaultOrderBy },
					orderDirection = defaultOrderDirection,
					search,
					filters,
				}) => {
					orderDirection =
						orderDirection === ''
							? defaultOrderDirection
							: orderDirection;

					filters = filters
						.filter(({ value }) => {
							if (Array.isArray(value)) {
								return value.length > 0;
							}
							return !!value;
						})
						.reduce(
							(filters, filter) => ({
								...filters,
								[filter.column.field + 'Filter']: filter.value,
							}),
							{}
						);

					return apolloClient
						.query({
							query: getQuery(search),
							variables: {
								...variables,
								limit: pageSize,
								offset: page * pageSize,
								[`${orderBy.field}OrderBy`]:
									orderDirection === 'asc'
										? 'asc_nulls_first'
										: 'desc_nulls_last',
								...filters,
							},
						})
						.then(({ data }) => ({
							data: data[queryEntity],
							totalCount:
								data[`${queryEntity}_aggregate`].aggregate
									.count,
							page,
						}));
				}}
				actions={[
					{
						hidden: !canAddExistingEntity,
						icon: () => <ImportContacts />,
						tooltip: `Add Existing ${entityToAddName}`,
						onClick: () =>
							showDialog(props => (
								<DialogForm
									title={`Search for a ${entityToAddName}`}
									onSubmit={values =>
										onAddExistingEntity(
											values[entityToAddName]
										)
									}
									validationSchema={yup.object().shape({
										[entityToAddName]: yup
											.object()
											.required(
												`Please select a ${entityToAddName}.`
											),
									})}
									{...props}
								>
									{() => (
										<EntityToAddSearch
											name={entityToAddName}
										/>
									)}
								</DialogForm>
							)),
						isFreeAction: true,
					},
					{
						hidden: !canAddNewEntity,
						icon: 'add',
						tooltip: `Add New ${entityToAddName}`,
						onClick: () =>
							history.pushNamedRoute(
								addNewEntityRouteName,
								addNewEntityRouteVariables
							),
						isFreeAction: true,
					},
					...actions,
				]}
			/>
		</>
	);
};

RemoteTable.propTypes = {
	addNewEntityRouteName: PropTypes.string,
	addNewEntityRouteVariables: PropTypes.object,
	canAddExistingEntity: PropTypes.bool,
	canAddNewEntity: PropTypes.bool,
	defaultOrderBy: PropTypes.string.isRequired,
	defaultOrderDirection: PropTypes.oneOf(['asc', 'desc']),
	detailPanel: PropTypes.func,
	entityToAddName: PropTypes.string,
	EntityToAddSearch: PropTypes.elementType,
	getQuery: PropTypes.func.isRequired,
	onAddExistingEntity: PropTypes.func,
	queryEntity: PropTypes.string.isRequired,
	remoteDetailPanel: PropTypes.shape({
		query: PropTypes.object.isRequired,
		getVariables: PropTypes.func.isRequired,
		render: PropTypes.elementType.isRequired,
	}),
	variables: PropTypes.object,
};

export default RemoteTable;
