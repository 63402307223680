import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, makeStyles, useTheme } from '@material-ui/core';

import DrawerContent from './DrawerContent';

import { useAuth } from 'services/auth';

const useStyles = makeStyles(theme => ({
	drawerPaper: {
		width: 240,
	},
	toolbar: theme.mixins.toolbar,
}));

const NavigationDrawer = ({ onClose, open }) => {
	const { loading, isAuthenticated } = useAuth();
	const classes = useStyles();
	const theme = useTheme();

	return (
		!loading &&
		isAuthenticated && (
			<Drawer
				variant="temporary"
				anchor={theme.direction === 'rtl' ? 'right' : 'left'}
				open={open}
				onClose={onClose}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<DrawerContent onClose={onClose} />
			</Drawer>
		)
	);
};

NavigationDrawer.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool,
};

export default NavigationDrawer;
