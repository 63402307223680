import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { post } from '@sporkbytes/api-client/ClientLocations';

import ClientLocationForm from 'components/client-locations/ClientLocationForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

const CLIENT_QUERY = gql`
	query ClientLocationCreate($id: uuid!) {
		Clients_by_pk(id: $id) {
			logoUrl
			name
		}
	}
`;

const ClientLocationCreate = () => {
	const { id: ClientId } = useParams();
	const initialValues = {
		paymentDue: 7,
		dietaryRestrictions: `<ul><li>Vegan: 0</li><li>Vegetarian: 0</li><li>Gluten-Free: 0</li><li>Dairy-Free: 0</li><li>Nut-Free: 0</li><li>Soy-Free: 0</li><li>Other:</li></ul>`,
		timeToPark: 5,
		timeToEnter: 2,
		timeToExit: 2,
		automatedApprovalThankYou: true,
		automatedEmailConfirmation: true,
		automatedReviewRequest: true,
	};

	return (
		<Page
			title={({ Clients_by_pk: { name } }) => `New Location for ${name}`}
			query={CLIENT_QUERY}
			variables={{ id: ClientId }}
		>
			{({
				data: {
					Clients_by_pk: { name, logoUrl },
				},
			}) => (
				<>
					<PageHeader headerText={name} imgUrl={logoUrl} />
					<PageSubheader>Add New Client Location</PageSubheader>
					<FormWrapper
						form={ClientLocationForm}
						initialValues={initialValues}
						onSubmit={(axios, values) =>
							post(axios, {
								ClientId,
								...values,
							})
						}
					/>
				</>
			)}
		</Page>
	);
};

export default ClientLocationCreate;
