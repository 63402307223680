import React, { useState } from 'react';
import gql from 'graphql-tag';
import {
	Currency,
	Form,
	GridContainer,
	LinkedCurrencyInput,
	LinkedSingleSelect,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';
import { getIncludedItems } from '@sporkbytes/purchase-order-utils';
import {
	itemTotalAfterDiscount,
	itemTotalDiscountActual,
} from '@sporkbytes/purchase-order-menu-item-utils';

import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

import * as yup from 'models/validation';

const PurchaseOrderPayoutAdjustmentForm = ({
	pageSubheaderText,
	purchaseOrder,
	...props
}) => {
	const [canEditText, setCanEditText] = useState(true);
	const validationSchema = yup.object().shape({
		amount: yup.number().required('Please enter an amount.'),
		description: yup
			.string()
			.required('Please enter a description of the payout adjustment.'),
		PurchaseOrderMenuItemId: yup.string(),
	});
	const menuItemOptions = getIncludedItems(purchaseOrder).sort((a, b) =>
		a.PartnerMenuItem.friendlyName.localeCompare(
			b.PartnerMenuItem.friendlyName
		)
	);

	return (
		<Form {...props} validationSchema={validationSchema}>
			{({ setFieldValue }) => (
				<>
					<PageHeader
						headerText={`${purchaseOrder.PartnerLocation.Partner.name} - ${purchaseOrder.MealOption.MealProposal.orderNumberText}`}
					/>
					<PageSubheader>{pageSubheaderText}</PageSubheader>
					<GridContainer>
						<LinkedSingleSelect
							emptyOptionLabel="None"
							name="PurchaseOrderMenuItemId"
							label="Choose a related item"
							options={menuItemOptions}
							getOptionKey={option => option.id}
							getOptionLabel={option => {
								const optionDiscount = itemTotalDiscountActual(
									option,
									'costToClient'
								);

								return (
									<span>
										{option.PartnerMenuItem.friendlyName}
										{option.PartnerMenuItem.size &&
											` (${option.PartnerMenuItem.size})`}{' '}
										-{' '}
										<Currency
											value={itemTotalAfterDiscount(
												option,
												'costToClient'
											)}
										/>
										{optionDiscount > 0 && (
											<Currency
												value={optionDiscount}
												renderText={value =>
													` with a discount of ${value}`
												}
											/>
										)}
									</span>
								);
							}}
							getOptionValue={option => option.id}
							helperText="Optional.  Choose an item if this adjustment is directly related to it, for instance if the item was discounted and the partner should cover the discount."
							onChange={id => {
								setFieldValue('PurchaseOrderMenuItemId', id);

								const relatedItem = menuItemOptions.find(
									({ id: PurchaseOrderMenuItemId }) =>
										id === PurchaseOrderMenuItemId
								);

								if (relatedItem) {
									setFieldValue(
										'amount',
										-itemTotalDiscountActual(
											relatedItem,
											'costToClient'
										)
									);
									setFieldValue(
										'description',
										`Discount for ${relatedItem.PartnerMenuItem.friendlyName}`
									);
									setCanEditText(false);
								} else {
									setFieldValue('amount', '');
									setFieldValue('description', '');
									setCanEditText(true);
								}
							}}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedCurrencyInput
							name="amount"
							helperText="Negative amounts will decrease the partner's payout; positive amounts will increase it."
							disabled={!canEditText}
						/>
						<LinkedTextInput
							name="description"
							disabled={!canEditText}
						/>
					</GridContainer>
				</>
			)}
		</Form>
	);
};

PurchaseOrderPayoutAdjustmentForm.fragments = {
	details: gql`
		fragment PurchaseOrderPayoutAdjustmentFormDetails on PurchaseOrders {
			MealOption {
				MealProposal {
					orderNumberText
				}
			}
			PartnerLocation {
				Partner {
					name
				}
			}
			PurchaseOrderMenuSections {
				PurchaseOrderMenuItems(
					order_by: { PartnerMenuItem: { friendlyName: asc } }
					where: {
						costToClient: { _is_null: false }
						discount: { _is_null: false }
						quantity: { _is_null: false }
					}
				) {
					id
					costToClient
					discount
					quantity
					PartnerMenuItem {
						friendlyName
						size
					}
				}
			}
		}
	`,
};

export default PurchaseOrderPayoutAdjustmentForm;
