import React from 'react';
import PropTypes from 'prop-types';
import {
	Form,
	GridContainer,
	LinkedPercentInput,
	LinkedPhoneNumberInput,
	LinkedTextInput,
	LinkedZIPCodeInput,
} from '@sporkbytes/material-ui-kit-react';
import { Phone, Email } from '@material-ui/icons';
import kebabCase from 'lodash.kebabcase';

import Link from 'components/routing/Link';
import StateSelector from 'components/forms/StateSelector';

import * as yup from 'models/validation';

const getValidationSchema = ({ initialValues }) =>
	yup.object().shape({
		name: yup.name().required().unique({
			tableName: 'SporkLocations',
			fieldName: 'name',
			message:
				'This name is already in use. Please enter a different name.',
			initialValue: initialValues.name,
		}),
		slug: yup
			.string()
			.max(50, 'Please limit to 50 characters.')
			.matches(
				/^[a-z0-9-]+$/,
				'Only lowercase letters, numbers, and hyphens are allowed.'
			)
			.required('Please enter a slug.')
			.unique({
				tableName: 'SporkLocations',
				fieldName: 'slug',
				message:
					'This slug is already in use. Please enter a different slug.',
				initialValue: initialValues.slug,
			}),
		streetAddress: yup.streetAddress().required(),
		streetAddress2: yup.string(),
		city: yup.city().required(),
		state: yup.state().required(),
		zipCode: yup.zipCode().required(),
		phoneNumber: yup.phoneNumber().required(),
		emailAddress: yup
			.string()
			.email('Please enter a valid email address.')
			.required('Please enter an email address.'),
		sporkCommissionPercent: yup
			.nonNegativeNumber()
			.max(100, 'Please enter a number no greater than 100.')
			.required('Please enter a commission.'),
		ServicedStates: yup
			.array()
			.required('Please select at least one state.'),
	});

const SporkLocationForm = props => {
	return (
		<Form
			{...props}
			validationSchema={getValidationSchema({
				initialValues: props.initialValues,
			})}
		>
			{({ setFieldValue }) => (
				<>
					<GridContainer>
						<LinkedTextInput
							name="name"
							autoFocus
							onChange={({ target: { value } }) => {
								setFieldValue('name', value);
								setFieldValue('slug', kebabCase(value));
							}}
						/>
						<LinkedTextInput
							name="slug"
							helperText="The slug is used for filtering on the marketing site, e.g. /the-restaurants?spork-location=portland."
						/>
					</GridContainer>
					<GridContainer>
						<LinkedTextInput name="streetAddress" />
						<LinkedTextInput name="streetAddress2" />
					</GridContainer>
					<GridContainer>
						<LinkedTextInput name="city" />
						<StateSelector
							name="state"
							helperText={
								<>
									If you don't see the state you need, make
									sure to add it to{' '}
									<Link
										permissions={['serviced-states:view']}
										routeName="servicedStates"
									>
										the list of Serviced States
									</Link>
									.
								</>
							}
						/>
						<LinkedZIPCodeInput name="zipCode" />
					</GridContainer>
					<GridContainer>
						<LinkedTextInput
							name="emailAddress"
							label="Email Address"
							type="email"
							icon={<Email />}
							helperText="This should be an email address specific to this location."
						/>
						<LinkedPhoneNumberInput
							name="phoneNumber"
							icon={<Phone />}
							helperText="This should be a phone number specific to this location, but it doesn't need to be."
						/>
					</GridContainer>
					<GridContainer>
						<LinkedPercentInput name="sporkCommissionPercent" />
						<StateSelector
							name="ServicedStates"
							getOptionValue={({ id }) => id}
							helperText="These are the states to which this location will provide service."
							type="multiple"
						/>
					</GridContainer>
				</>
			)}
		</Form>
	);
};

SporkLocationForm.propTypes = {
	initialValues: PropTypes.object,
};

SporkLocationForm.defaultProps = {
	initialValues: {},
};

export default SporkLocationForm;
