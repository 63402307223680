import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { post } from '@sporkbytes/api-client/PartnerMenuItemOptionGroups';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PartnerMenuItemOptionGroupForm from 'components/partner-menu-item-option-groups/PartnerMenuItemOptionGroupForm';

const PARTNER_MENU_SECTION_QUERY = gql`
	query PartnerMenuItemOptionGroupCreate($id: uuid!) {
		PartnerMenuItems_by_pk(id: $id) {
			name
			Partner {
				id
				logoUrl
				name
			}
		}
	}
`;

const PartnerMenuItemOptionGroupCreate = () => {
	const { id: PartnerMenuItemId } = useParams();

	return (
		<Page
			title={({ PartnerMenuItems_by_pk: { name } }) =>
				`New Option Group for ${name}`
			}
			query={PARTNER_MENU_SECTION_QUERY}
			variables={{ id: PartnerMenuItemId }}
		>
			{({
				data: {
					PartnerMenuItems_by_pk: { name, Partner },
				},
			}) => (
				<>
					<PageHeader
						headerText={Partner.name}
						imgUrl={Partner.logoUrl}
					/>
					<PageSubheader>
						<div>{name}</div>
						<div>Add New Option Group</div>
					</PageSubheader>
					<FormWrapper
						form={PartnerMenuItemOptionGroupForm}
						onSubmit={(axios, values) =>
							post(axios, {
								PartnerMenuItemId,
								...values,
							})
						}
					/>
				</>
			)}
		</Page>
	);
};

export default PartnerMenuItemOptionGroupCreate;
