import React from 'react';
import PropTypes from 'prop-types';
import {
	Card,
	CardContent,
	CardHeader,
	makeStyles,
	Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	card: {
		display: 'flex',
		flexDirection: 'column',
		height: 225,
		justifyContent: 'space-between',
		minWidth: 225,
	},
	cardContent: {
		textAlign: 'center',
	},
	cardFooter: {
		alignItems: 'center',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		display: 'flex',
		flexDirection: 'column',
		height: 64,
		justifyContent: 'center',
	},
	cardHeader: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		textAlign: 'center',
	},
}));

const MetricCard = ({ footer, label, showFooter, value }) => {
	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<CardHeader title={label} className={classes.cardHeader} />
			<CardContent className={classes.cardContent}>
				<Typography variant="h5">{value}</Typography>
			</CardContent>
			<Typography className={classes.cardFooter} component="div">
				{showFooter && footer}
			</Typography>
		</Card>
	);
};

MetricCard.propTypes = {
	footer: PropTypes.element,
	label: PropTypes.string.isRequired,
	showFooter: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.element])
		.isRequired,
};

MetricCard.defaultProps = {
	showFooter: true,
};

export default MetricCard;
