import { assign, Machine } from 'xstate';
import {
	isApproved,
	isConfirmed,
	isNotApproved,
	isNotConfirmed,
} from '@sporkbytes/object-utils';

export default (id, { events = {}, guards = {} } = {}) => {
	// EVENTS

	const APPROVE = {
		target: 'approving',
		cond: 'canApprove',
	};

	const CONFIRM = {
		target: 'confirming',
		cond: 'canConfirm',
	};

	const DELETE = {
		actions: 'delete',
		cond: 'canDelete',
	};

	const DISAPPROVE = {
		target: 'disapproving',
		cond: 'canDisapprove',
	};

	const UNDO_CONFIRMATION = {
		target: 'undoing_confirmation',
		cond: 'canUndoConfirmation',
	};

	const UPDATE_DATA = {
		actions: assign((context, event) => ({
			...context,
			...event.data,
		})),
	};

	// STATES

	const approving = {
		invoke: {
			id: 'approvingMealOption',
			src: 'approve',
			onDone: {
				target: 'approved',
			},
			onError: {
				target: 'disapproved',
			},
		},
	};

	const approved = {
		invoke: {
			id: 'updateData',
			src: 'updateData',
		},
		on: {
			// This will return us to the proper state when the UPDATE_DATA event is sent
			'': [
				{
					target: 'confirmed',
					cond: 'isConfirmed',
				},
				{
					target: 'disapproved',
					cond: 'isNotApproved',
				},
			],
			CONFIRM,
			DISAPPROVE,
			UPDATE_DATA,
			...(events?.approved ?? {}),
		},
	};

	const confirmed = {
		invoke: {
			id: 'updateData',
			src: 'updateData',
		},
		on: {
			// This will return us to the proper state when the UPDATE_DATA event is sent
			'': [
				{
					target: 'approved',
					cond: 'isNotConfirmed',
				},
			],
			UNDO_CONFIRMATION,
			UPDATE_DATA,
			...(events?.confirmed ?? {}),
		},
	};

	const confirming = {
		invoke: {
			id: 'confirmingMealOption',
			src: 'confirm',
			onDone: {
				target: 'confirmed',
			},
			onError: {
				target: 'approved',
			},
		},
	};

	const disapproved = {
		invoke: {
			id: 'updateData',
			src: 'updateData',
		},
		on: {
			// This will return us to the proper state when the UPDATE_DATA event is sent
			'': [
				{
					target: 'confirmed',
					cond: 'isConfirmed',
				},
				{
					target: 'approved',
					cond: 'isApproved',
				},
			],
			APPROVE,
			DELETE,
			UPDATE_DATA,
			...(events?.disapproved ?? {}),
		},
	};

	const disapproving = {
		invoke: {
			id: 'disapprovingMealOption',
			src: 'disapprove',
			onDone: {
				target: 'disapproved',
			},
			onError: {
				target: 'approved',
			},
		},
	};

	const init = {
		on: {
			'': [
				{
					target: 'confirmed',
					cond: 'isConfirmed',
				},
				{
					target: 'approved',
					cond: 'isApproved',
				},
				{
					target: 'disapproved',
				},
			],
		},
	};

	const loading = {
		invoke: {
			id: 'updateData',
			src: 'updateData',
		},
		on: {
			UPDATE_DATA: {
				...UPDATE_DATA,
				target: 'init',
			},
		},
	};

	const undoing_confirmation = {
		invoke: {
			id: 'undoingConfirmationForMealOption',
			src: 'undoConfirmation',
			onDone: {
				target: 'approved',
			},
			onError: {
				target: 'confirmed',
			},
		},
	};

	const states = {
		approving,
		approved,
		confirmed,
		confirming,
		disapproved,
		disapproving,
		init,
		loading,
		undoing_confirmation,
	};

	return Machine(
		{
			id,
			initial: 'loading',
			states,
		},
		{
			guards: {
				isApproved,
				isConfirmed,
				isNotApproved,
				isNotConfirmed,
				...guards,
			},
		}
	);
};
