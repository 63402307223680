import Edit from 'pages/purchase-orders/Edit';
import PayoutAdjustmentCreate from 'pages/purchase-orders/PayoutAdjustmentCreate';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'purchaseOrdersEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['purchase-orders:edit'],
		exact: true,
	},
	{
		name: 'purchaseOrderPayoutAdjustmentsCreate',
		path: '/:id/purchase-order-payout-adjustments/create',
		component: PayoutAdjustmentCreate,
		permissions: [
			'purchase-orders:edit',
			'purchase-order-payout-adjustments:create',
		],
		exact: true,
	},
];

export default prependUrlPath('purchase-orders', routes);
