import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Timeline, ViewStream } from '@material-ui/icons';
import { Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { getRoute } from '@sporkbytes/delivery-scheduler';

import CollapsedMealProposal from 'pages/dashboard/CollapsedMealProposal';
import LateByText from 'pages/dashboard/LateByText';
import ScheduleTimeline from 'pages/dashboard/ScheduleTimeline';

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(4),
		[theme.breakpoints.up('sm')]: {
			flex: '0 0 auto',
			width: 450,
			'& + &': {
				borderLeft: `1px solid ${theme.palette.lightGray}`,
			},
		},
	},
}));

const DriverSchedule = ({ distances, header, mealProposals }) => {
	const classes = useStyles();
	const [showTimeline, setShowTimeline] = useState(false);

	const route = getRoute(mealProposals, distances);

	return (
		<div className={classes.container}>
			<Grid container alignContent="center">
				<Grid item xs={10}>
					<div>{header}</div>
				</Grid>
				<Grid item xs={2}>
					<Tooltip
						title={showTimeline ? 'View meals' : 'View timeline'}
					>
						<IconButton
							onClick={() =>
								setShowTimeline(showTimeline => !showTimeline)
							}
						>
							{showTimeline ? <ViewStream /> : <Timeline />}
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>

			{showTimeline ? (
				<ScheduleTimeline
					mealProposals={mealProposals}
					distances={distances}
				/>
			) : (
				mealProposals.map(mealProposal => (
					<CollapsedMealProposal
						key={mealProposal.id}
						mealProposal={mealProposal}
						footer={
							<LateByText
								lateBy={
									route.find(
										({ type, mealProposal: { id } }) =>
											type === 'ClientLocation' &&
											id === mealProposal.id
									).lateBy
								}
							/>
						}
					/>
				))
			)}
		</div>
	);
};

DriverSchedule.fragments = {
	details: gql`
		fragment DriverSchedule on MealProposals {
			...ScheduleTimeline
			...CollapsedMealProposal
		}
		${CollapsedMealProposal.fragments.details}
		${ScheduleTimeline.fragments.details}
	`,
};

DriverSchedule.propTypes = {
	distances: PropTypes.object.isRequired,
	header: PropTypes.element.isRequired,
	mealProposals: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DriverSchedule;
