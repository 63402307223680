import Create from 'pages/users/Create';
import Edit from 'pages/users/Edit';
import Users from 'pages/users/Users';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'users',
		path: '',
		component: Users,
		permissions: ['users:view'],
		exact: true,
	},
	{
		name: 'usersCreate',
		path: '/create',
		component: Create,
		permissions: ['users:create'],
		exact: true,
	},
	{
		name: 'usersEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['users:edit'],
		exact: true,
	},
];

export default prependUrlPath('users', routes);
