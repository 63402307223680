import React from 'react';

import { useStore } from 'models/store';

const GlobalDialog = () => {
	const { Dialog, dialogOpen, onDialogClose } = useStore();

	return Dialog ? <Dialog open={dialogOpen} onClose={onDialogClose} /> : null;
};

export default GlobalDialog;
