import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { LinkedAsyncAutocomplete } from '@sporkbytes/material-ui-kit-react';

import NoContentText from 'components/content/NoContentText';

import apolloClient from 'services/apollo';
import { getMultiWordSearchWhereClause } from 'services/search';
import { removeHtml } from 'services/regexp';

const getPartnerMenuItemSearchQuery = (where, searchTerm) => gql`
	query PartnerMenuItemSearchQuery {
		PartnerMenuItems(
			limit: 20
			order_by: { name: asc }
			where: { 
				${where}
				_and: [${getMultiWordSearchWhereClause(searchTerm)}]
			}
		) {
			id
			name
			description
		}
	}
`;

const PartnerMenuItemSearch = ({ name, where, ...props }) => {
	return (
		<LinkedAsyncAutocomplete
			getSearchResults={async searchTerm => {
				const {
					data: { PartnerMenuItems },
				} = await apolloClient.query({
					query: getPartnerMenuItemSearchQuery(where, searchTerm),
				});

				return PartnerMenuItems;
			}}
			getOptionLabel={option => option.name}
			getOptionSecondaryText={({ description }) =>
				description ? (
					removeHtml(description)
				) : (
					<NoContentText component="span">
						no description
					</NoContentText>
				)
			}
			name={name}
			{...props}
		/>
	);
};

PartnerMenuItemSearch.propTypes = {
	name: PropTypes.string,
	where: PropTypes.string,
};

export default PartnerMenuItemSearch;
