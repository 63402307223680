import React from 'react';
import gql from 'graphql-tag';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import {
	getFeeAfterDiscount,
	getFeeBeforeDiscount,
} from '@sporkbytes/meal-option-utils';

import InlineContent from 'components/content/InlineContent';
import TableCellNumeric from 'components/content/TableCellNumeric';

const MealProposalFeeLineItem = ({ mealOption, fee }) => {
	const { headCount } = mealOption.MealProposal;

	return (
		<TableRow>
			<TableCell>
				{fee.description && (
					<InlineContent>
						<Tooltip title={fee.description}>
							<Info />
						</Tooltip>
					</InlineContent>
				)}
				<span>{fee.name}</span>
			</TableCell>
			<TableCellNumeric
				format="currency"
				value={getFeeBeforeDiscount(fee, mealOption, headCount)}
			/>
			<TableCellNumeric format="percent" value={fee.discount} />
			<TableCellNumeric
				format="currency"
				value={getFeeAfterDiscount(fee, mealOption, headCount)}
			/>
		</TableRow>
	);
};

MealProposalFeeLineItem.fragments = {
	details: gql`
		fragment MealProposalFeeLineItemDetails on MealOptions {
			MealProposal {
				headCount
				MealProposalFees {
					id
					name
					description
					amount
					discount
					type
				}
			}
			PurchaseOrders(order_by: { pickupTime: asc }) {
				PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
					PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
						costToClient
						discount
						paidToPartner
						quantity
					}
				}
			}
		}
	`,
};

export default MealProposalFeeLineItem;
