import React from 'react';
import {
	Form,
	GridContainer,
	LinkedTextInput,
	LinkedSwitch,
} from '@sporkbytes/material-ui-kit-react';
import { post } from '@sporkbytes/api-client/SporkReviewQuestions';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import * as yup from 'models/validation';

const validationSchema = yup.object().shape({
	question: yup.string().max(250).required('Please enter a question.'),
	required: yup.boolean(),
});

const Create = () => {
	return (
		<Page title="Add New Spork Review Question">
			<PageHeader headerText="Add New Spork Review Question" />
			<FormWrapper
				form={props => (
					<Form {...props} validationSchema={validationSchema}>
						{() => (
							<GridContainer columns={1}>
								<LinkedTextInput name="question" autoFocus />
								<LinkedSwitch
									name="required"
									uncheckedHelperText="No"
									checkedHelperText="Yes"
								/>
							</GridContainer>
						)}
					</Form>
				)}
				onSubmit={post}
			/>
		</Page>
	);
};

export default Create;
