import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { getContact } from '@sporkbytes/api-client/Accounting';
import { put } from '@sporkbytes/api-client/PartnerLocations';

import FormWrapper from 'components/forms/FormWrapper';
import LoadingStateContainer from 'components/utilities/LoadingStateContainer';
import PartnerLocationForm from 'components/partner-locations/PartnerLocationForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

import useRequest from 'hooks/useRequest';

import { useAuth } from 'services/auth';
import axios from 'services/data';

// TODO: use fragments here
const PARTNER_LOCATION_QUERY = gql`
	query PartnerLocationEdit($id: uuid!) {
		PartnerLocations_by_pk(id: $id) {
			AccountingContactId
			automatedEmailConfirmation
			city
			friendlyName
			howToOrder
			maximumOrderAmount
			maximumOrderHeadCount
			minimumLeadTimeAmount
			minimumLeadTimeScale
			minimumOrderAmount
			minimumOrderHeadCount
			name
			phoneNumber
			pickupInstructions
			preferredPickupTimes
			state
			streetAddress
			streetAddress2
			timeToEnter
			timeToExit
			timeToPark
			zipCode
			Partner {
				name
				logoUrl
			}
		}
	}
`;

const PageContent = ({ data }) => {
	const { id } = useParams();
	const { userCannot } = useAuth();
	const {
		PartnerLocations_by_pk: { Partner, friendlyName, ...partnerLocation },
	} = data;
	const { data: defaultAccountingContact, loading } = useRequest(() =>
		getContact(axios, partnerLocation.AccountingContactId)
	);

	return (
		<LoadingStateContainer loading={loading}>
			<PageHeader imgUrl={Partner.logoUrl} headerText={Partner.name} />
			<PageSubheader>{friendlyName}</PageSubheader>
			<FormWrapper
				form={PartnerLocationForm}
				initialValues={partnerLocation}
				onSubmit={(axios, values) => put(axios, id, values)}
				addressSearchDisabled={userCannot([
					'partner-locations:edit-address',
				])}
				defaultAccountingContact={defaultAccountingContact}
			/>
		</LoadingStateContainer>
	);
};

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ PartnerLocations_by_pk: { friendlyName } }) =>
				`Edit ${friendlyName}`
			}
			query={PARTNER_LOCATION_QUERY}
			variables={{ id }}
		>
			{({ data }) => <PageContent data={data} />}
		</Page>
	);
};

export default Edit;
