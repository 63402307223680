import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/DeliveryGear';

import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import DeliveryGearActionMenu from 'components/delivery-gear/DeliveryGearActionMenu';
import RemoteTable from 'components/content/RemoteTable';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';

const getDeliveryGearQuery = searchTerm => gql`
	query DeliveryGear(
		$DeliveryGearTypeId: uuid
		$activeOrderBy: order_by
		$nameOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		DeliveryGear(
			where: {
				DeliveryGearTypeId: { _eq: $DeliveryGearTypeId }
				name: { _ilike: "%${searchTerm}%" }
				SporkLocation: {
					active: { _eq: true }
				}
			}
			order_by: { active: $activeOrderBy, name: $nameOrderBy }
			limit: $limit
			offset: $offset
		) {
			id
			active
			name
			abbreviatedName
			SporkLocation {
				name
			}
		}
		DeliveryGear_aggregate(
			where: {
				DeliveryGearTypeId: { _eq: $DeliveryGearTypeId }
				name: { _ilike: "%${searchTerm}%" }
				SporkLocation: {
					active: { _eq: true }
				}
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const DeliveryGearTable = ({ DeliveryGearTypeId, ...props }) => {
	const ref = useRef();
	const { userCan } = useAuth();
	const { hasMoreThanOneSporkLocation } = useStore();

	return (
		<RemoteTable
			{...props}
			tableRef={ref}
			columns={[
				{
					title: 'Name',
					field: 'name',
				},
				{
					title: 'Abbreviated Name',
					field: 'abbreviatedName',
				},
				{
					title: 'Active',
					field: 'active',
					render: ({ active, id }) => (
						<BooleanFieldSwitch
							afterChange={ref.current.onQueryChange}
							entityId={id}
							entityType="deliveryGear"
							updateEntity={put}
							value={active}
						/>
					),
				},
				{
					title: 'Spork Location',
					field: 'SporkLocation.name',
					sorting: false,
					hidden: !hasMoreThanOneSporkLocation(),
				},
				{
					title: 'Actions',
					field: 'actions',
					sorting: false,
					render: deliveryGear => (
						<DeliveryGearActionMenu
							deliveryGear={deliveryGear}
							onActionComplete={ref.current.onQueryChange}
						/>
					),
				},
			]}
			defaultOrderBy="name"
			defaultOrderDirection="asc"
			getQuery={getDeliveryGearQuery}
			queryEntity="DeliveryGear"
			variables={{ DeliveryGearTypeId }}
			addNewEntityRouteName="deliveryGearCreate"
			addNewEntityRouteVariables={{
				id: DeliveryGearTypeId,
			}}
			entityToAddName="Delivery Gear"
			canAddNewEntity={userCan([
				'delivery-gear-types:edit',
				'delivery-gear:create',
			])}
		/>
	);
};

DeliveryGearTable.propTypes = {
	DeliveryGearTypeId: PropTypes.string.isRequired,
};

export default DeliveryGearTable;
