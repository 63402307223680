import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { endOfDay, format, parseISO, startOfDay } from 'date-fns';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import {
	DateRangeSelector,
	GridContainer,
	MultiSelect,
	PrimaryButton,
	SingleSelect,
} from '@sporkbytes/material-ui-kit-react';
import { getDateRangeForPreset } from '@sporkbytes/time-utils';

import LoadingStateContainer from 'components/utilities/LoadingStateContainer';
import ClientSearch from 'components/clients/ClientSearch';
import ExpansionPanel from 'components/content/ExpansionPanel';
import PartnerSearch from 'components/partners/PartnerSearch';

import { useStore } from 'models/store';

import { STATUS_OPTIONS } from 'services/mealProposal';

const UNAPPROVEDBY_OPTIONS = ['Partner', 'Client', 'Manager'];

const clientLocationQueryFields = `
	ClientLocations(order_by: { friendlyName: asc }) {
		id
		friendlyName
	}
`;

const getFilterDataQuery = ({
	ClientId,
	PartnerId,
	showCancellationReason,
}) => {
	const clientQuery = ClientId
		? `Clients_by_pk(id: "${ClientId}") { 
			name
			${clientLocationQueryFields}	
		 }`
		: '';

	const partnerQuery = PartnerId
		? `Partners_by_pk(id: "${PartnerId}") { name }`
		: '';

	const cancellationReasonQuery = showCancellationReason
		? `CancellationReasons(order_by: { sortOrder: asc }) {
				id
				name
			}`
		: '';

	return gql`
		query MealProposalFilters {
			MealCategories(
				where: { active: { _eq: true } }
				order_by: { sortOrder: asc }
			) {
				id
				name
			}
			Users(
				where: { active: { _eq: true } }
				order_by: { fullName: asc }
			) {
				emailAddress
				fullName
				id
			}
			${cancellationReasonQuery}
			${clientQuery}
			${partnerQuery}
		}
	`;
};

const transformQueryString = (
	{ dateRangePreset, beginDate, endDate, ...queryString },
	{ defaultPreset = 'upcomingWeek', defaultStatus } = {}
) => {
	let dateRange;

	if (dateRangePreset) {
		dateRange = getDateRangeForPreset(dateRangePreset);
	} else if (beginDate || endDate) {
		dateRange = {
			beginDate: beginDate ? startOfDay(parseISO(beginDate)) : undefined,
			endDate: endDate ? endOfDay(parseISO(endDate)) : undefined,
		};
	} else {
		dateRange = getDateRangeForPreset(defaultPreset);
	}

	let filteringBy = queryString?.filteringBy ?? 'deliveryDate';
	let orderBy = queryString?.orderBy ?? filteringBy;

	return {
		...queryString,
		...dateRange,
		filteringBy,
		groupBy: queryString?.groupBy ?? 'week',
		MealCategoryIds: queryString?.MealCategoryIds?.split(','),
		orderBy,
		orderDirection: queryString?.orderDirection ?? 'asc',
		page: queryString.page >= 1 ? parseInt(queryString.page) - 1 : 0, // page will be 1-indexed in the query string but 0-indexed in code
		pageSize: parseInt(queryString.pageSize) || 10,
		searchTerm: queryString.searchTerm ?? '',
		status: queryString?.status?.split(',') ?? defaultStatus,
	};
};

const useStyles = makeStyles(theme => ({
	expansionPanelContent: {
		display: 'block',
	},
	expansionPanel: {
		margin: theme.spacing(2, 0),
	},
}));

const MealProposalFilters = ({
	children,
	onChange,
	showCancellationReason,
	value: {
		beginDate,
		CancellationReasonId,
		ClientId,
		ClientLocationId,
		createdBy,
		endDate,
		MealCategoryIds,
		PartnerId,
		SporkContactId,
		status,
		unapprovedBy,
	},
}) => {
	const {
		activeSporkLocations,
		filterBySporkLocations,
		hasMoreThanOneSporkLocation,
		setFilterBySporkLocations,
	} = useStore();
	const classes = useStyles();
	const [clientLocations, setClientLocations] = useState();

	// We need to keep track of how many times the user hits reset so we can reset the internal state of ClientSearch and PartnerSearch components
	const [resetCount, setResetCount] = useState(0);
	// the FILTER_DATA_QUERY should only be run on page load
	const [FILTER_DATA_QUERY] = useState(() =>
		getFilterDataQuery({
			ClientId,
			PartnerId,
			showCancellationReason,
		})
	);

	const { data, loading } = useQuery(FILTER_DATA_QUERY);

	const {
		CancellationReasons,
		MealCategories,
		Users,
		Clients_by_pk: defaultClient,
		Partners_by_pk: defaultPartner,
	} = data || {};

	return (
		<LoadingStateContainer loading={loading}>
			<DateRangeSelector
				defaultDateRange={{
					beginDate,
					endDate,
				}}
				onChange={({ preset, beginDate, endDate }) => {
					if (preset) {
						onChange({
							dateRangePreset: preset,
							beginDate: undefined,
							endDate: undefined,
						});
					} else {
						onChange({
							beginDate: beginDate
								? format(beginDate, 'yyyy-MM-dd')
								: undefined,
							endDate: endDate
								? format(endDate, 'yyyy-MM-dd')
								: undefined,
							dateRangePreset: undefined,
						});
					}
				}}
			>
				{children({ onChange })}
			</DateRangeSelector>
			<ExpansionPanel
				title="Advanced Filters"
				titleProps={{ variant: 'h6', component: 'h1' }}
				contentProps={{
					className: classes.expansionPanelContent,
				}}
				className={classes.expansionPanel}
			>
				<GridContainer columns={2}>
					{hasMoreThanOneSporkLocation() && (
						<MultiSelect
							label="Spork Locations"
							options={activeSporkLocations}
							getOptionKey={({ id }) => id}
							getOptionLabel={({ name }) => name}
							getOptionValue={({ id }) => id}
							onChange={SporkLocationIds => {
								setFilterBySporkLocations(SporkLocationIds);

								// trigger a re-run of the query
								// we don't sync SporkLocationIds to the query string, but to localStorage
								onChange({});
							}}
							value={filterBySporkLocations}
						/>
					)}
					<ClientSearch
						key={resetCount}
						placeholder="Client"
						onChange={client => {
							onChange({
								ClientId: client.id,
								ClientLocationId: undefined,
							});
							setClientLocations(client?.ClientLocations);
						}}
						defaultValue={
							resetCount === 0 ? defaultClient : undefined
						}
						additionalFields={clientLocationQueryFields}
					/>
					<SingleSelect
						label="Client Location"
						options={
							clientLocations ||
							defaultClient?.ClientLocations ||
							[]
						}
						emptyOptionLabel="None"
						value={ClientLocationId}
						onChange={ClientLocationId =>
							onChange({ ClientLocationId })
						}
						getOptionKey={option => option.id}
						getOptionValue={option => option.id}
						getOptionLabel={option => option.friendlyName}
						disabled={!ClientId}
					/>
					<PartnerSearch
						key={resetCount}
						placeholder="Partner"
						defaultValue={
							resetCount === 0 ? defaultPartner : undefined
						}
						onChange={partner =>
							onChange({ PartnerId: partner.id })
						}
					/>
					<MultiSelect
						label="Status"
						options={STATUS_OPTIONS}
						value={status}
						onChange={status =>
							onChange({
								status:
									status?.length > 0
										? status.join(',')
										: undefined,
							})
						}
					/>
					<MultiSelect
						label="Categories"
						getOptionKey={option => option.id}
						getOptionLabel={option => option.name}
						getOptionValue={option => option.id}
						options={MealCategories}
						value={MealCategoryIds}
						onChange={MealCategoryIds =>
							onChange({
								MealCategoryIds:
									MealCategoryIds?.length > 0
										? MealCategoryIds.join(',')
										: undefined,
							})
						}
					/>
					{showCancellationReason && (
						<SingleSelect
							label="Cancellation Reason"
							options={CancellationReasons}
							emptyOptionLabel="None"
							value={CancellationReasonId}
							onChange={CancellationReasonId =>
								onChange({ CancellationReasonId })
							}
							getOptionKey={option => option.id}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.id}
						/>
					)}
					<SingleSelect
						label="Created By"
						options={Users}
						emptyOptionLabel="None"
						value={createdBy}
						onChange={createdBy => onChange({ createdBy })}
						getOptionKey={option => option.emailAddress}
						getOptionLabel={option => option.fullName}
						getOptionValue={option => option.emailAddress}
					/>
					<SingleSelect
						label="Spork Contact"
						options={Users}
						emptyOptionLabel="None"
						value={SporkContactId}
						onChange={SporkContactId =>
							onChange({ SporkContactId })
						}
						getOptionKey={option => option.id}
						getOptionLabel={option => option.fullName}
						getOptionValue={option => option.id}
					/>
					<SingleSelect
						label="Unapproved By"
						options={UNAPPROVEDBY_OPTIONS}
						emptyOptionLabel="None"
						value={unapprovedBy}
						onChange={unapprovedBy => onChange({ unapprovedBy })}
					/>
				</GridContainer>
				<PrimaryButton
					onClick={() => {
						onChange({
							CancellationReasonId: undefined,
							ClientId: undefined,
							ClientLocationId: undefined,
							createdBy: undefined,
							PartnerId: undefined,
							SporkContactId: undefined,
							status: undefined,
							unapprovedB: undefined,
						});
						setResetCount(resetCount => resetCount + 1);
					}}
				>
					Reset Filters
				</PrimaryButton>
			</ExpansionPanel>
		</LoadingStateContainer>
	);
};

MealProposalFilters.propTypes = {
	onChange: PropTypes.func.isRequired,
	showCancellationReason: PropTypes.bool,
	value: PropTypes.shape({
		beginDate: PropTypes.instanceOf(Date),
		CancellationReasonId: PropTypes.string,
		ClientId: PropTypes.string,
		ClientLocationId: PropTypes.string,
		createdBy: PropTypes.string,
		endDate: PropTypes.instanceOf(Date),
		PartnerId: PropTypes.string,
		SporkContactId: PropTypes.string,
		status: PropTypes.arrayOf(PropTypes.string),
		unapprovedBy: PropTypes.string,
	}).isRequired,
};

MealProposalFilters.defaultProps = {
	showCancellationReason: true,
};

export default MealProposalFilters;
export { transformQueryString };
