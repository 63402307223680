import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { isPortrait, orientationOptions } from 'services/printing';

const useStyles = makeStyles(() => ({
	container: {
		margin: '0 auto',
		padding: ({ padding }) => padding,
		width: ({ orientation }) =>
			isPortrait(orientation) ? '8.5in' : '11in',
		'@media print': {
			height: ({ orientation }) =>
				isPortrait(orientation) ? '11in' : '8.5in',
		},
	},
}));

const PrintablePage = ({ children, orientation, padding }) => {
	const classes = useStyles({ orientation, padding });

	return <div className={classes.container}>{children}</div>;
};

PrintablePage.propTypes = {
	orientation: PropTypes.oneOf(Object.values(orientationOptions)),
	padding: PropTypes.number,
};

PrintablePage.defaultProps = {
	orientation: orientationOptions.PORTRAIT,
	padding: 0,
};

export default PrintablePage;
