import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Typography } from '@material-ui/core';
import { getPurchaseOrders } from '@sporkbytes/api-client/PublicPartners';
import { Currency, DateRangeSelector } from '@sporkbytes/material-ui-kit-react';
import { totalReceivedByPartner } from '@sporkbytes/purchase-order-utils';

import LoadingPage from 'components/content/LoadingPage';
import PurchaseOrdersTable from 'components/purchase-orders/PurchaseOrdersTable';

import useRequest from 'hooks/useRequest';

import { formatDateRangeForQueryString } from 'services/dates';
import axios from 'services/data';

const DEFAULT_DATE_RANGE = {
	beginDate: new Date(),
};

const HIDDEN_PRESETS = ['allTime', 'allPast', 'quarters', 'years'];

const PurchaseOrders = () => {
	const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
	const { publicProfileId } = useParams();
	const { data, loading } = useRequest(
		() =>
			getPurchaseOrders(
				axios,
				publicProfileId,
				formatDateRangeForQueryString(dateRange, 'pickupTime')
			),
		[dateRange]
	);

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<Card>
			<CardContent>
				<DateRangeSelector
					defaultDateRange={dateRange}
					hiddenPresets={HIDDEN_PRESETS}
					onChange={setDateRange}
				/>
				<PurchaseOrdersTable
					hiddenColumns={['PartnerLocation.Partner.name']}
					purchaseOrders={data}
					title={
						<Typography>
							Payout Total -{' '}
							<Currency value={totalReceivedByPartner(data)} />
						</Typography>
					}
					options={{
						showTitle: true,
					}}
				/>
			</CardContent>
		</Card>
	);
};

export default PurchaseOrders;
