import React from 'react';
import gql from 'graphql-tag';
import { TableCell, TableRow } from '@material-ui/core';
import { foodSubtotal } from '@sporkbytes/meal-option-utils';

import TableCellNumeric from 'components/content/TableCellNumeric';

const MealOptionFoodSubtotalLineItem = ({ mealOption }) => (
	<TableRow>
		<TableCell colSpan="3">Food Subtotal</TableCell>
		<TableCellNumeric format="currency" value={foodSubtotal(mealOption)} />
	</TableRow>
);

MealOptionFoodSubtotalLineItem.fragments = {
	details: gql`
		fragment MealOptionFoodSubtotalLineItemDetails on MealOptions {
			PurchaseOrders(order_by: { pickupTime: asc }) {
				PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
					PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
						costToClient
						discount
						quantity
					}
				}
			}
		}
	`,
};

export default MealOptionFoodSubtotalLineItem;
