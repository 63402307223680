import React from 'react';
import {
	CameraAlt,
	Cancel,
	Category,
	Check,
	ContactMail,
	Create,
	Description,
	DirectionsCar,
	FileCopy,
	LocalPizza,
	Mail,
	MonetizationOn,
	Print,
	ShoppingBasket,
} from '@material-ui/icons';

export default ({ state, userCan }) => ({
	ASSIGN_DELIVERY_DRIVERS: {
		canPerformAction: userCan([
			'meal-proposals-delivery-drivers:create',
			'meal-proposals-delivery-drivers:delete',
		]),
		icon: <DirectionsCar />,
	},
	ASSIGN_DELIVERY_GEAR: {
		canPerformAction: userCan([
			'meal-proposals-delivery-gear:create',
			'meal-proposals-delivery-gear:delete',
		]),
		icon: <ShoppingBasket />,
	},
	ASSIGN_MEAL_CATEGORIES: {
		canPerformAction: userCan([
			'meal-proposals-meal-categories:create',
			'meal-proposals-meal-categories:delete',
		]),
		divider: true,
		icon: <Category />,
	},
	CANCEL: {
		canPerformAction: userCan(['meal-proposals:cancel']),
		icon: <Cancel />,
		type: 'error',
	},
	CONVERT_TO_DRAFT: {
		canPerformAction: userCan(['meal-proposals:edit']),
		icon: <Description />,
		type: 'warning',
	},
	COPY_CLIENT_LINK: {
		icon: <FileCopy />,
	},
	COPY_PO_LINK: {
		icon: <FileCopy />,
		label: 'Copy PO Link',
	},
	DELIVER: {
		icon: <DirectionsCar />,
		label: 'Mark as Delivered',
	},
	DUPLICATE: {
		canPerformAction: userCan(['meal-proposals:duplicate']),
		icon: <FileCopy />,
	},
	EDIT: {
		canPerformAction: userCan(['meal-proposals:edit']),
		icon: <Create />,
		label: `Edit ${state.context?.orderNumberText}`,
	},
	EDIT_PURCHASE_ORDER: {
		icon: <Create />,
		label: `Edit PO`,
	},
	MANAGE_MEAL_OPTIONS: {
		canPerformAction: userCan(['meal-proposals:manage-meal-options']),
		icon: <LocalPizza />,
	},
	MANAGER_APPROVE: {
		canPerformAction: userCan(['meal-proposals:approve']),
		icon: <Check />,
		type: 'success',
	},
	MANAGER_DISAPPROVE: {
		canPerformAction: userCan(['meal-proposals:disapprove']),
		icon: <Cancel />,
		type: 'error',
	},
	OPEN_HUB_SPOT_DEAL: {
		canPerformAction: userCan(['meal-proposal-hub-spot-syncs:view']),
		icon: <MonetizationOn />,
	},
	PRINT_LABELS: {
		canPerformAction: userCan([
			'meal-proposals:view',
			'partner-menu-items:view',
		]),
		icon: <Print />,
	},
	REASSIGN_SPORK_CONTACT: {
		canPerformAction: userCan(['meal-proposals:edit']),
		icon: <ContactMail />,
		divider: true,
	},
	REQUEST_CLIENT_APPROVAL: {
		canPerformAction: userCan(['meal-proposals:request-client-approval']),
		icon: <Mail />,
	},
	REQUEST_CLIENT_CONFIRMATION: {
		canPerformAction: userCan([
			'meal-proposals:request-client-confirmation',
		]),
		icon: <Mail />,
	},
	REQUEST_PARTNER_APPROVAL: {
		canPerformAction: userCan(['purchase-orders:request-approval']),
		icon: <Mail />,
	},
	REQUEST_PARTNER_CONFIRMATION: {
		canPerformAction: userCan(['purchase-orders:request-confirmation']),
		icon: <Mail />,
	},
	SEND_CLIENT_CANCELLATION_NOTICE: {
		canPerformAction: userCan([
			'meal-proposals:send-client-cancellation-notice',
		]),
		icon: <Mail />,
	},
	SEND_PARTNER_CANCELLATION_NOTICE: {
		canPerformAction: userCan([
			'purchase-orders:send-partner-cancellation-notice',
		]),
		icon: <Mail />,
	},
	SEND_CORRECTION: {
		canPerformAction: userCan(['meal-proposals:send-correction']),
		icon: <Mail />,
		label: `Send Correction to ${state.context?.SporkContact?.fullName}`,
	},
	SUBMIT: {
		canPerformAction: userCan(['meal-proposals:edit']),
		icon: <Check />,
		type: 'success',
	},
	VIEW_PHOTO: {
		icon: <CameraAlt />,
	},
});
