import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Typography } from '@material-ui/core';

import ApprovedConfirmedStatus from 'components/content/ApprovedConfirmedStatus';
import DateOutput from 'components/content/DateOutput';

const PurchaseOrderSummaryHeader = ({
	dateOutputProps,
	purchaseOrder,
	typographyProps,
}) => {
	return (
		<ApprovedConfirmedStatus
			approved={purchaseOrder.approved}
			confirmed={purchaseOrder.confirmed}
			text={
				<Typography {...typographyProps}>
					<span>
						{purchaseOrder.PartnerLocation.Partner.name} -{' '}
						<DateOutput
							date={purchaseOrder.pickupTime}
							timezone={purchaseOrder.PartnerLocation.timezone}
							formatter="time"
							component="span"
							{...dateOutputProps}
						/>{' '}
						({purchaseOrder.PartnerLocation.streetAddress})
					</span>
				</Typography>
			}
		/>
	);
};

PurchaseOrderSummaryHeader.propTypes = {
	dateOutputProps: PropTypes.object,
	typographyProps: PropTypes.object,
};

PurchaseOrderSummaryHeader.defaultProps = {
	dateOutputProps: {},
	typographyProps: {},
};

PurchaseOrderSummaryHeader.fragments = {
	details: gql`
		fragment PurchaseOrderSummaryHeaderDetails on PurchaseOrders {
			approved
			confirmed
			pickupTime
			PartnerLocation {
				streetAddress
				timezone
				Partner {
					name
				}
			}
		}
	`,
};

export default PurchaseOrderSummaryHeader;
