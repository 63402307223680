import {
	canApprove,
	canConfirm,
	canDelete,
	canDisapprove,
	canUndoConfirmation,
} from '@sporkbytes/meal-option-utils';

import createApprovalMachine from './createApprovalMachine';

export default createApprovalMachine('mealOption', {
	guards: {
		canApprove,
		canConfirm,
		canDelete,
		canDisapprove,
		canUndoConfirmation,
	},
});
