import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { FileCopy, Print } from '@material-ui/icons';

import ActionMenu from 'components/utilities/ActionMenu';

import {
	createEditAction,
	createNavigateToRouteAction,
} from 'services/actionMenu';
import { useAuth } from 'services/auth';

const PartnerMenuItemActionMenu = ({
	additionalActions,
	partnerMenuItem: { active, id, name, producesLabel },
}) => {
	const [loading, setLoading] = useState(false);
	const { userCan } = useAuth();
	const entityType = 'PartnerMenuItems';

	return (
		<ActionMenu
			loading={loading}
			options={[
				createEditAction({
					entityId: id,
					entityName: name,
					entityType,
					userCan,
				}),
				createNavigateToRouteAction({
					canPerformAction: userCan(['partner-menu-items:create']),
					routeName: 'partnerMenuItemsDuplicate',
					routeVariables: {
						id,
					},
					label: 'Duplicate',
					icon: <FileCopy />,
				}),
				createNavigateToRouteAction({
					canPerformAction: active && producesLabel,
					routeName: 'partnerMenuItemsPrintableLabel',
					routeVariables: {
						id,
					},
					label: 'Print Label',
					icon: <Print />,
				}),
				...additionalActions({ setLoading }),
			]}
		/>
	);
};

PartnerMenuItemActionMenu.propTypes = {
	additionalActions: PropTypes.func,
};

PartnerMenuItemActionMenu.defaultProps = {
	additionalActions: () => [],
};

PartnerMenuItemActionMenu.fragments = {
	details: gql`
		fragment PartnerMenuItemActionMenuDetails on PartnerMenuItems {
			active
			id
			name
			producesLabel
		}
	`,
};

export default PartnerMenuItemActionMenu;
