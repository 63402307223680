import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Grid, makeStyles } from '@material-ui/core';

import DeliveryDriverAssignedBar from 'pages/dashboard/DeliveryDriverAssignedBar';
import DetailPageLink from 'components/routing/DetailPageLink';
import DropOffAvatar from 'pages/dashboard/DropOffAvatar';
import HeadCount from 'components/content/HeadCount';
import PickupAvatar from 'pages/dashboard/PickupAvatar';

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(0, 2),
	},
}));

const CollapsedMealProposal = ({ mealProposal, footer }) => {
	const classes = useStyles();

	return (
		<>
			<Grid container alignItems="center" className={classes.container}>
				<Grid container justify="flex-start" item xs={3}>
					<DetailPageLink
						entityType="MealProposals"
						id={mealProposal.id}
						text={mealProposal.orderNumberText}
						target="_blank"
					/>
				</Grid>
				<Grid container justify="center" item xs={3}>
					<PickupAvatar mealProposal={mealProposal} />
				</Grid>
				<Grid container justify="center" item xs={3}>
					<DropOffAvatar mealProposal={mealProposal} />
				</Grid>
				<Grid container justify="flex-end" item xs={3}>
					<HeadCount value={mealProposal.headCount} noWrap />
				</Grid>
			</Grid>
			{footer}
			<DeliveryDriverAssignedBar mealProposal={mealProposal} />
		</>
	);
};

CollapsedMealProposal.fragments = {
	details: gql`
		fragment CollapsedMealProposal on MealProposals {
			id
			orderNumberText
			deliveryDate
			headCount
			ClientLocation {
				timezone
				friendlyName
				Client {
					name
					logoUrl
				}
			}
			MealOptions(where: { approved: { _eq: true } }) {
				approved
				PurchaseOrders(order_by: { pickupTime: asc }) {
					id
					pickupTime
					PartnerLocation {
						timezone
						Partner {
							name
							logoUrl
						}
					}
				}
			}
			...DeliveryDriverAssignedBar
			...DropOffAvatar
			...PickupAvatar
		}
		${DeliveryDriverAssignedBar.fragments.details}
		${DropOffAvatar.fragments.details}
		${PickupAvatar.fragments.details}
	`,
};

CollapsedMealProposal.propTypes = {
	footer: PropTypes.element,
};

export default CollapsedMealProposal;
