import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { endOfMonth, isPast, parseISO } from 'date-fns';
import { Typography } from '@material-ui/core';
import { deleteFeaturedPartners } from '@sporkbytes/api-client/FeaturedPartners';
import { Avatar, GridContainer } from '@sporkbytes/material-ui-kit-react';

import ActionMenu from 'components/utilities/ActionMenu';
import DetailPageLink from 'components/routing/DetailPageLink';
import RemoteTable from 'components/content/RemoteTable';

import { useStore } from 'models/store';

import { createDeleteAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';

const getFeaturedPartnersQuery = () => gql`
	query FeaturedPartners(
		$sporkLocationOrderBy: order_by
		$month: date
		$limit: Int!
		$offset: Int!
	) {
		FeaturedPartners(
			where: {
				month: { _eq: $month }
				SporkLocation: { active: { _eq: true } }
			}
			order_by: {
				SporkLocation: { name: $sporkLocationOrderBy }
				Partner: { name: asc }
			}
			limit: $limit
			offset: $offset
		) {
			id
			Partner {
				id
				name
				logoUrl
			}
			SporkLocation {
				name
			}
		}
		FeaturedPartners_aggregate(
			where: {
				month: { _eq: $month }
				SporkLocation: { active: { _eq: true } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const FeaturedPartnersTable = ({ month }) => {
	const { userCan, userCannot } = useAuth();
	const isPastMonth = isPast(endOfMonth(parseISO(month)));
	const userCanCreateFeaturedPartners =
		userCan(['featured-partners:create']) && !isPastMonth;
	const hideDeleteButton =
		userCannot(['featured-partners:delete']) || isPastMonth;
	const ref = useRef();
	const { hasMoreThanOneSporkLocation } = useStore();

	return (
		<RemoteTable
			tableRef={ref}
			getQuery={getFeaturedPartnersQuery}
			queryEntity="FeaturedPartners"
			defaultOrderBy="sporkLocation"
			variables={{ month }}
			columns={[
				{
					title: 'Name',
					field: 'name',
					sorting: false,
					render: ({ Partner: { id, logoUrl, name } }) => (
						<DetailPageLink
							entityType="Partners"
							id={id}
							text={
								<GridContainer
									columns="none"
									alignItems="center"
								>
									<Avatar src={logoUrl} alt={name} />
									<Typography>{name}</Typography>
								</GridContainer>
							}
						/>
					),
				},
				{
					title: 'Spork Location',
					field: 'SporkLocation.name',
					sorting: false,
					hidden: !hasMoreThanOneSporkLocation(),
				},
				{
					title: 'Actions',
					sorting: false,
					hidden: hideDeleteButton,
					render: ({ id, Partner, SporkLocation }) => (
						<ActionMenu
							options={[
								createDeleteAction({
									afterDelete: ref.current.onQueryChange,
									deleteFunction: deleteFeaturedPartners,
									entityId: id,
									entityName: `${Partner.name} for ${SporkLocation.name} in ${month}`,
								}),
							]}
						/>
					),
				},
			]}
			options={{
				search: false,
			}}
			canAddNewEntity={userCanCreateFeaturedPartners}
			entityToAddName="Featured Partner"
			addNewEntityRouteName="featuredPartnersCreate"
			addNewEntityRouteVariables={{ month }}
		/>
	);
};

FeaturedPartnersTable.propTypes = {
	month: PropTypes.string.isRequired,
};

export default FeaturedPartnersTable;
