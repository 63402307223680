import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { Print, MenuBook, Warning } from '@material-ui/icons';
import { ConfirmationDialog } from '@sporkbytes/material-ui-kit-react';
import { changePublicProfileId } from '@sporkbytes/api-client/Partners';

import ActionMenu from 'components/utilities/ActionMenu';
import SendEmailDialogForm from 'components/email-templates/SendEmailDialogForm';

import { useStore } from 'models/store';

import {
	createCopyTextToClipboardAction,
	createNavigateToRouteAction,
} from 'services/actionMenu';
import { createEditAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';
import apolloClient from 'services/apollo';
import axios from 'services/data';

const CONTACTS_QUERY = gql`
	query PartnerActionMenu($id: uuid!) {
		Contacts(
			where: {
				PartnerLocationsContacts: {
					PartnerLocation: { PartnerId: { _eq: $id } }
				}
			}
		) {
			firstName
			email
		}
	}
`;
const PartnerActionMenu = ({
	additionalActions,
	partner: { active, id, landingPageSlug, name, publicProfileId },
}) => {
	const { userCan, user } = useAuth();
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const entityType = 'Partners';
	const { showDialog, showDialogs, showSuccessNotification } = useStore();

	return (
		<ActionMenu
			loading={loading}
			options={[
				createEditAction({
					entityId: id,
					entityName: name,
					entityType,
					userCan,
				}),
				createCopyTextToClipboardAction({
					canPerformAction: active,
					label: 'Copy Landing Page Link',
					textToCopy: `https://www.sporkbytes.com/${landingPageSlug}/`,
				}),
				createNavigateToRouteAction({
					canPerformAction: userCan(['partner-menu-items:view']),
					routeName: 'partnersPrintableLabels',
					routeVariables: {
						id,
					},
					label: 'Print Labels',
					icon: <Print />,
				}),
				{
					label: 'View Public Profile',
					icon: <MenuBook />,
					onClick: () =>
						history.openNamedRoute('publicPartnerProfile', {
							publicProfileId,
						}),
				},
				{
					canPerformAction: userCan([
						'partners:change-public-profile-id',
					]),
					label: 'Change Public Profile URL',
					type: 'warning',
					icon: <Warning />,
					onClick: () =>
						showDialog(ConfirmationDialog, {
							title: 'Are you sure you want to change the public profile URL?',
							text: 'This cannot be undone and all contacts should be notified of the change, but you can choose who will receive a notification.',
							onConfirmation: async () => {
								setLoading(true);

								const {
									data: { Contacts },
								} = await apolloClient.query({
									query: CONTACTS_QUERY,
									variables: { id },
								});

								setLoading(false);

								let emails = {
									contacts: [],
									content: [],
								};

								await showDialogs(
									Contacts.map(
										contact =>
											({ showNextDialog, ...props }) =>
												(
													<SendEmailDialogForm
														onSubmit={emailContent => {
															emails.contacts.push(
																{
																	name: contact.firstName,
																	email: contact.email,
																}
															);
															emails.content.push(
																emailContent
															);

															showNextDialog();
															return Promise.resolve();
														}}
														emailTemplateId="publicProfileChanged"
														substitutions={{
															'-recipientFirstNames-':
																contact.firstName,
															'-partnerName-':
																name,
															'-userFirstName-':
																user.name,
														}}
														{...props}
													/>
												)
									)
								);

								setLoading(true);
								await changePublicProfileId(axios, id, emails);
								setLoading(false);

								showSuccessNotification(
									'Emails successfully sent'
								);
							},
						}),
				},
				...additionalActions({ setLoading }),
			]}
		/>
	);
};

PartnerActionMenu.propTypes = {
	additionalActions: PropTypes.func,
	partner: PropTypes.object.isRequired,
};

PartnerActionMenu.defaultProps = {
	additionalActions: () => [],
};

PartnerActionMenu.fragments = {
	details: gql`
		fragment PartnerActionMenu on Partners {
			active
			id
			landingPageSlug
			name
			publicProfileId
		}
	`,
};

export default PartnerActionMenu;
