import React from 'react';
import gql from 'graphql-tag';
import { Tooltip, Typography } from '@material-ui/core';

import NamedAvatar from 'components/content/NamedAvatar';
import TimeAndPlace from 'components/content/TimeAndPlace';

import { formatZonedTime } from 'services/dates';

const DropOffAvatar = ({
	mealProposal: {
		deliveryDate,
		ClientLocation: { timezone, Client },
	},
}) => {
	return (
		<>
			<Tooltip
				title={
					<TimeAndPlace
						time={deliveryDate}
						timezone={timezone}
						place={Client.name}
					/>
				}
			>
				<NamedAvatar
					imageUrl={Client.logoUrl}
					alt={Client.name}
					name={Client.name}
				/>
			</Tooltip>
			<Typography>
				{formatZonedTime(deliveryDate, 'time', timezone)}
			</Typography>
		</>
	);
};

DropOffAvatar.fragments = {
	details: gql`
		fragment DropOffAvatar on MealProposals {
			deliveryDate
			ClientLocation {
				timezone
				friendlyName
				Client {
					name
					logoUrl
				}
			}
		}
	`,
};

export default DropOffAvatar;
