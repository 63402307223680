import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const sizeToStyleMap = {
	small: {
		maxHeight: 200,
		maxWidth: 200,
	},
	medium: {
		maxHeight: 225,
		maxWidth: 225,
	},
	large: {
		maxHeight: 250,
		maxWidth: 250,
	},
};

const useStyles = makeStyles(theme => ({
	img: ({ size }) => sizeToStyleMap[size],
}));

const Logo = forwardRef(({ alt, className, size, url, ...props }, ref) => {
	const classes = useStyles({ size });

	return (
		<img
			{...props}
			src={url}
			alt={alt}
			ref={ref}
			className={`${classes.img} ${className}`}
		/>
	);
});

Logo.propTypes = {
	alt: PropTypes.string.isRequired,
	className: PropTypes.string,
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	url: PropTypes.string.isRequired,
};

Logo.defaultProps = {
	className: '',
	size: 'medium',
};

export default Logo;
