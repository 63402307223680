import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { Grid, makeStyles } from '@material-ui/core';
import { put } from '@sporkbytes/api-client/Partners';

import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import ParentPartnerMenuItemsTable from 'components/parent-partner-menu-items/ParentPartnerMenuItemsTable';
import PartnerActionMenu from 'components/partners/PartnerActionMenu';
import PartnerListMenusTable from 'components/partner-list-menus/PartnerListMenusTable';
import PartnerLocationsTable from 'components/partner-locations/PartnerLocationsTable';
import PartnerMenuItemsTable from 'components/partners/PartnerMenuItemsTable';
import PartnerMenuTable from 'components/partners/PartnerMenuTable';
import PurchaseOrderReviewsTable from 'components/purchase-order-reviews/PurchaseOrderReviewsTable';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';
import ReviewsSummary from 'components/utilities/ReviewsSummary';
import RibbonContainer from 'components/content/RibbonContainer';

import { createToggleActiveStatusAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';

const useStyles = makeStyles(theme => ({
	rating: {
		margin: theme.spacing(2, 0),
	},
}));

const PARTNER_QUERY = gql`
	query PartnerDetails($id: uuid!) {
		Partners_by_pk(id: $id) {
			active
			landingPageSlug
			logoUrl
			name
			...PartnerActionMenu
		}
		PurchaseOrderReviewsView_aggregate(where: { PartnerId: { _eq: $id } }) {
			aggregate {
				avg {
					rating
				}
				count
			}
		}
	}
	${PartnerActionMenu.fragments.details}
`;

const Detail = () => {
	const { id } = useParams();
	const { userCan } = useAuth();
	const classes = useStyles();

	return (
		<Page
			query={PARTNER_QUERY}
			variables={{ id }}
			title={({ Partners_by_pk: { name } }) => name}
		>
			{({
				data: {
					Partners_by_pk,
					PurchaseOrderReviewsView_aggregate: {
						aggregate: {
							avg: { rating },
							count,
						},
					},
				},
				refetch,
			}) => (
				<>
					<RibbonContainer
						show={!Partners_by_pk.active}
						title="inactive"
						color="error"
					>
						<PageHeader
							imgUrl={Partners_by_pk.logoUrl}
							headerText={Partners_by_pk.name}
							actionButton={
								<PartnerActionMenu
									partner={Partners_by_pk}
									additionalActions={({ setLoading }) => [
										createToggleActiveStatusAction({
											active: Partners_by_pk.active,
											entityId: id,
											entityName: Partners_by_pk.name,
											entityType: 'Partners',
											onActionComplete: () =>
												refetch({ id }),
											setLoading,
											updateEntity: put,
											userCan,
										}),
									]}
								/>
							}
						/>
						<Grid
							container
							justify="center"
							className={classes.rating}
						>
							<ReviewsSummary count={count} rating={rating} />
						</Grid>
					</RibbonContainer>
					<QueryStringSyncedTabs
						id="tab"
						tabs={[
							{
								id: 'locations',
								label: 'Locations',
								content: (
									<PartnerLocationsTable
										variables={{ PartnerId: id }}
										hiddenColumns={['partnerName']}
										canAddNewEntity={userCan([
											'partner-locations:create',
										])}
										entityToAddName="Partner Location"
										addNewEntityRouteName="partnerLocationsCreate"
										addNewEntityRouteVariables={{ id }}
									/>
								),
							},
							{
								id: 'menu',
								label: 'Menu',
								content: <PartnerMenuTable PartnerId={id} />,
							},
							{
								id: 'partner-list-menus',
								label: 'Partner List Menus',
								content: (
									<PartnerListMenusTable PartnerId={id} />
								),
							},
							{
								id: 'partner-menu-items',
								label: 'Menu Items',
								content: (
									<PartnerMenuItemsTable PartnerId={id} />
								),
							},
							{
								id: 'parent-partner-menu-items',
								label: 'Parent Menu Items',
								content: (
									<ParentPartnerMenuItemsTable
										PartnerId={id}
									/>
								),
							},
							{
								id: 'reviews',
								label: 'Reviews',
								content: (
									<PurchaseOrderReviewsTable
										hiddenColumns={['partnerName']}
										variables={{ PartnerId: id }}
									/>
								),
							},
						]}
					></QueryStringSyncedTabs>
				</>
			)}
		</Page>
	);
};

export default Detail;
