import Create from 'pages/spork-locations/Create';
import Edit from 'pages/spork-locations/Edit';
import SporkLocations from 'pages/spork-locations/SporkLocations';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'sporkLocations',
		path: '',
		component: SporkLocations,
		permissions: ['spork-locations:view'],
		exact: true,
	},
	{
		name: 'sporkLocationsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['spork-locations:edit'],
		exact: true,
	},
	{
		name: 'sporkLocationsCreate',
		path: '/create',
		component: Create,
		permissions: ['spork-locations:create'],
		exact: true,
	},
];

export default prependUrlPath('spork-locations', routes);
