import PartnerTags from 'pages/partner-tags/PartnerTags';
import Edit from 'pages/partner-tags/Edit';
import Create from 'pages/partner-tags/Create';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'partnerTags',
		path: '',
		component: PartnerTags,
		permissions: ['partner-tags:view'],
		exact: true,
	},
	{
		name: 'partnerTagsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['partner-tags:edit'],
		exact: true,
	},
	{
		name: 'partnerTagsCreate',
		path: '/create',
		component: Create,
		permissions: ['partner-tags:create'],
		exact: true,
	},
];

export default prependUrlPath('partner-tags', routes);
