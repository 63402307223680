import { store } from 'models/store';

function errorHandler(err) {
	const { showErrorNotification } = store.getState();

	if (err.response) {
		const { message } = err.response.data;

		showErrorNotification(message);
	} else {
		// This is needed for the case when the server responds without any errors, but axios throws an error. The source of this bug is unknown.
		console.error(err.isAxiosError ? err.toJSON() : err);
		showErrorNotification(
			'There was an error loading data.  Please try again.'
		);
	}

	return Promise.reject(err);
}

export default errorHandler;
