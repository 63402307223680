import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { getIncludedItems } from '@sporkbytes/meal-option-utils';
import { Alert } from '@sporkbytes/material-ui-kit-react';

import BackButton from 'components/routing/BackButton';
import MealProposalTableTopper from 'components/meal-proposals/MealProposalTableTopper';
import Page from 'components/layout/Page';
import PartnerMenuItemPrintableLabel from 'components/partner-menu-items/PartnerMenuItemPrintableLabel';
import PrintSettings from 'components/printable-labels/PrintSettings';

import { getDefaultQuantity } from 'services/printableLabels';

const MEAL_PROPOSAL_QUERY = gql`
	query MealProposalPrintableLabels($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			headCount
			orderNumberText
			...MealProposalTableTopperDetails
			MealOptions(where: { approved: { _eq: true } }) {
				PurchaseOrders(order_by: { pickupTime: asc }) {
					PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
						PurchaseOrderMenuItems(
							where: {
								PartnerMenuItem: {
									producesLabel: { _eq: true }
								}
							}
							order_by: { sortOrder: asc }
						) {
							id
							PartnerMenuItem {
								...PartnerMenuItemPrintableLabelDetails
							}
						}
					}
				}
			}
		}
	}
	${MealProposalTableTopper.fragments.details}
	${PartnerMenuItemPrintableLabel.fragments.details}
`;

const PrintableLabels = () => {
	const { id } = useParams();

	return (
		<Page
			query={MEAL_PROPOSAL_QUERY}
			variables={{ id }}
			title={({ MealProposals_by_pk: { orderNumberText } }) =>
				`Print Labels for ${orderNumberText}`
			}
		>
			{({ data: { MealProposals_by_pk } }) =>
				MealProposals_by_pk.MealOptions.length === 1 ? (
					<PrintSettings
						initialValues={{
							groups: [
								{
									id: 'item-options',
									name: 'Item Options',
									items: getIncludedItems(
										MealProposals_by_pk.MealOptions[0]
									).map(({ id, PartnerMenuItem }) => ({
										...PartnerMenuItem,
										id,
										quantity: getDefaultQuantity(
											MealProposals_by_pk.headCount
										),
									})),
									showTableTopper: true,
									tableTopperData: MealProposals_by_pk,
								},
							],
						}}
						title={`Labels for ${MealProposals_by_pk.orderNumberText}`}
					/>
				) : (
					<>
						<Alert type="error" size="large">
							No meal option has been approved yet.
						</Alert>
						<BackButton />
					</>
				)
			}
		</Page>
	);
};

export default PrintableLabels;
