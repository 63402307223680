import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { setMealCategories } from '@sporkbytes/api-client/MealProposals';

import ChecklistDialogForm from 'components/forms/ChecklistDialogForm';

import axios from 'services/data';

const MEAL_CATEGORIES_QUERY = gql`
	query MealProposalAssignMealCategoriesDialog($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			MealProposalsMealCategories {
				MealCategory {
					id
					name
				}
			}
		}
		MealCategories(
			order_by: { sortOrder: asc }
			where: { active: { _eq: true } }
		) {
			id
			name
		}
	}
`;

const MealProposalAssignMealCategoriesDialog = ({ id, ...props }) => {
	const { data, loading } = useQuery(MEAL_CATEGORIES_QUERY, {
		variables: {
			id,
		},
	});

	return (
		<ChecklistDialogForm
			{...props}
			title="Assign Meal Categories to this Meal Proposal"
			submitButtonContent="Assign"
			loading={loading}
			initialValues={data?.MealProposals_by_pk.MealProposalsMealCategories.map(
				({ MealCategory: { id } }) => id
			)}
			options={data?.MealCategories.map(({ id, name }) => ({
				id,
				label: name,
			}))}
			onSubmit={mealCategoryIds =>
				setMealCategories(axios, id, {
					data: mealCategoryIds.map(id => ({
						id,
					})),
				})
			}
			minSelected={1}
		/>
	);
};

MealProposalAssignMealCategoriesDialog.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MealProposalAssignMealCategoriesDialog;
