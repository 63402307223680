import Edit from 'pages/partner-menu-sections/Edit';
import PartnerMenuItemCreate from 'pages/partner-menu-sections/PartnerMenuItemCreate';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'partnerMenuSectionsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['partner-menu-sections:edit'],
		exact: true,
	},
	{
		name: 'partnerMenuItemsCreate',
		path: '/:id/partner-menu-items/create',
		component: PartnerMenuItemCreate,
		permissions: [
			'partner-menu-items:create',
			'partner-menu-sections:edit',
		],
		exact: true,
	},
];

export default prependUrlPath('partner-menu-sections', routes);
