import React from 'react';
import PropTypes from 'prop-types';
import { Print } from '@material-ui/icons';
import { PrimaryButton } from '@sporkbytes/material-ui-kit-react';

import { orientationOptions } from 'services/printing';

const print = ({ orientation }) => {
	const css = `@page { size: ${orientation}; }`;
	const head = document.head || document.getElementsByTagName('head')[0];
	const style = document.createElement('style');

	style.media = 'print';
	style.appendChild(document.createTextNode(css));

	head.appendChild(style);
	window.print();
	head.removeChild(style);
};

const PrintButton = ({ orientation, ...props }) => {
	return (
		<PrimaryButton
			startIcon={<Print />}
			onClick={() => print({ orientation })}
			{...props}
		>
			Print
		</PrimaryButton>
	);
};

PrintButton.propTypes = {
	orientation: PropTypes.oneOf(Object.values(orientationOptions)),
};

PrintButton.defaultProps = {
	orientation: orientationOptions.PORTRAIT,
};

export default PrintButton;
