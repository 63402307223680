import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import startCase from 'lodash.startcase';

import DateOutput from 'components/content/DateOutput';
import DefinitionList from 'components/content/DefinitionList';
import DetailPageLink from 'components/routing/DetailPageLink';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const EMAIL_ACTIVITY_LOG_QUERY = gql`
	query EmailActivityLogDetails($id: uuid!) {
		EmailActivityLogs_by_pk(id: $id) {
			Contact {
				id
				fullName
			}
			MealProposalActivityLog {
				MealProposal {
					id
					orderNumberText
				}
			}
			event
			type
			reason
			response
			category
			timestamp
		}
	}
`;

const Detail = () => {
	const { id } = useParams();

	return (
		<Page
			query={EMAIL_ACTIVITY_LOG_QUERY}
			variables={{ id }}
			title={() => 'Email Activity Log'}
		>
			{({
				data: {
					EmailActivityLogs_by_pk: {
						Contact,
						MealProposalActivityLog,
						...emailActivity
					},
				},
			}) => (
				<>
					<PageHeader headerText="Email Activity Log" />
					<DefinitionList
						items={[
							{
								label: 'Contact',
								value: Contact,
								render: ({ value }) =>
									value ? (
										<DetailPageLink
											entityType="Contacts"
											id={value.id}
											text={value.fullName}
										/>
									) : null,
							},
							{
								label: 'Event',
								value: emailActivity,
								render: ({ value }) => (
									<>
										{startCase(value.event) +
											(value.type
												? ` (${startCase(value.type)})`
												: '')}
									</>
								),
							},
							{
								label: 'Order #',
								value: MealProposalActivityLog,
								render: ({ value }) =>
									value?.MealProposal ? (
										<DetailPageLink
											entityType="MealProposals"
											id={value.MealProposal.id}
											text={
												value.MealProposal
													.orderNumberText
											}
										/>
									) : null,
							},
							{
								label: 'Date',
								value: emailActivity,
								render: ({ value }) => (
									<DateOutput date={value.timestamp} />
								),
							},
							{
								label: 'Response',
								value: emailActivity,
								render: ({ value }) =>
									value.reason ?? value.response,
							},
							{
								label: 'Categories',
								value: emailActivity,
								render: ({ value: { category: categories } }) =>
									categories ? (
										<ul
											style={{
												listStylePosition: 'inside',
												paddingLeft: 0,
											}}
										>
											{(JSON.parse(categories) ?? []).map(
												category => (
													<li>{category}</li>
												)
											)}
										</ul>
									) : null,
							},
						]}
					/>
				</>
			)}
		</Page>
	);
};

export default Detail;
