import React from 'react';
import gql from 'graphql-tag';
import { TableRow } from '@material-ui/core';
import { totalReceivedByAllPartners } from '@sporkbytes/meal-option-utils';

import TableCellFeatured from 'components/content/TableCellFeatured';
import TableCellNumeric from 'components/content/TableCellNumeric';

const MealOptionTotalReceivedByAllPartnersLineItem = ({ mealOption }) => (
	<TableRow>
		<TableCellFeatured colSpan="3">
			Total Received by All Partners
		</TableCellFeatured>
		<TableCellNumeric
			format="currency"
			value={totalReceivedByAllPartners(mealOption)}
		/>
	</TableRow>
);

MealOptionTotalReceivedByAllPartnersLineItem.fragments = {
	details: gql`
		fragment MealOptionTotalReceivedByAllPartnersLineItemDetails on MealOptions {
			PurchaseOrders(order_by: { pickupTime: asc }) {
				sporkCommissionPercent
				PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
					PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
						discount
						paidToPartner
						quantity
					}
				}
				PurchaseOrderPayoutAdjustments {
					amount
				}
			}
		}
	`,
};

export default MealOptionTotalReceivedByAllPartnersLineItem;
