import React from 'react';
import { Typography } from '@material-ui/core';
import { Currency, PhoneNumber } from '@sporkbytes/material-ui-kit-react';
import gql from 'graphql-tag';

import BooleanStatusIndicator from 'components/utilities/BooleanStatusIndicator';
import DefinitionList from 'components/content/DefinitionList';
import HeadCount from 'components/content/HeadCount';
import RichText from 'components/content/RichText';

const ClientLocationDetails = ({
	clientLocation: {
		automatedApprovalThankYou,
		automatedEmailConfirmation,
		automatedReviewRequest,
		budgetPerPerson,
		description,
		dietaryRestrictions,
		howToOrder,
		name,
		paymentDue,
		phoneNumber,
		timeToEnter,
		timeToExit,
		timeToPark,
		totalBudget,
		deliveryInstructions,
		headCount,
		setupInstructions,
	},
}) => {
	return (
		<>
			<DefinitionList
				title="Basic Info"
				items={[
					{
						label: 'Name',
						value: name,
					},
					{
						label: 'Phone Number',
						value: phoneNumber,
						render: PhoneNumber,
					},
					{
						label: 'Head Count',
						value: headCount,
						render: HeadCount,
					},
				]}
			/>
			<DefinitionList
				title="Budgets and Finance"
				items={[
					{
						label: 'Budget Per Person',
						value: budgetPerPerson,
						render: Currency,
					},
					{
						label: 'Total Budget',
						value: totalBudget,
						render: Currency,
					},
					{
						label: 'Payment Due',
						value: paymentDue,
						render: ({ value }) => (
							<Typography>{value} days</Typography>
						),
					},
				]}
			/>

			<RichText title="Description" body={description} />
			<RichText title="How To Order" body={howToOrder} />
			<RichText
				title="Delivery Instructions"
				body={deliveryInstructions}
			/>
			<RichText title="Setup Instructions" body={setupInstructions} />
			<RichText title="Dietary Restrictions" body={dietaryRestrictions} />
			<DefinitionList
				title="Access Times"
				items={[
					{
						label: 'Time To Park',
						value: `${timeToPark} minutes`,
					},
					{
						label: 'Time To Enter',
						value: `${timeToEnter} minutes`,
					},
					{
						label: 'Time To Exit',
						value: `${timeToExit} minutes`,
					},
				]}
			/>
			<DefinitionList
				title="Automated Emails"
				items={[
					{
						label: 'Automated Approval Thank You',
						value: automatedApprovalThankYou,
						render: BooleanStatusIndicator,
					},
					{
						label: 'Automated Email Confirmation',
						value: automatedEmailConfirmation,
						render: BooleanStatusIndicator,
					},
					{
						label: 'Automated Review Request',
						value: automatedReviewRequest,
						render: BooleanStatusIndicator,
					},
				]}
			/>
		</>
	);
};

ClientLocationDetails.fragments = {
	details: gql`
		fragment ClientLocationDetails on ClientLocations {
			automatedApprovalThankYou
			automatedEmailConfirmation
			automatedReviewRequest
			budgetPerPerson
			description
			dietaryRestrictions
			howToOrder
			name
			paymentDue
			phoneNumber
			timeToEnter
			timeToExit
			timeToPark
			totalBudget
			deliveryInstructions
			headCount
			setupInstructions
		}
	`,
};

export default ClientLocationDetails;
