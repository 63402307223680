import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { post } from '@sporkbytes/api-client/Bills';
import {
	Alert,
	Form,
	GridContainer,
	LinkedDateTimePicker,
} from '@sporkbytes/material-ui-kit-react';

import BillMealSelector from 'components/accounting/BillMealSelector';
import Link from 'components/routing/Link';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

import * as yup from 'models/validation';

import { createBill } from 'services/accounting';
import axios from 'services/data';

const validationSchema = yup.object().shape({
	includedMealProposalIds: yup
		.array()
		.required('Please select at least one meal.'),
	bill: yup.object().shape({
		dueDate: yup.date().required('Please select a due date.'),
	}),
});

const BILL_QUERY = gql`
	query CreateBill($PartnerLocationId: uuid!) {
		PartnerLocations_by_pk(id: $PartnerLocationId) {
			AccountingContactId
			friendlyName
			Partner {
				id
				name
			}
		}
	}
`;

const CreateBill = () => {
	const { PartnerLocationId } = useParams();
	const history = useHistory();

	return (
		<Page
			title="New Bill"
			query={BILL_QUERY}
			variables={{
				PartnerLocationId,
			}}
		>
			{({
				data: {
					PartnerLocations_by_pk: {
						AccountingContactId,
						friendlyName,
						Partner,
					},
				},
			}) => (
				<>
					<PageHeader headerText="New Bill" />
					<PageSubheader>{`${Partner.name} - ${friendlyName}`}</PageSubheader>
					{AccountingContactId ? (
						<Form
							initialValues={createBill(
								{
									contact: {
										contactID: AccountingContactId,
									},
								},
								{
									PartnerId: Partner.id,
									PartnerLocationId,
								}
							)}
							onSubmit={async values => {
								await post(axios, values);

								history.pushPreviousRoute();
							}}
							validationSchema={validationSchema}
						>
							{() => (
								<>
									<GridContainer columns={2}>
										<LinkedDateTimePicker
											name="bill.dueDate"
											dateOnly
											valueFormat="yyyy-MM-dd"
											minDate={new Date()}
										/>
									</GridContainer>
									<BillMealSelector
										PartnerLocationId={PartnerLocationId}
									/>
								</>
							)}
						</Form>
					) : (
						<Alert type="error">
							You have chosen a Partner Location with no
							Accounting Contact. Please{' '}
							<Link
								routeName="partnerLocationsEdit"
								variables={{ id: PartnerLocationId }}
								permissions={['partner-locations:edit']}
							>
								set an Accounting Contact
							</Link>{' '}
							for this Partner Location.
						</Alert>
					)}
				</>
			)}
		</Page>
	);
};

export default CreateBill;
