import ClientLocationCreate from 'pages/clients/ClientLocationCreate';
import Create from 'pages/clients/Create';
import Detail from 'pages/clients/Detail';
import Edit from 'pages/clients/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'clientsDetail',
		path: '/:id/detail',
		component: Detail,
		permissions: ['clients:view'],
		exact: true,
	},
	{
		name: 'clientsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['clients:edit'],
		exact: true,
	},
	{
		name: 'clientsCreate',
		path: '/create',
		component: Create,
		permissions: ['clients:create'],
		exact: true,
	},
	{
		name: 'clientLocationsCreate',
		path: '/:id/client-locations/create',
		component: ClientLocationCreate,
		permissions: ['client-locations:create'],
		exact: true,
	},
];

export default prependUrlPath('clients', routes);
