import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	status: {
		background: ({ value }) =>
			value ? theme.palette.success[800] : theme.palette.error[800],
		color: theme.palette.common.white,
		fontSize: 12,
		fontWeight: 'bold',
		lineHeight: '1.5em',
		padding: theme.spacing(0.25),
		textAlign: 'center',
		textTransform: 'uppercase',
		width: 80,
	},
}));

const BooleanStatusIndicator = ({ value, type, trueText, falseText }) => {
	const classes = useStyles({ value });

	return (
		<Typography className={type === 'filled' ? classes.status : ''}>
			{value ? trueText : falseText}
		</Typography>
	);
};

BooleanStatusIndicator.propTypes = {
	value: PropTypes.bool,
	type: PropTypes.oneOf(['plain', 'filled']),
	trueText: PropTypes.string,
	falseText: PropTypes.string,
};

BooleanStatusIndicator.defaultProps = {
	type: 'plain',
	trueText: 'Yes',
	falseText: 'No',
};

export default BooleanStatusIndicator;
