import AuthService from 'services/auth';

export default function(config) {
	const service = new AuthService();
	const token = service.getToken();

	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
}
