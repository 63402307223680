import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';
import { sort } from '@sporkbytes/api-client/PartnerListMenus';

import DetailPageLink from 'components/routing/DetailPageLink';
import EditableSortOrder from 'components/forms/EditableSortOrder';
import PartnerListMenuActionMenu from 'components/partner-list-menus/PartnerListMenuActionMenu';
import RemoteTable from 'components/content/RemoteTable';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const getPartnerListMenusQuery = searchTerm => gql`
	query PartnerListMenus(
		$PartnerId: uuid
		$nameOrderBy: order_by
		$sortOrderOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		PartnerListMenus(
			where: { PartnerId: { _eq: $PartnerId }, name: { _ilike: "%${searchTerm}%" } }
			order_by: { name: $nameOrderBy, sortOrder: $sortOrderOrderBy }
			limit: $limit
			offset: $offset
		) {
			id
			name
			sortOrder
			PartnerListMenusMealTypes(
				order_by: { MealType: { sortOrder: asc } }
			) {
				MealType {
					name
				}
			}
		}
		PartnerListMenus_aggregate(
			where: { PartnerId: { _eq: $PartnerId }, name: { _ilike: "%${searchTerm}%" } }
		) {
			aggregate {
				count
			}
		}
	}
`;

const PartnerListMenusTable = ({ PartnerId }) => {
	const { userCan, userCannot } = useAuth();
	const ref = useRef();

	return (
		<RemoteTable
			tableRef={ref}
			columns={[
				{
					title: 'Sort Order',
					field: 'sortOrder',
					render: ({ id, sortOrder }) => (
						<EditableSortOrder
							onSave={value =>
								sort(axios, id, {
									newValue: value,
								}).then(ref.current.onQueryChange)
							}
							permissions={['partner-list-menus:edit']}
							required={true}
							value={sortOrder}
						/>
					),
				},
				{
					title: 'Name',
					field: 'name',
					render: ({ id, name }) => (
						<DetailPageLink
							entityType="PartnerListMenus"
							id={id}
							text={name}
						/>
					),
				},
				{
					title: 'Meal Types',
					field: 'mealTypes',
					sorting: false,
					render: ({ PartnerListMenusMealTypes }) =>
						makeCommaSeparatedList(
							PartnerListMenusMealTypes.map(
								({ MealType: { name } }) => name
							)
						),
				},
				{
					hidden: userCannot(['partner-list-menus:edit']),
					title: 'Actions',
					field: 'actions',
					sorting: false,
					render: partnerListMenu => (
						<PartnerListMenuActionMenu
							partnerListMenu={partnerListMenu}
						/>
					),
				},
			]}
			canAddNewEntity={userCan(['partner-list-menus:create'])}
			addNewEntityRouteName="partnerListMenusCreate"
			addNewEntityRouteVariables={{ id: PartnerId }}
			entityToAddName="Partner List Menu"
			defaultOrderBy="sortOrder"
			getQuery={getPartnerListMenusQuery}
			queryEntity="PartnerListMenus"
			variables={{ PartnerId }}
		/>
	);
};

PartnerListMenusTable.propTypes = {
	PartnerId: PropTypes.string,
};

export default PartnerListMenusTable;
