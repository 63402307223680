import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { put } from '@sporkbytes/api-client/PurchaseOrders';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PurchaseOrderForm from 'components/purchase-orders/PurchaseOrderForm';

const PURCHASE_ORDER_QUERY = gql`
	query PurchaseOrderEdit($id: uuid!) {
		PurchaseOrders_by_pk(id: $id) {
			automatedEmailConfirmation
			PartnerLocationId
			pickupInstructions
			pickupTime
			reviewable
			sporkCommissionPercent
			PartnerLocation {
				id
				fullAddress
				timezone
				Partner {
					id
					name
					logoUrl
				}
			}
			MealOption {
				...PurchaseOrderFormSporkLocationFilter
				...PurchaseOrderFormSubheader
				id
				MealProposal {
					id
				}
			}
		}
	}
	${PurchaseOrderForm.fragments.sporkLocationFilter}
	${PurchaseOrderForm.fragments.subheader}
`;

const PurchaseOrderEdit = () => {
	const { id } = useParams();

	return (
		<Page
			query={PURCHASE_ORDER_QUERY}
			variables={{ id }}
			title={({
				PurchaseOrders_by_pk: {
					PartnerLocation: {
						Partner: { name },
					},
				},
			}) => `Edit Purchase Order ${name}`}
		>
			{({
				data: {
					PurchaseOrders_by_pk: {
						PartnerLocation,
						MealOption,
						...purchaseOrder
					},
				},
			}) => (
				<FormWrapper
					form={PurchaseOrderForm}
					initialValues={purchaseOrder}
					onSubmit={(axios, values) => put(axios, id, values)}
					partnerLocation={PartnerLocation}
					pageSubheaderText={`Edit Purchase Order for ${PartnerLocation.Partner.name}`}
					partnerLocationSearchFilter={`
						{ id: { _neq: "${PartnerLocation.id}" } }
						{ Partner: { id: { _eq: "${PartnerLocation.Partner.id}" } } }
					`}
					partnerLocationSearchHelperText={`Search for another ${PartnerLocation.Partner.name} location`}
					mealOption={MealOption}
				/>
			)}
		</Page>
	);
};

export default PurchaseOrderEdit;
