import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { put } from '@sporkbytes/api-client/PartnerMenuItems';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PartnerMenuItemForm from 'components/partner-menu-items/PartnerMenuItemForm';

// TODO: use fragments here
const PARTNER_MENU_ITEM_QUERY = gql`
	query PartnerMenuItemEdit($id: uuid!) {
		PartnerMenuItems_by_pk(id: $id) {
			name
			printableName
			cost
			size
			feedsMinimum
			feedsMaximum
			description
			howToOrder
			photoUrl
			producesLabel
			showInDriverChecklist
			visibleInMenu
			PartnerMenuItemsDietaryTags {
				DietaryTagId
			}
			Partner {
				logoUrl
				name
			}
		}
	}
`;

const Edit = props => {
	const { id } = useParams();

	return (
		<Page
			title={({ PartnerMenuItems_by_pk: { name } }) => `Edit ${name}`}
			query={PARTNER_MENU_ITEM_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					PartnerMenuItems_by_pk: {
						name,
						PartnerMenuItemsDietaryTags,
						Partner: { logoUrl, name: PartnerName },
						...data
					},
				},
			}) => (
				<>
					<PageHeader headerText={PartnerName} imgUrl={logoUrl} />
					<PageSubheader>Edit {name}</PageSubheader>
					<FormWrapper
						form={PartnerMenuItemForm}
						initialValues={{
							name,
							...data,
							DietaryTags: PartnerMenuItemsDietaryTags.map(
								({ DietaryTagId }) => DietaryTagId
							),
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
