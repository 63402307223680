import React from 'react';
import gql from 'graphql-tag';
import {
	Grid,
	IconButton,
	makeStyles,
	TableCell,
	TableRow,
	Tooltip,
} from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import { ConfirmationDialog } from '@sporkbytes/material-ui-kit-react';
import { deletePurchaseOrderPayoutAdjustments } from '@sporkbytes/api-client/PurchaseOrderPayoutAdjustments';
import { canEdit } from '@sporkbytes/purchase-order-utils';
import { postDeliveryEditWindowInDays } from '@sporkbytes/config';

import TableCellNumeric from 'components/content/TableCellNumeric';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';
import axios from 'services/data';
import history from 'services/history';

const useStyles = makeStyles(theme => ({
	description: {
		fontStyle: 'italic',
	},
	editableRow: {
		cursor: 'pointer',
	},
	nonEditableRow: {
		cursor: 'help',
	},
}));

const PurchaseOrderPayoutAdjustmentLineItems = ({ purchaseOrder }) => {
	const { role, userCan } = useAuth();
	const { showDialog } = useStore();
	const classes = useStyles();

	const canDelete =
		userCan(['purchase-order-payout-adjustments:delete']) &&
		canEdit(purchaseOrder, role);
	const canEditPayoutAdjustment = payoutAdjustment =>
		userCan(['purchase-order-payout-adjustments:edit']) &&
		!payoutAdjustment.PurchaseOrderMenuItemId &&
		canEdit(purchaseOrder, role);

	const Wrapper = ({ payoutAdjustment, children }) =>
		canEditPayoutAdjustment(payoutAdjustment) ? (
			children
		) : (
			<Tooltip
				title={`Payout adjustments can only be edited up to ${postDeliveryEditWindowInDays} days after the order. Payout adjustments for a specific item can only be deleted.`}
			>
				{children}
			</Tooltip>
		);

	return purchaseOrder.PurchaseOrderPayoutAdjustments?.map(
		payoutAdjustment => (
			<Wrapper
				key={payoutAdjustment.id}
				payoutAdjustment={payoutAdjustment}
			>
				<TableRow
					onClick={
						canEditPayoutAdjustment(payoutAdjustment)
							? () =>
									history.pushNamedRoute(
										'purchaseOrderPayoutAdjustmentsEdit',
										{ id: payoutAdjustment.id }
									)
							: undefined
					}
					hover={canEditPayoutAdjustment(payoutAdjustment)}
					className={
						canEditPayoutAdjustment(payoutAdjustment)
							? classes.editableRow
							: classes.nonEditableRow
					}
				>
					<TableCell>
						<Grid
							alignItems="center"
							container
							justify="space-between"
							spacing={1}
							wrap="nowrap"
						>
							<Grid item>
								Payout Adjustment for:
								<div className={classes.description}>
									{payoutAdjustment.description}
								</div>
							</Grid>
							{canDelete && (
								<Grid item>
									<IconButton
										onClick={e => {
											e.stopPropagation();
											showDialog(ConfirmationDialog, {
												text: `Are you sure you want to delete this payout adjustment?`,
												onConfirmation: () =>
													deletePurchaseOrderPayoutAdjustments(
														axios,
														payoutAdjustment.id
													),
											});
										}}
									>
										<DeleteForever fontSize="small" />
									</IconButton>
								</Grid>
							)}
						</Grid>
					</TableCell>
					<TableCellNumeric
						colSpan="4"
						format="currency"
						value={payoutAdjustment.amount}
					/>
				</TableRow>
			</Wrapper>
		)
	);
};

PurchaseOrderPayoutAdjustmentLineItems.fragments = {
	details: gql`
		fragment PurchaseOrderPayoutAdjustmentLineItemsDetails on PurchaseOrders {
			pickupTime
			PurchaseOrderPayoutAdjustments {
				id
				amount
				description
				PurchaseOrderMenuItemId
			}
		}
	`,
};

export default PurchaseOrderPayoutAdjustmentLineItems;
