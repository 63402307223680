import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	list: {
		padding: 0,
	},
	listItem: {
		display: 'inline',
		'&::after': {
			content: '", "',
		},
		'&:last-child:first-child::before': { content: '""' },
		'&:last-child::after': {
			content: ({ includePeriod }) => (includePeriod ? '"."' : '""'),
		},
		'&:last-child::before': {
			content: ({ includeAnd }) => (includeAnd ? '" and "' : '""'),
		},
		'&:nth-last-child(2):first-child::after': {
			content: '" "',
		},
	},
}));

const CommaSeparatedList = ({ items, ...props }) => {
	const classes = useStyles(props);

	return (
		<ul className={classes.list}>
			{items.map((item, index) => (
				<li className={classes.listItem} key={index}>
					{item}
				</li>
			))}
		</ul>
	);
};

CommaSeparatedList.propTypes = {
	includeAnd: PropTypes.bool,
	includePeriod: PropTypes.bool,
	items: PropTypes.arrayOf(PropTypes.element),
};

CommaSeparatedList.defaultProps = {
	includeAnd: true,
};

export default CommaSeparatedList;
