import React from 'react';
import { post } from '@sporkbytes/api-client/Partners';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PartnerForm from 'components/partners/PartnerForm';

import { useStore } from 'models/store';

const Create = () => {
	const { selectedSporkLocationId } = useStore();
	const initialValues = {
		reviewable: true,
		SporkLocations: [selectedSporkLocationId],
	};

	return (
		<Page title="New Partner">
			<PageHeader headerText="New Partner" />
			<FormWrapper
				form={PartnerForm}
				initialValues={initialValues}
				onSubmit={(axios, values) => post(axios, values)}
				redirectToRoute="partnersDetail"
			/>
		</Page>
	);
};

export default Create;
