import React from 'react';

import EditableValue from 'components/forms/EditableValue';

const EditableSortOrder = props => (
	<EditableValue
		{...props}
		inputLabel="Move to position"
		name="sortOrder"
		submitButtonContent="Move"
		tooltipText="Move to..."
		type="sortOrder"
	/>
);

export default EditableSortOrder;
