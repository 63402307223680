import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, Typography } from '@material-ui/core';

const LateByText = ({ lateBy }) => {
	const theme = useTheme();

	let color;
	if (lateBy <= 0) {
		color = theme.palette.success[600];
	} else if (lateBy <= 10) {
		color = theme.palette.warning[800];
	} else {
		color = theme.palette.error[600];
	}

	return (
		<Typography align="center" style={{ fontWeight: 'bold', color }}>{`${
			lateBy > 0 ? '-' : '+'
		} ${Math.abs(lateBy)} min`}</Typography>
	);
};

LateByText.propTypes = {
	lateBy: PropTypes.number.isRequired,
};

export default LateByText;
