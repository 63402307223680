import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { cdn } from '@sporkbytes/config';
import QRCode from 'qrcode.react';

const useStyles = makeStyles(theme => ({
	qrCodeContainer: {
		margin: 'auto',
		textAlign: 'center',
	},
	qrCodeScanEmoji: {
		display: 'inline-block',
		marginRight: theme.spacing(1),
		maxWidth: 40,
		verticalAlign: 'middle',
	},
}));

const ReviewQRCode = ({ headerProps, qrCodeValue, subheaderProps }) => {
	const classes = useStyles();

	return (
		<div className={classes.qrCodeContainer}>
			<Typography gutterBottom {...headerProps}>
				How was the food?
			</Typography>
			<Typography gutterBottom {...subheaderProps}>
				Scan this QR code to leave a review
			</Typography>
			<QRCode value={qrCodeValue} size={200} />
			<Typography>
				<img
					src={`${cdn.url}/content/selfie-emoji.png`}
					alt="Scan QR code with your camera"
					className={classes.qrCodeScanEmoji}
				/>
				<small>Use the camera on your phone to scan it.</small>
			</Typography>
		</div>
	);
};

ReviewQRCode.propTypes = {
	headerProps: PropTypes.object,
	qrCodeValue: PropTypes.string.isRequired,
	subheaderProps: PropTypes.object,
};

ReviewQRCode.defaultProps = {
	headerProps: {},
	subheaderProps: {},
};

export default ReviewQRCode;
