import React from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '@sporkbytes/material-ui-kit-react';

const BackButton = () => {
	const history = useHistory();

	return (
		<PrimaryButton onClick={history.pushPreviousRoute}>
			Go Back
		</PrimaryButton>
	);
};

export default BackButton;
