import React from 'react';
import { post } from '@sporkbytes/api-client/MealCategories';

import FormWrapper from 'components/forms/FormWrapper';
import NameForm from 'components/forms/NameForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const Create = () => {
	return (
		<Page title="Add New Meal Category">
			<PageHeader headerText="Add New Meal Category" />
			<FormWrapper form={NameForm} onSubmit={post} />
		</Page>
	);
};

export default Create;
