import React from 'react';
import gql from 'graphql-tag';
import { Currency } from '@sporkbytes/material-ui-kit-react';

import DefinitionList from 'components/content/DefinitionList';
import DetailSection from 'components/content/DetailSection';
import HeadCount from 'components/content/HeadCount';
import NoteContainer from 'components/content/NoteContainer';
import RichText from 'components/content/RichText';

const PartnerLocationOrderingDetails = ({ partnerLocation }) => (
	// Using a div wrapper allows this to be displayed properly in the context of an ExpansionPanelDetail
	<div>
		{partnerLocation.howToOrder && (
			<DetailSection title="How to Order">
				<NoteContainer content={partnerLocation.howToOrder} />
			</DetailSection>
		)}
		<DefinitionList
			title="Order Scale"
			items={[
				{
					label: 'Minimum Order Head Count',
					value: partnerLocation.minimumOrderHeadCount,
					render: HeadCount,
				},
				{
					label: 'Maximum Order Head Count',
					value: partnerLocation.maximumOrderHeadCount,
					render: HeadCount,
				},
				{
					label: 'Minimum Order Amount',
					value: partnerLocation.minimumOrderAmount,
					render: Currency,
				},
				{
					label: 'Maximum Order Amount',
					value: partnerLocation.maximumOrderAmount,
					render: Currency,
				},
			]}
		/>
		<DefinitionList
			title="Lead Times"
			items={[
				{
					label: 'Mimimum Lead Time Amount',
					value: partnerLocation.minimumLeadTimeAmount,
				},
				{
					label: 'Mimimum Lead Time Scale',
					value: partnerLocation.minimumLeadTimeScale,
				},
			]}
		/>
		<RichText
			title="Preferred Pickup Times"
			body={partnerLocation.preferredPickupTimes}
		/>
		<DefinitionList
			title="Access Times"
			items={[
				{
					label: 'Time To Park',
					value: `${partnerLocation.timeToPark} minutes`,
				},
				{
					label: 'Time To Enter',
					value: `${partnerLocation.timeToEnter} minutes`,
				},
				{
					label: 'Time To Exit',
					value: `${partnerLocation.timeToExit} minutes`,
				},
			]}
		/>
	</div>
);

PartnerLocationOrderingDetails.fragments = {
	details: gql`
		fragment PartnerLocationOrderingDetails on PartnerLocations {
			howToOrder
			maximumOrderAmount
			maximumOrderHeadCount
			minimumLeadTimeAmount
			minimumLeadTimeScale
			minimumOrderAmount
			minimumOrderHeadCount
			preferredPickupTimes
			timeToEnter
			timeToExit
			timeToPark
		}
	`,
};

export default PartnerLocationOrderingDetails;
