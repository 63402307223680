import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { Typography } from '@material-ui/core';
import { put } from '@sporkbytes/api-client/Partners';
import { Avatar, GridContainer } from '@sporkbytes/material-ui-kit-react';

import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import DetailPageLink from 'components/routing/DetailPageLink';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PartnerActionMenu from 'components/partners/PartnerActionMenu';
import RemoteTable from 'components/content/RemoteTable';

import { getMultiWordSearchWhereClause } from 'services/search';
import { useAuth } from 'services/auth';

const getPartnersQuery = searchTerm =>
	gql`
		query Partners(
			$activeOrderBy: order_by
			$nameOrderBy: order_by
			$limit: Int!
			$offset: Int!
		) {
			Partners(
				where: { _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
				order_by: {
					active: $activeOrderBy
					name: $nameOrderBy
				}
				limit: $limit
				offset: $offset
			) {
				id
                active
                logoUrl
                name
				PartnerListMenus {
					id
				}
                ...PartnerActionMenu
			}
			Partners_aggregate(where: { _and: [${getMultiWordSearchWhereClause(
				searchTerm
			)}] }) {
				aggregate {
					count
				}
			}
        }
        ${PartnerActionMenu.fragments.details}
	`;

const Partners = () => {
	const { userCan } = useAuth();
	const ref = useRef();
	const entityType = 'Partners';

	return (
		<Page title={entityType}>
			<PageHeader headerText={entityType} />
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Name',
						field: 'name',
						render: ({ id, logoUrl, name }) => (
							<DetailPageLink
								entityType="Partners"
								id={id}
								text={
									<GridContainer
										columns="none"
										alignItems="center"
										wrap="nowrap"
									>
										<Avatar src={logoUrl} alt={name} />
										<Typography>{name}</Typography>
									</GridContainer>
								}
							/>
						),
					},
					{
						title: 'Active',
						field: 'active',
						render: ({ active, id }) => (
							<BooleanFieldSwitch
								afterChange={ref.current.onQueryChange}
								entityId={id}
								entityType="partners"
								updateEntity={put}
								value={active}
							/>
						),
					},
					{
						title: 'Partner List Menus',
						field: 'PartnerListMenus.length',
						sorting: false,
						render: partner => (
							<Typography>
								{partner.PartnerListMenus.length}
							</Typography>
						),
					},
					{
						title: 'Actions',
						field: 'actions',
						sorting: false,
						export: false,
						render: partner => (
							<PartnerActionMenu
								onActionComplete={ref.current.onQueryChange}
								partner={partner}
							/>
						),
					},
				]}
				getQuery={getPartnersQuery}
				defaultOrderBy="name"
				defaultOrderDirection="asc"
				queryEntity="Partners"
				addNewEntityRouteName="partnersCreate"
				entityToAddName="Partner"
				canAddNewEntity={userCan(['partners:create'])}
				options={{
					exportButton: true,
					pageSize: 25,
					pageSizeOptions: [25, 50, 100],
				}}
			/>
		</Page>
	);
};

export default Partners;
