import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	photo: {
		maxWidth: '100%',
	},
}));

const PartnerMenuItemPhoto = ({ alt, src }) => {
	const classes = useStyles();

	return <img src={src} alt={alt} className={classes.photo} />;
};

PartnerMenuItemPhoto.propTypes = {
	alt: PropTypes.string.isRequired,
	src: PropTypes.string.isRequired,
};

export default PartnerMenuItemPhoto;
