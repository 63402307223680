import React from 'react';
import {
	Button,
	makeStyles,
	Snackbar,
	SnackbarContent,
} from '@material-ui/core';

import { useStore } from 'models/store';

const useStyles = makeStyles(theme => ({
	snackbar: {
		backgroundColor: theme.palette.info[700],
	},
}));

const UpdateNotification = () => {
	const classes = useStyles();
	const { updateRequired } = useStore();

	return (
		<Snackbar open={updateRequired}>
			<SnackbarContent
				className={classes.snackbar}
				message="Your app version is out of date.  Please click update after you've completed your work and are ready to update."
				action={
					<Button
						color="inherit"
						onClick={() => window.location.reload(true)}
					>
						Update
					</Button>
				}
			/>
		</Snackbar>
	);
};

export default UpdateNotification;
