import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/MealProposals';
import { Alert } from '@sporkbytes/material-ui-kit-react';
import { canEdit } from '@sporkbytes/meal-proposal-utils';

import BackButton from 'components/routing/BackButton';
import FormWrapper from 'components/forms/FormWrapper';
import MealProposalForm from 'components/meal-proposals/MealProposalForm';
import Page from 'components/layout/Page';

import { useAuth } from 'services/auth';

// TODO: use fragments here
const MEAL_PROPOSAL_QUERY = gql`
	query MealProposalEdit($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			ClientLocationId
			automatedApprovalThankYou
			automatedEmailConfirmation
			automatedReviewRequest
			budgetPerPerson
			businessUnit
			deliveryDate
			deliveryInstructions
			dietaryRestrictions
			headCount
			name
			orderNumberText
			setupInstructions
			SporkLocationId
			status
			totalBudget
			ClientLocation {
				fullAddress
				timezone
				Client {
					id
					logoUrl
					name
				}
			}
			MealProposalsMealCategories {
				MealCategoryId
			}
		}
	}
`;

const Edit = () => {
	const { id } = useParams();
	const { role } = useAuth();

	return (
		<Page
			title={({ MealProposals_by_pk: { orderNumberText } }) =>
				`Edit ${orderNumberText}`
			}
			query={MEAL_PROPOSAL_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					MealProposals_by_pk: {
						orderNumberText,
						ClientLocation,
						MealProposalsMealCategories,
						...initialValues
					},
				},
			}) =>
				canEdit(initialValues, role) ? (
					<FormWrapper
						form={MealProposalForm}
						initialValues={{
							...initialValues,
							MealCategories: MealProposalsMealCategories.map(
								({ MealCategoryId }) => MealCategoryId
							),
						}}
						pageSubheaderText={`Edit Meal Proposal ${orderNumberText}`}
						clientLocation={ClientLocation}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				) : (
					<>
						<Alert type="error" size="large">
							This meal cannot be edited. Only past meals that are
							in Draft status or future meals that are In Progress
							can be edited.
						</Alert>
						<BackButton />
					</>
				)
			}
		</Page>
	);
};

export default Edit;
