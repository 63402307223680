import Detail from 'pages/email-activity-logs/Detail';
import EmailActivityLogs from 'pages/email-activity-logs/EmailActivityLogs';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'emailActivityLogs',
		path: '',
		component: EmailActivityLogs,
		permissions: ['email-activity-logs:view'],
		exact: true,
	},
	{
		name: 'emailActivityLogsDetail',
		path: '/:id/detail',
		component: Detail,
		permissions: ['email-activity-logs:view'],
		exact: true,
	},
];

export default prependUrlPath('email-activity-logs', routes);
