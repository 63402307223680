import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useFormikContext } from 'formik';
import {
	DateRangeSelector,
	GridContainer,
	LinkedCheckboxGroup,
} from '@sporkbytes/material-ui-kit-react';
import { getLastPayoutPeriod } from '@sporkbytes/time-utils';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import DetailPageLink from 'components/routing/DetailPageLink';
import FormSectionHeader from 'components/forms/FormSectionHeader';
import LoadingStateContainer from 'components/utilities/LoadingStateContainer';

import { getLineItemForBill } from 'services/accounting';
import { pick } from 'services/utilities';

const PurchaseOrderBillRequirement = gql`
	fragment PurchaseOrderBillRequirement on PurchaseOrders {
		id
		sporkCommissionPercent
		PurchaseOrderMenuSections {
			PurchaseOrderMenuItems {
				discount
				paidToPartner
				quantity
			}
		}
		PurchaseOrderPayoutAdjustments {
			amount
		}
	}
`;

const MEALS_QUERY = gql`
	query BillMealSelector(
		$beginDate: timestamptz
		$endDate: timestamptz
		$PartnerLocationId: uuid!
	) {
		MealProposals(
			where: {
				deliveryDate: { _gte: $beginDate, _lte: $endDate }
				MealOptions: {
					approved: { _eq: true }
					PurchaseOrders: {
						BillId: { _is_null: true }
						PartnerLocationId: { _eq: $PartnerLocationId }
					}
				}
				status: { _nin: ["Canceled", "Draft"] }
			}
			order_by: { deliveryDate: asc }
		) {
			id
			deliveryDate
			orderNumberText
			MealOptions(
				where: {
					approved: { _eq: true }
					PurchaseOrders: {
						BillId: { _is_null: true }
						PartnerLocationId: { _eq: $PartnerLocationId }
					}
				}
			) {
				PurchaseOrders(
					where: {
						BillId: { _is_null: true }
						PartnerLocationId: { _eq: $PartnerLocationId }
					}
				) {
					...PurchaseOrderBillRequirement
				}
			}
		}
		AccountingSettings_by_pk(id: "partnerBillMealItemCode") {
			value
		}
	}
	${PurchaseOrderBillRequirement}
`;

const BillMealSelector = ({ PartnerLocationId }) => {
	const [dateRange, setDateRange] = useState(getLastPayoutPeriod());
	const { data, loading } = useQuery(MEALS_QUERY, {
		variables: {
			...pick(dateRange, ['beginDate', 'endDate']),
			PartnerLocationId,
		},
	});
	const { setFieldValue, values } = useFormikContext();

	// auto-select Meal Proposals when new ones are retrieved
	useEffect(() => {
		setFieldValue(
			'includedMealProposalIds',
			data?.MealProposals.map(({ id }) => id)
		);
	}, [data, setFieldValue]);

	// set appropriate Formik values when the included Meal Proposals change
	useEffect(() => {
		if (Array.isArray(values.includedMealProposalIds)) {
			const includedMealProposals = data?.MealProposals.filter(({ id }) =>
				values.includedMealProposalIds.includes(id)
			);

			setFieldValue(
				'bill.lineItems',
				includedMealProposals.map(mealProposal =>
					getLineItemForBill(
						mealProposal,
						mealProposal.MealOptions[0].PurchaseOrders[0],
						data?.AccountingSettings_by_pk.value
					)
				)
			);
			setFieldValue(
				'bill.invoiceNumber',
				makeCommaSeparatedList(
					includedMealProposals.map(
						({ orderNumberText }) => orderNumberText
					),
					false
				)
			);
			setFieldValue(
				'includedPurchaseOrderIds',
				includedMealProposals.map(
					({ MealOptions }) => MealOptions[0].PurchaseOrders[0].id
				)
			);
		}
	}, [data, setFieldValue, values.includedMealProposalIds]);

	return (
		<>
			<FormSectionHeader>Find Meals to Include</FormSectionHeader>
			<GridContainer>
				<DateRangeSelector
					defaultDateRange={dateRange}
					onChange={setDateRange}
				/>
			</GridContainer>
			<LoadingStateContainer loading={loading} height={100}>
				<GridContainer>
					<LinkedCheckboxGroup
						name="includedMealProposalIds"
						label="Choose Meals"
						options={data?.MealProposals}
						getOptionKey={option => option.id}
						getOptionLabel={option => (
							<DetailPageLink
								entityType="mealProposals"
								id={option.id}
								text={option.orderNumberText}
								target="_blank"
							></DetailPageLink>
						)}
						getOptionValue={option => option.id}
						showCheckAll={true}
					/>
				</GridContainer>
			</LoadingStateContainer>
		</>
	);
};

BillMealSelector.propTypes = {
	PartnerLocationId: PropTypes.string.isRequired,
};

BillMealSelector.fragments = {
	PurchaseOrderBillRequirement,
};

export default BillMealSelector;
