import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format, startOfMonth } from 'date-fns';
import {
	DateTimePicker,
	GridContainer,
} from '@sporkbytes/material-ui-kit-react';

import FeaturedPartnersTable from 'components/featured-partners/FeaturedPartnersTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const FeaturedPartners = () => {
	const { month } = useParams();
	const history = useHistory();

	return (
		<Page title="Featured Partners">
			<PageHeader headerText="Featured Partners" />
			<GridContainer columns={1}>
				<DateTimePicker
					value={month}
					views={['year', 'month']}
					dateOnly
					label="Month"
					onChange={date =>
						history.pushNamedRoute('featuredPartners', {
							month: format(startOfMonth(date), 'yyyy-MM-dd'),
						})
					}
				/>
				<FeaturedPartnersTable month={month} />
			</GridContainer>
		</Page>
	);
};

export default FeaturedPartners;
