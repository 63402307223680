import MealTypes from 'pages/meal-types/MealTypes';
import Create from 'pages/meal-types/Create';
import Edit from 'pages/meal-types/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'mealTypes',
		path: '',
		component: MealTypes,
		permissions: ['meal-types:view'],
		exact: true,
	},
	{
		name: 'mealTypesCreate',
		path: '/create',
		component: Create,
		permissions: ['meal-types:create'],
		exact: true,
	},
	{
		name: 'mealTypesEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['meal-types:edit'],
		exact: true,
	},
];

export default prependUrlPath('meal-types', routes);
