import Detail from 'pages/partner-list-menus/Detail';
import Edit from 'pages/partner-list-menus/Edit';
import PartnerListMenuSectionCreate from 'pages/partner-list-menus/PartnerListMenuSectionCreate';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'partnerListMenusDetail',
		path: '/:id/detail',
		component: Detail,
		permissions: ['partner-list-menus:view'],
		exact: true,
	},
	{
		name: 'partnerListMenusEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['partner-list-menus:edit'],
		exact: true,
	},
	{
		name: 'partnerListMenuSectionsCreate',
		path: '/:id/partner-list-menu-sections/create',
		component: PartnerListMenuSectionCreate,
		permissions: [
			'partner-list-menu-sections:create',
			'partner-list-menus:edit',
		],
		exact: true,
	},
];

export default prependUrlPath('partner-list-menus', routes);
