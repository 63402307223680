import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { put } from '@sporkbytes/api-client/EmailTemplates';

import EmailTemplateForm from 'components/email-templates/EmailTemplateForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const EMAIL_TEMPLATE_QUERY = gql`
	query EmailTemplateEdit($id: String!) {
		EmailTemplates_by_pk(id: $id) {
			id
			name
			greeting
			preButtonContent
			buttonText
			postButtonContent
			warningText
			signature
		}
	}
`;

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ EmailTemplates_by_pk: { name } }) => `Edit ${name}`}
			query={EMAIL_TEMPLATE_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					EmailTemplates_by_pk: { name, ...emailTemplate },
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${name}`} />
					<FormWrapper
						form={EmailTemplateForm}
						initialValues={{
							name,
							...emailTemplate,
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
						showIdentifierFields={false}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
