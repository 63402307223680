import React from 'react';
import gql from 'graphql-tag';
import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import {
	AccessAlarm,
	Check,
	GroupWork,
	People,
	Timeline,
	UnfoldLess,
	UnfoldMore,
} from '@material-ui/icons';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import {
	GridContainer,
	IntegerInput,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';

import { useDashboardDay } from 'pages/dashboard/DashboardDayContext';
import CurrentDriverSchedulesDialog from 'pages/dashboard/CurrentDriverSchedulesDialog';
import DateOutput from 'components/content/DateOutput';
import FindDriverSchedulesDialog from 'pages/dashboard/FindDriverSchedulesDialog';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';

const useStyles = makeStyles(theme => ({
	expandButton: {
		position: 'absolute',
		right: 0,
		top: 20,
	},
	header: {
		padding: theme.spacing(2),
		position: 'relative',
	},
	toggleButtonGroup: {
		left: 10,
		position: 'absolute',
		top: 20,
	},
}));

const DashboardDayHeader = () => {
	const {
		approvedMealProposals,
		controlsToShow,
		date,
		expanded,
		findDriverSchedulesDisabled,
		getCurrentDriverSchedules,
		mealProposals,
		numberOfDrivers,
		onSelectAllMeals,
		selectedMealProposals,
		setControlsToShow,
		setExpanded,
		setNumberOfDrivers,
		showCurrentSchedulesDisabled,
		showSchedulingControls,
	} = useDashboardDay();
	const { userCan } = useAuth();
	const { showDialog } = useStore();
	const classes = useStyles();

	return (
		<div className={classes.header}>
			<DateOutput
				variant="h5"
				align="center"
				date={date}
				formatter="date"
			/>
			<IconButton
				onClick={() => {
					setExpanded(expanded => !expanded);
					setControlsToShow(undefined);
				}}
				className={classes.expandButton}
			>
				{expanded ? <UnfoldLess /> : <UnfoldMore />}
			</IconButton>
			{userCan([
				'distances:calculate',
				'meal-proposals:find-driver-schedules',
			]) &&
				expanded && (
					<ToggleButtonGroup
						exclusive
						value={controlsToShow}
						onChange={(_e, controlsToShow) =>
							setControlsToShow(controlsToShow)
						}
						className={classes.toggleButtonGroup}
					>
						<ToggleButton value="schedulingControls">
							<Tooltip title="Scheduling Controls">
								<Timeline />
							</Tooltip>
						</ToggleButton>
						<ToggleButton value="dispatchControls">
							<Tooltip title="Dispatch Controls">
								<AccessAlarm />
							</Tooltip>
						</ToggleButton>
					</ToggleButtonGroup>
				)}
			{showSchedulingControls ? (
				<>
					<GridContainer
						justify="center"
						columns="none"
						alignItems="center"
					>
						<Typography>
							{selectedMealProposals.length} Meals Selected
						</Typography>
						<PrimaryButton
							onClick={onSelectAllMeals}
							startIcon={<Check />}
						>
							{selectedMealProposals.length ===
							approvedMealProposals.length
								? 'Deselect'
								: 'Select'}{' '}
							All Meals
						</PrimaryButton>
					</GridContainer>
					<GridContainer
						justify="center"
						columns="none"
						alignItems="center"
					>
						<IntegerInput
							value={numberOfDrivers}
							onValueChange={({ floatValue }) =>
								setNumberOfDrivers(floatValue)
							}
							label="Number of Drivers"
							icon={<People />}
						/>
						<PrimaryButton
							onClick={() =>
								showDialog(FindDriverSchedulesDialog, {
									mealProposalIds: selectedMealProposals.map(
										({ id }) => id
									),
									numberOfDrivers,
								})
							}
							disabled={findDriverSchedulesDisabled}
							startIcon={<GroupWork />}
						>
							Find Schedules
						</PrimaryButton>
						<PrimaryButton
							onClick={() =>
								showDialog(CurrentDriverSchedulesDialog, {
									currentDriverSchedules:
										getCurrentDriverSchedules(
											selectedMealProposals
										),
								})
							}
							disabled={showCurrentSchedulesDisabled}
							startIcon={<Timeline />}
						>
							Show Current Schedules
						</PrimaryButton>
					</GridContainer>
				</>
			) : (
				<Typography align="center">
					{`${approvedMealProposals.length} / ${mealProposals.length}`}{' '}
					Approved
				</Typography>
			)}
		</div>
	);
};

DashboardDayHeader.fragments = {
	details: gql`
		fragment DashboardDayHeader on MealProposals {
			MealProposalsDeliveryDrivers {
				DeliveryDriver {
					id
				}
			}
		}
	`,
};

export default DashboardDayHeader;
