import DeliveryDrivers from 'pages/delivery-drivers/DeliveryDrivers';
import Create from 'pages/delivery-drivers/Create';
import Edit from 'pages/delivery-drivers/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'deliveryDrivers',
		path: '',
		component: DeliveryDrivers,
		permissions: ['delivery-drivers:view'],
		exact: true,
	},
	{
		name: 'deliveryDriversCreate',
		path: '/create',
		component: Create,
		permissions: ['delivery-drivers:create'],
		exact: true,
	},
	{
		name: 'deliveryDriversEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['delivery-drivers:edit'],
		exact: true,
	},
];

export default prependUrlPath('delivery-drivers', routes);
