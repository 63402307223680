import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { put } from '@sporkbytes/api-client/MealOptions';

import NameForm from 'components/forms/NameForm';

import axios from 'services/data';

const MEAL_OPTION_QUERY = gql`
	query MealOptionEdit($id: uuid!) {
		MealOptions_by_pk(id: $id) {
			name
			MealProposal {
				orderNumberText
			}
		}
	}
`;

const MealOptionEditDialogForm = ({ id, ...props }) => {
	const { data, loading } = useQuery(MEAL_OPTION_QUERY, {
		variables: {
			id,
		},
	});

	return (
		<NameForm
			displayIn="dialog"
			initialValues={{
				name: data?.MealOptions_by_pk.name,
			}}
			onSubmit={values => put(axios, id, values)}
			loading={loading}
			title={`Edit ${data?.MealOptions_by_pk.name} for Meal Proposal ${data?.MealOptions_by_pk.MealProposal.orderNumberText}`}
			enableReinitialize
			{...props}
		/>
	);
};

MealOptionEditDialogForm.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MealOptionEditDialogForm;
