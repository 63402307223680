import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
	Form,
	GridContainer,
	LinkedCheckboxGroup,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import FormSectionHeader from 'components/forms/FormSectionHeader';

import * as yup from 'models/validation';

const ALL_MEAL_TYPES_QUERY = gql`
	query AllMealTypes {
		MealTypes(order_by: { sortOrder: asc }) {
			id
			name
		}
	}
`;

const PartnerListMenuForm = props => {
	const { loading, data } = useQuery(ALL_MEAL_TYPES_QUERY);

	const validationSchema = yup.object().shape({
		name: yup.name().required(),
		MealTypes: yup
			.array()
			.of(yup.string())
			.required('Please select at least one Meal Type.'),
	});

	return loading ? (
		<CenteredCircularProgress />
	) : (
		<Form {...props} validationSchema={validationSchema}>
			{() => (
				<>
					<GridContainer columns={2}>
						<LinkedTextInput name="name" autoFocus />
					</GridContainer>

					<FormSectionHeader>Public Partner List</FormSectionHeader>
					<GridContainer>
						<LinkedCheckboxGroup
							name="MealTypes"
							label="Check the boxes for all meal types you'd like to use this menu for on the Public Partner List."
							row
							options={data.MealTypes}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.id}
							getOptionKey={option => option.id}
						/>
					</GridContainer>
				</>
			)}
		</Form>
	);
};

export default PartnerListMenuForm;
