import React from 'react';
import { getItems } from '@sporkbytes/api-client/Accounting';
import {
	Form,
	GridContainer,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import AccountingSettingSelect from 'components/accounting-settings/AccountingSettingSelect';
import LoadingStateContainer from 'components/utilities/LoadingStateContainer';

import useRequest from 'hooks/useRequest';

import * as yup from 'models/validation';

import axios from 'services/data';

const validationSchema = yup.object().shape({
	id: yup
		.name()
		.matches(
			/^[a-z]+([A-Z][a-z]*)*$/,
			'Your id must be in a camelCase format.'
		)
		.required('Please enter an id.'),
	value: yup.name().required('Please enter a value.'),
	label: yup.name().required('Please enter a label.'),
});

const AccountingSettingForm = props => {
	const { data: AccountingItems, loading } = useRequest(() =>
		getItems(axios)
	);

	return (
		<LoadingStateContainer loading={loading}>
			<Form {...props} validationSchema={validationSchema}>
				{() => (
					<GridContainer>
						<LinkedTextInput name="id" autoFocus />
						<LinkedTextInput name="label" />
						<AccountingSettingSelect
							isLinked={true}
							name="value"
							options={AccountingItems}
						/>
					</GridContainer>
				)}
			</Form>
		</LoadingStateContainer>
	);
};

export default AccountingSettingForm;
