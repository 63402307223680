import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { sort } from '@sporkbytes/api-client/PartnerMenuItemOptionGroups';

import BooleanStatusIndicator from 'components/utilities/BooleanStatusIndicator';
import EditableSortOrder from 'components/forms/EditableSortOrder';
import PartnerMenuItemOptionGroupActionMenu from 'components/partner-menu-item-option-groups/PartnerMenuItemOptionGroupActionMenu';
import PartnerMenuItemsTable from 'components/partner-menu-item-option-groups/PartnerMenuItemsTable';
import RemoteTable from 'components/content/RemoteTable';

import { getMultiWordSearchWhereClause } from 'services/search';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const getPartnerMenuItemOptionGroupsQuery = searchTerm => gql`
	query PartnerMenuItemOptionGroups(
		$PartnerMenuItemId: uuid!
		$nameOrderBy: order_by
		$sortOrderOrderBy: order_by
		$checkOptionsByDefaultOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		PartnerMenuItemOptionGroups(
			where: {
				_and: [
					{ PartnerMenuItemId: { _eq: $PartnerMenuItemId } }
					${getMultiWordSearchWhereClause(searchTerm)}
				]
			}
			order_by: { 
				name: $nameOrderBy 
				sortOrder: $sortOrderOrderBy
				checkOptionsByDefault: $checkOptionsByDefaultOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			id
			name
			sortOrder
			checkOptionsByDefault
		}
		PartnerMenuItemOptionGroups_aggregate(
			where: {
				_and: [
					{ PartnerMenuItemId: { _eq: $PartnerMenuItemId } }
					${getMultiWordSearchWhereClause(searchTerm)}
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const PartnerMenuItemOptionGroupsTableTable = ({
	PartnerId,
	PartnerMenuItemId,
}) => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<RemoteTable
			tableRef={ref}
			columns={[
				{
					title: 'Sort Order',
					field: 'sortOrder',
					render: ({ id, sortOrder }) => (
						<EditableSortOrder
							onSave={value =>
								sort(axios, id, {
									newValue: value,
								}).then(ref.current.onQueryChange)
							}
							permissions={[
								'partner-menu-item-option-groups:edit',
							]}
							required={true}
							value={sortOrder}
						/>
					),
				},
				{
					title: 'Name',
					field: 'name',
					render: ({ name }) => name,
				},
				{
					title: 'Check Options by Default',
					field: 'checkOptionsByDefault',
					render: ({ checkOptionsByDefault }) => (
						<BooleanStatusIndicator value={checkOptionsByDefault} />
					),
				},
				{
					title: 'Actions',
					field: 'actions',
					sorting: false,
					render: partnerMenuItemOptionGroup => (
						<PartnerMenuItemOptionGroupActionMenu
							partnerMenuItemOptionGroup={
								partnerMenuItemOptionGroup
							}
						/>
					),
				},
			]}
			defaultOrderBy="sortOrder"
			defaultOrderDirection="asc"
			getQuery={getPartnerMenuItemOptionGroupsQuery}
			queryEntity="PartnerMenuItemOptionGroups"
			variables={{ PartnerMenuItemId }}
			addNewEntityRouteName="partnerMenuItemOptionGroupsCreate"
			addNewEntityRouteVariables={{ id: PartnerMenuItemId }}
			canAddNewEntity={userCan([
				'partner-menu-items:edit',
				'partner-menu-item-option-groups:create',
			])}
			entityToAddName="Option Group"
			detailPanel={({ id }) => (
				<PartnerMenuItemsTable
					PartnerMenuItemOptionGroupId={id}
					PartnerMenuItemId={PartnerMenuItemId}
					PartnerId={PartnerId}
				/>
			)}
		/>
	);
};

PartnerMenuItemOptionGroupsTableTable.propTypes = {
	PartnerMenuItemId: PropTypes.string.isRequired,
	PartnerId: PropTypes.string.isRequired,
};

export default PartnerMenuItemOptionGroupsTableTable;
