import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { formatZonedTime } from 'services/dates';

const DateOutput = ({ date, timezone, formatter, ...props }) => {
	return (
		<Typography variant="inherit" {...props}>
			{formatZonedTime(date, formatter, timezone)}
		</Typography>
	);
};

DateOutput.propTypes = {
	date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
		.isRequired,
	formatter: PropTypes.oneOf([
		'dateAndTime',
		'dateAndTimeMedium',
		'dateAndTimeShort',
		'date',
		'time',
	]),
	timezone: PropTypes.string,
};

DateOutput.defaultProps = {
	formatter: 'dateAndTime',
};

export default DateOutput;
