import React from 'react';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import { Dialog, PrimaryButton } from '@sporkbytes/material-ui-kit-react';

const CancellationPolicyDialog = ({ onClose, ...props }) => {
	return (
		<Dialog onClose={onClose} {...props}>
			<DialogContent>
				<Typography>
					Cancellations made within 48 hours of the delivery time are
					subject to a fee of up to 100% of the food subtotal. We make
					every effort to avoid or mitigate fees, but our partner
					restaurants use fresh and perishable ingredients which
					cannot usually be reused once purchased and put into
					production. Inclement weather cancellations policies are the
					same as the standard cancellation policy.
				</Typography>
			</DialogContent>
			<DialogActions>
				<PrimaryButton onClick={onClose}>Close</PrimaryButton>
			</DialogActions>
		</Dialog>
	);
};

export default CancellationPolicyDialog;
