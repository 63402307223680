import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@sporkbytes/material-ui-kit-react';

import UserFormElements, {
	validationObject,
} from 'components/users/UserFormElements';
import * as yup from 'models/validation';

const ContactForm = ({ initialValues, ...props }) => {
	const validationSchema = yup.object().shape({
		...validationObject,
		email: validationObject.email.unique({
			tableName: 'Contacts',
			fieldName: 'email',
			message:
				'This email is already in use. Please enter a different email address.',
			initialValue: initialValues.email,
		}),
	});

	return (
		<Form
			{...props}
			initialValues={initialValues}
			validationSchema={validationSchema}
		>
			{() => <UserFormElements />}
		</Form>
	);
};

ContactForm.propTypes = {
	initialValues: PropTypes.object,
};

ContactForm.defaultProps = {
	initialValues: {},
};

export default ContactForm;
