import React from 'react';
import { post } from '@sporkbytes/api-client/EmailTemplates';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import EmailTemplateForm from 'components/email-templates/EmailTemplateForm';

const Create = () => {
	return (
		<Page title="Add New Email Template">
			<PageHeader headerText="Add New Email Template" />
			<FormWrapper
				form={EmailTemplateForm}
				initialValues={{
					signature: 'Thank You!<br />Spork Bytes Team',
				}}
				onSubmit={post}
			/>
		</Page>
	);
};

export default Create;
