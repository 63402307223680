import React from 'react';
import gql from 'graphql-tag';
import {
	Card,
	CardActionArea,
	CardContent,
	CardHeader,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { Chip, Currency } from '@sporkbytes/material-ui-kit-react';

import FeedsNumber from 'components/content/FeedsNumber';
import RichText from 'components/content/RichText';

const useStyles = makeStyles(theme => ({
	container: {
		height: '100%',
	},
	dietaryTags: {
		marginTop: theme.spacing(2),
	},
}));

const PartnerMenuItemCard = ({ onClick, partnerMenuItem }) => {
	const classes = useStyles();
	const dietaryTags = partnerMenuItem.PartnerMenuItemsDietaryTags.flatMap(
		({ DietaryTag }) => DietaryTag
	);

	const Wrapper = ({ children }) =>
		!!onClick ? (
			<CardActionArea
				onClick={onClick}
				key={partnerMenuItem.id}
				className={classes.container}
			>
				{children}
			</CardActionArea>
		) : (
			<>{children}</>
		);

	return (
		<Wrapper>
			<Card variant="outlined" className={classes.container}>
				<CardHeader
					title={
						<Typography variant="h6" component="h4">
							{partnerMenuItem.friendlyName}{' '}
							{partnerMenuItem.size &&
								` (${partnerMenuItem.size}) `}
							<Currency value={partnerMenuItem.cost} />
						</Typography>
					}
					subheader={
						<FeedsNumber
							feedsMinimum={partnerMenuItem.feedsMinimum}
							feedsMaximum={partnerMenuItem.feedsMaximum}
						/>
					}
				/>
				<CardContent>
					<RichText body={partnerMenuItem.description} />
					{dietaryTags.length > 0 && (
						<div className={classes.dietaryTags}>
							{dietaryTags.map(({ name }) => (
								<Chip key={name} label={name} />
							))}
						</div>
					)}
				</CardContent>
			</Card>
		</Wrapper>
	);
};

PartnerMenuItemCard.fragments = {
	details: gql`
		fragment PartnerMenuItemCardDetails on PartnerMenuItems {
			id
			friendlyName
			description
			cost
			size
			feedsMinimum
			feedsMaximum
			PartnerMenuItemsDietaryTags(
				order_by: { DietaryTag: { sortOrder: asc } }
			) {
				DietaryTag {
					name
					sortOrder
				}
			}
		}
	`,
};

export default PartnerMenuItemCard;
