import React from 'react';
import {
	Form,
	GridContainer,
	LinkedCheckbox,
	LinkedRichTextEditor,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import * as yup from 'models/validation';

const PartnerMenuItemOptionForm = props => {
	const validationSchema = yup.object().shape({
		name: yup.name().required(),
		howToOrder: yup.string(),
		checkOptionsByDefault: yup.boolean(),
	});

	return (
		<Form {...props} validationSchema={validationSchema}>
			{() => (
				<>
					<GridContainer columns={2}>
						<LinkedTextInput name="name" autoFocus />
						<LinkedCheckbox
							name="checkOptionsByDefault"
							label="Check Options by Default"
						/>
					</GridContainer>
					<GridContainer>
						<LinkedRichTextEditor name="howToOrder" />
					</GridContainer>
				</>
			)}
		</Form>
	);
};

export default PartnerMenuItemOptionForm;
