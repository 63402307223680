import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { endOfDay, format, parseISO, startOfDay } from 'date-fns';
import { makeStyles } from '@material-ui/core';
import { DateTimePicker } from '@sporkbytes/material-ui-kit-react';

import BagTag from 'components/bag-tags/BagTag';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PrintablePage from 'components/layout/PrintablePage';
import PrintButton from 'components/utilities/PrintButton';

import { orientationOptions } from 'services/printing';

const MEAL_PROPOSAL_QUERY = gql`
	query MealProposalPrintableLabels(
		$startDate: timestamptz!
		$endDate: timestamptz!
	) {
		MealProposals(
			where: {
				deliveryDate: { _gte: $startDate, _lte: $endDate }
				MealOptions: { approved: { _eq: true } }
				status: { _nin: ["Canceled", "Draft"] }
			}
			order_by: { deliveryDate: asc }
		) {
			id
			...BagTagDetails
		}
	}
	${BagTag.fragments.details}
`;

const useStyles = makeStyles(theme => ({
	hideFromPrint: {
		marginBottom: theme.spacing(2),
		'@media print': {
			display: 'none',
		},
	},
}));

const BagTagsDay = () => {
	const { day } = useParams();
	const classes = useStyles();
	const history = useHistory();
	const title = `Bag Tags for ${format(parseISO(day), 'EEEE, MMMM d, yyyy')}`;

	return (
		<Page
			query={MEAL_PROPOSAL_QUERY}
			variables={{
				startDate: startOfDay(parseISO(day)),
				endDate: endOfDay(parseISO(day)),
			}}
			title={`Print ${title}`}
		>
			{({ data: { MealProposals } }) => (
				<>
					<div className={classes.hideFromPrint}>
						<DateTimePicker
							value={day}
							dateOnly
							label="Day"
							onChange={date =>
								history.pushNamedRoute('bagTagsDay', {
									day: format(date, 'yyyy-MM-dd'),
								})
							}
							className={classes.datePicker}
						/>
						<PageHeader headerText={title} />
						<PrintButton
							orientation={orientationOptions.LANDSCAPE}
						/>
					</div>
					<PrintablePage orientation={orientationOptions.LANDSCAPE}>
						{MealProposals.map(({ id, ...mealProposal }) => (
							<BagTag mealProposal={mealProposal} key={id} />
						))}
					</PrintablePage>
				</>
			)}
		</Page>
	);
};

export default BagTagsDay;
