import React, { useState } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { getForm, post } from '@sporkbytes/api-client/PublicSporkReviews';

import LoadingPage from 'components/content/LoadingPage';
import NoReviewableMealFoundPage from 'components/content/NoReviewableMealFoundPage';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import ReviewThankYou from 'components/content/ReviewThankYou';
import SporkReviewForm from 'components/spork-reviews/SporkReviewForm';
import withRecaptcha from 'components/forms/withRecaptcha';

import useQueryString from 'hooks/useQueryString';
import useRequest from 'hooks/useRequest';

import axios from 'services/data';

const SporkReview = ({ getRecaptchaToken, recaptchaText }) => {
	const [{ publicSporkReviewId }] = useQueryString();
	const [reviewSubmitted, setReviewSubmitted] = useState(false);

	const { data, loading } = useRequest(() =>
		getForm(axios, { publicSporkReviewId })
	);

	if (loading) {
		return <LoadingPage />;
	}

	if (data === 'No meal found') {
		return <NoReviewableMealFoundPage />;
	}

	if (reviewSubmitted) {
		return <ReviewThankYou />;
	}

	const { orderNumberText, sporkReviewQuestions } = data;

	return (
		<Page title="Leave a Review">
			<Card>
				<CardContent>
					<PageHeader
						headerText={`Let us know how we did with order ${orderNumberText}`}
					/>
					<PageSubheader>
						Please tell us what your experience was like with Spork.
					</PageSubheader>
					<SporkReviewForm
						sporkReviewQuestions={sporkReviewQuestions}
						onSubmit={async values => {
							const recaptchaResponse = await getRecaptchaToken();

							await post(axios, publicSporkReviewId, {
								...values,
								recaptchaResponse,
							});

							setReviewSubmitted(true);
						}}
						initialValues={{
							comments: '',
							sporkReviewQuestions: sporkReviewQuestions.map(
								({ id }) => ({
									id,
									answer: '',
								})
							),
						}}
					/>
					{recaptchaText}
				</CardContent>
			</Card>
		</Page>
	);
};

export default withRecaptcha(SporkReview);
