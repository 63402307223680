import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import {
	makeStyles,
	Table,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
} from '@material-ui/core';

import PurchaseOrderFoodSubtotalLineItem from 'components/purchase-orders/purchase-order-summary-table/PurchaseOrderFoodSubtotalLineItem';
import PurchaseOrderMenuSectionLineItems from 'components/purchase-orders/purchase-order-summary-table/PurchaseOrderMenuSectionLineItems';
import PurchaseOrderPayoutAdjustmentLineItems from 'components/purchase-orders/purchase-order-summary-table/PurchaseOrderPayoutAdjustmentLineItems';
import PurchaseOrderSporkCommissionLineItem from 'components/purchase-orders/purchase-order-summary-table/PurchaseOrderSporkCommissionLineItem';
import PurchaseOrderTotalReceivedByPartnerLineItem from 'components/purchase-orders/purchase-order-summary-table/PurchaseOrderTotalReceivedByPartnerLineItem';

import { PurchaseOrderContextProvider } from 'context/PurchaseOrderContext';

const useStyles = makeStyles(theme => ({
	container: {
		background: theme.palette.common.white,
		margin: theme.spacing(2, 0, 6),
	},
}));

const PurchaseOrderSummaryTable = ({
	costType,
	purchaseOrder,
	showDiscount,
	showPartnerMetrics,
	showPrices,
	showSporkMetrics,
}) => {
	const classes = useStyles();

	return (
		<PurchaseOrderContextProvider purchaseOrder={purchaseOrder}>
			<TableContainer className={classes.container}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Item</TableCell>
							<TableCell align="right">Quantity</TableCell>
							{showPrices && (
								<TableCell align="right">Cost</TableCell>
							)}
							{showDiscount && showPrices && (
								<TableCell align="right">Discount</TableCell>
							)}
							{showPrices && (
								<TableCell
									align="right"
									colSpan={showDiscount ? 1 : 2}
								>
									Subtotal
								</TableCell>
							)}
						</TableRow>
					</TableHead>
					<PurchaseOrderMenuSectionLineItems
						purchaseOrder={purchaseOrder}
						costType={costType}
						showDiscount={showDiscount}
						showPrices={showPrices}
					/>
					{showPrices && (
						<TableFooter>
							<PurchaseOrderFoodSubtotalLineItem
								purchaseOrder={purchaseOrder}
								costType={costType}
							/>
							{showSporkMetrics && (
								<PurchaseOrderSporkCommissionLineItem
									purchaseOrder={purchaseOrder}
								/>
							)}
							{showPartnerMetrics && (
								<>
									<PurchaseOrderPayoutAdjustmentLineItems
										purchaseOrder={purchaseOrder}
									/>
									<PurchaseOrderTotalReceivedByPartnerLineItem
										key={purchaseOrder.id}
										purchaseOrder={purchaseOrder}
									/>
								</>
							)}
						</TableFooter>
					)}
				</Table>
			</TableContainer>
		</PurchaseOrderContextProvider>
	);
};

PurchaseOrderSummaryTable.fragments = {
	details: gql`
		fragment PurchaseOrderSummaryTableDetails on PurchaseOrders {
			id
			...PurchaseOrderFoodSubtotalLineItemDetails
			...PurchaseOrderMenuSectionLineItemsDetails
			...PurchaseOrderPayoutAdjustmentLineItemsDetails
			...PurchaseOrderSporkCommissionLineItemDetails
			...PurchaseOrderTotalReceivedByPartnerLineItemDetails
		}
		${PurchaseOrderFoodSubtotalLineItem.fragments.details}
		${PurchaseOrderMenuSectionLineItems.fragments.details}
		${PurchaseOrderPayoutAdjustmentLineItems.fragments.details}
		${PurchaseOrderSporkCommissionLineItem.fragments.details}
		${PurchaseOrderTotalReceivedByPartnerLineItem.fragments.details}
	`,
};

PurchaseOrderSummaryTable.propTypes = {
	costType: PropTypes.oneOf(['costToClient', 'paidToPartner']),
	showDiscount: PropTypes.bool,
	showPartnerMetrics: PropTypes.bool,
	showPrices: PropTypes.bool,
	showSporkMetrics: PropTypes.bool,
};

PurchaseOrderSummaryTable.defaultProps = {
	costType: 'costToClient',
	showDiscount: true,
	showPartnerMetrics: true,
	showPrices: true,
	showSporkMetrics: true,
};

export default PurchaseOrderSummaryTable;
