import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash.startcase';
import { Avatar } from '@sporkbytes/material-ui-kit-react';

import theme from 'theme';

const NamedAvatar = forwardRef(({ alt, imageUrl, name, ...props }, ref) =>
	!!imageUrl ? (
		<Avatar alt={alt} src={imageUrl} size="small" {...props} ref={ref} />
	) : (
		<Avatar
			size="small"
			backgroundColor={theme.palette.grey[200]}
			{...props}
			ref={ref}
		>
			{startCase(name)
				.split(' ')
				.map(word => word[0])
				.join('')}
		</Avatar>
	)
);

NamedAvatar.propTypes = {
	alt: PropTypes.string,
	imageUrl: PropTypes.string,
	name: PropTypes.string,
};

export default NamedAvatar;
