import React from 'react';
import gql from 'graphql-tag';
import { TableRow } from '@material-ui/core';
import { costPerPerson } from '@sporkbytes/meal-option-utils';

import TableCellIndented from 'components/content/TableCellIndented';
import TableCellNumeric from 'components/content/TableCellNumeric';

const MealOptionCostPerPersonLineItem = ({ mealOption }) => (
	<TableRow>
		<TableCellIndented colSpan="3">Per Person</TableCellIndented>
		<TableCellNumeric
			format="currency"
			value={costPerPerson(mealOption, mealOption.MealProposal)}
		/>
	</TableRow>
);

MealOptionCostPerPersonLineItem.fragments = {
	details: gql`
		fragment MealOptionCostPerPersonLineItemDetails on MealOptions {
			PurchaseOrders(order_by: { pickupTime: asc }) {
				PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
					PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
						costToClient
						discount
						quantity
					}
				}
			}
			MealProposal {
				headCount
				MealProposalFees {
					amount
					discount
					type
				}
			}
		}
	`,
};

export default MealOptionCostPerPersonLineItem;
