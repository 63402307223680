import React from 'react';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import Page from 'components/layout/Page';

const LoadingPage = props => {
	return (
		<Page title="Loading">
			<CenteredCircularProgress />
		</Page>
	);
};

export default LoadingPage;
