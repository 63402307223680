import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	sectionHeader: {
		margin: theme.spacing(6, 0, 1, 0),
	},
}));

const FormSectionHeader = ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.sectionHeader}>
			<Typography variant="h5">{children}</Typography>
			<Divider />
		</div>
	);
};

FormSectionHeader.propTypes = {
	children: PropTypes.string.isRequired,
};

export default FormSectionHeader;
