import React from 'react';
import PropTypes from 'prop-types';

import ActionMenu from 'components/utilities/ActionMenu';

import { createEditAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';

const DeliveryGearActionMenu = ({ deliveryGear: { id, name } }) => {
	const { userCan } = useAuth();
	const entityType = 'DeliveryGear';

	return (
		<ActionMenu
			options={[
				createEditAction({
					entityId: id,
					entityName: name,
					entityType,
					userCan,
				}),
			]}
		/>
	);
};

DeliveryGearActionMenu.propTypes = {
	onActionComplete: PropTypes.func,
	deliveryGear: PropTypes.shape({
		name: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
	}).isRequired,
};

export default DeliveryGearActionMenu;
