import Detail from 'pages/partner-menu-items/Detail';
import Duplicate from 'pages/partner-menu-items/Duplicate';
import Edit from 'pages/partner-menu-items/Edit';
import PartnerMenuItemOptionGroupCreate from 'pages/partner-menu-items/PartnerMenuItemOptionGroupCreate';
import PrintableLabel from 'pages/partner-menu-items/PrintableLabel';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'partnerMenuItemsDetail',
		path: '/:id/detail',
		component: Detail,
		permissions: ['partner-menu-items:view'],
		exact: true,
	},
	{
		name: 'partnerMenuItemsDuplicate',
		path: '/:id/duplicate',
		component: Duplicate,
		permissions: ['partner-menu-items:create'],
		exact: true,
	},
	{
		name: 'partnerMenuItemsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['partner-menu-items:edit'],
		exact: true,
	},
	{
		name: 'partnerMenuItemOptionGroupsCreate',
		path: '/:id/partner-menu-item-option-groups/create',
		component: PartnerMenuItemOptionGroupCreate,
		permissions: [
			'partner-menu-items:edit',
			'partner-menu-item-option-groups:create',
		],
		exact: true,
	},
	{
		name: 'partnerMenuItemsPrintableLabel',
		path: '/:id/printable-label',
		component: PrintableLabel,
		permissions: ['partner-menu-items:view'],
		exact: true,
	},
];

export default prependUrlPath('partner-menu-items', routes);
