import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { format, parseISO } from 'date-fns';
import { makeStyles } from '@material-ui/core';
import { getPartnerNames } from '@sporkbytes/purchase-order-utils';

const useStyles = makeStyles(theme => ({
	container: {
		border: `1px solid ${theme.palette.common.black}`,
		display: 'inline-flex',
		flexDirection: 'column',
		fontSize: theme.typography.caption.fontSize,
		height: '4.25in',
		margin: 0,
		padding: '0.1in',
		width: '3.6666667in',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		width: '50%',
	},
	cutOffText: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	fillVertical: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	mockCheckbox: {
		'&:before': {
			border: `1px solid ${theme.palette.common.black}`,
			content: `''`,
			display: 'inline-block',
			height: '1em',
			marginRight: theme.spacing(1),
			verticalAlign: 'middle',
			width: '1em',
		},
	},
	row: {
		display: 'flex',
		'&:not(:first-child)': {
			marginTop: theme.spacing(1),
		},
	},
	rowBottom: {
		marginTop: 'auto',
	},
	term: {
		fontWeight: 'bold',
		textDecoration: 'underline',
		'&:not(:first-child)': {
			marginTop: theme.spacing(1),
		},
	},
	value: {
		marginLeft: 0,
	},
}));

const CUTLERY_OPTIONS = ['Spoons'];
const EXTRAS_OPTIONS = [
	'Half Pan',
	'Third Pan',
	'Bagel Spreaders',
	'Chip Bowl Small ',
	'Chip Bowl Large',
	'Platters Small',
	'Platters Large',
	'Small Plate',
	'To-Go Boxes',
	'____________________',
	'____________________',
];
const PRE_DEFINED_NOTES_OPTIONS = ['Mesh bag only'];

const BagTag = ({ mealProposal }) => {
	const classes = useStyles();

	return (
		<dl className={classes.container}>
			<div className={classes.row}>
				<dt className={classes.term}>Order Number:</dt>
				<dd className={classes.value} style={{ paddingLeft: 8 }}>
					{mealProposal.orderNumberText}
				</dd>
			</div>
			<div className={classes.row}>
				<div className={classes.column}>
					<dt className={classes.term}>Day/Date:</dt>
					<dd className={classes.value}>
						{format(
							parseISO(mealProposal.deliveryDate),
							'eeee M/dd'
						)}
					</dd>
				</div>
				<div className={classes.column}>
					<dt className={classes.term}>Head Count:</dt>
					<dd className={classes.value}>{mealProposal.headCount}</dd>
				</div>
			</div>
			<div className={classes.row}>
				<div className={classes.column}>
					<dt className={classes.term}>Client:</dt>
					<dd className={`${classes.value} ${classes.cutOffText}`}>
						{mealProposal.ClientLocation.Client.name}
					</dd>
				</div>
				<div className={classes.column}>
					<dt className={classes.term}>
						Partner
						{mealProposal.MealOptions[0].PurchaseOrders.length >
						1 ? (
							<span>s</span>
						) : null}
						:
					</dt>
					<dd className={`${classes.value} ${classes.cutOffText}`}>
						{getPartnerNames(
							mealProposal.MealOptions[0].PurchaseOrders
						)}
					</dd>
				</div>
			</div>
			<div className={classes.row}>
				<div className={classes.column}>
					<dt className={classes.term}>Cutlery:</dt>
					{CUTLERY_OPTIONS.map(label => (
						<dd
							className={`${classes.value} ${classes.mockCheckbox}`}
							key={label}
						>
							{label}
						</dd>
					))}
					<div className={classes.term}>Notes:</div>
					{PRE_DEFINED_NOTES_OPTIONS.map(label => (
						<dd
							className={`${classes.value} ${classes.mockCheckbox}`}
							key={label}
						>
							{label}
						</dd>
					))}
				</div>
				<div className={classes.column}>
					<dt className={classes.term}>Extras:</dt>
					{EXTRAS_OPTIONS.map(label => (
						<dd
							className={`${classes.value} ${classes.mockCheckbox}`}
							key={label}
						>
							___{'  '}
							{label}
						</dd>
					))}
				</div>
			</div>
			<div className={classes.rowBottom}>
				<div className={classes.fillVertical}>
					<div>
						<span className={classes.term}>INITIALS:</span>
						&nbsp;&nbsp;_________________
					</div>
				</div>
			</div>
		</dl>
	);
};

BagTag.fragments = {
	details: gql`
		fragment BagTagDetails on MealProposals {
			orderNumberText
			deliveryDate
			headCount
			ClientLocation {
				Client {
					name
				}
			}
			MealOptions(where: { approved: { _eq: true } }) {
				PurchaseOrders(order_by: { pickupTime: asc }) {
					PartnerLocation {
						Partner {
							name
						}
					}
				}
			}
		}
	`,
};

BagTag.propTypes = {
	mealProposal: PropTypes.object.isRequired,
};

export default BagTag;
