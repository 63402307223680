import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/Partners';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PartnerForm from 'components/partners/PartnerForm';

// TODO: use fragments here
const PARTNER_QUERY = gql`
	query PartnerEdit($id: uuid!) {
		Partners_by_pk(id: $id) {
			logoUrl
			name
			description
			reviewable
			photoUrl
			newPartnerExpirationDate
			landingPageSlug
			PartnersPartnerTags {
				PartnerTagId
			}
			SporkLocationsPartners {
				SporkLocationId
			}
		}
	}
`;

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ Partners_by_pk: { name } }) => `Edit ${name}`}
			query={PARTNER_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					// TODO: improve how we destructure and pass initialValues
					Partners_by_pk: {
						logoUrl,
						name,
						description,
						reviewable,
						photoUrl,
						newPartnerExpirationDate,
						landingPageSlug,
						PartnersPartnerTags,
						SporkLocationsPartners,
					},
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${name}`} />
					<FormWrapper
						form={PartnerForm}
						initialValues={{
							logoUrl,
							name,
							description,
							reviewable,
							photoUrl,
							newPartnerExpirationDate,
							landingPageSlug,
							PartnerTags: PartnersPartnerTags.map(
								({ PartnerTagId }) => PartnerTagId
							),
							SporkLocations: SporkLocationsPartners.map(
								({ SporkLocationId }) => SporkLocationId
							),
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
