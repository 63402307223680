import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';
import {
	Currency,
	Percent,
	TextFormatter,
} from '@sporkbytes/material-ui-kit-react';

const TableCellNumeric = ({ children, format, value, ...props }) => {
	const formatToComponentMap = {
		currency: Currency,
		number: props => <TextFormatter thousandSeparator={true} {...props} />,
		percent: Percent,
	};
	const FormatComponent = formatToComponentMap[format];

	return (
		<TableCell align="right" {...props}>
			<FormatComponent value={value} />
		</TableCell>
	);
};

TableCellNumeric.propTypes = {
	format: PropTypes.oneOf(['currency', 'number', 'percent']),
	value: PropTypes.number,
};

TableCellNumeric.defaultProps = {
	format: 'number',
};

export default TableCellNumeric;
