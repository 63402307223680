import { useEffect } from 'react';
import { matchPath } from 'react-router';
import { parse } from 'qs';

import history from 'services/history';

const redirects = [
	{
		old: '#/public/client-location/:publicMenuProfileId/menu-profile',
		new: params =>
			`/public/client-locations/menu-profile?publicMenuProfileId=${params.publicMenuProfileId}`,
	},
	{
		old: '#/public/client-location-reviewable-meals',
		new: params =>
			`/public/client-locations/reviewable-meals-profile?publicMenuProfileId=${params.publicMenuProfileId}`,
	},
	{
		old: '#/public/contact/:id/profile',
		new: params => `/public/contacts/${params.id}/profile`,
	},
	{
		old: '#/public/meal-menu-review',
		new: params =>
			`/public/meal-menu-review?mealMenuId=${params.mealMenuId}`,
	},
	{
		old: '#/public/meal-menu/:id',
		new: params => `/public/meal-menu/${params.id}`,
	},
	{
		old: '#/public/meal-proposal/:id',
		new: params => `/public/meal-proposals/${params.id}/detail`,
	},
	{
		old: '#/public/purchase-order/:id',
		new: params => `/public/purchase-orders/${params.id}/detail`,
	},
	{
		old: '#/public/spork-review',
		new: params =>
			`/public/spork-review?publicSporkReviewId=${params.publicSporkReviewId}`,
	},
];

const useHashUrlRedirect = () => {
	useEffect(() => {
		if (history.location.hash) {
			for (let redirect of redirects) {
				const [path, queryString] = history.location.hash.split('?');
				const matchedPath = matchPath(path, {
					path: redirect.old,
				});
				const matchedParams = {
					...(matchedPath?.params ?? {}),
					...(parse(queryString) ?? {}),
				};

				if (matchedPath?.isExact) {
					history.replace(redirect.new(matchedParams));
					return;
				}
			}

			history.push404();
		}
	}, []);
};

export default useHashUrlRedirect;
