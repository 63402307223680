import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Create, ImportExport } from '@material-ui/icons';
import { IconButton, Tooltip, Grid } from '@material-ui/core';
import {
	Currency,
	Form,
	Percent,
	LinkedCurrencyInput,
	LinkedIntegerInput,
	LinkedPercentInput,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';
import startCase from 'lodash.startcase';

import NoContentText from 'components/content/NoContentText';

import { useAuth } from 'services/auth';
import * as yup from 'models/validation';

const EditableValue = ({
	helperText,
	inputLabel,
	name,
	onSave,
	permissions,
	required,
	submitButtonContent,
	tooltipText,
	type,
	value,
	validation,
}) => {
	const { userCannot } = useAuth();
	const [isEditing, setIsEditing] = useState(false);

	const typeOptionsMap = {
		currency: {
			Input: LinkedCurrencyInput,
			Output: Currency,
			typeof: 'number',
			validation: validation ?? yup.positiveNumber(),
		},
		name: {
			Input: LinkedTextInput,
			typeof: 'string',
			validation: validation ?? yup.name(),
		},
		percent: {
			Input: LinkedPercentInput,
			Output: Percent,
			typeof: 'number',
			validation: validation ?? yup.nonNegativeNumber(),
		},
		sortOrder: {
			Icon: ImportExport,
			Input: LinkedIntegerInput,
			typeof: 'number',
			validation: validation ?? yup.positiveInteger(),
		},
	};
	const {
		Icon = Create,
		Input,
		Output = ({ value }) => value,
	} = typeOptionsMap[type];

	const validationSchema = yup.object().shape({
		value: required
			? typeOptionsMap[type].validation.required(`Please enter a value.`)
			: typeOptionsMap[type].validation,
	});

	const valueOutput =
		typeof value === typeOptionsMap[type].typeof ? (
			<Output value={value} renderText={value => value} />
		) : (
			<NoContentText>no value</NoContentText>
		);

	if (userCannot(permissions)) {
		return valueOutput;
	}

	return isEditing ? (
		<Form
			initialValues={{ value }}
			onCancel={() => setIsEditing(false)}
			onSubmit={({ value }, formikBag) => {
				onSave(typeof value === 'undefined' ? null : value)
					.then(() => {
						formikBag.setSubmitting(false);
						setIsEditing(false);
					})
					.catch(() => {
						formikBag.setSubmitting(false);
					});
			}}
			submitButtonContent={submitButtonContent}
			validationSchema={validationSchema}
		>
			{() => (
				<Input
					autoFocus
					name="value"
					label={inputLabel ?? `Edit ${startCase(name)}`}
					helperText={helperText}
				/>
			)}
		</Form>
	) : (
		<Grid
			alignItems="center"
			container
			justify={type === 'sortOrder' ? 'flex-start' : 'space-between'}
			wrap="nowrap"
		>
			{valueOutput}
			<Tooltip title={tooltipText ?? `Edit ${startCase(name)}`}>
				<IconButton onClick={() => setIsEditing(true)}>
					<Icon />
				</IconButton>
			</Tooltip>
		</Grid>
	);
};

EditableValue.propTypes = {
	helperText: PropTypes.string,
	inputLabel: PropTypes.string,
	name: PropTypes.string.isRequired,
	onSave: PropTypes.func.isRequired, // must return a Promise
	permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
	required: PropTypes.bool,
	submitButtonContent: PropTypes.string,
	tooltipText: PropTypes.string,
	type: PropTypes.oneOf(['currency', 'name', 'percent', 'sortOrder']),
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	validation: PropTypes.object,
};

EditableValue.defaultProps = {
	required: false,
	submitButtonContent: 'Save',
};

export default EditableValue;
