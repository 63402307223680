import React from 'react';
import gql from 'graphql-tag';
import { makeStyles, Typography } from '@material-ui/core';
import { shortLinkDomain } from '@sporkbytes/config';

import ReviewQRCode from 'components/content/ReviewQRCode';
import RichText from 'components/content/RichText';

import { getThemedLogo } from 'services/sporkMetadata';
import { GridContainer } from '@sporkbytes/material-ui-kit-react';

const useStyles = makeStyles(theme => ({
	container: {
		alignItems: 'center',
		backgroundColor: theme.palette.common.white,
		display: 'flex',
		flexDirection: 'column',
		height: '11in',
		justifyContent: 'space-between',
		margin: '0 auto',
		overflow: 'hidden',
		padding: '0.25in',
		width: '8.5in',
	},
	footer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	partner: {
		padding: theme.spacing(2),
		width: ({ numberOfPartners }) => {
			const numberOfRows = numberOfPartners > 2 ? 2 : 1;
			const percentWidth = Math.floor(
				100 / Math.ceil(numberOfPartners / numberOfRows)
			);

			return `${percentWidth}%`;
		},
	},
	partners: {
		alignItems: 'baseline',
		display: 'flex',
		flexWrap: 'wrap',
		textAlign: 'center',
	},
	partnerDescription: {
		marginTop: theme.spacing(1),
		'&::before': {
			content: '"\u201C"',
		},
		'&::after': {
			content: '"\u201D"',
		},
	},
	partnerLogo: {
		marginBottom: theme.spacing(1),
		maxHeight: ({ numberOfPartners }) =>
			numberOfPartners > 2 ? 100 : undefined,
		maxWidth: ({ numberOfPartners }) =>
			numberOfPartners > 2 ? '100%' : 200,
	},
	sporkLogo: {
		display: 'block',
		maxWidth: ({ numberOfPartners }) => (numberOfPartners > 2 ? 150 : 200),
	},
}));

const MealProposalTableTopper = ({ mealProposal }) => {
	// Filter by reviewable with this method so we can use our fragment on other pages, otherwise we'd have to run a separate query for this page with the where clause
	const reviewablePartners =
		mealProposal.MealOptions[0].PurchaseOrders.filter(
			({ reviewable }) => reviewable
		);
	const numberOfPartners = reviewablePartners.length;
	const largeTextSize = numberOfPartners > 2 ? 'h6' : 'h4';
	const smallTextSize = numberOfPartners > 2 ? 'body1' : 'h6';
	const classes = useStyles({ numberOfPartners });

	return (
		<div className={classes.container}>
			<div className={classes.partners}>
				{reviewablePartners.map(({ PartnerLocation }) => (
					<div
						className={classes.partner}
						key={PartnerLocation.Partner.name}
					>
						<img
							alt={PartnerLocation.Partner.name}
							src={PartnerLocation.Partner.logoUrl}
							className={classes.partnerLogo}
						/>
						<Typography variant={largeTextSize}>
							{PartnerLocation.Partner.name}
						</Typography>
						<Typography variant={smallTextSize}>
							{PartnerLocation.streetAddress}
						</Typography>
						{numberOfPartners <= 2 && (
							<RichText
								body={PartnerLocation.Partner.description}
								className={classes.partnerDescription}
							/>
						)}
					</div>
				))}
			</div>
			<GridContainer>
				<div>
					<Typography
						variant={largeTextSize}
						gutterBottom
						align="center"
					>
						A Friendly Reminder
					</Typography>
					<Typography variant={smallTextSize} align="center">
						The delicious food you're about to enjoy comes from a
						partner kitchen that handles various ingredients. While
						we've done our best to keep things like nuts, gluten,
						and other allergens separate, there may be some
						cross-contamination. Please enjoy the buffet and choose
						what's right for you! Eat, enjoy, and stay safe!
					</Typography>
				</div>
				<ReviewQRCode
					qrCodeValue={`${shortLinkDomain}/meal-menu-review?mealMenuId=${mealProposal.MealOptions[0].mealMenuId}`}
					headerProps={{
						variant: largeTextSize,
					}}
					subheaderProps={{
						variant: smallTextSize,
					}}
				/>
			</GridContainer>
			<div className={classes.footer}>
				<img
					src={getThemedLogo()}
					alt="Spork Bytes"
					className={classes.sporkLogo}
				/>
				<Typography>{mealProposal.orderNumberText}</Typography>
			</div>
		</div>
	);
};

MealProposalTableTopper.fragments = {
	details: gql`
		fragment MealProposalTableTopperDetails on MealProposals {
			orderNumberText
			MealOptions(where: { approved: { _eq: true } }) {
				mealMenuId
				PurchaseOrders(order_by: { pickupTime: asc }) {
					reviewable
					PartnerLocation {
						streetAddress
						Partner {
							description
							logoUrl
							name
						}
					}
				}
			}
		}
	`,
};

export default MealProposalTableTopper;
