import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

import DateOutput from 'components/content/DateOutput';

const useStyles = makeStyles(theme => ({
	container: {
		alignItems: 'center',
		display: 'flex',
	},
	date: {
		textAlign: 'right',
		minWidth: 100,
		whiteSpace: 'nowrap',
		'&::after': {
			content: '"\u00a0\u2014\u00a0"',
		},
	},
}));

const TimeAndPlace = ({ stacked, place, time, timezone }) => {
	const classes = useStyles();

	return stacked ? (
		<>
			<DateOutput
				date={time}
				timezone={timezone}
				formatter="time"
				variant="body1"
				align="center"
			/>
			<Typography component="div" align="center">
				{place}
			</Typography>
		</>
	) : (
		<div className={classes.container}>
			<Typography className={classes.date}>
				<DateOutput date={time} timezone={timezone} formatter="time" />
			</Typography>

			<Typography component="div">{place}</Typography>
		</div>
	);
};

TimeAndPlace.propTypes = {
	place: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	stacked: PropTypes.bool,
	time: PropTypes.string.isRequired,
	timezone: PropTypes.string.isRequired,
};

export default TimeAndPlace;
