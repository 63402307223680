import React from 'react';
import {
	Form,
	GridContainer,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import { LinkedS3Upload } from 'components/forms/S3Upload';

import * as yup from 'models/validation';

const ClientForm = props => {
	const validationSchema = yup.object().shape({
		logoUrl: yup.string(),
		name: yup.name().required(),
	});

	return (
		<Form {...props} validationSchema={validationSchema}>
			{() => (
				<GridContainer columns={3}>
					<LinkedTextInput name="name" autoFocus />
					<LinkedS3Upload
						name="logoUrl"
						label="Choose a Logo"
						path="logos/clients/"
					/>
				</GridContainer>
			)}
		</Form>
	);
};

export default ClientForm;
