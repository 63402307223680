import React from 'react';
import {
	Create,
	DeleteForever,
	DirectionsCar,
	Email,
	FileCopy,
	Phone,
	RemoveCircle,
} from '@material-ui/icons';
import identity from 'lodash.identity';
import camelCase from 'lodash.camelcase';
import kebabCase from 'lodash.kebabcase';
import startCase from 'lodash.startcase';
import {
	ConfirmationDialog,
	DeleteDialog,
} from '@sporkbytes/material-ui-kit-react';

import ToggleIcon from 'components/utilities/ToggleIcon';

import { store } from 'models/store';

import {
	callPhoneNumber,
	copyText,
	getDirections,
	sendEmail,
} from 'services/utilities';
import axios from 'services/data';
import history from 'services/history';

const { showDialog } = store.getState();

export const createCallPhoneNumberAction = ({
	entityName,
	phoneNumber,
	...args
}) => ({
	label: `Call ${entityName}`,
	icon: <Phone />,
	onClick: () => callPhoneNumber(phoneNumber),
	...args,
});

export const createCopyTextToClipboardAction = ({
	canPerformAction = true,
	textToCopy,
	...args
}) => ({
	canPerformAction:
		canPerformAction &&
		typeof window.navigator.clipboard.writeText === 'function',
	icon: <FileCopy />,
	onClick: () => {
		copyText(textToCopy);
	},
	...args,
});

export const createDeleteAction = ({
	afterDelete = () => {},
	deleteFunction,
	entityId,
	entityName,
	...args
}) => ({
	icon: <DeleteForever />,
	label: `Delete ${entityName}`,
	type: 'error',
	onClick: () =>
		showDialog(DeleteDialog, {
			onDelete: () => deleteFunction(axios, entityId).then(afterDelete),
		}),
	...args,
});

export const createDialogAction = ({ Dialog, dialogProps = {}, ...args }) => ({
	onClick: () => showDialog(Dialog, dialogProps),
	...args,
});

export const createEditAction = ({
	Dialog,
	dialogProps = {},
	entityId,
	entityName,
	entityType,
	userCan,
	...args
}) => ({
	canPerformAction: userCan([`${kebabCase(entityType)}:edit`]),
	icon: <Create />,
	label: `Edit ${entityName}`,
	onClick: () => {
		if (!!Dialog) {
			return showDialog(Dialog, dialogProps);
		}

		history.pushNamedRoute(`${camelCase(entityType)}Edit`, {
			id: entityId,
		});
	},
	...args,
});

export const createGetDirectionsAction = ({
	canPerformAction = true,
	entityName,
	address,
} = {}) => ({
	canPerformAction,
	icon: <DirectionsCar />,
	label: `Get Directions to ${entityName}`,
	onClick: () => getDirections(address),
});

export const createNavigateToRouteAction = ({
	routeName,
	routeVariables,
	...args
} = {}) => ({
	onClick: () => history.pushNamedRoute(routeName, routeVariables),
	...args,
});

export const createRemoveAction = ({
	entityName,
	onActionComplete,
	removeEntity,
	setLoading = identity,
	...args
} = {}) => ({
	icon: <RemoveCircle color="error" />,
	label: `Remove ${entityName}`,
	onClick: () => {
		showDialog(ConfirmationDialog, {
			text: `Are you sure you want to remove ${entityName}?`,
			onConfirmation: () => {
				setLoading(true);
				return removeEntity()
					.then(onActionComplete)
					.finally(() => setLoading(false));
			},
		});
	},
	...args,
});

export const createSendEmailAction = ({
	canPerformAction = true,
	entityName,
	emailAddress,
	...args
} = {}) => ({
	canPerformAction,
	icon: <Email />,
	label: `Email ${entityName}`,
	onClick: () => sendEmail(emailAddress),
	...args,
});

export const createStateEventActions = (
	state,
	machine,
	send,
	machineEventActionObjects,
	{ hiddenEvents = [], showDivider = true } = {}
) => {
	const availableEvents = state.nextEvents
		.filter(event => machine.transition(state, event).changed)
		.filter(event => !hiddenEvents.includes(event));

	return availableEvents.map((event, index) => ({
		label: startCase(event.toLowerCase()),
		onClick: () => send(event),
		divider: showDivider && index === availableEvents.length - 1,
		...machineEventActionObjects[event],
	}));
};

export const createToggleActiveStatusAction = ({
	active,
	entityId,
	entityName,
	entityType,
	onActionComplete,
	setLoading,
	updateEntity,
	userCan,
	...args
}) => ({
	canPerformAction: userCan([`${kebabCase(entityType)}:edit`]),
	label: active ? `Deactivate ${entityName}` : `Activate ${entityName}`,
	icon: <ToggleIcon on={!active} />,
	onClick: () => {
		setLoading(true);
		updateEntity(axios, entityId, { active: !active })
			.then(onActionComplete)
			.finally(() => setLoading(false));
	},
	...args,
});
