import { useEffect } from 'react';
import { connect } from '@sporkbytes/api-client/Accounting';

import axios from 'services/data';
import history from 'services/history';

const Callback = () => {
	useEffect(() => {
		const connectToAccounting = async () => {
			await connect(axios, {
				url: window.location.toString(),
			});
			history.pushNamedRoute('accountingHome');
		};

		connectToAccounting();
	}, []);

	return null;
};

export default Callback;
