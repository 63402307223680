import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { blue, green, purple, red } from '@material-ui/core/colors';
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineItem,
	TimelineSeparator,
} from '@material-ui/lab';
import { makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import { getRoute } from '@sporkbytes/delivery-scheduler';
import { GridContainer } from '@sporkbytes/material-ui-kit-react';

import DateOutput from 'components/content/DateOutput';
import DefinitionList from 'components/content/DefinitionList';
import ExpansionPanel from 'components/content/ExpansionPanel';
import HeadCount from 'components/content/HeadCount';
import NamedAvatar from 'components/content/NamedAvatar';

const FormatMinutes = ({ value }) => (
	<Typography>{`${value} ${value === 1 ? 'minute' : 'minutes'}`}</Typography>
);

const FormatTime = props => (
	<DateOutput formatter="time" variant="body1" {...props} />
);

const useStyles = makeStyles(theme => ({
	expansionPanelContent: {
		display: 'block',
	},
	gridContainer: {
		margin: 0,
	},
	timelineItem: {
		'&::before': {
			display: 'none', // this is needed to left align the timeline
		},
	},
}));

const ScheduleTimeline = ({ mealProposals, distances, header }) => {
	const classes = useStyles();
	const theme = useTheme();

	const colors =
		mealProposals.length > 1
			? mealProposals.reduce(
					(colors, { id }, index) => ({
						...colors,
						[id]: [
							theme.palette.common.black,
							red[500],
							blue[300],
							purple[500],
							green[500],
						][index],
					}),
					{}
			  )
			: {};

	return (
		<Timeline>
			{getRoute(mealProposals, distances).map(
				(
					{
						arrivalTime,
						date,
						earlyBy,
						finishedJobTime,
						fullAddress,
						leaveTime,
						locationName,
						logoUrl,
						mealProposal,
						minutesToLocation,
						parentEntityName,
						streetAddress,
						timeSpentDoingJob,
						timeToEnter,
						timeToExit,
						timeToPark,
						timezone,
						type,
					},
					index,
					route
				) => (
					<TimelineItem key={index} className={classes.timelineItem}>
						<TimelineSeparator>
							<NamedAvatar
								alt={parentEntityName}
								name={parentEntityName}
								imageUrl={logoUrl}
							/>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent>
							<ExpansionPanel
								style={{
									borderLeft: `3px solid ${
										colors[mealProposal.id]
									}`,
								}}
								contentProps={{
									className: classes.expansionPanelContent,
								}}
								titleProps={{ variant: 'body1' }}
								title={
									<GridContainer
										className={classes.gridContainer}
									>
										<div>
											<Tooltip
												title={
													<>
														{locationName && (
															<Typography align="center">
																{locationName}
															</Typography>
														)}
														<Typography align="center">
															{streetAddress}
														</Typography>
													</>
												}
											>
												<div>
													{index === 0 ? (
														parentEntityName
													) : (
														<a
															href={`https://www.google.com/maps/dir/?api=1&origin=${
																route[index - 1]
																	.fullAddress
															}&destination=${fullAddress}&travelmode=driving`}
															target="_blank"
															rel="noopener noreferrer"
														>
															{minutesToLocation}{' '}
															min to{' '}
															{parentEntityName}
														</a>
													)}
												</div>
											</Tooltip>
											<DateOutput
												date={date}
												timezone={timezone}
												formatter="time"
											/>{' '}
											-{' '}
											<HeadCount
												value={mealProposal.headCount}
												variant="inherit"
											/>
										</div>
										{header(route[index])}
									</GridContainer>
								}
							>
								<DefinitionList
									title="Estimated Times"
									items={[
										{
											label: 'Time To Find Parking',
											value: timeToPark,
											render: FormatMinutes,
										},
										{
											label: 'Arrived',
											value: arrivalTime,
											render: ({ value }) => (
												<FormatTime
													date={value}
													timezone={timezone}
												/>
											),
										},
										{
											label: 'Time To Enter',
											value: timeToEnter,
											render: FormatMinutes,
										},
										{
											label: 'Early By',
											value: earlyBy,
											render: FormatMinutes,
											hideItem: earlyBy <= 0,
										},
										{
											label: `Time Spent ${
												type === 'ClientLocation'
													? 'Setting'
													: 'Picking'
											} Up`,
											value: timeSpentDoingJob,
											render: FormatMinutes,
										},
										{
											label:
												type === 'ClientLocation'
													? 'Delivered'
													: 'Picked Up',
											value: finishedJobTime,
											render: ({ value }) => (
												<FormatTime
													date={value}
													timezone={timezone}
												/>
											),
										},
										{
											label: 'Time To Exit',
											value: timeToExit,
											render: FormatMinutes,
										},
										{
											label: 'Left',
											value: leaveTime,
											render: ({ value }) => (
												<FormatTime
													date={value}
													timezone={timezone}
												/>
											),
										},
									]}
								/>
							</ExpansionPanel>
						</TimelineContent>
					</TimelineItem>
				)
			)}
		</Timeline>
	);
};

ScheduleTimeline.fragments = {
	details: gql`
		fragment ScheduleTimeline on MealProposals {
			id
			deliveryDate
			orderNumber
			headCount
			ClientLocation {
				id
				timezone
				name
				streetAddress
				timeToPark
				timeToEnter
				timeToExit
				fullAddress
				Client {
					id
					name
					logoUrl
				}
			}
			MealOptions(where: { approved: { _eq: true } }) {
				id
				approved
				PurchaseOrders(order_by: { pickupTime: asc }) {
					id
					pickupTime
					PartnerLocation {
						id
						name
						timeToPark
						timeToEnter
						timeToExit
						fullAddress
						streetAddress
						timezone
						Partner {
							id
							name
							logoUrl
						}
					}
				}
			}
		}
	`,
};

ScheduleTimeline.propTypes = {
	distances: PropTypes.object.isRequired,
	mealProposals: PropTypes.arrayOf(PropTypes.object).isRequired,
	header: PropTypes.func,
};

ScheduleTimeline.defaultProps = {
	header: () => null,
};

export default ScheduleTimeline;
