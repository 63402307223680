import React from 'react';
import gql from 'graphql-tag';
import { useHistory, useParams } from 'react-router-dom';
import { deleteMealTypes, put } from '@sporkbytes/api-client/MealTypes';

import FormWrapper from 'components/forms/FormWrapper';
import NameForm from 'components/forms/NameForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const MEAL_TYPE_QUERY = gql`
	query MealTypeEdit($id: uuid!) {
		MealTypes_by_pk(id: $id) {
			name
		}
	}
`;

const Edit = () => {
	const { id } = useParams();
	const { pushNamedRoute } = useHistory();

	return (
		<Page
			title={({ MealTypes_by_pk: { name } }) => `Edit ${name}`}
			query={MEAL_TYPE_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					MealTypes_by_pk: { name },
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${name}`} />
					<FormWrapper
						form={NameForm}
						initialValues={{
							name,
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
						onDelete={axios =>
							deleteMealTypes(axios, id).then(() =>
								pushNamedRoute('mealTypes')
							)
						}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
