import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import DateOutput from 'components/content/DateOutput';
import RemoteTable from 'components/content/RemoteTable';
import StatusIcon from 'components/meal-proposal-hub-spot-syncs/StatusIcon';

import { formatZonedTime } from 'services/dates';

const getMealProposalHubSpotSyncsTableQuery = () => gql`
	query MealProposalHubSpotSyncsTable(
		$MealProposalId: uuid
		$createdAtOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		MealProposalHubSpotSyncs(
			where: { MealProposalId: { _eq: $MealProposalId } }
			order_by: { createdAt: $createdAtOrderBy }
			limit: $limit
			offset: $offset
		) {
			action
			createdAt
			error
			payload
			status
			updatedAt
		}
		MealProposalHubSpotSyncs_aggregate(
			where: { MealProposalId: { _eq: $MealProposalId } }
		) {
			aggregate {
				count
			}
		}
	}
`;

const MealProposalHubSpotSyncsTable = ({ variables }) => {
	return (
		<RemoteTable
			columns={[
				{
					title: 'Status',
					field: 'status',
					render: ({ action, updatedAt, status }) => (
						<>
							<StatusIcon
								status={status}
								tooltip={`${status} as of ${formatZonedTime(
									updatedAt,
									'dateAndTime'
								)}`}
							/>
							{action}
						</>
					),
					sorting: false,
				},
				{
					title: 'Payload',
					field: 'payload',
					render: ({ payload }) => (
						<pre style={{ maxWidth: 250, overflowX: 'auto' }}>
							{payload && JSON.stringify(payload, null, 2)}
						</pre>
					),
					sorting: false,
				},
				{
					title: 'Error',
					field: 'error',
					render: ({ error }) => (
						<pre style={{ maxWidth: 250, overflowX: 'auto' }}>
							{error && JSON.stringify(error, null, 2)}
						</pre>
					),
					sorting: false,
				},
				{
					title: 'Created At',
					field: 'createdAt',
					render: ({ createdAt }) => (
						<DateOutput date={createdAt} formatter="dateAndTime" />
					),
					defaultSort: 'desc',
				},
			]}
			getQuery={getMealProposalHubSpotSyncsTableQuery}
			defaultOrderBy="createdAt"
			defaultOrderDirection="desc"
			options={{
				toolbar: false,
			}}
			queryEntity="MealProposalHubSpotSyncs"
			variables={variables}
		/>
	);
};

MealProposalHubSpotSyncsTable.propTypes = {
	variables: PropTypes.shape({
		MealProposalId: PropTypes.string,
	}),
};

MealProposalHubSpotSyncsTable.defaultProps = {
	variables: {},
};

export default MealProposalHubSpotSyncsTable;
