import React from 'react';
import gql from 'graphql-tag';
import { Timeline } from '@material-ui/icons';
import {
	Button,
	Divider,
	Grid,
	makeStyles,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { getClientApproved } from '@sporkbytes/meal-option-utils';
import { Checkbox } from '@sporkbytes/material-ui-kit-react';

import { useDashboardDay } from 'pages/dashboard/DashboardDayContext';
import DashboardMealProposalActionMenu from 'pages/dashboard/DashboardMealProposalActionMenu';
import DetailPageLink from 'components/routing/DetailPageLink';
import DriverTrackerDialog from 'pages/dashboard/DriverTrackerDialog';
import HeadCount from 'components/content/HeadCount';
import NoContentText from 'components/content/NoContentText';
import TimeAndPlace from 'components/content/TimeAndPlace';

import { useStore } from 'models/store';

const useStyles = makeStyles(theme => ({
	container: {
		minHeight: 100,
		padding: theme.spacing(0, 1),
	},
	deliveryDriverButton: {
		textTransform: 'none',
		...theme.typography.body1,
	},
	headCount: ({ stacked }) => ({
		paddingRight: stacked ? 0 : `${theme.spacing(4)}px !important`, // needed to overwrite the padding set from the spacing prop on Grid container
		justifyContent: stacked ? 'center' : 'flex-end',
	}),
}));

const ExpandedMealProposal = ({ mealProposal }) => {
	const {
		approvedMealProposals,
		getCurrentDriverSchedules,
		onSelectMealProposal,
		selectedMealProposals,
		showDispatchControls,
		showSchedulingControls,
	} = useDashboardDay();
	const { showDialog } = useStore();
	const stacked = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const classes = useStyles({ stacked });

	const {
		ClientLocation,
		deliveryDate,
		headCount,
		id,
		MealOptions,
		MealProposalsDeliveryDrivers,
		MealProposalsDeliveryGear,
		orderNumberText,
		SporkContact,
	} = mealProposal;

	const approvedMealOption = getClientApproved(MealOptions);

	const selected = !!selectedMealProposals.find(
		({ id }) => id === mealProposal.id
	);

	return (
		<>
			<Grid
				container
				alignItems="center"
				spacing={2}
				className={classes.container}
			>
				<Grid
					container
					item
					xs={12}
					md={1}
					justify={stacked ? 'center' : undefined}
				>
					<DetailPageLink
						entityType="MealProposals"
						id={id}
						text={orderNumberText}
						target="_blank"
					/>
				</Grid>
				<Grid item xs={12} md={1}>
					<Typography align="center">
						{SporkContact.abbreviatedName}
					</Typography>
				</Grid>
				<Grid item xs={12} md={3}>
					{!!approvedMealOption ? (
						approvedMealOption.PurchaseOrders.map(
							({
								id,
								pickupTime,
								PartnerLocation: { timezone, Partner },
							}) => (
								<TimeAndPlace
									key={id}
									time={pickupTime}
									timezone={timezone}
									place={Partner.name}
									stacked={stacked}
								/>
							)
						)
					) : (
						<NoContentText align={stacked ? 'center' : undefined}>
							No meal option approved
						</NoContentText>
					)}
				</Grid>
				<Grid item xs={12} md={3}>
					<TimeAndPlace
						time={deliveryDate}
						timezone={ClientLocation.timezone}
						place={
							<>
								<Typography>
									{ClientLocation.Client.name}
								</Typography>
								<Typography variant="body2">
									{ClientLocation.friendlyName}
								</Typography>
							</>
						}
						stacked={stacked}
					/>
				</Grid>
				<Grid
					container
					item
					xs={12}
					md={1}
					className={classes.headCount}
				>
					<HeadCount value={headCount} />
				</Grid>
				<Grid item xs={12} md={1}>
					{MealProposalsDeliveryDrivers.map(
						({ DeliveryDriver: { id, abbreviatedName } }) => (
							<Typography key={id} align="center">
								{showDispatchControls ? (
									<Tooltip
										title={`View timeline for ${abbreviatedName}`}
									>
										<Button
											startIcon={<Timeline />}
											disabled={!approvedMealOption}
											onClick={() => {
												showDialog(
													DriverTrackerDialog,
													{
														deliveryDriver: {
															abbreviatedName,
														},
														mealProposalIds:
															getCurrentDriverSchedules(
																approvedMealProposals
															).find(
																({
																	deliveryDriver,
																}) =>
																	deliveryDriver.id ===
																	id
															).mealProposalIds,
													}
												);
											}}
											size="small"
											className={
												classes.deliveryDriverButton
											}
										>
											{abbreviatedName}
										</Button>
									</Tooltip>
								) : (
									abbreviatedName
								)}
							</Typography>
						)
					)}
				</Grid>
				<Grid item xs={12} md={1}>
					{MealProposalsDeliveryGear.map(
						({ DeliveryGear: { id, abbreviatedName } }) => (
							<Typography key={id} align="center">
								{abbreviatedName}
							</Typography>
						)
					)}
				</Grid>
				<Grid container justify="center" item xs={12} md={1}>
					{showSchedulingControls ? (
						<Checkbox
							label=""
							disabled={!approvedMealOption}
							value={selected}
							onChange={() =>
								onSelectMealProposal(mealProposal, !selected)
							}
						/>
					) : (
						<DashboardMealProposalActionMenu
							mealProposal={mealProposal}
						/>
					)}
				</Grid>
			</Grid>
			<Divider />
		</>
	);
};

ExpandedMealProposal.fragments = {
	details: gql`
		fragment ExpandedMealProposal on MealProposals {
			id
			orderNumberText
			deliveryDate
			headCount
			SporkContact {
				abbreviatedName
			}
			ClientLocation {
				timezone
				friendlyName
				Client {
					name
					logoUrl
				}
			}
			MealOptions(where: { approved: { _eq: true } }) {
				approved
				PurchaseOrders(order_by: { pickupTime: asc }) {
					id
					pickupTime
					PartnerLocation {
						timezone
						Partner {
							name
							logoUrl
						}
					}
				}
			}
			MealProposalsDeliveryDrivers {
				DeliveryDriver {
					id
					abbreviatedName
				}
			}
			MealProposalsDeliveryGear {
				DeliveryGear {
					id
					abbreviatedName
				}
			}
			...DashboardMealProposalActionMenu
		}
		${DashboardMealProposalActionMenu.fragments.details}
	`,
};

export default ExpandedMealProposal;
