import React from 'react';
import gql from 'graphql-tag';
import { useHistory, useParams } from 'react-router-dom';
import { deletePartnerTags, put } from '@sporkbytes/api-client/PartnerTags';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PartnerTagForm from 'components/partner-tags/PartnerTagForm';

const PARTNER_TAG_QUERY = gql`
	query PartnerTagEdit($id: uuid!) {
		PartnerTags_by_pk(id: $id) {
			name
			backgroundColor
		}
	}
`;

const Edit = () => {
	const { id } = useParams();
	const { pushNamedRoute } = useHistory();

	return (
		<Page
			title={({ PartnerTags_by_pk: { name } }) => `Edit ${name}`}
			query={PARTNER_TAG_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					PartnerTags_by_pk: { name, backgroundColor },
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${name}`} />
					<FormWrapper
						form={PartnerTagForm}
						initialValues={{
							name,
							backgroundColor,
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
						onDelete={axios =>
							deletePartnerTags(axios, id).then(() =>
								pushNamedRoute('partnerTags')
							)
						}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
