import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { shortLinkDomain } from '@sporkbytes/config';

import SporkLocationGlobalFilter from 'components/spork-locations/SporkLocationGlobalFilter';

import { useStore } from 'models/store';

const useStyles = makeStyles(theme => ({
	footer: {
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(2),
		width: '100%',
		'& > *': {
			margin: theme.spacing(2, 0),
			[theme.breakpoints.up('md')]: {
				margin: 0,
			},
		},
		[theme.breakpoints.up('md')]: {
			alignItems: 'center',
			bottom: 0,
			display: 'flex',
			justifyContent: 'space-around',
			position: 'fixed',
			zIndex: theme.layers.fixedContent,
		},
		'@media print': {
			display: 'none',
		},
	},
	sporkLocationFilterContainer: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200,
		},
	},
}));

const Footer = () => {
	const { hasMoreThanOneSporkLocation } = useStore();
	const classes = useStyles();

	return (
		<footer className={classes.footer}>
			<Typography>
				<a
					href={`${shortLinkDomain}/privacy-policy`}
					target="_blank"
					rel="noopener noreferrer"
				>
					Privacy Policy
				</a>
			</Typography>
			{hasMoreThanOneSporkLocation() ? (
				<div className={classes.sporkLocationFilterContainer}>
					<SporkLocationGlobalFilter />
				</div>
			) : null}
			<Typography color="inherit" component="h1">
				Copyright &copy; Spork Bytes
			</Typography>
		</footer>
	);
};

export default Footer;
