import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { makeStyles, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { isApproved } from '@sporkbytes/object-utils';

import DateOutput from 'components/content/DateOutput';
import DetailPageLink from 'components/routing/DetailPageLink';
import InlineContent from 'components/content/InlineContent';
import RemoteTable from 'components/content/RemoteTable';

import { getMultiWordSearchWhereClause } from 'services/search';

const getMealProposalsTableQuery = searchTerm => gql`
	query MealProposalsTable(
		$ClientId: uuid
		$MealProposalId: uuid
		$clientLocationOrderBy: order_by
		$deliveryDateOrderBy: order_by
		$headCountOrderBy: order_by
		$orderNumberOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		MealProposals(
			where: {
				_and: [
					{ id: { _neq: $MealProposalId } }
					{ status: { _nin: ["Canceled", "Draft"] } }
					{
						ClientLocation: {
							Client: {
								id: { _eq: $ClientId }
							}
						}
					}
					{
						_or: [
							{
								ClientLocation: {
									friendlyName: {
										_ilike: "%${searchTerm}%" 
									}
								}
							}
							${getMultiWordSearchWhereClause(searchTerm)}
						]
					}
				]
			}
			order_by: {
				ClientLocation: { friendlyName: $clientLocationOrderBy }
				deliveryDate: $deliveryDateOrderBy
				headCount: $headCountOrderBy
				orderNumber: $orderNumberOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			id
			deliveryDate
			headCount
			name
			orderNumberText
			ClientLocation {
				id
				friendlyName
				timezone
			}
			MealOptions(order_by: { approved: desc, createdAt: asc }) {
				id
				approved
				name
				PurchaseOrders(order_by: { pickupTime: asc }) {
					PartnerLocation {
						Partner {
							id
							name
						}
					}
				}
			}
		}
		MealProposals_aggregate(
			where: {
				_and: [
					{ id: { _neq: $MealProposalId } }
					{ status: { _nin: ["Canceled", "Draft"] } }
					{
						ClientLocation: {
							Client: {
								id: { _eq: $ClientId }
							}
						}
					}
					{
						_or: [
							{
								ClientLocation: {
									friendlyName: {
										_ilike: "%${searchTerm}%" 
									}
								}
							}
							${getMultiWordSearchWhereClause(searchTerm)}
						]
					}
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const useStyles = makeStyles(theme => ({
	approvedIcon: {
		color: theme.palette.success[500],
	},
}));

const MealProposalsTable = ({ hiddenColumns, variables }) => {
	const classes = useStyles();

	return (
		<RemoteTable
			columns={[
				{
					title: 'Order #',
					field: 'orderNumber',
					render: ({ id, name, orderNumberText }) => (
						<DetailPageLink
							entityType="MealProposals"
							id={id}
							text={`${orderNumberText}${
								name ? `: ${name}` : ''
							}`}
						/>
					),
				},
				{
					title: 'Client Location',
					field: 'clientLocation',
					render: ({ ClientLocation }) => (
						<DetailPageLink
							entityType="ClientLocations"
							id={ClientLocation.id}
							text={ClientLocation.friendlyName}
						/>
					),
				},
				{
					title: 'Delivery Date',
					field: 'deliveryDate',
					render: ({
						deliveryDate,
						ClientLocation: { timezone },
					}) => (
						<DateOutput
							date={deliveryDate}
							formatter="dateAndTime"
							timezone={timezone}
						/>
					),
				},
				{
					title: 'Head Count',
					field: 'headCount',
				},
				{
					title: 'Partners',
					field: 'partnerName',
					sorting: false,
					render: ({ MealOptions }) =>
						MealOptions.map(mealOption => (
							<div key={mealOption.id}>
								<Typography>
									{isApproved(mealOption) && (
										<InlineContent element="span">
											<Check
												className={classes.approvedIcon}
											/>
										</InlineContent>
									)}
									{mealOption.name}
								</Typography>
								<ul>
									{mealOption.PurchaseOrders.map(
										({ PartnerLocation: { Partner } }) => (
											<li key={Partner.id}>
												<DetailPageLink
													entityType="Partners"
													id={Partner.id}
													text={Partner.name}
												/>
											</li>
										)
									)}
								</ul>
							</div>
						)),
				},
			].filter(({ field }) => !hiddenColumns.includes(field))}
			defaultOrderBy="deliveryDate"
			defaultOrderDirection="desc"
			getQuery={getMealProposalsTableQuery}
			queryEntity="MealProposals"
			variables={variables}
		/>
	);
};

MealProposalsTable.propTypes = {
	hiddenColumns: PropTypes.arrayOf(PropTypes.string),
	variables: PropTypes.shape({
		ClientId: PropTypes.string,
		MealProposalId: PropTypes.string,
	}),
};

MealProposalsTable.defaultProps = {
	hiddenColumns: [],
	variables: {},
};

export default MealProposalsTable;
