import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/Fees';

import FeeForm from 'components/fees/FeeForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const FEE_QUERY = gql`
	query EditFee($id: uuid!) {
		Fees_by_pk(id: $id) {
			name
			description
			amount
			type
			AccountingSettingId
			SporkLocationId
		}
	}
`;

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			query={FEE_QUERY}
			variables={{ id }}
			title={({ Fees_by_pk: { name } }) => `Edit ${name}`}
		>
			{({ data: { Fees_by_pk } }) => (
				<>
					<PageHeader headerText={`Edit ${Fees_by_pk.name}`} />
					<FormWrapper
						form={FeeForm}
						initialValues={Fees_by_pk}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
