import React from 'react';
import { CardContent } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/ClientLocations';

import ClientLocationActionMenu from 'components/client-locations/ClientLocationActionMenu';
import ClientLocationDetails from 'components/client-locations/ClientLocationDetails';
import ClientLocationProfiles from 'components/client-locations/ClientLocationProfiles';
import ClientLocationsFeesTable from 'components/client-locations-fees/ClientLocationsFeesTable';
import ContactsTable from 'components/contacts/ContactsTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PurchaseOrderReviewsTable from 'components/purchase-order-reviews/PurchaseOrderReviewsTable';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';
import RibbonContainer from 'components/content/RibbonContainer';
import SporkReviewsTable from 'components/spork-reviews/SporkReviewsTable';

import { createToggleActiveStatusAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';

const CLIENT_LOCATION_QUERY = gql`
	query ClientLocationPageDetails($id: uuid!) {
		ClientLocations_by_pk(id: $id) {
			id
			active
			friendlyName
			fullAddress
			...ClientLocationDetails
			...ClientLocationProfiles
			Client {
				id
				logoUrl
				name
			}
		}
	}
	${ClientLocationDetails.fragments.details}
	${ClientLocationProfiles.fragments.details}
`;

const Detail = () => {
	const { id } = useParams();
	const { userCan } = useAuth();

	return (
		<Page
			title={({ ClientLocations_by_pk: { Client, friendlyName } }) =>
				`${friendlyName} (${Client.name})`
			}
			query={CLIENT_LOCATION_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					ClientLocations_by_pk: { Client, ...clientLocation },
				},
				refetch,
			}) => (
				<>
					<RibbonContainer
						show={!clientLocation.active}
						title="inactive"
						color="error"
					>
						<PageHeader
							imgUrl={Client.logoUrl}
							headerText={Client.name}
							linkProps={{
								routeName: 'clientsDetail',
								variables: { id: Client.id },
								permissions: ['clients:view'],
							}}
							actionButton={
								<ClientLocationActionMenu
									clientLocation={clientLocation}
									additionalActions={({ setLoading }) => [
										createToggleActiveStatusAction({
											active: clientLocation.active,
											entityId: id,
											entityName:
												clientLocation.friendlyName,
											entityType: 'ClientLocations',
											onActionComplete: () =>
												refetch({ id }),
											setLoading,
											updateEntity: put,
											userCan,
										}),
									]}
								/>
							}
						/>
						<PageSubheader>
							{clientLocation.name ? (
								<>
									<div>{clientLocation.name}</div>
									<div>{clientLocation.fullAddress}</div>
								</>
							) : (
								clientLocation.fullAddress
							)}
						</PageSubheader>
					</RibbonContainer>
					<QueryStringSyncedTabs
						id="tab"
						tabs={[
							{
								id: 'details',
								label: 'Details',
								content: (
									<CardContent>
										<ClientLocationDetails
											clientLocation={clientLocation}
										/>
									</CardContent>
								),
							},
							{
								id: 'contacts',
								label: 'Contacts',
								content: (
									<ContactsTable
										tableFilter={`
											MealProposalsContacts: {
												MealProposal: {
													status: { _neq: "Draft" }
													ClientLocation: {
														id: { _eq: "${id}" }
													}
												}
											}`}
										options={{
											exportButton: true,
											pageSize: 25,
											pageSizeOptions: [25, 50, 100],
										}}
									/>
								),
							},
							{
								id: 'default-fees',
								label: 'Default Fees',
								content: (
									<ClientLocationsFeesTable
										ClientLocationId={id}
									/>
								),
							},
							{
								id: 'profiles',
								label: 'Profiles',
								content: (
									<CardContent>
										<ClientLocationProfiles
											clientLocation={clientLocation}
										/>
									</CardContent>
								),
							},
							{
								id: 'spork-reviews',
								label: 'Spork Reviews',
								content: (
									<SporkReviewsTable
										variables={{
											ClientLocationId: id,
										}}
										hiddenColumns={[
											'clientLocationFriendlyName',
										]}
									/>
								),
							},
							{
								id: 'partner-reviews',
								label: 'Partner Reviews',
								content: (
									<PurchaseOrderReviewsTable
										hiddenColumns={['clientName']}
										variables={{
											ClientLocationId: id,
										}}
									/>
								),
							},
						]}
					/>
				</>
			)}
		</Page>
	);
};

export default Detail;
