import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/Contacts';

import ContactForm from 'components/contacts/ContactForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

// TODO: use fragments here
const CONTACT_QUERY = gql`
	query ContactEdit($id: uuid!) {
		Contacts_by_pk(id: $id) {
			firstName
			lastName
			fullName
			email
			phoneNumber
		}
	}
`;

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ Contacts_by_pk: { fullName } }) => `Edit ${fullName}`}
			query={CONTACT_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					Contacts_by_pk: { fullName, ...contact },
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${fullName}`} />
					<FormWrapper
						form={ContactForm}
						initialValues={contact}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
