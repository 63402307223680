import Cancel from 'pages/meal-proposals/Cancel';
import ContactCreate from 'pages/meal-proposals/ContactCreate';
import Create from 'pages/meal-proposals/Create';
import CreateFee from 'pages/meal-proposals/CreateFee';
import CreateMealProposalActivityLog from 'pages/meal-proposals/CreateMealProposalActivityLog';
import Detail from 'pages/meal-proposals/Detail';
import Duplicate from 'pages/meal-proposals/Duplicate';
import Edit from 'pages/meal-proposals/Edit';
import MealOptionsManage from 'pages/meal-proposals/MealOptionsManage';
import PrintableLabels from 'pages/meal-proposals/PrintableLabels';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'mealProposalsContactsCreate',
		path: '/:id/contacts/create',
		component: ContactCreate,
		permissions: ['meal-proposals-contacts:create', 'contacts:create'],
		exact: true,
	},
	{
		name: 'mealProposalsCreate',
		path: '/create',
		component: Create,
		permissions: ['meal-proposals:create'],
		exact: true,
	},
	{
		name: 'mealProposalFeesCreate',
		path: '/:id/meal-proposal-fees/create',
		component: CreateFee,
		permissions: ['meal-proposals:edit', 'meal-proposal-fees:create'],
		exact: true,
	},
	{
		name: 'mealProposalActivityLogsCreate',
		path: '/:id/meal-proposal-activity-logs/create',
		component: CreateMealProposalActivityLog,
		permissions: [
			'meal-proposals:edit',
			'meal-proposal-activity-logs:create',
		],
		exact: true,
	},
	{
		name: 'mealProposalsDetail',
		path: '/:id/detail',
		component: Detail,
		permissions: ['meal-proposals:view'],
		exact: true,
	},
	{
		name: 'mealProposalsDuplicate',
		path: '/:id/duplicate',
		component: Duplicate,
		permissions: ['meal-proposals:duplicate'],
		exact: true,
	},
	{
		name: 'mealProposalsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['meal-proposals:edit'],
		exact: true,
	},
	{
		name: 'mealProposalsMealOptionsManage',
		path: '/:id/meal-options/manage',
		component: MealOptionsManage,
		permissions: ['meal-proposals:manage-meal-options'],
		exact: true,
	},
	{
		name: 'mealProposalsPrintableLabels',
		path: '/:id/printable-labels',
		component: PrintableLabels,
		permissions: ['meal-proposals:view', 'partner-menu-items:view'],
		exact: true,
	},
	{
		name: 'mealProposalsCancel',
		path: '/:id/cancel',
		component: Cancel,
		permissions: ['meal-proposals:cancel'],
		exact: true,
	},
];

export default prependUrlPath('meal-proposals', routes);
