import React, { useContext, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Chart from 'react-apexcharts';
import { Grid } from '@material-ui/core';
import { Alert, MultiSelect } from '@sporkbytes/material-ui-kit-react';
import { getPercent } from '@sporkbytes/math-utils';
import {
	getSummationCalculation,
	isCanceled,
} from '@sporkbytes/meal-proposal-utils';
import { totalClientPays } from '@sporkbytes/meal-option-utils';
import Money from '@sporkbytes/money';

import LoadingStateContainer from 'components/utilities/LoadingStateContainer';
import Metric from 'components/analytics/Metric';
import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import { formatCurrency, formatPercent } from 'services/mealProposal';
import { groupBy, maxBy, partition } from 'services/utilities';

const CANCELLATION_REASONS_QUERY = gql`
	query AnalyticsCancellations {
		CancellationReasons(order_by: { sortOrder: asc }) {
			id
			name
		}
	}
`;

const otherCancellationReason = {
	id: null,
	name: 'Other',
};

const Cancellations = () => {
	const { data, loading } = useQuery(CANCELLATION_REASONS_QUERY);
	const { chartConfig, mealProposals } = useContext(AnalyticsContext);
	const [selectedCancellationReasons, setSelectedCancellationReasons] =
		useState([]);

	const [canceledMeals, nonCanceledMeals] = partition(
		mealProposals,
		isCanceled
	);
	const canceledMealsForSelectedCancellationReasons = canceledMeals.filter(
		({ CancellationReasonId }) =>
			selectedCancellationReasons?.includes(CancellationReasonId)
	);
	const mealProposalsByCancellationReason = groupBy(
		canceledMealsForSelectedCancellationReasons,
		mealProposal => mealProposal.CancellationReasonId
	);
	const getLostRevenue = mealProposals =>
		+mealProposals
			.map(
				({ MealOptions, headCount, MealProposalFees }) =>
					+new Money(
						totalClientPays(
							maxBy(MealOptions, mealOption =>
								totalClientPays(mealOption, {
									MealProposalFees,
									headCount,
								})
							),
							{
								MealProposalFees,
								headCount,
							}
						)
					)
			)
			.reduce((sum, value) => sum.add(value), new Money(0));
	const { series, labels } = Object.keys(
		mealProposalsByCancellationReason
	).reduce(
		({ series, labels }, CancellationReasonId) => ({
			series: [
				...series,
				getLostRevenue(
					mealProposalsByCancellationReason[CancellationReasonId]
				),
			],
			labels: [
				...labels,
				mealProposalsByCancellationReason[CancellationReasonId][0]
					?.CancellationReason?.name ?? otherCancellationReason.name,
			],
		}),
		{
			series: [],
			labels: [],
		}
	);

	const options = {
		...chartConfig.options,
		labels,
		tooltip: {
			y: {
				formatter: formatCurrency,
			},
		},
	};

	const canceledMealLostRevenue = getLostRevenue(
		canceledMealsForSelectedCancellationReasons
	);
	const nonCanceledMealRevenue = getSummationCalculation(
		'totalClientPays',
		nonCanceledMeals
	);

	useEffect(() => {
		// Set the selectedCancellationReasons to default to all reasons
		setSelectedCancellationReasons([
			...(data?.CancellationReasons?.map(({ id }) => id) ?? []),
			otherCancellationReason.id,
		]);
	}, [data]);

	return (
		<LoadingStateContainer loading={loading}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm md>
					<WidgetTitle>Cancellations</WidgetTitle>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<MultiSelect
						label="Cancellation Reasons"
						onChange={setSelectedCancellationReasons}
						options={[
							...(data?.CancellationReasons ?? []),
							otherCancellationReason,
						]}
						getOptionKey={({ id }) => id}
						getOptionLabel={({ name }) => name}
						getOptionValue={({ id }) => id}
						value={selectedCancellationReasons}
					/>
				</Grid>
			</Grid>
			{series.length > 0 ? (
				<Grid container spacing={3}>
					<Grid item xs={12} md={9}>
						<Chart
							type="donut"
							series={series}
							options={options}
							height={chartConfig.height}
						/>
					</Grid>

					<Grid
						item
						xs={12}
						md={3}
						container
						direction="row"
						justify="center"
						spacing={4}
					>
						<Grid item md={12}>
							<Metric
								value={formatPercent(
									getPercent(
										nonCanceledMealRevenue,
										nonCanceledMealRevenue +
											canceledMealLostRevenue
									)
								)}
								label="Sales Close %"
							/>
						</Grid>
						<Grid item md={12}>
							<Metric
								value={formatCurrency(nonCanceledMealRevenue)}
								label="Realized Revenue"
							/>
						</Grid>
						<Grid item md={12}>
							<Metric
								value={formatCurrency(canceledMealLostRevenue)}
								label="Lost Revenue"
							/>
						</Grid>
						<Grid item md={12}>
							<Metric
								value={formatCurrency(
									nonCanceledMealRevenue +
										canceledMealLostRevenue
								)}
								label="Potential Revenue w/o Losses"
							/>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<Alert type="info">
					There were no cancellations during the selected time period
					for the selected Cancellation Reasons.
				</Alert>
			)}
		</LoadingStateContainer>
	);
};

Cancellations.fragments = {
	details: gql`
		fragment CancellationsDetails on MealProposals {
			id
			orderNumberText
			status
			CancellationReasonId
			CancellationReason {
				name
			}
			MealProposalFees {
				amount
				discount
				type
			}
			MealOptions {
				approved
				gratuity
				PurchaseOrders {
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							costToClient
							discount
							quantity
						}
					}
				}
			}
		}
	`,
};

export default Cancellations;
