import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import gql from 'graphql-tag';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';
import { put } from '@sporkbytes/api-client/PartnerMenuItems';

import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import DetailPageLink from 'components/routing/DetailPageLink';
import EditableValue from 'components/forms/EditableValue';
import NoContentText from 'components/content/NoContentText';
import PartnerMenuItemActionMenu from 'components/partner-menu-items/PartnerMenuItemActionMenu';
import PartnerMenuItemMergeDuplicatesDialog from 'components/partner-menu-items/PartnerMenuItemMergeDuplicatesDialog';
import RemoteTable from 'components/content/RemoteTable';

import { useStore } from 'models/store';

import { getMultiWordSearchWhereClause } from 'services/search';
import { removeHtml } from 'services/regexp';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const getPartnerMenuItemsQuery = searchTerm => gql`
	query PartnerMenuItems(
		$PartnerId: uuid!
		$activeOrderBy: order_by
		$costOrderBy: order_by
		$descriptionOrderBy: order_by
		$nameOrderBy: order_by
		$partnerMenuSectionNameOrderBy: order_by
		$associatedItemsOrderBy: order_by
		$limit: Int
		$offset: Int
	) {
		PartnerMenuItems(
			where: { 
				PartnerId: { _eq: $PartnerId } 
				_or: [
					{ _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
					{
						PartnerMenuSection: {
							_and: [${getMultiWordSearchWhereClause(searchTerm)}] 
						}
					}
					{
						PartnerMenuItemsDietaryTags: {
							DietaryTag: {
								name: {
									_ilike: "%${searchTerm}%"
								}
							}
						}
					}
				]
			}
			order_by: {
				active: $activeOrderBy
				cost: $costOrderBy
				description: $descriptionOrderBy
				name: $nameOrderBy
				PartnerMenuSection: {
					name: $partnerMenuSectionNameOrderBy
				}
				PartnerMenuItemOptionGroupsPartnerMenuItems_aggregate: {
					count: $associatedItemsOrderBy
				}
			}
			limit: $limit
			offset: $offset
		) {
			id
			active
			description
			name
			cost
			...PartnerMenuItemActionMenuDetails
			PartnerMenuSection {
				name
			}
			PartnerMenuItemOptionGroupsPartnerMenuItems(
				order_by: { 
					PartnerMenuItemOptionGroup: {
						PartnerMenuItem: {
							name: asc 
						} 
					}
				}
			) {
				PartnerMenuItemOptionGroup {
					PartnerMenuItem {
						id
						name
					}
				}
			}
			PartnerMenuItemsDietaryTags(order_by: { DietaryTag: { sortOrder: asc } } ) {
				DietaryTag {
					name
				}
			}
		}
		PartnerMenuItems_aggregate(
			where: { 
				PartnerId: { _eq: $PartnerId } 
				_or: [
					{ _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
					{
						PartnerMenuSection: {
							name: {
								_ilike: "%${searchTerm}%"
							}
						}
					}
					{
						PartnerMenuItemsDietaryTags: {
							DietaryTag: {
								name: {
									_ilike: "%${searchTerm}%"
								}
							}
						}
					}
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
	${PartnerMenuItemActionMenu.fragments.details}
`;

const PartnerMenuItemsTable = ({ PartnerId, options, ...props }) => {
	const [selectedItems, setSelectedItems] = useState([]);
	const { userCan } = useAuth();
	const { showDialog } = useStore();
	const ref = useRef();

	return (
		<>
			<RemoteTable
				{...props}
				tableRef={ref}
				columns={[
					{
						title: 'Name',
						field: 'name',
						render: ({ id, name, PartnerMenuItemsDietaryTags }) => (
							<ListItemText
								primary={
									<DetailPageLink
										entityType="PartnerMenuItems"
										id={id}
										text={name}
									/>
								}
								secondary={makeCommaSeparatedList(
									PartnerMenuItemsDietaryTags.map(
										({ DietaryTag: { name } }) => name
									),
									false
								)}
							/>
						),
					},
					{
						title: 'In Section',
						field: 'partnerMenuSectionName',
						render: ({ PartnerMenuSection }) =>
							PartnerMenuSection ? (
								PartnerMenuSection.name
							) : (
								<NoContentText>no section</NoContentText>
							),
					},
					{
						title: 'As Option In',
						field: 'associatedItems',
						render: ({
							PartnerMenuItemOptionGroupsPartnerMenuItems,
						}) =>
							PartnerMenuItemOptionGroupsPartnerMenuItems.length >
							0 ? (
								<List dense>
									{PartnerMenuItemOptionGroupsPartnerMenuItems.map(
										({
											PartnerMenuItemOptionGroup: {
												PartnerMenuItem: { name, id },
											},
										}) => (
											<ListItem key={id} disableGutters>
												<ListItemText
													primary={
														<DetailPageLink
															entityType="PartnerMenuItems"
															id={id}
															text={name}
														/>
													}
												/>
											</ListItem>
										)
									)}
								</List>
							) : (
								<NoContentText>no items</NoContentText>
							),
					},
					{
						title: 'Cost',
						field: 'cost',
						render: ({ cost, id }) => (
							<EditableValue
								helperText="This is the cost of the item when it is sold à la carte."
								name="cost"
								onSave={cost =>
									put(axios, id, { cost }).then(
										ref.current.onQueryChange
									)
								}
								permissions={['partner-menu-items:edit']}
								type="currency"
								value={cost}
							/>
						),
					},
					{
						title: 'Description',
						field: 'description',
						render: ({ description }) =>
							description ? (
								removeHtml(description)
							) : (
								<NoContentText>no description</NoContentText>
							),
					},
					{
						title: 'Active',
						field: 'active',
						render: ({ active, id }) => (
							<BooleanFieldSwitch
								afterChange={ref.current.onQueryChange}
								entityId={id}
								entityType="partnerMenuItems"
								updateEntity={put}
								value={active}
							/>
						),
					},
					{
						title: 'Actions',
						field: 'action',
						sorting: false,
						render: partnerMenuItem => (
							<PartnerMenuItemActionMenu
								partnerMenuItem={partnerMenuItem}
								onActionComplete={ref.current.onQueryChange}
							/>
						),
					},
				]}
				defaultOrderBy="name"
				defaultOrderDirection="asc"
				getQuery={getPartnerMenuItemsQuery}
				queryEntity="PartnerMenuItems"
				variables={{ PartnerId }}
				options={{
					selection: userCan(['partner-menu-items:merge-duplicates']),
					showSelectAllCheckbox: false,
					showTextRowsSelected: false,
				}}
				onSelectionChange={setSelectedItems}
				actions={[
					{
						hidden: selectedItems.length < 2,
						icon: 'merge_type',
						tooltip: 'Merge Selected Menu Items',
						position: 'toolbarOnSelect', // This is needed to show the action in the toolbar when items are selected https://github.com/mbrn/material-table/issues/980#issuecomment-582809141
						onClick: () =>
							showDialog(PartnerMenuItemMergeDuplicatesDialog, {
								title: 'Merge Duplicate Items',
								itemsToMergeIds: selectedItems.map(
									({ id }) => id
								),
								afterSubmit: ref.current.onQueryChange,
								PartnerId,
							}),
					},
				]}
			/>
		</>
	);
};

PartnerMenuItemsTable.propTypes = {
	PartnerId: PropTypes.string.isRequired,
};

export default PartnerMenuItemsTable;
