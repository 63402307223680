import {
	canApprove,
	canConfirm,
	canDelete,
	canDisapprove,
	canEdit,
	canUndoConfirmation,
} from '@sporkbytes/purchase-order-utils';
import { isApproved } from '@sporkbytes/object-utils';

import createApprovalMachine from './createApprovalMachine';

// EVENTS

const ADD_PAYOUT_ADJUSTMENT = {
	actions: 'addPayoutAdjustment',
	cond: 'canEdit',
};

const COPY_PO_LINK = {
	actions: 'copyPOLink',
	cond: 'canCopyPOLink',
};

const DUPLICATE = {
	actions: 'duplicate',
	cond: 'canDuplicate',
};

const EDIT = {
	actions: 'edit',
	cond: 'canEdit',
};

const REASSIGN_CONTACTS = {
	actions: 'reassignContacts',
	cond: 'canEdit',
};

// GUARDS

const canCopyPOLink = context => isApproved(context?.MealOption);

export default (guards = {}) =>
	createApprovalMachine('purchaseOrder', {
		events: {
			approved: {
				DUPLICATE,
				EDIT,
				REASSIGN_CONTACTS,
				ADD_PAYOUT_ADJUSTMENT,
				COPY_PO_LINK,
			},
			confirmed: {
				DUPLICATE,
				EDIT,
				REASSIGN_CONTACTS,
				ADD_PAYOUT_ADJUSTMENT,
				COPY_PO_LINK,
			},
			disapproved: {
				DUPLICATE,
				EDIT,
				REASSIGN_CONTACTS,
				ADD_PAYOUT_ADJUSTMENT,
				COPY_PO_LINK,
			},
		},
		guards: {
			canApprove,
			canConfirm,
			canCopyPOLink,
			canDelete,
			canDisapprove,
			canEdit,
			canUndoConfirmation,
			...guards,
		},
	});
