import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import {
	DialogForm,
	Form,
	GridContainer,
	LinkedRichTextEditor,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import EmailTemplatePreview from 'components/email-templates/EmailTemplatePreview';

import * as yup from 'models/validation';

const useStyles = makeStyles(theme => ({
	dialog: {
		minWidth: '80%',
	},
	card: {
		marginTop: theme.spacing(2),
	},
}));

const EmailTemplateForm = ({ displayIn, showIdentifierFields, ...props }) => {
	const classes = useStyles();

	let validationSchema = yup.object().shape({
		greeting: yup.string().max(50).required('Please enter a greeting.'),
		buttonText: yup.string().max(50).required('Please enter button text.'),
		preButtonContent: yup
			.string()
			.required(
				'Please enter content that will appear before the button.'
			),
		postButtonContent: yup
			.string()
			.required(
				'Please enter content that will appear after the button.'
			),
		warningText: yup.string(),
		signature: yup.string().required('Please enter a signature.'),
	});

	if (showIdentifierFields) {
		validationSchema = validationSchema.concat(
			yup.object().shape({
				id: yup.string().max(50).required('Please enter an ID.'),
				name: yup.name().required(),
			})
		);
	}

	const Wrapper =
		displayIn === 'dialog'
			? ({ DialogProps, ...props }) => (
					<DialogForm
						DialogProps={{
							classes: {
								paper: classes.dialog,
							},
							...DialogProps,
						}}
						{...props}
					/>
			  )
			: Form;

	return (
		<Wrapper {...props} validationSchema={validationSchema}>
			{({ values }) => (
				<GridContainer>
					<div>
						{showIdentifierFields && (
							<GridContainer>
								<LinkedTextInput
									name="id"
									label="ID"
									autoFocus
								/>
								<LinkedTextInput name="name" />
							</GridContainer>
						)}
						<GridContainer>
							<LinkedTextInput name="greeting" />
							<LinkedTextInput name="buttonText" />
						</GridContainer>
						<GridContainer>
							<LinkedRichTextEditor
								name="preButtonContent"
								label="Pre-Button Content"
							/>
						</GridContainer>
						<GridContainer>
							<LinkedRichTextEditor
								name="postButtonContent"
								label="Post-Button Content"
							/>
						</GridContainer>
						<GridContainer>
							<LinkedTextInput name="warningText" />
						</GridContainer>
						<GridContainer>
							<LinkedTextInput name="signature" multiline />
						</GridContainer>
					</div>
					<Card className={classes.card}>
						<CardContent>
							<EmailTemplatePreview emailTemplate={values} />
						</CardContent>
					</Card>
				</GridContainer>
			)}
		</Wrapper>
	);
};

EmailTemplateForm.propTypes = {
	displayIn: PropTypes.oneOf(['dialog', 'page']),
	showIdentifierFields: PropTypes.bool,
};

EmailTemplateForm.defaultProps = {
	displayIn: 'page',
	showIdentifierFields: true,
};

export default EmailTemplateForm;
