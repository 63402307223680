import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import startCase from 'lodash.startcase';

import DateOutput from 'components/content/DateOutput';
import DetailPageLink from 'components/routing/DetailPageLink';
import RemoteTable from 'components/content/RemoteTable';

const getEmailActivityLogsQuery = searchTerm => gql`
	query EmailActivityLogs(
		$ContactId: uuid!
		$eventOrderBy: order_by
		$timestampOrderBy: order_by
		$contactOrderBy: order_by
		$orderNumberTextOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		EmailActivityLogs(
			where: {
				_or: [
					{ event: { _ilike: "%${searchTerm}%" } }
					{ type: { _ilike: "%${searchTerm}%" } }
					{ reason: { _ilike: "%${searchTerm}%" } }
					{ response: { _ilike: "%${searchTerm}%" } }
					{ category: { _ilike: "%${searchTerm}%" } }
				]
				Contact: { id: { _eq: $ContactId } }
			}
			order_by: {
				event: $eventOrderBy
				timestamp: $timestampOrderBy
				MealProposalActivityLog: { MealProposal: { orderNumberText: $orderNumberTextOrderBy } }
			}
			limit: $limit
			offset: $offset
		) {
			event
			type
			timestamp
			reason
			response
			category
			MealProposalActivityLog {
				MealProposal {
					id
					orderNumberText
				}
			}
		}
		EmailActivityLogs_aggregate(
			where: {
				_or: [
					{ event: { _ilike: "%${searchTerm}%" } }
					{ type: { _ilike: "%${searchTerm}%" } }
					{ reason: { _ilike: "%${searchTerm}%" } }
					{ response: { _ilike: "%${searchTerm}%" } }
					{ category: { _ilike: "%${searchTerm}%" } }
				]
				Contact: { id: { _eq: $ContactId } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const EmailActivityLogsTable = ({ ContactId }) => {
	return (
		<RemoteTable
			columns={[
				{
					title: 'Event',
					field: 'event',
					render: ({ event, type }) =>
						startCase(event) +
						(type ? ` (${startCase(type)})` : ''),
				},
				{
					title: 'Order #',
					field: 'orderNumberText',
					render: ({ MealProposalActivityLog }) =>
						MealProposalActivityLog?.MealProposal ? (
							<DetailPageLink
								entityType="MealProposals"
								id={MealProposalActivityLog.MealProposal.id}
								text={
									MealProposalActivityLog.MealProposal
										.orderNumberText
								}
							/>
						) : null,
				},
				{
					title: 'Date',
					field: 'timestamp',
					render: ({ timestamp }) => <DateOutput date={timestamp} />,
				},
				{
					title: 'Response',
					field: 'response',
					sorting: false,
					render: ({ reason, response }) => reason ?? response,
				},
				{
					title: 'Categories',
					field: 'category',
					sorting: false,
					render: ({ category: categories }) => (
						<ul>
							{JSON.parse(categories).map(category => (
								<li>{category}</li>
							))}
						</ul>
					),
				},
			]}
			defaultOrderBy="timestamp"
			defaultOrderDirection="desc"
			getQuery={getEmailActivityLogsQuery}
			queryEntity="EmailActivityLogs"
			variables={{ ContactId }}
		/>
	);
};

EmailActivityLogsTable.propTypes = {
	ContactId: PropTypes.string.isRequired,
};

export default EmailActivityLogsTable;
