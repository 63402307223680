import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		overflow: 'hidden',
		position: 'relative',
	},
	ribbon: {
		background: ({ color }) => theme.palette[color][800],
		color: theme.palette.common.white,
		fontSize: ({ size }) => (size === 'large' ? '1.5em' : '1em'),
		fontWeight: 'bold',
		lineHeight: '1.5em',
		position: 'absolute',
		right: ({ size }) => (size === 'large' ? -90 : -65),
		textAlign: 'center',
		textTransform: 'uppercase',
		top: ({ size }) => (size === 'large' ? 30 : 25),
		transform: 'rotate(45deg)',
		width: ({ size }) => (size === 'large' ? 300 : 200),
		zIndex: theme.layers.fixedContent,
	},
}));

const RibbonContainer = ({ children, color, show, size, title }) => {
	const classes = useStyles({ color, size });

	return (
		<div className={classes.container}>
			{show && <div className={classes.ribbon}>{title}</div>}
			{children}
		</div>
	);
};

RibbonContainer.propTypes = {
	color: PropTypes.string.isRequired,
	show: PropTypes.bool,
	size: PropTypes.oneOf(['medium', 'large']),
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
};

RibbonContainer.defaultProps = {
	size: 'large',
};

export default RibbonContainer;
