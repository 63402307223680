import React, { useRef } from 'react';
import gql from 'graphql-tag';

import ActionMenu from 'components/utilities/ActionMenu';
import RemoteTable from 'components/content/RemoteTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { useAuth } from 'services/auth';
import { createEditAction } from 'services/actionMenu';

const getPartnerTagsQuery = searchTerm =>
	gql`
		query PartnerTags(
			$nameOrderBy: order_by
			$backgroundColorOrderBy: order_by
			$limit: Int!
			$offset: Int!
		) {
			PartnerTags(
				where: { name: { _ilike: "%${searchTerm}%" } }
				order_by: { name: $nameOrderBy, backgroundColor: $backgroundColorOrderBy }
				limit: $limit
				offset: $offset
			) {
				id
				name	
				backgroundColor
			}
			PartnerTags_aggregate {
				aggregate {
					count
				}
			}
		}
	`;

const Detail = () => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<Page title="Partner Tags">
			<PageHeader headerText="Partner Tags" />
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Name',
						field: 'name',
					},
					{
						title: 'Background Color',
						field: 'backgroundColor',
					},
					{
						title: 'Actions',
						field: 'action',
						sorting: false,
						render: ({ id, name }) => (
							<ActionMenu
								options={[
									createEditAction({
										entityId: id,
										entityName: name,
										entityType: 'PartnerTags',
										userCan,
									}),
								]}
							/>
						),
					},
				]}
				getQuery={getPartnerTagsQuery}
				defaultOrderBy="name"
				defaultOrderDirection="asc"
				queryEntity="PartnerTags"
				addNewEntityRouteName="partnerTagsCreate"
				entityToAddName="Partner Tag"
				canAddNewEntity={userCan(['partner-tags:create'])}
				options={{ padding: 'dense' }}
			/>
		</Page>
	);
};

export default Detail;
