import React from 'react';
import gql from 'graphql-tag';
import { Divider, makeStyles, Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	divider: {
		backgroundColor: ({ driversAreAssigned }) =>
			driversAreAssigned
				? theme.palette.success[800]
				: theme.palette.warning[800],
		height: 6,
		margin: theme.spacing(0, 'auto', 3, 'auto'),
		width: '90%',
	},
}));

const DeliveryDriverAssignedBar = ({
	mealProposal: { MealProposalsDeliveryDrivers },
}) => {
	const driversAreAssigned = MealProposalsDeliveryDrivers.length > 0;

	const classes = useStyles({ driversAreAssigned });

	const tooltip = driversAreAssigned ? (
		<>
			<Typography align="center">Assigned Drivers:</Typography>
			{MealProposalsDeliveryDrivers.map(
				({ DeliveryDriver: { id, fullName } }) => (
					<Typography align="center" key={id}>
						{fullName}
					</Typography>
				)
			)}
		</>
	) : (
		<Typography>Drivers have not been assigned.</Typography>
	);

	return (
		<Tooltip title={tooltip}>
			<Divider className={classes.divider} />
		</Tooltip>
	);
};

DeliveryDriverAssignedBar.fragments = {
	details: gql`
		fragment DeliveryDriverAssignedBar on MealProposals {
			MealProposalsDeliveryDrivers {
				DeliveryDriver {
					id
					fullName
				}
			}
		}
	`,
};

export default DeliveryDriverAssignedBar;
