import React from 'react';
import { AccessTime, Cancel, Check, Sync } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

import Status from 'components/content/Status';

const useStyles = makeStyles({
	rotate: {
		animation: '$spin 2000ms linear infinite',
	},
	'@keyframes spin': {
		'0%': {
			transform: 'rotate(0deg)',
		},
		'100%': {
			transform: 'rotate(360deg)',
		},
	},
});

const StatusIcon = ({ status, ...props }) => {
	const classes = useStyles();

	switch (status) {
		case 'Pending':
			return <Status Icon={AccessTime} type="warning" {...props} />;
		case 'Syncing':
			return (
				<Status
					Icon={Sync}
					type="warning"
					contentClassName={classes.rotate}
					{...props}
				/>
			);
		case 'Succeeded':
			return <Status Icon={Check} type="success" {...props} />;
		case 'Failed':
			return <Status Icon={Cancel} type="error" {...props} />;
		default:
			return <>No icon</>;
	}
};

export default StatusIcon;
