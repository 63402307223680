import Create from 'pages/accounting-settings/Create';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'accountingSettingsCreate',
		path: '/create',
		component: Create,
		permissions: ['accounting-settings:create'],
		exact: true,
	},
];

export default prependUrlPath('accounting-settings', routes);
