import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { CardContent, Tooltip } from '@material-ui/core';
import {
	DateRangeSelector,
	GridContainer,
} from '@sporkbytes/material-ui-kit-react';
import { getAllUpcoming } from '@sporkbytes/time-utils';
import { getClientApproved } from '@sporkbytes/meal-option-utils';
import {
	clientHasApproved,
	clientHasConfirmed,
} from '@sporkbytes/meal-proposal-utils';

import ApprovedConfirmedStatus from 'components/content/ApprovedConfirmedStatus';
import DateOutput from 'components/content/DateOutput';
import DetailPageLink from 'components/routing/DetailPageLink';
import RemoteTable from 'components/content/RemoteTable';

import { pick } from 'services/utilities';

const getMealProposalsQuery = () => gql`
	query ContactMeals(
		$ContactId: uuid!
		$beginDate: timestamptz
		$endDate: timestamptz
		$limit: Int!
		$offset: Int!
		$orderNumberTextOrderBy: order_by
		$clientNameOrderBy: order_by
		$deliveryDateOrderBy: order_by
		$headCountOrderBy: order_by
	) {
		MealProposals(
			order_by: {
				orderNumberText: $orderNumberTextOrderBy
				ClientLocation: { Client: { name: $clientNameOrderBy } }
				deliveryDate: $deliveryDateOrderBy
				headCount: $headCountOrderBy
			}
			limit: $limit
			offset: $offset
			where: {
				deliveryDate: { _gte: $beginDate, _lte: $endDate }
				MealProposalsContacts: { ContactId: { _eq: $ContactId } }
			}
		) {
			deliveryDate
			id
			headCount
			name
			orderNumberText
			status
			ClientLocation {
				id
				friendlyName
				timezone
				Client {
					id
					name
				}
			}
			MealOptions(where: { approved: { _eq: true } }) {
				approved
				confirmed
				PurchaseOrders(order_by: { pickupTime: asc }) {
					approved
					confirmed
					id
					pickupTime
					PartnerLocation {
						timezone
						friendlyName
						Partner {
							id
							name
						}
					}
				}
			}
		}
		MealProposals_aggregate(
			where: {
				deliveryDate: { _gte: $beginDate, _lte: $endDate }
				MealProposalsContacts: { ContactId: { _eq: $ContactId } }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const MealProposalsTable = ({ ContactId }) => {
	const [dateRange, setDateRange] = useState(getAllUpcoming());
	const ref = useRef();

	return (
		<>
			<CardContent>
				<DateRangeSelector
					defaultDateRange={dateRange}
					onChange={setDateRange}
				/>
			</CardContent>
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Order #',
						field: 'orderNumberText',
						render: ({ id, orderNumberText }) => (
							<DetailPageLink
								entityType="MealProposals"
								id={id}
								text={orderNumberText}
							/>
						),
					},
					{
						title: 'Client',
						field: 'clientName',
						render: ({
							ClientLocation: { Client, ...clientLocation },
							...mealProposal
						}) => (
							<GridContainer alignItems="center">
								<ApprovedConfirmedStatus
									approved={clientHasApproved(mealProposal)}
									confirmed={clientHasConfirmed(mealProposal)}
									showText={false}
								/>
								<>
									<p>
										<DetailPageLink
											entityType="Clients"
											id={Client.id}
											text={Client.name}
										/>
									</p>
									<p>
										<DetailPageLink
											entityType="ClientLocations"
											id={clientLocation.id}
											text={clientLocation.friendlyName}
										/>
									</p>
								</>
							</GridContainer>
						),
					},
					{
						title: 'Delivery Date',
						field: 'deliveryDate',
						render: ({
							ClientLocation: { timezone },
							deliveryDate,
						}) => (
							<DateOutput
								date={deliveryDate}
								timezone={timezone}
								formatter="dateAndTimeMedium"
							/>
						),
					},
					{
						title: 'Head Count',
						field: 'headCount',
						type: 'numeric', // This will left align the head count column
					},
					{
						title: 'Pickups',
						field: 'partner',
						sorting: false,
						render: ({ MealOptions }) => {
							const approvedMealOption =
								getClientApproved(MealOptions);

							return (
								approvedMealOption?.PurchaseOrders?.map(
									purchaseOrder => {
										const {
											approved,
											confirmed,
											id,
											PartnerLocation: {
												Partner: {
													id: PartnerId,
													name,
												},
												friendlyName,
												timezone,
											},
											pickupTime,
										} = purchaseOrder;

										return (
											<GridContainer
												alignItems="center"
												key={id}
											>
												<ApprovedConfirmedStatus
													approved={approved}
													confirmed={confirmed}
													showText={false}
												/>
												<Tooltip title={friendlyName}>
													<p>
														<DetailPageLink
															entityType="Partners"
															id={PartnerId}
															text={name}
														/>{' '}
														-{' '}
														<DateOutput
															date={pickupTime}
															timezone={timezone}
															formatter="time"
														/>
													</p>
												</Tooltip>
											</GridContainer>
										);
									}
								) ?? null
							);
						},
					},
				]}
				defaultOrderBy="deliveryDate"
				defaultOrderDirection="asc"
				getQuery={getMealProposalsQuery}
				options={{
					search: false,
				}}
				queryEntity="MealProposals"
				variables={{
					ContactId,
					...pick(dateRange, ['beginDate', 'endDate']),
				}}
			/>
		</>
	);
};

MealProposalsTable.propTypes = {
	ContactId: PropTypes.string.isRequired,
};

export default MealProposalsTable;
