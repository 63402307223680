import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { put } from '@sporkbytes/api-client/MealCategories';

import FormWrapper from 'components/forms/FormWrapper';
import NameForm from 'components/forms/NameForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const MEAL_CATEGORY_QUERY = gql`
	query MealCategoryEdit($id: uuid!) {
		MealCategories_by_pk(id: $id) {
			name
		}
	}
`;

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ MealCategories_by_pk: { name } }) => `Edit ${name}`}
			query={MEAL_CATEGORY_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					MealCategories_by_pk: { name },
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${name}`} />
					<FormWrapper
						form={NameForm}
						initialValues={{
							name,
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
