import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';

const LoadingStateContainer = ({ children, height, loading, ...props }) => {
	const ref = useRef();

	return (
		<div ref={ref} {...props}>
			{loading ? (
				<CenteredCircularProgress
					containerProps={{
						style: { height: height ?? ref?.current?.offsetHeight },
					}}
				/>
			) : typeof children === 'function' ? (
				children()
			) : (
				children
			)}
		</div>
	);
};

LoadingStateContainer.propTypes = {
	height: PropTypes.number,
	loading: PropTypes.bool.isRequired,
};

export default LoadingStateContainer;
