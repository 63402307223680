import React from 'react';
import { PhoneNumber } from '@sporkbytes/material-ui-kit-react';
import gql from 'graphql-tag';

import BooleanStatusIndicator from 'components/utilities/BooleanStatusIndicator';
import DefinitionList from 'components/content/DefinitionList';
import PartnerLocationOrderingDetails from 'components/partner-locations/PartnerLocationOrderingDetails';
import RichText from 'components/content/RichText';

const PartnerLocationDetails = ({
	partnerLocation: {
		automatedEmailConfirmation,
		name,
		phoneNumber,
		pickupInstructions,
		...partnerLocation
	},
}) => {
	return (
		<>
			<DefinitionList
				title="Basic Info"
				items={[
					{
						label: 'Name',
						value: name,
					},
					{
						label: 'Phone Number',
						value: phoneNumber,
						render: PhoneNumber,
					},
				]}
			/>
			<RichText title="Pickup Instructions" body={pickupInstructions} />
			<PartnerLocationOrderingDetails partnerLocation={partnerLocation} />
			<DefinitionList
				title="Automated Emails"
				items={[
					{
						label: 'Automated Email Confirmation',
						value: automatedEmailConfirmation,
						render: BooleanStatusIndicator,
					},
				]}
			/>
		</>
	);
};

PartnerLocationDetails.fragments = {
	details: gql`
		fragment PartnerLocationDetails on PartnerLocations {
			automatedEmailConfirmation
			name
			phoneNumber
			pickupInstructions
			...PartnerLocationOrderingDetails
		}
		${PartnerLocationOrderingDetails.fragments.details}
	`,
};

export default PartnerLocationDetails;
