import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import {
	Grid,
	IconButton,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { Warning, DeleteForever } from '@material-ui/icons';
import {
	ConfirmationDialog,
	Currency,
} from '@sporkbytes/material-ui-kit-react';
import { canEdit } from '@sporkbytes/purchase-order-utils';
import { itemTotalAfterDiscount } from '@sporkbytes/purchase-order-menu-item-utils';
import {
	costPerPerson,
	costPerPersonTooHigh,
	totalClientPays,
} from '@sporkbytes/meal-option-utils';
import { deletePurchaseOrderMenuItems } from '@sporkbytes/api-client/PurchaseOrderMenuItems';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import DateOutput from 'components/content/DateOutput';
import InlineContent from 'components/content/InlineContent';
import PurchaseOrderMenuItemEditDialogForm from 'components/purchase-order-menu-items/PurchaseOrderMenuItemEditDialogForm';
import PurchaseOrderMenuSectionQuantityTableCell from 'components/purchase-order-menu-sections/PurchaseOrderMenuSectionQuantityTableCell';
import TableCellFeatured from 'components/content/TableCellFeatured';
import TableCellNumeric from 'components/content/TableCellNumeric';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const MEAL_OPTION_SUBSCRIPTION = gql`
	subscription MealOptionQuickSummary($id: uuid!) {
		MealOptions_by_pk(id: $id) {
			PurchaseOrders(order_by: { pickupTime: asc }) {
				id
				pickupTime
				PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
					id
					name
					PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
						id
						costToClient
						discount
						quantity
						PartnerMenuItem {
							friendlyName
							size
						}
					}
				}
				PartnerLocation {
					timezone
					Partner {
						name
					}
				}
			}
			MealProposal {
				budgetPerPerson
				headCount
				totalBudget
				MealProposalFees {
					amount
					discount
					type
				}
			}
		}
	}
`;

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(2),
		'& + &': {
			paddingTop: 0,
		},
	},
	itemRow: {
		cursor: ({ canEditItems }) => canEditItems && 'pointer',
	},
	keyValuePair: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	tableHead: {
		backgroundColor: theme.palette.primary[100],
		position: 'sticky',
		top: 0,
		zIndex: theme.layers.fixedContent,
	},
	warningIcon: {
		fill: theme.palette.warning[900],
	},
}));

const MealOptionQuickSummary = ({ id }) => {
	const { role, userCan } = useAuth();
	const { showDialog } = useStore();
	const canDeleteItems = userCan(['purchase-order-menu-items:delete']);
	const canEditItems = userCan(['purchase-order-menu-items:edit']);
	const classes = useStyles({
		canEditItems,
	});
	const { data, loading } = useSubscription(MEAL_OPTION_SUBSCRIPTION, {
		variables: { id },
	});

	if (loading) {
		return <CenteredCircularProgress />;
	}

	const mealOption = data.MealOptions_by_pk;
	const clientTotal = totalClientPays(mealOption, mealOption.MealProposal);
	const overTotalBudget =
		!!mealOption.MealProposal.totalBudget &&
		clientTotal > mealOption.MealProposal.totalBudget;

	return (
		<>
			<div className={classes.container}>
				<div className={classes.keyValuePair}>
					<Typography>
						<InlineContent element="span">Total</InlineContent>
						{overTotalBudget && (
							<Tooltip
								title={
									<span>
										You are over the total budget of{' '}
										<Currency
											value={
												mealOption.MealProposal
													.totalBudget
											}
										/>
										.
									</span>
								}
							>
								<Warning className={classes.warningIcon} />
							</Tooltip>
						)}
					</Typography>
					<Currency value={clientTotal} />
				</div>
				<div className={classes.keyValuePair}>
					<Typography>
						<InlineContent element="span">
							Cost Per Person
						</InlineContent>
						{costPerPersonTooHigh(
							mealOption,
							mealOption.MealProposal
						) && (
							<Tooltip
								title={
									<span>
										You are over the per person budget of{' '}
										<Currency
											value={
												mealOption.MealProposal
													.budgetPerPerson
											}
										/>
										.
									</span>
								}
							>
								<Warning className={classes.warningIcon} />
							</Tooltip>
						)}
					</Typography>
					<Currency
						value={costPerPerson(
							mealOption,
							mealOption.MealProposal
						)}
					/>
				</div>
			</div>
			<div className={classes.container}>
				{mealOption.PurchaseOrders.map(
					({
						id,
						PartnerLocation: {
							timezone,
							Partner: { name },
						},
						pickupTime,
					}) => (
						<div key={id} className={classes.keyValuePair}>
							<Typography>{name}</Typography>
							<DateOutput
								date={pickupTime}
								formatter="time"
								timezone={timezone}
							/>
						</div>
					)
				)}
			</div>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className={classes.tableHead}>
							Name
						</TableCell>
						<TableCell className={classes.tableHead} align="right">
							Quantity
						</TableCell>
						<TableCell className={classes.tableHead} align="right">
							Total
						</TableCell>
					</TableRow>
				</TableHead>
				{mealOption.PurchaseOrders.map(
					({ PurchaseOrderMenuSections, ...purchaseOrder }) =>
						PurchaseOrderMenuSections.map(
							purchaseOrderMenuSection => (
								<TableBody key={purchaseOrderMenuSection.id}>
									<TableRow>
										<TableCellFeatured>
											{purchaseOrderMenuSection.name}
										</TableCellFeatured>
										<PurchaseOrderMenuSectionQuantityTableCell
											items={
												purchaseOrderMenuSection.PurchaseOrderMenuItems
											}
										/>
										<TableCell />
									</TableRow>
									{purchaseOrderMenuSection.PurchaseOrderMenuItems.map(
										purchaseOrderMenuItem => (
											<TableRow
												key={purchaseOrderMenuItem.id}
												onClick={
													canEditItems &&
													canEdit(purchaseOrder, role)
														? () =>
																showDialog(
																	PurchaseOrderMenuItemEditDialogForm,
																	{
																		id: purchaseOrderMenuItem.id,
																	}
																)
														: undefined
												}
												hover={
													canEditItems &&
													canEdit(purchaseOrder, role)
												}
												className={classes.itemRow}
											>
												<TableCell>
													<Grid
														alignItems="center"
														container
														justify="space-between"
														spacing={1}
														wrap="nowrap"
													>
														<Grid item>
															{
																purchaseOrderMenuItem
																	.PartnerMenuItem
																	.friendlyName
															}
															{purchaseOrderMenuItem
																.PartnerMenuItem
																.size &&
																` (${purchaseOrderMenuItem.PartnerMenuItem.size})`}
														</Grid>
														{canDeleteItems &&
															canEdit(
																purchaseOrder,
																role
															) && (
																<Grid item>
																	<IconButton
																		onClick={e => {
																			e.stopPropagation();
																			showDialog(
																				ConfirmationDialog,
																				{
																					text: `Are you sure you want to delete ${purchaseOrderMenuItem.PartnerMenuItem.friendlyName}?`,
																					onConfirmation:
																						() =>
																							deletePurchaseOrderMenuItems(
																								axios,
																								purchaseOrderMenuItem.id
																							),
																				}
																			);
																		}}
																	>
																		<DeleteForever fontSize="small" />
																	</IconButton>
																</Grid>
															)}
													</Grid>
												</TableCell>
												<TableCellNumeric
													value={
														purchaseOrderMenuItem.quantity
													}
												/>
												<TableCellNumeric
													format="currency"
													value={itemTotalAfterDiscount(
														purchaseOrderMenuItem,
														'costToClient'
													)}
												/>
											</TableRow>
										)
									)}
								</TableBody>
							)
						)
				)}
			</Table>
		</>
	);
};

MealOptionQuickSummary.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MealOptionQuickSummary;
