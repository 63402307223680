import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { setDeliveryGear } from '@sporkbytes/api-client/MealProposals';

import ChecklistDialogForm from 'components/forms/ChecklistDialogForm';

import axios from 'services/data';

const DELIVERY_GEAR_QUERY = gql`
	query MealProposalAssignDeliveryGearDialog(
		$id: uuid!
		$SporkLocationId: uuid!
	) {
		MealProposals_by_pk(id: $id) {
			MealProposalsDeliveryGear {
				DeliveryGear {
					id
					name
				}
			}
		}
		DeliveryGearTypes(
			where: {
				DeliveryGear: { SporkLocationId: { _eq: $SporkLocationId } }
			}
		) {
			name
			DeliveryGear(
				where: {
					_or: [
						{ active: { _eq: true } }
						{
							MealProposalsDeliveryGear: {
								DeliveryGearId: { _eq: $id }
							}
						}
					]
					SporkLocationId: { _eq: $SporkLocationId }
				}
				order_by: { name: asc }
			) {
				id
				name
			}
		}
	}
`;

const MealProposalAssignDeliveryGearDialog = ({
	id,
	SporkLocationId,
	...props
}) => {
	const { data, loading } = useQuery(DELIVERY_GEAR_QUERY, {
		variables: {
			id,
			SporkLocationId,
		},
	});

	return (
		<ChecklistDialogForm
			{...props}
			title="Assign Delivery Gear to this Meal Proposal"
			submitButtonContent="Assign"
			loading={loading}
			initialValues={data?.MealProposals_by_pk.MealProposalsDeliveryGear.map(
				({ DeliveryGear: { id } }) => id
			)}
			options={data?.DeliveryGearTypes.flatMap(
				({ name, DeliveryGear }) => [
					{
						type: 'group',
						id: name,
						label: name,
					},
					...DeliveryGear.map(({ id, name }) => ({
						id,
						label: name,
					})),
				]
			)}
			onSubmit={deliveryGearIds =>
				setDeliveryGear(axios, id, {
					data: deliveryGearIds.map(id => ({
						id,
					})),
				})
			}
		/>
	);
};

MealProposalAssignDeliveryGearDialog.propTypes = {
	id: PropTypes.string.isRequired,
	SporkLocationId: PropTypes.string.isRequired,
};

export default MealProposalAssignDeliveryGearDialog;
