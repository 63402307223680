import React from 'react';
import gql from 'graphql-tag';
import { TableCell, TableRow } from '@material-ui/core';
import { sporkCommission } from '@sporkbytes/meal-option-utils';

import TableCellNumeric from 'components/content/TableCellNumeric';

const MealOptionSporkCommissionLineItem = ({ mealOption }) => (
	<TableRow>
		<TableCell colSpan="3">Spork Commission</TableCell>
		<TableCellNumeric
			format="currency"
			value={sporkCommission(mealOption)}
		/>
	</TableRow>
);

MealOptionSporkCommissionLineItem.fragments = {
	details: gql`
		fragment MealOptionSporkCommissionLineItemDetails on MealOptions {
			PurchaseOrders(order_by: { pickupTime: asc }) {
				sporkCommissionPercent
				PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
					PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
						discount
						paidToPartner
						quantity
					}
				}
			}
		}
	`,
};

export default MealOptionSporkCommissionLineItem;
