import React from 'react';
import { useParams } from 'react-router-dom';
import { get } from '@sporkbytes/api-client/PublicPartners';

import LoadingPage from 'components/content/LoadingPage';
import MenuItemAuditing from './MenuItemAuditing';
import NotFoundPage from 'components/content/NotFoundPage';
import PageHeader from 'components/content/PageHeader';
import PurchaseOrders from './PurchaseOrders';
import Page from 'components/layout/Page';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';

import useRequest from 'hooks/useRequest';

import axios from 'services/data';

const PartnerProfile = () => {
	const { publicProfileId } = useParams();
	const { data, loading } = useRequest(() => get(axios, publicProfileId));

	if (loading) {
		return <LoadingPage />;
	}

	if (!data) {
		return (
			<NotFoundPage
				body="No profile was found with that ID. It's possible that it has changed."
				title="Partner Not Found"
			/>
		);
	}

	return (
		<Page title="Profile">
			<PageHeader headerText={data.name} imgUrl={data.logoUrl} />
			<QueryStringSyncedTabs
				id="tab"
				tabs={[
					{
						id: 'purchase-orders',
						label: 'Purchase Orders',
						content: <PurchaseOrders />,
					},
					{
						id: 'menu-item-auditing',
						label: 'Menu Item Auditing',
						content: <MenuItemAuditing />,
					},
				]}
			/>
		</Page>
	);
};

export default PartnerProfile;
