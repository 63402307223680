import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import Helmet from 'react-helmet';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';

const useStyles = makeStyles(theme => ({
	container: {
		margin: 'auto',
		maxWidth: ({ fullWidth }) =>
			fullWidth ? '100%' : theme.sizes.contentMaxWidth,
		padding: ({ fullWidth }) => (fullWidth ? 0 : theme.spacing(2)),
		'@media print': {
			padding: `0 !important`, // will not override the above without !important
		},
	},
}));

const Container = ({ children, fullWidth, result, title, className }) => {
	const classes = useStyles({ fullWidth });

	return result?.loading ? (
		<CenteredCircularProgress />
	) : (
		<>
			<Helmet
				title={`${
					typeof title === 'function' ? title(result?.data) : title
				} - Spork Bytes`}
			/>
			<div className={`${classes.container} ${className}`}>
				{typeof children === 'function' ? children(result) : children}
			</div>
		</>
	);
};

const PageWithQuery = ({ query, variables, ...props }) => {
	const result = useQuery(query, {
		variables,
	});

	return <Container result={result} {...props} />;
};

const PageWithSubscription = ({ subscription, variables, ...props }) => {
	const result = useSubscription(subscription, {
		variables,
	});

	return <Container result={result} {...props} />;
};

const Page = ({ query, subscription, ...props }) => {
	const shouldIssueQuery = !!query;
	const shouldIssueSubscription = !!subscription;

	if (shouldIssueQuery) {
		return <PageWithQuery query={query} {...props} />;
	}
	if (shouldIssueSubscription) {
		return <PageWithSubscription subscription={subscription} {...props} />;
	}

	return <Container {...props} />;
};

Page.propTypes = {
	className: PropTypes.string,
	fullWidth: PropTypes.bool,
	query: PropTypes.object,
	subscription: PropTypes.object,
	title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
	variables: PropTypes.object,
};

Page.defaultProps = {
	className: '',
};

export default Page;
