import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { AuthProvider } from 'services/auth';
import history from 'services/history';
import { store } from 'models/store';
import { version } from '../package.json';

import App from 'App';
import * as serviceWorker from 'serviceWorker';

if (process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		debug: process.env.NODE_ENV !== 'production',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.browserProfilingIntegration(),
		],
		profilesSampleRate: 0.1,
		release: version,
		replaysOnErrorSampleRate: 1.0,
		tracesSampleRate: 0.1,
		tunnel: '/api/sentry-tunnel',
	});
}

const onRedirectCallback = appState => {
	history.push(
		appState && appState.targetUrl
			? appState.targetUrl
			: window.location.pathname
	);
};

const render = Component => {
	ReactDOM.render(
		<AuthProvider
			domain={process.env.REACT_APP_AUTH0_DOMAIN}
			client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
			redirect_uri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
			onAuthenticated={store.getState().setActiveSporkLocations}
			audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`}
		>
			{/* We use a custom history instance to be able to redirect users after signing in. */}
			<Router history={history}>
				<Component />
			</Router>
		</AuthProvider>,
		document.getElementById('root')
	);
};

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// Hot Module Reloading mechanism from https://medium.com/@brianhan/hot-reloading-cra-without-eject-b54af352c642
if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default;
		render(NextApp);
	});
}
