import Create from 'pages/serviced-states/Create';
import Edit from 'pages/serviced-states/Edit';
import ServicedStates from 'pages/serviced-states/ServicedStates';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'servicedStates',
		path: '',
		component: ServicedStates,
		permissions: ['serviced-states:view'],
		exact: true,
	},
	{
		name: 'servicedStatesEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['serviced-states:edit'],
		exact: true,
	},
	{
		name: 'servicedStatesCreate',
		path: '/create',
		component: Create,
		permissions: ['serviced-states:create'],
		exact: true,
	},
];

export default prependUrlPath('serviced-states', routes);
