import React from 'react';
import gql from 'graphql-tag';
import { useHistory, useParams } from 'react-router-dom';
import {
	deletePartnerMenuSections,
	put,
} from '@sporkbytes/api-client/PartnerMenuSections';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PartnerMenuSectionForm from 'components/partner-menu-sections/PartnerMenuSectionForm';

// TODO: use fragments here
const PARTNER_MENU_SECTION_QUERY = gql`
	query PartnerMenuSectionEdit($id: uuid!) {
		PartnerMenuSections_by_pk(id: $id) {
			name
			printableName
			howToOrder
			PartnerId
			Partner {
				logoUrl
				name
			}
		}
	}
`;

const Edit = () => {
	const { id } = useParams();
	const { pushNamedRoute } = useHistory();

	return (
		<Page
			title={({ PartnerMenuSections_by_pk: { name } }) => `Edit ${name}`}
			query={PARTNER_MENU_SECTION_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					PartnerMenuSections_by_pk: {
						name,
						PartnerId,
						Partner: { logoUrl, name: PartnerName },
						...data
					},
				},
			}) => (
				<>
					<PageHeader headerText={PartnerName} imgUrl={logoUrl} />
					<PageSubheader>Edit {name}</PageSubheader>
					<FormWrapper
						form={PartnerMenuSectionForm}
						initialValues={{
							name,
							...data,
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
						onDelete={axios =>
							deletePartnerMenuSections(axios, id).then(() =>
								pushNamedRoute('partnersDetail', {
									id: PartnerId,
									tab: 'menu',
								})
							)
						}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
