import React from 'react';
import gql from 'graphql-tag';
import { ThumbUp, ThumbDown } from '@material-ui/icons';
import { Integer, GridContainer } from '@sporkbytes/material-ui-kit-react';

import InlineContent from 'components/content/InlineContent';
import MetricCard from 'components/content/MetricCard';

const MetricCardSporkReviews = ({ mealProposals }) => {
	const sporkReviews = mealProposals.flatMap(
		({ SporkReviewAggregatedAnswers }) => SporkReviewAggregatedAnswers
	);

	const sporkReviewsCount = sporkReviews.length;

	const { positiveAnswers, negativeAnswers } = sporkReviews.reduce(
		({ positiveAnswers, negativeAnswers }, review) => ({
			negativeAnswers: negativeAnswers + review.negativeAnswers,
			positiveAnswers: positiveAnswers + review.positiveAnswers,
		}),
		{ positiveAnswers: 0, negativeAnswers: 0 }
	);

	return (
		<MetricCard
			label="Spork Reviews"
			value={<Integer value={sporkReviewsCount} />}
			footer={
				<GridContainer columns="none" justify="center" spacing={4}>
					<div>
						<InlineContent>
							<Integer value={positiveAnswers} />
						</InlineContent>
						<ThumbUp />
					</div>
					<div>
						<InlineContent>
							<Integer value={negativeAnswers} />
						</InlineContent>
						<ThumbDown />
					</div>
				</GridContainer>
			}
			showFooter={sporkReviewsCount > 0}
		/>
	);
};

MetricCardSporkReviews.fragments = {
	details: gql`
		fragment MetricCardSporkReviewsDetails on MealProposals {
			SporkReviewAggregatedAnswers {
				positiveAnswers
				negativeAnswers
			}
		}
	`,
};

export default MetricCardSporkReviews;
