import React, { useContext, useLayoutEffect, useState } from 'react';
import gql from 'graphql-tag';
import {
	Button,
	Drawer,
	IconButton,
	makeStyles,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { AccessTime, Help, People } from '@material-ui/icons';
import { Dialog, GridContainer, Tabs } from '@sporkbytes/material-ui-kit-react';

import AccordionGroup from 'components/content/AccordionGroup';
import AccordionItem from 'components/content/AccordionItem';
import DateOutput from 'components/content/DateOutput';
import HeadCount from 'components/content/HeadCount';
import InlineContent from 'components/content/InlineContent';
import MealOptionQuickSummary from 'components/meal-options/MealOptionQuickSummary';
import MealProposalsTable from 'components/meal-proposals/MealProposalsTable';
import PartnerLocationOrderingDetails from 'components/partner-locations/PartnerLocationOrderingDetails';
import PurchaseOrderReviewsTable from 'components/purchase-order-reviews/PurchaseOrderReviewsTable';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';
import RichText from 'components/content/RichText';
import SporkReviewsTable from 'components/spork-reviews/SporkReviewsTable';

import { PartnerContext } from 'context/PartnerContext';

import { useStore } from 'models/store';

const drawerVisibleBreakpoint = 'lg';
const drawerWidth = {
	highResolution: '25%',
	lowResolution: '90%',
};
const useStyles = makeStyles(theme => ({
	container: {
		[theme.breakpoints.up(drawerVisibleBreakpoint)]: {
			flexShrink: 0,
			width: drawerWidth.highResolution,
			zIndex: theme.layers.content,
		},
	},
	content: {
		padding: theme.spacing(2),
	},
	drawerPaper: {
		width: drawerWidth.lowResolution,
		[theme.breakpoints.up(drawerVisibleBreakpoint)]: {
			height: `calc(100% - ${theme.sizes.appToolbar}px - ${theme.sizes.footer}px)`,
			top: theme.sizes.appToolbar,
			width: drawerWidth.highResolution,
		},
	},
	header: {
		backgroundColor: theme.palette.common.white,
		display: 'flex',
		justifyContent: 'space-around',
		padding: theme.spacing(2),
		position: 'sticky',
		top: 0,
		zIndex: theme.layers.fixedContent,
	},
	helpButton: {
		position: 'fixed',
		right: theme.spacing(1),
		top: theme.sizes.appToolbar + theme.spacing(1),
		zIndex: theme.layers.fixedContent,
		[theme.breakpoints.up(drawerVisibleBreakpoint)]: {
			display: 'none',
		},
	},
	helpCursor: {
		cursor: 'help',
	},
	quickSummary: {
		bottom: 0,
		flexGrow: 1,
		overflowY: 'auto',
	},
}));

const MealProposalHelpDrawer = ({ mealProposal }) => {
	const { currentPartner } = useContext(PartnerContext);
	const { showDialog } = useStore();
	const theme = useTheme();
	const drawerAlwaysVisible = useMediaQuery(
		theme.breakpoints.up(drawerVisibleBreakpoint)
	);
	const [lowResolutionDrawerOpen, setLowResolutionDrawerOpen] =
		useState(false);
	const classes = useStyles();

	const { deliveryDate, dietaryRestrictions, headCount } = mealProposal;
	const { howToOrder, timezone, Client } = mealProposal.ClientLocation;
	const formattedHeadCount = (
		<HeadCount
			component="span"
			numberOnly={true}
			value={headCount}
			variant="inherit"
		/>
	);

	const handleDrawerToggle = () => {
		setLowResolutionDrawerOpen(!lowResolutionDrawerOpen);
	};

	useLayoutEffect(() => {
		if (drawerAlwaysVisible && lowResolutionDrawerOpen) {
			setLowResolutionDrawerOpen(false);
		}
	}, [drawerAlwaysVisible, lowResolutionDrawerOpen]);

	const drawer = (
		<>
			<header className={classes.header}>
				<Tooltip
					title={<span>Head count of {formattedHeadCount}</span>}
					className={classes.helpCursor}
				>
					<Typography component="h2" variant="h6">
						<InlineContent>
							<People />
						</InlineContent>
						{formattedHeadCount}
					</Typography>
				</Tooltip>
				<Tooltip
					title={
						<span>
							Ready to eat time of{' '}
							<DateOutput
								date={deliveryDate}
								timezone={timezone}
								component="span"
							/>
						</span>
					}
					className={classes.helpCursor}
				>
					<Typography component="h2">
						<InlineContent>
							<AccessTime />
						</InlineContent>
						<DateOutput
							date={deliveryDate}
							timezone={timezone}
							formatter="dateAndTimeShort"
							component="span"
							variant="h6"
						/>
					</Typography>
				</Tooltip>
			</header>
			<div className={classes.content}>
				<AccordionGroup>
					{dietaryRestrictions && (
						<AccordionItem id="dietary-restrictions">
							<RichText body={dietaryRestrictions} />
						</AccordionItem>
					)}
					{howToOrder && (
						<AccordionItem
							id="how-to-order-client"
							title={`How to Order - ${Client.name}`}
						>
							<RichText body={howToOrder} />
						</AccordionItem>
					)}
					{currentPartner && currentPartner.PartnerLocations[0] && (
						<AccordionItem
							id="how-to-order-partner"
							title={`How to Order - ${currentPartner.name}`}
						>
							<PartnerLocationOrderingDetails
								partnerLocation={
									currentPartner.PartnerLocations[0]
								}
							/>
						</AccordionItem>
					)}
				</AccordionGroup>
				<GridContainer>
					<Button
						color="primary"
						onClick={() =>
							showDialog(props => (
								<Dialog
									{...props}
									fullWidth={true}
									maxWidth="lg"
								>
									<Tabs
										tabs={[
											{
												id: 'spork-reviews',
												label: 'Spork Reviews',
												content: (
													<SporkReviewsTable
														variables={{
															ClientId: Client.id,
														}}
													/>
												),
											},
											{
												id: 'partner-reviews',
												label: 'Partner Reviews',
												content: (
													<PurchaseOrderReviewsTable
														variables={{
															ClientId: Client.id,
														}}
													/>
												),
											},
										]}
									/>
								</Dialog>
							))
						}
					>
						Reviews for {Client.name}
					</Button>
					<Button
						color="primary"
						onClick={() =>
							showDialog(props => (
								<Dialog
									{...props}
									fullWidth={true}
									maxWidth="lg"
								>
									<MealProposalsTable
										variables={{
											ClientId: Client.id,
											MealProposalId: mealProposal.id,
										}}
									/>
								</Dialog>
							))
						}
					>
						Meals for {Client.name}
					</Button>
				</GridContainer>
			</div>
			<div className={classes.quickSummary}>
				<QueryStringSyncedTabs
					id="meal-option"
					tabs={mealProposal.MealOptions.map(({ id, name }) => ({
						id,
						label: name,
						content: <MealOptionQuickSummary id={id} />,
					}))}
				/>
			</div>
		</>
	);

	return (
		<section className={classes.container} aria-label="Help">
			{!drawerAlwaysVisible && (
				<>
					<IconButton
						color="inherit"
						aria-label="View Help"
						edge="start"
						onClick={handleDrawerToggle}
						className={classes.helpButton}
					>
						<Help />
					</IconButton>
					<Drawer
						variant="temporary"
						anchor="right"
						open={lowResolutionDrawerOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</>
			)}
			{drawerAlwaysVisible && (
				<Drawer
					classes={{
						paper: classes.drawerPaper,
					}}
					variant="permanent"
					open
					anchor="right"
				>
					{drawer}
				</Drawer>
			)}
		</section>
	);
};

MealProposalHelpDrawer.fragments = {
	details: gql`
		# id is required for each entity, otherwise we get an error that "data is not defined," despite us not caching results like others with this issue
		# see https://github.com/apollographql/react-apollo/issues/1003
		fragment MealProposalHelpDrawerDetails on MealProposals {
			deliveryDate
			dietaryRestrictions
			headCount
			id
			ClientLocation {
				id
				howToOrder
				timezone
				Client {
					id
					name
				}
			}
			MealOptions(order_by: { approved: desc, createdAt: asc }) {
				id
				name
			}
		}
	`,
};

export default MealProposalHelpDrawer;
