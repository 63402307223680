import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import ListItem from 'components/utilities/ListItem';

import { useAuth } from 'services/auth';

const useStyles = makeStyles(theme => ({
	active: {
		background: theme.palette.lightGray,
	},
}));

const NavItem = ({
	pathVariables,
	permissions = [],
	routeName,
	onClick,
	onClose,
	openInNewWindow,
	...props
}) => {
	const { userCan } = useAuth();
	const history = useHistory();
	const classes = useStyles();

	return userCan(permissions) ? (
		<ListItem
			key={props.text}
			onClick={() => {
				if (onClick) {
					onClick();
				} else if (!history.isOnRoute(routeName)) {
					const routeFunction = openInNewWindow
						? history.openNamedRoute
						: history.pushNamedRoute;

					routeFunction(
						routeName,
						typeof pathVariables === 'function' && pathVariables()
					); // pathVariables need to be functions to allow for dynamic variables based on time of click
				}

				onClose();
			}}
			className={history.isOnRoute(routeName) ? classes.active : ''}
			button
			{...props}
		/>
	) : null;
};

NavItem.propTypes = {
	onClick: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	openInNewWindow: PropTypes.bool,
	pathVariables: PropTypes.func,
	permissions: PropTypes.arrayOf(PropTypes.string),
	routeName: PropTypes.string,
};

export default NavItem;
