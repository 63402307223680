import React from 'react';
import PropTypes from 'prop-types';
import { Check, DoneAll, AccessTime } from '@material-ui/icons';

import Status from 'components/content/Status';

const ApprovedConfirmedStatus = ({
	approved,
	confirmed,
	setType,
	showText,
	text,
	...props
}) => {
	let Icon;
	let type;
	let tooltip;
	let status;

	if (confirmed) {
		Icon = DoneAll;
		status = 'Confirmed';
		if (setType) {
			type = 'success';
		}
	} else if (approved) {
		Icon = Check;
		status = 'Approved';
		if (setType) {
			type = 'success';
		}
	} else {
		Icon = AccessTime;
		status = 'Waiting Approval';
		if (setType) {
			type = 'warning';
		}
	}

	if (!text && showText) {
		text = status;
	} else {
		tooltip = status;
	}

	return (
		<Status
			{...props}
			Icon={Icon}
			showText={showText}
			tooltip={tooltip}
			text={text}
			type={type}
		/>
	);
};

ApprovedConfirmedStatus.propTypes = {
	approved: PropTypes.bool.isRequired,
	confirmed: PropTypes.bool.isRequired,
	setType: PropTypes.bool,
	showText: PropTypes.bool,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ApprovedConfirmedStatus.defaultProps = {
	setType: true,
	showText: true,
};

export default ApprovedConfirmedStatus;
