import React, { useContext } from 'react';
import gql from 'graphql-tag';
import Chart from 'react-apexcharts';
import { Grid } from '@material-ui/core';
import { isPast, parseISO } from 'date-fns';
import { getPercent } from '@sporkbytes/math-utils';
import { isDelivered } from '@sporkbytes/meal-proposal-utils';

import Metric from 'components/analytics/Metric';
import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import { formatGroupedDate, formatPercent } from 'services/mealProposal';
import TimeBins from 'services/TimeBins';

const DeliveredAtStatistics = () => {
	const { chartConfig, filters, groupedMealProposalsForCalculations } =
		useContext(AnalyticsContext);
	let count = 0;

	const binnedMealProposals = groupedMealProposalsForCalculations
		.filter(({ date }) => isPast(parseISO(date)))
		.map(({ MealProposals, date }) => {
			const deliveredAtBins = new TimeBins();
			const formattedDate = formatGroupedDate(date, filters.groupBy);

			count += MealProposals.length;

			const markedDeliveredMealProposals =
				MealProposals.filter(isDelivered);

			markedDeliveredMealProposals.forEach(meal =>
				deliveredAtBins.addToBin(meal)
			);

			return {
				date: formattedDate,
				deliveredAtBins,
			};
		});

	let series = TimeBins.getBins().map(({ name }) => ({ name, data: [] }));

	const { markedDeliveredCount, earlyCount, onTimeCount, lateCount } =
		binnedMealProposals.reduce(
			(
				{ markedDeliveredCount, earlyCount, onTimeCount, lateCount },
				{ deliveredAtBins }
			) => ({
				markedDeliveredCount:
					markedDeliveredCount + deliveredAtBins.totalCount,
				earlyCount: earlyCount + deliveredAtBins.earlyCount,
				onTimeCount: onTimeCount + deliveredAtBins.onTimeCount,
				lateCount: lateCount + deliveredAtBins.lateCount,
			}),
			{
				markedDeliveredCount: 0,
				earlyCount: 0,
				onTimeCount: 0,
				lateCount: 0,
			}
		);

	binnedMealProposals.forEach(({ date, deliveredAtBins }) => {
		deliveredAtBins.bins.forEach(bin => {
			const matchedSeries = series.find(({ name }) => name === bin.name);

			matchedSeries.data.push({
				x: date,
				y: getPercent(bin.data.length, deliveredAtBins.totalCount) || 0,
			});
		});
	});

	const options = {
		...chartConfig.options,
		dataLabels: {
			formatter: value => formatPercent(Math.round(value)),
		},
		theme: {
			monochrome: {
				color: chartConfig.options.colors[0],
				enabled: true,
				shadeIntensity: 0.1,
				shadeTo: 'light',
			},
		},
		tooltip: {
			y: {
				formatter: value => formatPercent(Math.round(value)),
			},
		},
		xaxis: {
			tooltip: {
				enabled: false,
			},
		},
	};

	return (
		<>
			<WidgetTitle>Delivery Statistics</WidgetTitle>
			<Grid container direction="row" justify="space-around">
				<Grid item xs={12} lg={10}>
					<Chart
						type="heatmap"
						series={series}
						options={options}
						height={chartConfig.height}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					lg={2}
					container
					direction="row"
					justify="center"
					spacing={1}
				>
					<Grid item lg={12}>
						<Metric
							value={formatPercent(
								getPercent(markedDeliveredCount, count, 0)
							)}
							label="Marked Delivered"
						/>
					</Grid>
					<Grid item lg={12}>
						<Metric
							value={formatPercent(
								getPercent(onTimeCount, markedDeliveredCount, 0)
							)}
							label="On Time"
						/>
					</Grid>
					<Grid item lg={12}>
						<Metric
							value={formatPercent(
								getPercent(lateCount, markedDeliveredCount, 0)
							)}
							label="Late"
						/>
					</Grid>
					<Grid item lg={12}>
						<Metric
							value={formatPercent(
								getPercent(earlyCount, markedDeliveredCount, 0)
							)}
							label="Early"
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

DeliveredAtStatistics.fragments = {
	details: gql`
		fragment DeliveredAtStatisticsDetails on MealProposals {
			deliveredAt
			deliveryDate
			status
		}
	`,
};

export default DeliveredAtStatistics;
