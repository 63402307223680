import React from 'react';
import PropTypes from 'prop-types';
import { Phone, Email } from '@material-ui/icons';
import {
	GridContainer,
	LinkedPhoneNumberInput,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import * as yup from 'models/validation';

const validationObject = {
	firstName: yup.name().required('Please enter a first name.'),
	lastName: yup.name().required('Please enter a last name.'),
	email: yup
		.string()
		.email('Please enter a valid email address.')
		.required('Please enter an email address.'),
	phoneNumber: yup.phoneNumber(),
};

const UserFormElements = ({ children, emailAddressFieldName }) => {
	return (
		<>
			<GridContainer>
				<LinkedTextInput name="firstName" autoFocus />
				<LinkedTextInput name="lastName" />
			</GridContainer>
			<GridContainer>
				<LinkedTextInput
					name={emailAddressFieldName}
					label="Email Address"
					type="email"
					icon={<Email />}
				/>
				<LinkedPhoneNumberInput name="phoneNumber" icon={<Phone />} />
			</GridContainer>
			{children}
		</>
	);
};

UserFormElements.propTypes = {
	emailAddressFieldName: PropTypes.string,
};

UserFormElements.defaultProps = {
	emailAddressFieldName: 'email',
};

export default UserFormElements;
export { validationObject };
