import ClientLocationMenuProfile from 'pages/public/ClientLocationMenuProfile';
import ClientLocationReviewableMealsProfile from 'pages/public/ClientLocationReviewableMealsProfile';
import ContactPublicProfile from 'pages/public/ContactPublicProfile';
import MealMenu from 'pages/public/MealMenu';
import MealMenuReview from 'pages/public/MealMenuReview';
import MealProposalDetail from 'pages/public/MealProposalDetail';
import PartnerProfile from 'pages/public/PartnerProfile';
import PurchaseOrderDetail from 'pages/public/PurchaseOrderDetail';
import SporkReview from 'pages/public/SporkReview';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'clientLocationsMenuProfile',
		path: '/client-locations/menu-profile',
		component: ClientLocationMenuProfile,
		exact: true,
	},
	{
		name: 'clientLocationsReviewableMealsProfile',
		path: '/client-locations/reviewable-meals-profile',
		component: ClientLocationReviewableMealsProfile,
		exact: true,
	},
	{
		name: 'contactsPublicProfile',
		path: '/contacts/:personalProfileId/profile',
		component: ContactPublicProfile,
		exact: true,
	},
	{
		name: 'publicMealMenuReview',
		path: '/meal-menu-review',
		component: MealMenuReview,
		exact: true,
	},
	{
		name: 'publicMealMenu',
		path: '/meal-menu/:mealMenuId',
		component: MealMenu,
		exact: true,
	},
	{
		name: 'publicMealProposalsDetail',
		path: '/meal-proposals/:id/detail',
		component: MealProposalDetail,
		exact: true,
	},
	{
		name: 'publicPartnerProfile',
		path: '/partners/:publicProfileId/profile',
		component: PartnerProfile,
		exact: true,
	},
	{
		name: 'publicPurchaseOrdersDetail',
		path: '/purchase-orders/:id/detail',
		component: PurchaseOrderDetail,
		exact: true,
	},
	{
		name: 'publicSporkReview',
		path: '/spork-review',
		component: SporkReview,
		exact: true,
	},
];

export default prependUrlPath('public', routes);
