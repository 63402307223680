import gql from 'graphql-tag';

import useSubscribedMachine from 'hooks/useSubscribedMachine';

import purchaseOrderMachine from 'machines/purchaseOrderMachine';

const PURCHASE_ORDER_SUBSCRIPTION = gql`
	subscription UsePurchaseOrderMachine($id: uuid!) {
		PurchaseOrders_by_pk(id: $id) {
			id
			approved
			confirmed
			pickupTime
			MealOption {
				approved
				MealProposal {
					deliveryDate
					status
					MealOptions {
						id
					}
				}
			}
			PartnerLocation {
				Partner {
					name
				}
			}
		}
	}
`;

export default (id, options = {}) =>
	useSubscribedMachine(
		purchaseOrderMachine(options.guards),
		PURCHASE_ORDER_SUBSCRIPTION,
		{ id },
		'data.PurchaseOrders_by_pk',
		options
	);
