import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginBottom: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			marginBottom: theme.spacing(3),
		},
	},
	innerContainer: {
		backgroundColor: '#fdfd5e',
		display: 'inline-block',
		fontSize: theme.typography.body1.fontSize,
		padding: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(3),
		},
		'& + &': {
			paddingTop: 0,
		},
		'& :first-child': {
			marginTop: 0,
		},
		'& :last-child': {
			marginBottom: 0,
		},
	},
}));

const NoteContainer = ({ children, content }) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			{content ? (
				<div
					className={classes.innerContainer}
					dangerouslySetInnerHTML={{
						__html: content,
					}}
				></div>
			) : null}
			{children ? (
				<div className={classes.innerContainer}>{children}</div>
			) : null}
		</div>
	);
};

NoteContainer.propTypes = {
	content: PropTypes.string,
};

export default NoteContainer;
