import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import {
	Checkbox,
	MultiSelect,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';
import { findDriverSchedules } from '@sporkbytes/api-client/MealProposals';
import { bulkAssignMealProposals } from '@sporkbytes/api-client/DeliveryDrivers';
import { canAssignDeliveryDrivers } from '@sporkbytes/meal-proposal-utils';

import DriverSchedule from 'pages/dashboard/DriverSchedule';
import DriverSchedulesDialog from 'pages/dashboard/DriverSchedulesDialog';

import { useStore } from 'models/store';

import { partition } from 'services/utilities';
import { useAuth } from 'services/auth';
import apolloClient from 'services/apollo';
import axios from 'services/data';

import useRequest from 'hooks/useRequest';

const MEAL_PROPOSALS_DELIVERY_DRIVERS_QUERY = gql`
	query FindDriverSchedulesDialog($ids: [uuid!]!, $SporkLocationId: uuid!) {
		MealProposals(where: { id: { _in: $ids } }) {
			# status and deliveryDate is needed for canAssignDeliveryDrivers function
			status
			deliveryDate
			...DriverSchedule
		}
		DeliveryDrivers(
			where: {
				active: { _eq: true }
				SporkLocationId: { _eq: $SporkLocationId }
			}
			order_by: { firstName: asc }
		) {
			id
			fullName
		}
	}
	${DriverSchedule.fragments.details}
`;

const FindDriverSchedulesDialog = ({
	mealProposalIds,
	numberOfDrivers,
	...props
}) => {
	const { selectedSporkLocationId, showSuccessNotification } = useStore();
	const {
		data: [
			{ distances, schedules },
			{
				data: { DeliveryDrivers, MealProposals },
			},
		] = [{}, { data: { DeliveryDrivers: [] } }],
		loading,
		error,
	} = useRequest(() =>
		Promise.all([
			findDriverSchedules(axios, {
				mealProposalIds,
				numberOfDrivers,
			}),
			apolloClient.query({
				query: MEAL_PROPOSALS_DELIVERY_DRIVERS_QUERY,
				variables: {
					ids: mealProposalIds,
					SporkLocationId: selectedSporkLocationId,
				},
			}),
		])
	);

	const [driverAssignments, setDriverAssignments] = useState({});
	const [assigningDrivers, setAssigningDrivers] = useState(false);
	const { userCan } = useAuth();

	const [assignedDrivers, unassignedDrivers] = partition(
		DeliveryDrivers,
		driver =>
			Object.values(driverAssignments).some(({ deliveryDriverIds }) =>
				deliveryDriverIds.includes(driver.id)
			)
	);

	return (
		<DriverSchedulesDialog
			{...props}
			open={!!error ? false : props.open}
			loading={loading}
			additionalActions={
				<PrimaryButton
					onClick={e => {
						setAssigningDrivers(true);

						bulkAssignMealProposals(axios, {
							driverAssignments: Object.entries(driverAssignments)
								.filter(
									([_scheduleIndex, { deliveryDriverIds }]) =>
										deliveryDriverIds.length > 0
								)
								.flatMap(
									([
										scheduleIndex,
										{
											deliveryDriverIds,
											replaceCurrentDriverAssignments,
										},
									]) =>
										deliveryDriverIds.map(
											DeliveryDriverId => ({
												DeliveryDriverId,
												mealProposalIds:
													schedules[scheduleIndex],
												replaceCurrentDriverAssignments,
											})
										)
								),
						})
							.then(() => {
								showSuccessNotification('Drivers Assigned');
								props.onClose(e);
							})
							.finally(() => setAssigningDrivers(false));
					}}
					disabled={
						assigningDrivers ||
						Object.values(assignedDrivers).filter(value => !!value)
							.length === 0
					}
				>
					Assign Drivers
				</PrimaryButton>
			}
		>
			{schedules &&
				schedules
					.map(mealProposalIds =>
						mealProposalIds.map(id =>
							MealProposals.find(
								mealProposal => mealProposal.id === id
							)
						)
					)
					.map((schedule, scheduleIndex) => (
						<DriverSchedule
							key={scheduleIndex}
							mealProposals={schedule}
							distances={distances}
							header={
								<>
									<MultiSelect
										label="Assign Drivers"
										value={
											driverAssignments[scheduleIndex]
												?.deliveryDriverIds
										}
										onChange={deliveryDriverIds => {
											setDriverAssignments(
												driverAssignments => ({
													...driverAssignments,
													[scheduleIndex]: {
														deliveryDriverIds,
														replaceCurrentDriverAssignments:
															driverAssignments[
																scheduleIndex
															]
																?.replaceCurrentDriverAssignments ||
															false,
													},
												})
											);
										}}
										disabled={
											!userCan([
												'meal-proposals-delivery-drivers:create',
												'meal-proposals-delivery-drivers:delete',
											]) ||
											schedule.some(
												mealProposal =>
													!canAssignDeliveryDrivers(
														mealProposal
													)
											)
										}
										options={[
											{
												id: 'unassigned',
												type: 'group',
												fullName: 'Unassigned',
											},
											...unassignedDrivers,
											{
												id: 'previouslyAssigned',
												type: 'group',
												fullName: 'Previously Assigned',
											},
											...assignedDrivers,
										]}
										getOptionLabel={option =>
											option.fullName
										}
										getOptionValue={option => option.id}
										getOptionKey={option => option.id}
										getOptionType={option => option.type}
									/>
									<Checkbox
										value={
											driverAssignments[scheduleIndex]
												?.replaceCurrentDriverAssignments
										}
										onChange={checked => {
											setDriverAssignments(
												driverAssignments => ({
													...driverAssignments,
													[scheduleIndex]: {
														replaceCurrentDriverAssignments:
															checked,
														deliveryDriverIds:
															driverAssignments[
																scheduleIndex
															]
																?.deliveryDriverIds ||
															[],
													},
												})
											);
										}}
										label="Replace current driver assignments"
									/>
								</>
							}
						/>
					))}
		</DriverSchedulesDialog>
	);
};

FindDriverSchedulesDialog.propTypes = {
	mealProposalIds: PropTypes.arrayOf(PropTypes.string).isRequired,
	numberOfDrivers: PropTypes.number,
};

export default FindDriverSchedulesDialog;
