import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { post } from '@sporkbytes/api-client/PartnerMenuSections';

import FormWrapper from 'components/forms/FormWrapper';
import PartnerMenuSectionForm from 'components/partner-menu-sections/PartnerMenuSectionForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

const PARTNER_QUERY = gql`
	query PartnerMenuSectionCreate($id: uuid!) {
		Partners_by_pk(id: $id) {
			id
			logoUrl
			name
		}
	}
`;

const PartnerMenuSectionCreate = () => {
	const { id: PartnerId } = useParams();

	return (
		<Page
			title={({ Partners_by_pk: { name } }) =>
				`New Menu Section for ${name}`
			}
			query={PARTNER_QUERY}
			variables={{ id: PartnerId }}
		>
			{({
				data: {
					Partners_by_pk: { logoUrl, name },
				},
			}) => (
				<>
					<PageHeader headerText={name} imgUrl={logoUrl} />
					<PageSubheader>Add New Menu Section</PageSubheader>
					<FormWrapper
						form={PartnerMenuSectionForm}
						onSubmit={(axios, values) =>
							post(axios, {
								PartnerId,
								...values,
							})
						}
					/>
				</>
			)}
		</Page>
	);
};

export default PartnerMenuSectionCreate;
