import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';

import InlineContent from 'components/content/InlineContent';

const useStyles = makeStyles(theme => ({
	grid: {
		margin: theme.spacing(1),
	},
	icon: {
		color: ({ type }) =>
			type ? theme.palette[type][800] : theme.palette.common.white,
	},
}));

export const Status = ({
	className,
	contentClassName,
	Icon,
	showText,
	text,
	type,
	tooltip,
}) => {
	const classes = useStyles({ type });

	const textOutput =
		typeof text === 'string' ? <Typography>{text}</Typography> : text;

	const Wrapper = tooltip
		? props => <Tooltip title={tooltip} {...props} />
		: ({ children }) => children;

	return (
		<Grid
			container
			alignItems="center"
			className={`${classes.grid} ${className}`}
		>
			<InlineContent className={contentClassName}>
				<Wrapper>
					<Icon className={classes.icon} />
				</Wrapper>
			</InlineContent>
			{showText ? textOutput : null}
		</Grid>
	);
};

Status.propTypes = {
	className: PropTypes.string,
	contentClassName: PropTypes.string,
	Icon: PropTypes.elementType.isRequired,
	showText: PropTypes.bool,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltip: PropTypes.string,
	type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
};

Status.defaultProps = {
	className: '',
	contentClassName: '',
	showText: true,
};

export default Status;
