import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	sectionSubeader: {
		margin: theme.spacing(2, 0, 1, 0),
	},
}));

const FormSectionSubheader = ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.sectionSubeader}>
			<Typography variant="h6">{children}</Typography>
			<Divider />
		</div>
	);
};

FormSectionSubheader.propTypes = {
	children: PropTypes.string.isRequired,
};

export default FormSectionSubheader;
