import React from 'react';
import PropTypes from 'prop-types';
import { LinkedSingleSelect } from '@sporkbytes/material-ui-kit-react';

import { useStore } from 'models/store';

const SporkLocationLinkedSingleSelect = ({ name, ...props }) => {
	const { activeSporkLocations } = useStore();

	return (
		<LinkedSingleSelect
			label="Spork Location"
			name={name}
			options={activeSporkLocations}
			getOptionValue={({ id }) => id}
			getOptionKey={({ id }) => id}
			getOptionLabel={({ name }) => name}
			{...props}
		/>
	);
};

SporkLocationLinkedSingleSelect.propTypes = {
	name: PropTypes.string.isRequired,
};

SporkLocationLinkedSingleSelect.defaultProps = {
	name: 'SporkLocationId',
};

export default SporkLocationLinkedSingleSelect;
