import React, { useState } from 'react';
import { Button, Card, makeStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import {
	LinkedRating,
	LinkedTextInput,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';
import {
	getMealMenuForReview,
	postMealMenuReview,
} from '@sporkbytes/api-client/PublicMealOptions';

import LoadingPage from 'components/content/LoadingPage';
import Logo from 'components/content/Logo';
import NoReviewableMealFoundPage from 'components/content/NoReviewableMealFoundPage';
import Page from 'components/layout/Page';
import PageSubheader from 'components/content/PageSubheader';
import ReviewThankYou from 'components/content/ReviewThankYou';
import withRecaptcha from 'components/forms/withRecaptcha';

import useQueryString from 'hooks/useQueryString';
import useRequest from 'hooks/useRequest';

import axios from 'services/data';

import * as yup from 'models/validation';

const useStyles = makeStyles(theme => ({
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: theme.spacing(4, 0),
		width: '100%',
	},
	container: {
		padding: theme.spacing(3),
	},
	form: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	rating: {
		margin: theme.spacing(4, 0),
	},
	ratingLabel: {
		fontSize: 40,
	},
}));

const validationSchema = yup.object().shape({
	comments: yup.string().max(250),
	rating: yup.nonNegativeInteger().required('Please select a rating.'),
});

const MealMenuReview = ({ getRecaptchaToken, recaptchaText }) => {
	const [{ mealMenuId }] = useQueryString();
	const [partnerIndex, setPartnerIndex] = useState(0);
	const [reviewSubmitted, setReviewSubmitted] = useState(false);
	const classes = useStyles();

	const { data: mealMenu, loading } = useRequest(() =>
		getMealMenuForReview(axios, mealMenuId)
	);

	const [reviews, setReviews] = useState(Array(mealMenu?.length));

	if (loading) {
		return <LoadingPage />;
	}

	if (mealMenu === 'No meal found') {
		return <NoReviewableMealFoundPage />;
	}

	if (reviewSubmitted) {
		return <ReviewThankYou />;
	}
	const hasMultiplePartners = mealMenu.length > 1;
	const isOnFirstPartner = partnerIndex === 0;
	const isOnLastPartner = partnerIndex === mealMenu.length - 1;

	return (
		<Page title="Leave a Review">
			<Card className={classes.container}>
				{mealMenu
					.filter((_partner, index) => partnerIndex === index)
					.map(({ partnerName, logoUrl, publicReviewId }) => (
						<Formik
							key={publicReviewId}
							validationSchema={validationSchema}
							onSubmit={async values => {
								setReviews(reviews => {
									reviews[partnerIndex] = values;
									return reviews;
								});

								if (isOnLastPartner) {
									const recaptchaResponse = await getRecaptchaToken();

									await postMealMenuReview(
										axios,
										mealMenuId,
										{
											mealMenuReview: reviews,
											recaptchaResponse,
										}
									);
									setReviewSubmitted(true);
								} else {
									setPartnerIndex(
										partnerIndex => partnerIndex + 1
									);
								}
							}}
							initialValues={
								reviews[partnerIndex] || {
									comments: '',
									rating: '',
									publicReviewId,
								}
							}
							enableReinitialize
						>
							{({ isSubmitting }) => (
								<Form className={classes.form}>
									<Logo url={logoUrl} />
									<PageSubheader>
										How was your meal with {partnerName}?
									</PageSubheader>
									<div className={classes.rating}>
										<LinkedRating
											name="rating"
											label=""
											classes={{
												label: classes.ratingLabel,
											}}
										/>
									</div>
									<LinkedTextInput name="comments" />
									<div className={classes.buttonsContainer}>
										{hasMultiplePartners && (
											<Button
												disabled={
													isOnFirstPartner ||
													isSubmitting
												}
												onClick={() => {
													setPartnerIndex(
														partnerIndex =>
															partnerIndex - 1
													);
												}}
											>
												Previous
											</Button>
										)}
										<PrimaryButton
											type="submit"
											disabled={isSubmitting}
										>
											{isOnLastPartner
												? 'Submit'
												: 'Next'}
										</PrimaryButton>
									</div>
								</Form>
							)}
						</Formik>
					))}
				{recaptchaText}
			</Card>
		</Page>
	);
};

export default withRecaptcha(MealMenuReview);
