import { differenceInMinutes, parseISO } from 'date-fns';

const configBins = [
	{
		minTime: -Number.MAX_SAFE_INTEGER,
		maxTime: -40,
		name: '30+ min early',
		category: 'early',
		level: 3,
	},
	{
		minTime: -40,
		maxTime: -25,
		name: '15 - 30 min early',
		category: 'early',
		level: 2,
	},
	{
		minTime: -25,
		maxTime: -10,
		name: '0 - 15 min early',
		category: 'early',
		level: 1,
	},
	{
		minTime: -10,
		maxTime: 10,
		name: 'On Time',
		category: 'on time',
	},
	{
		minTime: 10,
		maxTime: 25,
		name: '0 - 15 min late',
		category: 'late',
		level: 1,
	},
	{
		minTime: 25,
		maxTime: 40,
		name: '15 - 30 min late',
		category: 'late',
		level: 2,
	},
	{
		minTime: 40,
		maxTime: Number.MAX_SAFE_INTEGER,
		name: '30+ min late',
		category: 'late',
		level: 3,
	},
];

class TimeBins {
	constructor(
		baseProperty = 'deliveryDate',
		compareProperty = 'deliveredAt'
	) {
		this.bins = configBins.map(bin => ({ ...bin, data: [] }));

		this.totalCount = 0;
		this.earlyCount = 0;
		this.onTimeCount = 0;
		this.lateCount = 0;

		this.compareProperty = compareProperty;
		this.baseProperty = baseProperty;
	}

	addToBin(entity) {
		const difference = differenceInMinutes(
			parseISO(entity[this.compareProperty]),
			parseISO(entity[this.baseProperty])
		);

		if (typeof difference !== 'number' || isNaN(difference)) {
			return;
		}

		const bin = this.bins.find(
			({ minTime, maxTime }) =>
				minTime <= difference && difference < maxTime
		);

		bin.data.push(entity);
		this.totalCount++;

		switch (bin.category) {
			case 'early':
				this.earlyCount++;
				break;
			case 'on time':
				this.onTimeCount++;
				break;
			case 'late':
				this.lateCount++;
				break;
			default:
				break;
		}
	}

	getBinSize(name) {
		return this.bins.find(bin => bin.name === name).data.length;
	}

	static getBinName(category, level) {
		return configBins.find(
			bin =>
				bin.category === category && bin.level === (level || bin.level)
		).name;
	}

	static getBins() {
		return configBins;
	}
}

export default TimeBins;
