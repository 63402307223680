import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { post } from '@sporkbytes/api-client/MealProposalFees';

import FormWrapper from 'components/forms/FormWrapper';
import MealProposalFeeForm from 'components/meal-proposal-fees/MealProposalFeeForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const MEAL_PROPOSAL_QUERY = gql`
	query MealProposalFeeCreate($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			orderNumberText
		}
	}
`;

const CreateFee = () => {
	const { id } = useParams();

	return (
		<Page
			query={MEAL_PROPOSAL_QUERY}
			variables={{ id }}
			title={() => 'New Fee'}
		>
			{({
				data: {
					MealProposals_by_pk: { orderNumberText },
				},
			}) => (
				<>
					<PageHeader
						headerText={`New Fee for Meal Proposal ${orderNumberText}`}
					/>
					<FormWrapper
						form={MealProposalFeeForm}
						onSubmit={(axios, values) =>
							post(axios, {
								MealProposalId: id,
								...values,
							})
						}
					/>
				</>
			)}
		</Page>
	);
};

export default CreateFee;
