import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { sort } from '@sporkbytes/api-client/DietaryTags';

import ActionMenu from 'components/utilities/ActionMenu';
import EditableSortOrder from 'components/forms/EditableSortOrder';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import RemoteTable from 'components/content/RemoteTable';

import { useAuth } from 'services/auth';
import axios from 'services/data';
import { createEditAction } from 'services/actionMenu';

const getDietaryTagsQuery = searchTerm =>
	gql`
		query DietaryTags(
			$sortOrderOrderBy: order_by
			$nameOrderBy: order_by
			$limit: Int!
			$offset: Int!
		) {
			DietaryTags(
				where: { name: { _ilike: "%${searchTerm}%" } }
				order_by: { sortOrder: $sortOrderOrderBy, name: $nameOrderBy }
				limit: $limit
				offset: $offset
			) {
				id
				name	
				sortOrder
			}
			DietaryTags_aggregate {
				aggregate {
					count
				}
			}
		}
	`;

const Detail = () => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<Page title="Dietary Tags">
			<PageHeader headerText="Dietary Tags" />
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Sort Order',
						field: 'sortOrder',
						render: ({ id, sortOrder }) => (
							<EditableSortOrder
								onSave={value =>
									sort(axios, id, {
										newValue: value,
									}).then(ref.current.onQueryChange)
								}
								permissions={['dietary-tags:edit']}
								required={true}
								value={sortOrder}
							/>
						),
					},
					{
						title: 'Name',
						field: 'name',
					},
					{
						title: 'Actions',
						field: 'actions',
						sorting: false,
						render: ({ id, name }) => (
							<ActionMenu
								options={[
									createEditAction({
										entityId: id,
										entityName: name,
										entityType: 'DietaryTags',
										userCan,
									}),
								]}
							/>
						),
					},
				]}
				getQuery={getDietaryTagsQuery}
				defaultOrderBy="sortOrder"
				defaultOrderDirection="asc"
				queryEntity="DietaryTags"
				addNewEntityRouteName="dietaryTagsCreate"
				entityToAddName="Dietary Tag"
				canAddNewEntity={userCan(['dietary-tags:create'])}
			/>
		</Page>
	);
};

export default Detail;
