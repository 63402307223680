import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { DialogForm, LinkedTextInput } from '@sporkbytes/material-ui-kit-react';
import { mergeDuplicates } from '@sporkbytes/api-client/ParentPartnerMenuItems';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import DetailPageLink from 'components/routing/DetailPageLink';
import RemoteTable from 'components/content/RemoteTable';

import * as yup from 'models/validation';
import { useStore } from 'models/store';

import { getMultiWordSearchWhereClause } from 'services/search';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const getParentPartnerMenuItemsQuery = searchTerm => gql`
	query ParentPartnerMenuItems(
		$PartnerId: uuid!
		$nameOrderBy: order_by
		$partnerMenuItemsOrderBy: order_by
		$limit: Int
		$offset: Int
	) {
		ParentPartnerMenuItems(
			where: { 
				_or: [
					{ _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
					{ PartnerMenuItems: { _and: [${getMultiWordSearchWhereClause(searchTerm)}] } }
				]
				PartnerId: { _eq: $PartnerId } 
			}
			order_by: {
				name: $nameOrderBy
				PartnerMenuItems_aggregate: { count: $partnerMenuItemsOrderBy }
			}
			limit: $limit
			offset: $offset
		) {
			id
			name
			PartnerMenuItems(order_by: { name: asc } ) {
				id
				name
			}
		}
		ParentPartnerMenuItems_aggregate(
			where: { 
				_or: [
					{ _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
					{ PartnerMenuItems: { _and: [${getMultiWordSearchWhereClause(searchTerm)}] } }
				]
				PartnerId: { _eq: $PartnerId } 
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const ParentParentPartnerMenuItemsTable = ({ PartnerId, ...props }) => {
	const ref = useRef();
	const { userCan } = useAuth();
	const { showDialog } = useStore();
	const [selectedItems, setSelectedItems] = useState([]);

	return (
		<>
			<RemoteTable
				{...props}
				tableRef={ref}
				columns={[
					{
						title: 'Parent',
						field: 'name',
					},

					{
						title: 'Children',
						field: 'partnerMenuItems',
						render: ({ PartnerMenuItems }) => (
							<List>
								{PartnerMenuItems.map(({ id, name }) => (
									<ListItem key={id} dense>
										<DetailPageLink
											entityType="PartnerMenuItems"
											id={id}
											text={name}
										/>
									</ListItem>
								))}
							</List>
						),
					},
				]}
				defaultOrderBy="name"
				defaultOrderDirection="asc"
				getQuery={getParentPartnerMenuItemsQuery}
				queryEntity="ParentPartnerMenuItems"
				variables={{ PartnerId }}
				onSelectionChange={setSelectedItems}
				options={{
					selection: userCan([
						'parent-partner-menu-items:merge-duplicates',
					]),
					showSelectAllCheckbox: false,
					showTextRowsSelected: false,
				}}
				actions={[
					{
						hidden: selectedItems.length < 2,
						icon: 'merge_type',
						tooltip: 'Merge Selected Parent Menu Items',
						position: 'toolbarOnSelect', // This is needed to show the action in the toolbar when items are selected https://github.com/mbrn/material-table/issues/980#issuecomment-582809141
						onClick: () =>
							showDialog(props => (
								<DialogForm
									{...props}
									title={`Merge ${makeCommaSeparatedList(
										selectedItems.map(({ name }) => name)
									)}`}
									validationSchema={yup.object().shape({
										name: yup.name().required(),
									})}
									onSubmit={({ name }) => {
										return mergeDuplicates(axios, {
											newParent: { name },
											oldParents: selectedItems.map(
												({ id }) => ({ id })
											),
										}).then(() => {
											setSelectedItems([]);
											ref.current.onQueryChange();
										});
									}}
								>
									{() => (
										<LinkedTextInput
											autoFocus
											name="name"
											helperText="Please provide the name of the new parent menu item."
										/>
									)}
								</DialogForm>
							)),
					},
				]}
			/>
		</>
	);
};

ParentParentPartnerMenuItemsTable.propTypes = {
	PartnerId: PropTypes.string.isRequired,
};

export default ParentParentPartnerMenuItemsTable;
