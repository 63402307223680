import React from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import { Dialog, PrimaryButton } from '@sporkbytes/material-ui-kit-react';

import EmailTemplatePreview from 'components/email-templates/EmailTemplatePreview';

const useStyles = makeStyles(theme => ({
	dialogContent: {
		paddingBottom: theme.spacing(6),
	},
}));

const EmailTemplatePreviewDialog = ({ emailTemplate, onClose, open }) => {
	const classes = useStyles();

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent className={classes.dialogContent}>
				<EmailTemplatePreview emailTemplate={emailTemplate} />
			</DialogContent>
			<DialogActions>
				<PrimaryButton onClick={onClose}>Close</PrimaryButton>
			</DialogActions>
		</Dialog>
	);
};

EmailTemplatePreviewDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	emailTemplate: PropTypes.object.isRequired,
};

export default EmailTemplatePreviewDialog;
