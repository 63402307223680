import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { Card, Grid, makeStyles } from '@material-ui/core';
import {
	getSummationCalculation,
	grossMargin,
} from '@sporkbytes/meal-proposal-utils';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import {
	formatCurrency,
	formatNumber,
	formatPercent,
} from 'services/mealProposal';

const useStyles = makeStyles(theme => ({
	card: {
		border: `2px solid ${theme.palette.primary[500]}`,
		padding: theme.spacing(2),
	},
	centerColumn: {
		[theme.breakpoints.up('sm')]: {
			textAlign: 'center',
		},
	},
	rightColumn: {
		[theme.breakpoints.up('sm')]: {
			textAlign: 'right',
		},
	},
	stickyContainer: {
		margin: theme.spacing(0, 2),
		position: 'sticky',
		top: theme.sizes.appToolbar + theme.spacing(2),
		zIndex: theme.layers.fixedContent,
	},
}));

const StickySummary = () => {
	const { mealProposalsForCalculations } = useContext(AnalyticsContext);
	const classes = useStyles();

	return (
		<Grid item xs={12} className={classes.stickyContainer}>
			<Card className={classes.card}>
				<Grid container justify="space-between">
					<Grid item xs={12} sm={4}>
						<WidgetTitle>{`${formatNumber(
							mealProposalsForCalculations.length
						)} meals`}</WidgetTitle>
					</Grid>
					<Grid item xs={12} sm={4}>
						<WidgetTitle className={classes.centerColumn}>
							{`${formatCurrency(
								getSummationCalculation(
									'totalClientPays',
									mealProposalsForCalculations
								)
							)} revenue`}
						</WidgetTitle>
					</Grid>
					<Grid item xs={12} sm={4}>
						<WidgetTitle className={classes.rightColumn}>
							{`${formatPercent(
								grossMargin(mealProposalsForCalculations)
							)} gross margin`}
						</WidgetTitle>
					</Grid>
				</Grid>
			</Card>
		</Grid>
	);
};

StickySummary.fragments = {
	details: gql`
		fragment StickySummaryDetails on MealProposals {
			deliveryDate
			headCount
			MealProposalFees {
				amount
				discount
				type
			}
			MealOptions {
				approved
				gratuity
				PurchaseOrders {
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							costToClient
							discount
							quantity
						}
					}
				}
			}
		}
	`,
};

export default StickySummary;
