import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';
import { Dialog } from '@sporkbytes/material-ui-kit-react';

import ApprovedConfirmedStatus from 'components/content/ApprovedConfirmedStatus';
import DateOutput from 'components/content/DateOutput';

const PurchaseOrderSelectionDialog = ({ onSelect, mealOptions, ...props }) => {
	return (
		<Dialog {...props}>
			{mealOptions.map(
				({ approved, confirmed, id, name, PurchaseOrders }) => (
					<React.Fragment key={id}>
						<DialogTitle>
							<ApprovedConfirmedStatus
								approved={approved}
								confirmed={confirmed}
								text={<>{name}</>}
							/>
						</DialogTitle>
						<List>
							{PurchaseOrders.map(
								({
									id,
									pickupTime,
									PartnerLocation: { Partner, timezone },
								}) => (
									<ListItem
										key={id}
										onClick={() => {
											onSelect(id);
											props.onClose();
										}}
										button
									>
										<ListItemText>
											{Partner.name} -{' '}
											<DateOutput
												date={pickupTime}
												timezone={timezone}
												formatter="time"
												component="span"
											/>
										</ListItemText>
									</ListItem>
								)
							)}
						</List>
					</React.Fragment>
				)
			)}
		</Dialog>
	);
};

PurchaseOrderSelectionDialog.propTypes = {
	onSelect: PropTypes.func.isRequired,
	mealOptions: PropTypes.arrayOf(PropTypes.object),
};

export default PurchaseOrderSelectionDialog;
