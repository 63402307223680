import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import {
	Avatar,
	Form,
	GridContainer,
	LinkedAutocomplete,
} from '@sporkbytes/material-ui-kit-react';

import SporkLocationLinkedSingleSelect from 'components/spork-locations/SporkLocationLinkedSingleSelect';

import { useStore } from 'models/store';
import * as yup from 'models/validation';

import apolloClient from 'services/apollo';

const AVAILABLE_PARTNERS_QUERY = gql`
	query AvailablePartners($month: date!, $SporkLocationId: uuid!) {
		Partners(
			where: {
				_not: { FeaturedPartners: { month: { _eq: $month } } }
				SporkLocationsPartners: {
					SporkLocationId: { _eq: $SporkLocationId }
				}
				active: { _eq: true }
			}
			order_by: { name: asc }
		) {
			id
			logoUrl
			name
		}
	}
`;

const validationSchema = yup.object().shape({
	PartnerId: yup.string().required('Please select a partner.'),
	SporkLocationId: yup.sporkLocationId(),
});

const FeaturedPartnerForm = ({ month, ...props }) => {
	const [availablePartners, setAvailablePartners] = useState([]);
	const { hasMoreThanOneSporkLocation } = useStore();

	useEffect(() => {
		async function getInitialAvailablePartners() {
			if (props.initialValues.SporkLocationId) {
				const {
					data: { Partners },
				} = await apolloClient.query({
					query: AVAILABLE_PARTNERS_QUERY,
					variables: {
						month,
						SporkLocationId: props.initialValues.SporkLocationId,
					},
				});

				setAvailablePartners(Partners);
			}
		}

		getInitialAvailablePartners();
	}, [month, props.initialValues.SporkLocationId]);

	return (
		<Form {...props} validationSchema={validationSchema}>
			{({ values, setFieldValue }) => (
				<>
					<GridContainer columns={2}>
						{hasMoreThanOneSporkLocation() ? (
							<SporkLocationLinkedSingleSelect
								onChange={async SporkLocationId => {
									const {
										data: { Partners },
									} = await apolloClient.query({
										query: AVAILABLE_PARTNERS_QUERY,
										variables: {
											month,
											SporkLocationId,
										},
									});

									setAvailablePartners(Partners);

									setFieldValue(
										'SporkLocationId',
										SporkLocationId
									);
								}}
							/>
						) : null}
						<LinkedAutocomplete
							disabled={!values.SporkLocationId}
							name="PartnerId"
							options={availablePartners}
							getOptionKey={({ id }) => id}
							getOptionLabel={({ name }) => name}
							getOptionValue={({ id }) => id}
							renderOption={option => (
								<>
									<ListItemAvatar>
										<Avatar
											alt={option.name}
											src={option.logoUrl}
											size="small"
										/>
									</ListItemAvatar>
									<ListItemText primary={option.name} />
								</>
							)}
						/>
					</GridContainer>
				</>
			)}
		</Form>
	);
};

FeaturedPartnerForm.propTypes = {
	month: PropTypes.string.isRequired,
};

export default FeaturedPartnerForm;
