import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { put } from '@sporkbytes/api-client/Users';

import UserForm from 'components/users/UserForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const USER_QUERY = gql`
	query UserEdit($id: uuid!) {
		Users_by_pk(id: $id) {
			Auth0RoleId
			emailAddress
			firstName
			HubSpotUserId
			lastName
			phoneNumber
			slackUsername
		}
	}
`;

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ Users_by_pk: { firstName } }) => `Edit ${firstName}`}
			query={USER_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					Users_by_pk: { firstName, ...user },
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${firstName}`} />
					<FormWrapper
						form={UserForm}
						initialValues={{
							firstName,
							...user,
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
