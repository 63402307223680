import React from 'react';

import ContactsTable from 'components/contacts/ContactsTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { useAuth } from 'services/auth';

const Contacts = () => {
	const entityType = 'Contacts';
	const { userCan } = useAuth();

	return (
		<Page title={entityType}>
			<PageHeader headerText={entityType} />
			<ContactsTable
				options={{
					exportButton: true,
					pageSize: 50,
					pageSizeOptions: [50, 100, 500],
				}}
				canAddNewEntity={userCan(['contacts:create'])}
				addNewEntityRouteName="contactsCreate"
			/>
		</Page>
	);
};

export default Contacts;
