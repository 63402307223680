import React, { useContext } from 'react';
import gql from 'graphql-tag';

import Cancellations from 'components/analytics/widgets/Cancellations';
import DeliveredAtStatistics from 'components/analytics/widgets/DeliveredAtStatistics';
import DiscountsByTimePeriod from 'components/analytics/widgets/DiscountsByTimePeriod';
import MealsByPartner from 'components/analytics/widgets/MealsByPartner';
import MealsByTimeOfDay from 'components/analytics/widgets/MealsByTimeOfDay';
import PartnerPayouts from 'components/analytics/widgets/PartnerPayouts';
import PartnerPayoutsByTimePeriod from 'components/analytics/widgets/PartnerPayoutsByTimePeriod';
import PickedUpAtStatistics from 'components/analytics/widgets/PickedUpAtStatistics';
import RevenueByClient from 'components/analytics/widgets/RevenueByClient';
import RevenueByDeliveryTimeRange from 'components/analytics/widgets/RevenueByDeliveryTimeRange';
import RevenueBySporkContact from 'components/analytics/widgets/RevenueBySporkContact';
import RevenueByTimePeriod from 'components/analytics/widgets/RevenueByTimePeriod';
import WidgetContainer from 'components/analytics/WidgetContainer';
import WidgetRow from 'components/analytics/WidgetRow';

import { AnalyticsContext } from 'pages/analytics/context';

const Home = () => {
	const { filters } = useContext(AnalyticsContext);

	return (
		<>
			<WidgetRow>
				<WidgetContainer xs={12}>
					<RevenueByTimePeriod />
				</WidgetContainer>
			</WidgetRow>
			{filters.status?.includes('Canceled') && (
				<WidgetRow>
					<WidgetContainer xs={12}>
						<Cancellations />
					</WidgetContainer>
				</WidgetRow>
			)}
			{!filters.ClientId && (
				<WidgetRow>
					<WidgetContainer xs={12}>
						<RevenueByClient />
					</WidgetContainer>
				</WidgetRow>
			)}
			<WidgetRow>
				<WidgetContainer xs={12} lg={6}>
					<RevenueBySporkContact />
				</WidgetContainer>
				<WidgetContainer xs={12} lg={6}>
					<RevenueByDeliveryTimeRange />
				</WidgetContainer>
			</WidgetRow>
			<WidgetRow>
				<WidgetContainer xs={12}>
					<DiscountsByTimePeriod />
				</WidgetContainer>
			</WidgetRow>
			{!filters.PartnerId && (
				<>
					<WidgetRow>
						<WidgetContainer xs={12}>
							<MealsByPartner />
						</WidgetContainer>
					</WidgetRow>
					<WidgetRow>
						<WidgetContainer xs={12}>
							<PartnerPayouts />
						</WidgetContainer>
					</WidgetRow>
				</>
			)}
			{filters.PartnerId && (
				<>
					<WidgetRow>
						<WidgetContainer xs={12}>
							<PartnerPayoutsByTimePeriod />
						</WidgetContainer>
					</WidgetRow>
					<WidgetRow>
						<WidgetContainer xs={12}>
							<PickedUpAtStatistics />
						</WidgetContainer>
					</WidgetRow>
				</>
			)}
			<WidgetRow>
				<WidgetContainer xs={12}>
					<DeliveredAtStatistics />
				</WidgetContainer>
			</WidgetRow>
			<WidgetRow>
				<WidgetContainer xs={12}>
					<MealsByTimeOfDay />
				</WidgetContainer>
			</WidgetRow>
		</>
	);
};

Home.fragments = {
	details: gql`
		fragment AnalyticsHomeDetails on MealProposals {
			...CancellationsDetails
			...DeliveredAtStatisticsDetails
			...DiscountsByTimePeriodDetails
			...MealsByPartnerDetails
			...MealsByTimeOfDayDetails
			...PartnerPayoutsDetails
			...PartnerPayoutsByTimePeriodDetails
			...PickedUpAtStatisticsDetails
			...RevenueByClientDetails
			...RevenueByDeliveryTimeRangeDetails
			...RevenueBySporkContactDetails
			...RevenueByTimePeriodDetails
		}
		${Cancellations.fragments.details}
		${DeliveredAtStatistics.fragments.details}
		${DiscountsByTimePeriod.fragments.details}
		${MealsByPartner.fragments.details}
		${MealsByTimeOfDay.fragments.details}
		${PartnerPayouts.fragments.details}
		${PartnerPayoutsByTimePeriod.fragments.details}
		${PickedUpAtStatistics.fragments.details}
		${RevenueByClient.fragments.details}
		${RevenueByDeliveryTimeRange.fragments.details}
		${RevenueBySporkContact.fragments.details}
		${RevenueByTimePeriod.fragments.details}
	`,
};

export default Home;
