import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import { Box } from '@material-ui/core';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import gql from 'graphql-tag';
import { Dialog } from '@sporkbytes/material-ui-kit-react';

import DateOutput from 'components/content/DateOutput';
import DetailPageLink from 'components/routing/DetailPageLink';
import RemoteTable from 'components/content/RemoteTable';
import SporkReviewFormCompleted from 'components/spork-reviews/SporkReviewFormCompleted';

import { useStore } from 'models/store';

import { getMultiWordSearchWhereClause } from 'services/search';

const getSporkReviewsQuery = searchTerm => gql`
	query SporkReviews(
		$ClientId: uuid
		$ClientLocationId: uuid
		$MealProposalId: uuid
		$clientLocationFriendlyNameOrderBy: order_by
		$commentsOrderBy: order_by
		$createdAtOrderBy: order_by
		$negativeAnswersOrderBy: order_by
		$orderNumberOrderBy: order_by
		$positiveAnswersOrderBy: order_by
		$sporkContactNameOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		SporkReviewAggregatedAnswersView(
			where: {
				_and: [
					{ ClientId: { _eq: $ClientId } }
					{ ClientLocationId: { _eq: $ClientLocationId } }
					{ MealProposalId : { _eq: $MealProposalId } }
					${getMultiWordSearchWhereClause(searchTerm)}
				]
			}
			order_by: {
				clientLocationFriendlyName: $clientLocationFriendlyNameOrderBy
				comments: $commentsOrderBy
				createdAt: $createdAtOrderBy
				negativeAnswers: $negativeAnswersOrderBy
				orderNumber: $orderNumberOrderBy
				positiveAnswers: $positiveAnswersOrderBy
				sporkContactName: $sporkContactNameOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			id
			clientLocationFriendlyName
			ClientLocationId
			comments
			createdAt
			MealProposalId
			negativeAnswers
			orderNumber
			orderNumberText
			positiveAnswers
			sporkContactName
		}
		SporkReviewAggregatedAnswersView_aggregate(
			where: {
				_and: [
					{ ClientId: { _eq: $ClientId } }
					{ ClientLocationId: { _eq: $ClientLocationId } }
					{ MealProposalId : { _eq: $MealProposalId } }
					${getMultiWordSearchWhereClause(searchTerm)}
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const SporkReviewsTable = ({ variables, hiddenColumns }) => {
	const { showDialog } = useStore();

	return (
		<RemoteTable
			columns={[
				{
					title: 'Order #',
					field: 'orderNumber',
					render: ({ MealProposalId, orderNumberText }) => (
						<DetailPageLink
							entityType="MealProposals"
							id={MealProposalId}
							text={orderNumberText}
						/>
					),
				},
				{
					title: 'Spork Contact',
					field: 'sporkContactName',
				},
				{
					title: 'Client Location',
					field: 'clientLocationFriendlyName',
				},
				{
					title: <ThumbUp />,
					field: 'positiveAnswers',
				},
				{
					title: <ThumbDown />,
					field: 'negativeAnswers',
				},
				{
					title: 'Comments',
					field: 'comments',
				},
				{
					title: 'Date Posted',
					field: 'createdAt',
					render: ({ createdAt }) => (
						<DateOutput date={createdAt} formatter="date" />
					),
				},
			].filter(({ field }) => !hiddenColumns.includes(field))}
			defaultOrderBy="createdAt"
			defaultOrderDirection="desc"
			getQuery={getSporkReviewsQuery}
			queryEntity="SporkReviewAggregatedAnswersView"
			variables={variables}
			onRowClick={(_event, { id }) =>
				showDialog(props => (
					<Query
						query={gql`
							query SporkReviewFormCompleted($id: uuid!) {
								SporkReviews_by_pk(id: $id) {
									...SporkReviewFormCompleted
								}
							}
							${SporkReviewFormCompleted.fragments.details}
						`}
						variables={{ id }}
					>
						{({ data, loading }) => (
							<Dialog loading={loading} {...props}>
								<Box margin={4}>
									<SporkReviewFormCompleted
										sporkReview={data?.SporkReviews_by_pk}
									/>
								</Box>
							</Dialog>
						)}
					</Query>
				))
			}
		/>
	);
};

SporkReviewsTable.propTypes = {
	variables: PropTypes.shape({
		ClientId: PropTypes.string,
		ClientLocationId: PropTypes.string,
		MealProposalId: PropTypes.string,
	}),
	hiddenColumns: PropTypes.arrayOf(PropTypes.string),
};

SporkReviewsTable.defaultProps = {
	variables: {},
	hiddenColumns: [],
};

export default SporkReviewsTable;
