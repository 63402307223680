import { useState, useEffect } from 'react';

const useRequest = (request, dependencies = [], debounceInterval = 0) => {
	const [state, setState] = useState({
		loading: true,
		data: undefined,
		error: undefined,
		refetch: fetchData,
	});

	function fetchData() {
		setState({ ...state, loading: true });
		request()
			.then(data => {
				setState({
					...state,
					loading: false,
					data,
					error: undefined,
				});
			})
			.catch(error => setState({ ...state, loading: false, error }));
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			fetchData();
		}, debounceInterval);

		return () => clearTimeout(timer);

		// This is to disable the warning that appears when dependencies aren't defined
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);

	return state;
};

export default useRequest;
