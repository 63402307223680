import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { RemoveCircle } from '@material-ui/icons';
import { setPartnerLocations } from '@sporkbytes/api-client/Contacts';
import { ConfirmationDialog } from '@sporkbytes/material-ui-kit-react';

import ContactActionMenu from 'components/contacts/ContactActionMenu';
import EmailActivityLogsTable from 'components/contacts/EmailActivityLogsTable';
import MealProposalsTable from 'components/contacts/MealProposalsTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PartnerLocationsTable from 'components/partner-locations/PartnerLocationsTable';
import PurchaseOrdersTable from 'components/contacts/PurchaseOrdersTable';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const CONTACT_QUERY = gql`
	query ContactDetails($id: uuid!) {
		Contacts_by_pk(id: $id) {
			id
			firstName
			lastName
			fullName
			email
			phoneNumber
		}
	}
`;

const Detail = () => {
	const { id } = useParams();
	const { userCannot } = useAuth();
	const { showDialog } = useStore();

	return (
		<Page
			query={CONTACT_QUERY}
			variables={{ id }}
			title={({ Contacts_by_pk: { fullName } }) => fullName}
		>
			{({
				data: {
					Contacts_by_pk: { fullName, ...contact },
				},
			}) => (
				<>
					<PageHeader
						headerText={fullName}
						actionButton={<ContactActionMenu contact={contact} />}
					/>
					<QueryStringSyncedTabs
						id="tab"
						tabs={[
							{
								id: 'meal-proposals',
								label: 'Meal Proposals',
								content: <MealProposalsTable ContactId={id} />,
							},
							{
								id: 'purchase-orders',
								label: 'Purchase Orders',
								content: <PurchaseOrdersTable ContactId={id} />,
							},
							{
								id: 'emailActivityLogs',
								label: 'Email Activity Logs',
								content: (
									<EmailActivityLogsTable ContactId={id} />
								),
							},
							{
								id: 'partnerLocations',
								label: 'Partner Locations',
								content: (
									<PartnerLocationsTable
										variables={{ ContactId: id }}
										additionalActions={ref => [
											{
												hidden: userCannot([
													'partner-locations-contacts:delete',
												]),
												icon: () => (
													<RemoveCircle color="error" />
												),
												isFreeAction: true,
												tooltip:
													'Remove From All Partner Locations',
												onClick: () =>
													showDialog(
														ConfirmationDialog,
														{
															text: 'Are you sure you would like to remove this contact from all locations?',
															onConfirmation:
																() =>
																	setPartnerLocations(
																		axios,
																		id,
																		{
																			data: [],
																		}
																	).then(
																		ref
																			.current
																			.onQueryChange
																	),
														}
													),
											},
										]}
									/>
								),
							},
						]}
					/>
				</>
			)}
		</Page>
	);
};

export default Detail;
