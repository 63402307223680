import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	AppBar,
	Button,
	IconButton,
	makeStyles,
	Toolbar,
} from '@material-ui/core';
import {
	Add,
	Business,
	Home,
	LocalPizza,
	Menu,
	People,
	Restaurant,
} from '@material-ui/icons';

import ActionMenu from 'components/utilities/ActionMenu';
import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import MasterSearch from 'components/forms/MasterSearch';
import NavigationDrawer from 'components/layout/NavigationDrawer/index';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';
import { logo } from 'services/sporkMetadata';

const useStyles = makeStyles(theme => ({
	container: {
		alignItems: 'center',
		display: 'flex',
		height: theme.sizes.appToolbar,
		zIndex: theme.zIndex.appBar + 1, // need to add 1 to stay on top of Tabs components
		'@media print': {
			display: 'none',
		},
	},
	filler: {
		flexGrow: 1,
	},
	hideMobile: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	menuButton: {
		[theme.breakpoints.up('sm')]: {
			marginRight: 20,
		},
	},
	logo: {
		margin: theme.spacing(1),
		maxHeight: 125,
		maxWidth: 125,
	},
	progress: {
		color: theme.palette.common.white,
	},
	toolbar: {
		width: '100%',
	},
}));

const AppToolbar = ({ position = 'static' }) => {
	const { isAuthenticated, userCan, loading, loginWithRedirect, logout } =
		useAuth();
	const { toolbarContent } = useStore();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const history = useHistory();
	const classes = useStyles();

	const NavigationToggle = () => (
		<IconButton
			color="inherit"
			aria-label="Open navigation"
			onClick={() => setDrawerOpen(!drawerOpen)}
			className={classes.menuButton}
		>
			<Menu />
		</IconButton>
	);

	const SpaceFiller = () => (
		<div className={`${classes.filler} ${classes.hideMobile}`}></div>
	);

	const HomeButton = () => (
		<IconButton
			className={classes.hideMobile}
			color="inherit"
			onClick={() => history.pushNamedRoute('home')}
		>
			<Home />
		</IconButton>
	);

	const QuickCreateMenu = () => (
		<ActionMenu
			keepMounted
			options={[
				{
					canPerformAction: userCan(['meal-proposals:create']),
					label: 'New Meal Proposal',
					icon: <LocalPizza />,
					onClick: () =>
						history.pushNamedRoute('mealProposalsCreate'),
				},
				{
					canPerformAction: userCan(['clients:create']),
					label: 'New Client',
					icon: <Business />,
					onClick: () => history.pushNamedRoute('clientsCreate'),
				},
				{
					canPerformAction: userCan(['partners:create']),
					label: 'New Partner',
					icon: <Restaurant />,
					onClick: () => history.pushNamedRoute('partnersCreate'),
				},
				{
					canPerformAction: userCan(['contacts:create']),
					label: 'New Contact',
					icon: <People />,
					onClick: () => history.pushNamedRoute('contactsCreate'),
				},
			]}
			icon={<Add />}
		/>
	);

	const SignInOutButton = () => (
		<Button
			className={classes.hideMobile}
			color="inherit"
			onClick={() => {
				if (isAuthenticated) {
					logout();
				} else {
					loginWithRedirect({
						appState: {
							targetUrl: window.location.pathname,
						},
						connection: 'Users',
					});
				}
			}}
		>
			Sign {isAuthenticated ? 'Out' : 'In'}
		</Button>
	);

	const DefaultToolbar = () => (
		<>
			{isAuthenticated && <NavigationToggle />}
			<SpaceFiller />
			{isAuthenticated && (
				<>
					<MasterSearch />
					<HomeButton />
					<QuickCreateMenu />
				</>
			)}
			<SignInOutButton />
		</>
	);

	return (
		<>
			<AppBar position={position} className={classes.container}>
				{history.isOnPublicPage() ? (
					<img
						src={logo.small.white}
						alt="Spork Bytes"
						className={classes.logo}
					/>
				) : (
					<Toolbar className={classes.toolbar}>
						{loading ? (
							<CenteredCircularProgress
								className={classes.progress}
							/>
						) : (
							toolbarContent ?? <DefaultToolbar />
						)}
					</Toolbar>
				)}
			</AppBar>
			<NavigationDrawer
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}
			/>
		</>
	);
};

export default AppToolbar;
