import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { sendShareableMenuLink } from '@sporkbytes/api-client/MealOptions';
import { Dialog } from '@sporkbytes/material-ui-kit-react';
import { getIncludedClientContacts } from '@sporkbytes/meal-proposal-utils';
import { getPartnerNames } from '@sporkbytes/purchase-order-utils';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import SendEmailDialogForm from 'components/email-templates/SendEmailDialogForm';

import { useStore } from 'models/store';

import { formatZonedTime } from 'services/dates';
import axios from 'services/data';

const MEAL_OPTION_QUERY = gql`
	query MealOptionSendShareableMenuLinkDialog($id: uuid!) {
		MealOptions_by_pk(id: $id) {
			MealProposal {
				deliveryDate
				ClientLocation {
					timezone
				}
				MealProposalsContacts {
					Contact {
						firstName
					}
				}
				SporkContact {
					firstName
				}
			}
			PurchaseOrders {
				PartnerLocation {
					Partner {
						name
					}
				}
			}
		}
	}
`;

const MealOptionSendShareableMenuLinkDialog = ({ id, ...props }) => {
	const { data, loading } = useQuery(MEAL_OPTION_QUERY, {
		variables: {
			id,
		},
	});
	const { showSuccessNotification } = useStore();

	if (loading) {
		return <Dialog loading={loading} {...props} />;
	}

	return (
		<SendEmailDialogForm
			substitutions={{
				'-deliveryDate-': formatZonedTime(
					data.MealOptions_by_pk.MealProposal.deliveryDate,
					'date',
					data.MealOptions_by_pk.MealProposal.ClientLocation.timezone
				),
				'-deliveryDayOfWeek-': formatZonedTime(
					data.MealOptions_by_pk.MealProposal.deliveryDate,
					'dayOfWeek',
					data.MealOptions_by_pk.MealProposal.ClientLocation.timezone
				),
				'-deliveryTime-': formatZonedTime(
					data.MealOptions_by_pk.MealProposal.deliveryDate,
					'time',
					data.MealOptions_by_pk.MealProposal.ClientLocation.timezone
				),
				'-partnerNames-': getPartnerNames(
					data.MealOptions_by_pk.PurchaseOrders
				),
				'-recipientFirstNames-': makeCommaSeparatedList(
					getIncludedClientContacts(
						data.MealOptions_by_pk.MealProposal
					).map(({ firstName }) => firstName)
				),
				'-sporkContactFirstName-':
					data.MealOptions_by_pk.MealProposal.SporkContact.firstName,
			}}
			onSubmit={values =>
				sendShareableMenuLink(axios, id, values).then(
					showSuccessNotification
				)
			}
			emailTemplateId="sendShareableMenuLink"
			{...props}
		/>
	);
};

MealOptionSendShareableMenuLinkDialog.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MealOptionSendShareableMenuLinkDialog;
