export const accentedCharacterMap = {
	à: 'a',
	á: 'a',
	â: 'a',
	ã: 'a',
	ä: 'a',
	å: 'a',
	ç: 'c',
	è: 'e',
	é: 'e',
	ê: 'e',
	ë: 'e',
	ì: 'i',
	í: 'i',
	î: 'i',
	ï: 'i',
	ñ: 'n',
	ð: 'o',
	ò: 'o',
	ó: 'o',
	ô: 'o',
	õ: 'o',
	ö: 'o',
	ù: 'u',
	ú: 'u',
	û: 'u',
	ü: 'u',
	ý: 'y',
	ÿ: 'y',
};

export const html = '(<\\/?[^>]*>)|(&[^\\s;]*;)';
export const nonAlphaNumeric = '[^a-z0-9\\s]';
export const whitespace = '\\s+';

export const removeHtml = string => string.replace(new RegExp(html, 'g'), '');
