import React from 'react';
import gql from 'graphql-tag';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteMealProposalFees,
	put,
} from '@sporkbytes/api-client/MealProposalFees';

import FormWrapper from 'components/forms/FormWrapper';
import MealProposalFeeForm from 'components/meal-proposal-fees/MealProposalFeeForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const MEAL_PROPOSAL_FEE_QUERY = gql`
	query MealProposalFeeEdit($id: uuid!) {
		MealProposalFees_by_pk(id: $id) {
			name
			description
			amount
			discount
			type
			FeeId
			MealProposalId
			MealProposal {
				orderNumberText
			}
		}
	}
`;

const Edit = () => {
	const { id } = useParams();
	const { pushNamedRoute } = useHistory();

	return (
		<Page
			title={({ MealProposalFees_by_pk: { name } }) => `Edit ${name}`}
			query={MEAL_PROPOSAL_FEE_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					MealProposalFees_by_pk: {
						name,
						MealProposal: { orderNumberText },
						FeeId,
						MealProposalId,
						...mealProposalFee
					},
				},
			}) => (
				<>
					<PageHeader
						headerText={`Edit ${name} for Meal Proposal ${orderNumberText}`}
					/>
					<FormWrapper
						form={MealProposalFeeForm}
						initialValues={{
							name,
							...mealProposalFee,
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
						onDelete={
							!FeeId
								? axios =>
										deleteMealProposalFees(axios, id).then(
											() =>
												pushNamedRoute(
													'mealProposalsDetail',
													{
														id: MealProposalId,
														tab: 'fees',
													}
												)
										)
								: undefined
						}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
