import React from 'react';
import PropTypes from 'prop-types';
import {
	ListItem as MUIListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';

const ListItem = ({ icon, text, children, ...props }) => (
	<MUIListItem {...props}>
		{icon && <ListItemIcon>{icon}</ListItemIcon>}
		{text && <ListItemText primary={text} />}
		{children}
	</MUIListItem>
);

ListItem.propTypes = {
	icon: PropTypes.element,
	text: PropTypes.string,
};

export default ListItem;
