import React from 'react';
import { post } from '@sporkbytes/api-client/Users';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import UserForm from 'components/users/UserForm';

const Create = () => {
	return (
		<Page title="Add New User">
			<PageHeader headerText="Add New User" />
			<FormWrapper form={UserForm} onSubmit={post} />
		</Page>
	);
};

export default Create;
