import React from 'react';
import createVanillaStore from 'zustand/vanilla';
import createHookStore from 'zustand';
import { persist } from 'zustand/middleware';
import gql from 'graphql-tag';

import apolloClient from 'services/apollo';

export const store = createVanillaStore(
	persist(
		(set, get) => {
			const showDialog = (Dialog, dialogProps) => {
				return set({
					Dialog: props => <Dialog {...dialogProps} {...props} />,
					dialogOpen: true,
				});
			};

			return {
				// Dialog
				dialogOpen: false,
				onDialogClose: () => set({ dialogOpen: false }),
				Dialog: undefined,
				hideDialog: () => set({ dialogOpen: false }),
				showDialog: showDialog,
				showDialogs: dialogs => {
					let sequence = Promise.resolve();

					dialogs.forEach(Dialog => {
						sequence = sequence.then(
							() =>
								new Promise((resolve, _reject) => {
									showDialog(({ onClose, ...props }) => (
										<Dialog
											showNextDialog={() => {
												resolve();
											}}
											onClose={() => {
												onClose();
												resolve();
											}}
											{...props}
										/>
									));
								})
						);
					});

					return sequence;
				},

				// Notifications
				notificationOpen: false,
				notificationType: 'success',
				notificationMessage: '',
				onNotificationClose: () => set({ notificationOpen: false }),
				showErrorNotification: message =>
					set({
						notificationOpen: true,
						notificationType: 'error',
						notificationMessage: message,
					}),
				showSuccessNotification: message =>
					set({
						notificationOpen: true,
						notificationType: 'success',
						notificationMessage: message,
					}),

				// Toolbar
				toolbarContent: undefined,
				setToolbarContent: toolbarContent => set({ toolbarContent }),

				// App Updates
				updateRequired: false,
				setUpdateRequired: updateRequired => set({ updateRequired }),

				// Active Spork Locations
				activeSporkLocations: [],
				hasMoreThanOneSporkLocation: () =>
					get().activeSporkLocations.length > 1,
				setActiveSporkLocations: async () => {
					const {
						data: { SporkLocations },
					} = await apolloClient.query({
						query: gql`
							query ActiveSporkLocations {
								SporkLocations(
									where: { active: { _eq: true } }
									order_by: { sortOrder: asc }
								) {
									id
									name
								}
							}
						`,
					});
					const currentSelectedSporkLocationId =
						get().selectedSporkLocationId;
					const activeSporkLocationsIncludeCurrentSelectedLocation =
						SporkLocations.map(({ id }) => id).includes(
							currentSelectedSporkLocationId
						);
					const selectedSporkLocationId =
						SporkLocations.length === 1 ||
						!activeSporkLocationsIncludeCurrentSelectedLocation
							? SporkLocations[0].id
							: currentSelectedSporkLocationId;

					set({
						activeSporkLocations: SporkLocations,
						selectedSporkLocationId,
					});
				},

				// Filter by Spork Locations
				filterBySporkLocations: [],
				setFilterBySporkLocations: filterBySporkLocations => {
					set({
						filterBySporkLocations,
					});
				},

				// Selected Spork Location
				selectedSporkLocationId: undefined,
				setSelectedSporkLocationId: selectedSporkLocationId => {
					set({
						selectedSporkLocationId,
					});
				},
			};
		},
		{
			name: 'settings',
			partialize: state => ({
				filterBySporkLocations: state.filterBySporkLocations,
				selectedSporkLocationId: state.selectedSporkLocationId,
			}),
		}
	)
);

export const useStore = createHookStore(store);
