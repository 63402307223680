import React from 'react';
import { Typography } from '@material-ui/core';
import { Alert } from '@sporkbytes/material-ui-kit-react';
import { reviewableMealsTimeframe } from '@sporkbytes/config';

import Page from 'components/layout/Page';

const NoReviewableMealFoundPage = () => {
	return (
		<Page title="Meal Not Found">
			<Alert type="info" size="large">
				No meal was found.
			</Alert>
			<Typography variant="h6">
				This meal cannot be reviewed. Reviews can be given up to{' '}
				{reviewableMealsTimeframe.amount}{' '}
				{reviewableMealsTimeframe.scale} after the meal is delivered.
			</Typography>
		</Page>
	);
};

export default NoReviewableMealFoundPage;
