import Edit from 'pages/purchase-order-payout-adjustments/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'purchaseOrderPayoutAdjustmentsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['purchase-order-payout-adjustments:edit'],
		exact: true,
	},
];

export default prependUrlPath('purchase-order-payout-adjustments', routes);
