import showPurchaseOrderSelectionDialog from '../showPurchaseOrderSelectionDialog';

export default ({ history, id, setLoading }) => async () => {
	await showPurchaseOrderSelectionDialog({
		MealProposalId: id,
		onPurchaseOrderSelect: selectedPurchaseOrderId => {
			history.pushNamedRoute('purchaseOrdersEdit', {
				id: selectedPurchaseOrderId,
			});
		},
		setLoading,
	});
};
