import React from 'react';
import gql from 'graphql-tag';

import DeliveryDriverDataTable from 'components/analytics/widgets/DeliveryDriverDataTable';
import DeliveryDriverNeedsOverTime from 'components/analytics/widgets/DeliveryDriverNeedsOverTime';
import DeliveryDriversToClient from 'components/analytics/widgets/DeliveryDriversToClient';
import DeliveryDriversToPartner from 'components/analytics/widgets/DeliveryDriversToPartner';
import WidgetContainer from 'components/analytics/WidgetContainer';
import WidgetRow from 'components/analytics/WidgetRow';

const DeliveryDrivers = () => {
	return (
		<>
			<WidgetRow>
				<WidgetContainer xs={12}>
					<DeliveryDriverNeedsOverTime />
				</WidgetContainer>
			</WidgetRow>
			<WidgetRow>
				<WidgetContainer xs={12}>
					<DeliveryDriverDataTable />
				</WidgetContainer>
			</WidgetRow>
			<WidgetRow>
				<WidgetContainer xs={12}>
					<DeliveryDriversToClient />
				</WidgetContainer>
			</WidgetRow>
			<WidgetRow>
				<WidgetContainer xs={12}>
					<DeliveryDriversToPartner />
				</WidgetContainer>
			</WidgetRow>
		</>
	);
};

DeliveryDrivers.fragments = {
	details: gql`
		fragment AnalyticsDeliveryDriversDetails on MealProposals {
			...DeliveryDriverDataTableDetails
			...DeliveryDriverNeedsOverTimeDetails
			...DeliveryDriversToClientDetails
			...DeliveryDriversToPartnerDetails
		}
		${DeliveryDriverDataTable.fragments.details}
		${DeliveryDriverNeedsOverTime.fragments.details}
		${DeliveryDriversToClient.fragments.details}
		${DeliveryDriversToPartner.fragments.details}
	`,
};

export default DeliveryDrivers;
