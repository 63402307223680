import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { sort } from '@sporkbytes/api-client/PartnerMenuSections';

import EditableSortOrder from 'components/forms/EditableSortOrder';
import PartnerMenuItemsTable from 'components/partner-menu-sections/PartnerMenuItemsTable';
import PartnerMenuSectionActionMenu from 'components/partner-menu-sections/PartnerMenuSectionActionMenu';
import RemoteTable from 'components/content/RemoteTable';

import { getMultiWordSearchWhereClause } from 'services/search';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const getPartnerMenuQuery = searchTerm => gql`
	query PartnerMenu(
		$PartnerId: uuid!
		$nameOrderBy: order_by
		$sortOrderOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		PartnerMenuSections(
			where: {
				_and: [
					{ PartnerId: { _eq: $PartnerId } }
					${getMultiWordSearchWhereClause(searchTerm)}
				]
			}
			order_by: { 
				name: $nameOrderBy 
				sortOrder: $sortOrderOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			id
			name
			sortOrder
		}
		PartnerMenuSections_aggregate(
			where: {
				_and: [
					{ PartnerId: { _eq: $PartnerId } }
					${getMultiWordSearchWhereClause(searchTerm)}
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const PartnerMenuTable = ({ PartnerId }) => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<RemoteTable
			tableRef={ref}
			columns={[
				{
					title: 'Sort Order',
					field: 'sortOrder',
					render: ({ id, sortOrder }) => (
						<EditableSortOrder
							onSave={values =>
								sort(axios, id, {
									newValue: values,
								}).then(ref.current.onQueryChange)
							}
							permissions={['partner-menu-sections:edit']}
							required={true}
							value={sortOrder}
						/>
					),
				},
				{
					title: 'Name',
					field: 'name',
					render: ({ name }) => name,
				},
				{
					title: 'Actions',
					field: 'actions',
					sorting: false,
					render: partnerMenuSection => (
						<PartnerMenuSectionActionMenu
							partnerMenuSection={partnerMenuSection}
						/>
					),
				},
			]}
			defaultOrderBy="sortOrder"
			defaultOrderDirection="asc"
			getQuery={getPartnerMenuQuery}
			queryEntity="PartnerMenuSections"
			variables={{ PartnerId }}
			detailPanel={({ id }) => (
				<PartnerMenuItemsTable
					PartnerMenuSectionId={id}
					PartnerId={PartnerId}
				/>
			)}
			entityToAddName="Partner Menu Section"
			canAddNewEntity={userCan(['partner-menu-sections:create'])}
			addNewEntityRouteName="partnerMenuSectionsCreate"
			addNewEntityRouteVariables={{ id: PartnerId }}
		/>
	);
};

PartnerMenuTable.propTypes = {
	PartnerId: PropTypes.string.isRequired,
};

export default PartnerMenuTable;
