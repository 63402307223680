import MealCategories from 'pages/meal-categories/MealCategories';
import Create from 'pages/meal-categories/Create';
import Edit from 'pages/meal-categories/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'mealCategories',
		path: '',
		component: MealCategories,
		permissions: ['meal-categories:view'],
		exact: true,
	},
	{
		name: 'mealCategoriesCreate',
		path: '/create',
		component: Create,
		permissions: ['meal-categories:create'],
		exact: true,
	},
	{
		name: 'mealCategoriesEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['meal-categories:edit'],
		exact: true,
	},
];

export default prependUrlPath('meal-categories', routes);
