import React from 'react';
import { post } from '@sporkbytes/api-client/MealProposals';

import FormWrapper from 'components/forms/FormWrapper';
import MealProposalForm from 'components/meal-proposals/MealProposalForm';
import Page from 'components/layout/Page';

import { getDefaultDeliveryDate } from 'services/dates';
import { useStore } from 'models/store';

const Create = () => {
	const { selectedSporkLocationId } = useStore();
	const initialValues = {
		automatedApprovalThankYou: true,
		automatedEmailConfirmation: true,
		automatedReviewRequest: true,
		deliveryDate: getDefaultDeliveryDate(),
		SporkLocationId: selectedSporkLocationId,
	};

	return (
		<Page title="New Meal Proposal">
			<FormWrapper
				form={MealProposalForm}
				initialValues={initialValues}
				pageSubheaderText="New Meal Proposal"
				onSubmit={(axios, values) => post(axios, values)}
				redirectToRoute="mealProposalsDetail"
				routeVariables={() => ({ tab: 'contacts' })}
			/>
		</Page>
	);
};

export default Create;
