import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { Fastfood, FlightLand, FlightTakeoff } from '@material-ui/icons';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { calculate } from '@sporkbytes/api-client/Distances';

import DriverSchedulesDialog from 'pages/dashboard/DriverSchedulesDialog';
import ScheduleTimeline from 'pages/dashboard/ScheduleTimeline';

import useRequest from 'hooks/useRequest';

import { formatZonedTime } from 'services/dates';
import axios from 'services/data';

const DateIcon = ({ Icon, date, timezone, action }) => {
	return (
		<Tooltip
			title={
				date
					? `${action} at ${formatZonedTime(date, 'time', timezone)}`
					: `Has not ${action.toLowerCase()}`
			}
		>
			<Grid container item direction="column" alignItems="center" xs={4}>
				<Icon color={!!date ? undefined : 'disabled'} />
				{date && (
					<Box marginTop={0.5}>
						<Typography variant="caption" noWrap>
							{formatZonedTime(date, 'time', timezone)}
						</Typography>
					</Box>
				)}
			</Grid>
		</Tooltip>
	);
};

const MEAL_PROPOSALS_SUBSCRIPTION = gql`
	subscription DriverTrackerDialog($ids: [uuid!]!) {
		MealProposals(where: { id: { _in: $ids } }) {
			id
			arrivedAt
			deliveredAt
			leftAt
			MealOptions(where: { approved: { _eq: true } }) {
				id
				PurchaseOrders(order_by: { pickupTime: asc }) {
					id
					arrivedAt
					pickedUpAt
					leftAt
				}
			}
			...ScheduleTimeline
		}
	}
	${ScheduleTimeline.fragments.details}
`;

const DriverTrackerDialog = ({ deliveryDriver, mealProposalIds, ...props }) => {
	const {
		data: { MealProposals } = { MealProposals: [] },
		loading: loadingMealProposals,
	} = useSubscription(MEAL_PROPOSALS_SUBSCRIPTION, {
		variables: { ids: mealProposalIds },
	});

	const { data: distances = {}, loading: loadingDistances } = useRequest(() =>
		calculate(axios, {
			groupedMealProposalIds: [mealProposalIds],
		})
	);

	return (
		<DriverSchedulesDialog
			{...props}
			title={deliveryDriver.abbreviatedName}
			loading={loadingMealProposals || loadingDistances}
		>
			<ScheduleTimeline
				mealProposals={MealProposals}
				distances={distances}
				header={({ type, timezone, purchaseOrder, mealProposal }) => {
					let arrivedAt;
					let finishedJobAt;
					let leftAt;
					let finishedJobAction;

					if (type === 'ClientLocation') {
						({
							arrivedAt,
							deliveredAt: finishedJobAt,
							leftAt,
						} = mealProposal);
						finishedJobAction = 'Delivered';
					} else {
						({
							arrivedAt,
							pickedUpAt: finishedJobAt,
							leftAt,
						} = purchaseOrder);
						finishedJobAction = 'Picked Up';
					}

					return (
						<Grid container item spacing={3}>
							<DateIcon
								Icon={FlightLand}
								date={arrivedAt}
								timezone={timezone}
								action="Arrived"
							/>
							<DateIcon
								Icon={Fastfood}
								date={finishedJobAt}
								timezone={timezone}
								action={finishedJobAction}
							/>
							<DateIcon
								Icon={FlightTakeoff}
								date={leftAt}
								timezone={timezone}
								action="Left"
							/>
						</Grid>
					);
				}}
			/>
		</DriverSchedulesDialog>
	);
};

DriverTrackerDialog.propTypes = {
	deliveryDriver: PropTypes.shape({
		abbreviatedName: PropTypes.string.isRequired,
	}).isRequired,
	mealProposalIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DriverTrackerDialog;
