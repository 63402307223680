import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import ListItem from 'components/utilities/ListItem';

const useStyles = makeStyles(theme => ({
	collapse: {
		paddingLeft: theme.spacing(2),
	},
}));

const NestedListItem = ({ children, defaultOpen, items, ...props }) => {
	const [open, setOpen] = useState(defaultOpen);
	const classes = useStyles();

	return (
		<>
			<ListItem {...props} button onClick={() => setOpen(!open)}>
				{children}
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open} className={classes.collapse}>
				{items}
			</Collapse>
		</>
	);
};

NestedListItem.propTypes = {
	defaultOpen: PropTypes.bool,
	items: PropTypes.arrayOf(PropTypes.element).isRequired,
};

NestedListItem.defaultProps = {
	defaultOpen: false,
};

export default NestedListItem;
