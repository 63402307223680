import React from 'react';
import gql from 'graphql-tag';
import { TableCell, TableRow } from '@material-ui/core';
import { sporkCommission } from '@sporkbytes/purchase-order-utils';

import TableCellNumeric from 'components/content/TableCellNumeric';

const PurchaseOrderSporkCommissionLineItem = ({ purchaseOrder }) => (
	<TableRow>
		<TableCell colSpan="4">Spork Commission</TableCell>
		<TableCellNumeric
			format="currency"
			value={sporkCommission(purchaseOrder)}
		/>
	</TableRow>
);

PurchaseOrderSporkCommissionLineItem.fragments = {
	details: gql`
		fragment PurchaseOrderSporkCommissionLineItemDetails on PurchaseOrders {
			sporkCommissionPercent
			PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
				PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
					discount
					paidToPartner
					quantity
				}
			}
		}
	`,
};

export default PurchaseOrderSporkCommissionLineItem;
