import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { Typography } from '@material-ui/core';
import { Alert } from '@sporkbytes/material-ui-kit-react';
import { duplicate } from '@sporkbytes/api-client/MealProposals';

import FormWrapper from 'components/forms/FormWrapper';
import MealProposalForm from 'components/meal-proposals/MealProposalForm';
import Page from 'components/layout/Page';

import { getDefaultDeliveryDate } from 'services/dates';

const MEAL_PROPOSAL_QUERY = gql`
	query MealProposalEdit($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			automatedApprovalThankYou
			automatedEmailConfirmation
			automatedReviewRequest
			budgetPerPerson
			ClientLocationId
			deliveryInstructions
			dietaryRestrictions
			headCount
			name
			orderNumberText
			setupInstructions
			SporkLocationId
			totalBudget
			ClientLocation {
				fullAddress
				timezone
				Client {
					id
					logoUrl
					name
				}
			}
		}
	}
`;

const Duplicate = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ MealProposals_by_pk: { orderNumberText } }) =>
				`Duplicate ${orderNumberText}`
			}
			query={MEAL_PROPOSAL_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					MealProposals_by_pk: {
						orderNumberText,
						ClientLocation,
						...initialValues
					},
				},
			}) => (
				<FormWrapper
					form={MealProposalForm}
					initialValues={{
						...initialValues,
						deliveryDate: getDefaultDeliveryDate(),
					}}
					pageSubheaderText={
						<>
							<Typography variant="inherit">
								Duplicate Meal Proposal {orderNumberText}
							</Typography>
							<Alert type="info">
								Please confirm that the prices, quantities, and
								active status of the menu items are accurate.
							</Alert>
						</>
					}
					clientLocation={ClientLocation}
					onSubmit={(axios, values) => duplicate(axios, id, values)}
					redirectToRoute="mealProposalsDetail"
					routeVariables={() => ({ tab: 'contacts' })}
				/>
			)}
		</Page>
	);
};

export default Duplicate;
