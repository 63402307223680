import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/DeliveryGearTypes';

import EditableValue from 'components/forms/EditableValue';
import DeliveryGearTable from 'components/delivery-gear/DeliveryGearTable';
import RemoteTable from 'components/content/RemoteTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const getDeliveryGearTypesQuery = searchTerm =>
	gql`
		query DeliveryGearTypes(
			$nameOrderBy: order_by
			$limit: Int!
			$offset: Int!
		) {
			DeliveryGearTypes(
				where: { name: { _ilike: "%${searchTerm}%" } }
				order_by: { name: $nameOrderBy }
				limit: $limit
				offset: $offset
			) {
				id
				name	
			}
			DeliveryGearTypes_aggregate(
				where: { name: { _ilike: "%${searchTerm}%" } }
			) {
				aggregate {
					count
				}
			}
		}
	`;

const Detail = () => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<Page title="Delivery Gear Types">
			<PageHeader headerText="Delivery Gear Types" />
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Name',
						field: 'name',
						render: ({ id, name }) => (
							<EditableValue
								name="name"
								onSave={name =>
									put(axios, id, { name }).then(
										ref.current.onQueryChange
									)
								}
								permissions={['delivery-gear-types:edit']}
								required={true}
								type="name"
								value={name}
							/>
						),
					},
				]}
				detailPanel={({ id }) => (
					<DeliveryGearTable DeliveryGearTypeId={id} />
				)}
				getQuery={getDeliveryGearTypesQuery}
				defaultOrderBy="name"
				defaultOrderDirection="asc"
				queryEntity="DeliveryGearTypes"
				addNewEntityRouteName="deliveryGearTypesCreate"
				entityToAddName="Delivery Gear Type"
				canAddNewEntity={userCan(['delivery-gear-types:create'])}
			/>
		</Page>
	);
};

export default Detail;
