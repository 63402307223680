import React from 'react';
import gql from 'graphql-tag';
import { useHistory, useParams } from 'react-router-dom';
import {
	deletePartnerListMenus,
	put,
} from '@sporkbytes/api-client/PartnerListMenus';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PartnerListMenuForm from 'components/partner-list-menus/PartnerListMenuForm';

// TODO: use fragments here
const PARTNER_MENU_QUERY = gql`
	query PartnerListMenuEdit($id: uuid!) {
		PartnerListMenus_by_pk(id: $id) {
			name
			PartnerId
			Partner {
				logoUrl
				name
			}
			PartnerListMenusMealTypes {
				MealTypeId
			}
		}
	}
`;

const Edit = () => {
	const { id } = useParams();
	const { pushNamedRoute } = useHistory();

	return (
		<Page
			title={({ PartnerListMenus_by_pk: { name } }) => `Edit ${name}`}
			query={PARTNER_MENU_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					PartnerListMenus_by_pk: {
						name,
						PartnerId,
						Partner: { logoUrl, name: PartnerName },
						PartnerListMenusMealTypes,
					},
				},
			}) => (
				<>
					<PageHeader headerText={PartnerName} imgUrl={logoUrl} />
					<PageSubheader>Edit {name}</PageSubheader>
					<FormWrapper
						form={PartnerListMenuForm}
						initialValues={{
							name,
							MealTypes: PartnerListMenusMealTypes.map(
								({ MealTypeId }) => MealTypeId
							),
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
						onDelete={axios =>
							deletePartnerListMenus(axios, id).then(() =>
								pushNamedRoute('partnersDetail', {
									id: PartnerId,
									tab: 'partner-list-menus',
								})
							)
						}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
