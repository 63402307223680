import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/react';
import './global.css';

import AppToolbar from 'components/layout/AppToolbar';
import ErrorBoundaryFallback from 'components/layout/ErrorBoundaryFallback';
import Footer from 'components/layout/Footer';
import GlobalDialog from 'components/utilities/GlobalDialog';
import GlobalNotification from 'components/utilities/GlobalNotification';
import Main from 'components/layout/Main';
import Maintenance from 'components/utilities/Maintenance';
import ProtectedRoute from 'components/routing/ProtectedRoute';
import UpdateNotification from 'components/utilities/UpdateNotification';

import useHashUrlRedirect from 'hooks/useHashUrlRedirect';

import Analytics from 'pages/analytics';
import BagTagsDay from 'pages/BagTagsDay';
import Dashboard from 'pages/dashboard';
import Home from 'pages/home';
import NotFound from 'pages/NotFound';
import PrintableLabelsDay from 'pages/PrintableLabelsDay';

import accountingRoutes from 'pages/accounting/routes';
import accountingSettingsRoutes from 'pages/accounting-settings/routes';
import cancellationReasonsRoutes from 'pages/cancellation-reasons/routes';
import clientLocationsRoutes from 'pages/client-locations/routes';
import clientsRoutes from 'pages/clients/routes';
import contactsRoutes from 'pages/contacts/routes';
import deliveryDriversRoutes from 'pages/delivery-drivers/routes';
import deliveryGearRoutes from 'pages/delivery-gear/routes';
import deliveryGearTypesRoutes from 'pages/delivery-gear-types/routes';
import dietaryTagsRoutes from 'pages/dietary-tags/routes';
import emailActivityLogsRoutes from 'pages/email-activity-logs/routes';
import emailTemplatesRoutes from 'pages/email-templates/routes';
import featuredPartnersRoutes from 'pages/featured-partners/routes';
import feesRoutes from 'pages/fees/routes';
import mealCategoriesRoutes from 'pages/meal-categories/routes';
import mealOptionsRoutes from 'pages/meal-options/routes';
import mealProposalFeesRoutes from 'pages/meal-proposal-fees/routes';
import mealProposalHubSpotSyncsRoutes from 'pages/meal-proposal-hub-spot-syncs/routes';
import mealProposalsRoutes from 'pages/meal-proposals/routes';
import mealTypesRoutes from 'pages/meal-types/routes';
import partnerListMenuSectionsRoutes from 'pages/partner-list-menu-sections/routes';
import partnerListMenusRoutes from 'pages/partner-list-menus/routes';
import partnerLocationsRoutes from 'pages/partner-locations/routes';
import partnerMenuItemOptionGroupsRoutes from 'pages/partner-menu-item-option-groups/routes';
import partnerMenuItemsRoutes from 'pages/partner-menu-items/routes';
import partnerMenuSectionsRoutes from 'pages/partner-menu-sections/routes';
import partnersRoutes from 'pages/partners/routes';
import partnerTagsRoutes from 'pages/partner-tags/routes';
import publicRoutes from 'pages/public/routes';
import purchaseOrderPayoutAdjustmentsRoutes from 'pages/purchase-order-payout-adjustments/routes';
import purchaseOrdersRoutes from 'pages/purchase-orders/routes';
import servicedStatesRoutes from 'pages/serviced-states/routes';
import sporkLocationsRoutes from 'pages/spork-locations/routes';
import sporkReviewQuestionsRoutes from 'pages/spork-review-questions/routes';
import sporkReviewsRoutes from 'pages/spork-reviews/routes';
import usersRoutes from 'pages/users/routes';

import apolloClient from 'services/apollo';

import theme from 'theme';
import history from 'services/history';

const App = () => {
	useHashUrlRedirect();

	const routes = [
		{
			name: 'analytics',
			path: '/analytics',
			component: Analytics,
			permissions: ['analytics:view'],
			exact: true,
		},
		{
			name: 'bagTagsDay',
			path: '/bag-tags/:day',
			component: BagTagsDay,
			permissions: ['meal-proposals:view'],
			exact: true,
		},
		{
			name: 'dashboard',
			path: '/dashboard',
			component: Dashboard,
			permissions: ['meal-proposals:view'],
			exact: true,
		},
		{
			name: 'home',
			path: '/',
			component: Home,
			permissions: ['meal-proposals:view'],
			exact: true,
		},
		{
			name: 'printableLabelsDay',
			path: '/printable-labels/:day',
			component: PrintableLabelsDay,
			permissions: ['meal-proposals:view', 'partner-menu-items:view'],
			exact: true,
		},
		...accountingRoutes,
		...accountingSettingsRoutes,
		...cancellationReasonsRoutes,
		...clientLocationsRoutes,
		...clientsRoutes,
		...contactsRoutes,
		...deliveryDriversRoutes,
		...deliveryGearRoutes,
		...deliveryGearTypesRoutes,
		...dietaryTagsRoutes,
		...emailActivityLogsRoutes,
		...emailTemplatesRoutes,
		...featuredPartnersRoutes,
		...feesRoutes,
		...mealCategoriesRoutes,
		...mealOptionsRoutes,
		...mealProposalFeesRoutes,
		...mealProposalHubSpotSyncsRoutes,
		...mealProposalsRoutes,
		...mealTypesRoutes,
		...partnerListMenuSectionsRoutes,
		...partnerListMenusRoutes,
		...partnerLocationsRoutes,
		...partnerMenuItemOptionGroupsRoutes,
		...partnerMenuItemsRoutes,
		...partnerMenuSectionsRoutes,
		...partnersRoutes,
		...partnerTagsRoutes,
		...publicRoutes,
		...purchaseOrderPayoutAdjustmentsRoutes,
		...purchaseOrdersRoutes,
		...servicedStatesRoutes,
		...sporkLocationsRoutes,
		...sporkReviewQuestionsRoutes,
		...sporkReviewsRoutes,
		...usersRoutes,
		{
			name: '404',
			component: NotFound,
		},
	];

	history.registerRoutes(routes);

	if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
		return (
			<MuiThemeProvider theme={theme}>
				<Maintenance />
			</MuiThemeProvider>
		);
	}

	return (
		<Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />} showDialog>
			<ApolloProvider client={apolloClient}>
				<MuiThemeProvider theme={theme}>
					<CssBaseline>
						<AppToolbar position="fixed" />
						<UpdateNotification />
						<GlobalDialog />
						<GlobalNotification />
						<Main>
							<Switch>
								{routes.map(({ name, ...route }) =>
									route.permissions &&
									route.permissions.length > 0 ? (
										<ProtectedRoute key={name} {...route} />
									) : (
										<Route key={name} {...route} />
									)
								)}
							</Switch>
						</Main>
						<Footer />
					</CssBaseline>
				</MuiThemeProvider>
			</ApolloProvider>
		</Sentry.ErrorBoundary>
	);
};

export default Sentry.withProfiler(App);
