import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { put } from '@sporkbytes/api-client/DeliveryDrivers';

import DeliveryDriverForm from 'components/delivery-drivers/DeliveryDriverForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const DELIVERY_DRIVER_QUERY = gql`
	query DeliveryDriverEdit($id: uuid!) {
		DeliveryDrivers_by_pk(id: $id) {
			email
			firstName
			lastName
			phoneNumber
			SporkLocationId
		}
	}
`;

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ DeliveryDrivers_by_pk: { firstName } }) =>
				`Edit ${firstName}`
			}
			query={DELIVERY_DRIVER_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					DeliveryDrivers_by_pk: { firstName, ...deliveryDriver },
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${firstName}`} />
					<FormWrapper
						form={DeliveryDriverForm}
						initialValues={{
							firstName,
							...deliveryDriver,
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
