import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { put } from '@sporkbytes/api-client/ServicedStates';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import ServicedStateForm from 'components/serviced-states/ServicedStateForm';

const SERVICED_STATE_QUERY = gql`
	query ServicedStateEdit($id: uuid!) {
		ServicedStates_by_pk(id: $id) {
			name
			abbreviation
		}
	}
`;

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ ServicedStates_by_pk: { name } }) => `Edit ${name}`}
			query={SERVICED_STATE_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					ServicedStates_by_pk: { name, ...data },
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${name}`} />
					<FormWrapper
						form={ServicedStateForm}
						initialValues={{
							name,
							...data,
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
