import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { addDays, format } from 'date-fns';
import { post } from '@sporkbytes/api-client/Invoices';
import {
	Form,
	GridContainer,
	LinkedDateTimePicker,
} from '@sporkbytes/material-ui-kit-react';

import AccountingContactSearch from 'components/accounting/AccountingContactSearch';
import InvoiceMealSelector from 'components/accounting/InvoiceMealSelector';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

import * as yup from 'models/validation';

import axios from 'services/data';

const validationSchema = yup.object().shape({
	includedMealProposalIds: yup
		.array()
		.required('Please select at least one meal.'),
	invoice: yup.object().shape({
		contact: yup.object().shape({
			contactID: yup.string().required('Please select a contact.'),
		}),
		dueDate: yup.date().required('Please select a due date.'),
	}),
});

const INVOICE_QUERY = gql`
	query CreateInvoice($ClientLocationId: uuid!) {
		ClientLocations_by_pk(id: $ClientLocationId) {
			friendlyName
			paymentDue
			Client {
				id
				name
			}
		}
	}
`;

const CreateInvoice = () => {
	const { ClientLocationId } = useParams();
	const history = useHistory();

	return (
		<Page
			title="New Invoice"
			query={INVOICE_QUERY}
			variables={{
				ClientLocationId,
			}}
		>
			{({
				data: {
					ClientLocations_by_pk: { friendlyName, paymentDue, Client },
				},
			}) => (
				<>
					<PageHeader headerText="New Invoice" />
					<PageSubheader>{`${Client.name} - ${friendlyName}`}</PageSubheader>
					<Form
						initialValues={{
							ClientId: Client.id,
							ClientLocationId,
							invoice: {
								dueDate: format(
									addDays(new Date(), paymentDue),
									'yyyy-MM-dd'
								),
								type: 'ACCREC',
							},
						}}
						onSubmit={async values => {
							await post(axios, values);

							history.pushPreviousRoute();
						}}
						validationSchema={validationSchema}
					>
						{() => (
							<>
								<GridContainer>
									<AccountingContactSearch
										autoFocus
										name="invoice.contact.contactID"
										getOptionValue={option =>
											option.contactID
										}
									/>
									<LinkedDateTimePicker
										name="invoice.dueDate"
										dateOnly
										valueFormat="yyyy-MM-dd"
										minDate={new Date()}
									/>
								</GridContainer>
								<InvoiceMealSelector
									ClientLocationId={ClientLocationId}
								/>
							</>
						)}
					</Form>
				</>
			)}
		</Page>
	);
};

export default CreateInvoice;
