import React, { useContext, useState } from 'react';
import gql from 'graphql-tag';
import Chart from 'react-apexcharts';
import { isPast, parseISO } from 'date-fns';
import { Autocomplete } from '@sporkbytes/material-ui-kit-react';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

const DeliveryDriversToClient = () => {
	const [selectedClientIndex, setSelectedClientIndex] = useState(0);
	const { chartConfig, mealProposalsForCalculations } =
		useContext(AnalyticsContext);

	let clientLocations = {};

	mealProposalsForCalculations
		.filter(({ deliveryDate }) => isPast(parseISO(deliveryDate)))
		.forEach(
			({
				ClientLocation: { id, friendlyName, Client },
				MealProposalsDeliveryDrivers,
			}) => {
				let location = clientLocations[id];

				if (!location) {
					location = {
						clientName: Client.name,
						location: friendlyName,
						drivers: {},
					};

					clientLocations[id] = location;
				}

				MealProposalsDeliveryDrivers.forEach(
					({ DeliveryDriver: { id: driverId, fullName } }) => {
						let driver = location.drivers[driverId];

						if (!driver) {
							driver = {
								fullName,
								deliveries: 0,
							};

							location.drivers[driverId] = driver;
						}

						driver.deliveries++;
					}
				);
			}
		);

	clientLocations = Object.values(clientLocations)
		.sort((clientA, clientB) => {
			if (clientA.clientName === clientB.clientName) {
				return clientA.location.localeCompare(clientB.location);
			}
			return clientA.clientName.localeCompare(clientB.clientName);
		})
		.map(({ clientName, location, drivers }, index) => ({
			value: index,
			label: `${clientName} - ${location}`,
			data: Object.values(drivers)
				.sort(
					(driverA, driverB) =>
						driverB.deliveries - driverA.deliveries
				)
				.reduce(
					(
						{ drivers, deliveriesToClient },
						{ fullName, deliveries }
					) => ({
						drivers: [...drivers, fullName],
						deliveriesToClient: [...deliveriesToClient, deliveries],
					}),
					{ drivers: [], deliveriesToClient: [] }
				),
		}));

	const currentLocation = clientLocations[selectedClientIndex];
	const deliveredMealsFound = !!currentLocation;
	const drivers = deliveredMealsFound ? currentLocation.data.drivers : [];
	const deliveriesToClient = deliveredMealsFound
		? currentLocation.data.deliveriesToClient
		: [];

	const options = {
		...chartConfig.options,
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: drivers,
		},
		yaxis: {
			tickAmount: 1,
		},
	};
	const series = [
		{
			data: deliveriesToClient,
			name: 'Deliveries',
		},
	];

	return (
		<>
			<WidgetTitle>Deliveries to Client Location</WidgetTitle>
			<Autocomplete
				getOptionLabel={({ label }) => label}
				onChange={({ value }) => setSelectedClientIndex(value)}
				options={clientLocations}
				placeholder="Select Client"
				value={currentLocation}
			/>
			<Chart
				type="bar"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</>
	);
};

DeliveryDriversToClient.fragments = {
	details: gql`
		fragment DeliveryDriversToClientDetails on MealProposals {
			ClientLocation {
				id
				friendlyName
				Client {
					name
				}
			}
			MealProposalsDeliveryDrivers {
				DeliveryDriver {
					id
					fullName
				}
			}
		}
	`,
};

export default DeliveryDriversToClient;
