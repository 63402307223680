import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Divider, List, makeStyles } from '@material-ui/core';

import navItems from './navItems';

import NavItem from './NavItem';
import NestedListItem from 'components/utilities/NestedListItem';

import { useAuth } from 'services/auth';

const useStyles = makeStyles(theme => ({
	toolbar: theme.mixins.toolbar,
}));

const DrawerContent = ({ onClose }) => {
	const { userCan } = useAuth();
	const history = useHistory();
	const classes = useStyles();

	return (
		<>
			<div className={classes.toolbar} />
			<Divider />
			<List>
				{navItems.map(({ type, navItems, ...props }) => {
					if (type === 'list') {
						return navItems.some(({ permissions = [] }) =>
							userCan(permissions)
						) ? (
							<NestedListItem
								key={props.text}
								items={navItems.map(props => (
									<NavItem
										onClose={onClose}
										key={props.text}
										{...props}
									/>
								))}
								defaultOpen={navItems.some(({ routeName }) =>
									history.isOnRoute(routeName)
								)}
								{...props}
							/>
						) : null;
					}

					return (
						<NavItem
							key={props.text}
							onClose={onClose}
							{...props}
						/>
					);
				})}
			</List>
		</>
	);
};

DrawerContent.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default DrawerContent;
