import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		margin: theme.spacing(2, 0),
	},
}));

const PageSubheader = ({ children }) => {
	const classes = useStyles();

	return (
		<Typography
			variant="h5"
			component="h2"
			align="center"
			className={classes.container}
		>
			{children}
		</Typography>
	);
};

export default PageSubheader;
