import React from 'react';
import { SingleSelect } from '@sporkbytes/material-ui-kit-react';

import { useStore } from 'models/store';

const SporkLocationGlobalFilter = ({ label, ...props }) => {
	const {
		activeSporkLocations,
		selectedSporkLocationId,
		setSelectedSporkLocationId,
	} = useStore();

	return (
		<SingleSelect
			label="Spork Location"
			onChange={setSelectedSporkLocationId}
			options={activeSporkLocations}
			value={selectedSporkLocationId}
			getOptionValue={({ id }) => id}
			getOptionKey={({ id }) => id}
			getOptionLabel={({ name }) => name}
			{...props}
		/>
	);
};

export default SporkLocationGlobalFilter;
