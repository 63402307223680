import React from 'react';
import gql from 'graphql-tag';
import { DirectionsCar, ShoppingBasket } from '@material-ui/icons';
import { canAssignDeliveryDrivers } from '@sporkbytes/meal-proposal-utils';

import ActionMenu from 'components/utilities/ActionMenu';
import MealProposalAssignDeliveryDriversDialog from 'components/meal-proposals/MealProposalAssignDeliveryDriversDialog';
import MealProposalAssignDeliveryGearDialog from 'components/meal-proposals/MealProposalAssignDeliveryGearDialog';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';

const DashboardMealProposalActionMenu = ({ mealProposal }) => {
	const { userCan } = useAuth();
	const { showDialog } = useStore();

	return (
		<ActionMenu
			options={[
				{
					label: 'Assign Delivery Drivers',
					canPerformAction:
						userCan([
							'meal-proposals-delivery-drivers:create',
							'meal-proposals-delivery-drivers:delete',
						]) && canAssignDeliveryDrivers(mealProposal),
					onClick: () =>
						showDialog(MealProposalAssignDeliveryDriversDialog, {
							id: mealProposal.id,
							SporkLocationId: mealProposal.SporkLocationId,
						}),
					icon: <DirectionsCar />,
				},
				{
					label: 'Assign Delivery Gear',
					canPerformAction: userCan([
						'meal-proposals-delivery-gear:create',
						'meal-proposals-delivery-gear:delete',
					]),
					onClick: () =>
						showDialog(MealProposalAssignDeliveryGearDialog, {
							id: mealProposal.id,
							SporkLocationId: mealProposal.SporkLocationId,
						}),
					icon: <ShoppingBasket />,
				},
			]}
		/>
	);
};

DashboardMealProposalActionMenu.fragments = {
	details: gql`
		fragment DashboardMealProposalActionMenu on MealProposals {
			id
			deliveryDate
			SporkLocationId
			status
		}
	`,
};

export default DashboardMealProposalActionMenu;
