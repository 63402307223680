import React, { useState } from 'react';

const PartnerContext = React.createContext();

const PartnerContextProvider = ({ children }) => {
	const [currentPartner, setCurrentPartner] = useState();

	return (
		<PartnerContext.Provider value={{ currentPartner, setCurrentPartner }}>
			{children}
		</PartnerContext.Provider>
	);
};

export { PartnerContext, PartnerContextProvider };
