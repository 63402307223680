import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import {
	Currency,
	DialogForm,
	GridContainer,
	LinkedCheckboxGroup,
} from '@sporkbytes/material-ui-kit-react';
import { post } from '@sporkbytes/api-client/PurchaseOrderMenuItems';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import InlineContent from 'components/content/InlineContent';
import NoteContainer from 'components/content/NoteContainer';
import PurchaseOrderMenuItemFormElements, {
	validationSchema as baseValidationSchema,
} from 'components/purchase-order-menu-items/PurchaseOrderMenuItemFormElements';
import RichText from 'components/content/RichText';

import * as yup from 'models/validation';

import axios from 'services/data';

const PARTNER_MENU_ITEM_QUERY = gql`
	query PurchaseOrderMenuItemCreateDialogFormQuery($id: uuid!) {
		PartnerMenuItems_by_pk(id: $id) {
			cost
			name
			size
			sortOrder
			PartnerMenuSection {
				friendlyName
				sortOrder
			}
			PartnerMenuItemOptionGroups(order_by: { sortOrder: asc }) {
				id
				checkOptionsByDefault
				howToOrder
				name
				PartnerMenuItemOptionGroupsPartnerMenuItems(
					order_by: { sortOrder: asc }
					where: { PartnerMenuItem: { active: { _eq: true } } }
				) {
					cost
					sortOrder
					PartnerMenuItem {
						id
						friendlyName
						howToOrder
						PartnerMenuItemsDietaryTags(
							order_by: { DietaryTag: { sortOrder: asc } }
						) {
							DietaryTag {
								name
							}
						}
					}
				}
			}
			...PurchaseOrderMenuItemFormElementsPartnerMenuItemDetails
		}
	}
	${PurchaseOrderMenuItemFormElements.fragments.partnerMenuItemDetails}
`;

const validationSchema = baseValidationSchema.concat(
	yup.object().shape({
		PartnerMenuItemOptionGroups: yup.array().of(
			yup.object().shape({
				name: yup.name().required(),
				options: yup.array().of(
					yup.object().shape({
						cost: yup.positiveNumber().nullable(),
						sortOrder: yup.positiveInteger().required(),
						PartnerMenuItem: yup.object().shape({
							id: yup.string().required(),
							friendlyName: yup.name().required(),
							howToOrder: yup.string().nullable(),
						}),
					})
				),
			})
		),
	})
);

const PurchaseOrderMenuItemCreateDialogForm = ({
	PartnerMenuItemId,
	PurchaseOrderId,
	...props
}) => {
	const [itemTotal, setItemTotal] = useState();
	const { data, loading } = useQuery(PARTNER_MENU_ITEM_QUERY, {
		variables: { id: PartnerMenuItemId },
	});

	const {
		cost,
		name,
		PartnerMenuItemOptionGroups,
		PartnerMenuSection,
		size,
		sortOrder,
		...partnerMenuItem
	} = data?.PartnerMenuItems_by_pk || {};

	const getTitle = () => {
		if (!name) {
			return 'Add';
		}

		return `Add ${name}${size ? ` (${size})` : ''}`;
	};

	return (
		<DialogForm
			{...props}
			loading={loading}
			title={getTitle()}
			onSubmit={values =>
				post(axios, {
					...values,
					PartnerMenuItemId,
					PartnerMenuSection,
					PurchaseOrderId,
					sortOrder,
				})
			}
			submitButtonContent={
				<span>
					Add
					<Currency
						value={itemTotal}
						renderText={value => ` - ${value}`}
					/>
				</span>
			}
			validationSchema={validationSchema}
			initialValues={{
				costToClient: cost,
				paidToPartner: cost,
				PartnerMenuItemOptionGroups: PartnerMenuItemOptionGroups?.map(
					optionGroup => ({
						name: optionGroup.name,
						options: optionGroup.checkOptionsByDefault
							? optionGroup.PartnerMenuItemOptionGroupsPartnerMenuItems
							: [],
					})
				),
			}}
			enableReinitialize
		>
			{() => (
				<>
					<PurchaseOrderMenuItemFormElements
						partnerMenuItem={partnerMenuItem}
						setItemTotal={setItemTotal}
					/>
					{PartnerMenuItemOptionGroups.map(
						(optionGroup, optionGroupIndex) => (
							<GridContainer key={optionGroup.id}>
								<LinkedCheckboxGroup
									name={`PartnerMenuItemOptionGroups[${optionGroupIndex}].options`}
									label={optionGroup.name}
									options={
										optionGroup.PartnerMenuItemOptionGroupsPartnerMenuItems
									}
									getOptionKey={option =>
										option.PartnerMenuItem.id
									}
									getOptionLabel={option => (
										<>
											<InlineContent component="span">
												{
													option.PartnerMenuItem
														.friendlyName
												}
											</InlineContent>
											<InlineContent component="span">
												<Currency
													value={option.cost}
													renderText={value =>
														value && ` +${value}`
													}
												/>
											</InlineContent>
											{option.PartnerMenuItem
												.howToOrder && (
												<Tooltip
													title={
														<RichText
															body={
																option
																	.PartnerMenuItem
																	.howToOrder
															}
														/>
													}
												>
													<Help />
												</Tooltip>
											)}
										</>
									)}
									getOptionHelperText={option =>
										makeCommaSeparatedList(
											option.PartnerMenuItem.PartnerMenuItemsDietaryTags?.map(
												({ DietaryTag: { name } }) =>
													name
											)
										)
									}
									showCheckAll={true}
								/>
								{optionGroup.howToOrder && (
									<NoteContainer
										content={optionGroup.howToOrder}
									/>
								)}
							</GridContainer>
						)
					)}
				</>
			)}
		</DialogForm>
	);
};

PurchaseOrderMenuItemCreateDialogForm.propTypes = {
	PartnerMenuItemId: PropTypes.string.isRequired,
	PurchaseOrderId: PropTypes.string.isRequired,
};

export default PurchaseOrderMenuItemCreateDialogForm;
