import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(0, 1),
	},
	dropOff: {
		marginLeft: 100,
	},
	headCount: {
		paddingRight: theme.spacing(4),
	},
	headerText: {
		color: theme.palette.grey[500],
		fontSize: 12,
		fontWeight: 'bold',
		letterSpacing: 1,
		textTransform: 'uppercase',
	},
	pickups: {
		marginLeft: 100,
	},
}));

const ExpandedColumnHeader = () => {
	const classes = useStyles();

	const HeaderText = ({ className = '', ...props }) => (
		<Typography
			{...props}
			className={`${classes.headerText} ${className}`}
		/>
	);

	return (
		<Grid container className={classes.container}>
			<Grid container item md={1}>
				<HeaderText>order #</HeaderText>
			</Grid>
			<Grid container justify="center" item md={1}>
				<HeaderText>Spork Contact</HeaderText>
			</Grid>
			<Grid container item md={3}>
				<HeaderText className={classes.pickups}>Pickups</HeaderText>
			</Grid>
			<Grid container item md={3}>
				<HeaderText className={classes.dropOff}>Drop Off</HeaderText>
			</Grid>
			<Grid
				container
				item
				md={1}
				justify="flex-end"
				className={classes.headCount}
			>
				<HeaderText>Head Count</HeaderText>
			</Grid>
			<Grid container justify="center" item md={1}>
				<HeaderText>Drivers</HeaderText>
			</Grid>
			<Grid container justify="center" item md={1}>
				<HeaderText>Gear</HeaderText>
			</Grid>
			<Grid container justify="center" item md={1}>
				<HeaderText>Actions</HeaderText>
			</Grid>
		</Grid>
	);
};

export default ExpandedColumnHeader;
