import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { getMinutes, getHours, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Chart from 'react-apexcharts';
import { getSummationCalculation } from '@sporkbytes/meal-proposal-utils';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import { convertHoursToMinutes, formatMinutes } from 'services/dates';
import { formatCurrency } from 'services/mealProposal';

const formatTimeRange = (start, end) =>
	`${formatMinutes(start)} - ${formatMinutes(end - 1)}`;

const findTimeRange = (date, timezone, ranges) => {
	date = utcToZonedTime(parseISO(date), timezone);

	const minutes = convertHoursToMinutes(getHours(date), getMinutes(date));

	let foundTimeRange;

	for (let index = 0; index < ranges.length - 1; index++) {
		const start = ranges[index];
		const end = ranges[index + 1];

		if (minutes >= start && minutes < end) {
			foundTimeRange = formatTimeRange(start, end);
			break;
		}
	}

	return foundTimeRange || 'Out of range';
};

const RevenueByDeliveryTimeRange = () => {
	const { chartConfig, deliveryTimeRanges, mealProposalsForCalculations } =
		useContext(AnalyticsContext);

	// The initialMealsByDeliveryTime is needed to maintain a consistent ordering for the series on the donut chart
	const initialMealsByDeliveryTimeRange = {};

	for (let index = 0; index < deliveryTimeRanges.length - 1; index++) {
		const start = deliveryTimeRanges[index];
		const end = deliveryTimeRanges[index + 1];

		initialMealsByDeliveryTimeRange[formatTimeRange(start, end)] = [];
	}

	initialMealsByDeliveryTimeRange['Out of range'] = [];

	const mealsByDeliveryTimeRange = mealProposalsForCalculations.reduce(
		(mealsByDeliveryTimeRange, meal) => {
			const timeRange = findTimeRange(
				meal.deliveryDate,
				meal.ClientLocation.timezone,
				deliveryTimeRanges
			);

			mealsByDeliveryTimeRange[timeRange].push(meal);

			return mealsByDeliveryTimeRange;
		},
		initialMealsByDeliveryTimeRange
	);

	let series = [];
	let labels = [];

	Object.keys(mealsByDeliveryTimeRange).forEach(timeRange => {
		series.push(
			getSummationCalculation(
				'totalClientPays',
				mealsByDeliveryTimeRange[timeRange]
			)
		);
		labels.push(timeRange);
	});

	const options = {
		...chartConfig.options,
		labels,
		tooltip: {
			y: {
				formatter: formatCurrency,
			},
		},
	};

	return (
		<>
			<WidgetTitle>Revenue by Delivery Time</WidgetTitle>
			<Chart
				type="donut"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</>
	);
};

RevenueByDeliveryTimeRange.fragments = {
	details: gql`
		fragment RevenueByDeliveryTimeRangeDetails on MealProposals {
			deliveryDate
			headCount
			ClientLocation {
				timezone
			}
			MealProposalFees {
				amount
				discount
				type
			}
			MealOptions {
				approved
				gratuity
				PurchaseOrders {
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							costToClient
							discount
							quantity
						}
					}
				}
			}
		}
	`,
};

export default RevenueByDeliveryTimeRange;
