import Edit from 'pages/partner-list-menu-sections/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'partnerListMenuSectionsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['partner-list-menu-sections:edit'],
		exact: true,
	},
];

export default prependUrlPath('partner-list-menu-sections', routes);
