import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Comment, Today } from '@material-ui/icons';
import {
	Form,
	GridContainer,
	LinkedDateTimePicker,
	LinkedMultiSelect,
	LinkedTextInput,
	LinkedSwitch,
} from '@sporkbytes/material-ui-kit-react';
import kebabCase from 'lodash.kebabcase';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import { LinkedS3Upload } from 'components/forms/S3Upload';

import * as yup from 'models/validation';
import { useStore } from 'models/store';

const PARTNER_FORM_QUERY = gql`
	query PartnerForm {
		PartnerTags(order_by: { name: asc }) {
			id
			name
		}
	}
`;

const PartnerForm = props => {
	const { loading, data } = useQuery(PARTNER_FORM_QUERY);
	const { activeSporkLocations, hasMoreThanOneSporkLocation } = useStore();

	const validationSchema = yup.object().shape({
		name: yup.name().required(),
		landingPageSlug: yup
			.string()
			.max(50, 'Please limit to 50 characters.')
			.matches(
				/^[a-z0-9-]+$/,
				'Only lowercase letters, numbers, and hyphens are allowed.'
			)
			.required('Please enter a landing page slug.'),
		description: yup.string(),
		PartnerTags: yup.array().of(yup.string()),
		newPartnerExpirationDate: yup.date(),
		reviewable: yup.boolean(),
		logoUrl: yup.string(),
		photoUrl: yup.string(),
		SporkLocations: yup
			.array()
			.required('Please select at least one Spork Location.'),
	});

	return loading ? (
		<CenteredCircularProgress />
	) : (
		<Form {...props} validationSchema={validationSchema}>
			{({ setFieldValue }) => (
				<>
					<GridContainer>
						<LinkedTextInput
							autoFocus
							name="name"
							onChange={({ target: { value } }) => {
								setFieldValue('name', value);
								setFieldValue(
									'landingPageSlug',
									kebabCase(value)
								);
							}}
						/>
						<LinkedTextInput name="landingPageSlug" />
						{hasMoreThanOneSporkLocation() && (
							<LinkedMultiSelect
								name="SporkLocations"
								options={activeSporkLocations}
								getOptionKey={({ id }) => id}
								getOptionValue={({ id }) => id}
								getOptionLabel={({ name }) => name}
							/>
						)}
					</GridContainer>
					<GridContainer>
						<LinkedTextInput
							name="description"
							icon={<Comment />}
							multiline
						/>
						<LinkedMultiSelect
							name="PartnerTags"
							label="Tags"
							options={data.PartnerTags}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.id}
							getOptionKey={option => option.id}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedDateTimePicker
							name="newPartnerExpirationDate"
							dateOnly
							icon={<Today />}
						/>
						<LinkedSwitch
							name="reviewable"
							uncheckedHelperText="No"
							checkedHelperText="Yes"
						/>
					</GridContainer>
					<GridContainer>
						<LinkedS3Upload
							name="logoUrl"
							label="Choose a Logo"
							path="logos/partners/"
						/>
						<LinkedS3Upload
							name="photoUrl"
							label="Choose a Photo"
							path="photos/partners/"
						/>
					</GridContainer>
				</>
			)}
		</Form>
	);
};

export default PartnerForm;
