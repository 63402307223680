import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { post } from '@sporkbytes/api-client/PartnerListMenuSections';

import FormWrapper from 'components/forms/FormWrapper';
import NameForm from 'components/forms/NameForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

const PARTNER_QUERY = gql`
	query PartnerListMenuSectionCreate($id: uuid!) {
		PartnerListMenus_by_pk(id: $id) {
			name
			Partner {
				id
				logoUrl
				name
			}
		}
	}
`;

const PartnerListMenuSectionCreate = () => {
	const { id: PartnerListMenuId } = useParams();

	return (
		<Page
			title={({ PartnerListMenus_by_pk: { name } }) =>
				`New Menu Section for ${name}`
			}
			query={PARTNER_QUERY}
			variables={{ id: PartnerListMenuId }}
		>
			{({
				data: {
					PartnerListMenus_by_pk: { name, Partner },
				},
			}) => (
				<>
					<PageHeader
						headerText={Partner.name}
						imgUrl={Partner.logoUrl}
					/>
					<PageSubheader>
						<div>{name}</div>
						<div>Add New Section</div>
					</PageSubheader>
					<FormWrapper
						form={NameForm}
						onSubmit={(axios, values) =>
							post(axios, {
								PartnerListMenuId,
								...values,
							})
						}
					/>
				</>
			)}
		</Page>
	);
};

export default PartnerListMenuSectionCreate;
