import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import DateOutput from 'components/content/DateOutput';
import DetailPageLink from 'components/routing/DetailPageLink';
import RemoteTable from 'components/content/RemoteTable';

import { getMultiWordSearchWhereClause } from 'services/search';

const getPurchaseOrderReviewsQuery = searchTerm => gql`
	query PurchaseOrderReviews(
		$ClientId: uuid
		$ClientLocationId: uuid
		$clientNameOrderBy: order_by
		$commentsOrderBy: order_by
		$createdAtOrderBy: order_by
		$MealProposalId: uuid
		$orderNumberOrderBy: order_by
		$PartnerId: uuid
		$PartnerLocationId: uuid
		$partnerNameOrderBy: order_by
		$ratingOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		PurchaseOrderReviewsView(
			where: {
				_and: [
					{ ClientId: { _eq: $ClientId } }
					{ ClientLocationId: { _eq: $ClientLocationId } }
					{ MealProposalId: { _eq: $MealProposalId } }
					{ PartnerId: { _eq: $PartnerId } }
					{ PartnerLocationId: { _eq: $PartnerLocationId } }
					${getMultiWordSearchWhereClause(searchTerm)}
				]
			}
			order_by: {
				clientName: $clientNameOrderBy
				comments: $commentsOrderBy
				createdAt: $createdAtOrderBy
				orderNumber: $orderNumberOrderBy
				partnerName: $partnerNameOrderBy
				rating: $ratingOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			ClientId
			clientName
			comments
			createdAt
			MealProposalId
			orderNumber
			orderNumberText
			PartnerId
			partnerName
			rating
		}
		PurchaseOrderReviewsView_aggregate(
			where: {
				_and: [
					{ ClientId: { _eq: $ClientId } }
					{ ClientLocationId: { _eq: $ClientLocationId } }
					{ MealProposalId: { _eq: $MealProposalId } }
					{ PartnerId: { _eq: $PartnerId } }
					{ PartnerLocationId: { _eq: $PartnerLocationId } }
					${getMultiWordSearchWhereClause(searchTerm)}
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const PurchaseOrderReviewsTable = ({ hiddenColumns, variables }) => {
	return (
		<RemoteTable
			columns={[
				{
					title: 'Order #',
					field: 'orderNumber',
					render: ({ MealProposalId, orderNumberText }) => (
						<DetailPageLink
							entityType="MealProposals"
							id={MealProposalId}
							text={orderNumberText}
						/>
					),
				},
				{
					title: 'Client',
					field: 'clientName',
					render: ({ ClientId, clientName }) => (
						<DetailPageLink
							entityType="Clients"
							id={ClientId}
							text={clientName}
						/>
					),
				},
				{
					title: 'Partner',
					field: 'partnerName',
					render: ({ PartnerId, partnerName }) => (
						<DetailPageLink
							entityType="Partners"
							id={PartnerId}
							text={partnerName}
						/>
					),
				},
				{
					title: 'Stars',
					field: 'rating',
				},
				{
					title: 'Comments',
					field: 'comments',
				},
				{
					title: 'Date Posted',
					field: 'createdAt',
					render: ({ createdAt }) => (
						<DateOutput date={createdAt} formatter="date" />
					),
				},
			].filter(({ field }) => !hiddenColumns.includes(field))}
			defaultOrderBy="createdAt"
			defaultOrderDirection="desc"
			getQuery={getPurchaseOrderReviewsQuery}
			queryEntity="PurchaseOrderReviewsView"
			variables={variables}
		/>
	);
};

PurchaseOrderReviewsTable.propTypes = {
	hiddenColumns: PropTypes.arrayOf(PropTypes.string),
	variables: PropTypes.shape({
		ClientId: PropTypes.string,
		ClientLocationId: PropTypes.string,
		MealProposalId: PropTypes.string,
		PartnerId: PropTypes.string,
		PartnerLocationId: PropTypes.string,
	}),
};

PurchaseOrderReviewsTable.defaultProps = {
	hiddenColumns: [],
	variables: {},
};

export default PurchaseOrderReviewsTable;
