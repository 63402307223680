import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { Tooltip } from '@material-ui/core';
import { getPercent } from '@sporkbytes/math-utils';
import { isDelivered } from '@sporkbytes/meal-proposal-utils';
import Money from '@sporkbytes/money';

import Table from 'components/content/Table';

import { AnalyticsContext } from 'pages/analytics/context';

import { formatCurrency, formatPercent } from 'services/mealProposal';
import TimeBins from 'services/TimeBins';

const DeliveryDriverDataTable = () => {
	const { mealProposalsForCalculations } = useContext(AnalyticsContext);

	let deliveryDriverMetrics = {};

	mealProposalsForCalculations.forEach(meal => {
		meal.MealProposalsDeliveryDrivers.map(
			({ DeliveryDriver }) => DeliveryDriver
		).forEach(({ id, firstName, lastName }) => {
			if (!deliveryDriverMetrics[id]) {
				deliveryDriverMetrics[id] = {
					id,
					lastName,
					firstName,
					deliveredCount: 0,
					deliveredAtBins: new TimeBins(),
				};
			}

			let singleDriverMetrics = deliveryDriverMetrics[id];

			singleDriverMetrics.deliveredCount++;

			if (isDelivered(meal)) {
				singleDriverMetrics.deliveredAtBins.addToBin(meal);
			}
		});
	});

	const formatPercentCells = (tooltipGetter, fieldName) => rowData => {
		const { id } = rowData;
		const { deliveredAtBins } = deliveryDriverMetrics[id];
		const tooltipValue = tooltipGetter(deliveredAtBins);
		const cellValue = rowData[fieldName];

		return (
			<Tooltip title={`${tooltipValue} Meals`}>
				<span>
					{isNaN(cellValue) ? '0%' : formatPercent(cellValue)}
				</span>
			</Tooltip>
		);
	};

	const late1BinName = TimeBins.getBinName('late', 1);
	const late2BinName = TimeBins.getBinName('late', 2);
	const late3BinName = TimeBins.getBinName('late', 3);

	const columns = [
		{ title: 'Last Name', field: 'lastName' },
		{ title: 'First Name', field: 'firstName' },
		{ title: 'Meals', field: 'deliveredCount' },
		{
			title: 'Reimbursement',
			field: 'reimbursement',
			render: ({ reimbursement }) => formatCurrency(reimbursement),
		},
		{
			title: 'Marked Delivered',
			field: 'markedDelivered',
			render: formatPercentCells(
				deliveredAtBins => deliveredAtBins.totalCount,
				'markedDelivered'
			),
		},
		{
			title: 'Early',
			field: 'early',
			render: formatPercentCells(
				deliveredAtBins => deliveredAtBins.earlyCount,
				'early'
			),
		},
		{
			title: TimeBins.getBinName('on time'),
			field: 'onTime',
			render: formatPercentCells(
				deliveredAtBins => deliveredAtBins.onTimeCount,
				'onTime'
			),
		},
		{
			title: late1BinName,
			field: 'late1',
			render: formatPercentCells(
				deliveredAtBins => deliveredAtBins.getBinSize(late1BinName),
				'late1'
			),
		},
		{
			title: late2BinName,
			field: 'late2',
			render: formatPercentCells(
				deliveredAtBins => deliveredAtBins.getBinSize(late2BinName),
				'late2'
			),
		},
		{
			title: late3BinName,
			field: 'late3',
			render: formatPercentCells(
				deliveredAtBins => deliveredAtBins.getBinSize(late3BinName),
				'late3'
			),
		},
	];

	const tableData = Object.values(deliveryDriverMetrics)
		.sort((driverA, driverB) =>
			driverA.lastName.localeCompare(driverB.lastName)
		)
		.map(
			({ id, lastName, firstName, deliveredCount, deliveredAtBins }) => ({
				id,
				lastName,
				firstName,
				deliveredCount,
				reimbursement: new Money(
					process.env.REACT_APP_REIMBURSEMENT_PER_MEAL
				)
					.multiply(deliveredCount)
					.valueOf(),
				markedDelivered: getPercent(
					deliveredAtBins.totalCount,
					deliveredCount
				),
				early: getPercent(
					deliveredAtBins.earlyCount,
					deliveredAtBins.totalCount
				),
				onTime: getPercent(
					deliveredAtBins.onTimeCount,
					deliveredAtBins.totalCount
				),
				late1: getPercent(
					deliveredAtBins.getBinSize(late1BinName),
					deliveredAtBins.totalCount
				),
				late2: getPercent(
					deliveredAtBins.getBinSize(late2BinName),
					deliveredAtBins.totalCount
				),
				late3: getPercent(
					deliveredAtBins.getBinSize(late3BinName),
					deliveredAtBins.totalCount
				),
			})
		);

	return <Table columns={columns} data={tableData} />;
};

DeliveryDriverDataTable.fragments = {
	details: gql`
		fragment DeliveryDriverDataTableDetails on MealProposals {
			deliveredAt
			deliveryDate
			status
			MealProposalsDeliveryDrivers {
				DeliveryDriver {
					id
					firstName
					lastName
				}
			}
		}
	`,
};

export default DeliveryDriverDataTable;
