import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Integer } from '@sporkbytes/material-ui-kit-react';

const HeadCount = ({ numberOnly, value, ...props }) => (
	<Typography {...props}>
		<Integer value={value} />{' '}
		{!numberOnly && (value === 1 ? 'person' : 'people')}
	</Typography>
);

HeadCount.propTypes = {
	numberOnly: PropTypes.bool,
	value: PropTypes.number.isRequired,
};

HeadCount.defaultProps = {
	numberOnly: false,
};

export default HeadCount;
