import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubscription } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import ExpansionPanel from 'components/content/ExpansionPanel';
import InlineContent from 'components/content/InlineContent';
import MealOptionSummaryTable from 'components/meal-options/MealOptionSummaryTable';
import PurchaseOrderActionMenu from 'components/purchase-orders/PurchaseOrderActionMenu/';
import PurchaseOrderSummaryHeader from 'components/purchase-orders/PurchaseOrderSummaryHeader';
import PurchaseOrderSummaryTable from 'components/purchase-orders/purchase-order-summary-table/PurchaseOrderSummaryTable';

const MEAL_OPTION_DETAILS_QUERY = gql`
	subscription MealOptionDetails($id: uuid!) {
		MealOptions_by_pk(id: $id) {
			name
			approved
			confirmed
			PurchaseOrders(order_by: { pickupTime: asc }) {
				...PurchaseOrderSummaryHeaderDetails
				...PurchaseOrderSummaryTableDetails
			}
			...MealOptionSummaryTableDetails
		}
	}
	${PurchaseOrderSummaryHeader.fragments.details}
	${PurchaseOrderSummaryTable.fragments.details}
	${MealOptionSummaryTable.fragments.details}
`;

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(2),
	},
	mealOptionSummaryTableTitle: {
		margin: theme.spacing(2, 0),
	},
}));

const MealOptionDetails = ({ id }) => {
	const classes = useStyles();
	const { data, loading } = useSubscription(MEAL_OPTION_DETAILS_QUERY, {
		variables: { id },
	});

	if (loading) {
		return <CenteredCircularProgress />;
	}

	const mealOption = data.MealOptions_by_pk;

	return (
		mealOption && (
			<div className={classes.container}>
				{mealOption.PurchaseOrders.map(purchaseOrder => (
					<ExpansionPanel
						key={purchaseOrder.id}
						title={
							<>
								<InlineContent>
									<PurchaseOrderSummaryHeader
										purchaseOrder={purchaseOrder}
										dateOutputProps={{
											variant: 'h6',
										}}
										typographyProps={{
											component: 'h3',
											variant: 'h6',
										}}
									/>
								</InlineContent>
								<PurchaseOrderActionMenu
									id={purchaseOrder.id}
								/>
							</>
						}
						defaultExpanded={mealOption.PurchaseOrders.length <= 2}
					>
						<PurchaseOrderSummaryTable
							purchaseOrder={purchaseOrder}
						/>
					</ExpansionPanel>
				))}
				<MealOptionSummaryTable
					mealOption={mealOption}
					titleProps={{
						className:
							mealOption.PurchaseOrders.length > 0
								? classes.mealOptionSummaryTableTitle
								: undefined,
					}}
				/>
			</div>
		)
	);
};

MealOptionDetails.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MealOptionDetails;
