import React from 'react';
import { post } from '@sporkbytes/api-client/ServicedStates';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import ServicedStateForm from 'components/serviced-states/ServicedStateForm';

const Create = () => {
	return (
		<Page title="Add New Serviced State">
			<PageHeader headerText="Add New Serviced State" />
			<FormWrapper form={ServicedStateForm} onSubmit={post} />
		</Page>
	);
};

export default Create;
