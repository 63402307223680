import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PurchaseOrderContext = React.createContext();

const PurchaseOrderContextProvider = ({ children, purchaseOrder }) => {
	const [currentPurchaseOrder, setCurrentPurchaseOrder] = useState(
		purchaseOrder
	);

	return (
		<PurchaseOrderContext.Provider
			value={{ currentPurchaseOrder, setCurrentPurchaseOrder }}
		>
			{children}
		</PurchaseOrderContext.Provider>
	);
};

PurchaseOrderContextProvider.propTypes = {
	purchaseOrder: PropTypes.object,
};

export { PurchaseOrderContext, PurchaseOrderContextProvider };
