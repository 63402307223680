import Callback from 'pages/accounting/Callback';
import CreateBill from 'pages/accounting/CreateBill';
import CreateInvoice from 'pages/accounting/CreateInvoice';
import Home from 'pages/accounting/Home';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'accountingCallback',
		path: '/callback',
		component: Callback,
		permissions: ['accounting:connect'],
		exact: true,
	},
	{
		name: 'accountingHome',
		path: '',
		component: Home,
		permissions: ['accounting:connect'],
		exact: true,
	},
	{
		name: 'invoicesCreate',
		path: '/client-locations/:ClientLocationId/invoices/create',
		component: CreateInvoice,
		permissions: ['invoices:create'],
		exact: true,
	},
	{
		name: 'billsCreate',
		path: '/partner-locations/:PartnerLocationId/bills/create',
		component: CreateBill,
		permissions: ['bills:create'],
		exact: true,
	},
];

export default prependUrlPath('accounting', routes);
