import axios from 'axios';

import auth from 'interceptors/auth';
import errorHandler from 'interceptors/errorHandler';
import updateRequired from 'interceptors/updateRequired';
import version from 'interceptors/version';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(auth);
axiosInstance.interceptors.response.use(updateRequired, errorHandler);
axiosInstance.interceptors.request.use(version);

export default axiosInstance;
