import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Grid, IconButton, TableRow } from '@material-ui/core';
import { Create, DeleteForever } from '@material-ui/icons';
import { ConfirmationDialog } from '@sporkbytes/material-ui-kit-react';
import { canEdit } from '@sporkbytes/purchase-order-utils';
import { itemTotalAfterDiscount } from '@sporkbytes/purchase-order-menu-item-utils';
import {
	deletePurchaseOrderMenuItems,
	sort,
} from '@sporkbytes/api-client/PurchaseOrderMenuItems';

import EditableSortOrder from 'components/forms/EditableSortOrder';
import InlineContent from 'components/content/InlineContent';
import PurchaseOrderMenuItemEditDialogForm from 'components/purchase-order-menu-items/PurchaseOrderMenuItemEditDialogForm';
import RichText from 'components/content/RichText';
import TableCellIndented from 'components/content/TableCellIndented';
import TableCellNumeric from 'components/content/TableCellNumeric';

import { PurchaseOrderContext } from 'context/PurchaseOrderContext';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const PurchaseOrderMenuItemLineItem = ({
	purchaseOrderMenuItem,
	costType,
	showDiscount,
	showPrices,
}) => {
	const { currentPurchaseOrder } = useContext(PurchaseOrderContext);
	const { role, userCan } = useAuth();
	const { showDialog } = useStore();
	const canDeleteItems =
		userCan(['purchase-order-menu-items:delete']) &&
		canEdit(currentPurchaseOrder, role);
	const canEditItems =
		userCan(['purchase-order-menu-items:edit']) &&
		canEdit(currentPurchaseOrder, role);

	const CommentComponent = props => <div {...props} />;

	return (
		<TableRow>
			<TableCellIndented style={{ width: canEditItems ? '50%' : '40%' }}>
				<Grid
					alignItems="center"
					container
					justify="space-between"
					spacing={1}
					wrap="nowrap"
				>
					{canEditItems && (
						<Grid item>
							<InlineContent>
								<EditableSortOrder
									onSave={value =>
										sort(axios, purchaseOrderMenuItem.id, {
											newValue: value,
										})
									}
									permissions={[
										'purchase-order-menu-items:edit',
									]}
									required={true}
									value={purchaseOrderMenuItem.sortOrder}
								/>
							</InlineContent>
						</Grid>
					)}
					<Grid item>
						{purchaseOrderMenuItem.PartnerMenuItem.friendlyName}
						{purchaseOrderMenuItem.PartnerMenuItem.size &&
							` (${purchaseOrderMenuItem.PartnerMenuItem.size})`}
						{purchaseOrderMenuItem.clientComment && (
							<RichText
								body={purchaseOrderMenuItem.clientComment}
								ContentComponent={CommentComponent}
							/>
						)}
						{purchaseOrderMenuItem.partnerComment && (
							<RichText
								body={purchaseOrderMenuItem.partnerComment}
								ContentComponent={CommentComponent}
							/>
						)}
					</Grid>
					{canEditItems && (
						<Grid item>
							<IconButton
								onClick={() =>
									showDialog(
										PurchaseOrderMenuItemEditDialogForm,
										{
											id: purchaseOrderMenuItem.id,
										}
									)
								}
							>
								<Create fontSize="small" />
							</IconButton>
						</Grid>
					)}
					{canDeleteItems && (
						<Grid item>
							<IconButton
								onClick={e => {
									e.stopPropagation();
									showDialog(ConfirmationDialog, {
										text: `Are you sure you want to delete ${purchaseOrderMenuItem.PartnerMenuItem.friendlyName}?`,
										onConfirmation: () =>
											deletePurchaseOrderMenuItems(
												axios,
												purchaseOrderMenuItem.id
											),
									});
								}}
							>
								<DeleteForever fontSize="small" />
							</IconButton>
						</Grid>
					)}
				</Grid>
			</TableCellIndented>
			<TableCellNumeric value={purchaseOrderMenuItem.quantity} />
			{showPrices && (
				<TableCellNumeric
					format="currency"
					value={purchaseOrderMenuItem[costType]}
				/>
			)}
			{showDiscount && showPrices && (
				<TableCellNumeric
					format="percent"
					value={purchaseOrderMenuItem.discount}
				/>
			)}
			{showPrices && (
				<TableCellNumeric
					format="currency"
					value={itemTotalAfterDiscount(
						purchaseOrderMenuItem,
						costType
					)}
					colSpan={showDiscount ? 1 : 2}
				/>
			)}
		</TableRow>
	);
};

PurchaseOrderMenuItemLineItem.fragments = {
	details: gql`
		fragment PurchaseOrderMenuItemLineItemDetails on PurchaseOrderMenuItems {
			id
			clientComment
			costToClient
			discount
			paidToPartner
			partnerComment
			quantity
			sortOrder
			PartnerMenuItem {
				friendlyName
				size
			}
		}
	`,
};

PurchaseOrderMenuItemLineItem.propTypes = {
	costType: PropTypes.string.isRequired,
	showDiscount: PropTypes.bool.isRequired,
	showPrices: PropTypes.bool.isRequired,
};

export default PurchaseOrderMenuItemLineItem;
