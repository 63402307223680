import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { DeleteForever } from '@material-ui/icons';
import {
	deleteClientLocationsFees,
	put,
} from '@sporkbytes/api-client/ClientLocationsFees';
import { ConfirmationDialog } from '@sporkbytes/material-ui-kit-react';

import ActionMenu from 'components/utilities/ActionMenu';
import EditableValue from 'components/forms/EditableValue';
import RemoteTable from 'components/content/RemoteTable';

import * as yup from 'models/validation';
import { useStore } from 'models/store';

import { createDialogAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const getClientLocationsFeesQuery = () => gql`
	query ClientLocationsFeesTable(
		$ClientLocationId: uuid!
		$amountOrderBy: order_by
		$nameOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		ClientLocationsFees(
			where: { ClientLocationId: { _eq: $ClientLocationId } }
			order_by: { amount: $amountOrderBy, Fee: { name: $nameOrderBy } }
			limit: $limit
			offset: $offset
		) {
			FeeId
			amount
			Fee {
				name
				type
				SporkLocation {
					name
				}
			}
		}
		ClientLocationsFees_aggregate(
			where: { ClientLocationId: { _eq: $ClientLocationId } }
		) {
			aggregate {
				count
			}
		}
	}
`;

const ClientLocationsFeesTable = ({ ClientLocationId }) => {
	const { userCan, userCannot } = useAuth();
	const ref = useRef();
	const { hasMoreThanOneSporkLocation } = useStore();

	return (
		<RemoteTable
			tableRef={ref}
			columns={[
				{
					title: 'Fee',
					field: 'name',
					render: ({ Fee: { name } }) => name,
				},
				{
					title: 'Amount',
					field: 'amount',
					render: ({ FeeId, amount, Fee: { type } }) => (
						<EditableValue
							name="amount"
							onSave={amount =>
								put(axios, ClientLocationId, FeeId, {
									amount,
								}).then(ref.current.onQueryChange)
							}
							permissions={['client-locations-fees:edit']}
							required={true}
							type={type === 'percent' ? 'percent' : 'currency'}
							value={amount}
							validation={yup.nonNegativeNumber()}
						/>
					),
				},
				{
					title: 'Spork Location',
					field: 'Fee.SporkLocation.name',
					sorting: false,
					hidden: !hasMoreThanOneSporkLocation(),
				},
				{
					title: 'Actions',
					sorting: false,
					hidden: userCannot(['client-locations-fees:delete']),
					render: ({ FeeId, Fee: { name } }) => (
						<ActionMenu
							options={[
								createDialogAction({
									Dialog: ConfirmationDialog,
									dialogProps: {
										text: `Are you sure you want to delete ${name}?`,
										onConfirmation: async () => {
											await deleteClientLocationsFees(
												axios,
												ClientLocationId,
												FeeId
											);

											ref.current.onQueryChange();
										},
									},
									icon: <DeleteForever />,
									label: `Delete ${name}`,
									type: 'error',
								}),
							]}
						/>
					),
				},
			]}
			defaultOrderBy="name"
			defaultOrderDirection="asc"
			getQuery={getClientLocationsFeesQuery}
			queryEntity="ClientLocationsFees"
			variables={{ ClientLocationId }}
			canAddNewEntity={userCan(['client-locations-fees:create'])}
			entityToAddName="Default Fee"
			addNewEntityRouteName="clientLocationsFeesCreate"
			addNewEntityRouteVariables={{ id: ClientLocationId }}
			options={{
				search: false,
			}}
		/>
	);
};

ClientLocationsFeesTable.propTypes = {
	ClientLocationId: PropTypes.string.isRequired,
};

export default ClientLocationsFeesTable;
