import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { put, sort } from '@sporkbytes/api-client/SporkLocations';
import { Alert } from '@sporkbytes/material-ui-kit-react';

import ActionMenu from 'components/utilities/ActionMenu';
import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import EditableSortOrder from 'components/forms/EditableSortOrder';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import RemoteTable from 'components/content/RemoteTable';

import { useStore } from 'models/store';

import { createEditAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const getSporkLocationsQuery = searchTerm =>
	gql`
		query SporkLocations(
			$activeOrderBy: order_by
			$nameOrderBy: order_by
			$sortOrderOrderBy: order_by
			$limit: Int!
			$offset: Int!
		) {
			SporkLocations(
				where: { name: { _ilike: "%${searchTerm}%" } }
				order_by: {
					active: $activeOrderBy
					name: $nameOrderBy
					sortOrder: $sortOrderOrderBy
				}
				limit: $limit
				offset: $offset
			) {
				id
				active
				name
                sortOrder
			}
			SporkLocations_aggregate {
				aggregate {
					count
				}
			}
		}
	`;

const SporkLocations = () => {
	const { userCan } = useAuth();
	const { setActiveSporkLocations } = useStore();
	const ref = useRef();
	const entityType = 'Spork Locations';

	return (
		<Page title={entityType}>
			<PageHeader headerText={entityType} />
			<Alert type="info">
				The sort order determines the order in which the Spork Locations
				appear in dropdown menus and the marketing site. For instance,
				we display Featured Partners on the marketing site homepage in
				the order of their respective Spork Locations' sort order.
			</Alert>
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Sort Order',
						field: 'sortOrder',
						render: ({ id, sortOrder }) => (
							<EditableSortOrder
								onSave={value =>
									sort(axios, id, {
										newValue: value,
									}).then(() => {
										ref.current.onQueryChange();
										setActiveSporkLocations();
									})
								}
								permissions={['spork-locations:edit']}
								required={true}
								value={sortOrder}
							/>
						),
					},
					{
						title: 'Name',
						field: 'name',
					},
					{
						title: 'Active',
						field: 'active',
						render: ({ active, id }) => (
							<BooleanFieldSwitch
								afterChange={() => {
									ref.current.onQueryChange();
									setActiveSporkLocations();
								}}
								entityId={id}
								entityType="sporkLocations"
								updateEntity={put}
								value={active}
							/>
						),
					},
					{
						title: 'Actions',
						field: 'actions',
						sorting: false,
						render: ({ id, name }) => (
							<ActionMenu
								options={[
									createEditAction({
										entityId: id,
										entityName: name,
										entityType,
										userCan,
									}),
								]}
							/>
						),
					},
				]}
				getQuery={getSporkLocationsQuery}
				defaultOrderBy="sortOrder"
				defaultOrderDirection="asc"
				queryEntity="SporkLocations"
				addNewEntityRouteName="sporkLocationsCreate"
				entityToAddName="Spork Location"
				canAddNewEntity={userCan(['spork-locations:create'])}
			/>
		</Page>
	);
};

export default SporkLocations;
