import React from 'react';
import { post } from '@sporkbytes/api-client/Clients';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import ClientForm from 'components/clients/ClientForm';

const Create = () => {
	return (
		<Page title="New Client">
			<PageHeader headerText="New Client" />
			<FormWrapper
				form={ClientForm}
				onSubmit={(axios, values) => post(axios, values)}
				redirectToRoute="clientsDetail"
			/>
		</Page>
	);
};

export default Create;
