import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { LinkedAsyncAutocomplete } from '@sporkbytes/material-ui-kit-react';

import apolloClient from 'services/apollo';
import { getMultiWordSearchWhereClause } from 'services/search';

const getPartnerLocationSearchQuery = (where, searchTerm) => gql`
	query PartnerLocationSearchQuery($SporkLocationId: uuid!) {
		PartnerLocations(
			limit: 20
			order_by: { Partner: { name: asc } }
			where: {
				${where}
				_or: [
					{ _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
					{ Partner: { _and: [${getMultiWordSearchWhereClause(searchTerm)}] } }
				]
				Partner: { SporkLocationsPartners: { SporkLocationId: { _eq: $SporkLocationId } } }
			}
		) {
			id
			name
			streetAddress
			Partner {
				name
			}
		}
	}
`;

const PartnerLocationSearch = ({ SporkLocationId, where, ...props }) => {
	return (
		<LinkedAsyncAutocomplete
			getSearchResults={async searchTerm => {
				const {
					data: { PartnerLocations },
				} = await apolloClient.query({
					query: getPartnerLocationSearchQuery(where, searchTerm),
					variables: {
						SporkLocationId,
					},
				});

				return PartnerLocations;
			}}
			getOptionLabel={option =>
				`${option.Partner.name}${
					option.name ? ' - ' + option.name : ''
				}`
			}
			getOptionSecondaryText={option => option.streetAddress}
			{...props}
		/>
	);
};

PartnerLocationSearch.propTypes = {
	SporkLocationId: PropTypes.string.isRequired,
	where: PropTypes.string,
};

export default PartnerLocationSearch;
