import React from 'react';

import EmailActivityLogsTable from 'components/email-activity-logs/EmailActivityLogsTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const EmailActivityLogs = () => {
	return (
		<Page title="Email Activity Logs">
			<PageHeader headerText="Email Activity Logs" />
			<EmailActivityLogsTable />
		</Page>
	);
};

export default EmailActivityLogs;
