import Create from 'pages/featured-partners/Create';
import FeaturedPartners from 'pages/featured-partners/FeaturedPartners';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'featuredPartners',
		path: '/month/:month',
		component: FeaturedPartners,
		permissions: ['featured-partners:view'],
		exact: true,
	},
	{
		name: 'featuredPartnersCreate',
		path: '/create/:month',
		component: Create,
		permissions: ['featured-partners:create'],
		exact: true,
	},
];

export default prependUrlPath('featured-partners', routes);
