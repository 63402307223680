import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';

import Logo from 'components/content/Logo';

const useStyles = makeStyles(theme => ({
	grid: {
		alignItems: 'center',
		flexDirection: 'column',
		marginBottom: theme.spacing(4),
	},
	logoContainer: {
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 2,
		minHeight: 125,
		'@media print': {
			display: 'none',
		},
	},
}));

const LogoHeader = ({ alt, logoUrl, text }) => {
	const classes = useStyles();

	return (
		<Grid container item xs={12} sm={6} className={classes.grid}>
			<Grid container item className={classes.logoContainer}>
				<Logo url={logoUrl} alt={alt} size="large" />
			</Grid>
			<div>{text}</div>
		</Grid>
	);
};

LogoHeader.propTypes = {
	alt: PropTypes.string.isRequired,
	logoUrl: PropTypes.string.isRequired,
	text: PropTypes.element.isRequired,
};
export default LogoHeader;
