import React from 'react';
import { Mail } from '@material-ui/icons';
import { hasIncludedClientContacts } from '@sporkbytes/meal-proposal-utils';
import { isApproved } from '@sporkbytes/object-utils';

import MealOptionSendShareableMenuLinkDialog from 'components/meal-options/MealOptionSendShareableMenuLinkDialog';

import { store } from 'models/store';

export default ({ id, state, userCan }) => ({
	canPerformAction:
		userCan(['meal-options:send-shareable-menu-link']) &&
		isApproved(state.context) &&
		hasIncludedClientContacts(state.context?.MealProposal),
	icon: <Mail />,
	label: 'Send Shareable Menu Link',
	onClick: () =>
		store
			.getState()
			.showDialog(MealOptionSendShareableMenuLinkDialog, { id }),
});
