import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@sporkbytes/material-ui-kit-react';

import useQueryString from 'hooks/useQueryString';

const QueryStringSyncedTabs = ({ id, replaceQueryString, ...props }) => {
	const [queryString, setQueryString] = useQueryString();

	return (
		<Tabs
			onChange={newTab => {
				const newQueryString = replaceQueryString
					? { [id]: newTab.id }
					: {
							...queryString,
							[id]: newTab.id,
					  };

				setQueryString(newQueryString);
			}}
			selectedTab={queryString[id]}
			{...props}
		/>
	);
};

QueryStringSyncedTabs.propTypes = {
	id: PropTypes.string.isRequired,
	replaceQueryString: PropTypes.bool,
};

QueryStringSyncedTabs.defaultProps = {
	replaceQueryString: false,
};

export default QueryStringSyncedTabs;
