import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import DetailSection from 'components/content/DetailSection';
import NoContentText from 'components/content/NoContentText';

const RichText = ({ body, ContentComponent, title, ...props }) => {
	const renderedHtml = (
		<ContentComponent
			dangerouslySetInnerHTML={{
				__html: body,
			}}
			{...props}
		/>
	);

	return title ? (
		<DetailSection title={title}>
			{body ? renderedHtml : <NoContentText />}
		</DetailSection>
	) : (
		renderedHtml
	);
};

RichText.propTypes = {
	body: PropTypes.string,
	ContentComponent: PropTypes.elementType,
	title: PropTypes.string,
};

RichText.defaultProps = {
	ContentComponent: props => <Typography component="div" {...props} />,
};

export default RichText;
