import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

import AuthService from 'services/auth';

const getAuthorizationHeader = () => {
	const service = new AuthService();
	const token = service.getToken();

	return token ? { Authorization: `Bearer ${token}` } : undefined;
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			)
		);

	if (networkError) console.log(`[Network error]: ${networkError.message}`);
});

const authLink = new ApolloLink((operation, forward) => {
	operation.setContext(({ headers = {} }) => {
		const authorizationHeader = getAuthorizationHeader();

		return {
			headers: {
				...headers,
				...(authorizationHeader ?? {}),
			},
		};
	});

	return forward(operation);
});

const httpLink = new HttpLink({
	uri: '/graphql',
});

const wsLink = new WebSocketLink({
	uri: process.env.REACT_APP_WEBSOCKET_URI,
	options: {
		lazy: true,
		reconnect: true,
		connectionParams: () => {
			const authorizationHeader = getAuthorizationHeader();

			return {
				headers: authorizationHeader ?? {},
			};
		},
	},
});

const dataLink = split(
	// split based on operation type
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	httpLink
);

const apolloClient = new ApolloClient({
	cache: new InMemoryCache({
		dataIdFromObject: ({ id }) => id,
	}),
	link: ApolloLink.from([errorLink, authLink, dataLink]),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'no-cache',
		},
		query: {
			fetchPolicy: 'no-cache',
		},
	},
});

export default apolloClient;
