import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';

import ClientActionMenu from 'components/clients/ClientActionMenu';
import ClientLocationsTable from 'components/client-locations/ClientLocationsTable';
import ContactsTable from 'components/contacts/ContactsTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PurchaseOrderReviewsTable from 'components/purchase-order-reviews/PurchaseOrderReviewsTable';
import SporkReviewsTable from 'components/spork-reviews/SporkReviewsTable';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';

const CLIENT_QUERY = gql`
	query ClientDetails($id: uuid!) {
		Clients_by_pk(id: $id) {
			logoUrl
			name
		}
	}
`;

const Detail = () => {
	const { id } = useParams();

	return (
		<Page
			query={CLIENT_QUERY}
			variables={{ id }}
			title={({ Clients_by_pk: { name } }) => name}
		>
			{({
				data: {
					Clients_by_pk: { name, logoUrl },
				},
			}) => (
				<>
					<PageHeader
						imgUrl={logoUrl}
						headerText={name}
						actionButton={
							<ClientActionMenu client={{ id, name }} />
						}
					/>
					<QueryStringSyncedTabs
						id="tab"
						tabs={[
							{
								id: 'locations',
								label: 'Locations',
								content: <ClientLocationsTable ClientId={id} />,
							},
							{
								id: 'contacts',
								label: 'Contacts',
								content: (
									<ContactsTable
										tableFilter={`
											MealProposalsContacts: {
												MealProposal: {
													status: { _neq: "Draft" }
													ClientLocation: {
														ClientId: { _eq: "${id}" }
													}
												}
											}`}
										options={{
											exportButton: true,
											pageSize: 25,
											pageSizeOptions: [25, 50, 100],
										}}
									/>
								),
							},
							{
								id: 'spork-reviews',
								label: 'Spork Reviews',
								content: (
									<SporkReviewsTable
										variables={{ ClientId: id }}
									/>
								),
							},
							{
								id: 'partner-reviews',
								label: 'Partner Reviews',
								content: (
									<PurchaseOrderReviewsTable
										hiddenColumns={['clientName']}
										variables={{ ClientId: id }}
									/>
								),
							},
						]}
					/>
				</>
			)}
		</Page>
	);
};

export default Detail;
