import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
	getIncludedContactIds,
	getIncludedContacts,
} from '@sporkbytes/purchase-order-utils';
import { setContacts } from '@sporkbytes/api-client/PurchaseOrders';
import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';

import ChecklistDialogForm from 'components/forms/ChecklistDialogForm';

import axios from 'services/data';

const CONTACTS_QUERY = gql`
	query PurchaseOrderReassignContactsDialog($id: uuid!) {
		PurchaseOrders_by_pk(id: $id) {
			PurchaseOrdersContacts {
				Contact {
					id
					fullName
				}
			}
			PartnerLocation {
				friendlyName
				PartnerLocationsContacts {
					Contact {
						id
						fullName
					}
				}
			}
		}
	}
`;

const getPartnerLocationAndPurchaseOrderContacts = ({
	PurchaseOrdersContacts,
	PartnerLocation: { PartnerLocationsContacts },
}) => {
	const purchaseOrderContacts = getIncludedContacts({
		PurchaseOrdersContacts,
	});

	const partnerLocationContacts = PartnerLocationsContacts.map(
		({ Contact }) => Contact
	);

	const contacts = [...purchaseOrderContacts, ...partnerLocationContacts].map(
		({ id, fullName }) => ({
			id,
			label: fullName,
		})
	);

	return sortBy(uniqBy(contacts, 'id'), 'label');
};

const PurchaseOrderReassignContactsDialog = ({ id, ...props }) => {
	const { data, loading } = useQuery(CONTACTS_QUERY, {
		variables: {
			id,
		},
	});

	return (
		<ChecklistDialogForm
			title="Assign Contacts to this Purchase Order"
			text="Contacts"
			submitButtonContent="Assign"
			minSelected={1}
			loading={loading}
			entityType="contact"
			initialValues={
				data ? getIncludedContactIds(data.PurchaseOrders_by_pk) : []
			}
			options={
				data
					? getPartnerLocationAndPurchaseOrderContacts(
							data.PurchaseOrders_by_pk
					  )
					: []
			}
			onSubmit={contactIds =>
				setContacts(axios, id, {
					data: contactIds.map(id => ({
						id,
					})),
				})
			}
			{...props}
		/>
	);
};

PurchaseOrderReassignContactsDialog.propTypes = {
	id: PropTypes.string.isRequired,
};

export default PurchaseOrderReassignContactsDialog;
