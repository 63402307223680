import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { post } from '@sporkbytes/api-client/PartnerLocations';

import FormWrapper from 'components/forms/FormWrapper';
import PartnerLocationForm from 'components/partner-locations/PartnerLocationForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

const PARTNER_QUERY = gql`
	query PartnerLocationCreate($id: uuid!) {
		Partners_by_pk(id: $id) {
			logoUrl
			name
		}
	}
`;

const PartnerLocationCreate = () => {
	const { id: PartnerId } = useParams();

	const initialValues = {
		timeToPark: 5,
		timeToEnter: 2,
		timeToExit: 2,
		automatedEmailConfirmation: true,
	};

	return (
		<Page
			title={({ Partners_by_pk: { name } }) => `New Location for ${name}`}
			query={PARTNER_QUERY}
			variables={{ id: PartnerId }}
		>
			{({
				data: {
					Partners_by_pk: { name, logoUrl },
				},
			}) => (
				<>
					<PageHeader headerText={name} imgUrl={logoUrl} />
					<PageSubheader>Add New Partner Location</PageSubheader>
					<FormWrapper
						form={PartnerLocationForm}
						initialValues={initialValues}
						onSubmit={(axios, values) =>
							post(axios, {
								PartnerId,
								...values,
							})
						}
					/>
				</>
			)}
		</Page>
	);
};

export default PartnerLocationCreate;
