import React from 'react';
import gql from 'graphql-tag';
import { Typography } from '@material-ui/core';
import { Currency, GridContainer } from '@sporkbytes/material-ui-kit-react';
import { getClientApproved } from '@sporkbytes/meal-option-utils';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import BooleanStatusIndicator from 'components/utilities/BooleanStatusIndicator';
import DateOutput from 'components/content/DateOutput';
import DefinitionList from 'components/content/DefinitionList';
import DetailSection from 'components/content/DetailSection';
import ExpansionPanel from 'components/content/ExpansionPanel';
import HeadCount from 'components/content/HeadCount';
import NoContentText from 'components/content/NoContentText';
import RichText from 'components/content/RichText';
import TimeAndPlace from 'components/content/TimeAndPlace';

const MealProposalDetails = ({ mealProposal }) => {
	const {
		automatedApprovalThankYou,
		automatedEmailConfirmation,
		automatedReviewRequest,
		budgetPerPerson,
		ClientLocation,
		createdAt,
		createdBy,
		deliveryInstructions,
		dietaryRestrictions,
		headCount,
		MealOptions,
		MealProposalsDeliveryDrivers,
		setupInstructions,
		SporkContact,
		totalBudget,
		updatedAt,
		updatedBy,
	} = mealProposal;

	const approvedMealOption = getClientApproved(MealOptions);

	return (
		<>
			<GridContainer>
				<DefinitionList
					title="Basic Info"
					items={[
						{
							label: 'Address',
							value: ClientLocation.fullAddress,
						},
						{
							label: 'Head Count',
							value: headCount,
							render: HeadCount,
						},
						{
							label: 'Approved Meal Option',
							value: approvedMealOption?.name,
							noContentText: 'none approved',
						},
						{
							label: 'Spork Contact',
							value: SporkContact?.fullName,
							noContentText: 'not assigned',
						},
						{
							label: 'Budget Per Person',
							value: budgetPerPerson,
							render: Currency,
						},
						{
							label: 'Total Budget',
							value: totalBudget,
							render: Currency,
						},
					]}
				/>

				<DetailSection title="Partners">
					{approvedMealOption?.PurchaseOrders.map(
						({
							id,
							pickupTime,
							pickupInstructions,
							PartnerLocation: { timezone, Partner },
						}) => (
							<ExpansionPanel
								key={id}
								title={
									<TimeAndPlace
										time={pickupTime}
										place={Partner.name}
										timezone={timezone}
										variant="body1"
									/>
								}
							>
								<GridContainer>
									<RichText
										title="Pickup Instructions"
										body={pickupInstructions}
									/>
								</GridContainer>
							</ExpansionPanel>
						)
					) || <NoContentText>none</NoContentText>}
				</DetailSection>
			</GridContainer>

			<DetailSection title="Delivery Drivers">
				{MealProposalsDeliveryDrivers.length > 0 ? (
					<Typography>
						{makeCommaSeparatedList(
							MealProposalsDeliveryDrivers.map(
								({ DeliveryDriver: { abbreviatedName } }) =>
									abbreviatedName
							)
						)}
					</Typography>
				) : (
					<NoContentText>No drivers assigned</NoContentText>
				)}
			</DetailSection>

			<RichText
				title="Delivery Instructions"
				body={deliveryInstructions}
			/>
			<RichText title="Setup Instructions" body={setupInstructions} />
			<RichText title="Dietary Restrictions" body={dietaryRestrictions} />
			<GridContainer>
				<DefinitionList
					title="Automated Emails"
					items={[
						{
							label: 'Automated Approval Thank You',
							value: automatedApprovalThankYou,
							render: BooleanStatusIndicator,
						},
						{
							label: 'Automated Email Confirmation',
							value: automatedEmailConfirmation,
							render: BooleanStatusIndicator,
						},
						{
							label: 'Automated Review Request',
							value: automatedReviewRequest,
							render: BooleanStatusIndicator,
						},
					]}
				/>
				<DefinitionList
					title="Additional Data"
					items={[
						{
							label: 'Created At',
							value: createdAt,
							render: ({ value }) => <DateOutput date={value} />,
						},
						{
							label: 'Created By',
							value: createdBy,
						},
						{
							label: 'Last Updated At',
							value: updatedAt,
							render: ({ value }) => <DateOutput date={value} />,
						},
						{
							label: 'Last Updated By',
							value: updatedBy,
						},
					]}
				/>
			</GridContainer>
		</>
	);
};

MealProposalDetails.fragments = {
	details: gql`
		fragment MealProposalDetails on MealProposals {
			automatedApprovalThankYou
			automatedEmailConfirmation
			automatedReviewRequest
			budgetPerPerson
			createdAt
			createdBy
			deliveryInstructions
			dietaryRestrictions
			headCount
			setupInstructions
			totalBudget
			updatedAt
			updatedBy
			ClientLocation {
				fullAddress
			}
			MealOptions(order_by: { approved: desc, createdAt: asc }) {
				name
				approved
				PurchaseOrders(order_by: { pickupTime: asc }) {
					id
					pickupInstructions
					pickupTime
					PartnerLocation {
						timezone
						Partner {
							name
						}
					}
				}
			}
			MealProposalsDeliveryDrivers(
				order_by: { DeliveryDriver: { firstName: asc } }
			) {
				DeliveryDriver {
					abbreviatedName
				}
			}
			SporkContact {
				fullName
			}
		}
	`,
};

export default MealProposalDetails;
