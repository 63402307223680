import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Currency, Percent } from '@sporkbytes/material-ui-kit-react';
import { canEdit } from '@sporkbytes/meal-proposal-utils';

import ActionMenu from 'components/utilities/ActionMenu';
import RemoteTable from 'components/content/RemoteTable';

import { useAuth } from 'services/auth';
import { createEditAction } from 'services/actionMenu';

const getMealProposalFees = searchTerm => gql`
	query MealProposalsFees(
		$MealProposalId: uuid!
		$amountOrderBy: order_by
		$descriptionOrderBy: order_by
		$discountOrderBy: order_by
		$nameOrderBy: order_by
		$typeOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		MealProposalFees(
			where: {
				MealProposalId: { _eq: $MealProposalId }
				name: { _ilike: "%${searchTerm}%" }
			}
			order_by: {
				amount: $amountOrderBy
				description: $descriptionOrderBy
				discount: $discountOrderBy
				name: $nameOrderBy
				type: $typeOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			id
			amount
			description
			discount
			name
			type
		}
		MealProposalFees_aggregate(
			where: {
				MealProposalId: { _eq: $MealProposalId }
				name: { _ilike: "%${searchTerm}%" }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const MealProposalFeesTable = ({ mealProposal, MealProposalId }) => {
	const { role, userCan } = useAuth();

	return (
		<RemoteTable
			columns={[
				{
					title: 'Name',
					field: 'name',
				},
				{
					title: 'Amount',
					field: 'amount',
					render: ({ amount, type }) =>
						type === 'percent' ? (
							<Percent value={amount} />
						) : (
							<Currency value={amount} />
						),
				},
				{
					title: 'Type',
					field: 'type',
				},
				{
					title: 'Description',
					field: 'description',
				},
				{
					title: 'Discount',
					field: 'discount',
					render: ({ discount }) => <Percent value={discount} />,
				},
				{
					title: 'Actions',
					field: 'actions',
					sorting: false,
					render: ({ id }) => (
						<ActionMenu
							options={[
								createEditAction({
									canPerformAction:
										userCan(['meal-proposal-fees:edit']) &&
										canEdit(mealProposal, role),
									entityId: id,
									entityName: 'Fee',
									entityType: 'MealProposalFees',
									userCan,
								}),
							]}
						/>
					),
				},
			]}
			entityToAddName="Fee"
			addNewEntityRouteName="mealProposalFeesCreate"
			addNewEntityRouteVariables={{ id: MealProposalId }}
			canAddNewEntity={
				userCan(['meal-proposals:edit', 'meal-proposal-fees:create']) &&
				canEdit(mealProposal, role)
			}
			defaultOrderBy="name"
			defaultOrderDirection="desc"
			getQuery={getMealProposalFees}
			queryEntity="MealProposalFees"
			variables={{ MealProposalId }}
		/>
	);
};

MealProposalFeesTable.propTypes = {
	MealProposalId: PropTypes.string.isRequired,
};

MealProposalFeesTable.fragments = {
	details: gql`
		fragment MealProposalFeesTableDetails on MealProposals {
			deliveryDate
			status
		}
	`,
};

export default MealProposalFeesTable;
