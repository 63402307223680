import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { sendClientCancellationNotice } from '@sporkbytes/api-client/MealProposals';
import { Dialog } from '@sporkbytes/material-ui-kit-react';
import { getIncludedClientContacts } from '@sporkbytes/meal-proposal-utils';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import SendEmailDialogForm from 'components/email-templates/SendEmailDialogForm';

import { useStore } from 'models/store';

import { formatZonedTime } from 'services/dates';
import axios from 'services/data';

const MEAL_PROPOSAL_QUERY = gql`
	query MealProposalSendClientCancellationNoticeDialog($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			deliveryDate
			orderNumberText
			ClientLocation {
				timezone
			}
			MealProposalsContacts {
				Contact {
					firstName
				}
			}
			SporkContact {
				firstName
			}
		}
	}
`;

const MealProposalSendClientCancellationNoticeDialog = ({ id, ...props }) => {
	const { data, loading } = useQuery(MEAL_PROPOSAL_QUERY, {
		variables: {
			id,
		},
	});
	const { showSuccessNotification } = useStore();

	if (loading) {
		return <Dialog loading={loading} {...props} />;
	}

	return (
		<SendEmailDialogForm
			emailTemplateId="cancellationNotice"
			substitutions={{
				'-date-': formatZonedTime(
					data.MealProposals_by_pk.deliveryDate,
					'date',
					data.MealProposals_by_pk.ClientLocation.timezone
				),
				'-time-': formatZonedTime(
					data.MealProposals_by_pk.deliveryDate,
					'time',
					data.MealProposals_by_pk.ClientLocation.timezone
				),
				'-orderNumber-': data.MealProposals_by_pk.orderNumberText,
				'-recipientFirstNames-': makeCommaSeparatedList(
					getIncludedClientContacts(data.MealProposals_by_pk).map(
						({ firstName }) => firstName
					)
				),
				'-sporkContactFirstName-':
					data.MealProposals_by_pk.SporkContact.firstName,
			}}
			onSubmit={values =>
				sendClientCancellationNotice(axios, id, values).then(
					showSuccessNotification
				)
			}
			{...props}
		/>
	);
};

MealProposalSendClientCancellationNoticeDialog.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MealProposalSendClientCancellationNoticeDialog;
