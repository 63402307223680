import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { TableCell, TableRow } from '@material-ui/core';
import { foodSubtotal } from '@sporkbytes/purchase-order-utils';

import TableCellNumeric from 'components/content/TableCellNumeric';

const PurchaseOrderFoodSubtotalLineItem = ({ purchaseOrder, costType }) => (
	<TableRow>
		<TableCell colSpan="4">Subtotal</TableCell>
		<TableCellNumeric
			format="currency"
			value={foodSubtotal(purchaseOrder, costType)}
		/>
	</TableRow>
);

PurchaseOrderFoodSubtotalLineItem.fragments = {
	details: gql`
		fragment PurchaseOrderFoodSubtotalLineItemDetails on PurchaseOrders {
			PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
				PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
					costToClient
					discount
					paidToPartner
					quantity
				}
			}
		}
	`,
};

PurchaseOrderFoodSubtotalLineItem.propTypes = {
	costType: PropTypes.string.isRequired,
};

export default PurchaseOrderFoodSubtotalLineItem;
