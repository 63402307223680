import React from 'react';
import gql from 'graphql-tag';
import { Currency } from '@sporkbytes/material-ui-kit-react';
import {
	getSummationCalculation,
	headCount as getHeadCount,
} from '@sporkbytes/meal-proposal-utils';

import MetricCard from 'components/content/MetricCard';

const MetricCardRevenue = ({ mealProposals }) => {
	const revenue = getSummationCalculation('totalClientPays', mealProposals);
	const headCount = getHeadCount(mealProposals);
	const mealCount = mealProposals.length;

	return (
		<MetricCard
			label="Revenue"
			value={<Currency value={revenue} />}
			footer={
				<>
					<div>
						<Currency value={revenue / mealCount} /> / meal
					</div>
					<div>
						<Currency value={revenue / headCount} /> / person
					</div>
				</>
			}
			showFooter={mealCount > 0}
		/>
	);
};

MetricCardRevenue.fragments = {
	details: gql`
		fragment MetricCardRevenueDetails on MealProposals {
			headCount
			MealProposalFees {
				amount
				discount
				type
			}
			MealOptions(where: { approved: { _eq: true } }) {
				approved
				gratuity
				PurchaseOrders {
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							costToClient
							discount
							quantity
						}
					}
				}
			}
		}
	`,
};

export default MetricCardRevenue;
