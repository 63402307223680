import React from 'react';
import PropTypes from 'prop-types';
import { DoneAll } from '@material-ui/icons';
import { PrimaryButton } from '@sporkbytes/material-ui-kit-react';

import axios from 'services/data';

const ConfirmButton = ({ afterConfirm, confirm, id, ...props }) => {
	return (
		<PrimaryButton
			onClick={() => confirm(axios, id).then(afterConfirm)}
			size="large"
			startIcon={<DoneAll />}
			{...props}
		>
			Confirm
		</PrimaryButton>
	);
};

ConfirmButton.propTypes = {
	afterConfirm: PropTypes.func.isRequired,
	confirm: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
};

export default ConfirmButton;
