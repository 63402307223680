import React from 'react';
import PropTypes from 'prop-types';
import { Form, GridContainer } from '@sporkbytes/material-ui-kit-react';

import SporkLocationLinkedSingleSelect from 'components/spork-locations/SporkLocationLinkedSingleSelect';
import UserFormElements, {
	validationObject,
} from 'components/users/UserFormElements';

import { useStore } from 'models/store';

import * as yup from 'models/validation';

const DeliveryDriverForm = ({ initialValues, ...props }) => {
	const validationSchema = yup.object().shape({
		...validationObject,
		phoneNumber: validationObject.phoneNumber
			.required('Please enter a phone number.')
			.unique({
				tableName: 'DeliveryDrivers',
				fieldName: 'phoneNumber',
				message:
					'This phone number is already in use. Please enter a different phone number.',
				initialValue: initialValues.phoneNumber,
			}),
		SporkLocationId: yup.sporkLocationId(),
	});
	const { hasMoreThanOneSporkLocation } = useStore();

	return (
		<Form
			{...props}
			initialValues={initialValues}
			validationSchema={validationSchema}
		>
			{() => (
				<UserFormElements>
					{hasMoreThanOneSporkLocation() ? (
						<GridContainer columns={2}>
							<SporkLocationLinkedSingleSelect />
						</GridContainer>
					) : null}
				</UserFormElements>
			)}
		</Form>
	);
};

DeliveryDriverForm.propTypes = {
	initialValues: PropTypes.object,
};

DeliveryDriverForm.defaultProps = {
	initialValues: {},
};

export default DeliveryDriverForm;
