import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { LinkedAsyncAutocomplete } from '@sporkbytes/material-ui-kit-react';

import apolloClient from 'services/apollo';
import { getMultiWordSearchWhereClause } from 'services/search';

const getClientLocationSearchQuery = (where, searchTerm) => gql`
	query ClientLocationSearchQuery {
		MasterSearchView(
			limit: 20
			order_by: { title: asc }
			where: {
				${where}
				_and: [
					${getMultiWordSearchWhereClause(searchTerm)}
					{ type: { _eq: "ClientLocations" } }
				]
			}
		) {
			id
			title
			subtitle
		}
	}
`;

const ClientLocationSearch = ({ name, where, ...props }) => {
	return (
		<LinkedAsyncAutocomplete
			getSearchResults={async searchTerm => {
				const {
					data: { MasterSearchView },
				} = await apolloClient.query({
					query: getClientLocationSearchQuery(where, searchTerm),
				});

				return MasterSearchView;
			}}
			getOptionLabel={option => option.title}
			getOptionSecondaryText={option => option.subtitle}
			name={name}
			{...props}
		/>
	);
};

ClientLocationSearch.propTypes = {
	name: PropTypes.string,
	where: PropTypes.string,
};

export default ClientLocationSearch;
