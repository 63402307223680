import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { sort, put } from '@sporkbytes/api-client/CancellationReasons';

import EditableSortOrder from 'components/forms/EditableSortOrder';
import EditableValue from 'components/forms/EditableValue';
import RemoteTable from 'components/content/RemoteTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const getCancellationReasonsQuery = searchTerm =>
	gql`
		query CancellationReasons(
			$sortOrderOrderBy: order_by
			$nameOrderBy: order_by
			$limit: Int!
			$offset: Int!
		) {
			CancellationReasons(
				where: { name: { _ilike: "%${searchTerm}%" } }
				order_by: { sortOrder: $sortOrderOrderBy, name: $nameOrderBy }
				limit: $limit
				offset: $offset
			) {
				id
				name	
				sortOrder
			}
			CancellationReasons_aggregate {
				aggregate {
					count
				}
			}
		}
	`;

const Detail = () => {
	const { userCan } = useAuth();
	const ref = useRef();

	return (
		<Page title="Cancellation Reasons">
			<PageHeader headerText="Cancellation Reasons" />
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Sort Order',
						field: 'sortOrder',
						render: ({ id, sortOrder }) => (
							<EditableSortOrder
								onSave={value =>
									sort(axios, id, {
										newValue: value,
									}).then(ref.current.onQueryChange)
								}
								permissions={['cancellation-reasons:edit']}
								required={true}
								value={sortOrder}
							/>
						),
					},
					{
						title: 'Name',
						field: 'name',
						render: ({ id, name }) => (
							<EditableValue
								name="name"
								onSave={name =>
									put(axios, id, {
										name,
									}).then(ref.current.onQueryChange)
								}
								permissions={['cancellation-reasons:edit']}
								required={true}
								type="name"
								value={name}
							/>
						),
					},
				]}
				getQuery={getCancellationReasonsQuery}
				defaultOrderBy="sortOrder"
				defaultOrderDirection="asc"
				queryEntity="CancellationReasons"
				addNewEntityRouteName="cancellationReasonsCreate"
				canAddNewEntity={userCan(['cancellation-reasons:create'])}
				entityToAddName="Cancellation Reason"
			/>
		</Page>
	);
};

export default Detail;
