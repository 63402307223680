import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import startCase from 'lodash.startcase';

import { AccordionGroupContext } from 'components/content/AccordionGroup';

const AccordionItem = ({ children, id, title = startCase(id) }) => {
	const { expandedItem, setExpandedItem } = useContext(AccordionGroupContext);

	return (
		<ExpansionPanel
			expanded={expandedItem === id}
			onChange={(event, isExpanded) => {
				setExpandedItem(isExpanded ? id : undefined);
			}}
		>
			<ExpansionPanelSummary expandIcon={<ExpandMore />}>
				<Typography>{title}</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>{children}</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};

AccordionItem.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string,
};

export default AccordionItem;
