import React from 'react';
import PropTypes from 'prop-types';

import ActionMenu from 'components/utilities/ActionMenu';

import { createEditAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';

const PartnerMenuItemOptionGroupActionMenu = ({
	partnerMenuItemOptionGroup: { id, name },
}) => {
	const { userCan } = useAuth();

	return (
		<ActionMenu
			options={[
				createEditAction({
					entityId: id,
					entityName: name,
					entityType: 'PartnerMenuItemOptionGroups',
					userCan,
				}),
			]}
		/>
	);
};

PartnerMenuItemOptionGroupActionMenu.propTypes = {
	partnerMenuItemOptionGroup: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}),
};

export default PartnerMenuItemOptionGroupActionMenu;
