import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { AccountBox, ContactMail, Warning } from '@material-ui/icons';
import {
	sendPersonalProfile,
	changePersonalProfileUrl,
} from '@sporkbytes/api-client/Contacts';
import { ConfirmationDialog } from '@sporkbytes/material-ui-kit-react';

import ActionMenu from 'components/utilities/ActionMenu';
import SendEmailDialogForm from 'components/email-templates/SendEmailDialogForm';

import { useStore } from 'models/store';

import apolloClient from 'services/apollo';

import {
	createCallPhoneNumberAction,
	createDialogAction,
	createEditAction,
	createSendEmailAction,
} from 'services/actionMenu';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const ContactActionMenu = ({
	contact: { id, email, firstName, phoneNumber },
	additionalActions,
}) => {
	const [loading, setLoading] = useState(false);
	const { userCan, user } = useAuth();
	const { showDialog, showSuccessNotification } = useStore();
	const history = useHistory();

	return (
		<ActionMenu
			loading={loading}
			options={[
				createEditAction({
					entityId: id,
					entityName: firstName,
					entityType: 'Contacts',
					userCan,
				}),
				createCallPhoneNumberAction({
					canPerformAction: !!phoneNumber,
					entityName: firstName,
					phoneNumber,
				}),
				createSendEmailAction({
					entityName: firstName,
					emailAddress: email,
					divider: additionalActions.length > 0,
				}),
				{
					label: `View Personal Profile for ${firstName}`,
					icon: <AccountBox />,
					onClick: async () => {
						const { data } = await apolloClient.query({
							query: gql`
								query contactPersonalProfileId($id: uuid!) {
									Contacts_by_pk(id: $id) {
										personalProfileId
									}
								}
							`,
							variables: { id },
						});

						history.openNamedRoute('contactsPublicProfile', {
							personalProfileId:
								data.Contacts_by_pk.personalProfileId,
						});
					},
				},
				createDialogAction({
					canPerformAction: userCan([
						'contacts:send-personal-profile',
					]),
					label: `Send Personal Profile to ${firstName}`,
					icon: <ContactMail />,
					Dialog: props => (
						<SendEmailDialogForm
							emailTemplateId="personalProfile"
							substitutions={{
								'-recipientFirstNames-': firstName,
								'-userFirstName-': user.name,
							}}
							onSubmit={values =>
								sendPersonalProfile(axios, id, values).then(
									showSuccessNotification
								)
							}
							{...props}
						/>
					),
				}),
				createDialogAction({
					canPerformAction: userCan([
						'contacts:change-personal-profile-url',
					]),
					label: `Change Personal Profile URL for ${firstName}`,
					icon: <Warning />,
					type: 'error',
					Dialog: props => (
						<ConfirmationDialog
							title={`Are you sure you want to change the personal profile URL for ${firstName}?`}
							text="This cannot be undone. If you don't send them a notification, the URL won't be changed."
							onConfirmation={() =>
								// setTimeout is needed to allow the ConfirmationDialog to close before the SendEmailDialogForm opens
								setTimeout(() =>
									showDialog(SendEmailDialogForm, {
										emailTemplateId:
											'personalProfileChanged',
										substitutions: {
											'-recipientFirstNames-': firstName,
											'-userFirstName-': user.name,
										},
										onSubmit: values =>
											changePersonalProfileUrl(
												axios,
												id,
												values
											).then(() =>
												showSuccessNotification(
													'Email successfully sent.'
												)
											),
									})
								)
							}
							{...props}
						/>
					),
				}),
				...additionalActions({ setLoading }),
			]}
		/>
	);
};

ContactActionMenu.propTypes = {
	additionalActions: PropTypes.func,
	contact: PropTypes.shape({
		id: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		firstName: PropTypes.string.isRequired,
		phoneNumber: PropTypes.string,
	}),
};

ContactActionMenu.defaultProps = {
	additionalActions: () => [],
};

export default ContactActionMenu;
