import Create from 'pages/email-templates/Create';
import Edit from 'pages/email-templates/Edit';
import EmailTemplates from 'pages/email-templates/EmailTemplates';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'emailTemplates',
		path: '',
		component: EmailTemplates,
		permissions: ['email-templates:view'],
		exact: true,
	},
	{
		name: 'emailTemplatesEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['email-templates:edit'],
		exact: true,
	},
	{
		name: 'emailTemplatesCreate',
		path: '/create',
		component: Create,
		permissions: ['email-templates:create'],
		exact: true,
	},
];

export default prependUrlPath('email-templates', routes);
