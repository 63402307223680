import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { deleteInvoices } from '@sporkbytes/api-client/Invoices';

import ActionMenu from 'components/utilities/ActionMenu';
import CommaSeparatedList from 'components/utilities/CommaSeparatedList';
import DetailPageLink from 'components/routing/DetailPageLink';
import RemoteTable from 'components/content/RemoteTable';

import { createDeleteAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';

const getInvoicesQuery = searchTerm => gql`
	query InvoicesTable(
		$createdAtOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		Invoices(
			order_by: { createdAt: $createdAtOrderBy }
			limit: $limit
			offset: $offset
			where: {
				_or: [
					{
						Client: {
							name: { _ilike: "%${searchTerm}%" }
						}
					}
					{
						ClientLocation: {
							friendlyName: { _ilike: "%${searchTerm}%" }
						}
					}
					{
						MealProposals: {
							orderNumberText: { _ilike: "%${searchTerm}%" }
						}
					}
				]
			}
		) {
			id
			MealProposals {
				id
				orderNumberText
			}
			ClientLocation {
				id
				friendlyName
			}
			Client {
				id
				name
			}
		}
		Invoices_aggregate {
			aggregate {
				count
			}
		}
	}
`;

const InvoicesTable = () => {
	const { userCannot } = useAuth();
	const ref = useRef();

	return (
		<RemoteTable
			tableRef={ref}
			columns={[
				{
					title: 'Order Number(s)',
					field: 'orderNumber',
					sorting: false,
					render: ({ MealProposals }) => (
						<CommaSeparatedList
							items={MealProposals.map(
								({ id, orderNumberText }) => (
									<DetailPageLink
										entityType="mealProposals"
										id={id}
										text={orderNumberText}
									/>
								)
							)}
						/>
					),
				},
				{
					title: 'Client',
					sorting: false,
					render: ({ Client: { id, name } }) => (
						<DetailPageLink
							entityType="clients"
							id={id}
							text={name}
						/>
					),
				},
				{
					title: 'Client Location',
					sorting: false,
					render: ({ ClientLocation: { id, friendlyName } }) => (
						<DetailPageLink
							entityType="clientLocations"
							id={id}
							text={friendlyName}
						/>
					),
				},
				{
					title: 'Actions',
					sorting: false,
					hidden: userCannot(['invoices:delete']),
					render: ({ id, Client: { name } }) => (
						<ActionMenu
							options={[
								createDeleteAction({
									afterDelete: ref.current.onQueryChange,
									deleteFunction: deleteInvoices,
									entityId: id,
									entityName: `Invoice for ${name}`,
								}),
							]}
						/>
					),
				},
			]}
			defaultOrderBy="createdAt"
			defaultOrderDirection="desc"
			getQuery={getInvoicesQuery}
			queryEntity="Invoices"
		/>
	);
};

export default InvoicesTable;
