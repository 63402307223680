import Contacts from 'pages/contacts/Contacts';
import Create from 'pages/contacts/Create';
import Detail from 'pages/contacts/Detail';
import Edit from 'pages/contacts/Edit';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'contacts',
		path: '',
		component: Contacts,
		permissions: ['contacts:view'],
		exact: true,
	},
	{
		name: 'contactsCreate',
		path: '/create',
		component: Create,
		permissions: ['contacts:create'],
		exact: true,
	},
	{
		name: 'contactsDetail',
		path: '/:id/detail',
		component: Detail,
		permissions: ['contacts:view'],
		exact: true,
	},
	{
		name: 'contactsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['contacts:edit'],
		exact: true,
	},
];

export default prependUrlPath('contacts', routes);
