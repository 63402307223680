import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useFormikContext } from 'formik';
import {
	GridContainer,
	LinkedCurrencyInput,
	LinkedInputFormatter,
	LinkedPercentInput,
	LinkedRichTextEditor,
} from '@sporkbytes/material-ui-kit-react';
import { itemTotalAfterDiscount } from '@sporkbytes/purchase-order-menu-item-utils';

import FeedsNumber from 'components/content/FeedsNumber';
import NoteContainer from 'components/content/NoteContainer';

import * as yup from 'models/validation';

const validationSchema = yup.object().shape(
	{
		clientComment: yup.string(),
		costToClient: yup
			.positiveNumber()
			.when(['paidToPartner'], function (paidToPartner) {
				if (!!paidToPartner) {
					return this.required('Please enter the cost to client.');
				}
				return this;
			}),
		discount: yup.positiveNumber(),
		paidToPartner: yup
			.positiveNumber()
			.when(['costToClient'], function (costToClient) {
				if (!!costToClient) {
					return this.required(
						'Please enter the amount paid to the partner.'
					);
				}
				return this;
			})
			.max(
				yup.ref('costToClient'),
				'Please enter a value no greater than the cost to client.'
			),
		partnerComment: yup.string(),
		quantity: yup
			.positiveNumber()
			.when(['costToClient'], function (costToClient) {
				if (!!costToClient) {
					return this.required('Please enter a quantity.');
				}
				return this;
			}),
	},
	[['costToClient', 'paidToPartner']] // needed to get around cyclical dependencies https://github.com/jquense/yup/blob/master@%7B2017-01-20%7D/test/object.js#L609
);

const PurchaseOrderMenuItemFormElements = ({
	partnerMenuItem: { feedsMaximum, feedsMinimum, howToOrder },
	setItemTotal,
}) => {
	const { values } = useFormikContext();

	useEffect(() => {
		setItemTotal(itemTotalAfterDiscount(values, 'costToClient'));
	}, [values, setItemTotal]);

	return (
		<>
			{howToOrder || feedsMinimum || feedsMaximum ? (
				<NoteContainer content={howToOrder}>
					{feedsMinimum || feedsMaximum ? (
						<FeedsNumber
							feedsMinimum={feedsMinimum}
							feedsMaximum={feedsMaximum}
						/>
					) : null}
				</NoteContainer>
			) : null}
			<GridContainer>
				<LinkedInputFormatter
					autoFocus
					name="quantity"
					decimalScale={2}
					thousandSeparator={true}
				/>
				<LinkedPercentInput name="discount" />
			</GridContainer>
			<GridContainer>
				<LinkedCurrencyInput name="costToClient" />
				<LinkedCurrencyInput
					name="paidToPartner"
					helperText="This is the amount before commission is taken out."
				/>
			</GridContainer>
			<GridContainer>
				<LinkedRichTextEditor name="clientComment" />
			</GridContainer>
			<GridContainer>
				<LinkedRichTextEditor name="partnerComment" />
			</GridContainer>
		</>
	);
};

PurchaseOrderMenuItemFormElements.fragments = {
	partnerMenuItemDetails: gql`
		fragment PurchaseOrderMenuItemFormElementsPartnerMenuItemDetails on PartnerMenuItems {
			feedsMaximum
			feedsMinimum
			howToOrder
		}
	`,
	purchaseOrderMenuItemDetails: gql`
		fragment PurchaseOrderMenuItemFormElementsPurchaseOrderMenuItemDetails on PurchaseOrderMenuItems {
			clientComment
			costToClient
			discount
			paidToPartner
			partnerComment
			quantity
		}
	`,
};

PurchaseOrderMenuItemFormElements.propTypes = {
	setItemTotal: PropTypes.func.isRequired,
};

export default PurchaseOrderMenuItemFormElements;

export { validationSchema };
