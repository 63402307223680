import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { put } from '@sporkbytes/api-client/SporkLocations';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import SporkLocationForm from 'components/spork-locations/SporkLocationForm';

const SPORK_LOCATION_QUERY = gql`
	query SporkLocationEdit($id: uuid!) {
		SporkLocations_by_pk(id: $id) {
			name
			slug
			streetAddress
			streetAddress2
			city
			state
			zipCode
			phoneNumber
			emailAddress
			sporkCommissionPercent
			SporkLocationsServicedStates(
				order_by: { ServicedState: { name: asc } }
			) {
				ServicedStateId
			}
		}
	}
`;

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ SporkLocations_by_pk: { name } }) => `Edit ${name}`}
			query={SPORK_LOCATION_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					SporkLocations_by_pk: {
						name,
						SporkLocationsServicedStates,
						...data
					},
				},
			}) => (
				<>
					<PageHeader headerText={`Edit ${name}`} />
					<FormWrapper
						form={SporkLocationForm}
						initialValues={{
							name,
							...data,
							ServicedStates: SporkLocationsServicedStates.map(
								({ ServicedStateId }) => ServicedStateId
							),
						}}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
