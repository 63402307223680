import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@sporkbytes/material-ui-kit-react';

import { useStore } from 'models/store';

const GlobalNotification = () => {
	const {
		notificationOpen,
		onNotificationClose,
		notificationType,
		notificationMessage,
	} = useStore();

	return (
		<Snackbar
			open={notificationOpen}
			onClose={onNotificationClose}
			autoHideDuration={2000}
		>
			<Alert type={notificationType}>{notificationMessage}</Alert>
		</Snackbar>
	);
};

export default GlobalNotification;
