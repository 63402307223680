import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { getItems } from '@sporkbytes/api-client/Accounting';
import { put } from '@sporkbytes/api-client/AccountingSettings';

import AccountingSettingSelect from 'components/accounting-settings/AccountingSettingSelect';
import LoadingStateContainer from 'components/utilities/LoadingStateContainer';
import RemoteTable from 'components/content/RemoteTable';

import useRequest from 'hooks/useRequest';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const getAccountSettingsQuery = searchTerm => gql`
	query AccountingSettingsTable(
		$labelOrderBy: order_by
		$valueOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		AccountingSettings(
			where: { label: { _ilike: "%${searchTerm}%" } }
			order_by: { label: $labelOrderBy, value: $valueOrderBy }
			limit: $limit
			offset: $offset
		) {
			id
			label
			value
		}
		AccountingSettings_aggregate {
			aggregate {
				count
			}
		}
	}
`;

const AccountingSettingsTable = () => {
	const { userCan } = useAuth();
	const { data: AccountingItems, loading } = useRequest(() =>
		getItems(axios)
	);
	const { showSuccessNotification } = useStore();
	const ref = useRef();

	return (
		<LoadingStateContainer loading={loading}>
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Label',
						field: 'label',
					},
					{
						title: 'Value',
						field: 'value',
						render: ({ id, value }) => {
							if (userCan(['accounting-settings:edit'])) {
								return (
									<AccountingSettingSelect
										isLinked={false}
										onChange={async value => {
											try {
												await put(axios, id, { value });

												showSuccessNotification(
													'Setting saved.'
												);
											} finally {
												ref.current.onQueryChange();
											}
										}}
										options={AccountingItems}
										value={value}
									/>
								);
							}

							return value;
						},
					},
				]}
				getQuery={getAccountSettingsQuery}
				defaultOrderBy="label"
				defaultOrderDirection="asc"
				queryEntity="AccountingSettings"
				addNewEntityRouteName="accountingSettingsCreate"
				entityToAddName="Accounting Setting"
				canAddNewEntity={userCan(['accounting-settings:create'])}
			/>
		</LoadingStateContainer>
	);
};

export default AccountingSettingsTable;
