import React from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';

const CenteredCircularProgress = ({ containerProps, ...props }) => (
	<Grid container justify="center" alignItems="center" {...containerProps}>
		<CircularProgress {...props} />
	</Grid>
);

CenteredCircularProgress.propTypes = {
	containerProps: PropTypes.object,
};

export default CenteredCircularProgress;
