import React from 'react';
import gql from 'graphql-tag';
import { MenuBook } from '@material-ui/icons';
import { isApproved } from '@sporkbytes/object-utils';

import apolloClient from 'services/apollo';

export default ({ history, id, state }) => ({
	canPerformAction: isApproved(state.context),
	label: 'View Menu',
	icon: <MenuBook />,
	onClick: async () => {
		const { data } = await apolloClient.query({
			query: gql`
				query mealOptionMealMenuId($id: uuid!) {
					MealOptions_by_pk(id: $id) {
						mealMenuId
					}
				}
			`,
			variables: { id },
		});

		history.openNamedRoute('publicMealMenu', {
			mealMenuId: data.MealOptions_by_pk.mealMenuId,
		});
	},
});
