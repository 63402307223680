import React from 'react';
import gql from 'graphql-tag';
import { sendPartnerCancellationNotice } from '@sporkbytes/api-client/PurchaseOrders';
import { getIncludedContacts } from '@sporkbytes/purchase-order-utils';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import SendEmailDialogForm from 'components/email-templates/SendEmailDialogForm';

import { store } from 'models/store';

import { formatZonedTime } from 'services/dates';
import apolloClient from 'services/apollo';
import axios from 'services/data';

const MEAL_PROPOSAL_QUERY = gql`
	query SendPartnerCancellationNotice($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			orderNumberText
			SporkContact {
				firstName
			}
			MealOptions(where: { approved: { _eq: true } }) {
				PurchaseOrders {
					id
					pickupTime
					PartnerLocation {
						timezone
					}
					PurchaseOrdersContacts {
						Contact {
							firstName
						}
					}
				}
			}
		}
	}
`;

export default async ({ id }) => {
	const { data } = await apolloClient.query({
		query: MEAL_PROPOSAL_QUERY,
		variables: {
			id,
		},
	});
	const { showDialogs, showSuccessNotification } = store.getState();

	return showDialogs(
		data.MealProposals_by_pk.MealOptions[0].PurchaseOrders.map(
			purchaseOrder =>
				({ showNextDialog, ...props }) =>
					(
						<SendEmailDialogForm
							emailTemplateId="cancellationNotice"
							substitutions={{
								'-orderNumber-':
									data.MealProposals_by_pk.orderNumberText,
								'-date-': formatZonedTime(
									purchaseOrder.pickupTime,
									'date',
									purchaseOrder.PartnerLocation.timezone
								),
								'-time-': formatZonedTime(
									purchaseOrder.pickupTime,
									'time',
									purchaseOrder.PartnerLocation.timezone
								),
								'-recipientFirstNames-': makeCommaSeparatedList(
									getIncludedContacts(purchaseOrder).map(
										({ firstName }) => firstName
									)
								),
								'-sporkContactFirstName-':
									data.MealProposals_by_pk.SporkContact
										.firstName,
							}}
							onSubmit={async values => {
								try {
									await sendPartnerCancellationNotice(
										axios,
										purchaseOrder.id,
										values
									).then(showSuccessNotification);
								} finally {
									showNextDialog();
								}
							}}
							{...props}
						/>
					)
		)
	);
};
