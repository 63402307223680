import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import RichText from 'components/content/RichText';

import { templateOptions } from 'services/printableLabels';
import { getThemedLogo } from 'services/sporkMetadata';

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: theme.palette.common.white,
		borderColor: theme.palette.common.black,
		borderStyle: 'solid',
		borderWidth: ({ includeBorder }) => (includeBorder ? 1 : 0),
		display: 'inline-block',
		height: ({ template }) => {
			switch (template) {
				case templateOptions.AVERY_25395:
					return '2.25in';
				case templateOptions.TWO_PER_PAGE:
					return '5.5in';
				default:
					return '2.75in';
			}
		},
		margin: ({ template }) => {
			switch (template) {
				case templateOptions.AVERY_25395:
					return '0.092375in 0.1875in'; // 3/32" and 3/16" to consistently cover half of the space between labels
				default:
					return 0;
			}
		},
		overflowY: 'auto',
		padding: '0.1in',
		textAlign: 'center',
		verticalAlign: 'top',
		width: ({ template }) => {
			switch (template) {
				case templateOptions.AVERY_25395:
					return '3.25in';
				case templateOptions.TWO_PER_PAGE:
					return '8.5in';
				default:
					return '4.25in';
			}
		},
		'@media print': {
			overflowY: 'hidden',
		},
	},
	description: {
		fontSize: ({ template }) => {
			switch (template) {
				case templateOptions.AVERY_25395:
					return theme.typography.caption.fontSize;
				case templateOptions.TWO_PER_PAGE:
					return theme.typography.h5.fontSize;
				default:
					return theme.typography.body1.fontSize;
			}
		},
		fontStyle: 'italic',
	},
	dietaryTags: {
		display: 'block',
		fontSize: ({ template }) => {
			switch (template) {
				case templateOptions.AVERY_25395:
					return theme.typography.caption.fontSize;
				case templateOptions.TWO_PER_PAGE:
					return theme.typography.h5.fontSize;
				default:
					return theme.typography.body1.fontSize;
			}
		},
		fontWeight: 'bold',
		marginBottom: ({ template }) => {
			switch (template) {
				case templateOptions.AVERY_25395:
					return '0.075in';
				default:
					return '0.1in';
			}
		},
	},
	footer: {
		width: '100%',
	},
	header: {
		margin: 'auto', // auto margin positions this at the center of available space
	},
	innerContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	logo: {
		maxHeight: ({ template }) => {
			switch (template) {
				case templateOptions.AVERY_25395:
					return '0.5in';
				case templateOptions.TWO_PER_PAGE:
					return '1in';
				default:
					return '0.65in';
			}
		},
		maxWidth: '50%',
	},
	logos: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},
	name: {
		display: 'block',
		fontSize: ({ template }) => {
			switch (template) {
				case templateOptions.AVERY_25395:
					return theme.typography.subtitle1.fontSize;
				case templateOptions.TWO_PER_PAGE:
					return theme.typography.h3.fontSize;
				default:
					return theme.typography.h6.fontSize;
			}
		},
		fontWeight: 'bold',
	},
}));

const PartnerMenuItemPrintableLabel = ({
	partnerMenuItem: {
		description,
		friendlyName,
		Partner,
		PartnerMenuItemsDietaryTags,
	},
	settings: { includeBorder, includePartnerLogo, includeSporkLogo, template },
}) => {
	const classes = useStyles({ includeBorder, template });

	return (
		<div className={classes.container}>
			<div className={classes.innerContainer}>
				<div className={classes.header}>
					<span className={classes.name}>{friendlyName}</span>
					<RichText
						body={description}
						className={classes.description}
					/>
				</div>
				<div className={classes.footer}>
					{PartnerMenuItemsDietaryTags?.length > 0 && (
						<div className={classes.dietaryTags}>
							{makeCommaSeparatedList(
								PartnerMenuItemsDietaryTags.map(
									({ DietaryTag: { name } }) => name
								),
								false
							)}
						</div>
					)}
					{(includePartnerLogo || includeSporkLogo) && (
						<div className={classes.logos}>
							{includePartnerLogo && (
								<img
									src={Partner?.logoUrl}
									alt=""
									className={classes.logo}
								/>
							)}
							{includeSporkLogo && (
								<img
									src={getThemedLogo()}
									alt="Spork Bytes"
									className={classes.logo}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

PartnerMenuItemPrintableLabel.fragments = {
	details: gql`
		fragment PartnerMenuItemPrintableLabelDetails on PartnerMenuItems {
			id
			description
			friendlyName
			ParentPartnerMenuItemId
			PartnerMenuItemsDietaryTags(
				order_by: { DietaryTag: { sortOrder: asc } }
			) {
				DietaryTag {
					name
				}
			}
			Partner {
				logoUrl
				name
			}
		}
	`,
};

PartnerMenuItemPrintableLabel.propTypes = {
	partnerMenuItem: PropTypes.shape({
		description: PropTypes.string,
		friendlyName: PropTypes.string,
		Partner: PropTypes.shape({
			logoUrl: PropTypes.string,
			name: PropTypes.string,
		}),
		PartnerMenuItemsDietaryTags: PropTypes.arrayOf(
			PropTypes.shape({
				DietaryTag: PropTypes.shape({
					name: PropTypes.string,
				}),
			})
		),
	}),
	settings: PropTypes.shape({
		includeBorder: PropTypes.bool,
		includePartnerLogo: PropTypes.bool,
		includeSporkLogo: PropTypes.bool,
		template: PropTypes.oneOf(Object.values(templateOptions)),
	}),
};

PartnerMenuItemPrintableLabel.defaultProps = {
	settings: {
		includeBorder: true,
		includePartnerLogo: true,
		includeSporkLogo: true,
		template: templateOptions.STANDARD,
	},
};

export default PartnerMenuItemPrintableLabel;
