import React from 'react';
import gql from 'graphql-tag';
import {
	Grid,
	IconButton,
	ListItemAvatar,
	Typography,
} from '@material-ui/core';
import { Create, Email, LocalPizza, Phone } from '@material-ui/icons';
import camelCase from 'lodash.camelcase';
import kebabCase from 'lodash.kebabcase';

import NamedAvatar from 'components/content/NamedAvatar';

import apolloClient from 'services/apollo';
import { getMultiWordSearchWhereClause } from 'services/search';
import { callPhoneNumber, sendEmail } from 'services/utilities';

export const getSearchResults = async searchTerm => {
	const query = gql`
			query MasterSearch {
				MasterSearchView(
					limit: 20
					order_by: { sortOrder: asc, title: asc }
					where: { _and: [${getMultiWordSearchWhereClause(searchTerm)}] }
				) {
					id
					type
					title
					subtitle
					imageUrl
					phoneNumber
					email
				}
			}
		`;

	const {
		data: { MasterSearchView },
	} = await apolloClient.query({
		query,
	});

	return MasterSearchView;
};

export const navigateToPage = ({ history, userCan }) => option => {
	if (!!option && userCan([`${kebabCase(option.type)}:view`])) {
		switch (option.type) {
			case 'DeliveryDrivers':
				history.pushNamedRoute(`deliveryDrivers`);
				break;
			default:
				history.pushNamedRoute(`${camelCase(option.type)}Detail`, {
					id: option.id,
				});
				break;
		}
	}
};

export const renderOption = ({ history, userCan }) => option => (
	<Grid container spacing={2} alignItems="center">
		<Grid item>
			<ListItemAvatar>
				<NamedAvatar
					alt={option.title}
					imageUrl={option.imageUrl}
					name={option.type}
				/>
			</ListItemAvatar>
		</Grid>
		<Grid item xs>
			<Typography>{option.title}</Typography>
			{option.subtitle && (
				<Typography variant="caption">{option.subtitle}</Typography>
			)}
		</Grid>
		{userCan([`${kebabCase(option.type)}:edit`]) && (
			<Grid item>
				<IconButton
					onClick={e => {
						e.stopPropagation(); // prevents the option itself from being clicked
						history.pushNamedRoute(
							`${camelCase(option.type)}Edit`,
							{
								id: option.id,
							}
						);
					}}
					edge="end"
					aria-label={`Edit ${option.title}`}
				>
					<Create />
				</IconButton>
			</Grid>
		)}
		{option.type === 'MealProposals' &&
			userCan(['meal-proposals:manage-meal-options']) && (
				<Grid item>
					<IconButton
						onClick={e => {
							e.stopPropagation(); // prevents the option itself from being clicked
							history.pushNamedRoute(
								`mealProposalsMealOptionsManage`,
								{
									id: option.id,
								}
							);
						}}
						edge="end"
						aria-label="Manage Meal Options"
					>
						<LocalPizza />
					</IconButton>
				</Grid>
			)}
		{option.phoneNumber && (
			<Grid item>
				<IconButton
					onClick={e => {
						e.stopPropagation(); // prevents the option itself from being clicked
						callPhoneNumber(option.phoneNumber);
					}}
					edge="end"
					aria-label={`Call ${option.title}`}
				>
					<Phone />
				</IconButton>
			</Grid>
		)}
		{option.email && (
			<Grid item>
				<IconButton
					onClick={e => {
						e.stopPropagation(); // prevents the option itself from being clicked
						sendEmail(option.email);
					}}
					edge="end"
					aria-label={`Email ${option.title}`}
				>
					<Email />
				</IconButton>
			</Grid>
		)}
	</Grid>
);
