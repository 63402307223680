import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { RoomService } from '@material-ui/icons';
import { confirmationWindowLengthInHours } from '@sporkbytes/config';
import {
	Form,
	GridContainer,
	LinkedDateTimePicker,
	LinkedRichTextEditor,
	LinkedSwitch,
	LinkedPercentInput,
} from '@sporkbytes/material-ui-kit-react';

import FormSectionHeader from 'components/forms/FormSectionHeader';
import FormSectionSubheader from 'components/forms/FormSectionSubheader';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PartnerLocationSearch from 'components/partner-locations/PartnerLocationSearch';

import * as yup from 'models/validation';
import { useStore } from 'models/store';

import { DEFAULT_TIMEZONE } from 'services/dates';
import { pick } from 'services/utilities';
import apolloClient from 'services/apollo';

const PARTNER_LOCATION_DETAIL = gql`
	query PartnerLocationDetailForPurchaseOrderForm($id: uuid!) {
		PartnerLocations_by_pk(id: $id) {
			automatedEmailConfirmation
			fullAddress
			pickupInstructions
			timezone
			Partner {
				id
				logoUrl
				name
				reviewable
			}
		}
	}
`;

const validationSchema = yup.object().shape({
	PartnerLocationId: yup
		.string()
		.required('Please select a partner location.'),
	sporkCommissionPercent: yup
		.nonNegativeNumber()
		.max(100, 'Please enter a number no greater than 100.')
		.required('Please enter a commission.'),
	pickupTime: yup.date().required('Please choose a pickup time.'),
	pickupInstructions: yup.string().nullable(),
	automatedEmailConfirmation: yup.boolean(),
	reviewable: yup.boolean(),
});

const PurchaseOrderForm = ({
	mealOption,
	pageSubheaderText,
	partnerLocation,
	partnerLocationSearchFilter,
	partnerLocationSearchHelperText,
	...props
}) => {
	const [selectedPartnerLocation, setSelectedPartnerLocation] = useState({
		timezone: DEFAULT_TIMEZONE,
		...partnerLocation,
	});
	const { showErrorNotification } = useStore();

	const onPartnerLocationChange =
		({ setValues, setSubmitting, values }) =>
		async option => {
			if (!!option) {
				try {
					setSubmitting(true);
					const {
						data: { PartnerLocations_by_pk },
					} = await apolloClient.query({
						query: PARTNER_LOCATION_DETAIL,
						variables: {
							id: option.id,
						},
					});

					setValues({
						...values,
						...pick(PartnerLocations_by_pk, [
							'automatedEmailConfirmation',
							'pickupInstructions',
						]),
						reviewable: PartnerLocations_by_pk.Partner.reviewable,
						PartnerLocationId: option.id,
					});
					setSelectedPartnerLocation(PartnerLocations_by_pk);
				} catch (error) {
					showErrorNotification(
						'There was an error setting default values.  Please double-check all values.'
					);
				} finally {
					setSubmitting(false);
				}
			} else {
				setValues({
					...values,
					automatedEmailConfirmation: false,
					pickupInstructions: '',
					reviewable: false,
					PartnerLocationId: undefined,
				});
				setSelectedPartnerLocation({
					timezone: DEFAULT_TIMEZONE,
				});
			}
		};

	return (
		<>
			<PageHeader
				imgUrl={selectedPartnerLocation?.Partner?.logoUrl}
				headerText={
					selectedPartnerLocation?.Partner?.name ||
					'Select a Partner Location'
				}
			/>
			<PageSubheader>
				<div>
					Meal Proposal -{' '}
					{mealOption.MealProposal.name ||
						mealOption.MealProposal.orderNumberText}
				</div>
				<div>Meal Option - {mealOption.name}</div>
				<div>{pageSubheaderText}</div>
			</PageSubheader>
			<Form {...props} validationSchema={validationSchema}>
				{formikBag => (
					<>
						<FormSectionHeader>Location and Time</FormSectionHeader>
						<GridContainer columns={3}>
							<PartnerLocationSearch
								autoFocus
								name="PartnerLocationId"
								placeholder="Search for a partner location"
								where={`
									_and: [
										{ active: { _eq: true } }
										{ Partner: { active: { _eq: true } } }
										${partnerLocationSearchFilter} 
									]
								`}
								onChange={onPartnerLocationChange(formikBag)}
								disableClearable
								helperText={partnerLocationSearchHelperText}
								SporkLocationId={
									mealOption.MealProposal.SporkLocationId
								}
							/>
							<LinkedDateTimePicker
								name="pickupTime"
								timezone={selectedPartnerLocation.timezone}
								timeOnly
								minutesStep={5}
							/>
							<LinkedPercentInput name="sporkCommissionPercent" />
						</GridContainer>

						<FormSectionSubheader>Address</FormSectionSubheader>
						<p>
							{selectedPartnerLocation?.fullAddress ||
								'Please select a partner location.'}
						</p>

						<FormSectionHeader>Details</FormSectionHeader>
						<GridContainer>
							<LinkedRichTextEditor
								name="pickupInstructions"
								icon={<RoomService />}
							/>
						</GridContainer>
						<GridContainer columns={3}>
							<LinkedSwitch
								name="automatedEmailConfirmation"
								uncheckedHelperText="The meal's confirmation email for the assigned partner contacts must be sent manually."
								checkedHelperText={`A confirmation email will be sent automatically ${confirmationWindowLengthInHours.partner} hours before delivery to the assigned partner contacts.`}
							/>
							<LinkedSwitch
								name="reviewable"
								label="Allow the partner to be reviewed for this meal?"
								uncheckedHelperText="The partner will not be reviewed for this meal."
								checkedHelperText="The partner will be reviewed for this meal."
							/>
						</GridContainer>
					</>
				)}
			</Form>
		</>
	);
};

PurchaseOrderForm.fragments = {
	sporkLocationFilter: gql`
		fragment PurchaseOrderFormSporkLocationFilter on MealOptions {
			MealProposal {
				SporkLocationId
			}
		}
	`,
	subheader: gql`
		fragment PurchaseOrderFormSubheader on MealOptions {
			name
			MealProposal {
				name
				orderNumberText
			}
		}
	`,
};

PurchaseOrderForm.propTypes = {
	pageSubheaderText: PropTypes.string.isRequired,
	partnerLocation: PropTypes.shape({
		fullAddress: PropTypes.string.isRequired,
		timezone: PropTypes.string.isRequired,
		Partner: PropTypes.shape({
			logoUrl: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
	}),
	partnerLocationSearchFilter: PropTypes.string,
	partnerLocationSearchHelperText: PropTypes.string,
};

PurchaseOrderForm.defaultProps = {
	partnerLocationSearchFilter: '',
};

export default PurchaseOrderForm;
