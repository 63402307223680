import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/PurchaseOrderPayoutAdjustments';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PurchaseOrderPayoutAdjustmentForm from 'components/purchase-order-payout-adjustments/PurchaseOrderPayoutAdjustmentForm';

import { pick } from 'services/utilities';

const PURCHASE_ORDER_PAYOUT_ADJUSTMENT_QUERY = gql`
	query PurchaseOrderPayoutAdjustmentEdit($id: uuid!) {
		PurchaseOrderPayoutAdjustments_by_pk(id: $id) {
			amount
			description
			PurchaseOrder {
				...PurchaseOrderPayoutAdjustmentFormDetails
			}
		}
	}
	${PurchaseOrderPayoutAdjustmentForm.fragments.details}
`;

const Edit = () => {
	const { id } = useParams();

	return (
		<Page
			title="Edit Payout Adjustment"
			query={PURCHASE_ORDER_PAYOUT_ADJUSTMENT_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					PurchaseOrderPayoutAdjustments_by_pk: payoutAdjustment,
				},
			}) => {
				return (
					<FormWrapper
						form={PurchaseOrderPayoutAdjustmentForm}
						initialValues={pick(payoutAdjustment, [
							'amount',
							'description',
						])}
						pageSubheaderText="Edit Payout Adjustment"
						purchaseOrder={payoutAdjustment.PurchaseOrder}
						onSubmit={(axios, values) => put(axios, id, values)}
					/>
				);
			}}
		</Page>
	);
};

export default Edit;
