import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ActionMenu from 'components/utilities/ActionMenu';

import {
	createCallPhoneNumberAction,
	createEditAction,
	createGetDirectionsAction,
} from 'services/actionMenu';
import { useAuth } from 'services/auth';

const ClientLocationActionMenu = ({
	additionalActions,
	clientLocation: { id, friendlyName, fullAddress, phoneNumber },
}) => {
	const { userCan } = useAuth();
	const [loading, setLoading] = useState(false);
	const entityType = 'ClientLocations';

	return (
		<ActionMenu
			loading={loading}
			options={[
				createEditAction({
					entityId: id,
					entityName: friendlyName,
					entityType,
					userCan,
				}),
				createCallPhoneNumberAction({
					canPerformAction: !!phoneNumber,
					entityName: friendlyName,
					phoneNumber,
				}),
				createGetDirectionsAction({
					address: fullAddress,
					entityName: friendlyName,
				}),
				...additionalActions({ setLoading }),
			]}
		/>
	);
};

ClientLocationActionMenu.propTypes = {
	additionalActions: PropTypes.func,
	clientLocation: PropTypes.shape({
		friendlyName: PropTypes.string.isRequired,
		fullAddress: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		phoneNumber: PropTypes.string,
	}).isRequired,
};

ClientLocationActionMenu.defaultProps = {
	additionalActions: () => [],
};

export default ClientLocationActionMenu;
