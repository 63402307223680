import PurchaseOrderCreate from 'pages/meal-options/PurchaseOrderCreate';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'purchaseOrdersCreate',
		path: '/:id/create',
		component: PurchaseOrderCreate,
		permissions: ['meal-options:edit', 'purchase-orders:create'],
		exact: true,
	},
];

export default prependUrlPath('meal-options', routes);
