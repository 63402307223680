import React from 'react';
import {
	Form,
	GridContainer,
	LinkedRichTextEditor,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import * as yup from 'models/validation';

const PartnerMenuSectionForm = props => {
	const validationSchema = yup.object().shape({
		name: yup.name().required(),
		printableName: yup.name(),
		howToOrder: yup.string(),
	});

	return (
		<Form {...props} validationSchema={validationSchema}>
			{() => (
				<>
					<GridContainer columns={2}>
						<LinkedTextInput name="name" autoFocus />
						<LinkedTextInput name="printableName" />
					</GridContainer>
					<GridContainer>
						<LinkedRichTextEditor name="howToOrder" />
					</GridContainer>
				</>
			)}
		</Form>
	);
};

export default PartnerMenuSectionForm;
