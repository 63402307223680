import React, { useContext } from 'react';
import gql from 'graphql-tag';
import Chart from 'react-apexcharts';
import { getSummationCalculation } from '@sporkbytes/meal-proposal-utils';

import WidgetTitle from 'components/analytics/WidgetTitle';

import { AnalyticsContext } from 'pages/analytics/context';

import { formatCurrency } from 'services/mealProposal';

const RevenueBySporkContact = () => {
	const { chartConfig, mealProposalsBySporkContact } = useContext(
		AnalyticsContext
	);
	const { series, labels } = Object.keys(mealProposalsBySporkContact).reduce(
		({ series, labels }, email) => ({
			series: [
				...series,
				getSummationCalculation(
					'totalClientPays',
					mealProposalsBySporkContact[email]
				),
			],
			labels: [
				...labels,
				mealProposalsBySporkContact[email][0].SporkContact.fullName,
			],
		}),
		{
			series: [],
			labels: [],
		}
	);

	const options = {
		...chartConfig.options,
		labels,
		tooltip: {
			y: {
				formatter: formatCurrency,
			},
		},
	};

	return (
		<>
			<WidgetTitle>Revenue by Spork Contact</WidgetTitle>
			<Chart
				type="donut"
				series={series}
				options={options}
				height={chartConfig.height}
			/>
		</>
	);
};

RevenueBySporkContact.fragments = {
	details: gql`
		fragment RevenueBySporkContactDetails on MealProposals {
			SporkContact {
				emailAddress
				fullName
			}
		}
	`,
};

export default RevenueBySporkContact;
