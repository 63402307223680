import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { post } from '@sporkbytes/api-client/DeliveryGear';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import DeliveryGearForm from 'components/delivery-gear/DeliveryGearForm';

import { useStore } from 'models/store';

const DELIVERY_GEAR_TYPE_QUERY = gql`
	query DeliveryGearCreate($id: uuid!) {
		DeliveryGearTypes_by_pk(id: $id) {
			name
		}
	}
`;

const DeliveryGearCreate = () => {
	const { id: DeliveryGearTypeId } = useParams();
	const { selectedSporkLocationId } = useStore();
	const initialValues = {
		SporkLocationId: selectedSporkLocationId,
	};

	return (
		<Page
			title={({ DeliveryGearTypes_by_pk: { name } }) =>
				`Add New Gear to ${name}`
			}
			query={DELIVERY_GEAR_TYPE_QUERY}
			variables={{ id: DeliveryGearTypeId }}
		>
			{({
				data: {
					DeliveryGearTypes_by_pk: { name },
				},
			}) => (
				<>
					<PageHeader headerText={`Add New Gear to ${name}`} />

					<FormWrapper
						form={DeliveryGearForm}
						initialValues={initialValues}
						onSubmit={(axios, values) =>
							post(axios, {
								DeliveryGearTypeId,
								...values,
							})
						}
					/>
				</>
			)}
		</Page>
	);
};

export default DeliveryGearCreate;
