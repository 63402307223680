import React from 'react';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';
import { FastForward, PlayArrow } from '@material-ui/icons';
import { Hidden, IconButton, makeStyles, TextField } from '@material-ui/core';
import { DateTimePicker } from '@sporkbytes/material-ui-kit-react';

const useStyles = makeStyles(theme => ({
	container: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(1),
		width: '100%',
	},
	icon: {
		color: theme.palette.common.white,
		fontSize: 42,
	},
	input: {
		color: theme.palette.common.white,
		fontSize: 26,
		textAlign: 'center',
	},
	label: {
		color: theme.palette.common.white,
		fontSize: 20,
	},
	rotatedIcon: {
		transform: 'rotate(180deg)',
	},
}));

const DateSelector = ({ value, onChange, ...props }) => {
	const classes = useStyles();

	const IconWrapper = ({ Icon, daysToAdd, rotated }) => (
		<Hidden xsDown>
			<IconButton
				onClick={() => onChange(addDays(value, daysToAdd))}
				className={classes.iconButton}
			>
				<Icon
					className={`${classes.icon} ${
						rotated ? classes.rotatedIcon : ''
					}`}
				/>
			</IconButton>
		</Hidden>
	);

	return (
		<div className={classes.container}>
			<IconWrapper Icon={FastForward} rotated daysToAdd={-7} />
			<IconWrapper Icon={PlayArrow} rotated daysToAdd={-1} />

			<DateTimePicker
				{...props}
				value={value}
				onChange={onChange}
				dateOnly
				format="MMM do yyyy"
				inputProps={{
					className: classes.input,
				}}
				TextFieldComponent={props => {
					return (
						<TextField
							{...props}
							fullWidth={false}
							InputLabelProps={{
								className: classes.label,
							}}
						/>
					);
				}}
			/>

			<IconWrapper Icon={PlayArrow} daysToAdd={1} />
			<IconWrapper Icon={FastForward} daysToAdd={7} />
		</div>
	);
};

DateSelector.propTypes = {
	value: PropTypes.instanceOf(Date),
	onChange: PropTypes.func.isRequired,
};

export default DateSelector;
