import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/ServicedStates';
import { Alert } from '@sporkbytes/material-ui-kit-react';

import ActionMenu from 'components/utilities/ActionMenu';
import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import RemoteTable from 'components/content/RemoteTable';

import { createEditAction } from 'services/actionMenu';
import { useAuth } from 'services/auth';

const getServicedStatesQuery = searchTerm =>
	gql`
		query ServicedStates(
			$activeOrderBy: order_by
			$nameOrderBy: order_by
			$limit: Int!
			$offset: Int!
		) {
			ServicedStates(
				where: { name: { _ilike: "%${searchTerm}%" } }
				order_by: {
					active: $activeOrderBy
					name: $nameOrderBy
				}
				limit: $limit
				offset: $offset
			) {
				id
				active
				name
			}
			ServicedStates_aggregate {
				aggregate {
					count
				}
			}
		}
	`;

const ServicedStates = () => {
	const { userCan } = useAuth();
	const ref = useRef();
	const entityType = 'Serviced States';

	return (
		<Page title={entityType}>
			<PageHeader headerText={entityType} />
			<Alert type="info">
				These are the states in which we provide service. These are
				associated with Spork Locations and are used to populate state
				values on the Get Started form for customers.
			</Alert>
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Name',
						field: 'name',
					},
					{
						title: 'Active',
						field: 'active',
						render: ({ active, id }) => (
							<BooleanFieldSwitch
								afterChange={ref.current.onQueryChange}
								entityId={id}
								entityType="servicedStates"
								updateEntity={put}
								value={active}
							/>
						),
					},
					{
						title: 'Actions',
						field: 'actions',
						sorting: false,
						render: ({ id, name }) => (
							<ActionMenu
								options={[
									createEditAction({
										entityId: id,
										entityName: name,
										entityType,
										userCan,
									}),
								]}
							/>
						),
					},
				]}
				getQuery={getServicedStatesQuery}
				defaultOrderBy="name"
				defaultOrderDirection="asc"
				queryEntity="ServicedStates"
				addNewEntityRouteName="servicedStatesCreate"
				entityToAddName="Serviced State"
				canAddNewEntity={userCan(['serviced-states:create'])}
			/>
		</Page>
	);
};

export default ServicedStates;
