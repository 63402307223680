import React from 'react';
import PropTypes from 'prop-types';
import {
	LinkedSingleSelect,
	SingleSelect,
} from '@sporkbytes/material-ui-kit-react';

const AccountingSettingSelect = ({ isLinked, options, ...props }) => {
	const Component = isLinked ? LinkedSingleSelect : SingleSelect;

	return (
		<Component
			options={options}
			getOptionKey={option => option.code}
			getOptionLabel={option => `${option.code} - ${option.name}`}
			getOptionValue={option => option.code}
			label="Xero Item"
			{...props}
		/>
	);
};

AccountingSettingSelect.propTypes = {
	isLinked: PropTypes.bool.isRequired,
	options: PropTypes.array.isRequired,
};

export default AccountingSettingSelect;
