import { string, number, addMethod } from 'yup';
import gql from 'graphql-tag';
import apolloClient from 'services/apollo';

addMethod(string, 'unique', function ({
	tableName,
	fieldName,
	message,
	initialValue,
}) {
	// TODO: alternative method for async validation with Formik https://gist.github.com/jaredpalmer/a8faaab12bc37e6a160a3c9549664f0b
	return this.test('unique', message, async value => {
		if (value && value !== initialValue) {
			const { data } = await apolloClient.query({
				query: gql`
					query CheckForUnique${tableName} {
						${tableName}_aggregate(where: { ${fieldName}: { _eq: "${value}" } }) {
							aggregate {
								count
							}
						}
					}
				`,
			});

			return data[`${tableName}_aggregate`].aggregate.count === 0;
		}

		return true;
	});
});

class StreetAddress extends string {
	required(message = 'Please enter an address.') {
		return super.required(message);
	}
}

class City extends string {
	required(message = 'Please enter a city.') {
		return super.required(message);
	}
}

class State extends string {
	required(message = 'Please enter a state.') {
		return super.required(message);
	}
}

class ZIPCode extends string {
	constructor() {
		super();

		return this.matches(
			/^[0-9]{5}(-[0-9]{4})?$/,
			'Please enter a valid ZIP Code'
		);
	}

	required(message = 'Please enter a ZIP Code.') {
		return super.required(message);
	}
}

class PositiveNumber extends number {
	constructor() {
		super();

		return this.moreThan(0, 'Please enter a positive number.');
	}
}

class NonNegativeNumber extends number {
	constructor() {
		super();

		return this.min(0, 'Please enter a non-negative number.');
	}
}

class PositiveInteger extends number {
	constructor() {
		super();

		return this.integer('Please enter an integer.').min(
			1,
			'Please enter a positive integer'
		);
	}
}

class NonNegativeInteger extends number {
	constructor() {
		super();

		return this.integer('Please enter an integer.').min(
			0,
			'Please enter a non-negative integer'
		);
	}
}

class PhoneNumber extends string {
	constructor() {
		super();

		return this.matches(
			/^[0-9]{10}$/,
			'Please enter a valid phone number.'
		);
	}

	required(message = 'Please enter a phone number.') {
		return super.required(message);
	}
}

class SporkLocationId extends string {
	constructor() {
		super();

		return this.required('Please select a Spork Location.');
	}
}

class TimeToPark extends NonNegativeInteger {
	required(message = 'Please enter the time it takes to park.') {
		return super.required(message);
	}
}

class TimeToEnter extends NonNegativeInteger {
	required(
		message = 'Please enter the time it takes to enter the building.'
	) {
		return super.required(message);
	}
}

class TimeToExit extends NonNegativeInteger {
	required(message = 'Please enter the time it takes to exit the building.') {
		return super.required(message);
	}
}

class Name extends string {
	constructor() {
		super();

		return this.max(50, 'Please limit to 50 characters.');
	}

	required(message = 'Please enter a name.') {
		return super.required(message);
	}
}

export const streetAddress = () => new StreetAddress();
export const city = () => new City();
export const state = () => new State();
export const zipCode = () => new ZIPCode();
export const positiveNumber = () => new PositiveNumber();
export const nonNegativeNumber = () => new NonNegativeNumber();
export const positiveInteger = () => new PositiveInteger();
export const nonNegativeInteger = () => new NonNegativeInteger();
export const phoneNumber = () => new PhoneNumber();
export const sporkLocationId = () => new SporkLocationId();
export const timeToPark = () => new TimeToPark();
export const timeToEnter = () => new TimeToEnter();
export const timeToExit = () => new TimeToExit();
export const name = () => new Name();

export * from 'yup';
