import React from 'react';
import { CardContent } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import {
	addExistingContact,
	put,
	removeContact,
} from '@sporkbytes/api-client/PartnerLocations';

import ContactsTable from 'components/contacts/ContactsTable';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PartnerLocationActionMenu from 'components/partner-locations/PartnerLocationActionMenu';
import PartnerLocationDetails from 'components/partner-locations/PartnerLocationDetails';
import PurchaseOrderReviewsTable from 'components/purchase-order-reviews/PurchaseOrderReviewsTable';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';
import RibbonContainer from 'components/content/RibbonContainer';

import {
	createRemoveAction,
	createToggleActiveStatusAction,
} from 'services/actionMenu';
import { useAuth } from 'services/auth';
import axios from 'services/data';

const PARTNER_LOCATION_QUERY = gql`
	query PartnerLocationPageDetails($id: uuid!) {
		PartnerLocations_by_pk(id: $id) {
			id
			active
			friendlyName
			fullAddress
			...PartnerLocationDetails
			Partner {
				id
				logoUrl
				name
			}
		}
	}
	${PartnerLocationDetails.fragments.details}
`;

const Detail = () => {
	const { id } = useParams();
	const { userCan } = useAuth();

	return (
		<Page
			title={({ PartnerLocations_by_pk: { Partner, friendlyName } }) =>
				`${friendlyName} (${Partner.name})`
			}
			query={PARTNER_LOCATION_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					PartnerLocations_by_pk: { Partner, ...partnerLocation },
				},
				refetch,
			}) => (
				<>
					<RibbonContainer
						show={!partnerLocation.active}
						title="inactive"
						color="error"
					>
						<PageHeader
							imgUrl={Partner.logoUrl}
							headerText={Partner.name}
							linkProps={{
								routeName: 'partnersDetail',
								variables: { id: Partner.id },
								permissions: ['partners:view'],
							}}
							actionButton={
								<PartnerLocationActionMenu
									partnerLocation={partnerLocation}
									additionalActions={({ setLoading }) => [
										createToggleActiveStatusAction({
											active: partnerLocation.active,
											entityId: id,
											entityName:
												partnerLocation.friendlyName,
											entityType: 'PartnerLocations',
											onActionComplete: () =>
												refetch({ id }),
											setLoading,
											updateEntity: put,
											userCan,
										}),
									]}
								/>
							}
						/>
						<PageSubheader>
							{partnerLocation.name ? (
								<>
									<div>{partnerLocation.name}</div>
									<div>{partnerLocation.fullAddress}</div>
								</>
							) : (
								partnerLocation.fullAddress
							)}
						</PageSubheader>
					</RibbonContainer>
					<QueryStringSyncedTabs
						id="tab"
						tabs={[
							{
								id: 'details',
								label: 'Details',
								content: (
									<CardContent>
										<PartnerLocationDetails
											partnerLocation={partnerLocation}
										/>
									</CardContent>
								),
							},
							{
								id: 'contacts',
								label: 'Contacts',
								content: (
									<ContactsTable
										tableFilter={`PartnerLocationsContacts: { PartnerLocationId: { _eq: "${id}" } }`}
										onAddExistingEntity={(
											axios,
											contact,
											ref
										) =>
											addExistingContact(
												axios,
												id,
												contact.id
											).then(ref.current.onQueryChange)
										}
										canAddExistingEntity={userCan([
											'partner-locations-contacts:create',
											'contacts:view',
										])}
										canAddNewEntity={userCan([
											'contacts:create',
											'partner-locations-contacts:create',
										])}
										addNewEntityRouteName="partnerLocationsContactsCreate"
										addNewEntityRouteVariables={{ id }}
										additionalActions={({
											ref,
											setLoading,
											contact,
										}) => [
											createRemoveAction({
												canPerformAction: userCan([
													'partner-locations-contacts:delete',
												]),
												entityName: contact.firstName,
												onActionComplete: () => {
													setLoading(false);
													ref.current.onQueryChange();
												},
												removeEntity: () => {
													setLoading(true);
													return removeContact(
														axios,
														id,
														contact.id
													);
												},
											}),
										]}
									/>
								),
							},
							{
								id: 'reviews',
								label: 'Reviews',
								content: (
									<PurchaseOrderReviewsTable
										hiddenColumns={['partnerName']}
										variables={{
											PartnerLocationId: id,
										}}
									/>
								),
							},
						]}
					/>
				</>
			)}
		</Page>
	);
};

export default Detail;
