import React from 'react';
import { useParams } from 'react-router';
import { post } from '@sporkbytes/api-client/FeaturedPartners';

import FeaturedPartnerForm from 'components/featured-partners/FeaturedPartnerForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { useStore } from 'models/store';

const Create = () => {
	const { month } = useParams();
	const { selectedSporkLocationId } = useStore();
	const initialValues = {
		month,
		SporkLocationId: selectedSporkLocationId,
	};

	return (
		<Page title="Add New Featured Partner">
			<PageHeader headerText="Add New Featured Partner" />
			<FormWrapper
				form={FeaturedPartnerForm}
				initialValues={initialValues}
				month={month}
				onSubmit={post}
			/>
		</Page>
	);
};

export default Create;
