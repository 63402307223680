import React from 'react';
import {
	useGoogleReCaptcha,
	GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => {
	return {
		text: {
			fontSize: '0.75rem',
			marginTop: theme.spacing(2),
		},
	};
});
const Helper = ({ Form, ...props }) => {
	const classes = useStyles();
	const { executeRecaptcha } = useGoogleReCaptcha();

	return (
		<>
			<Form
				{...props}
				getRecaptchaToken={() => executeRecaptcha(Form.name)}
				recaptchaText={
					<Typography className={classes.text}>
						This site is protected by reCAPTCHA and the Google{' '}
						<a
							href="https://policies.google.com/privacy"
							target="_blank"
							rel="noopener noreferrer"
						>
							Privacy Policy
						</a>{' '}
						and{' '}
						<a
							href="https://policies.google.com/terms"
							target="_blank"
							rel="noopener noreferrer"
						>
							Terms of Service
						</a>{' '}
						apply.
					</Typography>
				}
			/>
		</>
	);
};

const withRecaptcha = Form => props => {
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={
				process.env.REACT_APP_GOOGLE_RECAPTCHA_REVIEW_SITE_KEY
			}
		>
			<Helper Form={Form} {...props} />
		</GoogleReCaptchaProvider>
	);
};

export default withRecaptcha;
