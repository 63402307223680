import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import axios from 'services/data';

const FormWrapper = ({
	form: Form,
	onDelete,
	onSubmit,
	redirectToRoute,
	routeVariables,
	...props
}) => {
	const history = useHistory();

	return (
		<Form
			{...props}
			onSubmit={async values => {
				const response = await onSubmit(axios, values);

				if (redirectToRoute) {
					history.pushNamedRoute(redirectToRoute, {
						id: response.id,
						...routeVariables(response),
					});
				} else {
					history.pushPreviousRoute();
				}
			}}
			onDelete={onDelete ? () => onDelete(axios) : undefined}
		/>
	);
};

FormWrapper.propTypes = {
	form: PropTypes.elementType.isRequired,
	onDelete: PropTypes.func,
	onSubmit: PropTypes.func.isRequired,
	redirectToRoute: PropTypes.string,
	routeVariables: PropTypes.func,
};

FormWrapper.defaultProps = {
	routeVariables: () => {},
};

export default FormWrapper;
