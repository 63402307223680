import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

import DetailSection from 'components/content/DetailSection';
import NoContentText from 'components/content/NoContentText';

const useStyles = makeStyles(theme => ({
	itemContainer: {
		alignItems: 'baseline',
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(1, 0),
	},
	label: {
		fontSize: 12,
		fontWeight: 'bold',
		letterSpacing: 1,
		paddingRight: theme.spacing(1),
		textTransform: 'uppercase',
		width: 225,
	},
	value: {
		width: 225,
	},
}));

const DefinitionList = ({ items, title }) => {
	const classes = useStyles();

	return (
		<DetailSection title={title}>
			{items
				.filter(({ hideItem }) => !hideItem)
				.map(({ label, render: Render, value, noContentText }) => (
					<div key={label} className={classes.itemContainer}>
						<Typography className={classes.label}>
							{label}:&nbsp;
						</Typography>
						<div className={classes.value}>
							{value === undefined || value === null ? (
								<NoContentText>{noContentText}</NoContentText>
							) : Render ? (
								<Render value={value} />
							) : (
								<Typography>{value}</Typography>
							)}
						</div>
					</div>
				))}
		</DetailSection>
	);
};

DefinitionList.propTypes = {
	title: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			hideItem: PropTypes.bool,
			label: PropTypes.string.isRequired,
			noContentText: PropTypes.string,
			render: PropTypes.elementType,
			value: PropTypes.any,
		})
	),
};

export default DefinitionList;
