import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
	LinkedMultiSelect,
	LinkedSingleSelect,
} from '@sporkbytes/material-ui-kit-react';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';

const ACTIVE_SERVICED_STATES_QUERY = gql`
	query ActiveServicedStates {
		ServicedStates(
			where: { active: { _eq: true } }
			order_by: { name: asc }
		) {
			abbreviation
			id
			name
		}
	}
`;

const StateSelector = ({ type, ...props }) => {
	const { loading, data } = useQuery(ACTIVE_SERVICED_STATES_QUERY);

	const Wrapper = type === 'single' ? LinkedSingleSelect : LinkedMultiSelect;

	return loading ? (
		<CenteredCircularProgress />
	) : (
		<Wrapper
			options={data.ServicedStates}
			getOptionKey={({ id }) => id}
			getOptionValue={({ abbreviation }) => abbreviation}
			getOptionLabel={({ name }) => name}
			{...props}
		/>
	);
};

StateSelector.propTypes = {
	type: PropTypes.oneOf(['single', 'multiple']),
};

StateSelector.defaultProps = {
	type: 'single',
};

export default StateSelector;
