import React from 'react';
import PropTypes from 'prop-types';
import {
	Form,
	GridContainer,
	LinkedRichTextEditor,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import SporkLocationLinkedSingleSelect from 'components/spork-locations/SporkLocationLinkedSingleSelect';

import { useStore } from 'models/store';

import * as yup from 'models/validation';

const DeliveryGearForm = ({ initialValues, ...props }) => {
	const validationSchema = yup.object().shape({
		name: yup.name().required(),
		abbreviatedName: yup
			.string()
			.max(5, 'Please limit to 5 characters.')
			.unique({
				tableName: 'DeliveryGear',
				fieldName: 'abbreviatedName',
				message:
					'This abbreviated name is already in use. Please enter a different abbreviated name.',
				initialValue: initialValues.abbreviatedName,
			})
			.required('Please enter an abbreviated name.'),
		notes: yup.string(),
		SporkLocationId: yup.sporkLocationId(),
	});
	const { hasMoreThanOneSporkLocation } = useStore();

	return (
		<Form
			{...props}
			initialValues={initialValues}
			validationSchema={validationSchema}
		>
			{() => (
				<>
					<GridContainer>
						<LinkedTextInput name="name" autoFocus />
						<LinkedTextInput name="abbreviatedName" />
						{hasMoreThanOneSporkLocation() ? (
							<SporkLocationLinkedSingleSelect />
						) : null}
					</GridContainer>
					<GridContainer>
						<LinkedRichTextEditor name="notes" />
					</GridContainer>
				</>
			)}
		</Form>
	);
};

DeliveryGearForm.propTypes = {
	initialValues: PropTypes.object,
};

DeliveryGearForm.defaultProps = {
	initialValues: {},
};

export default DeliveryGearForm;
