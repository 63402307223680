import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/ClientLocations';

import ClientLocationForm from 'components/client-locations/ClientLocationForm';
import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';

import { useAuth } from 'services/auth';

// TODO: use fragments here
const CLIENT_LOCATION_QUERY = gql`
	query ClientLocationEdit($id: uuid!) {
		ClientLocations_by_pk(id: $id) {
			automatedApprovalThankYou
			automatedEmailConfirmation
			automatedReviewRequest
			budgetPerPerson
			city
			description
			dietaryRestrictions
			friendlyName
			howToOrder
			name
			paymentDue
			phoneNumber
			state
			streetAddress
			streetAddress2
			timeToEnter
			timeToExit
			timeToPark
			totalBudget
			deliveryInstructions
			headCount
			setupInstructions
			zipCode
			Client {
				name
				logoUrl
			}
		}
	}
`;

const Edit = () => {
	const { userCannot } = useAuth();
	const { id } = useParams();

	return (
		<Page
			title={({ ClientLocations_by_pk: { friendlyName } }) =>
				`Edit ${friendlyName}`
			}
			query={CLIENT_LOCATION_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					ClientLocations_by_pk: {
						Client,
						friendlyName,
						...clientLocation
					},
				},
			}) => (
				<>
					<PageHeader
						imgUrl={Client.logoUrl}
						headerText={Client.name}
					/>
					<PageSubheader>{friendlyName}</PageSubheader>
					<FormWrapper
						form={ClientLocationForm}
						initialValues={clientLocation}
						onSubmit={(axios, values) => put(axios, id, values)}
						addressSearchDisabled={userCannot([
							'client-locations:edit-address',
						])}
					/>
				</>
			)}
		</Page>
	);
};

export default Edit;
