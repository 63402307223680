import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Formik } from 'formik';
import { FilterList } from '@material-ui/icons';
import {
	GridContainer,
	LinkedCurrencyInput,
	LinkedIntegerInput,
	LinkedMultiSelect,
	LinkedTextInput,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import ExpansionPanel from 'components/content/ExpansionPanel';
import InlineContent from 'components/content/InlineContent';
import NoteContainer from 'components/content/NoteContainer';
import PartnerLocationOrderingDetails from 'components/partner-locations/PartnerLocationOrderingDetails';
import PartnerMenuItemCard from 'components/partner-menu-items/PartnerMenuItemCard';

import { PartnerContext } from 'context/PartnerContext';

import * as yup from 'models/validation';

const PARTNER_MENU_QUERY = gql`
	query PartnerMenuBrowser($PartnerId: uuid!, $PartnerLocationId: uuid) {
		Partners_by_pk(id: $PartnerId) {
			name
			PartnerMenuSections(order_by: { sortOrder: asc }) {
				id
				name
				howToOrder
				PartnerMenuItems(
					order_by: { sortOrder: asc }
					where: { active: { _eq: true } }
				) {
					...PartnerMenuItemCardDetails
				}
			}
			PartnerLocations(where: { id: { _eq: $PartnerLocationId } }) {
				...PartnerLocationOrderingDetails
			}
		}
		DietaryTags(order_by: { sortOrder: asc }) {
			name
		}
	}
	${PartnerLocationOrderingDetails.fragments.details}
	${PartnerMenuItemCard.fragments.details}
`;

const filterMenuItem = filters => item => {
	let itemMatchesFilters = {
		dietaryTags: true,
		maxPrice: true,
		minPrice: true,
		name: true,
	};

	if (filters.dietaryTags.length > 0) {
		itemMatchesFilters.dietaryTags = filters.dietaryTags.every(dietaryTag =>
			item.PartnerMenuItemsDietaryTags.map(
				({ DietaryTag }) => DietaryTag.name
			).includes(dietaryTag)
		);
	}

	if (!!filters.maxPrice) {
		itemMatchesFilters.maxPrice =
			!!item.cost && item.cost <= filters.maxPrice;
	}

	if (!!filters.minPrice) {
		itemMatchesFilters.minPrice =
			!!item.cost && item.cost >= filters.minPrice;
	}

	if (!!filters.name) {
		itemMatchesFilters.name = item.friendlyName
			.toLowerCase()
			.includes(filters.name.toLowerCase());
	}

	if (!!filters.feedsMaximum) {
		itemMatchesFilters.feedsMaximum =
			!!item.feedsMaximum && item.feedsMaximum <= filters.feedsMaximum;
	}

	if (!!filters.feedsMinimum) {
		itemMatchesFilters.feedsMinimum =
			!!item.feedsMinimum && item.feedsMinimum >= filters.feedsMinimum;
	}

	return Object.values(itemMatchesFilters).every(value => value);
};

const validationSchema = yup.object().shape({
	feedsMinimum: yup.positiveInteger(),
	feedsMaximum: yup.positiveInteger(),
	minPrice: yup.positiveNumber(),
	maxPrice: yup.positiveNumber(),
});

const PartnerMenuBrowser = ({
	onPartnerMenuItemCardClick,
	PartnerId,
	PartnerLocationId,
}) => {
	const { setCurrentPartner } = useContext(PartnerContext);
	const { data, loading } = useQuery(PARTNER_MENU_QUERY, {
		variables: { PartnerId, PartnerLocationId },
	});

	const contentProps = {
		style: {
			display: 'block',
		},
	};

	if (loading) {
		return <CenteredCircularProgress />;
	}

	setCurrentPartner(data.Partners_by_pk);

	return (
		<Formik
			initialValues={{
				dietaryTags: [],
				maxPrice: '',
				minPrice: '',
				name: '',
			}}
			validationSchema={validationSchema}
		>
			{({ handleReset, values }) => (
				<>
					<ExpansionPanel
						contentProps={contentProps}
						title={
							<>
								<InlineContent component="span">
									<FilterList />
								</InlineContent>
								<span>Filter Menu Items</span>
							</>
						}
						titleProps={{
							variant: 'h5',
							component: 'h2',
						}}
					>
						<GridContainer columns={3}>
							<LinkedTextInput name="name" />
							<LinkedCurrencyInput name="minPrice" />
							<LinkedCurrencyInput name="maxPrice" />
							<LinkedMultiSelect
								name="dietaryTags"
								options={data.DietaryTags}
								getOptionKey={dietaryTag => dietaryTag.name}
								getOptionLabel={dietaryTag => dietaryTag.name}
								getOptionValue={dietaryTag => dietaryTag.name}
							/>
							<LinkedIntegerInput name="feedsMinimum" />
							<LinkedIntegerInput name="feedsMaximum" />
						</GridContainer>
						<PrimaryButton onClick={handleReset}>
							Reset
						</PrimaryButton>
					</ExpansionPanel>
					{data.Partners_by_pk.PartnerMenuSections.filter(
						partnerMenuSection =>
							partnerMenuSection.PartnerMenuItems.some(
								filterMenuItem(values)
							)
					).map(partnerMenuSection => (
						<ExpansionPanel
							key={partnerMenuSection.id}
							contentProps={contentProps}
							defaultExpanded={true}
							title={partnerMenuSection.name}
							titleProps={{
								variant: 'h5',
								component: 'h3',
							}}
						>
							{partnerMenuSection.howToOrder && (
								<NoteContainer
									content={partnerMenuSection.howToOrder}
								/>
							)}
							<GridContainer columns={4}>
								{partnerMenuSection.PartnerMenuItems.filter(
									filterMenuItem(values)
								).map(partnerMenuItem => (
									<PartnerMenuItemCard
										key={partnerMenuItem.id}
										onClick={
											!!onPartnerMenuItemCardClick
												? () =>
														onPartnerMenuItemCardClick(
															partnerMenuItem
														)
												: undefined
										}
										partnerMenuItem={partnerMenuItem}
									/>
								))}
							</GridContainer>
						</ExpansionPanel>
					))}
				</>
			)}
		</Formik>
	);
};

PartnerMenuBrowser.propTypes = {
	onPartnerMenuItemCardClick: PropTypes.func,
	PartnerId: PropTypes.string.isRequired,
	PartnerLocationId: PropTypes.string,
};

export default PartnerMenuBrowser;
