import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
	getContact,
	getMealProposals,
	getPurchaseOrders,
} from '@sporkbytes/api-client/PublicContacts';
import {
	Alert,
	DateRangeSelector,
	GridContainer,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';
import { shortLinkDomain } from '@sporkbytes/config';

import { MealProposalTable, OrderSection } from './helpers';
import LoadingPage from 'components/content/LoadingPage';
import NotFoundPage from 'components/content/NotFoundPage';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PurchaseOrdersTable from 'components/purchase-orders/PurchaseOrdersTable';

import useRequest from 'hooks/useRequest';

import { formatDateRangeForQueryString } from 'services/dates';
import axios from 'services/data';

const DEFAULT_DATE_RANGE = {
	beginDate: new Date(),
};

const HIDDEN_PRESETS = ['allTime', 'allPast', 'quarters', 'years'];

const ContactPublicProfile = props => {
	const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
	const { personalProfileId } = useParams();
	const history = useHistory();

	const { loading: loadingContact, data: contact } = useRequest(() =>
		getContact(axios, personalProfileId)
	);
	const {
		loading: loadingMeals,
		data: [mealProposals, purchaseOrders] = [],
	} = useRequest(
		() =>
			Promise.all([
				getMealProposals(
					axios,
					personalProfileId,
					formatDateRangeForQueryString(dateRange, 'deliveryDate')
				),
				getPurchaseOrders(
					axios,
					personalProfileId,
					formatDateRangeForQueryString(dateRange, 'pickupTime')
				),
			]),
		[dateRange]
	);

	if (loadingContact || loadingMeals) {
		return <LoadingPage />;
	}

	if (contact === 'No contact found') {
		return (
			<NotFoundPage
				body="No profile was found with that ID. It's possible that it has changed."
				title="Contact Not Found"
			/>
		);
	}

	const isPartnerContact = contact.partners.length > 0;

	return (
		<Page title={contact.fullName}>
			<PageHeader headerText={`Welcome, ${contact.fullName}`} />

			<GridContainer columns="none" justify="center">
				<PrimaryButton
					href={`${shortLinkDomain}/get-started`}
					target="_blank"
					rel="noopener noreferrer"
				>
					New Order
				</PrimaryButton>
				{isPartnerContact &&
					contact.partners.map(({ publicProfileId, name }) => (
						<PrimaryButton
							onClick={() =>
								history.openNamedRoute('publicPartnerProfile', {
									publicProfileId,
								})
							}
						>
							View Profile for {name}
						</PrimaryButton>
					))}
			</GridContainer>
			<DateRangeSelector
				defaultDateRange={dateRange}
				hiddenPresets={[
					...HIDDEN_PRESETS,
					!isPartnerContact ? 'payoutPeriods' : null,
				]}
				onChange={setDateRange}
			/>
			{mealProposals.length > 0 && (
				<OrderSection
					header="Meal Proposals"
					table={<MealProposalTable mealProposals={mealProposals} />}
				/>
			)}
			{purchaseOrders.length > 0 && (
				<OrderSection
					header="Purchase Orders"
					table={
						<PurchaseOrdersTable purchaseOrders={purchaseOrders} />
					}
				/>
			)}
			{mealProposals.length === 0 && purchaseOrders.length === 0 && (
				<Alert type="info" size="large">
					There are no orders for this date range.
				</Alert>
			)}
		</Page>
	);
};

export default ContactPublicProfile;
