import React from 'react';
import PropTypes from 'prop-types';

import PurchaseOrderReviewsTable from 'components/purchase-order-reviews/PurchaseOrderReviewsTable';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';
import SporkReviewsTable from 'components/spork-reviews/SporkReviewsTable';

const MealProposalReviews = ({ MealProposalId }) => {
	return (
		<QueryStringSyncedTabs
			id="review-type"
			tabs={[
				{
					id: 'spork-reviews',
					label: 'Spork Reviews',
					content: (
						<SporkReviewsTable
							hiddenColumns={[
								'orderNumber',
								'clientLocationFriendlyName',
							]}
							variables={{ MealProposalId }}
						/>
					),
				},
				{
					id: 'purchase-order-reviews',
					label: 'Partner Reviews',
					content: (
						<PurchaseOrderReviewsTable
							hiddenColumns={['orderNumber', 'clientName']}
							variables={{ MealProposalId }}
						/>
					),
				},
			]}
		/>
	);
};

MealProposalReviews.propTypes = {
	MealProposalId: PropTypes.string.isRequired,
};

export default MealProposalReviews;
