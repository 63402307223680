import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import startCase from 'lodash.startcase';
import {
	Form,
	GridContainer,
	LinkedTextInput,
	LinkedCurrencyInput,
	LinkedPercentInput,
	LinkedSingleSelect,
} from '@sporkbytes/material-ui-kit-react';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import SporkLocationLinkedSingleSelect from 'components/spork-locations/SporkLocationLinkedSingleSelect';

import { useStore } from 'models/store';
import * as yup from 'models/validation';

const TYPES = ['flat', 'percent', 'per person'];

const SETTINGS_QUERY = gql`
	query FeeForm {
		AccountingSettings(order_by: { label: asc }) {
			id
			label
		}
	}
`;

const FeeForm = props => {
	const { data, loading } = useQuery(SETTINGS_QUERY);
	const { hasMoreThanOneSporkLocation } = useStore();

	const validationSchema = yup.object().shape({
		name: yup.name().required(),
		description: yup.string().max(100),
		amount: yup.nonNegativeNumber().required('Please enter an amount.'),
		type: yup.mixed().oneOf(TYPES).required('Please select a type.'),
		AccountingSettingId: yup
			.string()
			.required('Please select an accounting setting.'),
		SporkLocationId: yup.sporkLocationId(),
	});

	return loading ? (
		<CenteredCircularProgress />
	) : (
		<Form {...props} validationSchema={validationSchema}>
			{({ values }) => (
				<>
					<GridContainer>
						<LinkedTextInput name="name" autoFocus />
						<LinkedTextInput
							name="description"
							label="Description"
						/>
						{hasMoreThanOneSporkLocation() ? (
							<SporkLocationLinkedSingleSelect />
						) : null}
					</GridContainer>
					<GridContainer>
						{values.type === 'percent' ? (
							<LinkedPercentInput name="amount" />
						) : (
							<LinkedCurrencyInput name="amount" />
						)}
						<LinkedSingleSelect
							name="type"
							options={TYPES.map(type => ({
								value: type,
								label: startCase(type),
							}))}
							getOptionValue={option => option.value}
							getOptionLabel={option => option.label}
							getOptionKey={option => option.value}
						/>
						<LinkedSingleSelect
							name="AccountingSettingId"
							label="Accounting Setting"
							options={data.AccountingSettings.map(
								({ id, label }) => ({
									value: id,
									label,
								})
							)}
							getOptionValue={option => option.value}
							getOptionLabel={option => option.label}
							getOptionKey={option => option.value}
							helperText="This associates the fee with the appropriate item code on an invoice."
						/>
					</GridContainer>
				</>
			)}
		</Form>
	);
};

export default FeeForm;
