import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import {
	GridContainer,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';

import DetailSection from 'components/content/DetailSection';

const ClientLocationProfiles = ({
	clientLocation: { id, publicMenuProfileId },
}) => {
	const history = useHistory();

	return (
		<>
			<DetailSection title="Menu Profile">
				<Typography paragraph>
					The location's Menu Profile is a page that displays links to
					menus for upcoming meals. Because the page does not contain
					any links to meal pages where you can approve and confirm,
					this page can be shared with anyone at the client location
					who wants to have visibility into upcoming menus. Note,
					however, that this won't necessarily be appropriate to share
					with all client locations, but only those who have a
					consistent group that is fed regularly.
				</Typography>
				<PrimaryButton
					onClick={() =>
						history.openNamedRoute('clientLocationsMenuProfile', {
							publicMenuProfileId,
						})
					}
				>
					View Menu Profile
				</PrimaryButton>
			</DetailSection>

			<DetailSection title="Reviewable Meals Profile">
				<Typography paragraph>
					The location's Reviewable Meals Profile is a page that
					displays links to review forms for meals that have happened
					in the last week. The QR code linked to below encodes the
					URL of this location's Reviewable Meals Profile page. It can
					be printed and set up at the client location for employees
					to scan.
				</Typography>
				<GridContainer columns="none">
					<PrimaryButton
						onClick={() =>
							history.openNamedRoute(
								'clientLocationsReviewableMealsQRCode',
								{ id }
							)
						}
					>
						View QR Code
					</PrimaryButton>
					<PrimaryButton
						onClick={() =>
							history.openNamedRoute(
								'clientLocationsReviewableMealsProfile',
								{ publicMenuProfileId }
							)
						}
					>
						Reviewable Meals Profile
					</PrimaryButton>
				</GridContainer>
			</DetailSection>
		</>
	);
};

ClientLocationProfiles.fragments = {
	details: gql`
		fragment ClientLocationProfiles on ClientLocations {
			id
			publicMenuProfileId
		}
	`,
};

export default ClientLocationProfiles;
