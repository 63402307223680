import React from 'react';
import { makeStyles } from '@material-ui/core';
import { getRandom } from '@sporkbytes/api-client/PublicFoodQuotes';

import LoadingPage from 'components/content/LoadingPage';
import Logo from 'components/content/Logo';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

import { getThemedLogo } from 'services/sporkMetadata';
import axios from 'services/data';
import useRequest from 'hooks/useRequest';

const useStyles = makeStyles(theme => ({
	cite: {
		fontSize: 16,
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	logo: {
		display: 'block',
		margin: theme.spacing(4),
	},
	quote: {
		fontSize: 24,
		textAlign: 'center',
	},
}));

const ReviewThankYou = () => {
	const classes = useStyles();
	const { data, loading } = useRequest(() => getRandom(axios));

	if (loading) {
		return <LoadingPage />;
	}

	const { author, quote } = data;

	return (
		<Page title="Thank You For The Review">
			<div className={classes.container}>
				<PageHeader headerText="Thanks for the review!" />
				<blockquote className={classes.quote}>"{quote}"</blockquote>
				<cite className={classes.cite}> - {author}</cite>
				<Logo
					url={getThemedLogo()}
					size="large"
					alt="Spork Bytes"
					className={classes.logo}
				/>
			</div>
		</Page>
	);
};

export default ReviewThankYou;
