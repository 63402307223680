import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { post } from '@sporkbytes/api-client/PurchaseOrderPayoutAdjustments';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PurchaseOrderPayoutAdjustmentForm from 'components/purchase-order-payout-adjustments/PurchaseOrderPayoutAdjustmentForm';

const PURCHASE_ORDER_QUERY = gql`
	query PurchaseOrderPayoutAdjustmentCreate($PurchaseOrderId: uuid!) {
		PurchaseOrders_by_pk(id: $PurchaseOrderId) {
			...PurchaseOrderPayoutAdjustmentFormDetails
		}
	}
	${PurchaseOrderPayoutAdjustmentForm.fragments.details}
`;

const PayoutAdjustmentCreate = () => {
	const { id: PurchaseOrderId } = useParams();

	return (
		<Page
			title="New Payout Adjustment"
			query={PURCHASE_ORDER_QUERY}
			variables={{ PurchaseOrderId }}
		>
			{({ data: { PurchaseOrders_by_pk: purchaseOrder } }) => {
				return (
					<FormWrapper
						form={PurchaseOrderPayoutAdjustmentForm}
						purchaseOrder={purchaseOrder}
						pageSubheaderText="New Payout Adjustment"
						onSubmit={(axios, values) =>
							post(axios, { PurchaseOrderId, ...values })
						}
					/>
				);
			}}
		</Page>
	);
};

export default PayoutAdjustmentCreate;
