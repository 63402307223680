import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { Alert, GridContainer } from '@sporkbytes/material-ui-kit-react';

import ExpansionPanel from 'components/content/ExpansionPanel';
import LoadingStateContainer from 'components/utilities/LoadingStateContainer';
import MetricCardHeadCount from 'components/metrics/MetricCardHeadCount';
import MetricCardPartnerPayout from 'components/metrics/MetricCardPartnerPayout';
import MetricCardPurchaseOrderReviews from 'components/metrics/MetricCardPurchaseOrderReviews';
import MetricCardRevenue from 'components/metrics/MetricCardRevenue';
import MetricCardSporkPayout from 'components/metrics/MetricCardSporkPayout';
import MetricCardSporkReviews from 'components/metrics/MetricCardSporkReviews';

const useStyles = makeStyles(theme => ({
	content: {
		display: 'block',
	},
	expansionPanel: {
		margin: theme.spacing(2, 0),
	},
}));

const getMealProposalMetricsQuery = filters => gql`
	query MealProposalMetrics {
		MealProposals(
			where: {
				${filters}
				MealOptions: { approved: { _eq: true} }
			}
		) {
			...MetricCardHeadCountDetails
			...MetricCardPartnerPayoutDetails
			...MetricCardPurchaseOrderReviewsDetails
			...MetricCardRevenueDetails
			...MetricCardSporkPayoutDetails
			...MetricCardSporkReviewsDetails
		}
	}
	${MetricCardHeadCount.fragments.details}
	${MetricCardPartnerPayout.fragments.details}
	${MetricCardPurchaseOrderReviews.fragments.details}
	${MetricCardRevenue.fragments.details}
	${MetricCardSporkPayout.fragments.details}
	${MetricCardSporkReviews.fragments.details}
`;

const MealProposalMetrics = ({ filters }) => {
	const classes = useStyles();
	const [expanded, setExpanded] = useState();
	const {
		data = { MealProposals: [] },
		loading,
	} = useQuery(getMealProposalMetricsQuery(filters), { skip: !expanded });

	return (
		<ExpansionPanel
			title="Metrics"
			className={classes.expansionPanel}
			contentProps={{ className: classes.content }}
			titleProps={{ variant: 'h6', component: 'h1' }}
			onChange={(_e, expanded) => setExpanded(expanded)}
		>
			<LoadingStateContainer loading={loading}>
				<Alert type="info">
					These metrics only take into account proposals that have
					been approved by the client.
				</Alert>
				<GridContainer columns={4} justify="center">
					<MetricCardRevenue mealProposals={data.MealProposals} />
					<MetricCardHeadCount mealProposals={data.MealProposals} />
					<MetricCardPartnerPayout
						mealProposals={data.MealProposals}
					/>
					<MetricCardSporkPayout mealProposals={data.MealProposals} />
					<MetricCardSporkReviews
						mealProposals={data.MealProposals}
					/>
					<MetricCardPurchaseOrderReviews
						mealProposals={data.MealProposals}
					/>
				</GridContainer>
			</LoadingStateContainer>
		</ExpansionPanel>
	);
};

MealProposalMetrics.propTypes = {
	filters: PropTypes.string.isRequired,
};

export default MealProposalMetrics;
