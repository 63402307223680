import React from 'react';
import {
	Adjust,
	Cancel,
	Check,
	ContactMail,
	Create,
	DoneAll,
	FileCopy,
} from '@material-ui/icons';

import deletePurchaseOrder from 'components/purchase-orders/PurchaseOrderActionMenu/actions/delete';
import duplicatePurchaseOrder from 'components/purchase-orders/PurchaseOrderActionMenu/actions/duplicate';

import { omit } from 'services/utilities';

export default ({
	afterDelete,
	id,
	partnerName,
	queryString,
	setQueryString,
	userCan,
}) => ({
	ADD_PAYOUT_ADJUSTMENT: {
		canPerformAction: userCan(['purchase-order-payout-adjustments:create']),
		icon: <Adjust />,
	},
	APPROVE: {
		canPerformAction: userCan(['purchase-orders:approve']),
		icon: <Check />,
		label: `Approve PO for ${partnerName}`,
		type: 'success',
	},
	CONFIRM: {
		canPerformAction: userCan(['purchase-orders:confirm']),
		label: `Confirm PO for ${partnerName}`,
		icon: <DoneAll />,
		type: 'success',
	},
	COPY_PO_LINK: {
		label: 'Copy PO Link',
		icon: <FileCopy />,
	},
	DELETE: deletePurchaseOrder({
		afterDelete: async () => {
			await afterDelete();

			// we don't want to try to display a Purchase Order that no longer exists
			setQueryString(omit(queryString, ['purchase-order']));
		},
		id,
		partnerName,
		userCan,
	}),
	DISAPPROVE: {
		canPerformAction: userCan(['purchase-orders:disapprove']),
		label: `Disapprove PO for ${partnerName}`,
		icon: <Cancel />,
		type: 'error',
	},
	DUPLICATE: duplicatePurchaseOrder({
		id,
		partnerName,
		userCan,
	}),
	EDIT: {
		canPerformAction: userCan(['purchase-orders:edit']),
		icon: <Create />,
		label: `Edit PO for ${partnerName}`,
	},
	REASSIGN_CONTACTS: {
		canPerformAction: userCan([
			'purchase-orders-contacts:delete',
			'purchase-orders-contacts:create',
		]),
		icon: <ContactMail />,
	},
	UNDO_CONFIRMATION: {
		canPerformAction: userCan(['purchase-orders:undo-confirmation']),
		label: `Undo Confirmation for PO for ${partnerName}`,
		icon: <Cancel />,
		type: 'success',
	},
});
