import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { post } from '@sporkbytes/api-client/PartnerListMenus';

import FormWrapper from 'components/forms/FormWrapper';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import PageSubheader from 'components/content/PageSubheader';
import PartnerListMenuForm from 'components/partner-list-menus/PartnerListMenuForm';

const PARTNER_QUERY = gql`
	query PartnerListMenuCreate($id: uuid!) {
		Partners_by_pk(id: $id) {
			logoUrl
			name
		}
	}
`;

const PartnerListMenuCreate = () => {
	const { id: PartnerId } = useParams();

	return (
		<Page
			title={({ Partners_by_pk: { name } }) => `New Menu for ${name}`}
			query={PARTNER_QUERY}
			variables={{ id: PartnerId }}
		>
			{({
				data: {
					Partners_by_pk: { name, logoUrl },
				},
			}) => (
				<>
					<PageHeader headerText={name} imgUrl={logoUrl} />
					<PageSubheader>Add New Partner List Menu</PageSubheader>
					<FormWrapper
						form={PartnerListMenuForm}
						onSubmit={(axios, values) =>
							post(axios, {
								PartnerId,
								...values,
							})
						}
					/>
				</>
			)}
		</Page>
	);
};

export default PartnerListMenuCreate;
