import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Alert, SingleSelect } from '@sporkbytes/material-ui-kit-react';
import startCase from 'lodash.startcase';

import { AnalyticsContextProvider } from './context';

import DeliveryDrivers from 'components/analytics/tabs/DeliveryDrivers';
import Home from 'components/analytics/tabs/Home';
import MealProposalFilters, {
	transformQueryString,
} from 'components/meal-proposals/MealProposalFilters';
import Page from 'components/layout/Page';
import QueryStringSyncedTabs from 'components/content/QueryStringSyncedTabs';
import StickySummary from 'components/analytics/widgets/StickySummary';
import TabContainer from 'components/analytics/TabContainer';

import useQueryString from 'hooks/useQueryString';

import { pick } from 'services/utilities';

const useStyles = makeStyles(theme => ({
	filtersContainer: {
		margin: `0 auto`,
		maxWidth: theme.sizes.contentMaxWidth,
		padding: theme.spacing(2),
	},
}));

const Analytics = () => {
	const [queryString, setQueryString] = useQueryString();
	const classes = useStyles();
	const transformedQueryString = transformQueryString(queryString, {
		defaultPreset: 'thisMonth',
		defaultStatus: ['In Progress', 'Delivered', 'Canceled'],
	});

	const changeQueryString = queryStringChanges =>
		setQueryString({ ...queryString, ...queryStringChanges });

	return (
		<Page title="Analytics" fullWidth>
			<AnalyticsContextProvider queryString={transformedQueryString}>
				<StickySummary />
				<div className={classes.filtersContainer}>
					<MealProposalFilters
						value={pick(transformedQueryString, [
							'beginDate',
							'CancellationReasonId',
							'ClientId',
							'ClientLocationId',
							'createdBy',
							'endDate',
							'filteringBy',
							'MealCategoryIds',
							'PartnerId',
							'SporkContactId',
							'status',
							'unapprovedBy',
						])}
						onChange={filters =>
							changeQueryString({
								...filters,
								page: undefined,
							})
						}
						showCancellationReason={false}
					>
						{({ onChange }) => (
							<SingleSelect
								label="Group By"
								options={['day', 'week', 'month', 'quarter']}
								value={transformedQueryString?.groupBy}
								onChange={groupBy => onChange({ groupBy })}
								getOptionLabel={startCase}
							/>
						)}
					</MealProposalFilters>
					{transformedQueryString.status?.includes('Canceled') && (
						<Alert type="info">
							Canceled meals are only used for the Cancellations
							widget. They are not included in any other
							calculations.
						</Alert>
					)}
				</div>
				<QueryStringSyncedTabs
					id="tab"
					tabs={[
						{
							id: 'home',
							label: 'Home',
							content: (
								<TabContainer>
									<Home />
								</TabContainer>
							),
						},
						{
							id: 'delivery-drivers',
							label: 'Delivery Drivers',
							content: (
								<TabContainer>
									<DeliveryDrivers />
								</TabContainer>
							),
						},
					]}
				/>
			</AnalyticsContextProvider>
		</Page>
	);
};

export default Analytics;
