import gql from 'graphql-tag';

import useSubscribedMachine from 'hooks/useSubscribedMachine';

import mealProposalMachine from 'machines/mealProposalMachine';

const MEAL_PROPOSAL_SUBSCRIPTION = gql`
	subscription UseMealProposalMachine($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			approved
			deliveryDate
			deliveryPhotoUrl
			HubSpotDealId
			# id is required, otherwise we get an error that "data is not defined," despite us not caching results like others with this issue
			# see https://github.com/apollographql/react-apollo/issues/1003
			id
			orderNumberText
			status
			MealOptions {
				approved
				confirmed
				PurchaseOrders {
					approved
					confirmed
				}
			}
			MealProposalsContacts {
				Contact {
					id
				}
			}
			SporkContact {
				fullName
			}
		}
	}
`;

export default (id, options = {}) =>
	useSubscribedMachine(
		mealProposalMachine(options.guards),
		MEAL_PROPOSAL_SUBSCRIPTION,
		{ id },
		'data.MealProposals_by_pk',
		options
	);
