import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { cancel } from '@sporkbytes/api-client/MealProposals';
import { canCancel } from '@sporkbytes/meal-proposal-utils';
import { Alert } from '@sporkbytes/material-ui-kit-react';

import BackButton from 'components/routing/BackButton';
import FormWrapper from 'components/forms/FormWrapper';
import MealProposalCancellationForm from 'components/meal-proposals/MealProposalCancellationForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const MEAL_PROPOSAL_QUERY = gql`
	query MealProposalCancel($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			deliveryDate
			orderNumberText
			status
		}
	}
`;

const Cancel = () => {
	const { id } = useParams();

	return (
		<Page
			title={({ MealProposals_by_pk: { orderNumberText } }) =>
				`Cancel ${orderNumberText}`
			}
			query={MEAL_PROPOSAL_QUERY}
			variables={{ id }}
		>
			{({
				data: {
					MealProposals_by_pk: { orderNumberText, ...mealProposal },
				},
			}) =>
				canCancel(mealProposal) ? (
					<>
						<PageHeader headerText={`Cancel ${orderNumberText}`} />
						<FormWrapper
							form={MealProposalCancellationForm}
							pageSubheaderText={`Cancel ${orderNumberText}`}
							onSubmit={(axios, values) =>
								cancel(axios, id, values)
							}
						/>
					</>
				) : (
					<>
						<Alert type="error" size="large">
							This meal cannot be canceled.
						</Alert>
						<BackButton />
					</>
				)
			}
		</Page>
	);
};

export default Cancel;
