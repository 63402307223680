import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { put } from '@sporkbytes/api-client/Users';

import BooleanFieldSwitch from 'components/utilities/BooleanFieldSwitch';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';
import RemoteTable from 'components/content/RemoteTable';
import UserActionMenu from 'components/users/UserActionMenu';

import { getMultiWordSearchWhereClause } from 'services/search';
import { useAuth } from 'services/auth';

const getUsersQuery = searchTerm => gql`
	query Users(
		$activeOrderBy: order_by
		$fullNameOrderBy: order_by
		$limit: Int!
		$offset: Int!
	) {
		Users(
            where: { _and: [${getMultiWordSearchWhereClause(
				searchTerm,
				'fullName'
			)}] }
			order_by: {
				active: $activeOrderBy
				fullName: $fullNameOrderBy
			}
			limit: $limit
			offset: $offset
		) {
			id
			active
			emailAddress
			firstName
			fullName
			phoneNumber
		}
		Users_aggregate(where: { _and: [${getMultiWordSearchWhereClause(
			searchTerm,
			'fullName'
		)}] }) {
			aggregate {
				count
			}
		}
	}
`;

const Users = () => {
	const { userCan } = useAuth();
	const ref = useRef();
	const entityType = 'Users';

	return (
		<Page title={entityType}>
			<PageHeader headerText={entityType} />
			<RemoteTable
				tableRef={ref}
				columns={[
					{
						title: 'Name',
						field: 'fullName',
					},
					{
						title: 'Active',
						field: 'active',
						render: ({ active, id }) => (
							<BooleanFieldSwitch
								afterChange={ref.current.onQueryChange}
								entityId={id}
								entityType="users"
								updateEntity={put}
								value={active}
							/>
						),
					},
					{
						title: 'Actions',
						fields: 'actions',
						sorting: false,
						render: user => (
							<UserActionMenu
								user={user}
								onActionComplete={ref.current.onQueryChange}
							/>
						),
					},
				]}
				getQuery={getUsersQuery}
				defaultOrderBy="fullName"
				defaultOrderDirection="asc"
				queryEntity={entityType}
				addNewEntityRouteName="usersCreate"
				entityToAddName="User"
				canAddNewEntity={userCan(['users:create'])}
			/>
		</Page>
	);
};

export default Users;
