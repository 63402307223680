import React from 'react';
import PropTypes from 'prop-types';
import {
	AccessTime,
	AttachMoney,
	Comment,
	DirectionsCar,
	Help,
	People,
	Phone,
	Receipt,
	RoomService,
	Warning,
} from '@material-ui/icons';
import {
	Form,
	GridContainer,
	LinkedCurrencyInput,
	LinkedIntegerInput,
	LinkedPhoneNumberInput,
	LinkedRichTextEditor,
	LinkedSwitch,
	LinkedTextInput,
	LinkedZIPCodeInput,
} from '@sporkbytes/material-ui-kit-react';

import AddressSearch from 'components/forms/AddressSearch';
import FormSectionHeader from 'components/forms/FormSectionHeader';
import StateSelector from 'components/forms/StateSelector';

import { useAuth } from 'services/auth';
import * as yup from 'models/validation';

const ClientLocationForm = ({ addressSearchDisabled, ...props }) => {
	const { userCan } = useAuth();
	const canEditAddresses = userCan(['client-locations:edit-address']);

	const validationSchema = yup.object().shape({
		name: yup.name(),
		headCount: yup.positiveInteger(),
		phoneNumber: yup.phoneNumber(),
		streetAddress: yup.streetAddress().required(),
		streetAddress2: yup.string(),
		city: yup.city().required(),
		state: yup.state().required(),
		zipCode: yup.zipCode().required(),
		paymentDue: yup.positiveInteger(),
		budgetPerPerson: yup.positiveNumber(),
		totalBudget: yup.positiveNumber(),
		description: yup.string(),
		howToOrder: yup.string(),
		setupInstructions: yup.string(),
		deliveryInstructions: yup.string(),
		dietaryRestrictions: yup.string(),
		timeToPark: yup.timeToPark().required(),
		timeToEnter: yup.timeToEnter().required(),
		timeToExit: yup.timeToExit().required(),
		automatedApprovalThankYou: yup.boolean(),
		automatedEmailConfirmation: yup.boolean(),
		automatedReviewRequest: yup.boolean(),
	});

	return (
		<Form {...props} validationSchema={validationSchema}>
			{({ values, setValues }) => (
				<>
					<FormSectionHeader>Basic Info</FormSectionHeader>
					<GridContainer>
						<LinkedTextInput name="name" autoFocus />
						<LinkedIntegerInput
							name="headCount"
							icon={<People />}
						/>
						<LinkedPhoneNumberInput
							name="phoneNumber"
							icon={<Phone />}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedRichTextEditor
							name="description"
							icon={<Comment />}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedRichTextEditor
							name="howToOrder"
							icon={<Help />}
						/>
					</GridContainer>
					<FormSectionHeader>Address</FormSectionHeader>
					<GridContainer>
						<AddressSearch
							disabled={addressSearchDisabled}
							onChange={addressComponents =>
								setValues({ ...values, ...addressComponents })
							}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedTextInput
							name="streetAddress"
							disabled={!canEditAddresses}
						/>
						<LinkedTextInput
							name="streetAddress2"
							disabled={!canEditAddresses}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedTextInput
							name="city"
							disabled={!canEditAddresses}
						/>
						<StateSelector
							name="state"
							disabled={!canEditAddresses}
						/>
						<LinkedZIPCodeInput
							name="zipCode"
							disabled={!canEditAddresses}
						/>
					</GridContainer>
					<FormSectionHeader>Budgets and Finance</FormSectionHeader>
					<GridContainer>
						<LinkedIntegerInput
							name="paymentDue"
							label="Payment Due (days)"
							icon={<Receipt />}
						/>
						<LinkedCurrencyInput
							name="budgetPerPerson"
							icon={<AttachMoney />}
						/>
						<LinkedCurrencyInput
							name="totalBudget"
							icon={<AttachMoney />}
						/>
					</GridContainer>
					<FormSectionHeader>
						Instructions and Dietary Restrictions
					</FormSectionHeader>
					<GridContainer>
						<LinkedRichTextEditor
							name="deliveryInstructions"
							icon={<DirectionsCar />}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedRichTextEditor
							name="setupInstructions"
							icon={<RoomService />}
						/>
					</GridContainer>
					<GridContainer>
						<LinkedRichTextEditor
							name="dietaryRestrictions"
							icon={<Warning />}
						/>
					</GridContainer>
					<FormSectionHeader>Access Times</FormSectionHeader>
					<GridContainer>
						<LinkedIntegerInput
							name="timeToPark"
							label="Time To Park (minutes)"
							icon={<AccessTime />}
						/>
						<LinkedIntegerInput
							name="timeToEnter"
							label="Time To Enter (minutes)"
							icon={<AccessTime />}
						/>
						<LinkedIntegerInput
							name="timeToExit"
							label="Time To Exit (minutes)"
							icon={<AccessTime />}
						/>
					</GridContainer>
					<FormSectionHeader>Automated Emails</FormSectionHeader>
					<GridContainer>
						<LinkedSwitch
							name="automatedApprovalThankYou"
							uncheckedHelperText="No"
							checkedHelperText="Yes"
						/>
						<LinkedSwitch
							name="automatedEmailConfirmation"
							uncheckedHelperText="No"
							checkedHelperText="Yes"
						/>
						<LinkedSwitch
							name="automatedReviewRequest"
							uncheckedHelperText="No"
							checkedHelperText="Yes"
						/>
					</GridContainer>
				</>
			)}
		</Form>
	);
};

ClientLocationForm.propTypes = {
	addressSearchDisabled: PropTypes.bool,
};

export default ClientLocationForm;
