import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useFormikContext } from 'formik';
import {
	DateRangeSelector,
	GridContainer,
	LinkedCheckboxGroup,
} from '@sporkbytes/material-ui-kit-react';
import { getYesterday } from '@sporkbytes/time-utils';
import { makeCommaSeparatedList } from '@sporkbytes/string-utils';

import DetailPageLink from 'components/routing/DetailPageLink';
import FormSectionHeader from 'components/forms/FormSectionHeader';
import LoadingStateContainer from 'components/utilities/LoadingStateContainer';

import { getLineItemsForInvoice } from 'services/accounting';
import { pick } from 'services/utilities';

const MEALS_QUERY = gql`
	query InvoiceMealSelector(
		$beginDate: timestamptz
		$endDate: timestamptz
		$ClientLocationId: uuid!
	) {
		MealProposals(
			where: {
				ClientLocationId: { _eq: $ClientLocationId }
				deliveryDate: { _gte: $beginDate, _lte: $endDate }
				InvoiceId: { _is_null: true }
				MealOptions: { approved: { _eq: true } }
				status: { _nin: ["Canceled", "Draft"] }
			}
			order_by: { deliveryDate: asc }
		) {
			id
			deliveryDate
			headCount
			orderNumberText
			MealProposalsContacts(order_by: { Contact: { fullName: asc } }) {
				Contact {
					fullName
				}
			}
			MealOptions(where: { approved: { _eq: true } }) {
				PurchaseOrders {
					PurchaseOrderMenuSections {
						PurchaseOrderMenuItems {
							costToClient
							discount
							quantity
							PartnerMenuItem {
								friendlyName
							}
						}
					}
					PartnerLocation {
						Partner {
							name
						}
					}
				}
			}
			MealProposalFees {
				amount
				discount
				name
				type
				Fee {
					AccountingSetting {
						value
					}
				}
			}
		}
		AccountingSettings_by_pk(id: "clientInvoiceMealItemCode") {
			value
		}
	}
`;

const InvoiceMealSelector = ({ ClientLocationId }) => {
	const [dateRange, setDateRange] = useState(getYesterday());
	const { data, loading } = useQuery(MEALS_QUERY, {
		variables: {
			...pick(dateRange, ['beginDate', 'endDate']),
			ClientLocationId,
		},
	});
	const { setFieldValue, values } = useFormikContext();

	// auto-select Meal Proposals when new ones are retrieved
	useEffect(() => {
		setFieldValue(
			'includedMealProposalIds',
			data?.MealProposals.map(({ id }) => id)
		);
	}, [data, setFieldValue]);

	// set appropriate Formik values when the included Meal Proposals change
	useEffect(() => {
		if (Array.isArray(values.includedMealProposalIds)) {
			const includedMealProposals = data?.MealProposals.filter(({ id }) =>
				values.includedMealProposalIds.includes(id)
			);

			setFieldValue(
				'invoice.lineItems',
				includedMealProposals.flatMap(
					getLineItemsForInvoice(data?.AccountingSettings_by_pk.value)
				)
			);
			setFieldValue(
				'invoice.reference',
				makeCommaSeparatedList(
					includedMealProposals.map(
						({ orderNumberText }) => orderNumberText
					)
				)
			);
		}
	}, [data, setFieldValue, values.includedMealProposalIds]);

	return (
		<>
			<FormSectionHeader>Find Meals to Include</FormSectionHeader>
			<GridContainer>
				<DateRangeSelector
					defaultDateRange={dateRange}
					onChange={setDateRange}
				/>
			</GridContainer>
			<LoadingStateContainer loading={loading} height={100}>
				<GridContainer>
					<LinkedCheckboxGroup
						name="includedMealProposalIds"
						label="Choose Meals"
						options={data?.MealProposals}
						getOptionKey={option => option.id}
						getOptionLabel={option => (
							<>
								<DetailPageLink
									entityType="mealProposals"
									id={option.id}
									text={option.orderNumberText}
									target="_blank"
								></DetailPageLink>{' '}
								-{' '}
								{makeCommaSeparatedList(
									option.MealProposalsContacts.map(
										({ Contact: { fullName } }) => fullName
									)
								)}
							</>
						)}
						getOptionValue={option => option.id}
						showCheckAll={true}
					/>
				</GridContainer>
			</LoadingStateContainer>
		</>
	);
};

InvoiceMealSelector.propTypes = {
	ClientLocationId: PropTypes.string.isRequired,
};

export default InvoiceMealSelector;
