import React from 'react';
import { Form } from '@sporkbytes/material-ui-kit-react';

import PartnerMenuItemFormElements, {
	validationSchema,
} from 'components/partner-menu-items/PartnerMenuItemFormElements';

const PartnerMenuItemForm = props => {
	return (
		<Form {...props} validationSchema={validationSchema}>
			{() => <PartnerMenuItemFormElements />}
		</Form>
	);
};

export default PartnerMenuItemForm;
