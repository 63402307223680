import MealProposalHubSpotSyncs from 'pages/meal-proposal-hub-spot-syncs/MealProposalHubSpotSyncs';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'mealProposalHubSpotSyncs',
		path: '',
		component: MealProposalHubSpotSyncs,
		permissions: ['meal-proposal-hub-spot-syncs:view'],
		exact: true,
	},
];

export default prependUrlPath('meal-proposal-hub-spot-syncs', routes);
