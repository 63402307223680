import React, { useContext, useState } from 'react';
import { clientHasApproved } from '@sporkbytes/meal-proposal-utils';

const DashboardDayContext = React.createContext();

const getCurrentDriverSchedules = mealProposals => {
	let currentSchedules = {};

	mealProposals
		.filter(
			({ MealProposalsDeliveryDrivers }) =>
				MealProposalsDeliveryDrivers.length > 0
		)
		.forEach((mealProposal, index) => {
			mealProposal.MealProposalsDeliveryDrivers.forEach(
				({ DeliveryDriver }) => {
					let schedule = currentSchedules[DeliveryDriver.id];

					if (!schedule) {
						schedule = {
							mealProposalIds: [],
							deliveryDriver: DeliveryDriver,
							sortOrder: index,
						};

						currentSchedules[DeliveryDriver.id] = schedule;
					}

					schedule.mealProposalIds.push(mealProposal.id);
				}
			);
		});

	return Object.values(currentSchedules).sort(
		(scheduleA, scheduleB) => scheduleA.sortOrder - scheduleB.sortOrder
	);
};

const DashboardDayContextProvider = ({ date, children }) => {
	const [expanded, setExpanded] = useState(false);
	const [controlsToShow, setControlsToShow] = useState();
	const [selectedMealProposals, setSelectedMealProposals] = useState([]);
	const [mealProposals, mealProposalSetter] = useState([]);
	const [numberOfDrivers, setNumberOfDrivers] = useState('');

	// For the case when a selected meal is canceled, disapproved or moved to a different day.
	const setMealProposals = mealProposals => {
		mealProposalSetter(mealProposals);
		setSelectedMealProposals(selectedMealProposals =>
			selectedMealProposals.filter(
				({ id }) =>
					!!mealProposals
						.filter(clientHasApproved)
						.find(
							approvedMealProposal =>
								approvedMealProposal.id === id
						)
			)
		);
	};

	const approvedMealProposals = mealProposals.filter(clientHasApproved);

	const onSelectMealProposal = (mealProposal, selected) => {
		let newSelectedMealProposals;

		if (selected) {
			newSelectedMealProposals = approvedMealProposals.filter(
				approvedMealProposal =>
					!!selectedMealProposals.find(
						({ id }) => id === approvedMealProposal.id
					) || approvedMealProposal.id === mealProposal.id
			);
		} else {
			newSelectedMealProposals = selectedMealProposals.filter(
				({ id }) => id !== mealProposal.id
			);
		}

		setSelectedMealProposals(newSelectedMealProposals);
	};

	const onSelectAllMeals = () => {
		if (selectedMealProposals.length === approvedMealProposals.length) {
			setSelectedMealProposals([]);
		} else {
			setSelectedMealProposals(approvedMealProposals);
		}
	};

	const findDriverSchedulesDisabled =
		selectedMealProposals.length === 0 ||
		typeof numberOfDrivers !== 'number' ||
		numberOfDrivers < 1 ||
		numberOfDrivers > selectedMealProposals.length;

	const showCurrentSchedulesDisabled =
		getCurrentDriverSchedules(selectedMealProposals).length === 0;

	return (
		<DashboardDayContext.Provider
			value={{
				approvedMealProposals,
				controlsToShow,
				date,
				expanded,
				findDriverSchedulesDisabled,
				getCurrentDriverSchedules,
				mealProposals,
				numberOfDrivers,
				onSelectAllMeals,
				onSelectMealProposal,
				selectedMealProposals,
				setControlsToShow,
				setExpanded,
				setMealProposals,
				setNumberOfDrivers,
				showCurrentSchedulesDisabled,
				showDispatchControls: controlsToShow === 'dispatchControls',
				showSchedulingControls: controlsToShow === 'schedulingControls',
			}}
		>
			{children}
		</DashboardDayContext.Provider>
	);
};

const useDashboardDay = () => useContext(DashboardDayContext);

export { DashboardDayContextProvider, useDashboardDay };
