import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { reassignSporkContact } from '@sporkbytes/api-client/MealProposals';
import {
	DialogForm,
	LinkedRadioGroup,
} from '@sporkbytes/material-ui-kit-react';

import axios from 'services/data';

import * as yup from 'models/validation';

// TODO: Filter by the roles who can be assigned as Spork Contact (Account Manager and higher; currently just not Operations)
const SPORK_CONTACTS_QUERY = gql`
	query SporkContactsQuery($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			SporkContact {
				id
				fullName
			}
		}
		Users(
			where: {
				_or: [
					{ active: { _eq: true } }
					{ MealProposals: { id: { _eq: $id } } }
				]
			}
			order_by: { firstName: asc }
		) {
			id
			fullName
		}
	}
`;

const MealProposalReassignSporkContactDialog = ({ id, ...props }) => {
	const { data, loading } = useQuery(SPORK_CONTACTS_QUERY, {
		variables: {
			id,
		},
	});

	return (
		<DialogForm
			{...props}
			title="Assign a Spork Contact to this Meal Proposal"
			submitButtonContent="Assign"
			loading={loading}
			initialValues={{
				SporkContactId: data?.MealProposals_by_pk.SporkContact.id,
			}}
			validationSchema={yup.object().shape({
				SporkContactId: yup
					.string()
					.required('Please select a Spork Contact.'),
			})}
			options={data?.Users.map(({ id, fullName }) => ({
				id,
				label: fullName,
			}))}
			onSubmit={values =>
				reassignSporkContact(axios, id, values.SporkContactId)
			}
			enableReinitialize
		>
			{() => (
				<LinkedRadioGroup
					name="SporkContactId"
					label="Spork Contacts"
					options={data?.Users}
					getOptionLabel={option => option.fullName}
					getOptionValue={option => option.id}
					getOptionKey={option => option.id}
				/>
			)}
		</DialogForm>
	);
};

MealProposalReassignSporkContactDialog.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MealProposalReassignSporkContactDialog;
