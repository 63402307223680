import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import {
	Form,
	GridContainer,
	LinkedBooleanRating,
	LinkedTextInput,
} from '@sporkbytes/material-ui-kit-react';

import Logo from 'components/content/Logo';

import { getThemedLogo } from 'services/sporkMetadata';

import * as yup from 'models/validation';

const useStyles = makeStyles(theme => ({
	helperText: {
		textAlign: 'center',
	},
	logo: {
		display: 'block',
		margin: theme.spacing(4, 'auto'),
	},
	sporkReviewQuestion: {
		justifyContent: 'center',
	},
}));

const SporkReviewForm = ({ disabled, sporkReviewQuestions, ...props }) => {
	const classes = useStyles();

	const validationSchema = yup.object().shape({
		sporkReviewQuestions: yup.array().of(
			yup.object().shape({
				id: yup.string().required(),
				answer: yup.boolean().when(['id'], function (id) {
					const question = sporkReviewQuestions.find(
						question => question.id === id
					);

					if (question.required) {
						return this.required('Please select an option.');
					}
					return this;
				}),
			})
		),
		comments: yup.string().max(500),
	});

	return (
		<>
			<Form validationSchema={validationSchema} {...props}>
				{({ setSubmitting, isSubmitting }) => {
					// this is needed to disable the Submit and Cancel buttons for pages where we want to display the form filled out with a client's review and not the form for clients to fill out
					if (disabled && !isSubmitting) {
						setSubmitting(true);
					}

					return (
						<>
							<GridContainer
								direction="column"
								alignItems="center"
							>
								{sporkReviewQuestions.map(
									({ id, question, required }, index) => (
										<LinkedBooleanRating
											key={id}
											name={`sporkReviewQuestions[${index}].answer`}
											label={
												question +
												(required ? '' : ' (optional)')
											}
											className={
												classes.sporkReviewQuestion
											}
											HelperTextProps={{
												className: classes.helperText,
											}}
											disabled={disabled}
										/>
									)
								)}
							</GridContainer>
							<LinkedTextInput
								name="comments"
								disabled={disabled}
							/>
						</>
					);
				}}
			</Form>
			<Logo
				url={getThemedLogo()}
				alt="Spork Bytes"
				size="large"
				className={classes.logo}
			/>
		</>
	);
};

SporkReviewForm.propTypes = {
	disabled: PropTypes.bool,
	onSubmit: PropTypes.func,
	sporkReviewQuestions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			question: PropTypes.string.isRequired,
			required: PropTypes.bool.isRequired,
		})
	),
};

SporkReviewForm.defaultProps = {
	onSubmit: () => {},
};

export default SporkReviewForm;
