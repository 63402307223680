import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	label: {
		color: theme.palette.grey[500],
		fontSize: 14,
		fontWeight: 'bold',
		letterSpacing: 1,
		marginTop: theme.spacing(1),
		textTransform: 'uppercase',
	},
	value: {
		margin: theme.spacing(1, 0),
	},
}));

export const LabelValue = ({ headingLevel, inline, label, value }) => {
	const classes = useStyles();

	return (
		<Grid
			container
			direction={inline ? 'row' : 'column'}
			alignItems="center"
		>
			<Typography
				component={`h${headingLevel}`}
				align="center"
				className={classes.label}
			>
				{label}:{inline && <>&nbsp;</>}
			</Typography>
			<div className={classes.value}>
				{typeof value === 'string' ? (
					<Typography component={`h${headingLevel + 1}`}>
						{value}
					</Typography>
				) : (
					value
				)}
			</div>
		</Grid>
	);
};

LabelValue.propTypes = {
	headingLevel: PropTypes.number.isRequired,
	inline: PropTypes.bool,
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
};

LabelValue.defaultProps = {
	inline: false,
};

export default LabelValue;
