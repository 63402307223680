import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		alignItems: 'center',
		display: 'inline-flex',
		marginRight: theme.spacing(1),
		verticalAlign: 'middle',
		'& + * ': {
			verticalAlign: 'middle',
		},
	},
}));

const InlineContent = ({ children, className, element }) => {
	const classes = useStyles();

	return React.createElement(element, {
		children,
		className: `${classes.container} ${className}`,
	});
};

InlineContent.propTypes = {
	className: PropTypes.string,
	element: PropTypes.string,
};

InlineContent.defaultProps = {
	className: '',
	element: 'div',
};

export default InlineContent;
