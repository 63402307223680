import React from 'react';
import PropTypes from 'prop-types';
import { Divider, List, ListItem, makeStyles } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { sendCorrection } from '@sporkbytes/api-client/PublicPartnerMenuItems';
import {
	Alert,
	Chip,
	Currency,
	DialogForm,
	GridContainer,
	LinkedTextInput,
	PrimaryButton,
} from '@sporkbytes/material-ui-kit-react';

import DefinitionList from 'components/content/DefinitionList';
import FeedsNumber from 'components/content/FeedsNumber';
import LoadingPage from 'components/content/LoadingPage';
import PageSubheader from 'components/content/PageSubheader';
import RibbonContainer from 'components/content/RibbonContainer';
import RichText from 'components/content/RichText';

import axios from 'services/data';

import * as yup from 'models/validation';
import { useStore } from 'models/store';

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: theme.spacing(4),
	},
	itemDivider: {
		margin: theme.spacing(4, 0),
	},
	parentDivider: {
		backgroundColor: theme.palette.secondary[500],
		height: 2,
		margin: theme.spacing(6, 0),
	},
}));

const MenuItemList = ({ data, loading }) => {
	const classes = useStyles();
	const { showDialog, showSuccessNotification } = useStore();

	if (loading) {
		return <LoadingPage />;
	}

	const results = Object.entries(data);

	return results.length > 0 ? (
		results.map(([parentPartnerMenuItemName, partnerMenuItems]) => (
			<div key={parentPartnerMenuItemName} className={classes.container}>
				<PageSubheader>{parentPartnerMenuItemName}</PageSubheader>
				{partnerMenuItems.map(
					(
						{
							active,
							cost,
							description,
							DietaryTags,
							feedsMaximum,
							feedsMinimum,
							name,
							publicProfileId,
							size,
						},
						index
					) => (
						<React.Fragment key={publicProfileId}>
							<RibbonContainer
								show={!active}
								title="inactive"
								color="error"
								size="medium"
							>
								<GridContainer columns="none" spacing={8}>
									<DefinitionList
										items={[
											{
												label: 'Name',
												value: name,
											},
											{
												label: 'Description',
												value: description,
												render: ({ value }) => (
													<RichText body={value} />
												),
												noContentText: 'No description',
											},
											{
												label: 'Size',
												value: size,
												noContentText: 'No size',
											},
											{
												label: 'Feeds',
												value: {
													feedsMinimum,
													feedsMaximum,
												},
												render: ({ value }) => (
													<FeedsNumber {...value} />
												),
											},
											{
												label: 'Cost',
												value: cost,
												render: Currency,
												noContentText: 'No cost',
											},
										]}
									/>
									<List>
										{DietaryTags.map(({ name }) => (
											<ListItem key={name}>
												<Chip label={name} />
											</ListItem>
										))}
									</List>
								</GridContainer>
								<PrimaryButton
									startIcon={<Create />}
									onClick={() =>
										showDialog(DialogForm, {
											title: 'Submit a Correction',
											children: () => (
												<LinkedTextInput
													name="correction"
													autoFocus
												/>
											),
											validationSchema: yup
												.object()
												.shape({
													correction: yup
														.string()
														.required(
															'Please describe the correction.'
														),
												}),
											onSubmit: values =>
												sendCorrection(
													axios,
													publicProfileId,
													values
												).then(() =>
													showSuccessNotification(
														'Correction sent.'
													)
												),
										})
									}
								>
									Submit Correction
								</PrimaryButton>
							</RibbonContainer>
							{index < partnerMenuItems.length - 1 && (
								<Divider className={classes.itemDivider} />
							)}
						</React.Fragment>
					)
				)}
				<Divider className={classes.parentDivider} />
			</div>
		))
	) : (
		<Alert type="info">No items were found with those filters.</Alert>
	);
};

MenuItemList.propTypes = {
	data: PropTypes.object,
	loading: PropTypes.bool,
};

MenuItemList.defaultProps = {
	data: {},
};

export default MenuItemList;
