import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import {
	GridContainer,
	PrimaryButton,
	SingleSelect,
} from '@sporkbytes/material-ui-kit-react';

import CenteredCircularProgress from 'components/utilities/CenteredCircularProgress';
import InlineContent from 'components/content/InlineContent';
import Link from 'components/routing/Link';
import PartnerMenuBrowser from 'components/partner-menus/PartnerMenuBrowser';
import PurchaseOrderActionMenu from 'components/purchase-orders/PurchaseOrderActionMenu/';
import PurchaseOrderMenuItemCreateDialogForm from 'components/purchase-order-menu-items/PurchaseOrderMenuItemCreateDialogForm';
import PurchaseOrderSummaryHeader from 'components/purchase-orders/PurchaseOrderSummaryHeader';

import useQueryString from 'hooks/useQueryString';

import { useStore } from 'models/store';

import { useAuth } from 'services/auth';

const MEAL_OPTION_QUERY = gql`
	query MealOptionPurchaseOrderManager($id: uuid!) {
		MealOptions_by_pk(id: $id) {
			PurchaseOrders(order_by: { pickupTime: asc }) {
				id
				PartnerLocation {
					id
					Partner {
						id
						name
					}
				}
				...PurchaseOrderSummaryHeaderDetails
			}
		}
	}
	${PurchaseOrderSummaryHeader.fragments.details}
`;

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(2),
	},
}));

const MealOptionPurchaseOrderManager = ({ id }) => {
	const { data, loading, refetch } = useQuery(MEAL_OPTION_QUERY, {
		variables: {
			id,
		},
	});
	const { userCan } = useAuth();
	const { showDialog } = useStore();
	const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState();
	const [queryString, setQueryString] = useQueryString();
	const classes = useStyles();

	useEffect(() => {
		if (!!queryString['purchase-order']) {
			const newPO =
				data?.MealOptions_by_pk?.PurchaseOrders.find(
					({ id }) => id === queryString['purchase-order']
				) || data?.MealOptions_by_pk?.PurchaseOrders[0];

			setSelectedPurchaseOrder(newPO);
		} else {
			setSelectedPurchaseOrder(
				data?.MealOptions_by_pk?.PurchaseOrders[0]
			);
		}
	}, [data, queryString]);

	return (
		<div className={classes.container}>
			{loading ? (
				<CenteredCircularProgress />
			) : (
				<>
					<GridContainer alignItems="flex-end">
						{data.MealOptions_by_pk.PurchaseOrders.length > 0 && (
							<SingleSelect
								options={data.MealOptions_by_pk.PurchaseOrders}
								label="Select Purchase Order"
								getOptionKey={purchaseOrder => purchaseOrder.id}
								getOptionLabel={purchaseOrder => (
									<PurchaseOrderSummaryHeader
										purchaseOrder={purchaseOrder}
									/>
								)}
								getOptionValue={purchaseOrder =>
									purchaseOrder.id
								}
								value={selectedPurchaseOrder?.id}
								onChange={PurchaseOrderId => {
									setQueryString({
										...queryString,
										'purchase-order': PurchaseOrderId,
									});
								}}
							/>
						)}
						<div>
							{selectedPurchaseOrder && (
								<InlineContent>
									<PurchaseOrderActionMenu
										afterDelete={refetch}
										id={selectedPurchaseOrder.id}
									/>
								</InlineContent>
							)}
							<PrimaryButton
								routeName="purchaseOrdersCreate"
								variables={{ id }}
								component={Link}
								permissions={[
									'meal-options:edit',
									'purchase-orders:create',
								]}
							>
								Add Purchase Order
							</PrimaryButton>
						</div>
					</GridContainer>
					{selectedPurchaseOrder && (
						<PartnerMenuBrowser
							onPartnerMenuItemCardClick={
								userCan(['purchase-order-menu-items:create'])
									? partnerMenuItem =>
											showDialog(
												PurchaseOrderMenuItemCreateDialogForm,
												{
													PartnerMenuItemId:
														partnerMenuItem.id,
													PurchaseOrderId:
														selectedPurchaseOrder.id,
												}
											)
									: undefined
							}
							PartnerId={
								selectedPurchaseOrder.PartnerLocation.Partner.id
							}
							PartnerLocationId={
								selectedPurchaseOrder.PartnerLocation.id
							}
						/>
					)}
				</>
			)}
		</div>
	);
};

MealOptionPurchaseOrderManager.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MealOptionPurchaseOrderManager;
