// https://auth0.com/docs/quickstart/spa/react/01-login
import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { getUserPermissions } from '@sporkbytes/api-client/Auth';

import { noop } from 'services/utilities';
import axios from 'services/data';

const DEFAULT_REDIRECT_CALLBACK = () =>
	window.history.replaceState({}, document.title, window.location.pathname);

export default class AuthService {
	// construct a singleton
	constructor() {
		const instance = this.constructor.instance;

		if (instance) {
			return instance;
		}

		this.constructor.instance = this;
	}

	getToken() {
		return this.token;
	}

	setToken(token) {
		this.token = token;
	}
}

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({
	children,
	onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
	onAuthenticated = noop,
	...initOptions
}) => {
	const [isAuthenticated, setIsAuthenticated] = useState();
	const [user, setUser] = useState();
	const [permissions, setPermissions] = useState([]);
	const [role, setRole] = useState();
	const [auth0Client, setAuth0] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const initAuth0 = async () => {
			const auth0FromHook = await createAuth0Client(initOptions);
			setAuth0(auth0FromHook);

			// Xero redirects you back to /accounting/callback with a code in the query string, so we must ignore it
			// All other pages with code or state in the query string should be assumed to be Auth0-related and therefore get processed
			if (
				!window.location.pathname.startsWith('/accounting') &&
				window.location.search.includes('code=') &&
				window.location.search.includes('state=')
			) {
				const { appState } =
					await auth0FromHook.handleRedirectCallback();
				onRedirectCallback(appState);
			}

			const isAuthenticated = await auth0FromHook.isAuthenticated();
			setIsAuthenticated(isAuthenticated);

			if (isAuthenticated) {
				const user = await auth0FromHook.getUser();
				setUser(user);

				const token = await auth0FromHook.getIdTokenClaims({
					audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`,
					scope: 'openid email profile',
				});
				const service = new AuthService();
				service.setToken(token.__raw);

				setRole(
					user[
						`${process.env.REACT_APP_AUTH0_TOKEN_CUSTOM_DATA_NAMESPACE}roles`
					][0]
				);

				const permissions = await getUserPermissions(axios, user.sub);
				setPermissions(permissions);

				await onAuthenticated();
			}

			setLoading(false);
		};
		initAuth0();
		// eslint-disable-next-line
	}, []);

	const userCan = requiredPermissions =>
		requiredPermissions.every(requiredPermission =>
			permissions.includes(requiredPermission)
		);

	const userCannot = (...args) => !userCan(...args);

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				userCan,
				userCannot,
				user,
				permissions,
				role,
				loading,
				getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
				loginWithRedirect: (...p) =>
					auth0Client.loginWithRedirect(...p),
				getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
				getTokenWithPopup: (...p) =>
					auth0Client.getTokenWithPopup(...p),
				logout: (...p) => auth0Client.logout(...p),
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
