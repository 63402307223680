import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { sort } from '@sporkbytes/api-client/PurchaseOrderMenuSections';
import { canEdit } from '@sporkbytes/purchase-order-utils';

import EditableSortOrder from 'components/forms/EditableSortOrder';
import InlineContent from 'components/content/InlineContent';
import PurchaseOrderMenuItemLineItem from 'components/purchase-orders/purchase-order-summary-table/PurchaseOrderMenuItemLineItem';
import PurchaseOrderMenuSectionQuantityTableCell from 'components/purchase-order-menu-sections/PurchaseOrderMenuSectionQuantityTableCell';
import TableCellFeatured from 'components/content/TableCellFeatured';

import { PurchaseOrderContext } from 'context/PurchaseOrderContext';

import { useAuth } from 'services/auth';
import axios from 'services/data';

const PurchaseOrderMenuSectionLineItems = ({
	purchaseOrder,
	costType,
	showDiscount,
	showPrices,
}) => {
	const { currentPurchaseOrder } = useContext(PurchaseOrderContext);
	const { role, userCan } = useAuth();
	const permissionsToEdit = ['purchase-order-menu-sections:edit'];
	const canEditSection =
		userCan(permissionsToEdit) && canEdit(currentPurchaseOrder, role);

	return purchaseOrder.PurchaseOrderMenuSections.map(
		({ id, name, PurchaseOrderMenuItems, sortOrder }) => (
			<TableBody key={id}>
				<TableRow>
					<TableCellFeatured>
						{canEditSection && (
							<InlineContent>
								<EditableSortOrder
									onSave={value =>
										sort(axios, id, {
											newValue: value,
										})
									}
									permissions={permissionsToEdit}
									required={true}
									value={sortOrder}
								/>
							</InlineContent>
						)}
						{name}
					</TableCellFeatured>
					<PurchaseOrderMenuSectionQuantityTableCell
						items={PurchaseOrderMenuItems}
					/>
					{showPrices && <TableCell colSpan="3" />}
				</TableRow>
				{PurchaseOrderMenuItems.map(purchaseOrderMenuItem => (
					<PurchaseOrderMenuItemLineItem
						key={purchaseOrderMenuItem.id}
						purchaseOrderMenuItem={purchaseOrderMenuItem}
						costType={costType}
						showDiscount={showDiscount}
						showPrices={showPrices}
					/>
				))}
			</TableBody>
		)
	);
};

PurchaseOrderMenuSectionLineItems.fragments = {
	details: gql`
		fragment PurchaseOrderMenuSectionLineItemsDetails on PurchaseOrders {
			PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
				id
				name
				sortOrder
				PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
					...PurchaseOrderMenuItemLineItemDetails
				}
			}
		}
		${PurchaseOrderMenuItemLineItem.fragments.details}
	`,
};

PurchaseOrderMenuSectionLineItems.propTypes = {
	costType: PropTypes.string.isRequired,
	showDiscount: PropTypes.bool.isRequired,
	showPrices: PropTypes.bool.isRequired,
};

export default PurchaseOrderMenuSectionLineItems;
