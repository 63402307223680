import Detail from 'pages/client-locations/Detail';
import Edit from 'pages/client-locations/Edit';
import FeeCreate from 'pages/client-locations/FeeCreate';
import ReviewableMealsQRCode from 'pages/client-locations/ReviewableMealsQRCode';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'clientLocationsDetail',
		path: '/:id/detail',
		component: Detail,
		permissions: ['client-locations:view'],
		exact: true,
	},
	{
		name: 'clientLocationsEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['client-locations:edit'],
		exact: true,
	},
	{
		name: 'clientLocationsFeesCreate',
		path: '/:id/fees/create',
		component: FeeCreate,
		permissions: ['client-locations-fees:create'],
		exact: true,
	},
	{
		name: 'clientLocationsReviewableMealsQRCode',
		path: '/:id/reviewable-meals-qr-code',
		component: ReviewableMealsQRCode,
		permissions: ['client-locations:view'],
		exact: true,
	},
];

export default prependUrlPath('client-locations', routes);
