import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { post } from '@sporkbytes/api-client/MealProposalActivityLogs';

import FormWrapper from 'components/forms/FormWrapper';
import MealProposalActivityLogForm from 'components/meal-proposal-activity-logs/MealProposalActivityLogForm';
import Page from 'components/layout/Page';
import PageHeader from 'components/content/PageHeader';

const MEAL_PROPOSAL_QUERY = gql`
	query MealProposalFeeCreate($id: uuid!) {
		MealProposals_by_pk(id: $id) {
			orderNumberText
			MealProposalsContacts {
				Contact {
					id
					fullName
				}
			}
			MealOptions {
				PurchaseOrders {
					PurchaseOrdersContacts {
						Contact {
							id
							fullName
						}
					}
				}
			}
		}
	}
`;

const Create = () => {
	const { id } = useParams();

	return (
		<Page
			query={MEAL_PROPOSAL_QUERY}
			variables={{ id }}
			title={() => 'New Activity Log Entry'}
		>
			{({
				data: {
					MealProposals_by_pk: { orderNumberText, ...mealProposal },
				},
			}) => (
				<>
					<PageHeader
						headerText={`New Activity Log Entry for Meal Proposal ${orderNumberText}`}
					/>
					<FormWrapper
						form={MealProposalActivityLogForm}
						mealProposal={mealProposal}
						onSubmit={(axios, values) =>
							post(axios, {
								MealProposalId: id,
								...values,
							})
						}
					/>
				</>
			)}
		</Page>
	);
};

export default Create;
