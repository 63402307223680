import Create from 'pages/partners/Create';
import Detail from 'pages/partners/Detail';
import Edit from 'pages/partners/Edit';
import Partners from 'pages/partners/Partners';
import PartnerLocationCreate from 'pages/partners/PartnerLocationCreate';
import PartnerListMenuCreate from 'pages/partners/PartnerListMenuCreate';
import PartnerMenuSectionCreate from 'pages/partners/PartnerMenuSectionCreate';
import PrintableLabels from 'pages/partners/PrintableLabels';

import { prependUrlPath } from 'services/utilities';

const routes = [
	{
		name: 'partnersCreate',
		path: '/create',
		component: Create,
		permissions: ['partners:create'],
		exact: true,
	},
	{
		name: 'partnersDetail',
		path: '/:id/detail',
		component: Detail,
		permissions: ['partners:view'],
		exact: true,
	},
	{
		name: 'partnersEdit',
		path: '/:id/edit',
		component: Edit,
		permissions: ['partners:edit'],
		exact: true,
	},
	{
		name: 'partners',
		path: '',
		component: Partners,
		permissions: ['partners:view'],
		exact: true,
	},
	{
		name: 'partnerLocationsCreate',
		path: '/:id/partner-locations/create',
		component: PartnerLocationCreate,
		permissions: ['partner-locations:create'],
		exact: true,
	},
	{
		name: 'partnerMenuSectionsCreate',
		path: '/:id/partner-menu-sections/create',
		component: PartnerMenuSectionCreate,
		permissions: ['partner-menu-sections:create'],
		exact: true,
	},
	{
		name: 'partnerListMenusCreate',
		path: '/:id/partner-list-menus/create',
		component: PartnerListMenuCreate,
		permissions: ['partners:edit', 'partner-list-menus:create'],
		exact: true,
	},
	{
		name: 'partnersPrintableLabels',
		path: '/:id/printable-labels',
		component: PrintableLabels,
		permissions: ['partner-menu-items:view'],
		exact: true,
	},
];

export default prependUrlPath('partners', routes);
