import React from 'react';
import gql from 'graphql-tag';
import { TableRow } from '@material-ui/core';
import { totalReceivedBySpork } from '@sporkbytes/meal-option-utils';

import TableCellFeatured from 'components/content/TableCellFeatured';
import TableCellNumeric from 'components/content/TableCellNumeric';

const MealOptionTotalReceivedBySporkLineItem = ({ mealOption }) => (
	<TableRow>
		<TableCellFeatured colSpan="3">
			Total Received by Spork
		</TableCellFeatured>
		<TableCellNumeric
			format="currency"
			value={totalReceivedBySpork(mealOption, mealOption.MealProposal)}
		/>
	</TableRow>
);

MealOptionTotalReceivedBySporkLineItem.fragments = {
	details: gql`
		fragment MealOptionTotalReceivedBySporkLineItemDetails on MealOptions {
			PurchaseOrders(order_by: { pickupTime: asc }) {
				sporkCommissionPercent
				PurchaseOrderMenuSections(order_by: { sortOrder: asc }) {
					PurchaseOrderMenuItems(order_by: { sortOrder: asc }) {
						costToClient
						discount
						paidToPartner
						quantity
					}
				}
				PurchaseOrderPayoutAdjustments {
					amount
				}
			}
			MealProposal {
				headCount
				MealProposalFees {
					amount
					discount
					type
				}
			}
		}
	`,
};

export default MealOptionTotalReceivedBySporkLineItem;
