import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Currency, DialogForm } from '@sporkbytes/material-ui-kit-react';
import { put } from '@sporkbytes/api-client/PurchaseOrderMenuItems';

import PurchaseOrderMenuItemFormElements, {
	validationSchema,
} from 'components/purchase-order-menu-items/PurchaseOrderMenuItemFormElements';

import axios from 'services/data';

const PURCHASE_ORDER_MENU_ITEM_QUERY = gql`
	query PurchaseOrderMenuItemEditDialogFormQuery($id: uuid!) {
		PurchaseOrderMenuItems_by_pk(id: $id) {
			PartnerMenuItem {
				name
				size
				...PurchaseOrderMenuItemFormElementsPartnerMenuItemDetails
			}
			...PurchaseOrderMenuItemFormElementsPurchaseOrderMenuItemDetails
		}
	}
	${PurchaseOrderMenuItemFormElements.fragments.partnerMenuItemDetails}
	${PurchaseOrderMenuItemFormElements.fragments.purchaseOrderMenuItemDetails}
`;

const PurchaseOrderMenuItemEditDialogForm = ({ id, ...props }) => {
	const [itemTotal, setItemTotal] = useState();
	const { data, loading } = useQuery(PURCHASE_ORDER_MENU_ITEM_QUERY, {
		variables: { id },
	});

	const {
		PartnerMenuItem: { name, size },
		...purchaseOrderMenuItem
	} = data?.PurchaseOrderMenuItems_by_pk || { PartnerMenuItem: {} };

	const getTitle = () => {
		if (!name) {
			return 'Edit';
		}

		return `Edit ${name}${size ? ` (${size})` : ''}`;
	};

	return (
		<DialogForm
			{...props}
			loading={loading}
			title={getTitle()}
			onSubmit={values => put(axios, id, values)}
			submitButtonContent={
				<span>
					Save
					<Currency
						value={itemTotal}
						renderText={value => ` - ${value}`}
					/>
				</span>
			}
			validationSchema={validationSchema}
			initialValues={purchaseOrderMenuItem}
			enableReinitialize
		>
			{() => (
				<PurchaseOrderMenuItemFormElements
					partnerMenuItem={
						data.PurchaseOrderMenuItems_by_pk.PartnerMenuItem
					}
					setItemTotal={setItemTotal}
				/>
			)}
		</DialogForm>
	);
};

PurchaseOrderMenuItemEditDialogForm.propTypes = {
	id: PropTypes.string.isRequired,
};

export default PurchaseOrderMenuItemEditDialogForm;
